export default [
  {
    title: 'noCodeInjection',
    url: '/no-code/injection',
    description: 'Allow users to fill forms on your website with a document upload',
    buttonTitle: 'Add AI to your website',
    path: 'NO_CODE_AI_1.png',
    isWorkOffline: true
  },
  {
    title: 'noCodeForm',
    url: '/no-code/form',
    description: 'Allow users to upload documents directly into your systems',
    buttonTitle: 'Create smart forms',
    path: 'NO_CODE_AI_2.png',
    isWorkOffline: true
  }
]
