import { Component } from 'react'

class PaymentCreditsInsert extends Component {
  render () {
    return (
      <>
        <h3 className='paymentCreditsDisclaimer'>Included services</h3>
        <table className='paymentCreditsDisclaimer'>
          <thead>
            <tr>
              <td>Service</td>
              <td>Cost</td>
            </tr>
          </thead>
          <tr>
            <td>API enhancements <span>(no-code solutions, human-in-the-loop, document object model, optional data storage)</span></td>
            <td>Included</td>
          </tr>
          <tr>
            <td>Integrating API results with third parties <span>(file storages, databases, CSV/Excel, XML, SAAS, API, webhooks)</span></td>
            <td>Included</td>
          </tr>
          <tr>
            <td>Operational APIs <span>(user settings, usage, health check, login)</span></td>
            <td>Included</td>
          </tr>
          <tr>
            <td>Mock APIs for testing</td>
            <td>Included</td>
          </tr>
        </table>
      </>
    )
  }
}

// Export
export default PaymentCreditsInsert
