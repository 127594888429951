import MaterialIcon from '../material-icon/MaterialIcon'
import PropTypes from 'prop-types'

const EditButton = ({ onEdit, title }) => {
  const handleEdit = async () => {
    onEdit?.()
  }

  return (
    <MaterialIcon
      title={title || 'Edit'}
      name='stylus_note'
      onClick={handleEdit}
      className='hover-blue'
    />
  )
}

EditButton.propTypes = {
  onEdit: PropTypes.func,
  title: PropTypes.string
}

export default EditButton
