import { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Textarea from '../../interface/textarea/Textarea'
import RadioButton from '../radio-button/RadioButton'

const CustomSurvey = ({
  questionnaire = [],
  selectedItem = 0,
  isOtherAvailable,
  otherPlaceHolder,
  onChangeHandle
}) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedItem)

  const generatedQuestionnaire = isOtherAvailable ? [...questionnaire, 'Other'] : questionnaire

  return (
    <ul className='survey-radio-button-list'>
      {generatedQuestionnaire.map((item, index) => (
        <li key={index}>
          <RadioButton
            label={item}
            value={index === selectedIndex}
            className={clsx('survey')}
            style={{ textWrap: 'nowrap' }}
            onChange={() => {
              setSelectedIndex(index)
              onChangeHandle(index, generatedQuestionnaire[index])
            }}
          />
        </li>
      ))}
      <li>
        {generatedQuestionnaire.length - 1 === selectedIndex && isOtherAvailable && (
          <Textarea
            type='text'
            name='editableValue'
            className={clsx('editable-textarea')}
            placeholder={otherPlaceHolder}
            onChange={e => {
              setSelectedIndex(generatedQuestionnaire.length - 1)
              onChangeHandle(generatedQuestionnaire.length - 1, e.target.value)
            }}
          />
        )}
      </li>
    </ul>
  )
}

CustomSurvey.propTypes = {
  questionnaire: PropTypes.array,
  selectedItem: PropTypes.number,
  isOtherAvailable: PropTypes.bool,
  otherPlaceHolder: PropTypes.string,
  onChangeHandle: PropTypes.func
}

export default CustomSurvey
