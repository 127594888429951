// Modules
import PropTypes from 'prop-types'

// Shapes
import Exit from '~/interface/icon/shapes/Exit'
import HamburgerMenu from '~/interface/icon/shapes/HamburgerMenu'

// Interface: Icon
const Icon = props => {
  switch (props.name) {
    case 'exit':
      return <Exit {...props} />
    case 'hamburger-menu':
      return <HamburgerMenu {...props} />
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired
}

// Export
export default Icon
