import { isWorkingOffline } from '../context/environment.js'
import window from 'global/window'

const getOnPremTitle = () => window.onPremTitle || process.env.ON_PREM_TITLE || 'Offline'
const getOnPremBrand = () => window.onPremBrand || process.env.ON_PREM_BRAND || ''

const offlineBuildPrefix = () => {
  if (!isWorkingOffline()) {
    return ''
  }
  return getOnPremTitle() + ': '
}

export default {
  rawTitle: getOnPremBrand() || (offlineBuildPrefix() + 'Base64.ai: Automatically process all document types'),
  titleSuffix: ' · ' + (getOnPremBrand() || (offlineBuildPrefix() + 'Base64.ai: Automatically process all document types')),
  onPremTitle: getOnPremTitle(),
  onPremBrand: getOnPremBrand(),
  dataExtractionHeaderVideo: window.dataExtractionHeaderVideo || process.env.DATA_EXTRACTION_HEADER_VIDEO || ''
}
