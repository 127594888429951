// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'
import {
  Redirect,
  withRouter
} from 'react-router-dom'
// Content
import dataExtractionTypes from '~/data/dataExtractionTypes'
// Data
import types from '~/data/solutionTypes'
import DemoButton from '~/interface/demo-button/DemoButton'
import DynamicTitle from '~/interface/dynamic-title/DynamicTitle'
// Interface
import Link from '~/interface/link/Link'
import OptionalImage from '~/interface/optional-image/OptionalImage'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import window from 'global/window'
import SolutionsSection from '~/content/solutions-section/SolutionsSection'
import BenefitsSection from '~/content/benefits-section/BenefitsSection'
import CaseStudiesSection from '~/content/case-studies-section/CaseStudiesSection'
// View: Solutions
class Solutions extends Component {
  static propTypes = {
    type: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      samplePhoto: null
    }
    this.renderDocumentTypes = this.renderDocumentTypes.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.type !== prevProps.type) {
      this.typeChangeHandler()
    }
  }

  componentDidMount () {
    this.typeChangeHandler()
  }

  typeChangeHandler () {
    window.scrollTo(0, 0)
    const { type } = this.props
    this.setState({
      samplePhoto: `${window.location.origin}/static/content/solutions/covers/${type}.png`
    })
  }

  renderDocumentTypes (types) {
    return types && types.length > 0 && types.map(type => {
      if (!dataExtractionTypes[type]) {
        return null
      }
      const { name } = dataExtractionTypes[type]
      return (
        <Link
          key={type}
          to={`/features/data-extraction-api/${type}`}
        >
          <figure>
            <img
              src={`/static/content/features/data-extraction/samples/${type}.png`}
              onError={({ target }) => {
                target.onError = null
                target.src = '/static/images/dummy.jpg'
              }}
            />
            <figcaption>{name}</figcaption>
          </figure>
        </Link>
      )
    })
  }

  render () {
    const { type } = this.props
    if (type && !Object.keys(types).includes(type)) {
      return <Redirect to='/solutions' />
    }
    const { samplePhoto } = this.state
    return (
      <View
        name='solutions'
        identifier={type}
      >
        {type
          ? (
              <>
                <DynamicTitle title={`${types[type].name} solutions`} />
                {/* Header: Presentation */}
                <Header name='presentation'>
                  <h1 className='slogan'>
                    <small>End-to-End solutions for</small>
                    { types[type].logo
                      ? (
                          <a
                            href={types[type].demo}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{
                              display: 'block',
                              borderBottom: 'none',
                              margin: '2rem',
                              cursor: 'pointer'
                            }}
                          >
                            <img src={types[type].logo} alt={types[type].name} title={`${types[type].name} is a Base64.ai partner`} style={{ cursor: 'pointer' }} />
                          </a>
                        )
                      : (
                          <>
                    &nbsp;
                            <b>{types[type].name}</b>
                          </>
                        )}
                  </h1>

                  <p className='description'>{types[type].description}</p>
                  {
                    types[type].dataExtractionTypes
                      ? <div className='visual'>{this.renderDocumentTypes(types[type].dataExtractionTypes)}</div>
                      : null
                  }
                  <OptionalImage imageUrl={samplePhoto} />
                  { types[type].youtube
                    ? (
                        <div className='videoWrapper'>
                          <iframe
                            width='560'
                            height='315'
                            src={`https://www.youtube.com/embed/${types[type].youtube}?cc_load_policy=1`}
                            title='YouTube video player'
                            frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                          />
                        </div>
                      )
                    : null}
                </Header>
                {/* Section: Benefits */}
                <BenefitsSection type={type} />
                {/* Section: Case Study */}
                <CaseStudiesSection type={type} />
                {/* Section: Demonstration */}
                <Section
                  name='demonstration'
                  align='center'
                >
                  <h2 className='title'>Try our AI</h2>
                  <p>{
                    types[type].demo
                      ? `Experience our AI for ${types[type].name} now!`
                      : `Experience our AI on ${types[type].name} documents now!`
                  }
                  </p>
                  <DemoButton
                    type={types[type]}
                    demoModels={
                      (types[type].dataExtractionTypes || [])
                        .map(type =>
                          dataExtractionTypes[type]
                            ? dataExtractionTypes[type].model
                            : null
                        )
                        .join('+')
                    }
                  />
                </Section>
              </>
            )
          : (
              <Header name='solution'>
                <h1 className='slogan'>
                  <b>End-to-End Solutions</b>
                </h1>
                <p className='description'>
                  Whether it&apos;s a document, photo or video; Base64.ai can process it instantly. <br />
                  Companies that work with us can automate their end-to-end document processing scenarios.
                  You will not need to find and manage a new vendor for every document type.
                </p>
              </Header>
            )}
        <SolutionsSection />
      </View>
    )
  }
}

// Export within router
export default withRouter(Solutions)
