// Modules
import PropTypes from 'prop-types'

// Brand: Symbol
const Symbol = ({ color }) => (
  <img
    src='/static/images/icon.svg'
    alt='Symbol'
    className='symbol'
    style={{ fill: color }}
  />
)

Symbol.propTypes = {
  color: PropTypes.string
}

Symbol.defaultProps = {
  color: '#00dddd'
}

// Export
export default Symbol
