import { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import PropTypes from 'prop-types'

function range (startAt, endAt) {
  const size = endAt - startAt + 1
  return [...Array(size).keys()].map(i => i + startAt)
}

const DatePicker = ({ onChange, startDate, className }) => {
  const [selectedDate, setSelectedDate] = useState(startDate)

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const years = range(1990, 2100)

  return (
    <div className={className}>
      <ReactDatePicker
        dateFormat='yyyy-MM-dd'
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div className='date-navigation-container'>
            <div className='date-navigation'>
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {'<'}
              </button>
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {'>'}
              </button>
            </div>
            <div className='date-navigation'>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))}
              >
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        selected={selectedDate}
        onChange={date => {
          onChange?.(date)
          setSelectedDate(date)
        }}
      />
    </div>
  )
}

DatePicker.propTypes = {
  onChange: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  className: PropTypes.string
}

export default DatePicker
