import { Component, forwardRef } from 'react'
import Select from 'react-select'
import PropType from 'prop-types'
import acceptedFileFormats from '../../data/acceptedFileFormats'
import FileInput from '../file-input/FileInput'

class DocumentUploader extends Component {
  static propTypes = {
    onChange: PropType.func,
    allModels: PropType.array,
    handleFiles: PropType.func,
    dropText: PropType.string,
    flow: PropType.object,
    selectedModels: PropType.array,
    modalRef: PropType.object,
    dragAndDropButton: PropType.bool,
    title: PropType.string
  }

  static defaultProps = {
    dragAndDropButton: true
  }

  constructor (props) {
    super(props)
    this.state = {
      filter: props.selectedModels || [],
      customModelsData: null
    }

    this.filterStyle = {
      container: provided => ({
        ...provided,
        marginBottom: '1.25rem'
      })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.selectedModels !== this.props.selectedModels) {
      this.setState({ filter: this.props.selectedModels })
    }
  }

  componentDidMount () {
    if (this.props.selectedModels) {
      this.setState({ filter: this.props.selectedModels })
    }
  }

  renderFileInput () {
    return (
      <FileInput
        title={this.props.title || 'You can drag or upload any document here.'}
        accept={Array.from(acceptedFileFormats)}
        dropText={this.props.dropText || 'Upload file'}
        indicator={true}
        dragAndDropButton={this.props.dragAndDropButton}
        onDrop={this.props.handleFiles}
      />
    )
  }

  render () {
    let {
      filter
    } = this.state
    filter = filter || []
    const modelTitleNames = []
    filter.forEach(f => modelTitleNames.push(f.label))
    let modelTitleNameString = modelTitleNames.join(', ')
    if (modelTitleNames.length > 1) {
      modelTitleNameString = modelTitleNameString.substring(0, modelTitleNameString.lastIndexOf(', ')) + ' and ' + modelTitleNameString.substring(modelTitleNameString.lastIndexOf(', ') + 1)
    }
    return (
      <div className='document-uploader-container'>
        {this.state.isDragFile && <div className='drop-zone-container'></div>}
        <Select
          isMulti
          classNamePrefix='react-select'
          className='react-select'
          value={filter}
          styles={this.filterStyle}
          options={this.props.allModels}
          menuPortalTarget={this.props.modalRef && this.props.modalRef.current}
          closeMenuOnSelect={false}
          placeholder='Filter document types...'
          noOptionsMessage={() => 'Loading document types...'}
          filterOption={({
            label,
            value
          }, query) => {
            if (!query) {
              return true
            }
            const modelName = label.toLowerCase().replace(/[-)(]/g, '')
            const modelType = value.toLowerCase()
            query = query.toLowerCase().replace(/[-)(]/g, '')
            for (const part of query.split(' ').filter(x => x.trim())) {
              if (!modelName.includes(part) && !modelType.includes(part)) {
                return false
              }
            }
            return true
          }}
          onChange={filter => {
            this.setState({ filter }, () => this.props.onChange(this.state.filter, modelTitleNameString))
          }}
        />
        <div className='upload-button'>
          {this.renderFileInput()}
        </div>
      </div>
    )
  }
}

const DocumentUploaderWithRef = forwardRef((props, ref) => (
  <DocumentUploader
    modalRef={ref}
    {...props}
  />
))

DocumentUploaderWithRef.displayName = 'DocumentUploader'
DocumentUploaderWithRef.name = 'DocumentUploader'

export default DocumentUploaderWithRef
