import { isWorkingOffline } from '../context/environment'

export const isReCaptchaLoaded = () => {
  return typeof window.grecaptcha !== 'undefined' && typeof window.grecaptcha.enterprise !== 'undefined'
}

export const reCaptcha = (action, cb, errorHandler, retryCount) => {
  const token = 'token'
  if (isWorkingOffline()) {
    return cb && cb(token)
  }
  if (retryCount === undefined) {
    retryCount = 3
  }
  if (!isReCaptchaLoaded()) {
    console.error('reCAPTCHA not loaded')
    return errorHandler && errorHandler('reCAPTCHA not loaded')
  }
  const key = window.location.hostname === 'base64.ai' ? '6Ldm_EIcAAAAAPXvlNhMR28hNkAS47NYotQfj7Gq' : '6LcfTkMcAAAAAFqZJ6AaGCLLRWcwmaiBSxZdEyzG'
  window.grecaptcha.enterprise.ready(function () {
    window.grecaptcha.enterprise.execute(key, { action }).then(token => {
      cb(token)
    }).catch(err => {
      retryCount--
      if (retryCount > 0) {
        return reCaptcha(action, cb, errorHandler, retryCount)
      }
      if (errorHandler) {
        errorHandler(err)
      } else {
        throw new Error(err)
      }
    })
  })
}
