import RadioButton from '../radio-button/RadioButton'
import PropType from 'prop-types'

const BooleanPicker = ({ value, onChange }) => {
  return (
    <div className='boolean-picker'>
      <RadioButton
        value={value === true}
        onChange={() => {
          onChange?.(true)
        }}
        label='Yes'
      />
      <RadioButton
        value={value === false}
        onChange={() => {
          onChange?.(false)
        }}
        label='No'
      />
    </div>
  )
}

BooleanPicker.propTypes = {
  value: PropType.bool,
  onChange: PropType.func
}

export default BooleanPicker
