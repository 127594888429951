// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'
import document from 'global/document'
import window from 'global/window'
import { request } from '~/helpers/request'

// Context
import Context from '~/context/global'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import Loading from '../../interface/loading/Loading'
import Form from '../../interface/form/Form'
import { isProduction, isWorkingOffline } from '../../context/environment'
import Select from 'react-select'
import { isValidPhoneNumber } from 'react-phone-number-input'
import UserManagement from './Sections/UserManagement'
import { AppButton } from '../../interface/app-button/AppButton'

class UserSettings extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      user: this.props.user,
      flows: null,
      defaultFlow: null,
      phoneNumber: this.props.user?.phoneNumber || '',
      isDefaultLoading: false,
      submitHandler: () => {},
      loading: false,
      data: {}
    }

    this.applyPromoCode = this.applyPromoCode.bind(this)
    this.getEmailKey = this.getEmailKey.bind(this)
    this.setUpdate = this.setUpdate.bind(this)
    this.setDeletion = this.setDeletion.bind(this)
    this.saveUserSettings = this.saveUserSettings.bind(this)
    this.updateUserSession = this.updateUserSession.bind(this)
    this.getFlows = this.getFlows.bind(this)
    this.setDefaultFlow = this.setDefaultFlow.bind(this)
    this.sendForm = this.sendForm.bind(this)
    this.updateUniqueFields = this.updateUniqueFields.bind(this)
  }

  componentDidMount () {
    if (!this.state.flows) {
      this.getFlows()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.user?.email !== prevProps.user?.email) {
      this.setState({
        user: this.props.user,
        phoneNumber: this.props.user?.phoneNumber
      })
    }
  }

  getFlows () {
    request({
      endpoint: '/flow/default',
      method: 'POST'
    }, (error, flows) => {
      if (error) {
        return this.setState({
          flows: null
        })
      }
      const defaultFlow = flows.find(f => f.isDefault)
      this.setState({
        defaultFlow: defaultFlow?.flowID
          ? {
              value: defaultFlow.flowID,
              label: defaultFlow.name
            }
          : null
      })
      flows = flows.map(f => {
        return {
          value: f.flowID,
          label: f.name,
          isDefault: f.isDefault
        }
      })
      flows.sort((a, b) => Number(b.isDefault || 0) - Number(a.isDefault || 0))
      this.setState({
        flows
      })
    }, err => {
      console.error('Unable to fetch users flows. Error details:', err)
    })
  }

  setDefaultFlow () {
    this.setState({
      isDefaultLoading: true
    })
    request({
      endpoint: '/flow/default',
      method: 'POST',
      body: {
        desiredFlowID: this.state.defaultFlow.value
      }
    }, error => {
      if (error) {
        return this.setState({
          defaultFlow: null,
          isDefaultLoading: false
        })
      }
      this.setState({
        isDefaultLoading: false
      }, () => {
        this.context.displayModal({
          title: 'Success',
          content: `Your default flow has been updated to ${this.state.defaultFlow.label}.`,
          options: [
            {
              content: 'OK',
              action: () => window.location.reload()
            }
          ],
          isClosable: false
        })
      })
    }, err => {
      console.error('Unable to set default flow of user. Error details:', err)
    })
  }

  applyPromoCode () {
    const { data } = this.state
    if (data.promoCode?.length < 4) {
      return this.context.displayModal({
        title: 'Error',
        content: 'Promo code should be longer than 4 characters.',
        options: [
          {
            content: 'OK'
          }
        ],
        isClosable: true
      })
    }
    this.setState({
      isDefaultLoading: true
    })
    request({
      endpoint: '/apply-promo-code',
      body: data
    }, (error, response) => {
      if (error) {
        this.setState({
          isDefaultLoading: false
        })
        return this.context.displayModal({
          title: 'Failed',
          content: `User settings could not be updated:\n${error}`,
          options: [
            {
              content: 'OK'
            }
          ],
          isClosable: true
        })
      }
      this.setState({
        isDefaultLoading: true
      })
      this.context.displayModal({
        title: 'Success',
        content: 'Your promo code has been updated.',
        options: [
          {
            content: 'OK',
            action: () => window.location.reload()
          }
        ],
        isClosable: false
      })
    })
  }

  setDeletion () {
    this.context.displayModal({
      title: 'Confirmation',
      content: 'Are you sure you want to delete your account?',
      options: [
        {
          content: 'Cancel'
        },
        {
          content: 'Delete',
          intent: 'negative',
          action: () => {
            request({
              endpoint: '/auth/delete-request'
            }, (error, response) => {
              if (error) {
                this.context.displayModal({
                  title: 'Error',
                  content: 'Could not send your deletion request. Please try again later.',
                  options: [
                    { content: 'OK' }
                  ]
                })
              } else {
                this.context.displayModal({
                  title: 'Success',
                  content: 'Your deletion request has been received.',
                  options: [
                    {
                      content: 'OK',
                      action: () => window.location.reload()
                    }
                  ],
                  isClosable: false
                })
              }
            })
          }
        }
      ],
      isClosable: true
    })
  }

  setUpdate (data, errorCallback) {
    if (data.givenName) {
      if (data.givenName.length < 2) {
        errorCallback('Given name must be at least 2 characters long')
        return
      }
      if (!data.givenName.trim()) {
        errorCallback('Given name is invalid')
        return
      }
    }
    if (data.familyName) {
      if (data.familyName.length < 2) {
        errorCallback('Family name must be at least 2 characters long')
        return
      }
      if (!data.familyName.trim()) {
        errorCallback('Family name is invalid')
        return
      }
    }
    if (data.workEmail) {
      if (!data.workEmail.trim()) {
        errorCallback('Email is invalid')
        return
      }
    }
    if (data.password) {
      if (!data.passwordRepeat || (data.hasEmail && !data.passwordOld)) {
        errorCallback('You must fill all fields')
        return
      }
      if (data.password.length < 8) {
        errorCallback('Password should be 8 characters long')
        return
      }
      if (data.password !== data.passwordRepeat) {
        errorCallback('Passwords do not match')
        return
      }
    }
    if (data.phoneNumber) {
      if (!isValidPhoneNumber(data.phoneNumber)) {
        errorCallback('Please enter a valid phone number')
        return
      }
    }
    if (!data.password && !data.givenName && !data.familyName && !data.workEmail && !data.phoneNumber) {
      errorCallback('Invalid Input')
      return
    }
    request({
      endpoint: '/auth/user',
      body: data
    }, (error, response) => {
      if (error) {
        errorCallback(error?.message || error.toString())
      } else {
        errorCallback()
        this.context.displayModal({
          title: 'Success',
          content: 'Your account information has been updated.',
          options: [
            {
              content: 'OK',
              action: () => window.location.reload()
            }
          ],
          isClosable: true
        })
      }
    })
  }

  updateUserSession = (changes, callback) => {
    let error = false
    const {
      allowedSenders,
      allowedDomains,
      storeFiles,
      convertTextToSpeech,
      showDom,
      sftpUpload,
      scanFromAzure,
      options
    } = changes
    const user = this.state.user || {}
    user.settings = user.settings || {}
    if (allowedSenders) {
      user.settings.integrations = user.settings.integrations || {}
      user.settings.integrations.scanFromEmail = user.settings.integrations.scanFromEmail || {}
      user.settings.integrations.scanFromEmail.allowedSenders = allowedSenders
    }
    if (allowedDomains) {
      user.settings.integrations = user.settings.integrations || {}
      user.settings.integrations.scanFromEmail = user.settings.integrations.scanFromEmail || {}
      user.settings.integrations.scanFromEmail.allowedDomains = allowedDomains
    }
    if (storeFiles) {
      user.settings.storeFiles = storeFiles.checked
    }
    if (convertTextToSpeech) {
      user.settings.convertTextToSpeech = convertTextToSpeech.checked
    }
    if (showDom) {
      user.settings.showDom = showDom.checked
    }
    if (sftpUpload) {
      user.settings.integrations = user.settings.integrations || {}
      user.settings.integrations.sftpUpload = sftpUpload
      if (!Object.values(sftpUpload).filter(s => s).length) {
        delete user.settings.integrations.sftpUpload
        if (options && options.submitForUpload) {
          this.context.displayModal({
            title: 'Failed',
            content: 'User settings could not be updated. Please check the inputs.',
            options: [
              {
                content: 'OK',
                action: () => window.location.reload()
              }
            ],
            isClosable: true
          })
          error = true
        }
      }
    }
    if (scanFromAzure) {
      user.settings.integrations = user.settings.integrations || {}
      user.settings.integrations.scanFromAzure = scanFromAzure
      if (!Object.values(scanFromAzure).filter(s => s).length) {
        delete user.settings.integrations.scanFromAzure
        if (options && options.submitForUpload) {
          this.context.displayModal({
            title: 'Failed',
            content: 'User settings could not be updated. Please check the inputs.',
            options: [
              {
                content: 'OK',
                action: () => window.location.reload()
              }
            ],
            isClosable: true
          })
          error = true
        }
      }
    }
    this.setState({
      user
    }, () => {
      !error && callback && callback()
    })
  }

  saveUserSettings = () => {
    request({
      endpoint: '/auth/user',
      body: {
        settings: this.state.user?.settings
      }
    }, (error, response) => {
      if (error) {
        this.context.displayModal({
          title: 'Failed',
          content: 'User settings could not be updated. Please check the inputs.',
          options: [
            {
              content: 'OK',
              action: () => window.location.reload()
            }
          ],
          isClosable: true
        })
      } else {
        this.context.displayModal({
          title: 'Success',
          content: 'User settings have been updated.',
          options: [
            {
              content: 'OK',
              action: () => window.location.reload()
            }
          ],
          isClosable: true
        })
      }
    })
  }

  getScanEmailAddress (emailKey) {
    return `scan${isProduction() ? '+' : 'test+'}${emailKey}@base64.ai`
  }

  getEmailKey () {
    request({
      endpoint: '/emailKey'
    }, (error, response) => {
      if (error) {
        this.context.displayModal({
          title: 'Error',
          content: 'Data cannot be fetched. Please try again later.',
          options: [
            { content: 'OK' }
          ]
        })
        return
      }
      this.context.displayModal({
        title: 'Your secret email key',
        content: (
          <a
            title='Click to copy to clipboard'
            onClick={() => {
              document.body.focus()
              setTimeout(async () => {
                await navigator.clipboard.writeText(this.getScanEmailAddress(response.emailKey))
                window.alert('The email key is copied to your clipboard!')
              }, 500)
            }}
          >
            Click to copy to clipboard<br /><br />
            <span
              style={{
                cursor: 'pointer',
                fontFamily: 'Courier New',
                fontSize: '1rem',
                fontWeight: 'bold'
              }}
            >
              {this.getScanEmailAddress(response.emailKey)}
            </span>
          </a>
        ),
        options: [
          {
            content: 'OK',
            action: () => window.location.reload()
          }
        ],
        isClosable: true,
        isWide: true
      })
    })
  }

  updateUniqueFields (data) {
    this.setState({ data })
  }

  sendForm (data, errorCallback) {
    this.setState({ loading: true })

    const fullData = {
      ...data
    }
    const phoneNumber = this.state.phoneNumber
    if (phoneNumber) {
      fullData.phoneNumber = phoneNumber
    }
    request({
      endpoint: '/auth/user',
      body: fullData
    }, error => {
      if (error) {
        this.context.displayModal({
          title: 'Failed',
          content: `User settings could not be updated:\n${error}`,
          options: [
            {
              content: 'OK',
              action: () => window.location.reload()
            }
          ],
          isClosable: true
        })
      } else {
        this.context.displayModal({
          title: 'Success',
          content: 'User settings have been updated.',
          options: [
            {
              content: 'OK',
              action: () => window.location.reload()
            }
          ],
          isClosable: true
        })
      }
      this.setState({ loading: false })
    })
  }

  render () {
    const {
      user,
      fetching
    } = this.props

    const hasEmail = ((user && user.tags) || []).includes('authEmail')

    return (
      fetching || !user
        ? <Loading text='loading' />
        : user && user.email
          ? (
              <View name='user-settings'>
                <Form
                  className='text-form'
                  inline
                  onDataSubmit={this.sendForm}
                  onDataUpdate={this.updateUniqueFields}
                  onSubmitHandler={submitHandler => {
                    this.setState({ submitHandler })
                  }}
                >
                  {/* Disable "Enter" sending of the form */}
                  <input type='submit' disabled style={{ display: 'none' }} aria-hidden='true' />
                  <Header name='user-settings'>
                    <div className='save-row'>
                      <div className='left'>
                        <h1 className='slogan'>User Settings</h1>
                        <h2 className='introduction'>Manage your account information</h2>
                      </div>
                      <div className='right'>
                        <AppButton
                          className='secondary-cta min-w-36'
                          onClick={() => window.location.reload()}
                        >
                          Cancel
                        </AppButton>
                        <AppButton
                          loading={this.state.loading}
                          onClick={() => this.state.submitHandler()}
                          className='primary-cta min-w-36'
                        >
                          Update
                        </AppButton>
                      </div>
                    </div>
                  </Header>
                  <Section name='setting' tight={true} className='settings-card'>
                    <h1 className='slogan'>Login email</h1>
                    <h2>Your email</h2>
                    <p className='email'>{user.email}</p>
                    <h2>Work email</h2>
                    <p className='email'>{user.workEmail || 'Not specified'}</p>
                    <p><a href='/contact'>Contact us</a> if you want to change your emails.</p>
                  </Section>
                  <Section name='setting' tight={true} className='settings-card'>
                    <h1 className='slogan'>Name</h1>
                    <h2>Name</h2>
                    <Form.Input
                      className='basic'
                      type='text'
                      autoComplete='off'
                      reference='Given name'
                      placeholder={user.givenName}
                      name='givenName'
                    />
                    <h2>Surname</h2>
                    <Form.Input
                      className='basic'
                      type='text'
                      autoComplete='off'
                      reference='Family name'
                      placeholder={user.familyName}
                      name='familyName'
                    />
                  </Section>
                  <Section name='setting' tight={true} className='settings-card'>
                    <h1 className='slogan'>Password</h1>
                    {/* Combining these under a Fragment (<></>) breaks the form styling TODO: Form should ignore/unwrap fragments */}
                    { hasEmail ? <h2>Old Password</h2> : <></>}
                    { hasEmail
                      ? (
                          <Form.Input
                            className='basic'
                            type='password'
                            autoComplete='off'
                            reference='Old password'
                            name='passwordOld'
                          />
                        )
                      : <></>}
                    <h2>New Password</h2>
                    <Form.Input
                      className='basic'
                      type='password'
                      autoComplete='off'
                      reference='New password'
                      name='password'
                    />
                    <h2>Repeat Password</h2>
                    <Form.Input
                      className='basic'
                      type='password'
                      autoComplete='off'
                      reference='New password'
                      name='passwordRepeat'
                    />
                  </Section>
                  {!isWorkingOffline()
                    ? (
                        <Section name='setting' tight={true} className='settings-card'>
                          <h1 className='slogan'>Company information</h1>
                          <h2>Company Name</h2>
                          <Form.Input
                            className='basic'
                            type='text'
                            autoComplete='off'
                            reference='Company name'
                            name='companyName'
                            placeholder={user.companyName}
                          />
                          <h2>Company Website</h2>
                          <Form.Input
                            className='basic'
                            type='text'
                            autoComplete='off'
                            reference='Company website'
                            name='companyWebsite'
                            placeholder={user.companyWebsite}
                          />
                          <h2>Work Email</h2>
                          <Form.Input
                            className='basic'
                            type='text'
                            autoComplete='off'
                            reference='Work email'
                            name='workEmail'
                            placeholder={user.workEmail}
                          />
                        </Section>
                      )
                    : <></>}
                  { this.state.flows?.length
                    ? (
                        <Section name='setting' style={{ zIndex: '3' }} tight={true} className='settings-card'>
                          <h1 className='slogan' id='email-key-config'>Default Flow</h1>
                          <h2 className='description'>
                            { this.state.flows.find(f => f.isDefault)
                              ? <>You are using <b>{this.state.flows.find(f => f.isDefault).label}</b> as your default flow. </>
                              : <>You are not using any default flow and can set a new one from below. </>}
                            The API requests without base64ai-flow-id header or the files you upload on the Demo Page will go to that flow. <br />
                            Your default flow can be any flow that you own or are allowed to upload to.
                          </h2>
                          <div className='self-contained-button-area'>
                            <div className='flow-select'>
                              <Select
                                classNamePrefix='react-select'
                                className='react-select'
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    minHeight: '2.6rem'
                                  })
                                }}
                                isMulti={false}
                                value={this.state.defaultFlow}
                                options={this.state.flows}
                                closeMenuOnSelect={true}
                                placeholder='Filter flows by name...'
                                noOptionsMessage={() => 'No flow found'}
                                menuPlacement='auto'
                                onChange={filter => {
                                  this.setState({
                                    defaultFlow: filter
                                  })
                                }}
                              />
                            </div>
                            <div className='button-container'>
                              <AppButton
                                className='secondary-cta large min-w-28'
                                disabled={this.state.isDefaultLoading}
                                onClick={() => {
                                  this.setDefaultFlow()
                                }}
                              >
                                Save
                              </AppButton>
                            </div>
                          </div>
                        </Section>
                      )
                    : <></>}
                  {!isWorkingOffline()
                    ? (
                        <>
                          <Section name='setting' tight={true} className='settings-card'>
                            <h1 className='slogan'>{user.phoneNumber ? 'Change' : 'Add' } your phone number</h1>
                            <h2>Phone Number</h2>
                            <Form.PhoneInput
                              className='phone-input'
                              name='phoneNumber'
                              reference='Phone number'
                              addInternationalOption={false}
                              defaultCountry='US'
                              placeholder={user.phoneNumber}
                              onChange={e => {
                                this.setState({ phoneNumber: e })
                              }}
                            />
                          </Section>
                          <Section name='setting' tight={true} className='settings-card'>
                            <h1 className='slogan'>Add promo code</h1>
                            <h2>Promo Code</h2>
                            <div className='self-contained-button-area'>
                              <Form.Input
                                className='basic'
                                type='text'
                                autoComplete='off'
                                reference='Promo code'
                                name='promoCode'
                              />
                              <AppButton className='secondary-cta large min-w-28' disabled={this.state.isDefaultLoading} onClick={this.applyPromoCode}>
                                Save
                              </AppButton>
                            </div>
                          </Section>
                          {!isWorkingOffline() ? <UserManagement user={user} /> : <></>}
                          <Section name='setting' tight={true} className='settings-card'>
                            <h1 className='slogan'>Delete your account</h1>
                            <AppButton className='primary-cta large danger mt-8 min-w-28' onClick={this.setDeletion}>Delete</AppButton>
                          </Section>
                        </>
                      )
                    : <></>}
                </Form>
              </View>
            )
          : <Redirect to='/login?next=/user-settings' />
    )
  }
}

UserSettings.propTypes = {
  user: PropTypes.object,
  fetching: PropTypes.bool
}

// Export within router
export default withRouter(UserSettings)
