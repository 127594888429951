import MaterialIcon from '../material-icon/MaterialIcon'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import clsx from 'clsx'

const InfoButton = ({ onClick, children, title, className }) => {
  const handleClick = async () => {
    onClick?.()
  }

  return (

    <Tippy
      content={children}
      placement='top'
      animation='shift-away'
      arrow={false}
    >
      <MaterialIcon
        name='info'
        title={title || ''}
        className={clsx(className, 'hover-blue')}
        onClick={handleClick}
      />
    </Tippy>
  )
}

InfoButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string
}

export default InfoButton
