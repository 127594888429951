// Modules
import React, { Component } from 'react'
// Context
import Context from '~/context/global'
import { getBase64 } from '~/helpers/file'
// Helpers
import { request } from '~/helpers/request'
// Interface
import FileInput from '~/interface/file-input/FileInput'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import { reCaptcha } from '~/helpers/authentication'
import StatusToEmoji from '~/interface/validation-sign/StatusToEmoji'
// Utilities
import { triggerTrackingEvent } from '../../utilities/tracker'

// View: Demo
class FacialVerificationDemo extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.queryImageRef = React.createRef()
    this.queryImageCanvasRef = React.createRef()
    this.queryImageFigureRef = React.createRef()
    this.state = {
      responseMessage: 'Please upload a person\'s face, and a query photo in which you want to search for that face.',
      verifiedFaceImages: null,
      referenceImageBase64: null,
      referenceImageUrl: null,
      queryImageUrl: null,
      queryImageBase64: null
    }
    this.drawRects = this.drawRects.bind(this)
    this.handleFiles = this.handleFiles.bind(this)
    this.makeRequest = this.makeRequest.bind(this)
    this.verifyFaces = this.verifyFaces.bind(this)
    this.showSampleLink = this.showSampleLink.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.drawRects()
  }

  handleFiles (accepted, rejected, isReference) {
    this.setState({
      verifiedFaceImages: null
    })
    if (accepted && accepted.length) {
      getBase64({ source: accepted[0] }, (error, response) => {
        if (error) {
          this.setState({
            responseStatus: 'error',
            responseMessage: 'Cannot read the image file!'
          })
          return
        }
        if (isReference) {
          this.setState({
            referenceImageBase64: response
          }, this.verifyFaces)
        } else {
          this.setState({
            queryImageBase64: response
          }, this.verifyFaces)
        }
      })
    }
    if (rejected && rejected.length) {
      this.setState({
        responseStatus: 'error',
        responseMessage: 'Invalid file format'
      })
    }
  }

  makeRequest () {
    triggerTrackingEvent('demo-completed-facial-verification')
    reCaptcha('demo', token => {
      const queryImageOrUrl = this.state.queryImageBase64 ? { query: this.state.queryImageBase64 } : { queryUrl: this.state.queryImageUrl }
      const documentImageOrUrl = this.state.referenceImageBase64 ? { document: this.state.referenceImageBase64 } : { url: this.state.referenceImageUrl }
      request({
        endpoint: '/face',
        body: {
          token,
          ...queryImageOrUrl,
          ...documentImageOrUrl
        }
      }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: error.message,
            responseStatus: 'error'
          })
        } else {
          if (response.error) {
            this.setState({
              responseMessage: response.error.message,
              responseStatus: 'error'
            })
          } else {
            if (response.matches.length) {
              this.setState({
                responseMessage: `${response.matches.length} face(s) are verified in the query image.`,
                responseStatus: 'success',
                verifiedFaceImages: response.matches
              })
            } else {
              this.setState({
                responseMessage: 'No matching faces were not found in the query image.',
                responseStatus: 'warning'
              })
            }
          }
        }
      }, err => {
        this.setState({
          responseMessage: 'Error happened while processing: ' + err.message,
          responseStatus: 'error'
        })
      })
    })
  }

  verifyFaces () {
    if ((this.state.referenceImageBase64 || this.state.referenceImageUrl) && (this.state.queryImageBase64 || this.state.queryImageUrl)) {
      this.setState({
        responseStatus: 'info',
        responseMessage: 'Recognizing and verifying faces...'
      }, this.makeRequest)
    }
  }

  showSampleLink (status) {
    if (status) {
      return null
    }
    return (
      <a
        className='showSample'
        onClick={() => {
          this.setState({
            referenceImageUrl: 'https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png',
            queryImageUrl: 'https://base64.ai/static/content/features/facial-recognition/justin.jpg'
          }, this.verifyFaces)
        }}
      >Show sample
      </a>
    )
  }

  render () {
    const { responseMessage, responseStatus, referenceImageBase64, referenceImageUrl, queryImageUrl, queryImageBase64 } = this.state
    return (
      <View name='facial-verification-demo'>
        <Header name='header'>
          <h1 className='slogan'>Facial Verification AI</h1>
          <h2 className='introduction'>Replace manual user verification with our fast &amp; reliable AI</h2>
          <h3 className='introduction'>
            Instantly compare faces on documents and photos for automated ID verification. To try it out, upload an ID and a selfie.
            Base64.ai will detect faces on both images and determine if they match. Works with all IDs, driver licenses, passports, visas, and more!
          </h3>
        </Header>
        <Section name='output'>
          <div><StatusToEmoji status={responseStatus} message={responseMessage} />{this.showSampleLink(responseStatus)}</div>
        </Section>
        <Section
          name='input'
          tight={true}
        >
          <div className='uploadBox uploadBoxLeft'>
            <FileInput
              double={true}
              dropText='Upload a profile picture'
              multiple={false}
              indicator={true}
              accept={[
                'image/png',
                'image/jpg',
                'image/jpeg',
                'image/gif'
              ]}
              onDrop={(accepted, rejected, event) =>
                this.handleFiles(accepted, rejected, true)}
            />
            {referenceImageBase64 || referenceImageUrl ? <img src={referenceImageBase64 || referenceImageUrl} /> : null}
          </div>
          <div className='uploadBox'>
            <FileInput
              double={true}
              dropText='Upload a query photo'
              multiple={false}
              indicator={true}
              accept={[
                'image/png',
                'image/jpg',
                'image/jpeg',
                'image/gif'
              ]}
              onDrop={(accepted, rejected, event) =>
                this.handleFiles(accepted, rejected)}
            />
            {queryImageBase64 || queryImageUrl
              ? (
                  <figure ref={this.queryImageFigureRef}>
                    <canvas ref={this.queryImageCanvasRef} />
                    <img src={queryImageBase64 || queryImageUrl} ref={this.queryImageRef} />
                  </figure>
                )
              : null}
          </div>
        </Section>
      </View>
    )
  }

  drawRects () {
    const canvas = this.queryImageCanvasRef.current

    if (!canvas) {
      return
    }
    canvas.width = this.queryImageFigureRef.current.clientWidth
    canvas.height = this.queryImageFigureRef.current.clientHeight
    const scale = this.queryImageRef.current.naturalWidth / this.queryImageRef.current.clientWidth

    const images = this.state.verifiedFaceImages
    if (!images || images.length === 0) {
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      return
    }

    images.forEach(img => {
      const context = canvas.getContext('2d')
      context.beginPath()
      context.lineWidth = 3
      context.rect(
        img.left / scale,
        img.top / scale,
        img.width / scale,
        img.height / scale
      )
      context.setLineDash([5, 5])
      context.strokeStyle = '#00dddd'
      context.stroke()
    })
  }
}

// Export
export default FacialVerificationDemo
