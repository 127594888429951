// Modules
import { Component } from 'react'
import PropTypes from 'prop-types'
import document from 'global/document'

// Layout: View
class View extends Component {
  static propTypes = {
    name: PropTypes.string,
    identifier: PropTypes.string,
    flex: PropTypes.bool,
    children: PropTypes.node
  }

  static defaultProps = {
    identifier: '',
    flex: false
  }

  constructor (props) {
    super(props)
    this.configuration = {
      scrollDelay: 50,
      anchorResetDelay: 1000,
      navigationHeight: 80 // Approximate px value
    }
    this.scrollToAnchor = this.scrollToAnchor.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    setTimeout(() => this.scrollToAnchor(), this.configuration.scrollDelay)
  }

  componentDidUpdate (prevProps) {
    if (
      (this.props.name !== prevProps.name)
      || (this.props.identifier !== prevProps.identifier)
    ) window.scrollTo(0, 0)
    setTimeout(() => this.scrollToAnchor(), this.configuration.scrollDelay)
  }

  scrollToAnchor () {
    if (window.location.hash) {
      const element = document.querySelector(window.location.hash)
      if (element) {
        window.scrollBy({
          top: element.getBoundingClientRect().top - this.configuration.navigationHeight,
          behavior: 'smooth'
        })
        setTimeout(() => {
          window.history.pushState(
            null,
            null,
            window.location.href.split('#')[0]
          )
        }, this.configuration.anchorResetDelay)
      }
    }
  }

  render () {
    const { name, identifier, flex, children } = this.props
    return (
      <main
        data-view={name}
        data-identifier={identifier}
        className={
          (flex ? 'flex ' : '')
          + 'view'
        }
      >
        {children}
      </main>
    )
  }
}

export default View
