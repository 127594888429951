import { useFlows } from '~/providers/FlowContext'
import CreateFlow from './CreateFlow'

const FlowSettings = props => {
  const { flows, addFlow, setFlows, deleteFlow } = useFlows()

  return (
    <CreateFlow
      {...props}
      setFlows={setFlows}
      addFlow={addFlow}
      deleteFlow={deleteFlow}
      flows={flows}
    />
  )
}

export default FlowSettings
