// Modules
import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import PropTypes from 'prop-types'

// Content: BarChart
class BarChart extends React.Component {
  static propTypes = {
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    barOptions: PropTypes.object,
    colors: PropTypes.string,
    axisBottom: PropTypes.number,
    onClick: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.options = {
      axisRight: null,
      axisTop: null,
      axisLeft: {
        format: val => val.toLocaleString()
      },
      indexBy: 'label',
      isInteractive: true,
      labelSkipHeight: 10,
      labelSkipWidth: 10,
      labelTextColor: 'rgb(255, 255, 255)',
      valueFormat: val => val.toLocaleString(),
      useMesh: true,
      axisBottom: { tickRotation: -45 },
      margin: { top: 25, right: 80, bottom: 50, left: 80 },
      ...props.barOptions
    }

    this.axisBottom = {
      tickRotation: props.data?.length > 7 ? -45 : 0,
      ...props.axisBottom
    }

    this.keys = props.keys.length > 0 ? props.keys : ['value']
    this.colors = props.colors ? props.colors : '#00dddd'
    this.onClick = props.onClick
    this.data = props.data
  }

  render () {
    return (
      <ResponsiveBar
        {...this.options}
        colors={this.colors}
        keys={this.keys}
        data={this.data}
        axisBottom={this.axisBottom}
        onClick={this.onClick}
      />
    )
  }
}

// Exports
export default BarChart
