// Modules
import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import PropTypes from 'prop-types'

// Content: PieChartSection
class PieChart extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    pieOptions: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.options = {
      axisRight: null,
      axisTop: null,
      axisLeft: {
        format: val => val.toLocaleString()
      },
      indexBy: 'label',
      isInteractive: true,
      labelSkipHeight: 10,
      labelSkipWidth: 10,
      labelTextColor: 'rgb(61, 63, 71)',
      valueFormat: val => val.toLocaleString(),
      useMesh: true,
      borderWidth: 0,
      colors: item => item && item.data && item.data.color,
      cornerRadius: 4,
      enablePointLabel: true,
      innerRadius: 0.5,
      margin: { top: 35, right: 0, bottom: 35, left: 0 },
      padAngle: 1,
      radialLabel: item => `${item.label || item.id} (${item.value})`,
      radialLabelsLinkColor: { from: 'color' },
      radialLabelsLinkDiagonalLength: 25,
      radialLabelsLinkHorizontalLength: 15,
      radialLabelsLinkStrokeWidth: 1,
      radialLabelsLinkXOffset: 6,
      radialLabelsSkipAngle: 10,
      radialLabelsTextColor: 'rgb(61, 63, 71)',
      radialLabelsTextXOffset: 6,
      slicesLabelsSkipAngle: 10,
      arcLinkLabelsSkipAngle: 8,
      arcLabelsSkipAngle: 8,
      arcLabelsTextColor: 'rgb(255, 255, 255)',
      ...props.pieOptions

    }
    this.data = props.data
  }

  render () {
    return (
      <ResponsivePie
        {...this.options}
        data={this.data}
      />
    )
  }
}

// Exports
export default PieChart
