export default {
  // Solutions
  'covid-19-vaccine-card': ['vaccination', 'fleets', 'customer-satisfaction'],
  'background-check': ['end-to-end', 'accurately', 'seconds'],
  'gig-economy': ['operations-and-processing', 'identity-in-seconds', 'blur'],
  'banking-and-kyc': ['sensitive', 'errors', 'consistency'],
  'ride-sharing': ['customer-safety', 'get-users-approved', 'existing-platform'],
  'insurance': ['establish-proof', 'reduce', 'onboarding'],
  'airlines': ['security', 'background', 'extract'],
  'logistics': ['identify', 'autonomous', 'track'],
  'onboarding': ['save-time', 'apply', 'organize'],
  'uipath-rpa': ['boost', 'one', 'system'],
  'mobile-development': ['enhance', 'on-the-go', 'in-your-pocket'],
  'fraud-prevention': ['measures', 'reducing-accuracy', 'recognize'],
  'offline-air-gapped-networks': ['sensitive-data', 'offline', 'your-data'],
  'zapier': ['all-in-one', 'cycle', 'steps'],
  // Features
  'us-driver-license': ['processing-with-less', 'identity-in', 'spending-less'],
  'vehicle-registration': ['customers-identity', 'format', 'speed-up'],
  'vehicle-insurance': ['faster-processing', 'at-once', 'satisfaction'],
  'international-id': ['in-seconds', 'consistently', 'processing-costs'],
  'passport': ['languages', 'and-misinterpretations', 'operations'],
  'classification': ['instant-recognize', 'pre-built-models', 'unknown-models'],
  'document-generation': ['digitizing', 'avoid-omissions', 'digitize-forms'],
  'shape-verification': ['classify-shape', 'consistent-layout', 'reduce-errors-in-classification'],
  'multimedia': ['summarize-datapoints', 'detailed-answers', 'facilitate-impairment'],
  'photo-captioning': ['accesibility-for-hearing-impaired', 'index-efficiently', 'accessibility-for-education'],
  'iban': ['extract-banking-info', 'process-payment', 'no-storage'],
  'irs-tax-form': ['ensure-accuracy', 'minimize-discrepancy', 'compliant'],
  'geocoding': ['location-insight', 'improve-data-management', 'data-visualization'],
  'visa': ['customers', 'visa', 'avoid'],
  'receipt': ['data-fields', 'on-operations', 'receipt-data'],
  'invoice': ['less-manual', 'semi-structured', 'integrate-validation'],
  'checkbox': ['checkbox-validation', 'handle-checkboxes', 'associate-checkboxes'],
  'custom-taxonomy': ['normalize', 'eliminate-erros', 'avoid-inaccuracies'],
  'text-to-speech': ['text-to-speech', 'enable-impaired', 'multiple-formats'],
  'speech-to-text': ['inclusivity', 'educational', 'hearing-impairment'],
  'questions': ['use-questions', 'get-answers-from-any-document', 'automate-with-qna'],
  'check': ['data-fields', 'your-business', 'all-data-points'],
  'acord': ['certification', 'thousands-of-hours', 'quick-onboarding'],
  'sbc': ['days-long-delays', 'your-patients', 'cycle-time'],
  'license-plate': ['your-security', 'car', 'money-on'],
  'shipping-container': ['in-seconds', 'processing-with-less', 'shipments'],
  'file-ai': ['higher', 'quick', 'money'],
  'form-ai': ['quick', 'prone', 'higher'],
  'handwritten-document': ['unstructured', 'capture', 'misinterpretation'],
  'mobile-sdk': ['your-user', 'on-the-go', 'in-your-pocket'],
  'facial-recognition': ['measures', 'reducing-accuracy', 'recognize'],
  'signature-ai': ['extract-signature', 'match-signature', 'compatible'],
  'facial-ai': ['facial-recognition', 'id-scan', 'multiple-people'],
  'hitl': ['document-validation', 'validate-multilang', 'always-available'],
  'cms1500': ['high-confidence', 'hipaa', 'always-available'],
  'osha-forms': ['safety-regulations', 'safety-management', 'workplace-safety'],
  'bill-of-lading': ['validate-bill-of-lading', 'limit-human-involvement', 'efficient-data'],
  'customs-declaration': ['reduce-waiting-time', 'track-processed-documents', 'prevent-unqualified-travelers'],
  'smart-health-card': ['streamline-healthcare', 'smart-health', 'smart-health-fraud'],
  'safety-datasheet': ['safety-glasses', 'shorten-review', 'validate-various-languages'],
  'semantic-ai': ['save-time-building', 'object-detection', 'no-training'],
  'document-ai': ['ai-processing', 'system', 'capture'],
  'no-code-ai': ['integration-code', 'coding-on-laptop', 'mobile-desktop-devices'],
  'digital-signatures': ['document-extraction', 'signatureAI-extract', 'data-search'],
  'table-ai': ['analyzing', 'document-format', 'processing-accuracy'],
  'checkbox-ai': ['recognize-checkboxes', 'process-checkboxes', 'no-template'],
  'entity-ai': ['multi-language', 'no-template', 'data-search-entity'],
  'barcode-ai': ['boost', 'processing-more', 'track-information'],
  'watermark-ai': ['every-data-point', 'no-template', 'every-size-shape-form'],
  'nixie-label-ai': ['validate-compact', 'process-more', 'high-accuracy'],
  'bank-card': ['certified', 'id-cards', 'validated-id'],
  'us-green-card': ['card-approval', 'card-validation', 'S.S-card'],
  'us-social-security-card': ['verify-sensitive', 'id-validation', 'bank-level-security'],
  'remittance': ['prone', 'improve-workflow', 'time'],
  'cloud-deployment': ['convenience', 'scalable', 'reliable'],
  'image-quality-ai': ['recognize-imperfections', 'avoid-low-quality', 'eliminate-erronous-documents'],
  'segmentation-ai': ['multi-document', 'classify', 'high-accuracy'],
  'redaction-ai': ['sensitive-data', 'secure-data', 'security'],
  'real-id-enhanced-id': ['secure-data', 'avoid-invalid', 'id-validation-approved']
}
