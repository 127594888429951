// Modules
import { Component, createRef } from 'react'

// Layout
import View from '~/layout/view/View'
import Section from '~/layout/section/Section'

// Interface
import Form from '~/interface/form/Form'
import Link from '~/interface/link/Link'
import ThirdPartAuthentication from '~/interface/third-part-authentication/third-part-authentication'

// Constants
import { Messages } from '~/constants'

// Helpers
import { request } from '~/helpers/request'
import { reCaptcha } from '~/helpers/authentication'
import TeaserSection from '~/content/teaser-section/TeaserSection'
import PropTypes from 'prop-types'
import { triggerTrackingEvent } from '../../utilities/tracker'
import window from 'global'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { isWorkingOffline } from '../../context/environment'
import { RegEx } from '../../constants/RegEx'
import { NavigationPrompt } from '../../interface/navigation-prompt/NavigationPrompt'
import clsx from 'clsx'
import { AppButton } from '../../interface/app-button/AppButton'

// View: Signup
class Signup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      completed: false,
      message: '',
      isSubmitted: true,
      user: this.props.user,
      phoneNumber: '',
      submitHandler: () => { },
      loading: false
    }
    this.form = createRef()
    this.signup = this.signup.bind(this)
  }

  componentDidMount () {
    if (!isWorkingOffline()) {
      this.form.current.setInput('givenName', new URLSearchParams(window.location.search).get('givenName'))
      this.form.current.setInput('familyName', new URLSearchParams(window.location.search).get('familyName'))
      this.form.current.setInput('companyName', new URLSearchParams(window.location.search).get('companyName'))
      this.form.current.setInput('email', new URLSearchParams(window.location.search).get('email'))
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user
      })
    }
  }

  signup (data, errorCallback) {
    this.setState({ loading: true })
    request({
      endpoint: '/auth/signup',
      body: data
    }, (error, response) => {
      if (error) {
        errorCallback(error?.message || error.toString())
      } else {
        triggerTrackingEvent('signup-completed-email')
        this.setState({
          completed: true,
          isSubmitted: true,
          message: `Welcome, ${data.givenName}!`
        })
      }
      this.setState({ loading: false })
    })
  }

  render () {
    const {
      completed,
      user,
      message
    } = this.state
    const isAdmin = user?.tags?.includes('admin')
    return (
      <View name='signup' flex={true}>
        <TeaserSection />
        {
          completed
            ? isWorkingOffline()
              ? (
                  <Section
                    name='finalization'
                    align='center'
                    flex={true}
                  >
                    <h1>{message}</h1>
                    <p>The user is created successfully.</p>
                  </Section>
                )
              : (
                  <Section
                    name='finalization'
                    align='center'
                    flex={true}
                  >
                    <h1>{message}</h1>
                    <p>We have sent you a verification email.</p>
                    <p>Please click the activation link in the email to continue.</p>
                  </Section>
                )
            : !isAdmin && isWorkingOffline()
                ? (
                    <Section
                      name='finalization'
                      align='center'
                      flex={true}
                    >
                      <h1>Unauthorized</h1>
                      <p>Please login as an admin.</p>
                    </Section>
                  )
                : (
                    <Section
                      name='application'
                      flex={true}
                    >
                      <NavigationPrompt messageFunction={() => !this.state.isSubmitted ? Messages.CHANGES_NOT_SAVED : true} />
                      <Form
                        ref={this.form}
                        onDataUpdate={() => {
                          this.setState({ isSubmitted: false })
                        }}
                        onDataSubmit={(data, errorCallback) => {
                          data.verificationCode = this.props.verificationCode
                          data.phoneNumber = this.state.phoneNumber || ''
                          if (!isValidPhoneNumber(data.phoneNumber) && !isWorkingOffline()) {
                            return errorCallback('Please enter a valid phone number')
                          }
                          reCaptcha('signup', token => {
                            data.token = token
                            this.signup(data, errorCallback)
                          })
                        }}
                        onSubmitHandler={submitHandler => {
                          this.setState({ submitHandler })
                        }}
                        title='Sign up for Base64'
                      >
                        <Form.Input
                          type='text'
                          name='givenName'
                          reference='Given name'
                          placeholder='Given name'
                          autoComplete='off'
                          validator={{
                            pattern: RegEx.ALPHANUMERIC_OR_EMAIL,
                            minimumLength: 1
                          }}
                        />
                        <Form.Input
                          type='text'
                          name='familyName'
                          reference='Family name'
                          placeholder='Family name'
                          autoComplete='off'
                          validator={{
                            pattern: RegEx.ALPHANUMERIC_OR_EMAIL,
                            minimumLength: 1
                          }}
                        />
                        <Form.Input
                          type='text'
                          name='companyName'
                          reference='Company name'
                          placeholder='Company name'
                          hidden={isWorkingOffline()}
                          autoComplete='off'
                        />
                        <Form.Input
                          type='email'
                          name='email'
                          reference='Work email'
                          placeholder='Enter your work email'
                          autoComplete='off'
                          validator={{
                            pattern: RegEx.EMAIL,
                            maximumLength: 255
                          }}
                        />
                        <Form.PhoneInput
                          className={clsx('phone-input', isWorkingOffline() && 'hidden')}
                          name='phoneNumber'
                          reference='Phone number'
                          placeholder='Enter your phone number'
                          addInternationalOption={false}
                          defaultCountry='US'
                          onChange={e => {
                            this.setState({ phoneNumber: e })
                          }}
                        />
                        <Form.Input
                          type='password'
                          name='password'
                          reference='Password'
                          placeholder='Select your password'
                          autoComplete='off'
                          validator={{
                            minimumLength: 8
                          }}
                        />
                        <Form.Checkbox
                          type='checkbox'
                          name='isAdmin'
                          reference='Grant admin access'
                          placeholder='Grant admin access'
                          hidden={!isWorkingOffline()}
                        />
                        <AppButton
                          className='primary-cta'
                          loading={this.state.loading}
                          onClick={() => this.state.submitHandler()}
                        >
                          Sign up
                        </AppButton>
                        <ThirdPartAuthentication />
                        <small>
                          By clicking “SIGN UP”, you agree to our <a href='/terms-of-service'>Terms of Service</a> and acknowledge you have read the <a href='/privacy-policy'>Privacy Policy</a>.
                        </small>
                      </Form>
                      <Link
                        to='/login'
                        className='external'
                      >
                        Already signed up? Log in.
                      </Link>
                    </Section>
                  )
        }
      </View>
    )
  }
}

Signup.propTypes = {
  verificationCode: PropTypes.string,
  user: PropTypes.object
}

// Export
export default Signup
