import { Component } from 'react'
import PropTypes from 'prop-types'
import Section from '../../layout/section/Section'
import Checkbox from '../../interface/checkbox/Checkbox'
import window from 'global'

class CloneComponent extends Component {
  static propTypes = {
    component: PropTypes.object,
    onChange: PropTypes.func
  }

  static defaultProps = {
    component: {}
  }

  constructor (props) {
    super(props)
    this.state = {
      shareWithSamePeople: false,
      newName: 'Copy of ' + this.props.component.name
    }
  }

  onCheckBoxChange (data) {
    this.setState({
      [data.value]: data.checked
    }, () => this.props.onChange(this.state))
  }

  render () {
    const isMobile = window?.innerWidth < 767
    const isCustomModelClone = !!this.props.component?.isCustomModel
    const width = isMobile ? '55vw' : '25vw'
    return (
      <div className='flow-input' data-view='flow' style={{ background: 'none' }}>
        <Section style={{ width, padding: 'initial', marginTop: '-2rem', marginBottom: '-1rem' }}>
          <h2 className='title'>Name</h2>
          <input
            type='text'
            className='basic fullscreen-input'
            value={this.state.newName}
            onChange={e => {
              const target = e.target.value
              this.setState({ newName: target }, () => {
                this.props.onChange(this.state)
              })
            }}
          />
          <br />
          <br />
          {/* FLOW FOLDER SELECTION
        <Select
          isMulti={false}
          classNamePrefix='react-select'
          className='react-select'
          value={this.state.selectedFlow}
          options={this.props.flows}
          closeMenuOnSelect={true}
          placeholder="Select a flow"
          noOptionsMessage={() => 'No flow found'}
          menuPlacement = "auto"
          onChange={flow => {
            this.setState({
              selectedFlowID: flow.value
            }, () => { this.props.onChange(this.state) })
          }}/>
        <br/>
        */}
          <Checkbox
            showTitle={false}
            key={1}
            className='big left'
            placeholder={`Share the ${isCustomModelClone ? 'custom model' : 'Flow'} with the same people.`}
            value='shareWithSamePeople'
            onChange={e => {
              this.onCheckBoxChange(e.target)
            }}
            checked={this.state.shareWithSamePeople}
          />
          <br />
          {
            !isCustomModelClone
              ? <p>This action duplicates the Flow settings only. To include integrations, download and attach them to the new Flow.</p>
              : <p>This action duplicates the Custom Model settings only. To include post-processing integrations, download and attach them to the new custom model.</p>
          }
          <br />
        </Section>
      </div>
    )
  }
}

export default CloneComponent
