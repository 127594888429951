// Modules
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { AppButton } from '../app-button/AppButton'

const gateKeeper = (props, accepted, rejectedFiles, event) => {
  if (props.requireLogin) {
    props.context.displayModal({
      title: 'Login required',
      content: 'Please login to try the AI with your files, or use the provided sample.',
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: 'Login',
          action: () => {
            props.context.redirect(`/login?next=${props.next || ''}`)
          }
        }
      ]
    })
    return false
  }
  return props.onDrop(accepted, rejectedFiles, event)
}

// Interface: File Input
const FileInput = props => (
  <div
    title={props.title}
    className={
      (props.indicator ? 'indicator ' : '')
      + 'file-input'
    }
  >
    <Dropzone
      onDrop={(accepted, rejectedFiles, event) => gateKeeper(props, accepted, rejectedFiles, event)}
      accept={props.accept}
      multiple={props.multiple}
      disabled={props.disabled}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive
      }) => (
        <div>
          {props.dragAndDropButton
            ? (
                <div
                  className={
                    (isDragActive ? 'active ' : '')
                    + (props.single ? 'single ' : '')
                    + 'root'
                  }
                  style={{ ...props.style }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {props.dropText && <small><b>{props.dropText}</b></small>}
                </div>
              )
            : (
                <AppButton {...getRootProps()} className='secondary-cta large w-full' tabIndex='0'>
                  <input {...getInputProps()} />
                  {props.dropText}
                </AppButton>
              )}
        </div>

      )}
    </Dropzone>
  </div>
)

FileInput.propTypes = {
  context: PropTypes.object,
  next: PropTypes.string,
  requireLogin: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  indicator: PropTypes.bool,
  dropText: PropTypes.string,
  double: PropTypes.bool,
  single: PropTypes.bool,
  style: PropTypes.object,
  dragAndDropButton: PropTypes.bool,
  title: PropTypes.string
}

FileInput.defaultProps = {
  multiple: true,
  disabled: false,
  indicator: true,
  dragAndDropButton: true,
  dropText: 'Upload files here'
}

// Export
export default FileInput
