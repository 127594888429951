// Modules
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Interface: SocialIcon
class SocialIcon extends React.Component {
  render () {
    const color = this.props.color ? '-' + this.props.color : ''
    return (
      <a className='social-icon' target='_blank' href={this.props.href} rel='noopener noreferrer' title={this.props.title}>
        <img className={clsx({ 'green-overlay': this.props.color })} src={'/static/images/icons/' + this.props.media + color + '-icon.png'} alt={this.props.media + ' social media icon'} />
      </a>
    )
  }
}

SocialIcon.propTypes = {
  media: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string
}

SocialIcon.defaultProps = {
  color: ''
}

// Export
export default SocialIcon
