export default {
  default: {
    title: 'Learn how innovative companies use our AI',
    paragraph: 'Our customers save thousands of employee hours per month using our AI to process even the most complex documents in seconds with 99.7% accuracy.',
    buttonText: 'READ CASE STUDIES',
    reference: '/case-studies'
  },
  sbc: {
    title: 'Learn how innovative companies use our AI',
    paragraph: 'This healthcare leader saved thousands of employee hours per month and decreased SBC form processing time from days to seconds at 99.7% accuracy.',
    buttonText: 'READ CASE STUDY',
    reference: '/case-studies/Case-Study-Healthcare-Innovator-Sets-New-Industry-Standard-with-Cutting-Edge-AI-7a5ade64'
  },
  acord: {
    title: 'Learn how innovative insurance companies use our AI',
    paragraph: 'Our insurance customers save thousands of employee hours per month using our AI to process complex ACORD documents in seconds with 99.7% accuracy.',
    buttonText: 'READ CASE STUDY',
    reference: '/case-studies/Processing-Insurance-Forms-Accurately-500x-Faster-With-AI-2dba5690'
  }
}
