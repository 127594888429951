import { Component } from 'react'
import PropTypes from 'prop-types'
import ModelBox from './ModelBox'
import TextToSpeechBox from './TextToSpeechBox'
import FieldBox from './FieldBox'
import ObjectDetectionBox from './ObjectDetectionBox'
import PropertiesBox from './PropertiesBox'
import TableBox from './TableBox'
import OCRBox from './OCRBox'
import MaterialIcon from '../material-icon/MaterialIcon'
import { toCamelCase } from '../../utilities/format'
import { request } from '~/helpers/request'
import Loading from '../loading/Loading'
import { hasFeature } from '../../context/environment'
import TextareaAutosize from 'react-textarea-autosize'
import QuestionBox from '../question-box/QuestionBox'
import FlowSelector from './FlowSelector'
import QuestionAnswerContainer from './QuestionAnswerContainer'
import { getSourceFromBase64Audio } from '../../utilities/object'
import { v4 as uuid } from 'uuid'
import { displayQuestionAddingModal } from './DisplayQuestionAddingModal'

class DataExtractionResult extends Component {
  static propTypes = {
    result: PropTypes.object,
    resultIndex: PropTypes.number,
    contextProvider: PropTypes.object,
    context: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      result: this.props.result,
      newFieldKey: '',
      newFieldValue: '',
      queryDocument: '',
      questionAnswerResponses: [],
      selectedTargets: [],
      hideOtherTargets: false,
      followUpResponses: {},
      selectedFlowID: '',
      respondedQuestions: [],
      voiceAllowedNode: undefined
    }

    this.questionAddTargetArray = ['Result', 'Flow', 'Model']

    this.renderFields = this.renderFields.bind(this)
    this.renderTextToSpeech = this.renderTextToSpeech.bind(this)
    this.renderObjectDetection = this.renderObjectDetection.bind(this)
    this.renderProperties = this.renderProperties.bind(this)
    this.renderTables = this.renderTables.bind(this)
    this.renderAddNewFieldBox = this.renderAddNewFieldBox.bind(this)
    this.renderModelBox = this.renderModelBox.bind(this)
    this.renderQuestionAnswerBox = this.renderQuestionAnswerFeature.bind(this)
    this.deleteResult = this.deleteResult.bind(this)
    this.closeAddNewFieldPopup = this.closeAddNewFieldPopup.bind(this)
    this.queryDocumentWithQuestion = this.queryDocumentWithQuestion.bind(this)
    this.renderEcho = this.renderEcho.bind(this)
    this.displayFieldAddingModal = this.displayFieldAddingModal.bind(this)
    this.customSetState = this.customSetState.bind(this)
  }

  closeAddNewFieldPopup () {
    this.setState({
      isQuestionAnswer: false,
      questionAdded: false,
      newFieldKey: '',
      newFieldValue: '',
      originalQuestion: undefined
    })
  }

  deleteResult () {
    const confirmDelete = window.confirm('Are you sure you want to delete this result?')
    if (confirmDelete) {
      this.props.contextProvider.deleteResult(this.props.resultIndex)
    }
  }

  renderFields () {
    return (this.state.result.fields && Object.keys(this.state.result.fields)
      .map((field, index) => {
        if (this.state.result.fields[field].isDeleted) {
          return null
        }
        return (
          <FieldBox
            key={index}
            field={this.state.result.fields[field]}
            fieldKey={field}
            contextProvider={this.props.contextProvider}
            context={this.props.context}
            resultIndex={this.props.resultIndex}
          />
        )
      })).filter(s => s)
  }

  renderEcho () {
    return this.state.result.echo !== undefined
      ? (
          <FieldBox
            field={{
              key: 'Echo',
              value: typeof this.state.result.echo === 'object' ? JSON.stringify(this.state.result.echo) : this.state.result.echo
            }}
            fieldKey='echo-parameter'
            contextProvider={this.props.contextProvider}
            context={this.props.context}
            resultIndex={this.props.resultIndex}
          />
        )
      : <></>
  }

  renderTextToSpeech () {
    if (this.state.result?.features?.textToSpeech && this.state.result?.features?.textToSpeech !== 'deleted') {
      return (
        <TextToSpeechBox
          audio={this.state.result.features?.textToSpeech}
          contextProvider={this.props.contextProvider}
          context={this.props.context}
        />
      )
    }
  }

  renderObjectDetection (type, title) {
    if (!this.state.result.features[type]) {
      return
    }
    return this.state.result.features[type].map((object, index) => {
      if (object.isDeleted) {
        return
      }
      return (
        <ObjectDetectionBox
          key={index}
          type={type}
          title={title}
          object={object}
          objectIndex={index}
          context={this.props.context}
          contextProvider={this.props.contextProvider}
        />
      )
    }).filter(s => s)
  }

  renderProperties (type, title) {
    if (this.state.result.features.properties[type] === undefined) {
      return null
    }
    return (
      <PropertiesBox
        title={title}
        property={type}
        value={this.state.result.features.properties[type]}
        contextProvider={this.props.contextProvider}
        context={this.props.context}
      />
    )
  }

  renderTables () {
    if (!this.state.result.features?.dom?.pages) {
      return null
    }
    const tables = []
    this.state.result.features?.dom?.pages.forEach((page, pageIndex) => {
      if (!page.tables?.length) {
        return
      }
      page.tables.forEach((table, tableIndex) => {
        tables.push({ table, pageIndex, tableIndex })
      })
    })
    return (
      <TableBox
        tables={tables}
        context={this.props.context}
        contextProvider={this.props.contextProvider}
      />
    )
  }

  renderDigitalSignatures () {
    if (!this.state.result.features?.digitalSignatures?.length) {
      return null
    }
    const digitalSignatures = []
    this.state.result.features?.digitalSignatures.forEach((digitalSignature, index) => {
      const rows = []
      rows.push({
        cells: [{
          text: 'Key',
          confidence: 1
        },
        {
          text: 'Value',
          confidence: 1
        }
        ],
        confidence: 1
      })

      Object.keys(digitalSignature).forEach(key => {
        let value = digitalSignature[key]
        if (typeof value === 'boolean') {
          value = value.toString()
        }
        rows.push({
          cells: [{
            text: key,
            confidence: 1
          }, {
            text: value,
            confidence: 1
          }],
          confidence: 1
        })
      })
      const table = {
        rows,
        confidence: 1
      }
      digitalSignatures.push({ table, pageIndex: 0, tableIndex: index })
    })
    return (
      <TableBox
        tables={digitalSignatures}
        context={this.props.context}
        contextProvider={this.props.contextProvider}
        isEditDisabled={true}
        header='Digital Signatures'
      />
    )
  }

  renderOCR () {
    return (
      <OCRBox
        ocr={this.state.result.ocr}
        contextProvider={this.props.contextProvider}
        context={this.props.context}
      />
    )
  }

  renderAddNewFieldBox () {
    return (
      <div
        className='add-new-field result-box-background'
        onClick={() => {
          this.displayFieldAddingModal()
        }}
      >
        <MaterialIcon name='add_circle' />
        <p>Add new field</p>
      </div>
    )
  }

  renderModelBox () {
    return (
      <ModelBox
        contextProvider={this.props.contextProvider}
        context={this.props.context}
        model={this.state.result.model}
        resultIndex={this.props.resultIndex}
      />
    )
  }

  // For a top level (brand new) question, parentIndex and childIndex are undefined.
  // For a follow up question, parentIndex is defined and childIndex is undefined.
  // For a retry operation, parentIndex is defined. If it's a follow up question, childIndex is defined too.
  queryDocumentWithQuestion (question, { parentIndex, childIndex, isVoiceInput, retryCount }) {
    const { resultUuid } = this.props.context
    const requestUrl = `/result/ask/${resultUuid}`
    const requestUUID = uuid()
    const flowUUID = (parentIndex !== undefined && retryCount === undefined) ? this.state.questionAnswerResponses[parentIndex]?.flowUUID : this.state.selectedFlowID

    // if the question is already in the query document responses, don't query again. However, if it is retry operation, proceed.
    if (!retryCount && this.state.questionAnswerResponses.some(
      (response, index) =>
        index === parentIndex
          ? (response.question === question && response.flowUUID === flowUUID) || response.followUpQuestions?.some(q => q.question === question && q.flowUUID === flowUUID)
          : (response.question === question && response.flowUUID === flowUUID)
    )) {
      return
    }

    const questionAnswerResponses = [...this.state.questionAnswerResponses]
    let chatHistory
    let questionToModify
    let oldAnswer

    if (retryCount) {
      if (childIndex !== undefined) {
        questionToModify = questionAnswerResponses[parentIndex].followUpQuestions[childIndex]
      } else {
        questionToModify = questionAnswerResponses[parentIndex]
      }

      oldAnswer = questionToModify.answer
      questionToModify.retryCount = retryCount
      questionToModify.answer = undefined
      isVoiceInput && this.setState({ voiceAllowedNode: { parentIndex, childIndex } })
    } else if (parentIndex === undefined) {
      questionAnswerResponses.unshift({ question, requestUUID })
      isVoiceInput && this.setState({ voiceAllowedNode: { parentIndex: 0 } })
    } else {
      const parent = questionAnswerResponses[parentIndex]
      parent.followUpQuestions = parent.followUpQuestions || []
      parent.followUpQuestions.push({ question })
      isVoiceInput && this.setState({ voiceAllowedNode: { parentIndex, childIndex: parent.followUpQuestions.length - 1 } })
    }

    if (parentIndex !== undefined) {
      const parent = questionAnswerResponses[parentIndex]
      parent.followUpQuestions = parent.followUpQuestions || []

      if (retryCount) {
        chatHistory = childIndex === undefined ? {} : { [parent.question]: parent.answer }
        childIndex !== undefined && parent.followUpQuestions.forEach((q, i) => {
          if (i >= childIndex) {
            return
          }
          if (q.answer) {
            chatHistory[q.question] = q.answer
          }
        })
      } else {
        chatHistory = { [parent.question]: parent.answer }
        parent.followUpQuestions.forEach(q => {
          if (q.answer) {
            chatHistory[q.question] = q.answer
          }
        })
      }
    }

    const body = {
      flowUUID: questionToModify?.flowUUID || flowUUID,
      chatHistory,
      invalidAnswers: Array.from(questionToModify?.invalidAnswers || new Set()),
      modality: 'multimodal',
      retryCounter: retryCount,
      sources: questionAnswerResponses?.[parentIndex]?.sources || questionToModify?.sources,
      textToSpeech: isVoiceInput,
      questions: [question]
    }
    if (childIndex === undefined && retryCount) {
      delete body.sources
    }
    Object.keys(body).forEach(key => {
      if (!body[key] || (Array.isArray(body[key]) && !body[key].length)) {
        delete body[key]
      }
    })
    this.setState({ questionAnswerResponses })
    request({
      endpoint: requestUrl,
      body
    }, (error, httpResponse) => {
      if (error) {
        this.props.contextProvider.displayModal({
          isForbiddenToClickOutside: true,
          title: 'Question error',
          content: `${error}`,
          options: [
            {
              content: 'Close',
              intent: 'negative'
            }
          ]
        })
        const responses = [...this.state.questionAnswerResponses]
        // const queryObjectIndex = responses.findIndex(q => q.question === question)
        // responses.splice(queryObjectIndex, 1)
        if (retryCount) {
          questionToModify.answer = oldAnswer
        } else if (parentIndex === undefined) {
          responses.splice(0, 1)
        } else if (childIndex === undefined) {
          const parent = questionAnswerResponses[parentIndex]
          parent.followUpQuestions.pop()
        } else {
          const parent = questionAnswerResponses[parentIndex]
          parent.followUpQuestions.pop()
        }

        this.setState({ questionAnswerResponses: responses })

        return
      }
      // find the query object and replace it with the response
      if (!(httpResponse?.[0]?.answer || httpResponse?.answer)) {
        httpResponse = [{ answer: 'Not found.' }]
      }
      const response = {
        question,
        answer: httpResponse?.[0]?.answer || httpResponse?.answer,
        location: httpResponse?.[0]?.location || httpResponse?.location,
        suggestedQuestions: httpResponse?.[0]?.suggestedQuestions || httpResponse?.suggestedQuestions,
        textToSpeech: httpResponse?.[0]?.textToSpeech || httpResponse?.textToSpeech,
        sources: httpResponse?.[0]?.sources || httpResponse?.sources,
        retryCount,
        flowUUID
      }

      if (response?.textToSpeech) {
        response.audioFileSource = getSourceFromBase64Audio(response?.textToSpeech)
      }

      response.invalidAnswers = new Set([response.answer])
      if (retryCount) {
        questionToModify?.invalidAnswers?.forEach(pq => response.invalidAnswers.add(pq))
        response.sources = response.sources || questionToModify?.sources
        Object.assign(questionToModify, response)
      } else if (parentIndex === undefined) {
        const queryDocumentResponse = questionAnswerResponses.find(r => r.requestUUID === requestUUID)
        if (!queryDocumentResponse) {
          console.error('Response object not found')
        } else {
          queryDocumentResponse.invalidAnswers?.forEach(pq => response.invalidAnswers.add(pq))
          Object.assign(queryDocumentResponse, response)
        }
      } else {
        const parent = questionAnswerResponses[parentIndex]
        parent.followUpQuestions[parent.followUpQuestions.length - 1]?.invalidAnswers?.forEach(pq => response.invalidAnswers.add(pq))
        response.sources = response.sources || parent.sources
        Object.assign(parent.followUpQuestions[parent.followUpQuestions.length - 1], response)
      }

      this.props.contextProvider.setState({
        selectionContext: 'field',
        selectionDomId: null,
        selectionLocation: null,
        selectionRelativeIndex: null,
        isSelected: false
      })

      this.setState({ questionAnswerResponses })

      // TODO: listen to the audio-loaded event and update the state
      setTimeout(() => {
        this.setState({ voiceAllowedNode: undefined })
      }, 100)
    })
  }

  displayFieldAddingModal () {
    this.props.contextProvider.displayModal({
      title: 'Add new field',
      content: (
        <div className='add-new-field-input-container'>
          <label className='popup-label'>
            Enter field name:
            <br />
            <input
              className='hitl-input'
              value={this.state.newFieldKey}
              autoFocus={true}
              onChange={e => {
                const target = e.target.value
                this.setState({ newFieldKey: target }, this.displayFieldAddingModal)
              }}
            />
          </label>
          <label className='popup-label'>
            Enter value:
            <br />
            <TextareaAutosize
              maxRows={10}
              className='hitl-input value-box'
              value={this.state.newFieldValue}
              onChange={e => {
                const target = e.target.value
                this.setState({ newFieldValue: target }, this.displayFieldAddingModal)
              }}
            />
          </label>
        </div>
      ),
      close: () => {
        this.setState({
          newFieldKey: '',
          newFieldValue: ''
        })
      },
      options: [
        {
          content: 'Cancel',
          intent: 'negative',
          action: () => {
            this.setState({
              newFieldKey: '',
              newFieldValue: ''
            })
          }
        },
        {
          content: 'Add',
          intent: 'positive',
          action: () => {
            if (!this.state.newFieldKey) {
              return alert('Key must be valid')
            }
            this.props.contextProvider.updateResult(toCamelCase(this.state.newFieldKey), {
              key: this.state.newFieldKey,
              value: this.state.newFieldValue,
              confidence: 1,
              type: 'custom',
              isValid: true
            }, { resultIndex: this.props.resultIndex }, () => {
              const searchField = `result${this.props.resultIndex}${toCamelCase(this.state.newFieldKey)}`
              const resultViewerContainerDom = document.querySelector('.result-viewer-container')
              if (!resultViewerContainerDom) {
                return
              }
              this.setState({
                newFieldKey: '',
                newFieldValue: ''
              })
              const newFieldsDom = document.getElementById(searchField)
              if (!newFieldsDom) {
                return
              }
              const newFieldRelativeTopPosition = Math.abs(resultViewerContainerDom.offsetTop - newFieldsDom.offsetTop)
              resultViewerContainerDom.scrollTo(0, newFieldRelativeTopPosition)
              const scrollPosition = newFieldsDom.offsetTop - resultViewerContainerDom.scrollTop
              window.scrollTo({
                left: 0,
                top: scrollPosition - 100,
                behavior: 'smooth'
              })
            })
          }
        }
      ],
      isClosable: true
    })
  }

  customSetState (data) {
    this.setState(data, () => displayQuestionAddingModal(this.props, this.state, this.customSetState))
  }

  drawLine (questionData, domId) {
    const location = questionData?.location || questionData?.sources?.[0]?.location
    const sourceType = questionData?.sources?.[0]?.type

    if (!location || (sourceType && sourceType !== 'result')) {
      return this.props.contextProvider.setState({
        selectionContext: 'field',
        selectionDomId: null,
        selectionLocation: null,
        selectionRelativeIndex: null,
        isSelected: false
      })
    }

    this.props.contextProvider.setState({
      selectionContext: 'field',
      selectionDomId: domId,
      selectionLocation: location,
      selectionRelativeIndex: location?.pageNumber - this.props.context.startPage,
      isSelected: true
    })
  }

  renderQuestionAnswerFeature () {
    if (!hasFeature('questionAnswer')) {
      return null
    }
    // if not first result then don't show question answer box
    if (this.props.resultIndex !== 0) {
      return null
    }
    return (
      <div className='result-container mb-4'>
        <div className='result-box-background' style={{ backgroundColor: 'transparent' }}>
          <FlowSelector onChange={change => {
            this.setState({
              selectedFlowID: change.value
            })
          }}
          />
          <QuestionBox
            inputAutoFocus
            noteOnTyping='Ask a question by pressing Enter or clicking send.'
            onResult={(question, source) => {
              const isVoiceInput = source === 'voice'
              this.setState({ queryDocument: question })
              this.queryDocumentWithQuestion(question, { isVoiceInput })
            }}
          />
        </div>
        {this.state.questionAnswerResponses.map((response, parentIndex) => {
          const domId = 'qa-' + parentIndex
          const parentHasUnansweredFollowUpQuestion = response.followUpQuestions?.find(q => !q.answer)
          const parentHasFollowUpQuestions = !!response.followUpQuestions?.length
          return (
            <div
              className='result-box-background pb-4'
              key={parentIndex}
              onClick={() => {
                if (!this.state.questionAnswerResponses[parentIndex].followUpQuestions?.length) {
                  this.drawLine(response, domId)
                }
              }}
            >
              {response.answer !== undefined
                ? (
                    <>
                      <QuestionAnswerContainer
                        id={domId}
                        dialog={response}
                        onAdd={() => {
                          this.setState({
                            newFieldKey: response.question,
                            newFieldValue: response.answer,
                            originalQuestion: response.question,
                            isQuestionAnswer: true,
                            questionAddTarget: 0,
                            questionAnswerResponseWillAdd: this.state.questionAnswerResponses[parentIndex],
                            selectedTargets: ['Result'],
                            questionAddedToFlow: false,
                            questionAddedToModel: false,
                            addingQuestionStatus: {}
                          }, () => displayQuestionAddingModal(this.props, this.state, this.customSetState))
                        }}
                        onClick={() => {
                          this.drawLine(response, domId)
                        }}
                        onRemove={() => {
                          const questionAnswerResponses = [...this.state.questionAnswerResponses]
                          questionAnswerResponses.splice(parentIndex, 1)
                          this.setState({ questionAnswerResponses })
                        }}
                        onRetry={() => {
                          this.queryDocumentWithQuestion(response.question, { parentIndex, retryCount: (response.retryCount ?? 0) + 1, isVoiceInput: !!response?.textToSpeech })
                        }}
                        retryCount={response.retryCount}
                        autoPlay={this.state.voiceAllowedNode?.parentIndex === parentIndex && this.state.voiceAllowedNode?.childIndex === undefined}
                      />
                      {parentHasFollowUpQuestions && response.followUpQuestions.map?.((q, childIndex) => (
                        <QuestionAnswerContainer
                          id={`qa-${parentIndex}-${childIndex}`}
                          key={`${parentIndex}-${childIndex}`}
                          dialog={q}
                          onAdd={() => {
                            this.setState({
                              newFieldKey: q.question,
                              newFieldValue: q.answer,
                              originalQuestion: q.question,
                              isQuestionAnswer: true,
                              questionAddTarget: 0,
                              selectedTargets: ['Result'],
                              hideOtherTargets: true,
                              addingQuestionStatus: {}
                            }, () => displayQuestionAddingModal(this.props, this.state, this.customSetState))
                          }}
                          onRemove={() => {
                            const questionAnswerResponses = [...this.state.questionAnswerResponses]
                            questionAnswerResponses[parentIndex].followUpQuestions.splice(childIndex, 1)
                            this.setState({ questionAnswerResponses }, () => {
                              this.drawLine()
                            })
                          }}
                          onRetry={() => {
                            const opts = {
                              parentIndex,
                              childIndex,
                              retryCount: (q.retryCount ?? 0) + 1,
                              isVoiceInput:
                                !!q?.textToSpeech
                                || !!q?.audioFileSource
                            }

                            this.queryDocumentWithQuestion(
                              q.question,
                              opts
                            )
                          }}
                          onClick={() => {
                            this.drawLine(q, `qa-${parentIndex}-${childIndex}`)
                          }}
                          autoPlay={this.state.voiceAllowedNode?.parentIndex === parentIndex && this.state.voiceAllowedNode?.childIndex === childIndex}
                        />
                      )
                      )}
                      {!parentHasUnansweredFollowUpQuestion && (
                        <div className='result-box-container' style={{ borderColor: 'transparent' }}>
                          <div className='left item'>&nbsp;</div>
                          <div className='mid item'>
                            <QuestionBox
                              placeholder='Ask a follow up question...'
                              onResult={(question, source) => {
                                const isVoiceInput = source === 'voice'
                                this.setState({ queryDocument: question })
                                this.queryDocumentWithQuestion(question, { parentIndex, isVoiceInput })
                              }}
                            />
                            {!parentHasFollowUpQuestions && response.suggestedQuestions?.map((text, i) => (
                              <div
                                className='suggested-question'
                                key={i}
                                onClick={() => {
                                  this.queryDocumentWithQuestion(text, { parentIndex })
                                }}
                              >
                                {text}
                              </div>
                            ))}
                            {parentHasFollowUpQuestions && response.followUpQuestions[response.followUpQuestions.length - 1].suggestedQuestions?.map((text, i) => (
                              <div
                                className='suggested-question'
                                key={i}
                                onClick={() => {
                                  this.queryDocumentWithQuestion(text, { parentIndex })
                                }}
                              >
                                {text}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  )
                : <div className='pt-3'><Loading /></div>}
            </div>
          )
        })}
      </div>
    )
  }

  render () {
    return (
      <div>
        {this.renderQuestionAnswerFeature()}
        <div className='result-header'>
          <div className='result-number'>
            Result {this.props.resultIndex + 1}
          </div>
          <div className='result-operators'>
            {!this.props.context.isEditDisabled && this.props.context.results?.length > 1 && (
              <div
                className='result-delete operation-button'
                onClick={this.deleteResult}
              >
                <MaterialIcon name='delete' /> <p>Delete</p>
              </div>
            )}
          </div>
        </div>
        <div className='result-container'>
          {this.renderModelBox()}
          {this.renderTextToSpeech()}
          {this.renderEcho()}
          {this.renderFields()}
          {this.renderProperties('isInFocus', 'Is in focus')}
          {this.renderProperties('isGlareFree', 'Is glare free')}
          {this.renderObjectDetection('glares', 'Glare')}
          {this.renderObjectDetection('faces', 'Face')}
          {this.renderObjectDetection('signatures', 'Signature')}
          {this.renderDigitalSignatures()}
          {this.renderTables()}
          {this.renderOCR()}
          {!this.props.context.isEditDisabled && this.renderAddNewFieldBox()}
        </div>
      </div>
    )
  }
}

export default DataExtractionResult
