import { Component } from 'react'
import PropTypes from 'prop-types'
import DataExtractionBox from './DataExtractionBox'
import BooleanPicker from '../../interface/boolean-picker/BooleanPicker'

import * as uuid from 'uuid'

class PropertiesBox extends Component {
  static propTypes = {
    title: PropTypes.string,
    property: PropTypes.object,
    value: PropTypes.bool,
    contextProvider: PropTypes.object,
    context: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      mode: 'view'
    }
    this.id = uuid.v4()
    this.updateProperties = this.updateProperties.bind(this)
    this.modeHandler = this.modeHandler.bind(this)
  }

  updateProperties (value) {
    this.props.contextProvider.updateResult(this.props.property, value, { parent: 'properties' })
  }

  modeHandler (mode) {
    this.setState({ mode })
  }

  render () {
    return (
      <DataExtractionBox
        title={this.props.title}
        selectedDataReference={this.id}
        isEditable={true}
        isRemovable={false}
        isValid={this.props.value}
        modeHandler={this.modeHandler}
        desiredEditMode='result'
        context={this.props.context}
        contextProvider={this.props.contextProvider}
      >
        {
          this.state.mode === 'edit' && this.props.context.selectedDataReference === this.id
            ? (
                <BooleanPicker
                  onChange={bool => {
                    this.updateProperties(bool)
                  }}
                  value={this.props.value}
                />
              )
            : <div>{this.props.value ? 'Yes' : 'No'}</div>
        }
      </DataExtractionBox>
    )
  }
}

export default PropertiesBox
