// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'

// Context
import Context from '~/context/global'
import Header from '~/layout/header/Header'

// Data
import patentInfo from '../../data/patentInfo'

// View: Patents
class Patents extends Component {
  static contextType = Context
  constructor (props) {
    super(props)
    this.state = {
    }
    this.renderItem = this.renderItem.bind(this)
    this.renderLine = this.renderLine.bind(this)
  }

  renderLine (item) {
    return (
      <>
        <img src={item.image} />
        <article className='content'>
          <h2 className='title'>{item.title}</h2>
          <p><b>Filing date:</b> {item.filingDate}</p>
          <p><b>Application number:</b> {item.applicationNumber}</p>
          <main>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </main>
        </article>
      </>
    )
  }

  renderItem (item, i) {
    return (
      <li key={i}>
        <div className='row'>
          {this.renderLine(item)}
        </div>
      </li>
    )
  }

  render () {
    return (
      <View name='patents'>
        <Header name='patents'>
          <h1 className='slogan'>
            <b>Patents</b>
          </h1>
          <h3 className='introduction'>
            Innovation is the driving force behind Base64.ai&apos;s forward-thinking Intelligent Document Processing (IDP). Explore our patent-pending advancements, distinguishing us with exclusive solutions unparalleled by any other provider.
          </h3>
        </Header>
        <Section name='patents'>
          <ul>
            {patentInfo.map((item, index) => this.renderItem(item, index))}
          </ul>
        </Section>
      </View>
    )
  }
}

// Export within router
export default withRouter(Patents)
