// Modules
import PropTypes from 'prop-types'

// Data
import types from '~/data/dataExtractionTypes'
import DemoButton from '~/interface/demo-button/DemoButton'
import DynamicTitle from '~/interface/dynamic-title/DynamicTitle'
import Header from '~/layout/header/Header'

// Layout
import Section from '~/layout/section/Section'
import BenefitsSection from '~/content/benefits-section/BenefitsSection'
import FeaturesSection from '~/content/features-section/FeaturesSection'
import CaseStudiesSection from '~/content/case-studies-section/CaseStudiesSection'

// View: ImageRecognitionAPI
const ImageRecognitionAPI = props => (
  <>
    {props.type && Object.keys(types).includes(props.type)
      ? (
          <>
            <DynamicTitle title={`${types[props.type].name} feature`} />
            {/* Header: Presentation */}
            <Header name='presentation'>
              <h1 className='slogan'>
                <small>Extract Data From</small>
            &nbsp;
                <b>{types[props.type].name}</b>
              </h1>
              <p className='description'>{types[props.type].description}</p>
              <img className='visual' src={`/static/content/features/data-extraction/${props.type}.png`} />
            </Header>
            {/* Section: Benefits */}
            <BenefitsSection type={props.type} />
            {/* Section: Case Study */}
            <CaseStudiesSection type={props.type} />
            {/* Section: Demonstration */}
            <Section
              name='demonstration'
              align='center'
            >
              <h2 className='introduction'>Try our AI</h2>
              <h3 className='introduction'>{`Experience our AI on ${types[props.type].name} and other types of documents now!`}</h3>
              <DemoButton type={types[props.type]} />
            </Section>
          </>
        )
      : (
          <Header name='feature'>
            <h1 className='slogan'>
              <b>Image Recognition API</b>
            </h1>
            <p className='description'>
              Our image recognition API can tell the types of objects in a photo or document.
              <br />
              We enhance blurry and misaligned images. We use AI to find the document type.
              <br />
              We extract images within images such as the photo in an ID.
            </p>
          </Header>
        )}
    {/* Section: Features */}
    <FeaturesSection />
  </>
)

ImageRecognitionAPI.propTypes = {
  type: PropTypes.string
}

// Export
export default ImageRecognitionAPI
