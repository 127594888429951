import document from 'global/document'
import React from 'react'

export function renderSignature (data, index) {
  const signature = []
  const d = data[index]
  // Push a title
  signature.push(['Key', 'Value'])
  Object.entries(d).forEach(([key, value]) => {
    // Split camelCase key as normal capitalization
    let newKey = key.replace(/([a-z])([A-Z])/g, '$1 $2')
    newKey = newKey.charAt(0).toUpperCase() + newKey.toLowerCase().slice(1)
    // Push the key and value
    signature.push([newKey, value === true ? 'Yes' : value === false ? 'No' : value])
  })

  const isZoomed = data[index] === this.state.zoomedItem
  const closeZoom = () => this.setState({
    zoomedItem: null
  })
  document[`${isZoomed ? 'add' : 'remove'}EventListener`](
    'keydown',
    event => event.keyCode === 27 && closeZoom()
  )

  return (
    <React.Fragment key={0}>
      {data && data.length && data[index]
        ? (
            <a
              onClick={event => (!isZoomed || event.target.classList.contains('zoomed'))
              && this.setState({
                zoomedItem: isZoomed ? null : data[index]
              })}
              style={{
                marginRight: '0.3rem',
                borderBottom: 'unset'
              }}
            >
              {data.length === 1 ? 'View' : index + 1}
            </a>
          )
        : <span className='info'>No tables</span>}
      <div className={(isZoomed ? 'zoomed ' : '') + 'preview'} style={{ display: isZoomed ? 'flex' : 'none' }}>
        <figure className='table'>
          {isZoomed ? this.arrayToTable(signature) : null}
          <figcaption>
            <a
              className='close noBorder'
              style={{ display: isZoomed ? 'inline-block' : 'none' }}
              onClick={closeZoom}
            >
              Close
            </a>
          </figcaption>
        </figure>
      </div>
    </React.Fragment>
  )
}
