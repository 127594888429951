// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'
import Button from '../../interface/button/Button'
import PropTypes from 'prop-types'

class Compliance extends Component {
  constructor (props) {
    super(props)
    this.createRequestForm = this.createRequestForm.bind(this)
    this.toContactSection = this.toContactSection.bind(this)
  }

  createRequestForm (form) {
    const { user } = this.props
    const signature = user?.givenName ? (user.givenName + ' ' + user.familyName) : ''
    const email = user?.workEmail || user?.email || ''
    const companyName = user?.companyName || ''
    const phoneNumber = user?.phoneNumber || ''
    const message = 'mailto:support@base64.ai?Subject=Request for accessing Base64.ai compliance reports&body=Dear Base64.ai Support Team,<br /><br />'
      + '(Please change the signatory and add additional compliance reports as needed.)<br /><br />'
      + 'I would like to request access to Base64.ai, Inc.\'s ' + form + ' Certification.<br /><br />'
      + 'I understand that the company I represent will need to sign a Non-Disclosure Agreement (NDA) if they have not already. Please send the NDA via DocuSign to:<br/><br/>'
      + 'Signed by: ' + signature + '<br/>'
      + 'Email: ' + email + '<br/><br/>'
      + 'Thank you,<br/>'
      + signature + '<br/>'
      + email + '<br/>'
      + companyName + '<br/>'
      + phoneNumber
    return message.replace(/<br\s*\/?>/mg, '%0D%0A').replace(/ /g, '%20')
  }

  toContactSection () {
    window.scrollBy({
      top: 10000,
      behavior: 'smooth'
    })
  }

  render () {
    return (
      <View name='partners'>
        <Header name='compliance'>
          <h1 className='slogan'>Compliance</h1>
          <h3 className='introduction'>
            Certified for ISO, HIPAA, SOC 2, and GDPR, Base64.ai cloud and on-premise solutions offer top quality data extraction at the highest security standards.<br />
            <br />
            <i>Accessing the reports require signing an NDA for security and privacy purposes.</i>
          </h3>
        </Header>
        <Section name='certificate' align='center'>
          <h2 className='slogan' id='iso27001'>ISO 27001 Certified</h2>
          <h3 className='introduction'><img src='/static/content/partners/iso-27001-certified.png' title='ISO 27001 Certified' /></h3>
          <h3 className='introduction'>
            <a href='https://www.iso.org/standard/27001' target='_blank' rel='noopener noreferrer'>ISO/IEC 27001</a> is the globally recognized standard for information security management systems (ISMS).
            It guides organizations in establishing, implementing, maintaining, and improving an ISMS across various sectors and sizes.
            Compliance with ISO/IEC 27001 signifies that Base64.ai has implemented a system to manage data security risks,
            enhancing cyber-resilience, and achieving operational excellence.
          </h3>
          <Button href='https://www.iso.org/standard/27001' className='secondary-cta'>Learn more</Button>
          <Button href={this.createRequestForm('ISO 27001')}>View certificate</Button>
        </Section>
        <Section name='certificate' align='center'>
          <h2 className='slogan' id='iso20243'>ISO 20243 Certified</h2>
          <h3 className='introduction'><img src='/static/content/partners/iso-20243-ottps-certified.png' title='ISO 20243 Certified' /></h3>
          <h3 className='introduction'>
            The <a href='https://www.iso.org/standard/74399.html' target='_blank' rel='noopener noreferrer'>ISO/IEC 20243</a>, also known as the <b>Open Trusted Technology Provider Standard Certification (O-TTPS)</b>, is a standard created by The Open Group.
            The ISO/IEC 20243 O-TTPS Certification ensures that the organization follows a set of guidelines, recommendations, and requirements throughout all phases of a product lifecycle including design, sourcing, build, fulfillment, distribution, sustainment, and disposal.
            It certifies the integrity of Base64.ai as a commercial off-the-shelf (COTS) product and protects the security of the global supply chain of our customers.
          </h3>
          <Button href='https://www.iso.org/standard/74399.html' className='secondary-cta'>Learn more</Button>
          <Button href={this.createRequestForm('ISO 20243')}>View certificate</Button>
        </Section>
        <Section name='certificate' align='center'>
          <h2 className='slogan' id='hipaa'>HIPAA Certified</h2>
          <h3 className='introduction'><img src='/static/content/partners/hipaa-certified.png' title='HIPAA Certified' /></h3>
          <h3 className='introduction'>
            The <a href='https://aspe.hhs.gov/reports/health-insurance-portability-accountability-act-1996' target='_blank' rel='noopener noreferrer'>Health Insurance Portability and Accountability Act (HIPAA)</a> Privacy,
            Security, and Breach Notification Rules protect the privacy and security of health information
            and gives individuals rights to their health information. HIPAA establishes standards to protect PHI (personal health information) held by the processing entities and their
            business associates.
          </h3>
          <Button to='/news/Base64-ai-is-Now-HIPAA-Certified-8d8dcc5c' className='secondary-cta'>Learn more</Button>
          <Button href={this.createRequestForm('HIPAA')}>Download report</Button>
        </Section>
        <Section name='certificate' align='center'>
          <h2 className='slogan' id='soc2'>SOC 2 Type 1 & 2 Certified</h2>
          <h3 className='introduction'>
            <img src='/static/content/partners/soc-2-type-1-certified.png' title='SOC 2 Type 1 Certified' style={{ marginRight: '1rem' }} />
            <img src='/static/content/partners/soc-2-type-2-certified.png' title='SOC 2 Type 2 Certified' style={{ marginLeft: '1rem' }} />
          </h3>
          <h3 className='introduction'>
            <a href='https://us.aicpa.org/interestareas/frc/assuranceadvisoryservices/serviceorganization-smanagement.html' target='_blank' rel='noopener noreferrer'>System and Organization Controls (SOC)</a> validates
            the security and integrity of the internal controls and information systems.
            The SOC 2 has been widely accepted as a US standard for information security.
            It is used by SAAS companies as well as financial organizations, insurance companies and datacenters.
            The SOC 2 Type 1 Report assesses the design of security processes at a specific point in time, while the SOC 2 Type 2 Report assesses how effective those controls are over time by observing operations for several months.
          </h3>
          <Button href={this.createRequestForm('SOC 2 Type 1')} className='secondary-cta'>Download Type 1 report</Button>
          <Button href={this.createRequestForm('SOC 2 Type 2')}>Download Type 2 report</Button>
        </Section>
        <Section name='certificate' align='center'>
          <h2 className='slogan' id='gdpr'>GDPR Compliant</h2>
          <h3 className='introduction'><img src='/static/content/partners/gdpr-compliant.png' title='GDPR Compliant' /></h3>
          <h3 className='introduction'>
            The <a href='https://gdpr.eu' target='_blank' rel='noopener noreferrer'>General Data Protection Regulation (GDPR)</a> is the toughest privacy and security law in the world.
            Though it was drafted and passed by the European Union (EU), it imposes obligations onto organizations anywhere, so long as they target or collect data related to people in the EU.
          </h3>
          <Button href={this.createRequestForm('GDPR')}>Download report</Button>
        </Section>
        <Section name='certificate' align='center'>
          <h2 className='slogan' id='pbsa'>PBSA Accredited</h2>
          <h3 className='introduction'><img src='/static/content/partners/pbsa.png' title='Professional Background Screening Association (PBSA) Accredited' /></h3>
          <h3 className='introduction'>
            <a href='https://thepbsa.org/' target='_blank' rel='noopener noreferrer'>Professional Background Screening Association (PBSA)</a> was
            established to represent the interest of companies offering employment and tenant background screening services.
            The Association currently represents over 880 member companies engaged in employment and tenant background screening across the United States.
            Member companies range from Fortune 100 companies to small local businesses, conducting millions of employment related and tenant background checks each year as part of the hiring and leasing process.
            PBSA Member companies are defined as &apos;consumer reporting agencies&apos; pursuant to the Fair Credit Reporting Act (FCRA) and are regulated by both the FTC and CFPB.
          </h3>
          <Button href={this.createRequestForm('PBSA')}>View certificate</Button>
        </Section>
        <Section name='certificate' align='center'>
          <h2 className='slogan' id='acra'>American Car Rental Association Member</h2>
          <h3 className='introduction'><img src='/static/content/partners/acra.png' title='American Car Rental Association Member' /></h3>
          <h3 className='introduction'>
            Base64.ai is a member of the <a href='https://www.acraorg.com/associate-members/' target='_blank' rel='noopener noreferrer'>American Car Rental Association</a>,
            whose mission is to help drive growth and innovation in mobility services on behalf of its members.
            Base64.ai supports ACRA members and the global vehicle rental and leasing community with its cutting edge artificial intelligence service that automatically processes worldwide IDs, driver licenses, passports, vehicle registrations, and vehicle insurance cards.
          </h3>
          <Button href='https://www.acraorg.com/associate-members/'>Learn More</Button>
        </Section>
        <Section name='certificate' align='center'>
          <h2 className='slogan' id='inclusive-capitalism-esg'>Council for Inclusive Capitalism Member</h2>
          <h3 className='introduction'><img src='/static/content/partners/cfic.png' title='Council for Inclusive Capitalism Compliant' /></h3>
          <h3 className='introduction'>
            Base64.ai is a member of the <a href='https://www.inclusivecapitalism.com/organization/base64ai/' target='_blank' rel='noopener noreferrer'>Council for Inclusive Capitalism</a>, a global movement of leaders doing
            business in ways that benefit people, our communities, and the planet. In order to create more economic opportunities for low-income, low-education,
            and underprivileged members of society, Base64.ai automated document processing created &quot;remote document reviewer&quot; (RDR) job positions for people with
            limited experience with computers. RDRs can work from anywhere in the world 100% remotely. Base64.ai also commits building products and systems to enable
            its customers (i.e. other companies) to offer jobs to RDRs belonging to underrepresented, special, or disadvantaged groups, such as veterans, people with health impairments, sexual minorities, or geographical locations.
          </h3>
          <Button href='https://www.inclusivecapitalism.com/organization/base64ai/'>Learn More</Button>
        </Section>
      </View>
    )
  }
}

Compliance.propTypes = {
  user: PropTypes.object
}

// Export within router
export default withRouter(Compliance)
