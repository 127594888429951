import { request } from './request'

export function rerunIntegration (resultUuid, callback) {
  const requestOptions = {
    endpoint: '/result/integration/destination/' + resultUuid,
    method: 'GET'
  }
  request(requestOptions, (err, response) => {
    if (err) {
      return callback && callback(err)
    }
    callback && callback(null, response)
  }, () => {})
}
