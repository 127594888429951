// Modules
import { Component } from 'react'

// Helpers
import window from 'global/window'

import Context from '~/context/global'
import { hasDatabase } from '~/context/environment'

// Interface

// Layout
import Header from '~/layout/header/Header'
import constants from '../../data/constants'
import { AppButton } from '../../interface/app-button/AppButton'

// Content: DataExtractionHeader
class DataExtractionHeader extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      isMobile: window?.innerWidth < 767,
      videoSource: constants.dataExtractionHeaderVideo || `/static/videos/header_video${window?.innerWidth > 1600 ? '_hires' : ''}.mp4`
    }
    this.resizeHandler = this.resizeHandler.bind(this)
  }

  componentDidMount () {
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeHandler)
  }

  resizeHandler () {
    // only reload if new source is different from old source
    const newSource = constants.dataExtractionHeaderVideo || `/static/videos/header_video${window?.innerWidth > 1600 ? '_hires' : ''}.mp4`
    const vid = document.querySelector('[data-header="data-extraction"] .video')
    // trim domain and http(s) protocol from vid.src
    const shouldReloadVideo = !vid.src.endsWith(newSource)
    this.setState({
      isMobile: window.innerWidth < 767,
      videoSource: newSource
    }, () => {
      if (vid && shouldReloadVideo) {
        vid.src = newSource
        vid.load()
      }
    })
  }

  render () {
    return (
      <Header
        name='data-extraction'
        identifier={true}
      >
        <div className='overlay'>
          <div className='overlay-left'>
            <div className='overlay-left-block'>
              <div className='slogan-block'>
                <h1 className='slogan top'>
                  <nobr>
                    Process Documents Instantly
                  </nobr>
                </h1>
                <h1 className='slogan bottom'>
                  <nobr>
                    with Generative AI and LLMs
                  </nobr>
                </h1>
              </div>
              <h3 className='introduction'>
                Base64.ai is the leading no-code AI solution<br /><nobr>that understands documents, photos, and videos</nobr>
              </h3>
              {!hasDatabase()
                ? (
                    <div className='button-line'>
                      <AppButton href='/demo' className='primary-cta'>
                        Try demos
                      </AppButton>
                      <AppButton href='/contact' className='secondary-cta' hardLink={true}>
                        Let&apos;s talk
                      </AppButton>
                    </div>
                  )
                : (
                    <div className='button-line'>
                      <AppButton
                        href={this.state.isMobile ? '/demo' : '/integration-wizard'}
                        className='secondary-cta'
                        title={this.state.isMobile ? 'It will let you try our product' : 'Our Integration Wizard will help you choose the best integration options to automate documents.'}
                      >
                        {this.state.isMobile ? 'Try demos' : 'USE INTEGRATION WIZARD' }
                      </AppButton>
                      <AppButton href='/flow' className='primary-cta' title='With our AI, you can automate processing your all document types.'>
                        Start document automation
                      </AppButton>
                    </div>
                  )}

              <div className='g2-badges'>
                <a
                  className='g2-badges-link'
                  href='https://www.g2.com/products/base64-ai-automated-document-data-extraction/reviews#reviews'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='/static/images/badges/high-performer.png' alt='G2 badge, High performer, spring, 2024' />
                </a>
                <a
                  className='g2-badges-link'
                  href='https://www.g2.com/products/base64-ai-automated-document-data-extraction/reviews#reviews'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='/static/images/badges/easiest-to-use.png' alt='G2 badge, Easiest to use, spring, 2024' />
                </a>
                <a
                  className='g2-badges-link'
                  href='https://www.g2.com/products/base64-ai-automated-document-data-extraction/reviews#reviews'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='/static/images/badges/easiest-to-do-business.png' alt='G2 badge, Easiest to do business with, spring, 2024' />
                </a>
              </div>

            </div>

          </div>
          <div className='overlay-right'>
            {/* Homepage video (load deferred) */}
            <video
              disablePictureInPicture
              className='video'
              playsInline
              autoPlay
              muted
              loop
              src={this.state.videoSource}
              poster={constants.dataExtractionHeaderVideo ? undefined : '/static/images/header_video_thumb.png'}
            >
            </video>
          </div>
        </div>
      </Header>
    )
  }
}

// Export
export default DataExtractionHeader
