import { Component } from 'react'
import PropTypes from 'prop-types'
import DataExtractionBox from './DataExtractionBox'

class TextToSpeechBox extends Component {
  static propTypes = {
    audio: PropTypes.object,
    context: PropTypes.object,
    contextProvider: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
    }
    this.removeTextToSpeech = this.removeTextToSpeech.bind(this)
  }

  removeTextToSpeech () {
    this.props.contextProvider.updateResult('textToSpeech', 'deleted', { parent: 'features' })
  }

  render () {
    return (
      <DataExtractionBox
        title='Listen'
        removeHandler={this.removeTextToSpeech}
        contextProvider={this.props.contextProvider}
        context={this.props.context}
      >
        <audio className='audio-player' controls='controls' autobuffer='autobuffer'>
          <source src={this.props.audio}></source>
        </audio>
      </DataExtractionBox>
    )
  }
}

export default TextToSpeechBox
