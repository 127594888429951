import { Component } from 'react'
import Section from '~/layout/section/Section'
import PropTypes from 'prop-types'

// Interface
import Link from '~/interface/link/Link'
import { AppButton } from '../../interface/app-button/AppButton'

class DemoLinksSection extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const { type } = this.props
    const links = type.demoLinks
    return (
      Array.isArray(links)
        ? (
            <Section
              name='demo-links'
              align='center'
              identifier={true}
            >
              <h2 id='title' className='introduction' style={{ marginBottom: '3rem' }}>Try {type.brandName} now! </h2>
              {/* <h3 id="content" className="introduction" style={{ margin: '2rem 0' }}><b>Signature AI</b> extracts and matches signatures in documents, and photos.</h3> */}
              <ul className='list'>
                {links.map(link => (
                  <li key={link.url}>
                    <Link to={link.url}>
                      <p className='headline'>{link.title}</p>
                      <AppButton className='secondary-cta large w-fit' href={link.url}>{link.buttonName}</AppButton>
                    </Link>
                  </li>
                ))}
              </ul>
            </Section>
          )
        : null
    )
  }
}

DemoLinksSection.propTypes = {
  type: PropTypes.object
}

// Export
export default DemoLinksSection
