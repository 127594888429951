import MaterialIcon from '../material-icon/MaterialIcon'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import { useState } from 'react'
const AddButton = ({ icon, onAdd, title, tooltip }) => {
  const [localIcon, setLocalIcon] = useState(icon)
  const [localTooltip, setLocalTooltip] = useState(tooltip)

  const handleAdd = async () => {
    onAdd?.()
    setLocalIcon('task_alt')
    setLocalTooltip('Added to results')
  }

  return (
    <Tippy
      content={localTooltip}
      placement='top'
      animation='shift-away'
      arrow={false}
    >
      <MaterialIcon
        title={title || ''}
        name={localIcon}
        style={localIcon === 'task_alt' && { color: '#399E86' }}
        onClick={handleAdd}
        className='hover-blue'
      />
    </Tippy>
  )
}

AddButton.propTypes = {
  icon: PropTypes.string,
  onAdd: PropTypes.func,
  tooltip: PropTypes.string,
  title: PropTypes.string
}
AddButton.defaultProps = {
  icon: 'add_circle',
  tooltip: 'Add to results'
}

export default AddButton
