// Interface
import Link from '~/interface/link/Link'

// Data
import dataExtractionTypes from '~/data/dataExtractionTypes'

// Layout
import Section from '~/layout/section/Section'

// Helpers
import { hasDatabase } from '../../context/environment'

// Content: FeaturesSection
const FeaturesSection = props => (
  hasDatabase()
  && (
    <Section
      name='features'
      align='center'
      identifier
    >
      <h2 className='title'>One product for all your data <nobr>extraction needs</nobr></h2>
      <p className='introduction'>
        Pick a category to learn how we can automate your <nobr>document processing scenarios.</nobr>
        <br />
        Our AI service can scale infinitely in the cloud. No hardware <nobr>or templates needed.</nobr>
      </p>
      <ul className='list'>
        {Object.keys(dataExtractionTypes).map(type => (
          <li key={type}>
            <Link to={`/features/data-extraction-api/${type}`}>
              {dataExtractionTypes[type].name}
            </Link>
          </li>
        )
        )}
      </ul>
    </Section>
  )
)

// Export
export default FeaturesSection
