export default [
  { label: 'Model name', value: '*modelName' },
  { label: 'Created at', value: '*createdAt', getProperty: result => result?.features?.properties?.createdAt },
  { label: 'Updated at', value: '*updatedAt', getProperty: result => result?.features?.properties?.updatedAt }, // when the document is updated, it is the same as the created at
  { label: 'Given name', value: 'givenName' },
  { label: 'Family name', value: 'familyName' },
  { label: 'Full name', value: 'fullName' },
  { label: 'Age', value: 'age' },
  { label: 'Date of birth', value: 'dateOfBirth' },
  { label: 'Issue date', value: 'issueDate' },
  { label: 'Expiration date', value: 'expirationDate' },
  { label: 'Company name', value: 'companyName' },
  { label: 'Tax', value: 'tax' },
  { label: 'Total', value: 'total' },
  { label: 'Number of pages', value: '*numberOfPages', getProperty: result => result?.features?.properties?.pageCount },
  { label: 'Number of faces', value: '*numberOfFaces', getProperty: result => result?.features?.faces?.length },
  { label: 'Number of signatures', value: '*numberOfSignatures', getProperty: result => result?.features?.signatures?.length },
  { label: 'Number of tables', value: '*numberOfTables', getProperty: result => result?.features?.dom?.pages?.reduce((acc, page) => acc + page.tables?.length, 0) },
  { label: 'OCR text length', value: '*ocrTextLength', getProperty: result => result?.ocr?.length },
  { label: 'Approver', value: '*approvedBy' },
  { label: 'Uploader', value: '*uploadedBy' },
  { label: 'Integration status', value: '*integrationStatus' }
]
