export default [
  {
    filename: 'base64.ai-logo.png',
    description: 'Icon in high resolution PNG',
    isIcon: true
  },
  {
    filename: 'base64.ai-logo.svg',
    description: 'Logo with text in SVG format',
    style: {
      minWidth: '300px'
    }
  },
  {
    filename: 'base64.ai-logo-transparent.png',
    description: 'Logo in high resolution PNG'
  }
]
