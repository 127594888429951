import additionalListColumnsFields from './additionalListColumnsFields'

export default {
  columns: [
    {
      columnName: 'Display name',
      fieldName: 'displayName',
      inputType: 'text',
      placeholder: 'Enter display name...',
      defaultValue: ''
    },
    {
      columnName: 'Data source',
      inputType: 'creatable-select',
      fieldName: 'fieldName',
      placeholder: 'Select or enter data source...',
      options: additionalListColumnsFields,
      defaultValue: {
        label: '',
        value: ''
      },
      isMulti: false,
      isCamelCase: true
    }
  ],
  addButtonText: 'Add column',
  isDeleteButtonEnabled: true,
  className: 'three-column'
}
