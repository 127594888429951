export default {
  'iso-27001-certified': {
    name: 'ISO 27001',
    type: 'compliance',
    demo: '/compliance#iso27001',
    description: 'Base64.ai has a ISO 27001 security certificate for its processes and controls. See Compliance section on our website for more details.'
  },
  'iso-20243-ottps-certified': {
    name: 'ISO 20243',
    type: 'compliance',
    demo: '/compliance#iso20243',
    description: 'Base64.ai has a ISO 20243 security certificate for its processes and controls. See Compliance section on our website for more details.'
  },
  'soc-2-type-2-certified': {
    name: 'SOC 2 Type 2',
    type: 'compliance',
    demo: '/compliance#soc2',
    description: 'Base64.ai has a SOC-2 Type 2 security certificate for its processes and controls. See Compliance section on our website for more details.'
  },
  'hipaa-certified': {
    name: 'HIPAA',
    type: 'compliance',
    demo: '/compliance#hipaa',
    description: 'Base64.ai has HIPAA security certificate to process protected health information. See Compliance section on our website for more details.'
  },
  'gdpr-compliant': {
    name: 'GDPR',
    type: 'compliance',
    demo: '/compliance#gdpr',
    description: 'Base64.ai is a GDPR compliant service that respects the customer privacy. See Compliance section on our website for more details.'
  },
  'acord': {
    name: 'ACORD',
    type: 'membership',
    demo: '/features/data-extraction-api/acord',
    description: 'Base64.ai is a member of the Association for Cooperative Operations Research and Development (ACORD) whose objective is to enable the efficient and effective flow of data among all stakeholders across the insurance value chain.'
  },
  'pbsa': {
    name: 'Public Background Search Association (PBSA)',
    type: 'membership',
    demo: '/compliance',
    description: 'Base64.ai is a member of the Public Background Search Association (PBSA) and serves the community members by automating processing of IDs, driver\'s licenses, passports, visas, Covid vaccination cards, and test results.'
  },
  'acra': {
    name: 'American Car Rental Association (ACRA)',
    type: 'membership',
    demo: '/compliance',
    description: 'Base64.ai is a member of the American Car Rental Association (ACRA) and supports the global vehicle rental and leasing community with its cutting edge artificial intelligence service that automatically processes worldwide IDs, driver\'s licenses, passports, vehicle registrations, and vehicle insurance cards.'
  },
  'cfic': {
    name: 'Council for Inclusive Capitalism',
    type: 'membership',
    demo: '/compliance',
    description: 'Base64.ai is a member of the Council for Inclusive Capitalism, a global movement of leaders doing business in ways that benefit people, our communities, and the planet.'
  },
  'turkiye-ai': {
    name: 'Türkiye AI',
    type: 'membership',
    demo: 'https://turkiye.ai/homepage/',
    description: 'Base64.ai is a member of the Türkiye AI to bring artificial intelligence solutions to institutions in Türkiye.'
  },
  'google': {
    name: 'Google for Startups',
    isFeatured: true,
    type: 'marketplace',
    demo: 'https://startup.google.com/',
    description: 'Base64.ai is a Google Startups partner. Contact your Google Solution team to learn about Base64.ai.'
  },
  'microsoft': {
    name: 'Microsoft Azure',
    isFeatured: true,
    type: 'marketplace',
    demo: 'https://appsource.microsoft.com/en-us/marketplace/partner-dir/9f9d5d79-a8cc-48c2-acf4-fc241f2fc121/overview',
    description:
      'Base64.ai is a Microsoft Partner since 2021.\n'
      + 'Base64.ai is available on Microsoft Azure datacenters for all Azure Marketplace users for quick integration. '
      + 'Our solution is ready to integrate with your existing Azure architectures.'
  },
  'aws-marketplace': {
    name: 'AWS Marketplace',
    isFeatured: true,
    type: 'marketplace',
    demo: 'https://aws.amazon.com/marketplace/pp/B091BDJCQ3',
    description:
      'Base64.ai is an AWS partner since 2021.\n'
      + 'Base64.ai is available on all Amazon Web Services (AWS) datacenters for AWS Marketplace users for quick integration. '
      + 'Our solution is ready to integrate with your existing AWS architectures.\n'
      + 'You can use your existing AWS credits and commitments with Base64.ai.'
  },
  'tungsten-automation': {
    name: 'Tungsten Automation',
    isFeatured: true,
    type: 'marketplace',
    demo: 'https://marketplace.tungstenautomation.com/details/base64-ai-extraction-connector-for-totalagility',
    optionalImage: 'https://d8p1x3h4xd5gq.cloudfront.net/5ccb138347197b0d3c847968/public/65c271068db7a017dddc76d2.jpg',
    description: 'Base64.ai has partnered with Tungsten Automation to provide our complete document library to users of TotalAgility. Tungsten Automation\'s TotalAgility platform is a leading low-code platform with powerful capabilities for document based business processes.'
  },
  'eden-ai': {
    name: 'Eden AI',
    isFeatured: false,
    type: 'marketplace',
    demo: 'https://edenai.co/',
    description: 'Eden AI simplifies the use and deployment of AI technologies like Base64.ai by providing\n   a unified and simplified API connected to the best AI engines.'
  },
  'nvidia': {
    name: 'Nvidia',
    isFeatured: true,
    type: 'hardware',
    demo: 'https://www.nvidia.com/',
    description: 'Base64.ai is an Nvidia Inception Incubator partner. Our deep learning solution works best on Nvidia GPUs. Base64.ai & Nvidia partnership allows our customers to receive priority delivery on Nvidia GPUs hardware.'
  },
  'kodak-alaris': {
    name: 'Kodak Alaris',
    isFeatured: true,
    type: 'hardware',
    demo: 'https://www.kodakalaris.com/',
    description: 'Use Base64.ai\'s Document AI features from Kodak Alaris scanners for high-volume paper-based document processing.'
  },
  'fujitsu': {
    name: 'Fujitsu',
    isFeatured: true,
    type: 'hardware',
    demo: 'https://www.fujitsu.com/',
    description: 'Use Base64.ai\'s Document AI features from Fujitsu scanners for high-volume paper-based document processing.'
  },
  'ricoh': {
    name: 'Ricoh',
    isFeatured: true,
    type: 'hardware',
    demo: 'https://www.ricoh-usa.com/',
    description: 'Use Base64.ai\'s Document AI features from Ricoh scanners for high-volume paper-based document processing.'
  },
  'uipath-rpa': {
    name: 'UiPath',
    isFeatured: true,
    type: 'rpa',
    description: 'Base64.ai is UiPath\'s Advanced Technology Partner in automated document understanding. Use Base64.ai drag & drop activity component for UiPath RPA projects to boost implementation speed and accuracy. Unlike other document intelligence solutions, our component processes all document types out-of-the-box, so you can start automating instantly. We are integrated with the UiPath Validation Station for improved accuracy.',
    youtube: 'IdKYogailUE',
    demo: 'https://marketplace.uipath.com/listings/base64ai-automated-document-processing'
  },
  'automation-anywhere': {
    name: 'Automation Anywhere',
    isFeatured: true,
    type: 'rpa',
    demo: 'https://www.automationanywhere.com/',
    description: 'Base64.ai partners with Automation Anywhere and offers AI-powered document processing services to all AA partners and customers.'
  },
  'zapier': {
    name: 'Zapier',
    type: 'rpa',
    description: 'Set up automated actions between your Dropbox, Google Drive, Slack, and Base64.ai through Zapier’s convenient drag-and-drop user interface. This incredibly simple UI will help any busy professional get work done faster by letting Zapier and Base64.ai do the heavy lifting.',
    youtube: '7OYx9_8_Q1A',
    demo: 'https://zapier.com/apps/base64ai/integrations'
  },
  'indico': {
    name: 'Indico',
    type: 'rpa',
    demo: 'https://indicodata.ai',
    description: 'Base64.ai partners with Indico Data AI on AI-powered document understanding.'
  },
  'api3': {
    name: 'API3',
    type: 'rpa',
    demo: 'https://api3.org/web3-apis/Base64.ai%20API/',
    description:
      'API3 is a next-generation, “trustless”, cryptocurrency-based platform that deliver\n'
      + 'more security, efficiency, and data-source transparency. You can use Base64.ai in\n'
      + 'decentralized platforms via smart Ethereum contracts.'
  },
  'bardeen-ai': {
    name: 'Bardeen AI',
    demo: 'https://bardeen.ai/',
    type: 'rpa',
    description: 'Base64.ai is integrated with Bardeen AI and is the main automation provider for complex documents such as invoices.'
  },
  'electroneek': {
    name: 'ElectroNeek',
    type: 'rpa',
    isFeatured: true,
    demo: 'https://electroneek.com/',
    description: 'Base64.ai partners with ElectroNeek and offers AI-powered document processing services to all ElectroNeek partners and customers.'
  },
  'robocorp': {
    name: 'Robocorp',
    type: 'rpa',
    isFeatured: true,
    demo: 'https://robocorp.com/portal/robot/robocorp/example-idp-base64',
    optionalImage: '/static/content/solutions/covers/robocorp.png',
    description:
        'Robocorp\'s Open Source Gen2 RPA is better, faster, and is the most cost effective RPA platform '
        + 'in the world - built for developers and teams that want to work smarter, not harder. Robocorp '
        + 'incorporated Base64.ai\'s API in their framework as the go-to solution for document understanding.'
  },
  'cognillo': {
    name: 'Cognillo Aura',
    type: 'rpa',
    demo: 'https://www.cognillo.com/',
    description: 'Base64.ai partners with Cognillo Aura and offers AI-powered document processing services to all Cognillo Aura partners and customers.'
  },
  'genrpa': {
    name: 'GenRPA',
    type: 'rpa',
    demo: 'https://genrpa.com/en/home',
    description: 'Base64.ai partners with GenRPA and offers AI-powered document processing services to all GenRPA customers.'
  },
  'mysa': {
    name: 'MySA',
    type: 'solution',
    demo: 'https://mysa.tc/',
    description: 'Base64.ai partners with MySA My Security Analytics in bringing Document AI to government and commercial customers in Türkiye.'
  },
  'botteq': {
    name: 'Botteq',
    type: 'solution',
    demo: 'https://botteq.com.tr/',
    description: 'Base64.ai partners with Botteq in Türkiye to build automated document understanding projects.'
  },
  'evolvant': {
    name: 'Evolvant',
    type: 'solution',
    demo: 'https://base64.ai/news/Press-release-Evolvant-and-Base64-ai-Partner-to-Bring-Intelligent-Automation-to-Document-Processing-c92bccfd',
    description: 'Base64.ai partners with Evolvant in United Arab Emirates, Saudi Arabia, and Egypt to build automated document understanding projects.'
  },
  'ashling-partners': {
    name: 'Ashling Partners',
    type: 'solution',
    demo: 'https://www.ashlingpartners.com/',
    description: 'Base64.ai partners with Ashling Partners from Boston that is the largest UiPath solution integrator.'
  },
  '32bit': {
    name: '32bit',
    type: 'solution',
    demo: 'https://32bit.com.tr/en/home/',
    description: 'Base64.ai partners with 32bit in Türkiye to build automated document understanding projects.'
  },
  'practia': {
    name: 'Practia',
    type: 'solution',
    demo: 'https://base64.ai/news/Practia-Global-and-Base64-ai-Announce-their-Partnership-to-Automate-Document-Processing-and-Eliminate-Manual-Data-Entry-d2b4d657',
    description: 'Base64.ai partners with Pracita in LATAM, Americas and Europe to build automated document understanding projects.'
  },
  'propel-consult': {
    name: 'Propel Consult',
    type: 'solution',
    demo: 'https://propelconsult.co.uk/',
    description: 'Base64.ai partners with Propel Consult in the United Kingdom and Europe to build automated document understanding projects.'
  },
  'tigris': {
    name: 'Tigris Smart',
    type: 'solution',
    demo: 'https://en.tigrissmart.com/',
    description: 'Base64.ai partners with Tigris (formerly know as Extunda) in Türkiye to build automated document understanding projects.'
  },
  'ai-partnerships': {
    name: 'AI Partnerships',
    type: 'solution',
    demo: 'https://www.aipartnershipscorp.com/post/aip-affiliate-spotlight-base64-ai',
    description: 'Base64.ai is a member of AI Partnerships Community (AIP) since its incubation and works together with AIP to bring automation to North American companies.'
  },
  'prime-robot': {
    name: 'Prime Robot',
    type: 'solution',
    demo: 'https://primerobot.com.br/',
    description: 'Base64.ai partners with Prime Robot in Brazil to build automated document understanding projects.'
  },
  'da-barnes': {
    name: 'DA Barnes Group',
    type: 'solution',
    demo: 'http://www.dabarnesgroup.com/',
    description: 'Base64.ai partners with DA Barnes Group from Michigan to add the power of AI to document management.'
  },
  'anitites': {
    name: 'Anitites',
    type: 'solution',
    demo: 'https://www.antites.com/',
    description: 'Base64.ai partners with Anitites from Spain to build automated document understanding projects.'
  },
  'oys-consultores': {
    name: 'Oys Consultores',
    type: 'solution',
    demo: 'https://www.oysconsultores.com/',
    description: 'Base64.ai partners with Oys Consultores from Spain to build automated document understanding projects.'
  },
  'lean-automation': {
    name: 'Lean Automation',
    type: 'solution',
    demo: 'https://leanautomation.co.uk/',
    description: 'Base64.ai partners with Lean Automation from the UK that is also a partner of Robocorp RPA, with which Base64.ai has a partnership.'
  }
}
