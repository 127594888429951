export const domainValidator = domain => {
  if (!domain.trim()) {
    return false
  }
  return !!domain.match(/^\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b$|(localhost)$/)
}

export const emailValidator = email => {
  if (!email.trim()) {
    return false
  }
  return /[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+/.test(email)
}

export const regexValidator = regex => {
  try {
    ''.match(regex)
    return true
  } catch {
    return false
  }
}
