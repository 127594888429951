import React, { Component } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

class Popup extends Component {
  static propTypes = {
    children: PropTypes.children,
    closeHandler: PropTypes.func,
    customHeader: PropTypes.func,
    customIcons: PropTypes.func,
    isClosable: PropTypes.bool,
    isFloating: PropTypes.bool,
    moveHandler: PropTypes.boll,
    initialPosition: PropTypes.object,
    title: PropTypes.string,
    editTitle: PropTypes.bool,
    titleLeftMargin: PropTypes.number,
    onTitleChange: PropTypes.func,
    onResize: PropTypes.func,
    defaultPosition: PropTypes.string
  }

  static defaultProps = {
    customHeader: () => {},
    customIcons: () => {},
    closeButtonHandler: () => {},
    moveHandler: () => {},
    onTitleChange: () => {},
    onResize: () => {},
    isFloating: true
  }

  constructor (props) {
    super(props)
    this.state = {
      isMouseDown: false,
      observer: null,
      isPositionTouched: false,
      newTitle: props.title
    }
    this.containerRef = React.createRef()
  }

  componentDidMount () {
    window.addEventListener('keydown', this.keyDownHandler.bind(this))

    const position = this.props.defaultPosition ?? 'center'
    const containerDom = this.containerRef.current
    const scrollbarOffset = 18
    let top, left

    switch (position) {
      case 'top-left':
        top = 0
        left = 0
        break
      case 'top-center':
        top = 0
        left = (window.innerWidth - containerDom.offsetWidth) / 2
        break
      case 'top-right':
        top = 0
        left = window.innerWidth - containerDom.offsetWidth - scrollbarOffset
        break
      case 'center-left':
        top = (window.innerHeight - containerDom.offsetHeight) / 2
        left = 0
        break
      case 'center':
        top = (window.innerHeight - containerDom.offsetHeight) / 2
        left = (window.innerWidth - containerDom.offsetWidth) / 2
        break
      case 'center-right':
        top = (window.innerHeight - containerDom.offsetHeight) / 2
        left = window.innerWidth - containerDom.offsetWidth - scrollbarOffset
        break
      case 'bottom-left':
        top = window.innerHeight - containerDom.offsetHeight
        left = 0
        break
      case 'bottom-center':
        top = window.innerHeight - containerDom.offsetHeight
        left = (window.innerWidth - containerDom.offsetWidth) / 2
        break
      case 'bottom-right':
        top = window.innerHeight - containerDom.offsetHeight
        left = window.innerWidth - containerDom.offsetWidth - scrollbarOffset
        break
      default:
        top = (window.innerHeight - containerDom.offsetHeight) / 2
        left = (window.innerWidth - containerDom.offsetWidth) / 2
    }

    this.containerRef.current.style.left = left + 'px'
    this.containerRef.current.style.top = top + 'px'

    this.setState(
      {
        resizeObserver: new ResizeObserver(e => {
          window.requestAnimationFrame(() => {
            this.props.moveHandler()
          })
        }).observe(containerDom),
        initialPosition: {
          x: left,
          y: top
        }
      }
    )

    const observer = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        this.props.onResize?.(entries?.[0])
      })
    })
    // Only observe the second box
    observer.observe(this.containerRef.current)
    this.setState({ observer })
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.keyDownHandler.bind(this))
    this.state.observer?.disconnect()
  }

  componentDidUpdate (prevProps, prevState) {
    const customHeader = this.containerRef.current.querySelector('.custom-header-container')
    const popupIcons = this.containerRef.current.querySelector('.popup-icons')
    const title = this.containerRef.current.querySelector('.title-container')
    const margin = (popupIcons?.offsetWidth ?? 0) - ((customHeader?.offsetWidth) ?? 0)

    if (margin !== prevState.titleLeftMargin) {
      this.setState({ titleLeftMargin: margin })
      title.style.marginLeft = margin + 'px'
    }

    if (!prevProps.editTitle && this.props.editTitle) {
      title.focus()

      // Set cursor to the end of the text
      const range = document.createRange()
      const selection = window.getSelection()
      range.selectNodeContents(title)
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    }

    if (prevProps.editTitle
      && !this.props.editTitle
      && this.state.newTitle !== this.props.title) {
      this.props.onTitleChange(this.state.newTitle)
    }
  }

  keyDownHandler (e) {
    if (e.key === 'Escape') {
      this.props.closeHandler()
    }
  }

  handlePopupPosition (e) {
    this.setState({
      isMouseDown: true,
      movingOffset: {
        left: e.clientX - this.containerRef.current.offsetLeft,
        top: e.clientY - this.containerRef.current.offsetTop
      }
    })
  }

  render () {
    return (
      <div
        className='popup-component'
        onClick={e => {
          if (this.props.isClosable && e.target.className.includes('popup-component')) {
            this.props.closeHandler()
          }
        }}
        onMouseUp={e => {
          if (!this.props.isFloating) {
            return
          }
          this.setState({
            isMouseDown: false
          })
        }}
        onMouseMove={e => {
          if (
            !this.props.isFloating
            || !this.state.isMouseDown
            || !e.buttons
          ) {
            return
          }
          let left = e.clientX - this.state.movingOffset.left
          if (left <= 0) {
            left = 0
          }
          let top = e.clientY - this.state.movingOffset.top
          if (top <= 0) {
            top = 0
          }
          if (top > window.innerHeight - 40) {
            top = window.innerHeight - 40
          }
          this.containerRef.current.style.left = left + 'px'
          this.containerRef.current.style.top = top + 'px'
          this.props.moveHandler({ left, top })
        }}
      >
        <div
          ref={this.containerRef}
          className='popup-container'
        >
          <div
            className={clsx('popup-header', this.props.isFloating && 'floating')}
            onMouseDown={e => {
              const disallowedToMove = !this.props.isFloating || !e.target.querySelectorAll('.custom-header-container').length > 0 || !e.target.querySelectorAll('.popup-icons').length > 0
              if (disallowedToMove) {
                return
              }
              this.handlePopupPosition(e)
            }}
          >
            <div className='custom-header-container'>
              {this.props.customHeader()}
            </div>
            <div
              contentEditable={this.props.editTitle}
              className={clsx('title-container', this.props.editTitle ? 'cursor-text' : 'cursor-move select-none')}
              style={{
                maxWidth: `calc(60vw - ${this.state.titleLeftMargin}px - ${this.props.titleLeftMargin ?? 0}px)`
              }}
              onMouseDown={e => {
                if (!this.props.isFloating || this.props.editTitle) {
                  return
                }
                this.handlePopupPosition(e)
              }}
              onKeyUp={e => {
                const plainText = e.target.innerText
                  .replace(/\r?\n|\r/g, '') // Remove new lines
                  .replace(/<[^>]+>/g, '') // Remove HTML tags
                this.setState({
                  newTitle: plainText
                })
              }}
              onPaste={e => {
                e.preventDefault()
                const plainText = e.clipboardData.getData('text/plain')
                document.execCommand('insertText', false, plainText)
              }}
            >
              <span
                className={clsx('title hover-underline', this.props.editTitle && 'active')}
                title={this.props.title}
              >
                {this.props.title}
              </span>
            </div>
            <div className='popup-icons'>
              {this.props.customIcons()}
              <img
                src='/static/images/icons/hitl-close.svg'
                name='disabled_by_default'
                onClick={() => {
                  this.props.closeHandler()
                }}
              />
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Popup
