const getMainRoute = options => {
  if (options.isMock) {
    return 'mock'
  } else if (options.isOAuth) {
    return 'oauth'
  } else if (options.isChore) {
    return 'chore'
  }
  return 'api'
}

const getHost = () => {
  if (window.isUIDevelopment) {
    return 'https://api.base64.ai'
  }
  return ''
}

export const request = (options = {}, callback, errorHandler) => {
  // Response status
  let status
  // Error flag
  let error = false
  // Method
  const method = options.method || 'POST'
  // Body
  const body = JSON.stringify(options.body || {})
  // Headers
  const headers = new Headers({
    'Content-Type': 'application/json; charset=utf-8',
    ...options.headers
  })
  // Request
  fetch(`${getHost()}/${getMainRoute(options)}` + options.endpoint, {
    method,
    mode: 'cors',
    credentials: 'include',
    headers,
    ...(method === 'POST' ? { body } : {})
  })
    // Response handler
    .then(response => {
      status = response.status
      if (!response.ok) {
        error = true
      }
      return response.text()
    })
    .then(responseText => {
      let response
      try {
        response = JSON.parse(responseText)
      } catch (e) {
        if (responseText.includes('429 Too Many Requests')) {
          response = { message: 'The rate limit exceeded. Please try again after 60 seconds.', code: 'B64C1429' }
        } else {
          response = { message: responseText, code: 'B64C1004' }
        }
      }
      if (error) {
        const errorText = response.message || response.statusText
        if (status >= 500) {
          console.error(`[${status}]`, errorText)
          error = errorText || 'There was an error processing your request.'
        } else if (status >= 401) {
          error = errorText || 'Unauthorized'
        } else {
          error = errorText || 'An unknown error occurred.'
        }
        if (response.code) {
          error = `${error} (Error code: ${response.code})`
        }
      }
      callback && callback(
        error ? new Error(error) : null,
        response
      )
    })
    .catch(err => {
      if (errorHandler) {
        errorHandler(err)
      } else {
        throw new Error(err)
      }
    })
}
