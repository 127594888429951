import { useFlows } from '../../providers/FlowContext'
import ShowFlowResults from './ShowFlowResults'
import { useLocationContext } from '../../providers/LocationContext'

const ResultList = props => {
  const { flows, setFlows } = useFlows()
  const { searchParamsObject, setSearchParams } = useLocationContext()

  return (
    <ShowFlowResults
      {...props}
      flows={flows}
      setFlows={setFlows}
      searchParamsObject={searchParamsObject}
      changeSearchParams={setSearchParams}
    />
  )
}

export default ResultList
