export const handleModels = (modelsData, flow, customModelsData, options) => {
  if (!modelsData) {
    return []
  }
  const models = Object.keys(modelsData).sort((a, b) => a.localeCompare(b))
  const data = [
    {
      label: 'Models',
      path: '',
      options: []
    }
  ]
  models.forEach(model => {
    const parentModelPath = model.replace(/\/?[\w-]+$/, '')
    let [parentModelData] = data.filter(item =>
      item.path === parentModelPath
    )
    if (!parentModelData) {
      data.push({
        label: modelsData[parentModelPath],
        path: parentModelPath,
        options: []
      })
      parentModelData = data[data.length - 1]
    }
    parentModelData.options.push({
      label: modelsData[model],
      value: model
    })
  })
  if (customModelsData?.length && !flow) {
    const newCustomModelsData = {
      label: 'Custom models',
      options: customModelsData
    }
    data.unshift(newCustomModelsData)
  } else if (customModelsData?.length && Object.keys(flow?.customModels || {}).length) {
    const filteredCustomModels = customModelsData.filter(cm => cm.value && flow.customModels[cm.value])
    if (filteredCustomModels.length) {
      const newCustomModelsData = {
        label: 'Custom models',
        options: filteredCustomModels
      }
      data.unshift(newCustomModelsData)
    }
  }
  if (flow && !options?.disableFlowFiltering) {
    return filterModelByFlowSettings(data, flow, options)
  }
  return data
}

const filterModelByFlowSettings = (modelsData, flow, options) => {
  if (!modelsData) {
    return []
  }
  const unrecognizedModel = {
    label: 'Unrecognized',
    path: 'unrecognized',
    options: [
      { label: 'Unrecognized', value: 'unrecognized' }
    ]
  }
  const reclassifyModel = {
    label: 'Reclassify',
    options: [
      { value: 'semantic', label: 'Reclassify (semantic)' }
    ]
  }
  const { modelTypes } = flow.settings || {}
  if (!modelTypes?.length) {
    if (options?.isHITL) {
      return [reclassifyModel, ...modelsData, unrecognizedModel]
    }
    return modelsData
  }
  const filteredModels = modelsData.filter(model => {
    const tmpOptions = (model.options || []).filter(option => {
      for (const modelType of modelTypes) {
        if (option.value.startsWith(modelType.value)) {
          return true
        }
      }
      return false
    })
    if (tmpOptions.length) {
      model.options = tmpOptions
      return true
    }
    return false
  })
  if (options?.isHITL) {
    return [
      ...filteredModels,
      unrecognizedModel
    ]
  }
  return filteredModels
}
