import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from '../material-icon/MaterialIcon'
import document from 'global/document'

class DocumentNavigator extends Component {
  static propTypes = {
    rotateHandler: PropTypes.func,
    setPageHandler: PropTypes.func,
    nextPageHandler: PropTypes.func,
    prevPageHandler: PropTypes.func,
    fullscreenHandler: PropTypes.func,
    clearSelectionsHandler: PropTypes.func,
    hasSelectedAnnotation: PropTypes.bool,
    activeIndex: PropTypes.number,
    isFullscreen: PropTypes.bool,
    pageCount: PropTypes.number,
    resultUuid: PropTypes.string,
    linkedResults: PropTypes.array
  }

  static defaultProps = {
    resultUuid: '',
    linkedResults: []
  }

  constructor (props) {
    super(props)
    this.state = {
      isMultiPage: this.props.pageCount > 1,
      isDocumentDropDownOpen: false,
      isPageDropDownOpen: false
    }
    this.documentNavigatorRef = React.createRef()
    this.pageNavigatorRef = React.createRef()
  }

  componentDidMount () {
    document.addEventListener('click', e => {
      if (!e.target.className.includes('document-navigator')) {
        this.setState({ isDocumentDropDownOpen: false })
      }
    })
  }

  render () {
    return (
      <div className='document-navigator-box'>
        <div className='document-navigator-container'>
          <div
            ref={this.documentNavigatorRef}
            className='document-navigator-document document-navigator-item'
            onClick={() => {
              this.setState({ isDocumentDropDownOpen: !this.state.isDocumentDropDownOpen })
            }}
          >
            {this.props.linkedResults.length > 1
            && <MaterialIcon name={this.state.isDocumentDropDownOpen ? 'expand_less' : 'expand_more'} />}
            Document: {this.props.linkedResults.indexOf(this.props.resultUuid) + 1} / {this.props.linkedResults.length}
          </div>
          {
            this.props.linkedResults.length > 1 && this.state.isDocumentDropDownOpen
            && (
              <div
                className='dropdown-container overflowed'
                style={{
                  left: this.documentNavigatorRef.current.offsetLeft + 'px',
                  width: this.documentNavigatorRef.current.offsetWidth + 'px'
                }}
              >
                <ul>
                  {
                    this.props.linkedResults.map((document, index) => {
                      return (
                        <li className={'document-navigator-dropdown-item ' + (document === this.props.resultUuid ? 'selected' : '')} key={index}>
                          <a href={`/hitl/${document}`}>
                            Document {index + 1}
                          </a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            )
          }

          {this.state.isMultiPage && (
            <div className='document-navigator-rotate document-navigator-item'>
              <img
                className='navigate-button'
                onClick={() => {
                  this.props.prevPageHandler()
                }}
                src='/static/images/icons/navigateBeforeIcon.png'
                title='Previous page'
                alt='navigate before icon'
              />
            </div>
          )}

          <div
            ref={this.pageNavigatorRef}
            className='document-navigator-document document-navigator-item'
            onClick={() => {
              this.setState({ isPageDropDownOpen: !this.state.isPageDropDownOpen })
            }}
          >
            {
              this.props.pageCount > 1
              && <MaterialIcon name={this.state.isPageDropDownOpen ? 'expand_less' : 'expand_more'} />
            }  Page: {this.props.activeIndex + 1} / {this.props.pageCount || 1}
          </div>
          {
            this.props.pageCount > 1 && this.state.isPageDropDownOpen
            && (
              <div
                className='dropdown-container overflowed'
                style={{
                  left: this.pageNavigatorRef.current.offsetLeft + 'px',
                  width: this.pageNavigatorRef.current.offsetWidth + 'px'
                }}
              >
                <ul>
                  {
                    Array(this.props.pageCount).fill(0).map((_s, index) => {
                      return (
                        <li
                          className={'document-navigator-dropdown-item ' + (index === this.props.activeIndex ? 'selected' : '')}
                          key={index}
                          onClick={
                            () => {
                              this.props.setPageHandler(index)
                              this.setState({ isPageDropDownOpen: !this.state.isPageDropDownOpen })
                            }
                          }
                        >
                          <a>
                            Page {index + 1}
                          </a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            )
          }
          {this.state.isMultiPage && (
            <div className='document-navigator-rotate document-navigator-item'>
              <img
                className='navigate-button'
                onClick={() => {
                  this.props.nextPageHandler()
                }}
                src='/static/images/icons/navigateNextIcon.png'
                title='Next page'
                alt='navigate next icon'
              />
            </div>
          )}
          <div className='document-navigator-rotate document-navigator-item'>
            <img
              className='rotate-button navigate-button'
              onClick={() => {
                this.props.rotateHandler()
              }}
              src='/static/images/icons/rotateIcon.png'
              title='Rotate image'
              alt='rotate icon'
            />
          </div>
          <div className='document-navigator-rotate document-navigator-item'>
            <img
              className='navigate-button'
              onClick={() => {
                this.props.fullscreenHandler(this)
              }}
              src={`/static/images/icons/${this.props.isFullscreen ? 'fullscreen_exit' : 'fullscreen'}_icon.png`}
              title={this.props.isFullscreen ? 'Fullscreen exit' : 'Fullscreen'}
              alt='fullscreen toogle icon'
            />
          </div>
        </div>
      </div>
    )
  }
}

export default DocumentNavigator
