import { request } from '~/helpers/request'

export const getCustomModels = (flow, callback) => {
  let customModelsData = []
  if (!Object.keys(flow?.customModels || {}).length) {
    return callback(customModelsData)
  }
  request({
    endpoint: '/custom-model' + (flow?.flowID ? `?flowID=${flow.flowID}` : ''),
    method: 'GET'
  }, (error, response) => {
    if (error) {
      return callback(customModelsData)
    }
    customModelsData = (response || []).map(cm => {
      return {
        label: cm.name,
        value: cm.type
      }
    })
    return callback(customModelsData)
  })
}
