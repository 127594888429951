export default [
  // README: Titles must be unique
  {
    title: 'What is Base64.ai?',
    answer: 'Base64.ai is a cloud-based AI service for extracting text, data, and images from various documents, including IDs, passports, invoices, and more. It identifies document types, extracts data, verifies results, and integrates seamlessly into your systems.',
    link: 'https://help.base64.ai/kb/guide/en/frequently-asked-questions-E5UgNE62k1/Steps/1489210,1489215'
  },
  {
    title: 'How do I get started?',
    answer: 'Begin your Base64.ai journey in just four simple steps: Sign up for a free account, subscribe to a plan, seamlessly integrate, and upload your first document to start experiencing the power of document processing and data extraction.',
    link: 'https://help.base64.ai/kb/guide/en/frequently-asked-questions-E5UgNE62k1/Steps/1489210,1489479'
  },
  {
    title: 'What can I do with Base64.ai Flow?',
    answer: 'Base64.ai Flow is an all-in-one platform that allows users to do all their processing in one place. Available on the cloud and on-premises, Flow offers a multitude of features to enhance your processing experience, including to ability to integrate with over 400 no-code nodes and apply two-step document verifications with Human-in-the-loop processing.',
    link: 'https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2068067'
  },
  {
    title: 'What integrations does Base64.ai support?',
    answer: 'Base64.ai supports 8 easy integration options, enabling seamless integration into your software, workflows, and RPA systems, with a quick 5-minute setup.',
    link: 'https://help.base64.ai/kb/guide/en/frequently-asked-questions-E5UgNE62k1/Steps/1489210,1489471'
  },
  {
    title: 'How do I use demo?',
    answer: 'Experience Base64.ai\'s document processing and people recognition capabilities firsthand. Explore the AI validation tools, data extraction customization, and no-code integrations for third-party services.',
    link: 'https://help.base64.ai/kb/guide/en/frequently-asked-questions-E5UgNE62k1/Steps/1489210,1489656'
  },
  {
    title: 'What is pricing structure?',
    answer: 'Pricing is usage-based with two options: subscription-based or enterprise pricing. It includes valuable services like API enhancements, third-party integrations, and dedicated account support.',
    link: 'https://help.base64.ai/kb/guide/en/frequently-asked-questions-E5UgNE62k1/Steps/1489210,1489218'
  }
]
