// Modules
import { Component } from 'react'
import { request } from '~/helpers/request'
import { withRouter } from 'react-router-dom'

// Context
import Context from '~/context/global'

// Interface
import Form from '~/interface/form/Form'

// Layout
import View from '~/layout/view/View'
import Section from '~/layout/section/Section'
import Header from '~/layout/header/Header'
import PropTypes from 'prop-types'

// View: CreateBlogPost
class CreateBlogPost extends Component {
  static contextType = Context

  constructor (params) {
    super(params)
    this.state = {}
    this.postBlog = this.postBlog.bind(this)
  }

  postBlog (data, errorCallback) {
    request({
      endpoint: '/blog/create',
      body: {
        ...data
      }
    }, (error, response) => {
      if (error) {
        errorCallback(error?.message || error.toString())
      } else {
        this.props.history.push(response.url)
      }
    })
  }

  render () {
    return (
      <View name='create-blog-post'>
        <Header>
          <h1 className='slogan'>
            Create a Blog Post
          </h1>
          <p>
            Use h2 for headers,<br />
            <code>target=&quot;_blank&quot; rel=&quot;noopener noreferrer&quot;</code> for links,<br />
            and <a href='https://html-online.com/editor/' target='_blank' rel='noopener noreferrer'>rich text formatting</a>
          </p>
        </Header>
        <div className='create-blog'>
          <Section name='form'>
            <Form
              className='blogForm'
              onDataSubmit={(data, errorCallback) => {
                this.postBlog(data, errorCallback)
              }}
            >
              <Form.Checkbox
                type='checkbox'
                name='isCaseStudy'
                reference='Click here if it is a case study'
                placeholder='Click here if it is a case study'
              />
              <Form.Input
                type='text'
                name='title'
                reference='Title'
                placeholder='Title'
                autoComplete='off'
                validator={{
                  minimumLength: 1
                }}
              />
              <Form.Input
                type='text'
                name='url'
                reference='URL'
                placeholder='URL'
                autoComplete='off'
              />
              <Form.Input
                type='text'
                name='image'
                reference='Image URL'
                placeholder='Image'
                autoComplete='off'
              />
              <Form.Input
                type='text'
                name='author'
                reference='Author'
                placeholder='Author'
                autoComplete='off'
              />
              <Form.Textarea
                type='text'
                name='description'
                col='200'
                reference='Description'
                placeholder='Description'
                autoComplete='off'
              />
              <Form.Textarea
                className='content'
                type='text'
                name='content'
                col='200'
                reference='Content'
                placeholder='Content'
                autoComplete='off'
              />
              <Form.Button>
                Post
              </Form.Button>
            </Form>
          </Section>
        </div>
      </View>
    )
  }
}

CreateBlogPost.propTypes = {
  history: PropTypes.object
}

// Export
export default withRouter(CreateBlogPost)
