// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from '~/layout/header/Header'
import View from '~/layout/view/View'
// import { loadStripe } from '@stripe/stripe-js'
import Section from '~/layout/section/Section'
import PropTypes from 'prop-types'
import pricingPlan from '~/data/pricingPlan'
import { request } from '~/helpers/request'
import Loading from '~/interface/loading/Loading'
import { isWorkingOffline, isProduction } from '../../context/environment'
import window from 'global/window'
import { triggerTrackingEvent } from '../../utilities/tracker'
import PaymentCreditsInsert from '../../content/payment-credits-insert/PaymentCreditsInsert'
import Context from '~/context/global'
import Toggle from 'react-toggle'
import { AppButton } from '../../interface/app-button/AppButton'
import clsx from 'clsx'

let loadStripe
let stripePromise
const stripeKey = isProduction()
  ? 'pk_live_51IS6OeK9xCqO45UdrSalBs1oGtzO9CchXtffdD4fAHyJO0Szq4krgFa6MitpnMw1PTbQqQBTmtC7vnHUFfDAAHSn0030pT9vZU'
  : 'pk_test_51IS6OeK9xCqO45Ud1tEDKG8LkUe6OwxvFQ2ck4zpKueibhng3c0jFictxAfztGesEwt15u13hM29ZZqAVL01c1rH00ttj9gpiC'
loadStripe = null
stripePromise = null

const featureNames = [
  'API Key',
  'Workflow management',
  '2,800+ document types',
  '400+ no-code integrations',
  'Custom model builder',
  'ISO, HIPAA, SOC-2, GDPR compliant',
  'Guaranteed uptime',
  'Dedicated account support',
  'Offline air-gapped potential'
]

const SubscribeHandler = props => {
  const { feature, period, userPlanPeriod, displayError } = props
  let { userPlan } = props
  // LEGACY SUBSCRIPTION OVERRIDE: Subscriptions are displayed as 'enterprise'
  if (userPlan === 'scale' || userPlan === 'success') {
    userPlan = 'enterprise'
  }

  const compoundPeriod = (userPlan === 'starter' || userPlan === 'enterprise') ? true : (userPlanPeriod === period)
  const isPlan = ((userPlan === feature.title.toLowerCase()) && compoundPeriod)
  const currentPlanText = (userPlan !== 'starter' && isPlan) ? 'Your Plan' : feature.buttonText
  const enterpriseText = (userPlan === 'enterprise' && !isPlan) ? 'Contact us' : currentPlanText
  const text = userPlan === 'enterprise' ? enterpriseText : (userPlan !== 'starter' && !isPlan && feature.hasPayment) ? 'Change Plan' : currentPlanText// isSubscriber && not same -> 'change plan'
  const target = userPlan === 'enterprise' ? '/contact' : feature.target // (isPlan) ? null : feature.target

  const handleClick = async () => {
    const stripe = await stripePromise
    request(
      {
        method: 'POST',
        endpoint: '/payment/subscribe',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          priceID: feature.title.toLowerCase(),
          period: period
        }
      }, (error, response) => {
        if (error) {
          console.error('Error occurred in payments', error.message)
          displayError(error)
        } else if (userPlan === 'starter') {
          triggerTrackingEvent(`subscription-button-clicked-new-${feature.title.toLowerCase()}`)
          stripe.redirectToCheckout({
            sessionId: response.id
          }).then(result => {
            if (result.error) {
              console.error('Error occurred in payments', result.error.message)
            }
          }).catch(error => {
            console.error('Error occurred in stripe checkout', error.message)
          })
        } else {
          triggerTrackingEvent(`subscription-button-clicked-updated-${feature.title}`)
          // TODO: maybe find a better way
          window.location.href = window.location.origin + '/pricing/subscription?stripeOperation=updated'
        }
      })
  }

  const hasPayment = (userPlan === 'enterprise') ? false : feature.hasPayment

  let onClick = (isPlan || !hasPayment) ? null : handleClick

  const onTarget = (isPlan && userPlan !== 'starter') ? null : target

  // AdRoll tracking
  if (feature.title.toLowerCase() === 'enterprise') {
    onClick = () => {
      triggerTrackingEvent('enterprise-subscription-button-clicked')
    }
  }

  return (
    hasPayment
      ? (
          <AppButton className={clsx('my-4 mx-8', isPlan ? 'primary-cta' : 'secondary-cta')} onClick={onClick}>{text}</AppButton>
        )
      : (
          <AppButton className={clsx('my-4 mx-8', isPlan ? 'primary-cta' : 'secondary-cta')} href={onTarget} onClick={onClick} hardLink={onTarget === '/contact'}>{text}</AppButton>
        )
  )
}

SubscribeHandler.propTypes = {
  feature: PropTypes.object,
  userPlan: PropTypes.string,
  userPlanPeriod: PropTypes.string,
  period: PropTypes.string,
  displayError: PropTypes.func
}

// View: Pricing
class SubscriptionPricing extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.formatMoney = this.formatMoney.bind(this)
    this.handlePeriodChange = this.handlePeriodChange.bind(this)
    this.componentCleanup = this.componentCleanup.bind(this)
    this.triggerPurchaseCheck = this.triggerPurchaseCheck.bind(this)
    this.displayError = this.displayError.bind(this)
    this.state = {
      period: 'annually'
    }
  }

  componentDidMount () {
    if (!isWorkingOffline() && !stripePromise) {
      import('@stripe/stripe-js').then(lib => {
        loadStripe = lib.loadStripe
        stripePromise = loadStripe(stripeKey, {
          apiVersion: '2020-08-27'
        })
      })
    }
    window.addEventListener('beforeunload', this.componentCleanup)
  }

  componentWillUnmount () {
    this.componentCleanup()
    window.removeEventListener('beforeunload', this.componentCleanup)
  }

  componentCleanup () {
    this.triggerPurchaseCheck()
  }

  triggerPurchaseCheck () {
    request({ endpoint: '/notify', method: 'POST', body: { event: 'purchaseNotBeenMade' } }, (err, result) => {
      if (err) {
        return console.error(err)
      }
    }, err => {
      console.error(err)
    })
  }

  displayError (error) {
    this.context.displayModal({
      title: 'Error',
      content: error.message,
      options: [
        {
          content: 'OK'
        }
      ],
      isClosable: true
    })
  }

  formatMoney (m) {
    return `$ ${m.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`
  }

  handlePeriodChange (e) {
    const isAnnual = e.target.checked
    this.setState({
      period: isAnnual ? 'annually' : 'monthly'
    })
  }

  render () {
    const {
      user,
      fetching
    } = this.props

    return (
      <View name='subscription-pricing'>
        <Header name='pricing' align='center'>
          <h1 className='slogan'>
            <b>Pricing</b>
          </h1>
          <h3 className='introduction'>
            <span className='intro-top'>Try For Free. Scale To Unlimited.</span><br />
            Pay only for the pages you process.
          </h3>
        </Header>
        <Section name='payment' align='center'>
          {
            user && user.email
              ? (
                  <h2 className='user-plan'>Your Current
                    Plan: <span>{user && user.subscriptionType} {user && user.subscriptionPeriod ? user.subscriptionPeriod : ''}</span>
                  </h2>
                )
              : (
                  <h2 className='user-plan'>Subscription Plans</h2>
                )
          }
          <div className='switch-container'>
            <div className='switch'>
              <label
                className={`switch-label ${this.state.period === 'monthly' ? 'active' : ''}`}
                htmlFor='period-switch'
              >Monthly
              </label>
              <Toggle
                id='period-switch'
                defaultChecked={this.state.period === 'annually'}
                onChange={this.handlePeriodChange}
                icons={{
                  checked: '♥',
                  unchecked: '✓'
                }}
              />
              <label
                className={`switch-label ${this.state.period === 'annually' ? 'active' : ''}`}
                htmlFor='period-switch'
              >Annually and save 8%
              </label>
            </div>
          </div>
          <ul className='blocks'>
            {pricingPlan.map(f => {
              return (
                <li key={f} className='sub-block'>
                  <div className='top-part'>
                    <div className='top-part-grid'>
                      <h3 className='title'>{f.title}</h3>
                      <p
                        className='description'
                      >{this.state.period === 'monthly' ? f.descriptionMonthly : f.descriptionAnnually}
                      </p>
                      <h4
                        className='subtitle'
                      >
                        {this.state.period === 'monthly' ? f.creditsMonthly : (f.creditsAnnual || f.creditsMonthly)}
                      </h4>
                    </div>
                  </div>
                  <hr className='divider' />
                  <div className='bottom-part'>
                    <ul className='list'>
                      {featureNames.map((feat, idx) => {
                        return (
                          <li key={f + feat}>
                            <div className='feature-box'>
                              {f.feats.includes(idx) && <img src='/static/images/icons/checkbox.png' className='green-overlay' />}
                            </div>
                            <p className={f.feats.includes(idx) ? 'checkmark' : 'empty'}>
                              {feat}
                            </p>
                          </li>
                        )
                      })}
                    </ul>
                    {!fetching
                      ? (
                          user && user.email
                            ? (
                                <SubscribeHandler
                                  feature={f}
                                  userPlan={user.subscriptionType}
                                  period={this.state.period}
                                  userPlanPeriod={user.subscriptionPeriod}
                                  displayError={this.displayError}
                                />
                              )
                            : (f.title?.toLowerCase() === 'enterprise'
                                ? (
                                    <SubscribeHandler
                                      feature={f}
                                      userPlan=''
                                      period={this.state.period}
                                      userPlanPeriod=''
                                      displayError={this.displayError}
                                    />
                                  )
                                : (
                                    <AppButton className='secondary-cta my-4 mx-8' href='/login?next=/pricing/subscription'>{f.buttonText}</AppButton>
                                  ))
                        )
                      : (
                          <Loading text='loading' />
                        )}
                  </div>
                </li>
              )
            })}
          </ul>
          <PaymentCreditsInsert />
        </Section>

        <Section name='roi-calculator-ad' align='center'>
          <h2 className='slogan'>
            <b>Calculate your Return on Investment (ROI)!</b><br />
          </h2>
          <p className='description'>
            Use our calculator to estimate how Base64.ai could improve<br /> your automated document processing.
          </p>
          <AppButton className='secondary-cta' href='/roi-calculator'>Go to ROI Calculator</AppButton>
        </Section>
      </View>
    )
  }
}

SubscriptionPricing.propTypes = {
  user: PropTypes.object,
  fetching: PropTypes.bool
}

// Export within router
export default withRouter(SubscriptionPricing)
