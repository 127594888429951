// Modules
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'
import Link from '../../interface/link/Link'
import { isWorkingOffline } from '~/context/environment'
import PartnerLogos from '../../content/partner-logos/PartnerLogos'
import document from 'global/document'
import window from 'global/window'
import { request } from '~/helpers/request'

// Context
import Context from '~/context/global'
import PropTypes from 'prop-types'
import { triggerTrackingEvent } from '../../utilities/tracker'
import CodeSnippet from '../../interface/code-snippet/CodeSnippet'

// Data
import integrationsCodeSnippets from '../../data/codeSnippets.js'
import { hasDatabase, isBlueWashed } from '../../context/environment'
import CustomSurvey from '../../interface/custom-survey/CustomSurvey'
import MaterialIcon from '../../interface/material-icon/MaterialIcon'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { AppButton } from '../../interface/app-button/AppButton.jsx'

class Integrations extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      recreatedApiKey: 0,
      user: this.props.user
    }
    this.copyKey = this.copyKey.bind(this)
    this.getApiKey = this.getApiKey.bind(this)
    this.recreateApiKey = this.recreateApiKey.bind(this)
    this.toContactSection = this.toContactSection.bind(this)
    this.getIntegrationList = this.getIntegrationList.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapShot) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user
      })
    }
  }

  copyKey = key => {
    document.body.focus()
    setTimeout(async () => {
      await navigator.clipboard.writeText(key)
      window.alert('The API key is copied to your clipboard!')
    }, 500)
  }

  recreateApiKey () {
    this.context.displayModal({
      title: 'Confirmation',
      content: (
        <>
          <p>Which API key you do you want to recreate? This action will invalidate the selected API key, while the other key remains intact.</p>
          <CustomSurvey
            questionnaire={['API key 1', 'API key 2']}
            onChangeHandle={e => {
              this.setState({ recreatedApiKey: e })
            }}
          />
        </>
      ),
      options: [
        {
          content: 'Cancel'
        },
        {
          content: 'Recreate',
          intent: 'negative',
          action: () => {
            request({
              endpoint: '/generate-key',
              body: {
                apiKey2: !!this.state.recreatedApiKey
              }
            }, (error, response) => {
              if (error) {
                this.context.displayModal({
                  title: 'Error',
                  content: 'Could not recreate a new API key.',
                  options: [
                    { content: 'OK' }
                  ]
                })
                return
              }
              const newKey = response.apiKey || response.apiKey2
              const keyID = response.apiKey ? '1' : '2'
              this.context.displayModal({
                title: 'Your new API key ' + keyID,
                content: (
                  <div className='api-key-container'>
                    <span className='api-key'>{newKey}</span>
                    <CopyToClipboard
                      text={newKey}
                      onCopy={() => {
                        window.alert('The new API key ' + keyID + ' is copied to your clipboard!')
                      }}
                    >
                      <MaterialIcon
                        name='content_copy'
                        style={{
                          fontVariationSettings: '\'FILL\' 0,\'wght\' 200,\'GRAD\' 0,\'opsz\' 48'
                        }}
                      >
                      </MaterialIcon>
                    </CopyToClipboard>
                  </div>
                ),
                options: [
                  { content: 'OK' }
                ],
                isClosable: true
              })
            })
          }
        }
      ],
      isClosable: true
    })
  }

  getApiKey () {
    triggerTrackingEvent('integrations-api-key-button')
    request({
      endpoint: '/key'
    }, (error, response) => {
      if (error) {
        this.context.displayModal({
          title: 'Error',
          content: 'Data cannot be fetched. Please try again later.',
          options: [
            { content: 'OK' }
          ]
        })
        return
      }
      this.context.displayModal({
        title: 'Your secret API keys',
        content: (
          <>
            <p>Base64.ai provides two API keys. Migrate to other key while you recreate the other one with zero downtime! Use either key to connect via API.</p>
            <p className='api-key-title'>API key 1</p>
            <div className='api-key-container'>
              <span className='api-key'>{response.apiKey}</span>
              <CopyToClipboard
                text={response.apiKey}
                onCopy={() => {
                  window.alert('The API key 1 is copied to your clipboard!')
                }}
              >
                <MaterialIcon
                  name='content_copy'
                  style={{
                    fontVariationSettings: '\'FILL\' 0,\'wght\' 200,\'GRAD\' 0,\'opsz\' 48'
                  }}
                >
                </MaterialIcon>
              </CopyToClipboard>
            </div>
            <p className='api-key-title'>API key 2</p>
            <div className='api-key-container'>
              {
                response.apiKey2
                  ? (
                      <>
                        <span className='api-key'>{response.apiKey2}</span>
                        <CopyToClipboard
                          text={response.apiKey2}
                          onCopy={() => {
                            window.alert('The API key 2 is copied to your clipboard!')
                          }}
                        >
                          <MaterialIcon
                            name='content_copy'
                            style={{
                              fontVariationSettings: '\'FILL\' 0,\'wght\' 200,\'GRAD\' 0,\'opsz\' 48'
                            }}
                          >
                          </MaterialIcon>
                        </CopyToClipboard>
                      </>
                    )
                  : (
                      <span className='api-key' style={{ fontStyle: 'italic', margin: 'auto' }}>Not created</span>
                    )
              }
            </div>
          </>
        ),
        options: [
          { content: 'OK' }
        ],
        isClosable: true
      })
    })
  }

  getIntegrationList () {
    let integrationList = []
    integrationList.push({
      showInOnpremises: hasDatabase(),
      name: 'flow',
      title: 'Flows',
      content: (
        <React.Fragment>
          <h3 className='introduction'>
            Flows are the easiest way to automate document processing with Base64.ai.<br />
            Automate your work in minutes using 400+ prebuilt third-party integrations.<br />
            Flows also allow humans to review the document results with<br />
            Base64.ai human-in-the-loop verification technology.
          </h3>
          {!hasDatabase()
            ? <p>Flows are only available in cloud offering</p>
            : (
                <div>
                  {
                    this.state.user?.email
                      ? <AppButton href='/flow' className='secondary-cta'>Launch Flow Manager</AppButton>
                      : <AppButton href='/login?next=/flow' className='secondary-cta'>Login to access Flows</AppButton>
                  }
                </div>
              )}
        </React.Fragment>
      )
    })
    integrationList.push({
      showInOnpremises: true,
      name: 'api',
      title: 'API integration',
      content: (
        <React.Fragment>
          <h3 className='introduction'>
            1 simple API call for all document types.<br />
            SDKs in 27 programming languages and libraries.<br className='flexible' />
            No machine learning expertise required.<br />
            <a href='https://base64.ai/api/doc' target='_blank' rel='noopener noreferrer'>Read the API docs</a>
          </h3>
          {hasDatabase() && (
            <div className='api-key-buttons'>
              {
                this.state.user?.email
                  ? (
                      <>
                        <AppButton className='secondary-cta' onClick={this.recreateApiKey}>Recreate an API key</AppButton>
                        <AppButton className='primary-cta' onClick={this.getApiKey}>Get your API key</AppButton>
                      </>
                    )
                  : (
                      <>
                        <AppButton className='secondary-cta' href='/login?next=/integrations'>Login to recreate an API key</AppButton>
                        <AppButton className='primary-cta' href='/login?next=/integrations'>Login to see your API key</AppButton>
                      </>
                    )
              }
            </div>
          )}
          <CodeSnippet examples={integrationsCodeSnippets} user={this.state.user} />
        </React.Fragment>
      ),
      style: { display: 'flex' }
    })
    integrationList.push({
      name: 'no-code',
      title: 'No-code AI',
      content: (
        <React.Fragment>
          <h3 className='introduction'>
            No-code AI allows rapid integration without coding
          </h3>
          {isWorkingOffline()
            ? <p>The no-code integrations are only available in the cloud offerings</p>
            : (
                <ul className='noCode'>
                  <li key='no-code-injection' style={{ maxWidth: '25rem' }}>
                    <Link to='/no-code/injection'>
                      <p className='headline'>Allow users to fill forms on your website with a document upload</p>
                      <AppButton
                        className='secondary-cta'
                        onClick={() => {
                          triggerTrackingEvent('integrations-add-ai-button')
                        }}
                      >Add AI to your website
                      </AppButton>
                    </Link>
                  </li>
                  <li key='no-code-form' style={{ maxWidth: '25rem' }}>
                    <Link to='/no-code/form'>
                      <p className='headline'>Allow users to upload documents directly into your systems</p>
                      <AppButton
                        className='secondary-cta'
                        onClick={() => {
                          triggerTrackingEvent('integrations-smart-forms-button')
                        }}
                      >Create smart forms
                      </AppButton>
                    </Link>
                  </li>
                </ul>
              )}
        </React.Fragment>
      )
    })
    integrationList.push({
      name: 'hardware-partners',
      title: 'Hardware partners',
      content: (
        <React.Fragment>
          <h3 className='introduction'>
            Our hardware partners provide priority access to GPU resources for Base64.ai on-premises customers and scanners that embed our AI for your high-volume paper document processing needs
          </h3>
          <PartnerLogos
            action={() => {
              triggerTrackingEvent('integrations-hardware-partner-logo')
            }}
            type='hardware'
          />
        </React.Fragment>
      )
    })
    integrationList.push({
      name: 'marketplace-partners',
      title: 'Marketplace partners',
      content: (
        <React.Fragment>
          <h3 className='introduction'>
            Use one of our existing marketplace partners for an in-cloud integration
          </h3>
          <PartnerLogos
            action={() => {
              triggerTrackingEvent('integrations-marketplace-partner-logo')
            }}
            type='marketplace'
          />
        </React.Fragment>
      )
    })
    integrationList.push({
      showInOnpremises: !isBlueWashed(),
      name: 'rpa-partners',
      title: 'RPA partners',
      content: (
        <React.Fragment>
          <h3 className='introduction'>
            Our solution is integrated into many RPA (Robotic Process Automation) products
          </h3>
          <PartnerLogos
            action={() => {
              triggerTrackingEvent('integrations-rpa-partner-logo')
            }}
            type='rpa'
          />
        </React.Fragment>
      )
    })
    integrationList.push({
      name: 'offline',
      title: 'On-premises',
      content: (
        <React.Fragment>
          <h3 className='introduction'>
            No data leaves your data center with our on-premises, air-gapped, and offline solution
          </h3>
          <AppButton
            className='secondary-cta'
            href='/solutions/offline-air-gapped-networks'
            onClick={() => {
              triggerTrackingEvent('integrations-on-premises-button')
            }}
          >
            Learn more
          </AppButton>
        </React.Fragment>
      )
    })
    integrationList.push({
      name: 'solution-partners',
      title: 'Solution partners',
      content: (
        <React.Fragment>
          <h3 className='introduction'>
            Work with one of the leading RPA experts around the globe
          </h3>
          <PartnerLogos
            action={() => {
              triggerTrackingEvent('integrations-solution-partner-logo')
            }}
            type='solution'
          />
        </React.Fragment>
      )
    })
    integrationList.push({
      name: 'api',
      title: 'Integration Wizard',
      content: (
        <React.Fragment>
          <h3 className='introduction'>
            Not sure where to start? Base64.ai&apos;s Integration Wizard will help you choose the
            best integration options to automate your document processing,
            based on your desired workflow.
          </h3>
          <AppButton className='secondary-cta' href='/integration-wizard'>Use Integration Wizard</AppButton>
        </React.Fragment>
      )
    })
    integrationList = integrationList.filter(s => {
      if (isWorkingOffline() && !s.showInOnpremises) {
        return false
      }
      return true
    })
    return integrationList
  }

  toContactSection () {
    window.scrollBy({
      top: 10000,
      behavior: 'smooth'
    })
  }

  render () {
    return (
      <View name='integrations'>
        <Header name='integrations'>
          <h1 className='slogan'>{isWorkingOffline() ? 'Many' : `${this.getIntegrationList().length} Easy`} Ways to Integrate</h1>
          <h3 className='introduction'>
            Add the power of Base64.ai to your existing software,<br className='flexible' />
            workflows, and RPA systems. Get started in 5 minutes!
          </h3>
        </Header>
        {!this.state.user?.subscriptionType && !isWorkingOffline()
        && (
          <Section name='api' align='center' style={{ display: 'flex' }} tight={true}>
            <h2 className='slogan'>Subscribe now!</h2>
            <h3 className='introduction'>
              Choose your payment plan to ensure your integration does not get interrupted if you run out of credits.
            </h3>
            <AppButton className='secondary-cta' href='/pricing'>See pricing</AppButton>
          </Section>
        )}
        {
          this.getIntegrationList().map((integration, index) => (
            <Section
              key={index}
              name={integration.name}
              align='center'
              style={integration.style || {}}
              tight={true}
            >
              <h2 className='slogan'>Option {index + 1}: {integration.title}</h2>
              {integration.content}
            </Section>
          ))
        }
      </View>
    )
  }
}

Integrations.propTypes = {
  user: PropTypes.object
}

// Export within router
export default withRouter(Integrations)
