// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'
import Select from 'react-select'
import { Prompt } from 'react-router-dom'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
/* SSR-IGNORE */ import 'prismjs/themes/prism.css'

// Constants
import { Messages } from '~/constants'

// Context
import Context from '~/context/global'

// Helpers
import { request } from '~/helpers/request'

// Interface
import Link from '~/interface/link/Link'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'

// Layout
import View from '~/layout/view/View'
import Errors from '~/views/errors/Errors'
import window from 'global/window'
import { handleModels } from '../../helpers/handleModels'
import Checkbox from '../../interface/checkbox/Checkbox'
import { AppButton } from '../../interface/app-button/AppButton'

const NEW_FORM_KEY = 'new'
const TYPE_INJECTION = 'injection'
const TYPE_FORM = 'form'

// View: NoCodeDemo
class NoCodeDemo extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      user: this.props.user,
      activeSelectFilter: null,
      formUuid: this.props.formUuid,
      noCodeConfigurations: undefined,
      type: this.props.type,
      isUpload: false,
      isSubmitted: true,
      origin: null,
      pathname: null,
      flows: null,
      selectedFlow: null
    }
    this.configuration = {
      models: [],
      modelsData: [],
      summarizedModelsData: []
    }
    const defaultStyle = {
      color: '#3d3f47',
      fontSize: '0.9em',
      fontFamily: 'inherit',
      fontWeight: 300
    }
    this.configuration.filterStyle = {
      singleValue: (provided, _state) => {
        return {
          ...provided,
          ...defaultStyle
        }
      },
      input: (provided, _state) => {
        return {
          ...provided,
          ...defaultStyle
        }
      },
      menu: (provided, _state) => {
        return {
          ...provided,
          ...defaultStyle
        }
      },
      control: (provided, _state) => {
        return {
          ...provided,
          ...defaultStyle
        }
      }
    }
    this.deleteForm = this.deleteForm.bind(this)
    this.loadConfiguration = this.loadConfiguration.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.parseJson = this.parseJson.bind(this)
    this.renderIntegration = this.renderIntegration.bind(this)
    this.validateJson = this.validateJson.bind(this)
    this.unloadHandler = this.unloadHandler.bind(this)
    this.getFlows = this.getFlows.bind(this)
  }

  componentDidMount () {
    window.addEventListener('beforeunload', this.unloadHandler)
    this.updateData()
    this.getFlows()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.user === this.props.user) {
      return
    }
    this.updateData()
  }

  getSelectableFlow (flowID) {
    const { flows } = this.state
    const flow = flows.find(ff => ff.value === flowID) || null
    return flow
  }

  getFlows () {
    request({
      endpoint: '/flow/default',
      method: 'POST'
    }, (error, flows) => {
      if (error) {
        return this.setState({
          flows: null
        })
      }
      const defaultFlow = flows.find(f => f.isDefault)
      this.setState({
        selectedFlow: defaultFlow?.flowID
          ? {
              value: defaultFlow.flowID,
              label: defaultFlow.name
            }
          : null
      })
      flows = flows.map(f => {
        return {
          value: f.flowID,
          label: f.name,
          isDefault: f.isDefault
        }
      })
      flows.sort((a, b) => Number(b.isDefault || 0) - Number(a.isDefault || 0))
      this.setState({
        flows
      })
    }, err => {
      console.error('Unable to fetch users flows. Error details:', err)
    })
  }

  updateData = () => {
    this.props.getModels && this.props.getModels((error, response) => {
      if (!error) {
        this.configuration.modelsData = response
        this.configuration.summarizedModelsData = handleModels(response)
      }
    })

    this.setState({
      user: this.props.user,
      origin: window.location.origin,
      pathname: window.location.pathname
    }, () => this.loadConfiguration())
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.unloadHandler)
  }

  unloadHandler (e) {
    if (this.state.isSubmitted === false) {
      e.preventDefault()
      e.returnValue = Messages.CHANGES_NOT_SAVED
    }
  }

  deleteForm (formUuid) {
    // console.log('formUuid', formUuid)
    this.context.displayModal({
      title: 'Are you sure?',
      content: (
        <div>
          <p>Are you sure to delete this form?</p>
          <p><b>If it is in production, it may break the functionality of the website.</b></p>
        </div>
      ),
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: formUuid === NEW_FORM_KEY ? 'Discard' : 'Delete',
          action: () => {
            const ncc = this.state.noCodeConfigurations || {}
            if (formUuid === NEW_FORM_KEY) {
              // console.log('delete new form')
              delete ncc[formUuid]
              this.setState({
                formUuid: null,
                noCodeConfigurations: ncc
              }, () => {
                this.context.redirect(`/no-code/${this.state.type}`)
                window.scrollBy({
                  top: -10000,
                  behavior: 'smooth'
                })
              })
              return
            }
            // console.log('delete old form', formUuid)
            ncc[formUuid].status = 'deleted'
            this.submitForm(formUuid)
          }
        }
      ],
      isClosable: true
    })
  }

  loadConfiguration () {
    const endpoint = this.state.type === TYPE_INJECTION
      ? '/no-code/config'
      : this.state.type === TYPE_FORM
        ? '/no-code-form/config'
        : ''

    if (!this.state?.user?.email || !endpoint) {
      return
    }
    request({
      endpoint
    }, (error, results) => {
      if (error) {
        return alert('Unable to load configuration: ' + error)
      }
      const resultMap = {};
      (results || []).forEach(r => {
        resultMap[r.key] = r
      })
      this.setState({
        noCodeConfigurations: resultMap
      })
    }, error => alert('Unable to load configuration: ' + error))
  }

  parseJson (data) {
    if (!data) {
      return ''
    }
    try {
      if (
        typeof data === 'object') {
        return JSON.stringify(data, null, 2)
      }
    } catch (e) {
      // eat
    }
    return data || ''
  }

  submitForm (formUuid) {
    this.setState({ isUpload: true })
    const errors = []
    const ncc = this.state.noCodeConfigurations[formUuid] || {}
    if (!ncc.name) {
      errors.push('Form name cannot be empty')
    }
    if (this.state.type === TYPE_INJECTION) {
      ncc.dom = ncc.dom || {}
      if (!ncc.allowedDomains || !ncc.allowedDomains.length) {
        errors.push('Allowed domains cannot be empty')
      } else {
        ncc.allowedDomains = ncc.allowedDomains.map(s => {
          const match = s.match(/\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b|(localhost)/)
          if (match) {
            return match[0].trim()
          } else {
            errors.push(`${s} is an invalid domain`)
            return s
          }
        })
      }
      if (!ncc.dropText) {
        errors.push('Upload zone text cannot be empty')
      }
      if (!ncc.errorMessage) {
        errors.push('Error message cannot be empty')
      }
      if (!ncc.glareErrorMessage) {
        errors.push('Glare error message cannot be empty')
      }
      if (!ncc.blurErrorMessage) {
        errors.push('Blur error message cannot be empty')
      }
      if (!ncc.dom.querySelector) {
        errors.push('CSS query selector cannot be empty')
      }
      if (isNaN(parseInt(ncc.dom.selectorIndex))) {
        errors.push('Selector index is not a number')
      } else if (parseInt(ncc.dom.selectorIndex) < 0) {
        errors.push('Selector index cannot be negative')
      } else {
        ncc.dom.selectorIndex = `${parseInt(ncc.dom.selectorIndex)}`
      }
      if (isNaN(parseInt(ncc.dom.insertBeforeIndex))) {
        errors.push('Insert index is not a number')
      } else if (parseInt(ncc.dom.insertBeforeIndex) < 0) {
        errors.push('Insert index cannot be negative')
      } else {
        ncc.dom.insertBeforeIndex = `${parseInt(ncc.dom.insertBeforeIndex)}`
      }
      ncc.reuseResults = !!ncc.reuseResults
      if (typeof ncc.script === 'string' && !ncc.script.match(/result\./)) {
        errors.push('Script has no reference to "result"')
      }
      this.validateJson(ncc, 'styles', true, null, errors)
    } else if (this.state.type === TYPE_FORM) {
      if (!ncc?.flowID) {
        errors.push('Integration flow cannot be empty')
      }
    }
    if (errors.length && ncc && ncc.status !== 'deleted') {
      this.context.displayModal({
        title: 'Errors in form',
        content: (
          <div>
            <p>Please correct the following errors:</p>
            <ul
              style={{
                textAlign: 'left',
                marginTop: '1rem',
                listStyle: 'auto'
              }}
            >
              {errors.map((e, i) => <li key={i}>{e}</li>)}
            </ul>
          </div>
        ),
        options: [
          { content: 'OK' }
        ],
        isClosable: true
      })
      this.setState({
        isUpload: false,
        isSubmitted: true
      })
      return
    }
    request({
      endpoint: (this.state.type === TYPE_INJECTION
        ? '/no-code/config/'
        : (this.state.type === TYPE_FORM
            ? '/no-code-form/config/'
            : null)) + formUuid,
      body: {
        noCodeConfig: ncc
      }
    }, (error, result) => {
      // console.log('Update call response', result)
      this.setState({ isUpload: false })
      if (error) {
        alert('Unable to save configuration: ' + error)
      } else {
        const config = this.state.noCodeConfigurations || {}
        config[result.key] = result
        if (result.status === 'deleted') {
          // console.log('remove from state', result.key)
          delete config[result.key]
        }
        this.setState({
          formUuid: result.status === 'deleted' ? null : result.key,
          noCodeConfigurations: config,
          isSubmitted: true
        }, () => {
          this.context.displayModal({
            title: 'Success',
            content: 'Configuration is saved and is live in production.',
            isClosable: true,
            options: [
              {
                content: 'OK',
                action: () => {
                  if (result.status === 'deleted') {
                    this.context.redirect(`/no-code/${this.state.type}`)
                  } else {
                    this.context.redirect(`/no-code/${this.state.type}/${result.key}`)
                  }
                  window.scrollBy({
                    top: (result.status === 'deleted') ? -10000 : 500,
                    behavior: 'smooth'
                  })
                }
              }
            ]
          })
        })
      }
    }, error => alert('Unable to save configuration: ' + error))
  }

  emailAddressesFormatter (emailAddresses, errors) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailAddresses.replace(/ /g, '').split(',').map(mail => {
      const match = mail.match(re)
      if (match) {
        return match[0]
      } else {
        errors.push('Invalid email address : ' + mail)
        return mail
      }
    })
  }

  validateJson (ncc, fieldName, canBeEmpty, regexChecks, errors) {
    if (canBeEmpty && !ncc[fieldName]) {
      // This is fine...
    } else if (!ncc[fieldName]) {
      errors.push(`${fieldName} cannot be empty`)
    } else {
      if (typeof ncc[fieldName] === 'object') {
        try {
          JSON.stringify(ncc[fieldName])
        } catch (e) {
          errors.push(`${fieldName} is not a valid object`)
        }
      } else {
        (regexChecks || []).forEach(rc => {
          if (!ncc[fieldName].match(rc)) {
            errors.push(`${fieldName} do not have a reference to "${rc}"`)
          }
        })
        try {
          ncc[fieldName] = JSON.parse(ncc[fieldName])
        } catch (e) {
          errors.push(`${fieldName} is not a valid JSON`)
        }
      }
    }
  }

  renderFormLink () {
    if (!this.state.formUuid || this.state.formUuid === NEW_FORM_KEY) {
      return null
    }
    const name = this.state.formUuid && this.state.noCodeConfigurations && this.state.noCodeConfigurations[this.state.formUuid]
      ? this.state.noCodeConfigurations[this.state.formUuid].name.toLowerCase().replace(/[^\w]/g, '-').replace(/[-]+/g, '-') + '-'
      : ''
    return (
      <Header name='header'>
        <h1 className='slogan'>No-Code Form</h1>
        <h3 className='introduction'>Your AI form is ready to use! Access your page from the link below</h3>
        <Link to={'/widgets/document-processing/' + name + this.state.formUuid} target='_blank' rel='noopener noreferrer' style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
          {this.state.origin}/widgets/document-processing/{name}{this.state.formUuid}
        </Link>
      </Header>
    )
  }

  renderIntegration () {
    if (this.state.formUuid === NEW_FORM_KEY) {
      return null
    }
    const key = this.state.formUuid || <span style={{ color: 'red' }}>YOUR_FORM_ID</span>
    const name = this.state.formUuid && this.state.noCodeConfigurations && this.state.noCodeConfigurations[this.state.formUuid]
      ? this.state.noCodeConfigurations[this.state.formUuid].name.toLowerCase().replace(/[^\w]/g, '-').replace(/[-]+/g, '-') + '-'
      : ''
    return (
      <Header name='header'>
        <h1 className='slogan'>No-Code Integration</h1>
        <h2 className='introduction'>Test it on your browser</h2>
        <h3 className='introduction'>
          <ol>
            <li>
              Go to the form&apos;s web page
            </li>
            <li>
              Open your browser&apos;s Developer Console
              (
              <a
                href='https://support.airtable.com/hc/en-us/articles/232313848-How-to-open-the-developer-console'
                target='_blank'
                rel='noopener noreferrer'
              >
                instructions
              </a>
              )
            </li>
            <li>
              Copy-paste this code and hit Enter. You should see the uploader on the form.<br />
              If you want to make changes, reload the page before copy-pasting again.
            </li>
          </ol>
          <h3
            className='code'
            style={{ fontSize: this.state.formUuid ? '0.85rem' : '0.78rem' }}
          >
            {'var b=function(path){var s=document.createElement("script"); s.type="text/javascript"; s.src="' + this.state.origin + '/scripts/noCode.js?key=' + name + key + '"; document.head.appendChild(s);}()'}
          </h3>
        </h3>
        <h3 className='introduction'><b>Ready for production? Add the following line to your website HTML:</b>
        </h3>
        <h3
          className='code'
          style={{ fontSize: this.state.formUuid ? '0.85rem' : '0.78rem' }}
        >
          &lt;script
          type=&quot;text/javascript&quot; src=&quot;{this.state.origin}/scripts/noCode.js?key={name + key}&quot;&gt;&lt;/script&gt;
        </h3>
        <h3 className='introduction'>
          You can also add this line to
          <a
            href='https://tagmanager.google.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Google Tag Manager
          </a>
          &nbsp;or&nbsp;
          <a
            href='https://www.g2.com/products/google-tag-manager/competitors/alternatives'
            target='_blank'
            rel='noopener noreferrer'
          >
            similar products
          </a>.<br />
          Make sure to run the script on &quot;All Pages&quot; and &quot;History Change&quot; event.<br />
          <br />
          <img src='/static/content/features/no-code/google-tag-manager.png' />
        </h3>

      </Header>
    )
  }

  typeStyles = {
    control: styles => ({ ...styles, paddingBottom: '1px' })
  }

  render () {
    const {
      user
    } = this.props
    const wasUserLoggedIn = this.state.user?.email || user?.email
    const {
      activeSelectFilter,
      formUuid,
      noCodeConfigurations,
      type,
      flows
    } = this.state
    const {
      modelsData,
      summarizedModelsData,
      filterStyle
    } = this.configuration

    if ((noCodeConfigurations && formUuid) && (noCodeConfigurations[formUuid] || formUuid === NEW_FORM_KEY)) {
      noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
    }
    const ncc = noCodeConfigurations && formUuid ? noCodeConfigurations[formUuid] : null
    const filter = (activeSelectFilter === null)
      ? ((ncc && ncc.acceptedTypes) || []).map(t => {
          return {
            value: t,
            label: modelsData[t] || t
          }
        })
      : activeSelectFilter
    const flowFilter = ncc && ncc.flowID && this.getSelectableFlow(ncc.flowID)
    const styleValue = this.parseJson(ncc && ncc.styles)
    const typeString = type === TYPE_INJECTION ? 'script' : type === TYPE_FORM ? 'form' : 'none' // Localization!
    if (typeString === 'none') return <Errors title='It‘s 404!' />

    return (
      <View name='no-code-demo'>
        <Prompt when={!this.state.isSubmitted} message={Messages.CHANGES_NOT_SAVED} />
        {!formUuid || formUuid === NEW_FORM_KEY
          ? (
              <Header name='header'>
                <h1 className='slogan'>The Best of Artificial Intelligence,<br />But None of the Coding</h1>
                {type === TYPE_INJECTION
                  ? (
                      <>
                        <h2 className='introduction'>Allow users to fill forms on your website with a document upload</h2>
                        <h3 className='introduction'>
                          Create a smart script that inserts an upload button on your existing website.<br />
                          When the user uploads a file, it automatically fills your website forms with the document data.
                          See how it works by uploading an ID document on our <Link to='/signup' target='_blank' rel='noopener noreferrer'>signup page</Link>.<br />
                          No coding or AI experience required. Works on both desktop and mobile.
                        </h3>
                        <h3
                          className='code'
                          style={{ fontSize: '0.81rem' }}
                        >
                          &lt;script
                          type=&quot;text/javascript&quot; src=&quot;{this.state.origin}/scripts/nocode.js?key=<span style={{ color: 'red' }}>YOUR_FORM_ID</span>&quot;&gt;&lt;/script&gt;
                        </h3>
                      </>
                    )
                  : (
                      type === TYPE_FORM
                        ? (
                            <>
                              <h2 className='introduction'>Allow users to upload documents directly into your systems</h2>
                              <h3 className='introduction'>
                                Create a smart form to allow users to send documents to your email inbox, database, CRM, or as an API call.
                                The form allows the user to make amendments to the results.
                                See how it works by uploading any document on our <Link to='/widgets/document-processing/' target='_blank' rel='noopener noreferrer'>demo page</Link>.<br />
                                No coding or AI experience required. Works on both desktop and mobile.
                              </h3>
                            </>
                          )
                        : null
                    )}
                {wasUserLoggedIn
                  ? null
                  : (
                      <AppButton
                        className='secondary-cta large mt-4'
                        href={'/login?next=' + this.state.pathname}
                      >
                        Login to create your {type === TYPE_FORM ? 'form' : 'script'} for free
                      </AppButton>
                    )}
              </Header>
            )
          : null}
        {
          wasUserLoggedIn
            ? (
                formUuid
                  ? (
                      !noCodeConfigurations || (formUuid !== NEW_FORM_KEY && !noCodeConfigurations[formUuid])
                        ? (
                            <Section
                              name='input'
                              tight={true}
                            >
                              <h3 className='introduction'>Loading...</h3>
                            </Section>
                          )
                        : (
                            <>
                              <Section
                                name='input'
                                tight={true}
                                style={{ zIndex: '3' }}
                              >
                                <Link
                                  style={{
                                    marginBottom: '1rem',
                                    display: 'inline-block'
                                  }}
                                  onClick={() => {
                                    this.unloadHandler('link').then(result => {
                                      if (result) {
                                        this.setState({
                                          formUuid: null,
                                          isSubmitted: true
                                        }, () => {
                                          if (type === TYPE_INJECTION) {
                                            this.context.redirect(`/no-code/${TYPE_INJECTION}`)
                                          } else if (type === TYPE_FORM) {
                                            this.context.redirect(`/no-code/${TYPE_FORM}`)
                                          }
                                          window.scrollBy({
                                            top: -10000,
                                            behavior: 'smooth'
                                          })
                                        })
                                      }
                                    })
                                  }}
                                >
                                  &larr; Back to configuration list
                                </Link>
                                {formUuid === NEW_FORM_KEY ? <h2>Create a new {typeString}</h2> : <h2>Update the {typeString} {ncc?.name || ''}</h2>}
                                <form
                                  title='Enter the details of the form you want to supercharge'
                                >
                                  <h3>Form name</h3>
                                  <input
                                    type='text'
                                    value={(ncc && ncc.name) || ''}
                                    name='name'
                                    placeholder='e.g. Customer signup form'
                                    onChange={e => {
                                      // console.log('name', noCodeConfigurations)
                                      noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                      noCodeConfigurations[formUuid].name = e.target.value
                                      this.setState({
                                        isSubmitted: false,
                                        noCodeConfigurations
                                      })
                                    }}
                                  />
                                  <h3>Flow</h3>
                                  <div className='flow-select'>
                                    <Select
                                      isMulti={false}
                                      classNamePrefix='react-select'
                                      className='react-select'
                                      value={flowFilter}
                                      options={flows}
                                      closeMenuOnSelect={true}
                                      placeholder='Filter flows by name...'
                                      noOptionsMessage={() => 'No flow found'}
                                      menuPlacement='auto'
                                      onChange={filter => {
                                        noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                        noCodeConfigurations[formUuid].flowID = filter.value
                                        this.setState({
                                          isSubmitted: false,
                                          noCodeConfigurations
                                        })
                                      }}
                                    />
                                  </div>
                                  {type === TYPE_INJECTION
                                    ? (
                                        <>
                                          <h3>Allowed domains</h3>
                                          <input
                                            type='text'
                                            value={(ncc && Array.isArray(ncc.allowedDomains) && ncc.allowedDomains.join(',')) || ''}
                                            name='url'
                                            placeholder="e.g. example.com or app.example.com. Multiple domain is supported via comma ',' e.g. example.com, app.example.com."
                                            onChange={e => {
                                              // console.log('urls', noCodeConfigurations)
                                              noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                              noCodeConfigurations[formUuid].allowedDomains = e.target.value.split(',')
                                              this.setState({
                                                isSubmitted: false,
                                                noCodeConfigurations
                                              })
                                            }}
                                          />
                                          <h3>URL(s) where form should show (Regex is supported)</h3>
                                          <input
                                            type='text'
                                            value={(ncc && ncc.url) || ''}
                                            name='url'
                                            placeholder='e.g. https://mysite.com/signup. Leave blank for all pages that meet the query selector criteria.'
                                            onChange={e => {
                                              // console.log('urls', noCodeConfigurations)
                                              noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                              noCodeConfigurations[formUuid].url = e.target.value
                                              this.setState({
                                                isSubmitted: false,
                                                noCodeConfigurations
                                              })
                                            }}
                                          />
                                        </>
                                      )
                                    : null}
                                  <h3>Upload zone text</h3>
                                  <input
                                    type='text'
                                    value={(ncc && ncc.dropText) || ''}
                                    name='name'
                                    placeholder='e.g. Upload an ID document here'
                                    onChange={e => {
                                      // console.log('name', noCodeConfigurations)
                                      noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                      noCodeConfigurations[formUuid].dropText = e.target.value
                                      this.setState({
                                        isSubmitted: false,
                                        noCodeConfigurations
                                      })
                                    }}
                                  />
                                  <h3>Document type suggestion</h3>
                                  <input
                                    type='text'
                                    value={(ncc && ncc.errorMessage) || ''}
                                    name='name'
                                    placeholder="e.g. Please upload an ID, driver's license, or passport."
                                    onChange={e => {
                                      // console.log('name', noCodeConfigurations)
                                      noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                      noCodeConfigurations[formUuid].errorMessage = e.target.value
                                      this.setState({
                                        isSubmitted: false,
                                        noCodeConfigurations
                                      })
                                    }}
                                  />
                                  <h3>Limit document types (optional)</h3>
                                  <Select
                                    isMulti
                                    classNamePrefix='react-select'
                                    className='react-select'
                                    value={filter}
                                    styles={filterStyle}
                                    options={summarizedModelsData}
                                    closeMenuOnSelect={false}
                                    placeholder='Filter document types...'
                                    noOptionsMessage={() => 'Loading document types...'}
                                    onChange={f => {
                                      // console.log('select', noCodeConfigurations)
                                      noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                      noCodeConfigurations[formUuid].acceptedTypes = f.map(fx => fx.value)
                                      this.setState({
                                        noCodeConfigurations,
                                        isSubmitted: false
                                      })
                                    }}
                                  />
                                  <h3>Blur error message</h3>
                                  <input
                                    type='text'
                                    value={(ncc && ncc.blurErrorMessage) || ''}
                                    name='name'
                                    placeholder='e.g. The image has excess blur.'
                                    onChange={e => {
                                      // console.log('name', noCodeConfigurations)
                                      noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                      noCodeConfigurations[formUuid].blurErrorMessage = e.target.value
                                      this.setState({
                                        isSubmitted: false,
                                        noCodeConfigurations
                                      })
                                    }}
                                  />
                                  <h3>Glare error message</h3>
                                  <input
                                    type='text'
                                    value={(ncc && ncc.glareErrorMessage) || ''}
                                    name='name'
                                    placeholder='e.g. The image has excess glare.'
                                    onChange={e => {
                                      // console.log('name', noCodeConfigurations)
                                      noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                      noCodeConfigurations[formUuid].glareErrorMessage = e.target.value
                                      this.setState({
                                        isSubmitted: false,
                                        noCodeConfigurations
                                      })
                                    }}
                                  />
                                  <h3>Whitelabeling</h3>
                                  <Checkbox
                                    className='big left'
                                    showTitle={true}
                                    onChange={e => {
                                      noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                      noCodeConfigurations[formUuid].whiteLabel = e.target.checked
                                      this.setState({
                                        isSubmitted: false,
                                        noCodeConfigurations
                                      })
                                    }}
                                    title='Base64.ai gives confidence to your users that their data will be processed securely and accurately.'
                                    placeholder={'Remove "Powered by Base64.ai" from the form UI.'}
                                    checked={!!(ncc && ncc.whiteLabel)}
                                  />
                                  {type === TYPE_FORM && (
                                    <>
                                      <h3>Exports</h3>
                                      <p>The user will always see Delete (cancels the submission), Reset (reverts users changes to original result), and Submit (updates the result with user&apos;s corrections) buttons by default.</p>
                                      <Checkbox
                                        className='big left'
                                        showTitle={true}
                                        onChange={e => {
                                          noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                          noCodeConfigurations[formUuid].csvExport = e.target.checked
                                          this.setState({
                                            isSubmitted: false,
                                            noCodeConfigurations
                                          })
                                        }}
                                        title='Enable to show a CSV button for users to export their extracted results as CSV.'
                                        placeholder='Show export as CSV button.'
                                        checked={!!(ncc && ncc.csvExport)}
                                      />
                                      <Checkbox
                                        className='big left'
                                        showTitle={true}
                                        onChange={e => {
                                          noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                          noCodeConfigurations[formUuid].pdfExport = e.target.checked
                                          this.setState({
                                            isSubmitted: false,
                                            noCodeConfigurations
                                          })
                                        }}
                                        title='Enable to show a PDF button for users to export their extracted results as PDF.'
                                        placeholder='Show export as PDF button.'
                                        checked={!!(ncc && ncc.pdfExport)}
                                      />
                                    </>
                                  )}
                                  {type === TYPE_INJECTION
                                    ? (
                                        <>
                                          <h3>DOM CSS query selector (for document.querySelectorAll)</h3>
                                          <input
                                            type='text'
                                            value={(ncc && ncc.dom && ncc.dom.querySelector) || ''}
                                            name='domQuerySelector'
                                            placeholder='e.g. body > div.menu > form'
                                            onChange={e => {
                                              // console.log('qs', noCodeConfigurations)
                                              noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                              noCodeConfigurations[formUuid].dom = noCodeConfigurations[formUuid].dom || {}
                                              noCodeConfigurations[formUuid].dom.querySelector = e.target.value
                                              this.setState({
                                                isSubmitted: false,
                                                noCodeConfigurations
                                              })
                                            }}
                                          />
                                          <h3>The form&apos;s index in the above selection</h3>
                                          <input
                                            type='number'
                                            min='0'
                                            value={(ncc && ncc.dom && `${ncc.dom.selectorIndex}`) || 0}
                                            name='domSelectorIndex'
                                            placeholder='e.g. 0 (typically)'
                                            onChange={e => {
                                              // console.log('select index', noCodeConfigurations)
                                              noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                              noCodeConfigurations[formUuid].dom = noCodeConfigurations[formUuid].dom || {}
                                              noCodeConfigurations[formUuid].dom.selectorIndex = e.target.value
                                              this.setState({
                                                isSubmitted: false,
                                                noCodeConfigurations
                                              })
                                            }}
                                          />
                                          <h3>Index of element above which you want the Base64.ai file uploader</h3>
                                          <input
                                            type='number'
                                            min='0'
                                            value={(ncc && ncc.dom && `${ncc.dom.insertBeforeIndex}`) || 0}
                                            name='domInsertBeforeIndex'
                                            placeholder='e.g. 0 (if you want it on the top)'
                                            onChange={e => {
                                              // console.log('above', noCodeConfigurations)
                                              noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                              noCodeConfigurations[formUuid].dom = noCodeConfigurations[formUuid].dom || {}
                                              noCodeConfigurations[formUuid].dom.insertBeforeIndex = e.target.value
                                              this.setState({
                                                isSubmitted: false,
                                                noCodeConfigurations
                                              })
                                            }}
                                          />
                                          <h3>Override CSS style</h3>
                                          <textarea
                                            name='styles'
                                            value={styleValue}
                                            placeholder={
                                              'Override the default styles of the uploader components (' + this.state.origin + '/static/no-code/style.css) to match your website. '
                                              + 'The styles should be in Javascript (JSON) format, e.g. use "marginTop" instead of "margin-top"'
                                            }
                                            rows='10'
                                            onChange={e => {
                                              // console.log('resultstyle', noCodeConfigurations)
                                              noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                              noCodeConfigurations[formUuid].styles = e.target.value
                                              this.setState({
                                                isSubmitted: false,
                                                noCodeConfigurations
                                              })
                                            }}
                                          />
                                          <h3>
                                            <label>
                                              <input
                                                type='checkbox'
                                                name='reuseResults'
                                                checked={ncc && !!ncc.reuseResults}
                                                style={{
                                                  margin: '0 0.3rem 0 0',
                                                  width: 'unset',
                                                  height: 13
                                                }}
                                                onChange={e => {
                                                  noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                                  noCodeConfigurations[formUuid].reuseResults = !!e.target.checked
                                                  this.setState({
                                                    isSubmitted: false,
                                                    noCodeConfigurations
                                                  })
                                                }}
                                              />
                                              Reuse results on multiple pages
                                            </label>
                                          </h3>
                                          <h3>Mapping script</h3>
                                          <Editor
                                            value={(ncc && ncc.script) || ''}
                                            onValueChange={code => {
                                              noCodeConfigurations[formUuid] = noCodeConfigurations[formUuid] || {}
                                              noCodeConfigurations[formUuid].script = code
                                              this.setState({
                                                isSubmitted: false,
                                                noCodeConfigurations
                                              })
                                            }}
                                            placeholder={'Javascript to consume the Base64.ai "result" to fill up the form, e.g. "document.getElementsById(\'last_name\').value = result.fields.familyName.value;"'}
                                            highlight={code => highlight(code, languages.javascript, 'javascript')}
                                            padding={10}
                                            style={{
                                              fontFamily: '"Fira code", "Fira Mono", monospace',
                                              fontSize: 12,
                                              backgroundColor: 'white',
                                              minHeight: '165px',
                                              border: '1px solid rgba(61, 63, 71, 0.25)'
                                            }}
                                          />
                                        </>
                                      )
                                    : null}
                                  <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '1rem' }}>
                                    <AppButton
                                      className='primary-cta large danger'
                                      onClick={() => this.state.isUpload ? null : this.deleteForm(formUuid)}
                                    >
                                      {formUuid === NEW_FORM_KEY ? 'Discard' : 'Delete'}
                                    </AppButton>
                                    <AppButton
                                      className='secondary-cta large'
                                      onClick={() => this.state.isUpload ? null : this.submitForm(formUuid)}
                                    >
                                      {formUuid === NEW_FORM_KEY ? 'Create' : 'Update'}
                                    </AppButton>
                                  </div>
                                </form>
                              </Section>
                              {type === TYPE_INJECTION
                                ? this.renderIntegration()
                                : null}
                              {type === TYPE_FORM
                                ? this.renderFormLink()
                                : null}
                            </>
                          )
                    )
                  : (
                      <>
                        <Section
                          name='input'
                          tight={true}
                        >
                          <>
                            <ul className='list'>
                              <li key='-1'>
                                <Link
                                  to={`/no-code/${type}/new`}
                                  onClick={() => {
                                    this.setState({ formUuid: NEW_FORM_KEY })
                                  }}
                                  style={{
                                    float: 'left'
                                  }}
                                >
                                  ★ Create a
                                  new {type === TYPE_INJECTION ? 'Injection Script' : null}{type === TYPE_FORM ? 'Form' : null}
                                </Link>
                              </li>
                              {
                                noCodeConfigurations === undefined
                                  ? (
                                      <p style={{ lineHeight: '4rem' }}>Loading your forms...</p>
                                    )
                                  : (
                                      Object.keys(noCodeConfigurations).length === 0
                                        ? <p style={{ lineHeight: '4rem' }}>You have no existing forms.</p>
                                        : (
                                            Object.keys(noCodeConfigurations).map((ncck, i) => (
                                              <li key={i}>
                                                <Link onClick={() => {
                                                  this.setState({
                                                    formUuid: ncck
                                                  })
                                                  this.context.redirect(`/no-code/${this.state.type}/${ncck}`)
                                                  window.scrollBy({
                                                    top: -10000,
                                                    behavior: 'smooth'
                                                  })
                                                }}
                                                >{noCodeConfigurations[ncck].name || 'Draft form'}
                                                </Link>
                                              </li>
                                            ))
                                          )
                                    )
                              }
                            </ul>
                          </>
                        </Section>
                      </>
                    )
              )
            : null
        }
      </View>
    )
  }
}

NoCodeDemo.propTypes = {
  getModels: PropTypes.func,
  formUuid: PropTypes.string,
  user: PropTypes.object,
  type: PropTypes.string
}

// Export
export default NoCodeDemo
