import PropTypes from 'prop-types'
import Loading from '../loading/Loading'
import FormatLLMResponseWithSources from '../../utilities/modifiers'
import ClipboardButton from '../clipboard-button/ClipboardButton'
import RetryButton from '../retry-button/RetryButton'
import RemoveButton from '../remove-button/RemoveButton'
import AddButton from '../add-button/AddButton'

const QuestionAnswerContainer = ({ id, dialog, onAdd, onRetry, onRemove, onClick, autoPlay }) => {
  return (
    <div className='result-box-container' id={id}>
      <div className='left item' style={{ paddingTop: '0.1rem' }}>
        <AddButton
          onAdd={onAdd}
        />
      </div>
      <div onClick={onClick} className='mid item'>
        <div className='result-box-title'>
          {dialog.question}
        </div>
        <div className='value'>
          {dialog.answer
            ? <FormatLLMResponseWithSources response={dialog} />
            : <div className='mt-1 mx-auto'><Loading /></div>}
        </div>
        {dialog.audioFileSource && dialog.answer && (
          <div className='mt-2'>
            <audio className='audio-player' controls='controls' autoPlay={autoPlay}>
              <source src={dialog.audioFileSource} />
            </audio>
          </div>
        )}
      </div>
      <div className='right item hover-icons'>
        <RemoveButton
          onRemove={onRemove}
        />
        <ClipboardButton
          contentToCopy={dialog.answer}
        />
        <RetryButton
          onRetry={onRetry}
          retryCount={dialog.retryCount}
        />
      </div>
    </div>
  )
}

QuestionAnswerContainer.propTypes = {
  onAdd: PropTypes.func,
  onRetry: PropTypes.func,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  id: PropTypes.string,
  autoPlay: PropTypes.bool,
  dialog: PropTypes.object
}

export default QuestionAnswerContainer
