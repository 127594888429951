import PropTypes from 'prop-types'
import { useFlows } from '../../providers/FlowContext'
import InfoButton from '../info-button/InfoButton'

const SourceInfo = ({ source, questionObj }) => {
  const { flows } = useFlows()
  const { question, answer } = questionObj ?? {}
  const flowUUID = source?.flowUUID
  const flow = flows.find(flow => flow.flowID === flowUUID)

  const annotation = {
    question,
    answer
  }
  if (source?.location) {
    annotation.location = source.location
  }

  const annotationsStr = encodeURIComponent(JSON.stringify(annotation, null, 0))

  const sourceText = source?.type === 'reference'
    ? <><b>{source?.label}</b> in the <b>{flow?.name}</b></>
    : 'This document'

  const onClick = () => source?.type === 'reference'
    ? window.open(`/hitl/${source?.value}?annotations=[${annotationsStr}]`)
    : false

  return (
    <InfoButton
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      className='inline-block text-lg align-text-top ml-1'
    >
      Source: {sourceText}
    </InfoButton>
  )
}

SourceInfo.propTypes = {
  source: PropTypes.object,
  questionObj: PropTypes.object
}

export default SourceInfo
