import { Component } from 'react'
import { Link } from 'react-router-dom'

// interface
import SocialIcon from '~/interface/social-icon/SocialIcon'

// layouts
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'
import Button from '../../interface/button/Button'

class AboutUs extends Component {
  render () {
    return (
      <View name='about-us'>
        <Header name='header'>
          <h1 className='slogan'>About Us</h1>
          <h3 className='introduction'>
            Helping industries get work faster with AI-driven Intelligent Document Processing
          </h3>
        </Header>
        <Section name='mission' align='center' tight={false}>
          <h2 className='slogan'>Our Mission</h2>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5.4rem' }}>
            <p>
              At Base64.ai, We are building an automated document processing AI platform that is easy to use, secure, integrated, and suitable for all document types.
              We want to enhance manual document review and data entry processes with AI-powered solutions.
              We help our customers to instantly process documents, scale operations volume, boost data entry accuracy, and cut costs.
            </p>
            <img src='/static/images/ocr-ai-base64.png'></img>
          </div>
        </Section>
        <Section name='investors' align='center' tight={false}>
          <h2 className='slogan'>Investors</h2>
          <p className='introduction'>Base64.ai is backed by top Silicon Valley investors, including&nbsp;
            <a href='https://www.sequoiacap.com/' target='_blank' rel='noopener noreferrer'>Sequoia Capital</a>,&nbsp;
            <a href='https://www.longjourney.vc' target='_blank' rel='noopener noreferrer'>Long Journey Ventures</a>,&nbsp;
            <a href='https://zeroprime.vc' target='_blank' rel='noopener noreferrer'>Zero Prime (formerly, Data Community Fund)</a>, and&nbsp;
            <a href='https://gaingels.com/' target='_blank' rel='noopener noreferrer'>Gaingels</a>.
            For the full list, please visit us on <a href='https://base64.ai/crunchbase' target='_blank' rel='noopener noreferrer'>CrunchBase</a>.
          </p>
          <div className='centered-row-items'>
            <a href='https://www.sequoiacap.com/' target='_blank' rel='noopener noreferrer'><img src='/static/content/investors/sequoia-capitalpng.png' alt='Sequoia Capital'></img></a>
            <a href='https://www.longjourney.vc' target='_blank' rel='noopener noreferrer'><img src='/static/content/investors/LJV-long-journey-ventures.jpeg' alt='Long Journey Ventures'></img></a>
            <a href='https://zeroprime.vc' target='_blank' rel='noopener noreferrer'><img src='/static/content/investors/zero-prime.png' alt='Zero Prime'></img></a>
            <a href='https://gaingels.com/' target='_blank' rel='noopener noreferrer'><img src='/static/content/investors/Gaingels.png' alt='Gaingels'></img></a>
          </div>
        </Section>
        <Section name='story' align='center' tight={false}>
          <h2 className='slogan'>Base64.ai Story</h2>
          <p>
            Every company has a story. Our founder <a href='https://www.linkedin.com/in/ozanerenbilgen/' target='_blank' rel='noopener noreferrer'>Ozan Bilgen</a>
            &nbsp;(a Silicon Valley veteran who has been at IBM, Microsoft, Netflix, PayPal, Uber, and Palantir) witnessed
            firsthand how manual document processing caused operational delays, customer complaints,
            and compliance problems even at the most revolutionary companies in Silicon Valley.
            Existing solutions addressed only a few document types.
            Old-school engineering methods were not able to understand complex and unstructured documents.
            Employing hundreds of people was the traditional way to process documents...
          </p>
          <p>
            When we started Base64.ai Document Understanding AI in <b>January 2020</b>, we focused on building a holistic AI that can automatically process all document types, not just a few of them.
            Our team utilized the latest advancements in technology.
            Base64.ai pioneered in merging the power of Cloud Computing with the potential of <b>Large Language Models, Generative AI, and Transformer Neural Networks</b>.
            We created the first AI that understands complex documents, such as PDFs that contain multiple documents.
            We voluntarily held ourself accountable to <b>ISO, SOC 2, HIPAA, and GDPR certifications</b> to demonstrate our commitment to data security and privacy.
            Base64.ai has registered its revolutionary AI technology with <b>multiple US patent applications in intelligent document processing</b>.
            In time, Base64.ai has achieved near-perfect accuracy (over 99%) for most document types.
            Today Base64.ai is used in various <b>banks, insurance companies, RPA platforms, and even in other competitive Document AI offerings!</b>
          </p>
          <p>
            Read the <Link to='/blog'>Base64.ai Blog</Link> and listen one of our podcasts to hear our vision on the future of office with AI,<br />
            the <i><b>&quot;White Collar AI&quot;</b></i>.
          </p>
          <iframe
            style={{ borderRadius: '12px', maxWidth: '81%' }}
            src='https://open.spotify.com/embed/episode/58oOerlsBIJsdonPaURGG7?utm_source=generator&theme=0'
            width='100%'
            height='152'
            frameBorder='0'
            allowFullScreen=''
            allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
            loading='lazy'
          >
          </iframe>
        </Section>
        <Section name='esg' align='center' tight={false}>
          <h2 className='slogan'>ESG Criteria</h2>
          <p className='introduction'>We care deeply about environmental, social, and governance principles</p>
          <div className='esg-container'>
            <div className='criteria'>
              <h2 className='title'>Environmental</h2>
              <ul className='list-items'>
                <li>
                  We are a software technology company with minimum physical footprint and environmental impact.
                </li>
                <li>
                  We reduce carbon emissions with our fully remote team across the US, Europe, APAC, and LATAM.
                </li>
                <li>
                  We help reducing paper waste with AI. Less paper, less water usage.
                </li>
              </ul>
            </div>
            <div className='criteria social'>
              <h2 className='title'>Social</h2>
              <ul className='list-items'>
                <li>
                  We make all of our full-time employees shareholders so they have a long-term gain from their company&apos;s success.
                </li>
                <li>
                  We support our employees to grow. Over 20% of our team is enrolled in a higher education program and other work-related training.
                </li>
                <li>
                  We hold team events throughout the year to strengthen our community and sense of unity.
                </li>
              </ul>
            </div>
            <div className='criteria'>
              <h2 className='title'>Governance</h2>
              <ul className='list-items'>
                <li>
                  ISO, SOC-2, HIPAA, and GDPR certified: We hold ourselves to the highest standards of business conduct and information security.
                </li>
                <li>
                  We have zero tolerance for bribery, corruption, or harassment.
                </li>
                <li>
                  We don&apos;t accept investors that are backed by government funds.
                </li>
              </ul>
            </div>
          </div>
        </Section>
        <Section name='news' align='center' tight={false}>
          <h2 className='slogan'>News</h2>
          <p className='introduction'>Stay up to date with current Base64.ai news and media coverage</p>

          <div className='logos'>
            <SocialIcon color='blue' href='https://base64.ai/linkedin' title="Visit Base64.ai's LinkedIn page" media='linkedin' />
            <SocialIcon color='blue' href='https://base64.ai/twitter' title="Visit Base64.ai's X (Twitter) page" media='x' />
            <SocialIcon color='blue' href='https://base64.ai/youtube' title="Visit Base64.ai's YouTube channel" media='youtube' />
            <SocialIcon color='blue' href='https://base64.ai/vimeo' title="Visit Base64.ai's Vimeo channel" media='vimeo' />
            <SocialIcon color='blue' href='https://base64.ai/quora' title="Visit Base64.ai's Quora page" media='quora' />
            <SocialIcon color='blue' href='https://base64.ai/facebook' title="Visit Base64.ai's Facebook page" media='facebook' />
            <SocialIcon color='blue' href='https://base64.ai/github' title="Visit Base64.ai's Github page" media='github' />
          </div>
          <Button to='/news'>News About Base64.ai</Button>
        </Section>
      </View>
    )
  }
}

AboutUs.propTypes = {}

export default AboutUs
