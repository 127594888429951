import { Component } from 'react'

// Layout
import Context from '~/context/global'
import PropTypes from 'prop-types'

// Data
import partners from '../../data/partners'

// Content: PartnerLogos
class PartnerLogos extends Component {
  static contextType = Context

  static propTypes = {
    showFeatured: PropTypes.bool,
    showHeaders: PropTypes.bool,
    type: PropTypes.string,
    action: PropTypes.func.isRequired
  }

  renderLogos = type => {
    const typeToString = {
      compliance: 'Certifications',
      membership: 'Memberships',
      marketplace: 'Marketplace Partners',
      hardware: 'Hardware Partners',
      rpa: 'RPA Platforms',
      solution: 'Solution Providers'
    }
    const partnersArray = Object.keys(partners)
      .filter(p => !type || type === partners[p].type)
      .filter(p => !this.props.showFeatured || partners[p].isFeatured)
      .filter(p => !partners[p].disabled)
    return (
      <>
        {this.props.showHeaders && <h3 className='partner-logos-name'>{typeToString[type]}</h3>}
        {partnersArray.length
          ? (
              <ul className='partner-logos-list'>
                {
                  partnersArray.map(pid => (
                    <li key={pid}>
                      <a href={['compliance', 'membership'].includes(partners[pid].type) ? '/compliance' : ('/partners/' + pid)} className='noBorder' onClick={this.props.action}>
                        <img src={`/static/content/partners/${pid}.png`} alt={partners[pid].name} />
                      </a>
                    </li>
                  )
                  )
                }
              </ul>
            )
          : null}
      </>
    )
  }

  render () {
    return this.props.type ? this.renderLogos(this.props.type) : ([...new Set(Object.values(partners).map(p => p.type))].map(pt => this.renderLogos(pt)))
  }
}

// Export
export default PartnerLogos
