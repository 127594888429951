// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// Interface
import Loading from '~/interface/loading/Loading'
import Button from '~/interface/button/Button'

// Layout
import View from '~/layout/view/View'

// Helpers
import { request } from '~/helpers/request'
import { isProduction } from '~/context/environment'

class OAuthConsent extends Component {
  constructor (props) {
    super(props)
    const search = this.props.location.search.substring(1)
    const params = search === '' ? {} : JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    this.state = {
      params
    }

    this.getClientInfo = this.getClientInfo.bind(this)
    this.oAuth = this.oAuth.bind(this)
  }

  componentDidMount () {
    this.getClientInfo()
  }

  getClientInfo = () => {
    request({
      endpoint: `/client/${this.state.params.client_id}`,
      method: 'GET',
      isOAuth: true
    }, (error, response) => {
      if (error) {
        this.setState({
          clientName: this.state.params.client_id,
          error: 'Invalid OAuth Credentials'
        })
        return console.error(error)
      }
      this.setState({
        clientName: response.name
      })
    })
  }

  oAuth = cause => {
    const params = this.state.params
    params.referrer = 'oauth-consent'
    params.redirect_uri = decodeURIComponent(params.redirect_uri)
    if (cause === 'deny') {
      params.error = 'access_denied'
      // FIXME: This is janky. I don't think there is a better way but if there is we should use it
      window.location.href = `${params.redirect_uri}?error=${encodeURI('User has denied connection')}`
    } else {
      const query = new URLSearchParams(params).toString()
      window.location.href = `${isProduction() ? 'https://base64.ai' : 'http://localhost:3001'}/oauth/authenticate?${query}`
    }
  }

  render () {
    return (
      <View
        flex={true}
        name='oauth-consent'
      >
        {
          (!this.state.clientName)
            ? <Loading />
            : (
                <div className='consent'>
                  {
                    (this.state.error)
                      ? (
                          <>
                            <p className='prompt'>{this.state.error}</p>
                            <div className='buttons'>
                              <Button className='return-button' href='/'>Back</Button>
                            </div>
                          </>
                        )
                      : (
                          <>
                            <p className='prompt'>Do you allow {this.state.clientName} to access your account?</p>
                            <div className='buttons'>
                              <Button
                                className='oauth-button'
                                onClick={() => {
                                  this.oAuth()
                                }}
                              >Allow
                              </Button>
                              <Button
                                className='oauth-button negative'
                                onClick={() => {
                                  this.oAuth('deny')
                                }}
                              >Deny
                              </Button>
                            </div>
                          </>
                        )
                  }
                </div>
              )
        }

      </View>
    )
  }
}

OAuthConsent.propTypes = {
  location: PropTypes.object
}

export default withRouter(OAuthConsent)
