import { Component } from 'react'
import Checkbox from '../../../interface/checkbox/Checkbox'

import PropTypes from 'prop-types'

export default class SelectExtractTypes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      input: this.props.parent.state.extractDataResult || {}
    }
  }

  setInput (name, value, callback) {
    const input = this.state.input
    input[name] = value
    this.setState({ input })
    // add result in the parent element
    this.props.parent.setState(prev => {
      return {
        ...prev,
        extractDataResult: this.state.input
      }
    })
    callback && callback(input)
  }

  onCheckBoxChange (data) {
    this.setInput(data.placeholder, data.checked) // this option is set true as default
  }

  onTextBoxChange (data) {
    this.setInput('other', data.value)
  }

  componentDidMount () {
    this.setInput('Document type and OCR text', true)
  }

  extractData = [
    'Document type and OCR text',
    'Form fields, line items, and tables',
    'Faces',
    'Ink signatures',
    'Digital signatures',
    'Watermarks',
    'Checkboxes',
    'Text-to-speech or speech-to-text'
  ]

  render () {
    return (
      <div className='main'>
        <div className='description'>
          <h2>What would you like to extract from these documents?</h2>
          <h3 className='introduction small'>
            Select the type of the information you wish to extract.
          </h3>
        </div>
        <div className='entries'>
          {this.extractData.map((item, index) => (
            <Checkbox
              key={index}
              className='big'
              placeholder={item}
              onChange={e => {
                this.onCheckBoxChange(e.target)
              }}
              checked={this.state.input[item]}
            />
          ))}

          <div className='textAreaContainer'>
            <textarea
              placeholder='Describe your other options here...'
              value={this.props.parent.state.extractDataResult?.other || ''}
              onChange={e => {
                this.onTextBoxChange(e.target)
              }}
            >
            </textarea>
          </div>
        </div>
      </div>
    )
  }
}

SelectExtractTypes.propTypes = {
  input: PropTypes.object,
  parent: PropTypes.object
}
