import { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

// Context
import Context from '~/context/global'

// interface
import ROIPDFExport from './ROIPDFExport'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'
import { Slider } from '../../interface/slider/Slider'
import { AppButton } from '../../interface/app-button/AppButton'

export class ROICalculator extends Component {
  static contextType = Context
  static timer = null

  state = {
    documentSize: 50000,
    pagesPerDocument: 3,
    employeeSize: 40,
    costPerFTE: 60000,
    openResult: false,
    giveInfo: false
  }

  componentWillUnmount () {
    clearTimeout(this.timer)
  }

  calculateResults = () => {
    const workDayInTheMonth = 20
    const documentsPerEmployee = this.state.documentSize / this.state.employeeSize / workDayInTheMonth // documentSize = documents per month
    const totalPagesMonthly = this.state.documentSize * this.state.pagesPerDocument

    const minutesDailyProcessing = documentsPerEmployee * this.state.pagesPerDocument * 2.5// employee minutes processing daily
    const hoursDailyProcessing = minutesDailyProcessing / 60 // employee hours processing daily
    const hoursDailyProcessingAI = this.state.documentSize / workDayInTheMonth * 3 * 0.5 / 60 // assuming AI takes 30 seconds per document
    const hoursSaved = this.state.documentSize / workDayInTheMonth * 3 * 2.5 / 60 - hoursDailyProcessingAI

    const costWithBase64 = 0.25 * 12 * totalPagesMonthly

    const annualSaving = this.state.employeeSize * this.state.costPerFTE - costWithBase64
    const currentSpending = this.state.employeeSize * this.state.costPerFTE

    this.setState({
      pagesProcessedMonthly: this.numberWithCommas(totalPagesMonthly),
      documentsPerEmployee: this.numberWithCommas(documentsPerEmployee),
      employeeTimeSpent: this.numberWithCommas(Math.floor(((hoursDailyProcessing / 8) * 100))), // 8 hours daily working time
      giveTimeWarning: (hoursDailyProcessing / 8) >= 1.01,
      hoursSaved: this.numberWithCommas(hoursSaved),
      annualHoursSaved: this.numberWithCommas(hoursSaved * 5 * 52),
      freeUpEmployee: this.numberWithCommas(hoursSaved / 8),
      currentSpending: this.numberWithCommas(currentSpending),
      costWithBase64: this.numberWithCommas(costWithBase64),
      annualSaving: this.numberWithCommas(annualSaving),
      annualSavingPercentage: this.numberWithCommas(100 * (annualSaving / currentSpending))
    })
  }

  numberWithCommas = x => {
    return x.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }

  valueLabelFormat = x => {
    return this.numberWithCommas(x)
  }

  handleDocumentSizeChange = value => {
    this.setState(
      { documentSize: value },
      this.calculateResults
    )
  }

  handlePagesPerDocument = value => {
    this.setState(
      { pagesPerDocument: value },
      this.calculateResults
    )
  }

  handleEmployeeSizeChange = value => {
    this.setState(
      { employeeSize: value },
      this.calculateResults
    )
  }

  handleCostPerFTEChange = value => {
    this.setState(
      { costPerFTE: value },
      this.calculateResults
    )
  }

  render () {
    return (
      <View name='roi-calculator'>
        <Header name='header'>
          <h1 className='slogan'>Estimate your ROI<br />for intelligent document processing</h1>
          <h2 className='introduction'>
            Use our calculator to estimate your ROI (Return on Investment) and see how <br />Base64.ai could improve your automated document processing
          </h2>
        </Header>
        <Section name='calculator' align='center' style={{ paddingTop: '0.5rem' }}>
          <div className='calculatorContainer'>
            <div id='selections' className='inputRow'>
              <div className='inputArea'>
                <h4>Monthly average number<br />of processed document:</h4>
                <p className='inputValue'>{this.numberWithCommas(this.state.documentSize)}</p>
                <Slider
                  className='roi-slider'
                  ariaLabelGroupForHandles='Custom marks'
                  defaultValue={this.state.documentSize}
                  step={1000}
                  min={1000}
                  max={100000}
                  marks={{
                    1000: this.numberWithCommas(1000),
                    100000: this.numberWithCommas(100000)
                  }}
                  onChange={v => {
                    this.handleDocumentSizeChange(v)
                  }}
                />
              </div>
              <div className='inputArea'>
                <h4>Average number of pages<br />per document</h4>
                <p className='inputValue'>{this.numberWithCommas(this.state.pagesPerDocument)}</p>
                <Slider
                  className='roi-slider'
                  ariaLabelGroupForHandles='Custom marks'
                  defaultValue={this.state.pagesPerDocument}
                  step={1}
                  min={1}
                  max={20}
                  marks={{
                    1: '1',
                    10: '10',
                    20: '20'
                  }}
                  onChange={v => {
                    this.handlePagesPerDocument(v)
                  }}
                />
              </div>
            </div>

            <div className='inputRow' style={{ marginBottom: '-0.6rem' }}>
              <div className='inputArea'>
                <h4>Number of employees<br />processing documents</h4>
                <p className='inputValue'>{this.numberWithCommas(this.state.employeeSize)}</p>
                <Slider
                  className='roi-slider'
                  ariaLabelGroupForHandles='Custom marks'
                  defaultValue={this.state.employeeSize}
                  step={1}
                  min={1}
                  max={100}
                  marks={{
                    1: '1',
                    50: '50',
                    100: '100'
                  }}
                  onChange={v => {
                    this.handleEmployeeSizeChange(v)
                  }}
                />
              </div>
              <div className='inputArea'>
                <h4>Average annual cost<br />per employee</h4>
                <p className='inputValue'>${this.numberWithCommas(this.state.costPerFTE)}</p>
                <Slider
                  className='roi-slider'
                  ariaLabelGroupForHandles='Custom marks'
                  defaultValue={this.state.costPerFTE}
                  step={5000}
                  min={10000}
                  max={100000}
                  marks={{
                    10000: '$' + this.numberWithCommas(10000),
                    50000: '$' + this.numberWithCommas(50000),
                    100000: '$' + this.numberWithCommas(100000)
                  }}
                  onChange={v => {
                    this.handleCostPerFTEChange(v)
                  }}
                />
              </div>
            </div>

            {this.state.openResult
            && (
              <div className={this.state.giveInfo ? 'infoMessage' : 'warningMessage'} style={{ visibility: (this.state.giveTimeWarning || this.state.giveInfo) ? 'visible' : 'hidden' }}>
                {this.state.giveInfo
                  ? <p>You can still change the options to see the live results</p>
                  : <p>Warning: Employee time spent ({this.state.employeeTimeSpent}% of daily working hours)<br />please update your information!</p>}
              </div>
            )}

            <div className={'resultSection'.concat(this.state.giveTimeWarning ? ' blurry-text' : '')} style={{ display: this.state.openResult ? 'block' : 'none' }}>

              <div className='description-item first-item'>
                <label className='left first-item'>Annual savings</label>
                <label className='right first-item'>${this.state.annualSaving} ({this.state.annualSavingPercentage}%)</label>
              </div>

              <div className='description-item'>
                <label className='left'>Estimated annual cost of current manual process</label>
                <label className='right'>${this.state.currentSpending}</label>
              </div>

              <div className='description-item'>
                <label className='left'>Estimated annual cost with <Link to='/'>Base64.ai</Link></label>
                <label className='right'>${this.state.costWithBase64}</label>
              </div>

              <div className='description-item'>
                <label className='left'>Number of pages processed monthly</label>
                <label className='right'>{ this.state.pagesProcessedMonthly }</label>
              </div>

              <div className='description-item'>
                <label className='left'>Number of documents per employee daily</label>
                <label className='right'>{ this.state.documentsPerEmployee }</label>
              </div>

              <div id='employeeTime' className='description-item'>
                <label className='left'>% employee time spent on document processing</label>
                <label className={'right '.concat(this.state.giveTimeWarning ? 'red' : '')}>
                  {this.state.employeeTimeSpent}%
                </label>
              </div>

              <div className='description-item'>
                <label className='left'>Annual hours saved</label>
                <label className='right'>{this.state.annualHoursSaved}</label>
              </div>
              <div className='description-item'>
                <label className='left'>Daily hours saved</label>
                <label className='right'>{this.state.hoursSaved}</label>
              </div>

              <div className='disclaimer'>
                <p>
                  Disclaimer: The purpose of this calculator is to give you an estimated cost using 25 cents per page.
                  Your actual cost may vary and may depend on the type of document(s).
                </p>
              </div>
              {/* TODO: use this form to send an email in the future */}
              {/* <div style={{ visibility: this.state.giveTimeWarning ? 'hidden' : 'visible' }}>
                <Form
                  className="leadForm inline"
                  onDataSubmit={(data, errorCallback) => {
                    reCaptcha('lead', token => {
                      data.token = token
                      this.addLead(data, errorCallback)
                    })
                  }}
                >
                  <Form.Button
                    type="submit"
                    className="submitDiv"
                  >
                    Send
                  </Form.Button>
                  <Form.Input
                    className="inputDiv"
                    type="text"
                    name="email"
                    reference="Work email"
                    placeholder="Enter your work email..."
                    autoComplete="off"
                    validator={{
                      minimumLength: 5
                    }}/>
                </Form>
              </div> */}
              { this.state.openResult
              && <ROIPDFExport data={this.state} visibility={!this.state.giveTimeWarning} />}
            </div>
            <div style={{ textAlign: 'center', margin: '1rem auto', marginTop: '4rem', display: this.state.openResult ? 'none' : undefined }}>
              <AppButton
                className='secondary-cta'
                onClick={
                  () => {
                    this.calculateResults()
                    this.setState({ giveInfo: true, openResult: true })
                    this.timer = setTimeout(() => {
                      this.setState({ giveInfo: false })
                    }, 5000)
                    this.context.redirect('#selections')
                  }
                }
              >
                Calculate Now
              </AppButton>
            </div>
          </div>
        </Section>
      </View>
    )
  }
}

ROICalculator.propTypes = {
}

export default withRouter(ROICalculator)
