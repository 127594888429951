import { useFlows } from '../../providers/FlowContext'
import HITL from './HITL'

const HITLWrapper = props => {
  const { flows, setFlows } = useFlows()

  return (
    <HITL
      {...props}
      flows={flows}
      setFlows={setFlows}
    />
  )
}

export default HITLWrapper
