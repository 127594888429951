// Modules
import { Component } from 'react'
import PropTypes from 'prop-types'

// Brand: Symbol
import Symbol from '~/brand/symbol/Symbol'

// Animation: DataExtraction
class DataExtraction extends Component {
  static propTypes = {
    image: PropTypes.string,
    regions: PropTypes.array,
    customLeftContent: PropTypes.element,
    customRightContent: PropTypes.element,
    items: PropTypes.array,
    durationPerItem: PropTypes.number,
    maximumLoop: PropTypes.number,
    onLoopEnd: PropTypes.func,
    onClickImage: PropTypes.func,
    onAnimationStart: PropTypes.func,
    onAnimationEnd: PropTypes.func
  }

  static defaultProps = {
    regions: ['left', 'right'],
    durationPerItem: 1500
  }

  constructor (props) {
    super(props)
    this.state = {
      loopCount: 1,
      itemCount: 1
    }
    this.interval = false
    this.startAnimation = this.startAnimation.bind(this)
    this.startStep = this.startStep.bind(this)
    this.endAnimation = this.endAnimation.bind(this)
    this.endLoop = this.endLoop.bind(this)
  }

  componentDidMount () {
    setTimeout(() => {
      this.startAnimation()
      this.props.onAnimationStart && this.props.onAnimationStart()
    }, 2000)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.image !== this.props.image) {
      clearInterval(this.interval)
      this.startAnimation()
    }
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  startAnimation () {
    const { items, durationPerItem } = this.props
    if (items && items.length > 0) {
      this.setState({
        loopCount: 1,
        itemCount: 1
      })
      this.interval = setInterval(this.startStep, durationPerItem)
    }
  }

  startStep () {
    const { loopCount, itemCount } = this.state
    const { items, maximumLoop } = this.props
    if (maximumLoop && loopCount === maximumLoop && itemCount === items.length) {
      this.endLoop()
      this.endAnimation()
    } else {
      itemCount === items.length && this.endLoop()
      this.setState({
        loopCount: itemCount === items.length
          ? loopCount + 1
          : loopCount,
        itemCount: itemCount % items.length + 1
      })
    }
  }

  endAnimation () {
    const { onAnimationEnd } = this.props
    clearInterval(this.interval)
    onAnimationEnd && onAnimationEnd()
  }

  endLoop () {
    const { onLoopEnd } = this.props
    onLoopEnd && onLoopEnd()
  }

  render () {
    const { itemCount } = this.state
    const { image, items, regions, customLeftContent, customRightContent, onClickImage } = this.props
    const item = items && items.length > 0
      ? items[itemCount - 1]
      : null
    return (
      <div
        data-animation='teaser'
        className='animation'
      >
        {regions.includes('left')
        && <div className='left'>{customLeftContent}</div>}
        <div className='middle'>
          {item
          && (
            <div
              className='pointer'
              style={{
                [item.alignment]: '0',
                top: `${item.top * 100}%`,
                width: `${item.width * 100}%`
              }}
            >
              {(item.title || item.body)
              && (
                <div className={`${item.alignment} content`}>
                  {item.title && <b>{item.title}</b>}
                  {item.text && <p>{item.text}</p>}
                  {item.image && <img src={item.image} />}
                </div>
              )}
              <Symbol />
            </div>
          )}
          {image
          && (
            <figure>
              <img
                src={image}
                onClick={onClickImage}
              />
            </figure>
          )}
        </div>
        {regions.includes('right')
        && <div className='right'>{customRightContent}</div>}
      </div>
    )
  }
}

// Export
export default DataExtraction
