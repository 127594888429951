// Modules
import PropTypes from 'prop-types'

// Interface
import { AppButton } from '../app-button/AppButton'

// Interface: DemoButton
const DemoButton = ({ type }) => {
  const demo = type?.demo
  const className = 'secondary-cta large'
  return (
    (demo)
      ? (demo.startsWith('http'))
          ? (
              <AppButton href={demo} target='_blank' rel='noopener noreferrer' className={className}>
                Check out online
              </AppButton>
            )
          : (
              <AppButton href={demo} className={className}>
                Start free demo
              </AppButton>
            )
      : (
          <AppButton href='/flow' className={className}>
            Start free demo
          </AppButton>
        )
  )
}

DemoButton.propTypes = {
  type: PropTypes.object
}

// Export
export default DemoButton
