import React, { Component } from 'react'
import MaterialIcon from '../material-icon/MaterialIcon'
import MultiInputTable from '../multi-input-table/MultiInputTable'
import propTypes from 'prop-types'
import csv from 'csv-parse/lib/sync'
// Context
import Context from '~/context/global'
import { Parser as Json2csv } from 'json2csv'

class QuestionAnswer extends Component {
  static contextType = Context

  static defaultProps = {
    questionAndAnswerTable: {
      columns: [
        {
          columnName: 'Field name',
          fieldName: 'key',
          inputType: 'text',
          placeholder: 'Field name...',
          defaultValue: ''
        },
        {
          columnName: 'Question',
          fieldName: 'question',
          inputType: 'text',
          placeholder: 'Ask a question...',
          defaultValue: ''
        },
        {
          columnName: 'Modality',
          fieldName: 'modality',
          inputType: 'select',
          options: [
            { label: 'Text', value: 'text' },
            { label: 'Image', value: 'image' },
            { label: 'Multimodal', value: 'multimodal' }
          ],
          defaultValue: { label: 'Text', value: 'text' },
          alwaysShow: true
        },
        {
          columnName: 'Data source',
          fieldName: 'flowUUID',
          inputType: 'select',
          options: [
            { label: 'Ask current document', value: undefined }
          ],
          defaultValue: { label: 'Ask current document', value: undefined },
          alwaysShow: true
        }
      ],
      addButtonText: 'Add question',
      isDeleteButtonEnabled: true,
      className: 'five-column'
    }
  }

  static propTypes = {
    questionAndAnswerTable: MultiInputTable.propTypes.table,
    headerParagraph: propTypes.node,
    onChange: propTypes.func,
    questions: propTypes.arrayOf(propTypes.object),
    flows: propTypes.array
  }

  constructor (props) {
    super(props)
    this.state = {
      questions: props.questions || [],
      isSubmitted: false,
      questionInputText: ''
    }
    this.onImportQuestions = this.onImportQuestions.bind(this)
    this.updateQuestionsViaImport = this.updateQuestionsViaImport.bind(this)
    this.onExportQuestions = this.onExportQuestions.bind(this)
  }

  updateQuestionsViaImport () {
    if (this.state.questionInputText?.length) {
      const text = this.state.questionInputText
      let csvObject = null
      try {
        csvObject = csv(text, {
          skip_empty_lines: true,
          columns: true,
          relax_column_count: true
        })
      } catch (e) {
        console.error(e)
        this.context.displayModal({
          title: 'Error',
          content: (
            <div>
              <p>Failed to parse the CSV file.</p>
            </div>
          ),
          options: [{ content: 'OK' }],
          isClosable: true
        })
        return
      }

      if (!csvObject) {
        this.context.displayModal({
          title: 'Error',
          content: (
            <div>
              <p>Failed to parse the CSV file.</p>
            </div>
          ),
          options: [{ content: 'OK' }],
          isClosable: true
        })
        return
      }

      // csv field validation
      for (const [index, row] of csvObject.entries()) {
        if (!row.fieldName || !row.question) {
          this.context.displayModal({
            title: 'Error',
            content: (
              <div>
                <p>Row {index + 1} has missing field name or question.</p>
              </div>
            ),
            options: [{ content: 'OK' }],
            isClosable: true
          })
          return
        }
      }

      const newQuestions = csvObject.map(row => ({
        key: row.fieldName,
        question: row.question
      }))

      this.setState({
        questions: newQuestions
      })
      this.props.onChange(newQuestions)
    }
  }

  onImportQuestions (e) {
    const file = e.target.files && e.target.files[0]
    if (!file) {
      return
    }
    if (file.type !== 'text/csv' || !file.name.endsWith('.csv')) {
      this.context.displayModal({
        title: 'Error',
        content: (
          <div>
            <p>Only CSV files are supported.</p>
            <p>File name: {file.name}</p>
            <p>File type: {file.type}</p>
          </div>
        ),
        options: [{ content: 'OK' }],
        isClosable: true
      })
      return
    }
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      this.setState({
        questionInputText: reader.result
      }, () => {
        this.updateQuestionsViaImport()
      })
    }
    reader.onerror = () => {
      this.context.displayModal({
        title: 'Error',
        content: (
          <div>
            <p>Failed to read the file.</p>
          </div>
        ),
        options: [{ content: 'OK' }],
        isClosable: true
      })
    }
  }

  onExportQuestions () {
    if (!this.state.questions?.length) {
      return
    }
    const header = ['fieldName', 'question', 'modality', 'flowUUID']
    const data = this.state.questions.map(q => {
      return {
        fieldName: q.key,
        question: q.question,
        modality: q.modality,
        flowUUID: q.flowUUID
      }
    })
    try {
      const url = window.URL.createObjectURL(
        new Blob([new Json2csv(header).parse(data)])
      )
      const a = document.createElement('a')
      a.href = url
      a.download = `questions.csv`
      a.click()
      a.remove()
      setTimeout(() => window.URL.revokeObjectURL(url), 100)
    } catch (e) {
      console.error(e)
    }
  }

  render () {
    const eligibleFlows = this.props.flows.filter(s => !(s?.role?.includes('uploader') && s?.role?.length === 1) && s?.flowSearch?.status === 'enabled')
    eligibleFlows.sort((a, b) => a.isDefault ? -1 : (b.isDefault ? 1 : (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0))))
    const destinationFlows = eligibleFlows.map(flow => ({
      label: flow.name,
      value: flow.flowID
    }))
    if (!destinationFlows.length) {
      destinationFlows.push({ label: <i>No Flows have AI Search enabled</i>, value: '', isDisabled: true })
    }
    this.props.questionAndAnswerTable.columns
      .find(column => column.columnName === 'Data source')
      .options[1] = { label: 'Ask a Flow that has AI Search enabled', options: destinationFlows }

    return (
      <React.Fragment>
        <div className='questions-header-row header-row-with-icons'>
          <div className='header-row-title'>
            <h2 className='title'>Questions &amp; Answers</h2>
          </div>
          <div className='header-row-icons'>
            <label htmlFor='question-answer-csv-import'>
              <MaterialIcon title='Import Questions & Answers from file' name='upload' className='material-icon-outlined icon-size' role='button' />
            </label>
            <input id='question-answer-csv-import' type='file' accept='.csv' hidden onChange={this.onImportQuestions} />
            <MaterialIcon title='Export Questions & Answers from file' name='download' className='material-icon-outlined icon-size' style={{ marginLeft: '0.5rem' }} onClick={this.onExportQuestions} />
          </div>
        </div>
        {this.props.headerParagraph}
        <MultiInputTable
          tableStructure={this.props.questionAndAnswerTable}
          data={this.state.questions}
          onChange={data => {
            data.forEach(question => {
              Object.keys(question).forEach(key => {
                if (typeof question[key] !== 'object') {
                  return
                }
                question[key] = question[key].value
              })
            })
            this.setState({
              questions: data,
              isSubmitted: false
            }),
            this.props.onChange(data)
          }}
        />
      </React.Fragment>
    )
  }
}

export default QuestionAnswer
