import { Component } from 'react'
import PropTypes from 'prop-types'
import { CodeBlock, github } from 'react-code-blocks'
import { request } from '../../helpers/request'
import window from 'global'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { hasDatabase, isWorkingOffline } from '../../context/environment'
import { toCamelCase } from '../../utilities/format'
import constants from '~/data/constants'

class CodeSnippet extends Component {
  // Structure of 'examples':
  // [
  // {
  //    title: 'with API key',
  //    languages: [
  //      javascript: {
  //        name: 'Javascript',
  //        code: "console.log('hello, world!');" +
  //        "console.log('Welcome');"
  //        // Or use ES6 Syntax
  //        `console.log('hello, world!');
  //        "console.log('Welcome');`
  //      },
  //      ...
  //    ]
  // },
  // ...
  // ]
  static propTypes = {
    examples: PropTypes.object,
    user: PropTypes.object
  }

  constructor (props) {
    super(props)
    const selectedGroup = Object.keys(this.props.examples)[0]
    const selectedExample = Object.keys(this.props.examples[selectedGroup])[0]
    this.state = {
      selectedGroup,
      selectedExample,
      selectedLanguage: Object.keys(this.props.examples[selectedGroup][selectedExample].languages)[0],
      user: this.props.user,
      defaultFlowId: props.user?.defaultFlowID || 'default-flow-id',
      apiKey: 'secret'
    }

    this.selectExampleHandler = this.selectExampleHandler.bind(this)
    this.selectLanguageHandler = this.selectLanguageHandler.bind(this)
    this.formatSnippetTemplate = this.formatSnippetTemplate.bind(this)
    this.onCopyHandler = this.onCopyHandler.bind(this)
    this.getApiKey = this.getApiKey.bind(this)
  }

  componentDidMount () {
    if (this.props.user?.email) {
      this.getApiKey()
      this.setState({ defaultFlowId: this.props.user?.defaultFlowID || 'default-flow-id' })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!prevProps.user?.email && this.props.user?.email) {
      this.setState({ user: this.props.user })
      this.getApiKey()
      this.setState({ defaultFlowId: this.props.user?.defaultFlowID || 'default-flow-id' })
    }
  }

  getApiKey () {
    request({
      endpoint: '/key'
    }, (error, response) => {
      if (error) {
        // Cannot fetch API Key, probably not logged in
        return
      }
      this.setState({
        apiKey: response.apiKey
      })
    })
  }

  formatSnippetTemplate (template) {
    let formatted = template
    // Format host
    formatted = formatted.replace(/https:\/\/base64\.ai\/api/gm, `${window?.location?.origin || 'https://base64.ai'}/api`)
    // Format API Key
    if (isWorkingOffline() && !hasDatabase()) {
      formatted = formatted.replace(/{APIKEY}/gm, 'f255a63a-af8e-430e-a69d-d0929c3c7069')
      formatted = formatted.replace(/{EMAIL}/gm, toCamelCase(constants.onPremTitle) + '@base64.ai')
      formatted = formatted.replace(/{FLOW_ID}/gm, this.state.defaultFlowId || 'default-flow-id')
      return formatted
    }
    formatted = formatted.replace(/{APIKEY}/gm, this.state.apiKey || 'your-api-secret')
    formatted = formatted.replace(/{EMAIL}/gm, this.state.user?.email || 'email')
    formatted = formatted.replace(/{FLOW_ID}/gm, this.state.defaultFlowId || 'default-flow-id')
    return formatted
  }

  selectExampleHandler (e) {
    if (e.target.value === 'more') {
      window.open('https://apidoc.base64.ai/view/10132588/SWT5hfdz#intro')
      return
    }
    const parts = e.target.value.split('-')
    this.setState({
      selectedGroup: parts[0],
      selectedExample: parts[1]
    })
  }

  selectLanguageHandler (e) {
    if (e.target.value === 'more') {
      window.open(this.props.examples[this.state.selectedGroup][this.state.selectedExample]
        ? this.props.examples[this.state.selectedGroup][this.state.selectedExample].more_url
        : 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#intro')
      return
    }
    this.setState({
      selectedLanguage: e.target.value
    })
  }

  onCopyHandler (e) {
    if (this.timeoutId) {
      return
    }
    this.setState({ copied: true })
    this.timeoutId = setTimeout(function () {
      this.setState({ copied: false })
      this.timeoutId = null
    }.bind(this), 1500)
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  render () {
    const { examples } = this.props
    const { selectedGroup, selectedExample, selectedLanguage, copied } = this.state
    return (
      <div className='code-snippet'>
        <div className='selector'>
          <select onChange={this.selectExampleHandler} value={selectedGroup + '-' + selectedExample}>
            {
              Object.keys(examples).map(k => (
                <optgroup label={k} key={k}>
                  { Object.keys(examples[k]).map(sk => <option key={sk} value={k + '-' + sk}>{examples[k][sk].title}</option>)}
                </optgroup>
              ))
            }
            <option value='more'>More...</option>
          </select>
          <select onChange={this.selectLanguageHandler} value={selectedLanguage}>
            { Object.entries(examples[selectedGroup][selectedExample].languages).map(v => <option key={v[0]} value={v[0]}>{v[1].name}</option>)}
            <option value='more'>More...</option>
          </select>
          <CopyToClipboard
            text={this.formatSnippetTemplate(examples[selectedGroup][selectedExample].languages[selectedLanguage].snippet)}
            onCopy={this.onCopyHandler}
          >
            <span className='clipboard-icon' title='Click here to copy'>
              { copied ? '✅' : '📋' }
            </span>
          </CopyToClipboard>
        </div>
        <div className='code-area'>
          <CodeBlock
            text={this.formatSnippetTemplate(examples[selectedGroup][selectedExample].languages[selectedLanguage].snippet)}
            language={examples[selectedGroup][selectedExample].languages[selectedLanguage].key}
            theme={github}
            showLineNumbers={true}
            codeBlock
          />
        </div>
      </div>
    )
  }
}

// Export
export default CodeSnippet
