// This is a wrapper for rc-slider

import React from 'react'

import Slider from 'rc-slider'

class SliderComponent extends React.Component {
  render () {
    const {
      ...otherProps
    } = this.props
    otherProps.trackStyle = otherProps.trackStyle || { display: 'none' }
    otherProps.handleStyle = otherProps.handleStyle || {
      backgroundColor: '#00dddd',
      color: '#00dddd',
      borderColor: '#00dddd',
      width: '20px',
      height: '20px',
      marginTop: '-8px',
      opacity: '1'
    }
    otherProps.railStyle = otherProps.railStyle || {
      backgroundColor: '#00dddd',
      borderRadius: '5px',
      opacity: '0.38'
    }
    otherProps.dotStyle = otherProps.dotStyle || { display: 'none' }
    otherProps.activeDotStyle = otherProps.activeDotStyle || { display: 'none' }
    return (
      <Slider
        {...otherProps}
      />
    )
  }
}

export {
  SliderComponent as Slider
}
