export default {
  columns: [
    {
      columnName: 'Preferred field',
      fieldName: 'name',
      inputType: 'text',
      title: 'Field name to be used for the taxonomy.',
      placeholder: 'Preferred field...',
      defaultValue: ''
    },
    {
      columnName: 'Data source',
      inputType: 'creatable-select',
      fieldName: 'alternateSpelling',
      placeholder: 'Enter alternative spelling, wording, or synonyms...',
      title: 'Values can be comma separated. \\, escapes commas.',
      options: [],
      defaultValue: [],
      isMulti: true
    },
    {
      columnName: 'Replace matched',
      inputType: 'boolean',
      fieldName: 'deleteMatchedTaxonomy',
      title: 'Replace the matched field with preferred field to prevent duplicates',
      options: [],
      alwaysShow: true,
      defaultValue: false
    }
  ],
  addButtonText: 'Add column',
  isDeleteButtonEnabled: true,
  className: 'four-column'
}
