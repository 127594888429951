// Modules
import { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// Context
import Context from '~/context/global'

// Layout
import View from '~/layout/view/View'

// Interface
import Form from '~/interface/form/Form'
import Link from '~/interface/link/Link'
import ThirdPartAuthentication from '~/interface/third-part-authentication/third-part-authentication'

// Helpers
import { request } from '~/helpers/request'
import { reCaptcha } from '~/helpers/authentication'
import { triggerTrackingEvent } from '../../utilities/tracker'
import Section from '../../layout/section/Section'
import { hasDatabase, isWorkingOffline } from '../../context/environment'
import { AppButton } from '../../interface/app-button/AppButton'

// View: Login
class Login extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      params: {},
      completed: false,
      submitHandler: () => {},
      loading: false
    }
    this.login = this.login.bind(this)
  }

  componentDidMount () {
    const query = new URLSearchParams(
      this.props.location.search
    )
    const data = {}
    for (const params of query.entries()) {
      data[params[0]] = params[1]
    }
    this.setState({ params: data })
  }

  componentDidUpdate (prevProps) {
    const { user } = this.props
    if (
      !prevProps.user?.email
      && user?.email
      && !this.state.params?.oauth
      && !this.state.loading
      && !this.state.completed
    ) {
      this.context.redirect('/flow')
    }
  }

  login (data, errorCallback) {
    this.setState({ loading: true })
    request({
      endpoint: '/auth/login',
      body: data
    }, (error, response) => {
      if (error) {
        errorCallback(error?.message || error.toString())
        this.setState({ loading: false })
      } else {
        triggerTrackingEvent('login-completed-email')
        this.context.setUser(() => {
          if (this.state.params.oauth) {
            const params = this.state.params
            params.redirect_uri = decodeURIComponent(params.redirect_uri)
            const query = new URLSearchParams(params).toString()
            this.setState(prevState => ({
              params: {
                ...prevState.params,
                next: `/oauth-consent?${query}`
              }
            }))
          }
          this.setState({
            completed: true
          }, () => {
            this.setState({ loading: false })
          })
        })
      }
    })
  }

  render () {
    const params = this.state.params
    params.redirect_uri = decodeURIComponent(params.redirect_uri)
    const query = new URLSearchParams(params).toString()
    const { user } = this.props
    const redirectEndpoint = params.next || (hasDatabase() ? '/flow' : '/flow')
    return this.state.completed
      ? <Redirect to={redirectEndpoint} />
      : user && user.email && params.oauth
        ? <Redirect to={`/oauth-consent?${query}`} />
        : (
            <View
              name='login'
              flex={true}
            >
              <Section name='login'>
                <Form
                  onDataSubmit={(data, errorCallback) => {
                    reCaptcha('login', token => {
                      data.token = token
                      this.login(data, errorCallback)
                    })
                  }}
                  onSubmitHandler={submitHandler => {
                    this.setState({ submitHandler })
                  }}
                  title={params.oauth ? 'Log in with OAuth' : 'Log in to begin'}
                >
                  <Form.Input
                    type='text'
                    name='email'
                    reference='Work email'
                    placeholder='Enter your work email'
                    autoComplete='off'
                    validator={{
                      minimumLength: 1
                    }}
                  />
                  <Form.Input
                    type='password'
                    name='password'
                    reference='Password'
                    placeholder='Enter your password'
                    autoComplete='off'
                    validator={{
                      minimumLength: 1
                    }}
                  />

                  <div className='mt-2'>
                    <Form.Link to='/password-reset'>
                      Forgot Password?
                    </Form.Link>
                  </div>

                  <AppButton
                    className='primary-cta'
                    loading={this.state.loading}
                    onClick={() => this.state.submitHandler()}
                    data-testid='login-button'
                  >
                    {params.oauth ? 'Authenticate' : 'Login'}
                  </AppButton>

                  {params.oauth ? <></> : <ThirdPartAuthentication />}
                </Form>
                {!isWorkingOffline() && (
                  <Link
                    to='/signup'
                    className='external'
                  >
                    Don‘t have access? Signup.
                  </Link>
                )}
              </Section>
            </View>
          )
  }
}
Login.propTypes = {
  location: PropTypes.object,
  user: PropTypes.object
}

// Export within router
export default withRouter(Login)
