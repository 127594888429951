import { Component } from 'react'
import Checkbox from '../../../interface/checkbox/Checkbox'
import PropTypes from 'prop-types'

export default class HowToReceive extends Component {
  constructor (props) {
    super(props)
    this.state = {
      input: this.props.parent.state.howToReceiveResult || {}
    }
  }

  setInput (name, value, callback) {
    const input = this.state.input
    input[name] = value
    this.setState({ input })
    // add result in the parent element
    this.props.parent.setState(prev => {
      return {
        ...prev,
        howToReceiveResult: this.state.input
      }
    })
    callback && callback(input)
  }

  onCheckBoxChange (data) {
    this.setInput(data.placeholder, data.checked)
  }

  onTextBoxChange (data) {
    this.setInput('other', data.value)
  }

  receiveMethods = [
    'Email',
    'Export as PDF',
    'Export as CSV/Excel',
    'Add rows to my Google Sheet',
    'Insert into my database',
    'Make an API call to my service',
    'CRMs (Salesforce, HubSpot, etc.)',
    'Standard API integration response (JSON, XML, CSV)'
  ]

  render () {
    return (
      <>
        <div className='main'>
          <div className='description'>
            <h2>How would like to receive the results?</h2>
            <h3 className='introduction small'>
              Please specify where and how you would like to receive the results. Select all that apply.
            </h3>
          </div>
          <div className='entries'>

            {this.receiveMethods.map((item, index) => (
              <Checkbox
                key={index}
                className='big'
                placeholder={item}
                onChange={e => {
                  this.onCheckBoxChange(e.target)
                }}
                checked={this.state.input[item]}
              />
            ))}

            <div className='textAreaContainer'>
              <textarea
                placeholder='Describe your other options here...'
                value={this.props.parent.state.howToReceiveResult?.other || ''}
                onChange={e => {
                  this.onTextBoxChange(e.target)
                }}
              >
              </textarea>
            </div>
          </div>
        </div>
      </>
    )
  }
}

HowToReceive.propTypes = {
  input: PropTypes.object,
  parent: PropTypes.object
}
