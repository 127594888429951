// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'

// Context
import Context from '~/context/global'
import PropTypes from 'prop-types'

class ReferralPrograms extends Component {
  static contextType = Context

  render () {
    return (
      <View name='referral-program'>
        <Header name='referral-header'>
          <h1 className='slogan'>Referral Program</h1>
          <h3 className='introduction'>
            Let&apos;s grow Base64.ai together and win bonuses with B2B referral program
          </h3>
        </Header>

        <Section name='bonuses' align='center' style={{ display: 'flex' }} tight={true}>
          <h2 className='slogan'>Referral Bonuses</h2>
          <h3 className='introduction'>
            We would love for you to introduce us to the companies in your network.
          </h3>
          <ul>
            <li>
              <img src='/static/images/icons/megaphone.png' className='green-overlay'></img>
              <p className='featureTitle'>Gift of $25 value</p>
              <p className='featureDescription'>For every warm introduction to a company that results in a meeting</p>
            </li>
            <li>
              <img style={{ width: '2.5rem' }} src='/static/images/icons/good-quality.png' className='green-overlay'></img>
              <p className='featureTitle'>$250</p>
              <p className='featureDescription'>If we continue meeting with your referral after the qualification call</p>
            </li>
            <li>
              <img src='/static/images/icons/handshake.png' className='green-overlay'></img>
              <p className='featureTitle'>10%</p>
              <p className='featureDescription'>If your referral makes a purchase, you will receive up to 10% commission on the first year&apos;s value</p>
            </li>
          </ul>
          <p className='footer'>Restrictions apply. Please see the referral form below for more details.</p>
        </Section>
        <Section name='submit-referral' align='center' tight={true}>
          <h2 className='slogan'>Submit A Referral</h2>
          <iframe
            src='https://share.hsforms.com/1bSOkRaNAQfuaferHZ8MNRw4e27s?utm_source=website&utm_campaign=website&utm_medium=website?embed=true'
            scrolling='auto'
            style={{ display: 'block', margin: 'auto', width: '100%', height: '1720px', marginTop: '2rem' }}
          >
          </iframe>
        </Section>
      </View>
    )
  }
}

ReferralPrograms.propTypes = {
  user: PropTypes.object
}

// Export within router
export default withRouter(ReferralPrograms)
