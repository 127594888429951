export default {
  'us-driver-license': {
    name: 'IDs & Driver licenses',
    canLowerCase: true,
    model: 'id+driver_license',
    headerImage: true,
    description: 'Instantly process IDs and driver licenses from all across the US, Europe, LATAM, and beyond,\nand extract data such as name, license number, expiration date, and Real ID compliance.',
    content: [
      { title: 'State', text: 'California', alignment: 'right', top: 25 / 224, width: 202 / 360 },
      { title: 'Is Real ID compliant?', text: 'Yes', alignment: 'right', top: 30 / 224, width: 31 / 360 },
      { title: 'License number', text: 'I1234562', alignment: 'right', top: 58 / 224, width: 146 / 360 },
      { title: 'Expiration date', text: '2014-08-31', alignment: 'right', top: 76 / 224, width: 139 / 360 },
      { title: 'Family name', text: 'SAMPLE', alignment: 'right', top: 92 / 224, width: 165 / 360 },
      { title: 'Given name', text: 'ALEXANDER JOSEPH', alignment: 'right', top: 104 / 224, width: 90 / 360 },
      { title: 'Address', text: '2570 24TH STREET\nANYTOWN, CA 95818', alignment: 'right', top: 120 / 224, width: 137 / 360 },
      { title: 'Date of birth', text: '1977-08-31', alignment: 'right', top: 140 / 224, width: 139 / 360 },
      { title: 'Face', image: '/static/content/features/data-extraction/samples/us-driver-license_face.jpg', alignment: 'right', top: 150 / 224, width: 225 / 360 },
      { title: 'Signature', image: '/static/content/features/data-extraction/samples/us-driver-license_signature.jpg', alignment: 'right', top: 186 / 224, width: 216 / 360 }
    ],
    isAnimated: true
  },
  'vehicle-registration': {
    name: 'Vehicle registrations',
    canLowerCase: true,
    model: 'vehicle/registration',
    headerImage: true,
    content: [
      { title: 'State', text: 'New York', alignment: 'right', top: 18 / 224, width: 50 / 360 },
      { title: 'License plate', text: 'AVZ1324', alignment: 'right', top: 56 / 224, width: 270 / 360 },
      { title: 'VIN', text: '5J6YH28729L005246', alignment: 'right', top: 87 / 224, width: 47 / 360 },
      { title: 'Issue date', text: '2017-09-12', alignment: 'right', top: 103 / 224, width: 12 / 360 },
      { title: 'Expiration date', text: '2019-10-06', alignment: 'right', top: 135 / 224, width: 12 / 360 }
    ],
    description: 'We extract DMV vehicle registrations\' data\nsuch as VIN, owner\'s name, and license plate.',
    isAnimated: true
  },
  'vehicle-insurance': {
    name: 'Vehicle insurances',
    canLowerCase: true,
    model: 'vehicle/insurance',
    headerImage: true,
    description: 'We extract US vehicle insurance cards\' data\nsuch as VIN, owner\'s name, and expiration date.',
    content: [
      { title: 'Company', text: 'Geico', alignment: 'right', top: 27 / 224, width: 265 / 360 },
      { title: 'Policy number', text: '000000001', alignment: 'right', top: 80 / 224, width: 299 / 360 },
      // { title: 'Effective date', text: '2013-10-03',alignment: 'right', top: 80 / 224, width: 176 / 360 },
      { title: 'Expiration date', text: '2014-04-03', alignment: 'right', top: 80 / 224, width: 65 / 360 },
      { title: 'Full name', text: 'MR GEICO GECKO', alignment: 'right', top: 100 / 224, width: 238 / 360 },
      { title: 'VIN', text: '1GNEK13ZXXXXXX', alignment: 'right', top: 165 / 224, width: 230 / 360 },
      { title: 'Year', text: '2013', alignment: 'right', top: 196 / 224, width: 319 / 360 },
      { title: 'Make', text: 'Chevrolet', alignment: 'right', top: 196 / 224, width: 204 / 360 }
    ],
    isAnimated: true
  },
  'international-id': {
    name: 'Worldwide IDs',
    model: 'id',
    description: 'We recognize IDs and driver licenses across the world,\nand extract data such as name, date of birth, and expiration date.',
    isAnimated: false
  },
  'passport': {
    name: 'Passports',
    canLowerCase: true,
    model: 'travel/passport',
    headerImage: true,
    description: 'We recognize passports from 180+ countries,\nand extract data such as the traveler name, visa type, and expiration date.',
    content: [
      { title: 'Country', text: 'Germany', alignment: 'right', top: 16 / 375, width: 270 / 600 },
      { title: 'Passport number', text: 'CO1XYCCG9', alignment: 'right', top: 35 / 240, width: 76 / 360 },
      { title: 'Family name', text: 'MUSTERMANN', alignment: 'right', top: 52 / 240, width: 185 / 360 },
      { title: 'Given name', text: 'ERIKA', alignment: 'right', top: 78 / 240, width: 208 / 360 },
      { title: 'Expiration date', text: '2027-02-28', alignment: 'right', top: 145 / 240, width: 109 / 360 },
      { title: 'Face', image: '/static/content/features/data-extraction/samples/passport_face.jpg', alignment: 'right', top: 95 / 240, width: 240 / 360 },
      { title: 'Signature', image: '/static/content/features/data-extraction/samples/passport_signature.jpg', alignment: 'right', top: 160 / 240, width: 12 / 360 }
    ],
    isAnimated: true
  },
  'visa': {
    name: 'Travel visas',
    canLowerCase: true,
    model: 'travel/visa',
    headerImage: true,
    description: 'We recognize 2000+ visa types from 180+ countries,\nand extract data such as traveler name, visa type, and expiration date.',
    content: [
      { title: 'Country', text: 'United States of America', alignment: 'right', top: 20 / 240, width: 100 / 720 },
      { title: 'Family name', text: 'TRAVELER', alignment: 'right', top: 76 / 240, width: 202 / 360 },
      { title: 'Given name', text: 'HAPPY', alignment: 'right', top: 94 / 240, width: 218 / 360 },
      { title: 'Passport number', text: '123456789', alignment: 'right', top: 114 / 240, width: 198 / 360 },
      { title: 'Birth date', text: '12DEC1966', alignment: 'right', top: 114 / 240, width: 73 / 360 },
      { title: 'Expiration date', text: '28JUL2014', alignment: 'right', top: 134 / 240, width: 67 / 360 },
      { title: 'Face', image: '/static/content/features/data-extraction/samples/visa_face.jpg', alignment: 'right', top: 105 / 240, width: 240 / 360 }
    ],
    isAnimated: true
  },
  'receipt': {
    name: 'Receipts',
    canLowerCase: true,
    model: 'finance/receipt/usa',
    headerImage: true,
    description: 'Extract company name, date, tax, and total,\nand automatically validate the results for the most accurate expense report.',
    content: [
      { title: 'Company name', text: 'Alta CA', alignment: 'right', top: 33 / 448, width: 350 / 720 },
      { title: 'Address', text: '1420 Market St\nSan Francisco, CA', alignment: 'right', top: 58 / 448, width: 310 / 720 },
      { title: 'Date', text: '2015-08-19', alignment: 'right', top: 113 / 448, width: 215 / 720 },
      { title: 'Time', text: '12:56:00', alignment: 'right', top: 141 / 448, width: 460 / 720 },
      { title: 'Amount', text: '100.66', alignment: 'right', top: 331 / 448, width: 198 / 720 },
      { title: 'Tip', text: '20.00', alignment: 'right', top: 363 / 448, width: 198 / 720 },
      { title: 'Total', text: '120.66', alignment: 'right', top: 396 / 448, width: 198 / 720 }
    ],
    isAnimated: true
  },
  'questions': {
    name: 'Questions & Answers AI',
    brandName: 'Questions & Answers',
    canLowerCase: true,
    demo: '/flow',
    demoText: 'Experience our AI by asking questions about your uploads.',
    description: 'Use Questions & Answers AI for Document Processing with Generative AI and Large Language Models (LLMs). Ask questions about a document’s data, audio files, and videos by manually typing a prompt from your Flow results or changing Flow settings to automate the same questions to all documents processed in a specific Flow.',
    youtube: 'Lz07tcshEQc'
  },
  'invoice': {
    name: 'Invoices & Purchase order documents',
    brandName: 'Invoices & Purchase orders',
    canLowerCase: true,
    model: 'finance/invoice',
    headerImage: true,
    demoText: 'Demo our cutting-edge Invoice & Purchase order data extraction AI!',
    description: 'Invoices, supplied by sellers post-transaction, meticulously outline provided items, costs, taxes, and the total payment due. They serve as both payment requests and vital financial records. Conversely, purchase orders are formal documents buyers issue to sellers. These express the clear intent to buy goods or services and encompass crucial specifics: item types, quantities, prices, and anticipated delivery dates. Base64.ai automatically extract financial information from invoices and purchase orders to process essential data including table line items, signatures, and vendor information.',
    content: [
      { title: 'Company name', text: 'Gearblock Inc', alignment: 'right', top: 38 / 480, width: 465 / 720 },
      { title: 'Address', text: '123 Main St\nNew York, NY 10016', alignment: 'right', top: 77 / 480, width: 492 / 720 },
      { title: 'Invoice date', text: '2020-02-21', alignment: 'right', top: 160 / 480, width: 40 / 720 },
      { title: 'Total', text: '261.90', alignment: 'right', top: 410 / 480, width: 100 / 720 },
      { title: 'Balance', text: '161.90', alignment: 'right', top: 463 / 480, width: 100 / 720 }
    ],
    isAnimated: true
  },
  'check': {
    name: 'Checks',
    canLowerCase: true,
    model: 'finance/check',
    headerImage: true,
    description: 'Extract the financial information from the checks,\nand automatically insert them into your accounting system.\n\n Base64.ai automatically cross verifies amount, check number,\n and routing number between the MICR (bottom numbers) and text in the check.',
    content: [
      { title: 'Company name', text: 'LEONORDO COMPUTER & ELECTRONICS', alignment: 'right', top: 20 / 161, width: 176 / 371 },
      { title: 'Check number', text: '5279', alignment: 'right', top: 24 / 161, width: 10 / 371 },
      { title: 'Date', text: '2020-05-15', alignment: 'right', top: 44 / 161, width: 80 / 371 },
      { title: 'Payee', text: 'CTNT. INC.', alignment: 'right', top: 60 / 161, width: 186 / 371 },
      { title: 'Amount', text: '376.36$', alignment: 'right', top: 60 / 161, width: 18 / 371 },
      { title: 'Bank name', text: 'City National Bank', alignment: 'right', top: 106 / 161, width: 276 / 371 },
      { title: 'Signature', image: '/static/content/features/data-extraction/samples/check_signature.png', alignment: 'right', top: 116 / 161, width: 80 / 371 },
      { title: 'Routing number', text: '122016066', alignment: 'right', top: 142 / 161, width: 216 / 371 },
      { title: 'Account number', text: '914154530', alignment: 'right', top: 142 / 161, width: 138 / 371 }
    ],
    isAnimated: true
  },
  'acord': {
    name: 'ACORD Forms',
    categoryName: 'Forms',
    model: 'insurance/acord',
    headerImage: true,
    description: 'ACORD (Association for Cooperative Operations Research and Development) Forms enable the efficient and effective flow of data among all stakeholders across the insurance value chain. Base64.ai is a member of the non-profit ACORD Organization. Our AI extracts all data from multi-page and complex ACORD forms whether they are digital or scanned.',
    listHeader: 'Most used ACORD forms include:',
    list: [
      'ACORD 3: General liability notice of occurrence/claim',
      'ACORD 22: Intermodal interchange certificate of insurance',
      'ACORD 23: Vehicle or equipment certificate of insurance form',
      'ACORD 24: Certificate of property insurance form',
      'ACORD 25: Certificate of liability insurance form',
      'ACORD 27: Evidence of property insurance form',
      'ACORD 28: Evidence of commercial property insurance form',
      'ACORD 35: Cancellation request/policy release',
      'ACORD 36: Agent/broker of record change',
      'ACORD 45: Additional interest',
      'ACORD 60: Flood insurance notice/rejection',
      'ACORD 60 US: Insurance supplement',
      'ACORD 61 PA: Pennsylvania auto supplement',
      'ACORD 62 US: Insurance supplement - standard fire policy only',
      'ACORD 63: Fraud statements',
      'ACORD 69 MD: Maryland insurance supplement',
      'ACORD 75: Insurance binder',
      'ACORD 80: Homeowner application',
      'ACORD 84: Dwelling fire application',
      'ACORD 101: Additional remarks schedule form',
      'ACORD 105: Apartment building supplement',
      'ACORD 125: Commercial insurance application form',
      'ACORD 126: Commercial general liability section form',
      'ACORD 127: Business auto section',
      'ACORD 128: Garage and dealers section',
      'ACORD 129: Vehicle schedule',
      'ACORD 130: Workers compensation application form',
      'ACORD 131: Umbrella excess section form',
      'ACORD 134: Non-election form',
      'ACORD 137 CA: California commercial auto',
      'ACORD 137 IA: Iowa commercial auto',
      'ACORD 137 IL: Illinois commercial auto',
      'ACORD 137 LA: Louisiana commercial auto',
      'ACORD 137 NC: North Carolina commercial auto',
      'ACORD 137 NJ: New Jersey commercial auto',
      'ACORD 137 NV: Nevada commercial auto',
      'ACORD 137 NY: New York commercial auto',
      'ACORD 137 PA: Pennsylvania commercial auto',
      'ACORD 137 SC: South Carolina commercial auto',
      'ACORD 137 TX: Texas commercial auto',
      'ACORD 137 WA: Washington commercial auto',
      'ACORD 138 LA: Louisiana garage and dealers',
      'ACORD 139: Statement of values',
      'ACORD 140: Property section',
      'ACORD 141: Crime section',
      'ACORD 144: Glass and sign supplement',
      'ACORD 146: Equipment floater section',
      'ACORD 160: Business owners section form',
      'ACORD 163: Commercial auto driver information schedule',
      'ACORD 175: Commercial policy change request',
      'ACORD 810: Business income',
      'ACORD 3101: Additional remarks schedule form'
    ],
    content: [
      { title: 'Document type', text: 'ACORD 25 form', alignment: 'right', top: 30 / 480, width: 160 / 720 },
      { title: 'Contact', text: 'John Hancock', alignment: 'right', top: 140 / 480, width: 240 / 720 },
      { title: 'Insured', text: 'A Cow Jumped Over the Moon, LLC\n500 Manhattan Ave., Ste. G\nNew York, NY 10001', alignment: 'right', top: 220 / 480, width: 480 / 720 },
      { title: 'Commercial General Liability', text: 'Yes', alignment: 'right', top: 368 / 480, width: 530 / 720 }
    ],
    isAnimated: true
  },
  'sbc': {
    name: 'Summary of benefits & coverage',
    canLowerCase: true,
    model: 'health/sbc',
    headerImage: true,
    description: 'We extract over 100 fields from\n12 pages long Summary of Benefits and Coverage form in 20 seconds.',
    isAnimated: false
  },
  'license-plate': {
    name: 'License plates',
    canLowerCase: true,
    model: 'vehicle/license_plate/international',
    headerImage: true,
    description: 'We extract license plate numbers\n from images of cars.',
    isAnimated: false
  },
  'shipping-container': {
    name: 'Shipping containers',
    canLowerCase: true,
    model: 'vehicle/container',
    headerImage: true,
    description: 'We can extract data from shipping containers,\nand transcribe the codes into human readable text.',
    content: [
      // { title: 'Owner code', text: 'SUDU', alignment: 'right', top: 51 / 240, width: 95 / 360 },
      { title: 'Serial number', text: '546347', alignment: 'right', top: 40 / 240, width: 26 / 360 },
      { title: 'Container type', text: 'General purpose\ncontainer (42G1)', alignment: 'right', top: 70 / 240, width: 41 / 360 },
      { title: 'Maximum gross', text: '32500 kg\n71650 lbs', alignment: 'right', top: 133 / 240, width: 33 / 360 },
      { title: 'Maximum payload', text: '28700 kg\n63270 lbs', alignment: 'right', top: 184 / 240, width: 33 / 360 }
    ],
    isAnimated: true
  },
  'file-ai': {
    name: 'Files',
    canLowerCase: true,
    model: 'ocr',
    headerImage: true,
    description: 'Use our OCR API to extract the text in any file.\nWe accept images, PDFs, Microsoft Office documents, and more.\nYou can send multi-page documents and files with multiple documents too.',
    listHeader: 'The following file formats are supported:',
    list: [
      'Images: JPEG, PNG, GIF, SVG, HEIC, WEBP, TIFF',
      'Microsoft Office: DOC, DOCX, XLS, XLS, PPT, PPTX',
      'Open Office: ODS, ODT, ODP',
      'PDF: Both digital and image-only files are supported. PDFs may be single or multi-page and may contain multiple document types (e.g., 3 ID pages plus 1 invoice).',
      'ZIP: May only contain the supported file formats',
      'MSG: Outlook message files and the contents within (e.g., email\'s PDFs attachments)',
      'Audio: MP3, OGG, FLAC, WAV',
      'Video: MOV, MP4, AVI, WMV, M4V',
      'Please contact us if you need another file format.'
    ]
  },
  'form-ai': {
    name: 'Forms',
    canLowerCase: true,
    model: 'semantic',
    headerImage: true,
    description: 'Base64.ai can automate your form processing by identifying the labels and extracting the corresponding values. Please contact us for your custom forms.',
    content: [
      { title: 'Document type', text: 'W-2', alignment: 'right', top: 54 / 480, width: 540 / 720 },
      { title: 'Employee\'s social security number', text: '123-45-6789', alignment: 'right', top: 151 / 480, width: 562 / 720 },
      { title: 'Wages, tips, other compensation', text: '123,456.78', alignment: 'right', top: 328 / 480, width: 572 / 720 },
      { title: 'Social security tax withheld', text: '23,456.78', alignment: 'right', top: 392 / 480, width: 252 / 720 }
    ],
    isAnimated: true,
    text: [
      {
        title: 'How we process Forms?',
        content: [
          'Processing with our Form AI allows you to extract data from all types of forms, including your custom forms that our AI '
          + 'has not seen before. This makes it easier to identify data values with high accuracy which provides a quick and simple process '
          + 'that reduces the margin of error.',
          'Form AI is a unique solution that can be set up to scan a large variety of forms and focus on specific areas of the documents if needed. '
          + 'It can scan customer intake forms, shipping documents, legal documents, and more - all at a fraction of the cost of manual data entry.'
        ]
      }
    ]
  },
  'handwritten-document': {
    name: 'Handwriting',
    canLowerCase: true,
    headerImage: true,
    model: 'ocr',
    description: 'Base64.ai can read handwriting too! Use our APIs to extract data from forms and notes.'
  },
  'mobile-sdk': {
    name: 'Mobile SDK',
    headerImage: true,
    demo: 'https://play.google.com/store/apps/details?id=com.base64ai.camera',
    description: 'Base64.ai is compatible with mobile platforms. We also provide on-device, air-tight scanning functionality for iPhone & Android apps.'
  },
  'signature-ai': {
    name: 'Signatures',
    brandName: 'Signature AI',
    canLowerCase: true,
    subDocument: true,
    headerImage: true,
    description: 'Recognize, extract, and verify signatures\non documents and images for signature verification.',
    demoLinks: [
      {
        title: 'Extract signatures from any document including forms, contracts, and IDs',
        url: '/demo/signature-recognition',
        buttonName: 'Recognize signatures'
      },
      {
        title: 'Compare signatures on multiple documents to verify signature authenticity',
        url: '/demo/signature-verification',
        buttonName: 'Verify signatures'
      }
    ],
    text: [
      {
        title: 'How does Signature AI work?',
        content: [
          'Trusting and verifying the legitimacy of signatures doesn\'t have to be a long process.'
          + ' Signature AI uses advanced deep learning methods to recognize and verify signatures in seconds.'
          + 'Using Signature Recognition AI is a simple and effective way for companies to detect signatures on contracts, forms, passports,'
          + ' and more. It\'s as simple as uploading the document and the signatures will be recognized automatically.',
          'Signature Verification is very similar in terms of process, but the end outcome is that it compares signatures on multiple documents to make sure that they are a match.'
          + ' This is a simple way to check signatures on important documents to determine that they are a match without having to rely on guessing or human judgment.'
          + ' Our Signature Verification AI solution is used for comparing signatures on IDs, driver licenses, and passports against the signatures on contracts, forms, checks, and other important documents.'
        ]
      },
      {
        title: 'Why does Signature Verification matter?',
        content: [
          'Verifying the authenticity of signatures is key to proving the legitimacy of a document. These could be legal'
          + ' documents, documents needed for travel, or anything else of such importance.',
          'Signature Recognition allows a convenient way to ensure the documents were signed by hand, while Signature Verification allows to ensure it was signed by the correct person.'
          + ' Signature AI is a great tool for banks, law enforcement, law offices, and company that rely on signed documents.'
          + ' It adds an extra layer of protection and helps to protect companies from legal troubles or processing delays.',
          'Companies who use signature recognition and verification for their documentation are proving that they\'re taking the best steps to provide safe and securely signed documents.'
        ]
      }
    ],
    content: [
      { title: 'Signature1', text: '', alignment: 'right', top: 200 / 419, width: 180 / 900 },
      { title: 'Signature2', text: '', alignment: 'right', top: 340 / 419, width: 200 / 900 }
    ],
    isAnimated: true
  },
  'facial-ai': {
    name: 'Faces',
    brandName: 'Facial AI',
    canLowerCase: true,
    subDocument: true,
    headerImage: true,
    description: 'Replace manual user verification with our fast & reliable AI.\n'
    + ' Recognize and verify faces on documents and images.\n'
    + ' Facial Verification AI is the perfect way to compare faces\nin documents and for ID verification.',
    demoLinks: [
      {
        title: 'Extract faces from any document including IDs, driver licenses, and passports',
        url: '/demo/facial-recognition',
        buttonName: 'Recognize faces'
      },
      {
        title: 'Compare faces on documents with selfies to automate user onboarding and KYC',
        url: '/demo/facial-verification',
        buttonName: 'Verify faces'
      }
    ],
    text: [
      {
        title: 'What is Facial Verification?',
        content: [
          'Facial Verification AI is the perfect way to compare faces on documents and for ID verification.'
          + ' When it comes to AI solutions, this is a great way to determine if a person and their ID are a match.'
          + ' It\'s a fast and secure way to scan all sorts of IDs, from driver\'s licenses to passports, visas, and more.',
          'The verification software is as easy as uploading the document and an image or picture and determining if they are a match.'
          + ' When you can use a powerful tool to verify documents, data, and more, it\'s a simple and effective way to ensure that'
          + ' there aren\'t false documents or information being presented.',
          'Facial verification takes away the need for manual identification and puts AI automation at the forefront. This process is fast, reliable, and simple.'
        ]
      },
      {
        title: 'How does Facial Verification work?',
        content: [
          'The process starts with recognizing faces on both documents. If one or more inputs has no faces, the verification provides an error.'
          + ' If both inputs have faces, the AI compares 128 data points on each face and creates a similarity score.',
          ' If the score is above a given threshold, the AI considers the faces matching and returns a positive verification result.',
          'Facial verification AI can be used for so many things. It can be used to track employees, log school attendance,'
          + ' fraud prevention, and even Know Your Customer (KYC) verification. Having the option for facial recognition is a great way to prove '
          + '  safety and security to those using your services. It can be used on apps, in person, and in dealing with anything online.',
          'Using artificial intelligence like facial recognition means that the same type of software and procedures can easily be used company-wide.'
          + ' This ensures that policies and procedures are followed and that the same security and protocols are in place.'
          + ' It also cuts down on the amount of time spent trying to manually identify and reduces human errors and mistakes.'
        ]
      }
    ],
    content: [
      { title: 'Face1', text: 'detected', alignment: 'right', top: 70 / 258, width: 192 / 387 },
      { title: 'Face2', text: 'detected', alignment: 'right', top: 90 / 258, width: 150 / 387 },
      { title: 'Face3', text: 'detected', alignment: 'right', top: 80 / 258, width: 100 / 387 },
      { title: 'Face4', text: 'Jason Segel recognized', alignment: 'right', top: 40 / 258, width: 20 / 387 },
      { title: 'Face5', text: 'detected', alignment: 'right', top: 126 / 258, width: 40 / 387 }
    ],
    isAnimated: true
  },
  'hitl': {
    name: 'Human-In-the-Loop',
    alternativeTitle: 'Validate results with',
    brandName: 'Human-In-the-Loop (HITL) integration',
    canLowerCase: true,
    headerImage: true,
    description: 'Base64.ai offers a scalable and secure human review team to validate processing results with 100% accuracy. '
    + 'Our 24/7 HITL service helps businesses extract data from complex documents and images without hindering processing time. All reviewers passed international background checks by Checkr and are extensively trained, monitored, and work across time zones and languages. '
    + 'The process includes real-time monitoring, quick data validation, and privacy measures like watermarks and redaction.',
    demo: '/flow',
    demoText: 'Harness the power of Human-In-the-Loop (HITL) document validation using Flow.',
    benefit: {
      title: 'The benefits of Human-In-the-Loop',
      subtitle: ''
    },
    isAnimated: false
  },
  'checkbox': {
    name: 'Checkboxes & Radio Buttons',
    description: 'Checkboxes and Radio Buttons are interactive elements in documents and forms. Checkboxes allow users to select multiple options from a list independently, indicating a binary choice (checked or unchecked) for each item. Radio Buttons, on the other hand, permit users to select only one option from a list, ensuring exclusive selection among the available choices. Both elements serve to collect user input and facilitate decision-making in forms, surveys, and other interactive content. Base64.ai detects Checkboxes and Radio buttons from documents and understands if they are checked alongside associated text.',
    isAnimated: true,
    headerImage: true,
    content: [
      { title: 'Graduation (Check one)', text: 'College', alignment: 'right', top: 310 / 1046, width: 224 / 1842 },
      { title: 'Are you legally authorized to work in the United States?', text: 'Yes', alignment: 'right', top: 818 / 1046, width: 950 / 1842 },
      { title: 'Were you ever discharged by any company?', text: 'No', alignment: 'right', top: 910 / 1046, width: 842 / 1842 }
    ]
  },
  'multimedia': {
    name: 'Multimedia',
    alternativeTitle: 'Extract data from',
    brandName: 'Multimedia (audio and video)',
    description: 'Multimedia involves using both sound and visual elements to share information, entertainment, or messages. This means, processing data from recorded sound, music, and voiceovers, from audio and video. Use our Questions & Answers feature to query Base64.ai\'s Document AI regarding audio and video files, like audiobooks or YouTube videos, and generate responses.',
    youtube: 'I_VHltENBGc',
    demoText: 'Experience our AI on video and audio files.',
    demo: '/flow'
  },
  'document-generation': {
    name: 'Document Generation',
    alternativeTitle: 'Generate digital PDF files using',
    brandName: 'Document Generation',
    description: 'Document Generation refers to the automated procedure of producing digital documents based on the information extracted. Document Generation technology helps you to create a clean and digitized PDF from any scanned copy using its data derived from the AI extraction results and manual corrections.',
    demoText: 'Experience our Document Generation feature.',
    demo: '/flow'
  },
  'shape-verification': {
    name: 'Shape Verification',
    alternativeTitle: 'Classify documents by Shape with ',
    description: 'Shape verification in document processing refers to confirming the correct layout, structure, and arrangement of elements within a document. It involves  the validating that the document adheres to predetermined dimensions or expected visual patterns, such as checks are always horizontal rectangles. This process helps ensure accurate data extraction and consistent formatting, enhancing the reliability and quality of processed documents. Our AI can classify documents based on their shape such as card, letter, check, and more to ensure correct classification was selected.',
    isAnimated: false,
    headerImage: true,
    demo: '/flow',
    demoText: 'Experience our Shape Verification feature.'
  },
  'photo-captioning': {
    name: 'Photo Captioning',
    alternativeTitle: 'Caption data with',
    description: 'Photo captioning is the practice of adding descriptive text or a brief explanation to a photograph or image. The purpose of a photo caption is to provide context, clarify the subject matter, or offer additional information about the image. It helps viewers understand the content and significance of the photo, especially in situations where the image alone might not fully convey its meaning or context. Get more from documents with Base64.ai’s photo captioning. Have fun trying it on any photo from a glass on the table to the Statute of Liberty!',
    headerImage: true,
    isAnimated: false,
    demo: '/flow',
    demoText: 'Experience our Photo captioning feature'
  },
  'custom-taxonomy': {
    name: 'Custom Taxonomy',
    description: 'Documents often lack consistent field naming, posing challenges in normalizing data extraction across various formats. This mismatch in key phrasing can lead to system failures in recognizing expected keys and values. Taxonomy resolves this by comprehending diverse naming conventions, ensuring smooth data extraction even from different document types. To address this issue Base64.ai offers Taxonomy, a feature used to record alternative spelling, wording, or synonyms for document types that use multiple phrases to the same keys',
    youtube: '1-WXjvu228w',
    demos: [
      {
        title: 'Learn more about Custom Taxonomy from the help center.',
        url: 'https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2618361',
        buttonName: 'Help Center'
      }
    ],
    blogPost: 'Taxonomy-Management-for-Document-Processing-913bcb71'
  },
  'text-to-speech': {
    name: 'Text-to-Speech AI',
    brandName: 'Text-to-Speech',
    alternativeTitle: 'Convert text into speech with',
    canLowerCase: true,
    description: 'This assistive technology transforms text into audible speech enhances accessibility and makes it easier for users with visual impairments to understand text-based information. Boost your document’s accessibility with Base64.ai\'s text-to-speech feature to transform OCR text into MP3 audio.'
  },
  'speech-to-text': {
    name: 'Speech-to-Text AI',
    brandName: 'Speech-to-Text',
    alternativeTitle: 'Convert speech into text with',
    canLowerCase: true,
    description: 'Speech-to-Text AI transforms spoken language into written text, allowing easy transcription, insight, sharing of data, and accessibility  to the hearing impaired. We offer Speech-To-Text AI to transcribe audio and video files into text. Speech-to-Text goes hand-to-hand with our Multimedia (audio and video) processing and Questions & Answers AI to find information by asking queries about a file.'
  },
  'iban': {
    name: 'IBAN Detection',
    alternativeTitle: 'Extract IBAN with',
    canLowerCase: true,
    description: 'An International Bank Account Number (IBAN) is a standardized international numbering system used to uniquely identify bank accounts. It was developed to facilitate cross-border transactions, particularly within the European Union and other participating countries. The IBAN consists of a series of alphanumeric characters that include a country code, a two-digit checksum, and the account number itself. Base64.ai offers IBAN detection to identify bank accounts instantly.',
    isAnimated: true,
    headerImage: true,
    content: [
      { title: 'IBAN', text: 'GB 14 WSXD 230239 12345678', alignment: 'right', top: 620 / 1176, width: 1420 / 1920 },
      { title: 'Country Code', text: 'GB', alignment: 'right', top: 620 / 1176, width: 1785 / 1920 },
      { title: 'Check Digit Code', text: '14', alignment: 'right', top: 620 / 1176, width: 1748 / 1920 },
      { title: 'Bank Identifier Code', text: 'WSXD', alignment: 'right', top: 620 / 1176, width: 1660 / 1920 },
      { title: 'Branch Code (Sort Code)', text: '230239', alignment: 'right', top: 620 / 1176, width: 1550 / 1920 },
      { title: 'Account Number', text: '12345678', alignment: 'right', top: 620 / 1176, width: 1420 / 1920 }
    ]
  },
  'irs-tax-form': {
    name: 'IRS Tax Forms',
    brandName: 'IRS Forms',
    canLowerCase: true,
    headerImage: true,
    isAnimated: true,
    description: 'IRS tax forms are official documents from the U.S. Internal Revenue Service. They\'re used by individuals, businesses, and organizations to report income, deductions, credits, and financial info as required by the tax code. These forms cover various taxes like income, employment, estate, and gift tax, tailored to different taxpayers and activities, ensuring compliance and accurate tax calculation. Verify identity and finances using our IDP solution for 900+ IRS document versions, no extra training needed. See supported IRS documents in the Finance section of our Model Types page or click here.',
    demo: '/flow',
    demoText: 'Experience the power of IRS tax form processing',
    benefit: {
      title: 'The benefits of IRS Tax Forms\n document processing',
      subtitle: ''
    },
    content: [
      { title: 'Your first name and middle initial', text: 'JOHN P', alignment: 'right', top: 440 / 1678, width: 1926 / 2166 },
      { title: 'Last name', text: 'MICHAELS II', alignment: 'right', top: 440 / 1678, width: 1136 / 2166 },
      { title: 'Your social security number', text: '900 11 0000', alignment: 'right', top: 440 / 1678, width: 136 / 2166 },
      { title: 'State', text: 'TX', alignment: 'right', top: 695 / 1678, width: 826 / 2166 },
      { title: 'ZIP code', text: '77099', alignment: 'right', top: 695 / 1678, width: 576 / 2166 }
    ]
  },
  'geocoding': {
    name: 'Address Geocoding',
    alternativeTitle: 'Extract location data with',
    canLowerCase: true,
    description: 'Address geocoding is the conversion of textual addresses into geographic coordinates, facilitating the translation of human-readable location information into machine-readable and structured data. Address geocoding enhances the accuracy and standardization of extracted addresses, enabling location-based insights for analysis, optimizing routes for operations, and supporting advanced mapping and geospatial analysis. Our Intelligent Document Processing (IDP) solution enables the splitting or flattening of addresses into street, city, state, zip code, country names, and coordinates.'
  },
  'classification': {
    name: 'Classification',
    brandName: 'Classification AI',
    alternativeTitle: 'Detect different document types using',
    canLowerCase: true,
    description: 'Document classification involves categorizing documents into predefined classes or categories based on their content, structure, or context. It is a critical component of intelligent document processing (IDP) where our advanced artificial intelligence (AI) and machine learning (ML) automate the organization and categorization of various types of documents. Document Classification is a must-have feature if you are not sure about the document type or need to process multipage documents (PDFs) that contain multiple document types (invoices, IDs, etc.). Base64.ai can classify over 2,800 document types or unknown samples using Semantic AI, for automatic text, tables, data, faces, and signature extraction. Documents are organized into categories called models.',
    demo: '/flow',
    demoText: 'Experience our AI-driven Classification',
    headerImage: true,
    isAnimated: true,
    content: [
      { title: 'Model', text: 'Loan Agreement', alignment: 'right', top: 50 / 100, width: 10 / 100 }
    ]
  },
  'cms1500': {
    name: 'CMS 1500',
    alternativeTitle: 'Extract data from',
    brandName: 'CMS 1500: Health Insurance Claim Forms',
    canLowerCase: true,
    headerImage: true,
    description: 'The CMS-1500 form is the standard claim form used by a non-institutional provider or supplier to bill Medicare carriers and durable medical equipment regional carriers (DMERCs) when a provider qualifies for a waiver from the Administrative Simplification Compliance Act (ASCA) requirement for electronic submission of claims. '
    + 'It is also used for billing some Medicaid State Agencies. Use Base64.ai to streamline CMS-1500 document processing by instantly extracting data points like dates, checkboxes, and QR codes to streamline your Health Insurance Claim document processing.',
    demo: '/flow',
    demoText: 'Experience the power of Health insurance claim processing',
    benefit: {
      title: 'The benefits of CMS 1500: Health Insurance Claim Forms\n document processing',
      subtitle: ''
    },
    content: [
      { title: 'Patient\'s Birth Date', text: '04/23/1978', alignment: 'right', top: 265 / 772, width: 608 / 1246 },
      { title: 'Patient\'s Name', text: 'SMITH, SAM', alignment: 'right', top: 265 / 772, width: 1086 / 1246 },
      { title: 'Sex', text: 'Male', alignment: 'right', top: 265 / 772, width: 566 / 1246 },
      { title: 'Employment', text: 'No', alignment: 'right', top: 510 / 772, width: 566 / 1246 },
      { title: 'Auto Accident', text: 'No', alignment: 'right', top: 560 / 772, width: 566 / 1246 },
      { title: 'Other Accident', text: 'No', alignment: 'right', top: 610 / 772, width: 566 / 1246 }
    ],
    isAnimated: true
  },
  'smart-health-card': {
    name: 'SMART Health Card',
    alternativeTitle: 'Extract data from',
    brandName: 'SMART Health Cards',
    canLowerCase: true,
    headerImage: true,
    description: 'SMART Health Cards are verified versions of your clinical information, such as vaccination history or test results. They allow you to keep a copy of your important health records on hand and easily share this information with others if you choose. '
    + 'SMART Health Cards contain a secure QR code and may be saved digitally or printed on paper. Instantly extract health information from SMART Health Cards to read Covid-19 vaccination records, test results, and medical history.',
    demo: '/flow',
    demoText: 'Experience the power of AI-driven Health Card processing',
    benefit: {
      title: 'The benefits of SMART Health Card\n document processing',
      subtitle: ''
    },
    isAnimated: false
  },
  'safety-datasheet': {
    name: 'Safety datasheets',
    alternativeTitle: 'Extract data from',
    brandName: 'Safety datasheets',
    canLowerCase: true,
    headerImage: true,
    description: 'The Hazard Communication Standard (HCS), requires that the chemical manufacturer, distributor, or importer provide Safety Data Sheets (SDSs) (formerly MSDSs or Material Safety Data Sheets) for each hazardous chemical to downstream users to communicate information on these hazards. '
    + 'This brief provides guidance to help workers who handle hazardous chemicals to become familiar with the format and understand the contents of the SDSs. Use Base64.ai to process information like properties of each chemical, environmental health hazards, and protective measures from tables, and key-value pairs.',
    demo: '/flow',
    demoText: 'Unlock the Potential of Safety Data Sheet Processing',
    benefit: {
      title: 'The benefits of Safety datasheet\n document processing',
      subtitle: ''
    },
    isAnimated: false
  },
  'osha-forms': {
    name: 'OSHA Forms',
    alternativeTitle: 'Extract data from',
    brandName: 'OSHA Forms',
    canLowerCase: true,
    headerImage: true,
    description: 'Occupational Safety and Health Administration (OSHA) forms ensure workplace safety by providing employers with valuable insights into potential hazards and trends, which in turn allows them to take corrective actions and preventive measures. '
    + 'Base64.ai extract all information from OSHA forms, including checkboxes and table structures, enabling a timely and efficient process of workplace hazard notifications.',
    demo: '/flow',
    demoText: 'Discover the Advantages of processing OSHA forms with our AI',
    benefit: {
      title: 'The benefits of OSHA Form\n document processing',
      subtitle: ''
    },
    isAnimated: false
  },
  'bill-of-lading': {
    name: 'Bill of Lading',
    alternativeTitle: 'Extract data from',
    brandName: 'Bill of Lading',
    canLowerCase: true,
    headerImage: true,
    description: 'A Bill of Lading is issued by a carrier to a shipper, signed by the captain, agent, or owner of a vessel, furnishing written evidence regarding receipt of the goods, the conditions on which transportation is made (contract of carriage), and the engagement to deliver goods at the prescribed port of destination to the lawful holder of the Bill of Lading. '
    + 'Use Base64.ai to extract essential data like the type, quantity, and destination of the goods being carried and process shipments on time.',
    demo: '/flow',
    demoText: 'Discover the Advantages of processing Bills of Lading with our AI',
    benefit: {
      title: 'The benefits of Bill of Lading\n document processing',
      subtitle: ''
    },
    isAnimated: false
  },
  'customs-declaration': {
    name: 'Customs declaration forms',
    alternativeTitle: 'Extract data from',
    brandName: 'Customs declaration forms',
    canLowerCase: true,
    headerImage: true,
    description: 'The Customs Declaration form gathers essential information about the traveler and the items being brought into a country, including agricultural and wildlife products, as well as any prior farm visits. '
    + 'Legally, a customs declaration is the act whereby a person indicates the wish to place goods under a given customs procedure. Process Custom Declaration documents with AI-driven data extraction to understand checkbox information, and extract key-value pairs, and signatures.',
    demo: '/flow',
    demoText: 'Discover the Advantages of processing Customs declaration forms with our AI',
    benefit: {
      title: 'The benefits of Customs declaration form\n document processing',
      subtitle: ''
    },
    isAnimated: false
  },
  'semantic-ai': {
    name: 'Semantic',
    alternativeTitle: 'Extract data from any document with',
    brandName: 'Semantic AI',
    canLowerCase: true,
    headerImage: true,
    description: 'With Semantic AI, we extract relevant information from any document, even ones not seen before. This includes document classification, a summary of the content, and extracting key-value pairs and tables, which are available in JSON response. '
    + 'Our semantic reader includes a powerful Large Language Model (LLM), Generative AI, and Named Entity Recognition (NER) service, ensuring precise extraction of crucial details such as organizations, locations, and people from unstructured text data. '
    + 'No training for pre-existing models is required.',
    demoLinks: [
      {
        title: 'Harness the power of Semantic AI now!',
        url: '/flow',
        buttonName: 'Understand documents'
      }
    ],
    benefit: {
      title: 'The benefits of Semantic AI\n document processing',
      subtitle: ''
    },
    content: [
      { title: 'Booking Reference', text: '93R3M', alignment: 'right', top: 655 / 1486, width: 1670 / 2550 },
      { title: 'Ticket Number', text: '61834490V-38401', alignment: 'right', top: 1000 / 1486, width: 1570 / 2550 },
      { title: 'From', text: 'Tokyo (NRT-Narita Intl)', alignment: 'right', top: 1245 / 1486, width: 1320 / 2550 },
      { title: 'To', text: 'Los Angeles (LAX-Los Angeles Intl)', alignment: 'right', top: 1300 / 1486, width: 1130 / 2550 }
    ],
    isAnimated: true
  },
  'document-ai': {
    name: 'Documents',
    brandName: 'Document AI',
    canLowerCase: true,
    headerImage: true,
    description: 'Our powerful AI can understand and extract information from your documents\n'
    + 'with swift ease and reliability. Learn more about Base64.ai and our powerful IDP capabilities.\n'
    + 'Let\'s get work done faster.',
    demoLinks: [
      {
        title: 'Speed up document processing by automatically extracting text, tables, data, faces, and signatures',
        url: '/flow',
        buttonName: 'Understand Documents'
      },
      {
        title: 'Help stop the spread of Covid-19 by validating Covid-19 vaccination cards with IDs, driver licenses, or passports',
        url: '/demo/covid-19-vaccination-validation',
        buttonName: 'Validate Covid-19 vaccination'
      },
      {
        title: 'Validate ACORD 25 Certificate of Liability Insurance form against the minimum requirements',
        url: '/demo/acord25-validation',
        buttonName: 'Validate ACORD 25'
      },
      {
        title: 'Validate Health & Safety Documents against expired revision dates, watermarks, and draft text in seconds',
        url: '/demo/health-and-safety-document-validation',
        buttonName: 'Validate Health & Safety Doc'
      },
      {
        title: 'Embed document processing in your application and process documents with zero lines of code',
        url: '/widgets/document-processing/',
        buttonName: 'Embedded doc processing'
      }
    ],
    benefit: {
      title: 'The benefits of automated\n document processing',
      subtitle: ''
    },
    content: [
      { title: 'Company name', text: 'Gearblock Inc', alignment: 'right', top: 38 / 480, width: 465 / 720 },
      { title: 'Address', text: '123 Main St\nNew York, NY 10016', alignment: 'right', top: 77 / 480, width: 492 / 720 },
      { title: 'Invoice date', text: '2020-02-21', alignment: 'right', top: 160 / 480, width: 40 / 720 },
      { title: 'Total', text: '261.90', alignment: 'right', top: 410 / 480, width: 100 / 720 },
      { title: 'Balance', text: '161.90', alignment: 'right', top: 463 / 480, width: 100 / 720 }
    ],
    isAnimated: true,
    text: [
      {
        title: 'Data Extraction from Documents',
        content: [
          'Base64.ai offers AI automation for all types of documents. Our AI processes over 2,800 document types out of the box '
          + 'including IDs, driver\'s licenses, passports, invoices, checks, and all types of forms. ',
          'With our semantic processing capability allowing businesses to process any document type with no templates, '
          + 'Document AI allows for high efficiency and accuracy at a fraction of the cost. ',
          'Available in over 180 countries and 60 languages, businesses can process data points like text, tables, checkboxes, '
          + 'watermarks, signatures, and faces with ease. If there is a document that needs to be scanned for validation, '
          + 'Base64.ai Document Understanding AI is the fastest and easiest way to get it done, on-premises or in the cloud.',
          'The scanned document and its data don\'t have to be stored for the most advanced data security. Document AI is SOC 2, HIPAA, and GDPR compliant.'
        ]
      }
    ]
  },
  'no-code-ai': {
    name: 'No-code',
    brandName: 'No-code AI',
    alternativeTitle: 'Automate Data Extraction with',
    headerImage: false,
    canLowerCase: true,
    description: 'Allow users to fill in forms on your website with a document upload that puts\n'
    + 'documents directly into your systems quickly!\n',
    text: [
      {
        title: 'Our No-Code Solutions',
        content: [
          'No-code allows users to integrate solutions without the need for engineering support. Businesses looking for a fast '
          + 'and inexpensive way to start processing their data can use Base64.ai No-Code AI, and automate their document processing '
          + 'without any coding experience or training required.',
          'Any user without programming experience can use Base64.ai to automate document processing with a few clicks. '
          + 'Without any programming knowledge, Base64.ai can be integrated into your existing environment in less than two hours. '
          + 'Once you embed our no-code solution into your existing environment, processing your documents with IDP will save you time, '
          + 'eliminate human validation errors, boost accuracy, and reduce expenses.'
        ]
      }
    ],
    demoLinks: [
      {
        title: 'Allow users to fill forms on your website with a document upload',
        url: '/no-code/injection',
        buttonName: 'Add AI to your website'
      },
      {
        title: 'Allow users to upload documents directly into your systems',
        url: '/no-code/form',
        buttonName: 'Create smart forms'
      }
    ],
    benefit: {
      title: 'The benefits of our no-code solutions',
      subtitle: ''
    },
    content: [
      { title: 'Company name', text: 'Gearblock Inc', alignment: 'right', top: 38 / 480, width: 465 / 720 },
      { title: 'Address', text: '123 Main St\nNew York, NY 10016', alignment: 'right', top: 77 / 480, width: 492 / 720 },
      { title: 'Invoice date', text: '2020-02-21', alignment: 'right', top: 160 / 480, width: 40 / 720 },
      { title: 'Total', text: '261.90', alignment: 'right', top: 410 / 480, width: 100 / 720 },
      { title: 'Balance', text: '161.90', alignment: 'right', top: 463 / 480, width: 100 / 720 }
    ],
    isAnimated: false
  },
  'digital-signatures': {
    name: 'Digital Signatures',
    brandName: 'Digital Signature AI',
    alternativeTitle: 'Eliminate manual verification with',
    headerImage: true,
    model: 'object/digital_signature',
    description:
    'Our digital signature AI can detect any digital signatures\n'
    + 'and validate their authenticity in seconds. Extract your signatures\n'
    + 'and ensure that your documents are secured.',
    text: [
      {
        title: 'What are Digital Signatures?',
        content: [
          'Digital signatures are a measure of security used to validate the authenticity and integrity of digital documents. '
          + 'Like a handwritten signature, digital signatures avoid tampering with your documents by using cryptography.',
          'Base64.ai offers Digital Signature AI to recognize if this security measure is present on your processed documents. '
          + 'In seconds, we extract all digital signatures and ensure documents using digital signatures are known.'
        ]
      }
    ]
  },
  'table-ai': {
    name: 'Tables',
    brandName: 'Table AI',
    model: 'object/table',
    headerImage: true,
    canLowerCase: true,
    subDocument: true,
    description: 'Process your document tables without the need for creating expensive models.\n Try it now, upload any form and Base64.ai will detect all tables on your document.',
    isAnimated: true,
    content: [
      { title: 'Table', text: '', alignment: 'right', top: 200 / 480, width: 60 / 720 }
    ],
    text: [
      {
        title: 'How we process Tables?',
        content: [
          'Table AI is used to identify any information structured as a table in your documents. Not all document types use '
          + 'the same table formats, meaning processing data in table form can be complex. With Base64.ai, all values in a table '
          + 'can be extracted as JSON in seconds.',
          'Using Table AI is an excellent way to extract table information and improve accuracy while processing difficult '
          + 'formats and hard-to-read data points.'
        ]
      }
    ]
  },
  'checkbox-ai': {
    name: 'Checkboxes',
    brandName: 'Checkbox AI',
    headerImage: true,
    canLowerCase: true,
    subDocument: true,
    description:
      'Our AI recognizes checkbox structures that appear on any document type\n'
      + 'whether the material has been issued in single or groups-of-multiple boxes.\n'
      + 'Try it now, upload any form and Base64.ai will process all checkboxes.',
    isAnimated: true,
    content: [
      { title: 'Checkbox', text: '', alignment: 'right', top: 285 / 360, width: 100 / 720 }
    ],
    text: [
      {
        title: 'How we process Checkboxes?',
        content: [
          'Checkboxes on documents are used to provide lists of options for users to select.  Each checkbox is independent of '
          + 'all other checkboxes, meaning when businesses validate their documents, checkboxes must be reviewed line by line. '
          + 'While manually processing checkboxes can be accurate, business processing hundreds or thousands of data points a day '
          + 'can be time-consuming, expensive, and prone to human error.',
          'Using any automated processing solution may result in expensive training or modeling. However, Base64.ai\'s Checkbox AI '
          + 'recognizes all checkboxes in a document without the need to build new models. With base64.ai\'s processing speed and accuracy, '
          + 'it helps businesses validate single or groups-of-multiple boxes, and it supports any checkbox designs without disrupting accuracy.'
        ]
      }
    ]
  },
  'entity-ai': {
    name: 'Entities',
    brandName: 'Entity Recognition AI',
    model: 'object/entity',
    headerImage: true,
    canLowerCase: true,
    subDocument: true,
    description:
      'Base64.ai\'s embedded Natural Language Processing (NLP) feature identifies important information '
      + 'from any document. Our AI extracts named entities (NER), such as people, organizations, locations, '
      + 'and dates so you can easily categorize key information.',
    isAnimated: true,
    content: [
      { title: 'Organization', text: 'Sony', alignment: 'right', top: 130 / 778, width: 2000 / 2140 },
      { title: 'Event', text: 'World War II', alignment: 'right', top: 121 / 778, width: 1227 / 2140 },
      { title: 'Person', text: 'Masaru Ibuka', alignment: 'right', top: 121 / 778, width: 673 / 2140 },
      { title: 'Location', text: 'Shirokiya', alignment: 'right', top: 210 / 778, width: 1461 / 2140 },
      { title: 'Location', text: 'Nihonbashi', alignment: 'right', top: 210 / 778, width: 250 / 2140 },
      { title: 'Date', text: '7 May 1946', alignment: 'right', top: 385 / 778, width: 1325 / 2140 },
      { title: 'Person', text: 'Akio Morita', alignment: 'right', top: 385 / 778, width: 515 / 2140 },
      { title: 'Organization', text: 'Tokyo Tsushin Kogyo', alignment: 'right', top: 471 / 778, width: 1383 / 2140 },
      { title: 'Organization', text: '東 京 通 信 工 業', alignment: 'right', top: 471 / 778, width: 990 / 2140 },
      { title: 'Organization', text: 'Tokyo Telecommunications Engineering Corporation', alignment: 'right', top: 550 / 778, width: 1600 / 2140 },
      { title: 'Product', text: 'Type-G', alignment: 'right', top: 642 / 778, width: 1260 / 2140 },
      { title: 'Date', text: '1958', alignment: 'right', top: 642 / 778, width: 840 / 2140 }
    ],
    text: [
      {
        title: 'How we recognize entities?',
        content: [
          'Entity recognition is the process of identifying and extracting specific pieces of information '
          + 'from unstructured data such as text or speech. It involves recognizing and classifying entities '
          + 'such as names, dates, locations, organizations, and other relevant information. \n',
          'Base64.ai\'s Entity Recognition AI uses machine learning algorithms to accurately identify '
          + 'and extract entities from various sources of data. We can recognize and classify entities '
          + 'in a variety of contexts and languages, making it easier for businesses to '
          + 'extract valuable insights from their data.',
          'Our Entity Recognition AI can be used to streamline various '
          + 'business processes such as document processing, customer service, and data analysis. '
        ]
      }
    ]
  },
  'barcode-ai': {
    name: 'Barcodes',
    brandName: 'Barcode AI',
    headerImage: true,
    canLowerCase: true,
    subDocument: true,
    description:
      'Learn more about Base64.ai and our powerful IDP capabilities for PDF417 barcodes.\n'
      + 'Whether you need to process products, IDs, or documents, our barcode AI solution\n'
      + 'will allow your business to reduce employee training time,\n'
      + 'eliminate human error, and track data rapidly',
    isAnimated: true,
    content: [
      { title: 'PFD417 Barcode', text: '', alignment: 'right', top: 56 / 360, width: 88 / 720 }
    ],
    text: [
      {
        title: 'How we process Barcodes?',
        content: [
          'As a method to track and store information for thousands to millions of items, barcodes are used to display a visual '
          + 'representation of large quantities of data. Using a machine-readable representation of numerals and characters, '
          + 'barcodes cut costs, reduce file sizes, and eliminate manual data entry.',
          'Base64.ai can process many barcodes including PDF417 barcodes in driver\'s licenses and QR codes as a fast and efficient '
          + 'way to extract all key-value pairs. Simply upload a photo of your barcodes, validate your data in seconds, and send your '
          + 'results to your database. Our barcode AI recognizes barcodes on IDs, driver\'s licenses, passports, visas, vehicle registrations, '
          + 'checks, and all other documents with barcodes.'
        ]
      }
    ]
  },
  'watermark-ai': {
    name: 'Watermarks',
    alternativeTitle: 'Check Watermark with',
    brandName: 'Watermark AI',
    model: 'object/watermark',
    headerImage: true,
    canLowerCase: true,
    subDocument: true,
    description:
      'Our AI recognizes embedded images or text that appears behind or in front of\n'
      + 'the main text of the document. These labels are often used to state the purpose of a document\n'
      + 'with words such as DRAFT or CONFIDENTIAL.\n\n'
      + 'To try it out, process your document and see how Base64.ai can detect\n'
      + 'watermarks for any document type.',
    isAnimated: true,
    content: [
      { title: 'Watermark', text: '', alignment: 'right', top: 108 / 360, width: 180 / 720 }
    ],
    text: [
      {
        title: 'How we process Watermarks?',
        content: [
          'A watermark may appear as a word like DRAFT and CONFIDENTIAL to display the status of a document, or as a monochromatic '
          + 'and transparent design. Watermarks make your sensitive information secure from theft or unauthorized use. ',
          'With our Watermark AI, your documents can be processed and checked for watermarks in seconds, automatically detecting '
          + 'labels and tracking your document\'s readiness and security. Meaning your processing workflow with Base64.ai will reduce expenses, '
          + 'eliminate manual processing, and improve your document tracking.'
        ]
      }
    ]
  },
  'nixie-label-ai': {
    name: 'Nixie labels',
    brandName: 'Nixie label AI',
    model: 'object/nixie',
    headerImage: true,
    canLowerCase: true,
    subDocument: true,
    description:
      'Our AI can read Nixie labels used by the United States Postal Service\n'
      + 'to a piece of mail that is undeliverable as addressed.\n'
      + 'Try it now, submit your documents to Base64.ai containing a Nixie label\n and we will process it.',
    text: [
      {
        title: 'Processing Nixie labels',
        content: [
          'The United States Postal Service refers to a piece of mail that is undeliverable as Nixie. Nixie labels are '
          + 'a valuable tool for companies to keep their address databases up-to-date and ensure money is not spent on mail '
          + 'that will never reach the intended recipient.',
          'Base64.ai offers a Nixie label reader that allows businesses to automatically extract Nixie information right '
          + 'from the label on the envelope and update their records for increased letter delivery rates.'
        ]
      }
    ],
    isAnimated: true,
    content: [
      { title: 'Nixie Label', text: '', alignment: 'right', top: 110 / 360, width: 60 / 720 }
    ]
  },
  'bank-card': {
    name: 'Bank Cards',
    model: 'finance/bank_card',
    headerImage: true,
    canLowerCase: true,
    description: 'Our IDP solution processes bank card information with bank-level security,\nso your sensitive data is safe on the cloud or on-premise. To try it out, upload your samples\n onto our demo page and process your data in seconds. It\'s safe and secure.',
    isAnimated: true,
    content: [
      { title: 'Bank Name', text: 'BANK NAME', alignment: 'right', top: 40 / 247, width: 30 / 387 },
      { title: 'Card Number', text: '0000 0000 0000 0000', alignment: 'right', top: 150 / 247, width: 30 / 387 },
      { title: 'Expiration Date', text: '2025-12-31', alignment: 'right', top: 180 / 247, width: 130 / 387 }
    ],
    text: [
      {
        title: 'Our Bank Card Solutions',
        content: [
          'Base64.ai’s Bank Card processing enables instant extract all data points from bank cards. Debit and credit card '
          + 'information such as card number, expiration date, bank name, etc. is processed instantly with high accuracy and security.',
          'Although processing data like bank information can be sensitive to manage, Base64.ai processes all documents with '
          + 'bank-level security. Base64.ai is SOC 2, HIPAA, and GDPR certified. Base64.ai is not PCI compliant.',
          'As an additional security measure, Base64.ai’s API does not store any data processed by your business '
          + 'to ensure no possible data breaches of your information'
        ]
      }
    ]
  },
  'us-green-card': {
    name: 'US Green Cards',
    model: 'id/usa/prc',
    headerImage: true,
    canLowerCase: true,
    description: 'Extract all data points from US green cards, front and back of your IDs.\n See how our AI can understand and process all information right in our demo.',
    isAnimated: true,
    content: [
      { title: 'Country Name', text: 'United States of America', alignment: 'right', top: 30 / 360, width: 340 / 720 },
      { title: 'Family name', text: 'Simen', alignment: 'right', top: 84 / 360, width: 350 / 720 },
      { title: 'Given name', text: 'Wendy', alignment: 'right', top: 126 / 360, width: 330 / 720 },
      { title: 'USCIS Number', text: '000-003-007', alignment: 'right', top: 170 / 360, width: 360 / 720 },
      { title: 'Category Code', text: 'RE8', alignment: 'right', top: 170 / 360, width: 180 / 720 },
      { title: 'Country of Birth', text: 'Indonesia', alignment: 'right', top: 210 / 360, width: 300 / 720 },
      { title: 'Date of Birth', text: '1986-01-13', alignment: 'right', top: 250 / 360, width: 324 / 720 },
      { title: 'Expiration Date', text: '2025-08-21', alignment: 'right', top: 295 / 360, width: 320 / 720 },
      { title: 'Issue Date', text: '2015-08-21', alignment: 'right', top: 315 / 360, width: 304 / 720 },
      { title: 'Face', image: '/static/content/features/data-extraction/samples/us-green-card_face.png', alignment: 'right', top: 210 / 360, width: 480 / 720 }
    ],
    text: [
      {
        title: 'How we process US Green Cards?',
        content: [
          'Verifying citizenship or temporary citizenship has never been easier. Using automated AI to scan documents makes '
          + 'it a fast and simple way to verify vital information without any lag or downtime. This feature is especially useful '
          + 'for workforces bound by following laws to verify that workers have the proper identification to permanently live and work '
          + 'in the United States.',
          'Card scanning makes it a streamlined process to scan all the information on the Green Card to verify the authenticity '
          + 'and validity of the document. With Base64.ai, all data points on a US Green Card will be extracted and validated in seconds. '
          + 'Without any template-based models needed, businesses can start processing US Green Cards right from the website.'
        ]
      }
    ]
  },
  'us-social-security-card': {
    name: 'US Social Security Cards',
    model: 'id/usa/ssn',
    headerImage: true,
    canLowerCase: true,
    description:
      'Base64.ai can instantly capture sensitive data on Social Security Cards \n with high-level security.\n'
      + 'Process data points like Social Security Number (SSN) knowing our API extracts\n'
      + 'all data points safely without storing any information to avoid data breaches.',
    isAnimated: true,
    content: [
      { title: 'Social Security Number', text: '123-45-6789', alignment: 'right', top: 100 / 237, width: 130 / 387 },
      { title: 'Given Name', text: 'Your Name', alignment: 'right', top: 136 / 237, width: 106 / 387 },
      { title: 'Family Name', text: 'Here', alignment: 'right', top: 136 / 237, width: 106 / 387 }
    ],
    text: [
      {
        title: 'How we process Social Security Cards?',
        content: [
          'Scanning and verifying sensitive information like social security cards is a process that often takes several '
          + 'minutes to carefully validate. Using AI for data extraction and signature recognition can help speed up your '
          + 'processing cycle and boost accuracy.'
        ]
      }
    ]
  },
  'remittance': {
    name: 'Remittance',
    model: 'finance/remittance',
    // headerImage: true,
    canLowerCase: true,
    description: 'Try out our intelligent document processing for Remittance documents.\nBase64.ai can accurately process all key-value pairs in seconds. No templates needed.',
    // isAnimated: true,
    text: [
      {
        title: 'Automated document processing for Remittance letters',
        content: [
          'Remittance letters are financial documents that show which invoices were paid. They are typically attached to '
          + 'checks but can be sent separately as well. For companies that process remittance documents, extracting and '
          + 'validating semi-structured documents can be time-consuming and prone to human error.',
          'Base64.ai processes all data points from Remittance letters in seconds. Our AI-powered API allows businesses '
          + 'to eliminate the need to manually process any remittance structure at a fraction of the cost. Base64.ai can also '
          + 'verify the invoice numbers and amounts against your invoice data.'
        ]
      }
    ]
  },
  'cloud-deployment': {
    name: 'Cloud Deployment',
    alternativeTitle: 'Experience our',
    headerImage: false,
    description:
      'Our solution can be deployed on Google Cloud, Microsoft Azure,\n'
      + 'and Amazon AWS, wherever those solutions have local data center.\n'
      + 'We ensure a convenient, reliable, scalable solution to process your data securely\n'
      + 'with our ISO, SOC 2, HIPAA, and GDPR compliance.',
    benefit: {
      title: 'The benefits of our Cloud Deployment',
      subtitle: ''
    },
    text: [
      {
        title: 'Our Cloud Deployment Solutions',
        content: [
          'Cloud deployment is changing the way businesses are managing their workflows. Cloud allows users to quickly access '
          + 'stored information anywhere at any time, and increase productivity and efficiency by ensuring your data is accessible and secure. '
          + 'Cloud deployment also reduces costs in hardware, software, and operations, boosting the capacity of your storage.',
          'To ensure convenience in reliability, autoscaling, and locality, Base64.ai offers Cloud Deployment with highly secure compliance. '
          + 'Our company and solution are ISO, SOC 2, HIPAA, and GDPR certified.',
          'Since our solutions are deployable on the cloud, this means we can meet any processing volume needed. Our solution '
          + 'can also be deployed on Google Cloud, Microsoft Azure, and Amazon AWS both in the US and internationally; '
          + 'wherever those solutions have local data centers.'
        ]
      }
    ]
  },
  'segmentation-ai': {
    name: 'Segmentation AI',
    headerImage: true,
    alternativeTitle: 'Split documents with',
    canLowerCase: false,
    isAnimated: true,
    demo: '/flow',
    description:
        'Base64.ai offers the only IDP solution that processes two or more documents on the same image (or page), such as two receipts or IDs on a single photo. '
        + 'Our patent-pending Segmentation AI understands documents\' physical boundaries, crops each document, and process each of them separately for most accurate results.',
    content: [
      { title: 'Document type', text: 'New York driver license', alignment: 'right', top: 99 / 480, width: 600 / 1060 },
      { title: 'Given name', text: 'MICHAEL, MATTHEW', alignment: 'right', top: 165 / 480, width: 745 / 1060 },
      { title: 'Document type', text: 'Ukraine passport', alignment: 'right', top: 165 / 480, width: 42 / 1060 },
      { title: 'Given name', text: 'MARIANA', alignment: 'right', top: 192 / 480, width: 244 / 1060 }
    ],
    benefit: {
      title: 'The benefits of Segmentation AI'
    }
  },
  'image-quality-ai': {
    name: 'Image Quality AI',
    alternativeTitle: 'Improve Processing Accuracy with',
    canLowerCase: false,
    description:
      'Instantly detect common image quality issues such as blur and glare (flash) and ask your users to upload a new document while they are still on your website or app. '
      + 'Image AI reduces funnel drops, preventing reading errors, and helps avoiding false positives.',
    benefit: {
      title: 'The benefits of automated Image Quality AI'
    },
    // isAnimated: true,
    content: [
      { title: 'Image with glare', text: '', alignment: 'right', top: 90 / 270, width: 230 / 387 }
    ],
    text: [
      {
        title: 'How our Image Quality AI works?',
        content: [
          'Often users may upload images that don\'t have the best quality, which may be below the acceptable level. Using flash in a photo '
          + 'creates a glare that may cover the text on a document. Shaking hands, low lighting, or shutter speeds may prevent focused images.',
          'Base64.ai Image Quality AI detects blur and glare instantly. Our API results list any imperfection it notices and displays this data '
          + 'to inform the user that a new photo must be taken, or that the reason for the inaccuracy is correlated to the quality of the image. '
          + 'Therefore, using Image Quality AI can help businesses eliminate back-and-forth in onboarding due to low-quality images, and boost the speed, '
          + 'and accuracy of their workflow.'
        ]
      }
    ]
  },
  'redaction-ai': {
    name: 'Redaction AI',
    alternativeTitle: 'Hide confidential information with',
    canLowerCase: false,
    description: 'Detect and remove personal and sensitive information from documents and images',
    benefit: {
      title: 'The benefits of automated Redaction AI'
    },
    youtube: '3beufvTnW3E',
    // isAnimated: true,
    demoLinks: [
      {
        title: 'Anonymize personal and sensitive information from all kind of documents such as IDs, forms, and contracts etc.',
        url: '/demo/redaction',
        buttonName: 'Redact documents'
      }
    ],
    text: [
      {
        title: 'Why document data redaction is important?',
        content: [
          'Document data redaction is crucial for several reasons. First and foremost, it helps protect sensitive and confidential information from falling into the wrong hands. In today\'s digital age, where data breaches and unauthorized access to information are prevalent, redacting sensitive data ensures that personal, financial, or classified information remains hidden and inaccessible. This is particularly important in industries such as healthcare, legal, finance, and government, where privacy and data protection are paramount.',
          'Secondly, document data redaction helps organizations comply with legal and regulatory requirements. Many jurisdictions have stringent privacy laws, such as the General Data Protection Regulation (GDPR) in the European Union or the Health Insurance Portability and Accountability Act (HIPAA) in the United States. Redacting sensitive information ensures compliance with these regulations, avoiding penalties, legal liabilities, and reputational damage.',
          'Furthermore, redaction is vital for preserving confidentiality during litigation processes. In legal proceedings, sensitive details that are irrelevant to the case need to be concealed to protect individuals\' privacy or maintain business secrets. By redacting specific information, such as names, addresses, or financial details, organizations can ensure that only relevant and admissible evidence is presented, while keeping sensitive data confidential.',
          'In summary, document data redaction is important to safeguard sensitive information, comply with legal and regulatory obligations, and maintain confidentiality during legal proceedings. It is an essential practice for protecting privacy, preventing data breaches, and mitigating the risks associated with unauthorized access to confidential information.'
        ]
      }
    ]
  },
  'real-id-enhanced-id': {
    name: 'Real ID and Enhanced ID',
    headerImage: true,
    canLowerCase: false,
    description:
      'Process Real and Enhanced IDs to extract all valuable information such as whether\n'
      + 'your users comply with the necessary identification to cross a US border\n'
      + 'coming from Canada, Mexico, and some Caribbean countries, by land or sea.\n'
      + 'Try it now, upload any ID and Base64.ai will identify these criteria and more.',
    isAnimated: true,
    content: [
      { title: 'State code', text: 'NY', alignment: 'right', top: 25 / 260, width: 96 / 410 },
      { title: 'Country name', text: 'United States of America', alignment: 'right', top: 25 / 260, width: 42 / 410 },
      { title: 'Driver Licence Type', text: 'Learner Permit', alignment: 'right', top: 44 / 260, width: 125 / 410 },
      { title: 'Is enhanced ID', text: 'Yes', alignment: 'right', top: 57 / 260, width: 148 / 410 },
      { title: 'Family name', text: 'MOTORIST', alignment: 'right', top: 75 / 260, width: 196 / 410 },
      { title: 'Given name', text: 'MICHAEL, MATTHEW', alignment: 'right', top: 87 / 260, width: 140 / 410 },
      { title: 'Address', text: '2345 ANYWHERE STREET\n YOUR CITY, NY 12345', alignment: 'right', top: 113 / 260, width: 132 / 410 },
      { title: 'Sex', text: 'Male', alignment: 'right', top: 158 / 260, width: 236 / 410 },
      { title: 'Date of birth', text: '1982-08-31', alignment: 'right', top: 176 / 260, width: 172 / 410 },
      { title: 'Expiration date', text: '2022-08-31', alignment: 'right', top: 192 / 260, width: 160 / 410 },
      { title: 'Issue date', text: '2017-08-31', alignment: 'right', top: 240 / 260, width: 166 / 410 },
      { title: 'Face', image: '/static/content/features/data-extraction/samples/real-id-enhanced-id_face.png', alignment: 'right', top: 140 / 260, width: 268 / 410 },
      { title: 'Signature', image: '/static/content/features/data-extraction/samples/real-id-enhanced-id_signature.png', alignment: 'right', top: 216 / 260, width: 268 / 410 }
    ],
    text: [
      {
        title: 'What are Real and Enhanced IDs?',
        content: [
          'According to the United States Real ID Act of 2005, all driver\'s licenses and ID cards are required '
          + 'to be accepted by the federal government for “official purpose”. This means, depending on the status of your ID type, '
          + 'boarding commercially operated airline flights or entering federal buildings requires a Real ID by 2023. ',
          'Enhanced licenses or passports have the same authority as Real IDs plus they can be used to cross the U.S. from Canada, '
          + 'Mexico, and some countries in the Caribbean. Base64.ai is the only AI solution that automatically detects '
          + 'Real ID and Enhanced ID on IDs and driver\'s licenses.'
        ]
      }
    ]
  }
}
