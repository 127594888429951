import MaterialIcon from '../material-icon/MaterialIcon'
import PropTypes from 'prop-types'

const RemoveButton = ({ onRemove, title }) => {
  const handleRemove = async () => {
    onRemove?.()
  }

  return (
    <MaterialIcon
      title={title || 'Remove'}
      name='disabled_by_default'
      onClick={handleRemove}
      className='hover-red'
    />
  )
}

RemoveButton.propTypes = {
  onRemove: PropTypes.func,
  title: PropTypes.string
}

export default RemoveButton
