export const getBase64 = (file, callback) => {
  if (file.image) {
    callback(null, file.image)
    return
  }
  // Read file with reader
  const reader = new FileReader()
  reader.readAsDataURL(file.source)
  // Abort
  reader.onabort = () => callback(new Error('The operation is aborted.'))
  // Error
  reader.onerror = error => callback(error)
  // Success
  reader.onload = () => {
    const data = reader.result.split(',')[1]
    callback(null, `data:${file.source.type};base64,${data}`)
  }
}
