// Modules
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
// Context
import Context from '~/context/global'
import constants from '~/data/constants'
import solutionTypes from '~/data/solutionTypes'
import dataExtractionTypes from '~/data/dataExtractionTypes'
import { googleOneTabAuthentication } from '~/helpers/googleOneTabAuthentication'
// Helpers
import { request } from '~/helpers/request'
import Loading from '~/interface/loading/Loading'
import window from 'global/window'
import document from 'global/document'
// Interface
import Modal from '~/interface/modal/Modal'
import Footer from '~/layout/footer/Footer'
// Layout
import Navigation from '~/layout/navigation/Navigation'
import Overlay from '~/layout/overlay/Overlay'
// Styles
import '~/styles/index.less'
import AcceptableUsePolicy from '~/views/acceptable-use-policy/AcceptableUsePolicy'
import Acord25Validation from '~/views/acord25-validation-demo/Acord25Validation'
import HealthAndSafetyDocumentValidation from '~/views/health-and-safety-document-validation-demo/HealthAndSafetyDocumentValidation'
import Admin from '~/views/admin/Admin'
import Demo from '~/views/demo/Demo'
import DocumentProcessingDemo from '~/views/document-processing-demo/DocumentProcessingDemo'
import Errors from '~/views/errors/Errors'
import FaceManagerDemo from '~/views/face-manager-demo/FaceManagerDemo'
import FaceTrackingDemo from '~/views/face-tracking-demo/FaceTrackingDemo'
import Features from '~/views/features/Features'
// Views
import Home from '~/views/home/Home'
import Login from '~/views/login/Login'
import NoCodeDemo from '~/views/no-code-demo/NoCodeDemo'
import PasswordReset from '~/views/password-reset/PasswordReset'
import PrivacyPolicy from '~/views/privacy-policy/PrivacyPolicy'
import ScanWidgetDemo from '~/views/scan-widget-demo/ScanWidgetDemo'
import ScanWidget from '~/views/scan-widget/ScanWidget'
import Signup from '~/views/signup/Signup'
import Solutions from '~/views/solutions/Solutions'
import TermsOfService from '~/views/terms-of-service/TermsOfService'
import Usage from '~/views/usage/Usage'
import Blog from '~/views/blog/Blog'
import CovidVaccinationValidation from '~/views/covid-vaccination-demo/CovidVaccinationValidation'
import Partners from '~/views/partners/Partners'
import Contact from '~/views/contact/Contact'
import SubscriptionPricing from '~/views/subscription/Subscription'
import OAuthConsent from '~/views/oauth-consent/OAuthConsent'
import { isWorkingOffline, isSSR } from '~/context/environment'
import BlogDetail from '~/views/blog-detail/BlogDetail'
import MediaKit from '~/views/media-kit/MediaKit'
import CreateBlogPost from '~/views/create-blog-post/CreateBlogPost'
import Integrations from '~/views/integrations/Integrations'
import UserSettings from '~/views/user-settings/UserSettings'
import { showCompleteCustomerVerificationDialog } from './helpers/workEmail'
import IntegrationWizard from './views/integration-wizard/IntegrationWizard'
import Compliance from './views/compliance/Compliance'
import SignatureVerificationDemo from './views/signature-verification-demo/SignatureVerificationDemo'
import ROICalculator from './views/roi-calculator/ROICalculator'
import AboutUs from './views/about-us/AboutUs'
import RedactionDemo from './views/redaction-demo/RedactionDemo'
import FlowList from './views/flow/FlowList'
import ReferralProgram from './views/referral-program/ReferralProgram'
import FacialRecognitionDemo from './views/facial-recognition-demo/FacialRecognitionDemo'
import FacialVerificationDemo from './views/facial-verification-demo/FacialVerificationDemo'
import SignatureRecognitionDemo from './views/signature-recognition-demo/SignatureRecognitionDemo'
import ModelTypes from './views/model-types/ModelTypes'
import { isValidPhoneNumber } from 'react-phone-number-input'
import ErrorCodes from './views/error-codes/ErrorCodes'
import { hasDatabase, hasLogin, isBlueWashed } from './context/environment'
import ListCustomModel from './views/custom-model/ListCustomModel'
import Section from './layout/section/Section'
import Patents from './views/patents/Patents'
import { FlowProvider } from './providers/FlowContext'
import FlowSettings from './views/flow/FlowSettings'
import HITLWrapper from './views/hitl/HITLWrapper'
import ResultList from './views/flow/ResultList'
import CustomModelSettings from './views/custom-model/CustomModelSettings'
import { LocationProvider } from './providers/LocationContext'
import { AppButton } from './interface/app-button/AppButton'

// Application
class App extends Component {
  static context = Context
  static propTypes = {
    history: PropTypes.object,
    ssrData: PropTypes.array
  }

  constructor (props) {
    super(props)
    this.state = {
      user: null,
      models: {},
      flows: [],
      searchParams: {},
      modal: null,
      fetchingUser: false
    }
    this.configuration = {
      drift: {
        size: 76,
        margin: 24
      }
    }
    this.footer = createRef()
    this.redirect = this.redirect.bind(this)
    this.handleError = this.handleError.bind(this)
    this.displayError = this.displayError.bind(this)
    this.displayModal = this.displayModal.bind(this)
    this.setUser = this.setUser.bind(this)
    this.setSearchParams = this.setSearchParams.bind(this)
    this.getModels = this.getModels.bind(this)
    this.logout = this.logout.bind(this)
    this.handleDriftPosition = this.handleDriftPosition.bind(this)
    this.showCompleteCustomerVerificationDialog = showCompleteCustomerVerificationDialog.bind(this)
    this.completeCustomerVerificationDialogUserProvider = this.completeCustomerVerificationDialogUserProvider.bind(this)
  }

  componentDidMount () {
    window.addEventListener('error', this.handleError)
    window.addEventListener('scroll', this.handleDriftPosition)
    this.setState({
      user: {}
    })
    this.setUser()
  }

  componentWillUnmount () {
    window.removeEventListener('error', this.handleError)
    window.removeEventListener('scroll', this.handleDriftPosition)
  }

  redirect (path = '/') {
    if (path === '/') {
      setTimeout(() => {
        window.location.href = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
      }, 0)
      return
    }
    this.props.history.push(path)
  }

  handleError (message, source, line, column, error) {
    confirm('The page needs to be refreshed due to an error. Sorry about that.')
      ? window.location.reload()
      : this.displayError(JSON.stringify({
        message,
        source,
        line,
        column,
        error
      }))
  }

  displayError (error) {
    document.body.innerHTML = error
  }

  displayModal (modal = null) {
    this.setState({ modal })
  }

  completeCustomerVerificationDialogUserProvider (userData) {
    if (userData !== 'update') {
      this.context.user = { ...this.context.user, ...userData }
      return
    }
    if (!this.context.user.workEmail) {
      return alert('Please provide a work email')
    }
    if (!this.context.user.phoneNumber) {
      return alert('Please provide a phone number')
    }
    if (!isValidPhoneNumber(this.context.user.phoneNumber)) {
      this.setState({
        user: { ...this.state.user, phoneNumber: '' }
      })
      return alert('Please provide a valid phone number')
    }
    request({
      endpoint: '/auth/validate-customer-verification',
      body: {
        workEmail: (this.context.user.workEmail || '').trim(),
        phoneNumber: (this.context.user.phoneNumber || '').trim(),
        useCase: (this.context.user.useCase || '').trim()
      }
    }, (error, response) => {
      if (error) {
        this.displayModal({
          title: 'An error occurred',
          content: error.toString(),
          options: [
            {
              content: 'OK'
            }
          ]
        })
      } else {
        this.displayModal({
          title: response?.isWorkEmailVerificationEmailSent ? 'Verification email sent' : 'Verification completed',
          isWide: true,
          content: response?.isWorkEmailVerificationEmailSent
            ? (
                <div>
                  <p>We have sent you a verification email.</p>
                  <p>Please click the activation link in</p>
                  <p>the email to verify your work email.</p>
                </div>
              )
            : (
                <div>
                  <p>Your accounts verification is completed.</p>
                </div>
              ),
          options: [
            {
              content: 'OK',
              action: () => {
                window.location.reload()
              }
            }
          ]
        })
      }
    })
  }

  setUser (callback) {
    if (!hasLogin()) {
      return this.setState({
        user: {}
      })
    }
    this.setState({
      fetchingUser: true
    })
    request({
      endpoint: '/auth/user',
      method: 'GET'
    }, (error, user) => {
      if (error) {
        this.setState({
          user: {},
          fetchingUser: false
        })
        googleOneTabAuthentication()
      } else {
        this.setState({
          user: user,
          fetchingUser: false
        })
        this.context.user = user
        this.showCompleteCustomerVerificationDialog(this.displayModal, this.completeCustomerVerificationDialogUserProvider, user)
        callback && callback(user)
      }
    }, err => {
      console.error('Unable to fetch the user session. Possible cause: The API server is not running. Error details:', err)
    })
  }

  setSearchParams (searchParams) {
    this.setState({ searchParams })
  }

  getModels (callback) {
    request({
      method: 'GET',
      endpoint: '/models'
    }, (error, response) => {
      callback && callback(error, response)
    })
  }

  logout (callback) {
    const logoutWrapper = all => {
      const allArg = all ? '?endAllSessions=true' : ''
      request({
        endpoint: '/auth/logout' + allArg,
        method: 'GET'
      }, (error, response) => {
        if (!error) {
          if (!isWorkingOffline()) {
            if (window?.google) {
              window.google.accounts.id.disableAutoSelect()
            } else {
              console.log('Login with Google plugin was not loaded')
            }
          }
          this.setState({
            user: {}
          })
          callback && callback()
          window.location.reload()
          return
        }
        this.displayModal({
          title: 'Error',
          isWide: false,
          isClosable: false,
          content: (
            <div>
              <p>Unable to log out. Please try again later.</p>
              <p>Error details: {error.message}</p>
            </div>
          ),
          options: [
            {
              content: 'Close',
              action: () => {
                window.location.reload()
              },
              intent: 'negative'
            }
          ]
        })
      })
    }
    this.displayModal({
      title: 'Logout',
      isWide: false,
      content: (
        <div>
          <p>Which sessions do you want to log out from.</p>
        </div>
      ),
      options: [
        {
          content: 'Current',
          action: () => {
            logoutWrapper(false)
          }
        },
        {
          content: 'All',
          action: () => {
            logoutWrapper(true)
          },
          intent: 'negative'
        }
      ]
    })
  }

  handleDriftPosition () {
    const drift = document.getElementById('drift-widget')
    const footer = this.footer.current
    if (drift && footer) {
      const {
        size,
        margin
      } = this.configuration.drift
      const distance = window.innerHeight - footer.getBoundingClientRect().top
      const effectiveDistance = Math.max(distance - size / 2, margin)
      const safeSpace = 50
      if (effectiveDistance > margin + size / 2 - safeSpace) {
        drift.style.bottom = (effectiveDistance) + 'px'
      }
    }
  }

  render () {
    const {
      user,
      models,
      flows,
      searchParams,
      modal,
      fetchingUser
    } = this.state
    return (user !== null || isSSR())
      ? (
          <LocationProvider>
            <FlowProvider>
              <Context.Provider value={{
                user,
                flows,
                searchParams,
                redirect: this.redirect,
                setUser: this.setUser,
                setSearchParams: this.setSearchParams,
                displayModal: this.displayModal,
                logout: this.logout
              }}
              >
                {modal
                && (
                  <Overlay
                    alwaysOn={modal.isForbiddenToClickOutside}
                    isHITL={modal.isHITL}
                  >
                    <Modal
                      title={modal.title}
                      content={modal.content}
                      isWide={modal.isWide}
                      style={modal.style}
                      isHITL={modal.isHITL}
                      showNote={modal.showNote}
                      ref={modal.ref}
                      options={modal.options}
                      close={this.displayModal}
                      isClosable={modal.isClosable}
                    />
                  </Overlay>
                )}
                {/* Navigation */}
                <Navigation
                  user={user}
                  completeCustomerVerificationDialogUserProvider={this.completeCustomerVerificationDialogUserProvider}
                  logout={this.logout}
                />
                {/* Routes */}
                <Switch>
                  {/* Home */}
                  <Route
                    path='/'
                    exact={true}
                    render={() => (
                      <>
                        <Helmet>
                          <title>{constants.rawTitle}</title>
                        </Helmet>
                        <Home />
                      </>
                    )}
                  />
                  {/* Contact */}
                  {!isWorkingOffline() && (
                    <Route
                      path='/contact*'
                      render={() => (
                        <>
                          <Helmet>
                            <title>Contact{constants.titleSuffix}</title>
                          </Helmet>
                          <Contact />
                        </>
                      )}
                    />
                  )}
                  {/* Signup */}
                  {hasDatabase() && (
                    <Route
                      path={['/signup/:verificationCode', '/signup']}
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Signup{constants.titleSuffix}</title>
                          </Helmet>
                          <Signup verificationCode={match.params.verificationCode} user={user} />
                        </>
                      )}
                    />
                  )}
                  {/* Login */}
                  {hasLogin() && (
                    <Route
                      path='/login*'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Login{constants.titleSuffix}</title>
                          </Helmet>
                          <Login match={match} user={user} />
                        </>
                      )}
                    />
                  )}
                  {/* OAuth Consent */}
                  {hasDatabase() && (
                    <Route
                      path='/oauth-consent*'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>OAuth Consent{constants.titleSuffix}</title>
                          </Helmet>
                          <OAuthConsent match={match} />
                        </>
                      )}
                    />
                  )}
                  {/* Password Reset */}
                  {hasDatabase() && (
                    <Route
                      path='/password-reset/:email?/:verification?'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Password Reset{constants.titleSuffix}</title>
                          </Helmet>
                          <PasswordReset
                            email={match.params.email}
                            verification={match.params.verification}
                          />
                        </>
                      )}
                    />
                  )}
                  {/* Features */}
                  {hasDatabase() && (
                    <Route
                      path='/features/:item?/:type?'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>{(dataExtractionTypes[match.params.type] && dataExtractionTypes[match.params.type].name ? dataExtractionTypes[match.params.type].name + ' feature' : 'Features')}{constants.titleSuffix}</title>
                          </Helmet>
                          <Features
                            item={match.params.item}
                            type={match.params.type}
                          />
                        </>
                      )}
                    />
                  )}
                  {/* Solutions */}
                  {hasDatabase() && (
                    <Route
                      path='/solutions/:type?'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>{(solutionTypes[match.params.type]?.name + ' solutions') || 'Solutions'}{constants.titleSuffix}</title>
                          </Helmet>
                          <Solutions type={match.params.type} />
                        </>
                      )}
                    />
                  )}
                  {/* SubscriptionPricing */}
                  {!isWorkingOffline() && (
                    <Route
                      path='/pricing'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Pricing{constants.titleSuffix}</title>
                          </Helmet>
                          <SubscriptionPricing user={user} fetching={fetchingUser} />
                        </>
                      )}
                    />
                  )}
                  {/* ROI Calculator */}
                  {!isWorkingOffline() && (
                    <Route
                      path='/roi-calculator'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>ROI (Return of Investment) Calculator{constants.titleSuffix}</title>
                          </Helmet>
                          <ROICalculator />
                        </>
                      )}
                    />
                  )}
                  {/* Media Kit */}
                  <Route
                    path='/media-kit'
                    render={({ match }) => (
                      <>
                        <Helmet>
                          <title>Media Kit{constants.titleSuffix}</title>
                        </Helmet>
                        <MediaKit />
                      </>
                    )}
                  />
                  {/* Partners */}
                  {!isWorkingOffline() && (
                    <Route
                      path='/partner(s)?/:item?'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Partners{constants.titleSuffix}</title>
                          </Helmet>
                          <Partners
                            item={match.params.item}
                          />
                        </>
                      )}
                    />
                  )}
                  {/* Referral Program */}
                  {!isWorkingOffline() && (
                    <Route
                      path={['/referral-program', '/refer*']}
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Referral Program{constants.titleSuffix}</title>
                          </Helmet>
                          <ReferralProgram />
                        </>
                      )}
                    />
                  )}
                  {hasDatabase() && (
                    <Route
                      path={['/demo/document-processing/*', '/demo/document-processing']}
                      render={({ match }) => user || hasDatabase()
                        ? <Redirect to='/flow' />
                        : (
                            <>
                              <Helmet>
                                <title>Document Understanding AI{constants.titleSuffix}</title>
                              </Helmet>
                              <DocumentProcessingDemo
                                user={user}
                                getModels={this.getModels}
                                models={
                                  match.url
                                    .replace(/^\/demo\/document-processing\/?/, '')
                                    .length > 0
                                    ? match.url
                                      .replace(/^\/demo\/document-processing\/?/, '')
                                      .split('+')
                                      .filter(model => !!model)
                                    : []
                                }
                              />
                            </>
                          )}
                    />
                  )}
                  <Route
                    path={['/demo/facial-recognition/*', '/demo/facial-recognition']}
                    render={() => (
                      <>
                        <Helmet>
                          <title>Facial Recognition AI{constants.titleSuffix}</title>
                        </Helmet>
                        <FacialRecognitionDemo />
                      </>
                    )}
                  />
                  <Route
                    path={['/demo/facial-verification/*', '/demo/facial-verification']}
                    render={() => (
                      <>
                        <Helmet>
                          <title>Facial Verification AI{constants.titleSuffix}</title>
                        </Helmet>
                        <FacialVerificationDemo />
                      </>
                    )}
                  />
                  {hasDatabase() && (
                    <Route
                      path={['/demo/facial-tracking/*', '/demo/facial-tracking']}
                      render={() => (
                        <>
                          <Helmet>
                            <title>Camera Tracking AI{constants.titleSuffix}</title>
                          </Helmet>
                          <FaceTrackingDemo />
                        </>
                      )}
                    />
                  )}
                  {hasDatabase() && (
                    <Route
                      path='/demo/scan-widget/:scannerType?'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Embedded No-code Widgets {constants.titleSuffix}</title>
                          </Helmet>
                          <ScanWidgetDemo
                            scannerType={match.params.scannerType}
                          />
                        </>
                      )}
                    />
                  )}
                  {hasDatabase() && (
                    <Route
                      path={['/demo/acord25-validation/*', '/demo/acord25-validation']}
                      render={() => (
                        <>
                          <Helmet>
                            <title>ACORD 25 Validation AI{constants.titleSuffix}</title>
                          </Helmet>
                          <Acord25Validation user={user} />
                        </>
                      )}
                    />
                  )}
                  {hasDatabase() && (
                    <Route
                      path={['/demo/health-and-safety-document-validation/*', '/demo/health-and-safety-document-validation']}
                      render={() => (
                        <>
                          <Helmet>
                            <title>Health and safety document validation{constants.titleSuffix}</title>
                          </Helmet>
                          <HealthAndSafetyDocumentValidation user={user} />
                        </>
                      )}
                    />
                  )}
                  {hasDatabase() && (
                    <Route
                      path={['/demo/covid-(19-)?vaccination-validation/*', '/demo/covid-(19-)?vaccination-validation']}
                      render={() => (
                        <>
                          <Helmet>
                            <title>Covid-19 Vaccination Card Validation AI{constants.titleSuffix}</title>
                          </Helmet>
                          <CovidVaccinationValidation user={user} />
                        </>
                      )}
                    />
                  )}
                  <Route
                    path={['/demo/signature-recognition/*', '/demo/signature-recognition']}
                    render={() => (
                      <>
                        <Helmet>
                          <title>Signature Recognition AI{constants.titleSuffix}</title>
                        </Helmet>
                        <SignatureRecognitionDemo />
                      </>
                    )}
                  />
                  <Route
                    path={['/demo/signature-verification/*', '/demo/signature-verification']}
                    render={() => (
                      <>
                        <Helmet>
                          <title>Signature Verification AI{constants.titleSuffix}</title>
                        </Helmet>
                        <SignatureVerificationDemo />
                      </>
                    )}
                  />
                  {hasDatabase() && (
                    <Route
                      path={['/demo/redaction/*', '/demo/redaction']}
                      render={() => (
                        <>
                          <Helmet>
                            <title>Redaction AI{constants.titleSuffix}</title>
                          </Helmet>
                          <RedactionDemo />
                        </>
                      )}
                    />
                  )}
                  {hasDatabase() && (
                    <Route
                      path='/widgets/scan/:companyId/:scannerType?'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Scan Widget {constants.titleSuffix}</title>
                          </Helmet>
                          <ScanWidget
                            user={user}
                            companyId={match.params.companyId}
                            scannerType={match.params.scannerType}
                          />
                        </>
                      )}
                    />
                  )}
                  {hasDatabase() && (
                    <Route
                      path='/widgets/document-processing/:formUuid?'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>No-code Document Understanding AI{constants.titleSuffix}</title>
                          </Helmet>
                          <DocumentProcessingDemo
                            getModels={this.getModels}
                            isEmbedded={true}
                            user={user}
                            formUuid={match.params.formUuid}
                          />
                        </>
                      )}
                    />
                  )}
                  {hasDatabase() && (
                    <Route
                      path={['/demo/facial-manager/*', '/demo/facial-manager']}
                      render={() => (
                        <>
                          <Helmet>
                            <title>Store Face Data{constants.titleSuffix}</title>
                          </Helmet>
                          <FaceManagerDemo user={user} fetching={fetchingUser} />
                        </>
                      )}
                    />
                  )}
                  {/* Demo home */}
                  <Route
                    path={['/demo/*', '/demo']}
                    render={() => (
                      <>
                        <Helmet>
                          <title>Demo{constants.titleSuffix}</title>
                        </Helmet>
                        <Demo user={user} />
                      </>
                    )}
                  />
                  {/* Status */}
                  {/* <Route */}
                  {/*  path="/api/status" */}
                  {/*  render={() => */}
                  {/*    <> */}
                  {/*      <Helmet> */}
                  {/*        <title>Status{constants.titleSuffix}</title> */}
                  {/*      </Helmet> */}
                  {/*      <Status/> */}
                  {/*    </> */}
                  {/*  }/> */}
                  {/* Terms of Service */}
                  <Route
                    path='/terms-of-service'
                    render={() => (
                      <>
                        <Helmet>
                          <title>Terms of Service{constants.titleSuffix}</title>
                        </Helmet>
                        <TermsOfService />
                      </>
                    )}
                  />
                  <Route
                    path='/about-us'
                    render={() => (
                      <>
                        <Helmet>
                          <title>About Us{constants.titleSuffix}</title>
                        </Helmet>
                        <AboutUs />
                      </>
                    )}
                  />
                  {/* Privacy Policy */}
                  <Route
                    path='/privacy-policy'
                    render={() => (
                      <>
                        <Helmet>
                          <title>Privacy Policy{constants.titleSuffix}</title>
                        </Helmet>
                        <PrivacyPolicy />
                      </>
                    )}
                  />
                  {/* Acceptable Use Policy */}
                  <Route
                    path='/acceptable-use-policy'
                    render={() => (
                      <>
                        <Helmet>
                          <title>Acceptable Use Policy{constants.titleSuffix}</title>
                        </Helmet>
                        <AcceptableUsePolicy />
                      </>
                    )}
                  />
                  {/* Case Studies */}
                  {!isWorkingOffline() && (
                    <Route
                      path={['/case-studies/:postKey', '/case-studies/:postKey']}
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>{(document.title || 'Case studies').replace(constants.titleSuffix, '')}{constants.titleSuffix}</title>
                          </Helmet>
                          <BlogDetail postKey={match.params.postKey} ssrData={this.props.ssrData} />
                        </>
                      )}
                    />
                  )}
                  {!isWorkingOffline() && (
                    <Route
                      path={['/case-studies']}
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Case studies{constants.titleSuffix}</title>
                          </Helmet>
                          <Blog type='case-study' />
                        </>
                      )}
                    />
                  )}
                  {/* Patents */}
                  {!isWorkingOffline() && (
                    <Route
                      path={['/patents']}
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Patents{constants.titleSuffix}</title>
                          </Helmet>
                          <Patents />
                        </>
                      )}
                    />
                  )}
                  {/* News */}
                  {!isWorkingOffline() && (
                    <Route
                      path={['/news/:postKey', '/blog/:postKey']}
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>{(document.title || 'Case studies').replace(constants.titleSuffix, '')}{constants.titleSuffix}</title>
                          </Helmet>
                          <BlogDetail postKey={match.params.postKey} ssrData={this.props.ssrData} />
                        </>
                      )}
                    />
                  )}
                  {!isWorkingOffline() && (
                    <Route
                      path={['/blog', '/news']}
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>News{constants.titleSuffix}</title>
                          </Helmet>
                          <Blog />
                        </>
                      )}
                    />
                  )}
                  {hasDatabase() && (
                    <Route
                      path={['/model-types']}
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Model Types{constants.titleSuffix}</title>
                          </Helmet>
                          <ModelTypes />
                        </>
                      )}
                    />
                  )}
                  <Route
                    path={['/error-codes/:code?']}
                    render={({ match }) => (
                      <>
                        <Helmet>
                          <title>Error Codes{constants.titleSuffix}</title>
                        </Helmet>
                        <ErrorCodes errorCode={match.params.code} />
                      </>
                    )}
                  />
                  {/* UserSettings */}
                  {hasDatabase() && (
                    <Route
                      path='/user-settings'
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>User Settings{constants.titleSuffix}</title>
                              </Helmet>
                              <UserSettings user={user} fetching={fetchingUser} />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  {/* Integrations */}
                  <Route
                    path={['/integrations/*', '/integrations']}
                    render={() => (
                      <>
                        <Helmet>
                          <title>Integrations{constants.titleSuffix}</title>
                        </Helmet>
                        <Integrations user={user} />
                      </>
                    )}
                  />
                  {/* No-code with type */}
                  {!isWorkingOffline() && (
                    <Route
                      path='/no-code/:type/:formUuid?'
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>No-code Artificial Intelligence{constants.titleSuffix}</title>
                              </Helmet>
                              <NoCodeDemo
                                getModels={this.getModels}
                                user={user}
                                type={match.params.type}
                                formUuid={match.params.formUuid}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  {/* No-code */}
                  {!isWorkingOffline() && (
                    <Route
                      path='/no-code'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>No-code Artificial Intelligence{constants.titleSuffix}</title>
                          </Helmet>
                          <Redirect to='/features/data-extraction-api/no-code-ai' />
                        </>
                      )}
                    />
                  )}
                  {/* Usage */}
                  {hasDatabase() && (
                    <Route
                      path='/usage/:filter?/:limit?'
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>Usage{constants.titleSuffix}</title>
                              </Helmet>
                              <Usage
                                user={user}
                                filter={match.params.filter}
                                limit={match.params.limit}
                                models={models}
                                getModels={this.getModels}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  {/* Create Blog Post */}
                  <Route
                    path='/admin/create-blog-post'
                    render={({ match }) => (
                      <>
                        <Helmet>
                          <title>Create Blog Post{constants.titleSuffix}</title>
                        </Helmet>
                        <CreateBlogPost />
                      </>
                    )}
                  />
                  {/* Create a new flow */}
                  {hasDatabase() && (
                    <Route
                      path='/flow/new'
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>Create A New Flow{constants.titleSuffix}</title>
                              </Helmet>
                              <FlowSettings
                                user={user}
                                fetching={fetchingUser}
                                getModels={this.getModels}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  {/* OLD Edit flow */}
                  {hasDatabase() && (
                    <Route
                      path={['/flow/edit/:flowID/:tab', '/flow/edit/:flowID']}
                      render={({ match }) => <Redirect to={`/flow/${match.params.flowID}/edit${match.params.tab ? '/' + match.params.tab : ''}`} />}
                    />
                  )}
                  {/* Edit flow */}
                  {hasDatabase() && (
                    <Route
                      path={['/flow/:flowID/edit/:tab', '/flow/:flowID/edit']}
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>Edit A Flow{constants.titleSuffix}</title>
                              </Helmet>
                              <FlowSettings
                                user={user}
                                isEdit={true}
                                flowID={match.params.flowID}
                                getModels={this.getModels}
                                activeTab={match.params.tab}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  {/* Show Flow Results */}
                  {hasDatabase() && (
                    <Route
                      path={['/flow/:flowID/usage/:filter?/:limit?', '/flow/:flowID/result/*', '/flow/:flowID/result']}
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>Show Flow Results{constants.titleSuffix}</title>
                              </Helmet>
                              <ResultList
                                user={user}
                                flowID={match.params.flowID}
                                getModels={this.getModels}
                                history={this.props.history}
                                filter={match.params.filter}
                                limit={match.params.limit}
                                models={match.url
                                  .replace(/^\/flow\/[0-9a-fA-F]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}\/result\/?/, '')
                                  .length > 0
                                  ? match.url
                                    .replace(/^\/flow\/[0-9a-fA-F]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}\/result\/?/, '')
                                    .split('+')
                                    .filter(model => !!model)
                                  : []}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  {/* OLD Show Flow Results */}
                  {hasDatabase() && (
                    <Route
                      path={['/flow/usage/:flowID/:filter?/:limit?', '/flow/results/:flowID/*', '/flow/results/:flowID']}
                      render={({ match }) => (
                        <Redirect to={
                          match.url.includes('usage')
                            ? `/flow/${match.params.flowID}/usage${match.params.filter ? '/' + match.params.filter : ''}`
                            : `/flow/${match.params.flowID}/result${match.params.filter ? '/' + match.params.filter : ''}`
                        }
                        />
                      )}
                    />
                  )}
                  {/* Flow result /flow/uuid/result? */}
                  {hasDatabase() && (
                    <Route
                      path={['/flow/:flowID']}
                      render={({ match, location }) => {
                        const query = location.search ?? ''
                        return (
                          <Redirect to={
                            match.url.match(/\/flow\/[0-9a-fA-F]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/)
                              ? `/flow/${match.params.flowID}/result/${match.url.replace(/\/flow\/[0-9a-fA-F]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/, '')}${query}`
                              : `/flow`
                          }
                          />
                        )
                      }}
                    />
                  )}
                  {/* Edit custom model */}
                  {hasDatabase() && (
                    <Route
                      path={['/custom-models/edit/:modelUUID/:tab', '/custom-models/edit/:modelUUID']}
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>Edit A Custom Model{constants.titleSuffix}</title>
                              </Helmet>
                              <CustomModelSettings
                                user={user}
                                isEdit={true}
                                modelUUID={match.params.modelUUID}
                                getModels={this.getModels}
                                activeTab={match.params.tab}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  {/* Create a new custom model */}
                  {hasDatabase() && (
                    <Route
                      path='/custom-models/new'
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>Create A New Custom Model{constants.titleSuffix}</title>
                              </Helmet>
                              <CustomModelSettings
                                user={user}
                                getModels={this.getModels}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  {/* Show Custom Models */}
                  {hasDatabase() && (
                    <Route
                      path='/custom-models'
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>Show Custom Models{constants.titleSuffix}</title>
                              </Helmet>
                              <ListCustomModel
                                user={user}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  {/* HITL validation review */}
                  {hasDatabase() && (
                    <Route
                      path='/hitl/:resultUuid'
                      render={({ match }) => (
                        <>
                          <Helmet>
                            <title>Document validation{constants.titleSuffix}</title>
                          </Helmet>
                          <HITLWrapper
                            user={user}
                            resultUuid={match.params.resultUuid}
                            getModels={this.getModels}
                          />
                        </>
                      )}
                    />
                  )}
                  {/* {!isWorkingOffline() && <Route
            path="/hitl/:resultUuid"
            render={({ match }) =>
              <>
                <Helmet>
                  <title>Document validation{constants.titleSuffix}</title>
                </Helmet>
                <DocumentProcessingDemo
                  getModels={this.getModels}
                  isHITL={true}
                  user={user}
                  resultUuid={match.params.resultUuid}
                />
              </>
            }/>} */}
                  {/* List user flows */}
                  {hasDatabase() && (
                    <Route
                      path={['/flow', '/demo/document-processing/*', '/demo/document-processing']}
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>List User Flows{constants.titleSuffix}</title>
                              </Helmet>
                              <FlowList
                                user={user}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}

                  {/* Activity */}
                  {hasDatabase() && (
                    <Route
                      path='/admin/:page?/:userId?'
                      render={({ match }) => user || isSSR()
                        ? (
                            <>
                              <Helmet>
                                <title>Admin activity view{constants.titleSuffix}</title>
                              </Helmet>
                              <Admin
                                page={match.params.page}
                                userId={match.params.userId}
                              />
                            </>
                          )
                        : <Redirect to='/login' />}
                    />
                  )}
                  <Route
                    path='/compliance'
                    render={() => (
                      <>
                        <Helmet>
                          <title>Compliance{constants.titleSuffix}</title>
                        </Helmet>
                        <Compliance user={user} />
                      </>
                    )}
                  />
                  {/* Integration Wizard */}
                  {hasDatabase() && (
                    <Route
                      path='/integration-wizard'
                      render={() => (
                        <>
                          <Helmet>
                            <title>Integration Wizard{constants.titleSuffix}</title>
                          </Helmet>
                          <IntegrationWizard
                            getModels={this.getModels}
                            user={user}
                          />
                        </>
                      )}
                    />
                  )}
                  {/* Errors */}
                  <Route
                    path='/error'
                    render={({ match }) => (
                      <>
                        <Helmet>
                          <title>Error{constants.titleSuffix}</title>
                        </Helmet>
                        <Errors />
                      </>
                    )}
                  />
                  <Route
                    path='/*'
                    render={({ match }) => (
                      <>
                        <Helmet>
                          <title>Error{constants.titleSuffix}</title>
                        </Helmet>
                        <Errors title='It‘s 404!' message={match.url + ' is not found'}>
                          <Section align='center'>
                            <p>Sorry for the inconvenience.</p>
                            <p>We couldn‘t find the page you are looking for.</p>
                            <AppButton href='/' className='secondary-cta large mt-8'>Go to homepage</AppButton>
                          </Section>
                        </Errors>
                      </>
                    )}
                  />
                </Switch>
                { !isBlueWashed() && <Footer ref={this.footer} />}
              </Context.Provider>
            </FlowProvider>
          </LocationProvider>
        )
      : (
          <div className='idle'>
            <Loading />
          </div>
        )
  }
}

// Wrap application with router
const AppWithRouter = withRouter(App)

// Render application within client-side routing
export default AppWithRouter
