// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'
import document from 'global/document'
import { isWorkingOffline } from '~/context/environment'
import {
  NavLink
} from 'react-router-dom'

// Brand

// Context
import Context from '~/context/global'

// Data

// Interface
import Dropdown from '../../interface/dropdown/Dropdown'
import Icon from '~/interface/icon/Icon'

// Helpers
import { request } from '~/helpers/request'
import { shouldShowCompleteCustomerVerificationDialog, showCompleteCustomerVerificationDialog } from '~/helpers/workEmail'
import window from 'global'
import { hasDatabase, hasLogin } from '../../context/environment'

// Render component
class Navigation extends Component {
  static contextType = Context

  static propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func,
    completeCustomerVerificationDialogUserProvider: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      scrolled: false,
      search: '',
      open: false,
      clickedDropdownName: ''
    }
    this.configuration = {
      minimumScrollDistance: 10
    }
    this.reportScroll = this.reportScroll.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.showCompleteCustomerVerificationDialog = showCompleteCustomerVerificationDialog.bind(this)
    this.isBannerOpen = this.isBannerOpen.bind(this)
    this.isSelected = this.isSelected.bind(this)
    this.handlePopState = this.handlePopState.bind(this)
  }

  componentDidMount () {
    this.reportScroll()
    this.setState({ search: window.location.search, pathname: window.location.pathname })
    window.addEventListener('scroll', this.reportScroll)
    window.addEventListener('popstate', this.handlePopState)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.reportScroll)
    window.removeEventListener('popstate', this.handlePopState)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.pathname !== window.location.pathname) {
      this.setState({ pathname: window.location.pathname })
    }
  }

  handlePopState = () => {
    this.toggleMenu(true)
  }

  reportScroll () {
    const verticalScroll = window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body).scrollTop
    const scrolled = verticalScroll > this.configuration.minimumScrollDistance
    scrolled !== this.state.scrolled && this.setState({ scrolled })
  }

  toggleMenu (isForcedToClose) {
    let open = !this.state.open
    if (isForcedToClose && open) {
      open = false
    }
    open !== this.state.open && this.setState({ open })
  }

  setClickedDropdownName = name => {
    this.setState(prev => {
      return { ...prev, clickedDropdownName: name }
    })
  }

  isSelected (expectedLocation) {
    if (['Solutions', 'Resources'].includes(this.state.clickedDropdownName)) {
      return false
    }
    return (this.state.pathname || '').startsWith(expectedLocation)
  }

  isBannerOpen = (user, subscriptionResult) => shouldShowCompleteCustomerVerificationDialog(user)
  || user?.isWorkEmailVerificationEmailSent
  || user?.originalUserEmail
  || subscriptionResult

  render () {
    const {
      scrolled,
      open
    } = this.state
    const {
      user,
      logout
    } = this.props
    const userMenuList = [
      {
        label: 'Help center',
        action: () => {
          this.setClickedDropdownName('')
          window.open('https://help.base64.ai')
        }
      }
    ]

    if (hasDatabase()) {
      userMenuList.push({
        label: 'Display usage',
        action: () => {
          this.setClickedDropdownName('')
          this.context.redirect('/usage')
        }
      })
      userMenuList.push({
        label: 'User settings',
        action: () => {
          this.setClickedDropdownName('')
          this.context.redirect('/user-settings')
        }
      })
    }

    userMenuList.push({
      label: (
        <span className='logout'>
          <Icon name='exit' />
          <span>Logout</span>
        </span>
      ),
      action: () => logout(() => {
        this.setClickedDropdownName('')
        this.context.redirect('/')
      }
      )
    })

    if (this.context.user?.tags?.includes('admin')) {
      userMenuList.push({
        label: <b>Admin options:</b>
      })
      userMenuList.push({
        label: 'Admin panel',
        action: () => {
          this.setClickedDropdownName('')
          this.context.redirect('/admin')
        }
      })
      if (!isWorkingOffline()) {
        userMenuList.push({
          label: 'Create blog post',
          action: () => {
            this.setClickedDropdownName('')
            this.context.redirect('/admin/create-blog-post')
          }
        })
      }
      if (isWorkingOffline() && hasDatabase()) {
        userMenuList.push({
          label: 'Create a new user',
          action: () => {
            this.setClickedDropdownName('')
            this.context.redirect('/signup')
          }
        })
      }
      if (!isWorkingOffline()) {
        userMenuList.push({
          label: 'Login as',
          action: () => {
            const targetAccount = prompt('Login as :', '')
            if (targetAccount) {
              request({
                endpoint: '/auth/loginAs',
                body:
                        { targetAccount }
              },
              (error, response) => {
                if (error) {
                  alert(error.toString())
                } else {
                  this.context.setUser(() => {
                    this.setClickedDropdownName('')
                    window.location.href = window.location.origin + '/flow'
                  })
                }
              })
            }
          }
        })
      }
    }

    const subscriptionResult = new URLSearchParams(this.state.search).get('stripeOperation')

    const subscriptionStatus = {
      success: 'Your subscription has started and is ready to use 🎉',
      cancelled: 'No changes were made to your subscription.',
      updated: 'Your plan has changed'
    }

    const isHITL = window?.location?.pathname?.startsWith('/hitl')

    return (
      <nav className={
        (scrolled ? 'solid ' : '')
        + (open ? 'open ' : '')
        + 'navigation'
      }
      >
        {
          shouldShowCompleteCustomerVerificationDialog(user) && !isHITL
          && (
            <div className='banner enterWorkEmail'>
              <a onClick={() => this.showCompleteCustomerVerificationDialog(this.context.displayModal, this.props.completeCustomerVerificationDialogUserProvider, user)}>Hi {user.givenName || user.email}, please complete your profile to get 100 free credits!</a>
            </div>
          )
        }
        {
          user && user.isWorkEmailVerificationEmailSent && !isHITL
          && (
            <div className='banner enterWorkEmail'>
              Please verify your work email from the activation link we sent as email.
            </div>
          )
        }
        {(user?.originalUserEmail && user.originalUserEmail !== user.email)
        && (
          <div className='banner originalUserEmail'>
            Be careful {user.originalUserEmail}! You have logged in as another user. With great power comes great responsibility.
          </div>
        )}
        {subscriptionResult && !isHITL
        && (
          <div className={`banner ${subscriptionResult}`}>
            {subscriptionStatus[subscriptionResult]}
          </div>
        )}
        <div className='container'>
          <div className='header'>
            <a
              href='/'
              className='brand'
            >
              <img className='symbol' src='/static/images/brand-logo-navbar-light.png' alt='Base64.ai' />
              {/* TODO: On prem requires text */}
              {/* <Symbol />
            <b>{constants.onPremBrand || 'Base64.ai'}{(!constants.onPremBrand || constants.onPremBrand.match(/base64\.ai/i)) && isWorkingOffline() && ` for ${constants.onPremTitle}`}</b> */}
            </a>
            {/* Toggle Link */}
            <a
              className='toggle'
              onClick={() => this.toggleMenu()}
            >
              <Icon
                name='hamburger-menu'
                color='white'
              />
            </a>
          </div>
          {/* Brand */}
          {/* Menu */}
          <ul className='menu'>
            <li className='mobile' key='integrations'>
              <NavLink
                title='Add the power of Base64.ai to your existing software, workflows and RPA systems.'
                to='/integrations'
                onClick={() => this.toggleMenu(true)}
              >
                Integrations
              </NavLink>
            </li>
            <li className={'non-mobile' + (this.isSelected('/integrations') ? ' selected' : '')} key='integrations-nm'>
              <NavLink
                title='Add the power of Base64.ai to your existing software, workflows and RPA systems.'
                to='/integrations'
                onClick={
                  () => {
                    this.toggleMenu(true)
                    this.setClickedDropdownName('clear')
                  }
                }
              >
                Integrations
              </NavLink>
            </li>
            {!isWorkingOffline() && (
              <li className={'non-mobile' + (this.isSelected('/pricing') ? ' selected' : '')} key='pricing'>
                <NavLink
                  title='You can get information about our fee policies or contact us.'
                  to='/pricing'
                  onClick={
                    () => {
                      this.toggleMenu(true)
                      this.setClickedDropdownName('clear')
                    }
                  }
                >
                  Pricing
                </NavLink>
              </li>
            )}
            {hasDatabase() && (
              <li className='mobile' key='flow'>
                <NavLink
                  to='/flow'
                  onClick={() => this.toggleMenu(true)}
                >
                  Flow
                </NavLink>
              </li>
            )}
            {hasDatabase() && (
              <li className={'non-mobile' + (this.isSelected('/flow') ? ' selected' : '')} key='flow-nm'>
                <NavLink
                  title='Manage your document processes with Base64.ai'
                  to='/flow'
                  onClick={
                    () => {
                      this.toggleMenu(true)
                      this.setClickedDropdownName('clear')
                    }
                  }
                >
                  Flow
                </NavLink>
              </li>
            )}
            {!isWorkingOffline() && (
              <li className={'non-mobile' + (this.isSelected('/demo') ? ' selected' : '')} key='demo'>
                <NavLink
                  title='See for yourself how Base64.ai processes all types of documents, and recognizes people in photos and maches signatures'
                  to='/demo'
                  onClick={
                    () => {
                      this.toggleMenu(true)
                      this.setClickedDropdownName('clear')
                    }
                  }
                >
                  Demo
                </NavLink>
              </li>
            )}
            {hasLogin() && (
              user && user.email
                ? (
                    <>
                      {
                        hasDatabase() && (
                          <li className='mobile' key='usage'>
                            <NavLink
                              to='/usage'
                              onClick={() => this.toggleMenu(true)}
                            >
                              Usage
                            </NavLink>
                          </li>
                        )
                      }
                      <li className='mobile' key='logout'>
                        <a
                          className='logout'
                          onClick={() => {
                            logout(() => this.context.redirect('/'))
                            this.toggleMenu(true)
                          }}
                        >
                          <Icon name='exit' />
                          <span className='label'>Log out</span>
                        </a>
                      </li>
                      <li className='non-mobile' key='user-dropdown'>
                        <Dropdown
                          isAlignedRight={true}
                          title={this.context.user.givenName.length > 13 ? `${this.context.user.givenName.substring(0, 11)}...` : this.context.user.givenName}
                          items={userMenuList}
                        />
                      </li>
                    </>
                  )
                : (
                    <li className={(this.isSelected('/login') ? ' selected' : '')} key='login'>
                      <NavLink
                        to='/login'
                        onClick={
                          () => {
                            this.toggleMenu(true)
                            this.setClickedDropdownName('clear')
                          }
                        }
                      >
                        Login
                      </NavLink>
                    </li>
                  )
            )}
          </ul>
        </div>
      </nav>
    )
  }
}

// Export
export default Navigation
