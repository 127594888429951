// Modules
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Interface: Checkbox
const Banner = ({ children, style, onClick, className }) => {
  return (
    <div className={clsx('banner-container', className)} style={style} onClick={onClick}>
      {children}
    </div>
  )
}

Banner.propTypes = {
  children: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
}

// Export
export default Banner
