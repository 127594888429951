import { Component } from 'react'
import PropTypes from 'prop-types'
import { request } from '~/helpers/request'

class DownloadLink extends Component {
  static propTypes = {
    url: PropTypes.string,
    name: PropTypes.string,
    fileName: PropTypes.string,
    style: PropTypes.object
  }

  static defaultProps = {
    url: '',
    name: 'Download file',
    style: {}
  }

  download = url => {
    request({
      endpoint: url,
      method: 'GET'
    }, (error, base64data) => {
      if (error) {
        console.error('error while downloading instructions file', error)
        return
      }
      const a = document.createElement('a')
      a.href = base64data.file
      a.download = this.props.fileName || base64data.fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  }

  render () {
    return (
      <>
        { this.props.fileName
        && (
          <a
            className='download-link'
            style={this.props.style}
            onClick={() => {
              this.download(this.props.url)
            }}
            download
          >{this.props.name}
          </a>
        )}
      </>
    )
  }
}

export default DownloadLink
