// Layout
import Context from '~/context/global'
import PropTypes from 'prop-types'
import Section from '~/layout/section/Section'
import { isWorkingOffline } from '~/context/environment'
import { Component } from 'react'
import PartnerLogos from '../partner-logos/PartnerLogos'
import { AppButton } from '../../interface/app-button/AppButton'

// Content: PartnerSection
class PartnersSection extends Component {
  static contextType = Context

  static propTypes = {
    type: PropTypes.string,
    showButton: PropTypes.bool,
    showFeatured: PropTypes.bool,
    showCompliance: PropTypes.bool,
    showMemberships: PropTypes.bool,
    showHeaders: PropTypes.bool,
    action: PropTypes.func.isRequired
  }

  render () {
    if (isWorkingOffline()) {
      return null
    }
    return (
      <Section
        name='partners'
        align='center'
        identifier
      >
        <h2 className='title'>Trusted by the industry leaders</h2>
        <h3 className='introduction'>
          Base64.ai is a proud partner of the leading organizations in the automation world.<br />
          We are working together with our partners to bring AI automation to all industries.
        </h3>
        <div className='partner-logos-container'>
          {this.props.showCompliance && <PartnerLogos showHeaders={this.props.showHeaders} action={this.props.action} type='compliance' />}
          {this.props.showMemberships && <PartnerLogos showHeaders={this.props.showHeaders} action={this.props.action} type='membership' />}
          <PartnerLogos showHeaders={this.props.showHeaders} action={this.props.action} type={this.props.type} showFeatured={this.props.showFeatured} />
        </div>
        {this.props.showButton && (
          <div className='button-line'>
            <AppButton href='/case-studies' className='secondary-cta'>Read case studies</AppButton>
            <AppButton href='/partners' className='primary-cta'>See all partners</AppButton>
          </div>
        )}
      </Section>
    )
  }
}

// Export
export default PartnersSection
