import { Component } from 'react'
import PropTypes from 'prop-types'
import { triggerTrackingEvent } from '../../../utilities/tracker'

// Interfaces
import DemoButton from '~/interface/demo-button/DemoButton'
import DynamicTitle from '~/interface/dynamic-title/DynamicTitle'
import OptionalImage from '~/interface/optional-image/OptionalImage'

// Layouts
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'

// Contents
import CaseStudiesSection from '~/content/case-studies-section/CaseStudiesSection'
import WhyBecomeAPartnerSection from '~/content/why-become-a-partner-section/WhyBecomeAPartnerSection'
import PartnersSection from '~/content/partners-section/PartnersSection'

class PartnerPage extends Component {
  static propTypes = {
    pid: PropTypes.string,
    partner: PropTypes.object
  }

  render () {
    const { pid, partner } = this.props
    return (
      <View name='partners'>
        <DynamicTitle title={`Base64.ai & ${partner.name} partnership`} />
        <Header name='partners'>
          <h1 className='slogan'>
            <small>Meet Our Partner</small>
            <a
              href={partner.demo}
              target='_blank'
              rel='noopener noreferrer'
              style={{
                display: 'block',
                borderBottom: 'none',
                margin: '2rem',
                cursor: 'pointer'
              }}
            >
              <img src={`/static/content/partners/${pid}.png`} alt={partner.name} title={`${partner.name} is a Base64.ai partner`} style={{ cursor: 'pointer' }} />
            </a>
          </h1>
          <p className='description'>
            {partner.description}
          </p>
          <OptionalImage imageUrl={partner.optionalImage} />
          {partner.youtube && (
            <div className='dataExtractionVideoEmbed'>
              <iframe
                width='560'
                height='315'
                src={`https://www.youtube.com/embed/${partner.youtube}?cc_load_policy=1`}
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          )}
        </Header>
        {partner.demo
        && (
          <Section
            name='demonstration'
            align='center'
          >
            <h2 className='introduction'>Try Our AI </h2>
            <h3 className='introduction'>{`Experience our AI for ${partner.name} now!`}</h3>
            <DemoButton type={{ demo: partner.demo }} />
          </Section>
        )}
        <PartnersSection
          showHeaders={true}
          action={() => {
            triggerTrackingEvent('partners-page-partner-logo')
          }}
        />
        <WhyBecomeAPartnerSection />
        <CaseStudiesSection />
      </View>
    )
  }
}

export default PartnerPage
