import { createContext } from 'react'

export default createContext({
  brand: {
    color: {
      hex: '#00dddd',
      rgb: 'rgb(0, 221, 221)'
    }
  }
})
