// Modules
import PropTypes from 'prop-types'

// Interface: ValidationSign
const ValidationSign = props => (
  <span
    className='validation-sign'
    title={
      props.success
        ? 'This value meets the expectations.'
        : 'This value does not confirm the expectations. Base64.ai recommends double checking the document and the extracted value.'
    }
  >
    {props.success ? '✅' : '⚠️'}
  </span>
)

ValidationSign.propTypes = {
  success: PropTypes.bool
}

// Export
export default ValidationSign
