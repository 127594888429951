import { useEffect } from 'react'
import PropTypes from 'prop-types'
import constants from '~/data/constants'
import document from 'global/document'
function DynamicTitle (props) {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = props.title + constants.titleSuffix
  })
  return <></>
}

DynamicTitle.propTypes = {
  title: PropTypes.string.isRequired
}
// Export
export default DynamicTitle
