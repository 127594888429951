// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// Layout
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'

// Interface
import View from '../../layout/view/View'
import { request } from '../../helpers/request'

class ErrorCodes extends Component {
  static propTypes = {
    errorCode: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      errorCatalogue: null
    }
  }

  componentDidMount () {
    request({ endpoint: '/error-codes', method: 'GET' }, (err, result) => {
      if (err) {
        return console.error(err)
      }
      this.setState({
        errorCatalogue: result
      })
    }, err => {
      console.error(err)
    })
  }

  renderErrorCodeSection = ss => {
    const { errorCatalogue } = this.state
    const list = Object.keys(errorCatalogue.errorCodes).filter(x => x.startsWith(ss))
    if (!list.length) {
      return null
    }

    return (
      <>
        <div>
          <h2>{errorCatalogue.subsystems[ss]}</h2>
        </div>
        <div className='table-border'>
          <table>
            <thead>
              <tr>
                <th>Error code</th>
                <th>HTTP status</th>
                <th width='60%'>Message</th>
              </tr>
            </thead>
            <tbody>
              {list.map(errorCode => {
                return (
                  <tr key={errorCode}>
                    <td className='error-code'>
                      <code>0xB64{errorCode}</code>
                    </td>
                    <td>{errorCatalogue.errorCodes[errorCode].status}</td>
                    <td>{errorCatalogue.errorCodes[errorCode].title}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </>
    )
  }

  render () {
    const { errorCatalogue } = this.state
    return (
      <View name='error-codes'>
        <Header name='error-codes-header'>
          <h1 className='slogan'>Error codes</h1>
          <h3 className='introduction'>
            This page contains the list of possible error codes. When contacting support, please share the
            error code that starts with 0xB64.
          </h3>
        </Header>
        <Section name='error-codes-section' tight={true}>
          {
            errorCatalogue
              ? Object.keys(errorCatalogue.subsystems).map(ss => this.renderErrorCodeSection(ss))
              : <h2 className='loading'>Please wait while loading...</h2>
          }
        </Section>
      </View>
    )
  }
}

export default withRouter(ErrorCodes)
