import { hasDatabase } from '../context/environment'

export default [
  {
    title: 'facialRecognition',
    url: '/demo/facial-recognition',
    description: 'Extract faces from any document including IDs, driver licenses, and passports',
    buttonTitle: 'Facial recognition',
    path: 'Face_AI_1.png',
    isWorkOffline: true
  },
  {
    title: 'facialVerification',
    url: '/demo/facial-verification',
    description: 'Compare faces on documents with selfies to automate user onboarding and KYC',
    buttonTitle: 'Facial verification',
    path: 'Face_AI_2.png',
    isWorkOffline: true
  },
  {
    title: 'facialTracking',
    url: '/demo/facial-tracking',
    description: 'Secure your workplace by identifying unwanted guests using the existing cameras, webcams, or any smartphone',
    buttonTitle: 'Camera Tracking',
    path: 'Face_AI_3.png',
    isWorkOffline: hasDatabase()
  },
  {
    title: 'facialManager',
    url: '/demo/facial-manager',
    description: 'Register employees in your secure facial verification account for enhanced physical security',
    buttonTitle: 'Store Face Data',
    path: 'Face_AI_4.png',
    isWorkOffline: hasDatabase()
  }
  // {
  //   title: 'scanWidgetFace',
  //   url: '/demo/scan-widget/face',
  //   description: 'Embed face detection in your application or process with zero lines of code',
  //   buttonTitle: 'Embedded face detection',
  //   isWorkOffline: false
  // }
]
