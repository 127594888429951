// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'
import {
  Redirect,
  withRouter
} from 'react-router-dom'

// Layout
import View from '~/layout/view/View'
import FeaturesSection from '../../content/features-section/FeaturesSection'

// Views
import DataExtractionAPI from './data-extraction-api/DataExtractionAPI'
import ImageRecognitionAPI from './image-recognition-api/ImageRecognitionAPI'

// View: Features
class Features extends Component {
  static propTypes = {
    item: PropTypes.string,
    type: PropTypes.string
  }

  static defaultProps = {
    item: 'data-extraction-api'
  }

  constructor (props) {
    super(props)
    this.configuration = {
      items: {
        'data-extraction-api': type => <DataExtractionAPI type={type} />,
        'image-recognition-api': type => <ImageRecognitionAPI type={type} />
      },
      defaultItem: 'data-extraction-api'
    }
  }

  componentDidUpdate (prevProps) {
    this.props.type !== prevProps.type && window.scrollTo(0, 0)
  }

  render () {
    const {
      item,
      type
    } = this.props
    const {
      items,
      defaultItem
    } = this.configuration
    return item && Object.keys(items).includes(item)
      ? (
          <View
            name='features'
            identifier={item}
          >
            {type ? items[item](type) : <FeaturesSection />}
          </View>
        )
      : <Redirect to={`/features/${defaultItem}`} />
  }
}

// Export within router
export default withRouter(Features)
