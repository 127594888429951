// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'
import PropTypes from 'prop-types'
import window from 'global/window'

// Context
import Context from '~/context/global'
import DynamicTitle from '~/interface/dynamic-title/DynamicTitle'
import BackButton from '../../interface/back-button/BackButton'
// View: Blog
class BlogDetail extends Component {
  static contextType = Context
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      postKey: this.props.postKey,
      posts: this.props.ssrData || JSON.parse((window && window.ssrData && atob(window.ssrData)) || '[]')
    }
  }

  getPostContent (postKey) {
    return (this.state.posts || []).filter(post => post.postKey === postKey.split('-').splice(-1)[0])[0]
  }

  componentDidMount () {
    if (!this.state.posts.length) {
      fetch('/api/blog/posts/' + this.props.postKey, { method: 'POST' })
        .then(res => res.json())
        .then(data => {
          // create two-dimensional array with 3 elements per inner array
          this.setState({ posts: data.sort((a, b) => b.createdAt - a.createdAt), isLoading: false })
        })
    }
  }

  getDateTime (ms) {
    return new Date(ms).toLocaleDateString('en', { month: 'long', day: 'numeric', year: 'numeric' })
  }

  render () {
    const { postKey, isLoading } = this.state
    let selectedPost
    if (postKey) {
      selectedPost = this.getPostContent(postKey)
    }
    const isCaseStudy = selectedPost?.type === 'case-study'
    const homePage = isCaseStudy ? '/case-studies' : '/news'
    return selectedPost
      ? (
          <View name='blog-detail'>
            <Header name='header'>
              <div className='back-link'>
                <BackButton
                  to={homePage}
                  placeholder={`See all ${isCaseStudy ? ' case studies' : ' news'}`}
                />
              </div>
              <h1 className='slogan'>{selectedPost.title}</h1>
              <h2 className='introduction'>
                {selectedPost.author} on {this.getDateTime(selectedPost.createdAt)}
              </h2>
              <DynamicTitle title={selectedPost.title} />
            </Header>
            <Section name='posts' tight={true}>
              <img src={selectedPost.image} alt={`Cover image of ${selectedPost.title}`} />
              <article className='content'>
                <div dangerouslySetInnerHTML={{ __html: selectedPost.content }} />
              </article>
            </Section>
          </View>
        )
      : (
          <View name='blog-detail'>
            <Section name='posts'>
              {!isLoading && <a className='back' href='/news'>Content not found, click to go News Page</a>}
            </Section>
          </View>
        )
  }
}

BlogDetail.propTypes = {
  postKey: PropTypes.string,
  ssrData: PropTypes.array
}

// Export within router
export default withRouter(BlogDetail)
