// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'

// Layout
import View from '~/layout/view/View'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'

// Interface
import Link from '~/interface/link/Link'
import { request } from '../../helpers/request'

class ModelTypes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modelTypes: null
    }
  }

  componentDidMount () {
    request({ endpoint: '/models?isBuild=true', method: 'GET' }, (err, result) => {
      if (err) {
        return console.error(err)
      }
      this.setState({
        modelTypes: result
      })
    }, err => {
      console.error(err)
    })
  }

  toggleSection (id) {
    const list = document.getElementById(id)
    if (list.style.display === 'none') {
      list.style.display = 'block'
    } else {
      list.style.display = 'none'
    }
  }

  expandAll () {
    this.state.modelTypes?.forEach(m => {
      const id = this.createID(m.title)
      const list = document.getElementById(id)
      list.style.display = 'block'
    })
  }

  createID (title) {
    return 'table-' + title.toLowerCase().replace(/ /g, '-')
  }

  render () {
    const { modelTypes } = this.state
    const modelCount = modelTypes?.reduce((acc, modelType) => {
      return acc + Object.keys(modelType.data).length
    }, 0) || 'thousands of'

    return (
      <View
        name='model-types'
      >
        <Header name='model-types'>
          <h1 className='slogan'>Supported Document Types</h1>
          <h3 className='introduction'>
            Base64.ai is the industry leader in supporting the most number of documents out of the box!<br />
            Our AI automatically recognizes, classifies, and processes <b>{ modelCount?.toLocaleString?.('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) } document types</b> without
            the need for any additional machine learning training.
          </h3>
          <h3 className='introduction'>
            No need to worry if you cannot find your document type.
            Our <Link href='/flow'>Semantic AI</Link> enables Base64.ai to process documents we have never encountered before.
          </h3>
          <h3 className='introduction'>
            Click on the titles to expand the list or <a onClick={() => this.expandAll()}>click here to expand all</a>.
          </h3>
        </Header>
        <Section name='model-types' tight={true}>
          <div>
            {
              !modelTypes
                ? <h2>Please wait while loading...</h2>
                : modelTypes.map(section => {
                  const id = this.createID(section.title)
                  return (
                    <div key={section.index} className='model-type-section'>
                      <h2><a onClick={() => this.toggleSection(id)}>
                        {section.title}
                          </a>
                      </h2>
                      <p>{Object.keys(section.data).length} document types</p>
                      <div className='table-border' id={id} style={{ display: 'none', transition: 'all 0.25s ease' }}>
                        <table>
                          <thead>
                            <tr>
                              <th>Document type</th>
                              <th>Model</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              Object.keys(section.data).map(model => {
                                const isSemantic = section.data[model]['Model type'].startsWith('semantic')
                                return (
                                  <tr key={model}>
                                    <td>{section.data[model]['Model name']}</td>
                                    <td className='model-value'><code><Link href='/flow'>{isSemantic ? 'semantic' : section.data[model]['Model type']}</Link></code></td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                })
            }
          </div>
        </Section>
      </View>
    )
  }
}

export default withRouter(ModelTypes)
