import { isWorkingOffline } from '../context/environment'
/* SSR-IGNORE */ import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export const shouldShowCompleteCustomerVerificationDialog = user =>
  !isWorkingOffline()
  && user
  && user.email
  && (
    user.isWorkEmailVerified === false
    || user.phoneNumber === null
  )
  && !user.tags.includes('unlimitedCredits')
  && !user.isWorkEmailVerificationEmailSent

export const showCompleteCustomerVerificationDialog = (showModal, userProvider, user) => {
  if (!shouldShowCompleteCustomerVerificationDialog(user)) {
    return
  }
  showModal({
    title: 'Enjoying Base64.ai',
    isWide: true,
    content: <>
      <p>Verify your work email and phone number to unlock 100 credits.</p>
      <br />
      <div className='modal-form'>
        <label>
          <b>Work email:</b>
        </label>
        <input
          autoFocus
          type='email'
          defaultValue={user.workEmail}
          placeholder='Enter your work email...'
          onChange={e => {
            userProvider({ workEmail: e.target.value })
          }}
        />
      </div>
      <br />
      <div className='modal-form'>
        <label>
          <b>Phone number:</b>
        </label>
        <PhoneInput
          className='phone-input'
          placeholder='Enter your phone number'
          value={user.phoneNumber}
          addInternationalOption={false}
          defaultCountry='US'
          onChange={e => {
            userProvider({ phoneNumber: e })
          }}
        />
      </div>
      <br />
      <div className='modal-form'>
        <label>
          <b>Your use case:</b>
        </label>
        <input
          type='text'
          placeholder='Enter your use case...'
          defaultValue={user.useCase}
          onChange={e => {
            userProvider({ useCase: e.target.value })
          }}
        />
      </div>
    </>,
    options: [
      {
        content: 'Add later',
        intent: 'negative'
      },
      {
        content: 'Update',
        action: () => {
          userProvider('update')
        }
      }
    ]
  })
}
