// Modules
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  isDesktop,
  isMobile
} from 'react-device-detect'
import Webcam from 'react-webcam'
// Context
import Context from '~/context/global'
import { reCaptcha } from '~/helpers/authentication'
// Helpers
import { request } from '~/helpers/request'
// Interface
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'

// View: Demo
class ScanWidget extends Component {
  static contextType = Context

  static IMAGE_PATH = '/static/content/features/facial-recognition'

  static OVERLAY_IMAGE = {
    face: `${ScanWidget.IMAGE_PATH}/${isMobile ? 'mobile-' : ''}face-overlay.png`,
    document: `${ScanWidget.IMAGE_PATH}/${isMobile ? 'mobile-' : ''}document-overlay.png`
  }

  constructor (props) {
    super(props)
    const scannerType = this.props.scannerType || 'face'
    const useFrontCamera = isDesktop
      ? true
      : scannerType === 'face'
    this.webcamRef = React.createRef()
    this.queryImageCanvasRef = React.createRef()
    this.queryImageFigureRef = React.createRef()
    this.state = {
      responseStatus: null,
      responseMessage: null,
      useFrontCamera,
      cameraImage: null,
      data: null,
      scannerType
    }
    this.submit = this.submit.bind(this)
    this.makeRequest = this.makeRequest.bind(this)
    this.retake = this.retake.bind(this)
    this.switchCamera = this.switchCamera.bind(this)
    this.handleUserMedia = this.handleUserMedia.bind(this)
    this.handleUserError = this.handleUserError.bind(this)
  }

  submit () {
    alert('The photo is submitted!')
  }

  makeRequest () {
    this.setState({
      cameraImage: `${ScanWidget.IMAGE_PATH}/loading.gif`,
      responseMessage: 'Processing...'
    })
    if (!this.webcamRef || !this.webcamRef.current) {
      console.log('Camera was not ready, try again')
      return
    }
    const resolution = isMobile
      ? {
          width: 2160,
          height: 2880
        }
      : {}
    const document = this.webcamRef.current.getScreenshot(resolution)
    if (!document || document === 'data:,') {
      console.log('Camera was not ready, try again')
      return
    }

    const endpoint = this.state.scannerType === 'face' ? '/face' : '/scan'
    reCaptcha('demo', token => {
      request({
        endpoint,
        body: {
          token,
          imageIsMirrored: this.state.useFrontCamera,
          document
        }
      }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: error.message,
            responseStatus: 'error'
          })
        } else {
          if (response.message) {
            this.setState({
              responseMessage: error,
              responseStatus: 'error'
            })
          } else {
            if (response.length) {
              const responseMessage = this.state.scannerType === 'face' ? `${response.length} face detected.` : `${response[0].model.name} detected.`
              this.setState({
                responseMessage,
                responseStatus: 'success',
                cameraImage: document,
                data: response
              })
            } else {
              this.setState({
                responseMessage: `No ${this.state.scannerType} detected.`,
                responseStatus: 'warning'
              })
            }
          }
        }
      })
    })
  }

  retake () {
    this.setState({
      cameraImage: null,
      responseStatus: null,
      responseMessage: null
    })
  }

  switchCamera () {
    this.setState({
      useFrontCamera: !this.state.useFrontCamera
    })
  }

  handleUserMedia = () => {
    // Show controls
  }

  handleUserError = error => {
    this.setState({
      responseMessage: `Cannot launch camera. Did you enable our website to access your camera? (Details: ${error})`,
      responseStatus: 'error',
      detectedFaces: null
    })
  }

  render () {
    const {
      cameraImage,
      responseStatus,
      responseMessage,
      useFrontCamera
    } = this.state
    return (
      <View name='scan-widget'>
        <Section name='webcam'>
          {
            cameraImage
              ? (
                  <>
                    <div className='silhouette'>
                      <img src={cameraImage} />
                      <div className='buttonArea'>
                        <b>{responseStatus ? responseMessage : 'Please wait while processing...'}</b><br />
                        <b>{responseStatus === 'success' ? 'Click "Submit" to use this photo, or click "Retake" and try again.' : ' '}</b><br />
                        <a className='button cancelButton' onClick={this.retake}>Retake</a>
                        <a className='button' onClick={this.submit}>Submit</a>
                      </div>
                    </div>
                  </>
                )
              : (
                  <>
                    <div className='silhouette'>
                      <img src={ScanWidget.OVERLAY_IMAGE[this.state.scannerType]} />
                      <div className='buttonArea'>
                        <b>Ensure your {this.state.scannerType} fits inside the white overlay frame.</b><br />
                        <b>&nbsp;</b><br />
                        {
                          isDesktop
                            ? null
                            : <a className='button' onClick={this.switchCamera}>Switch camera</a>
                        }
                        <a className='button' onClick={this.makeRequest}>Take picture</a>
                      </div>
                    </div>
                    <Webcam
                      style={{
                        width: '100%',
                        top: 0,
                        right: 0,
                        left: 0
                      }}
                      videoConstraints={{
                        facingMode: useFrontCamera ? 'user' : 'environment'
                      }}
                      ref={this.webcamRef}
                      audio={false}
                      mirrored={useFrontCamera}
                      screenshotFormat='image/png'
                      screenshotQuality={1}
                      forceScreenshotSourceSize={false}
                      onUserMedia={this.handleUserMedia}
                      onUserMediaError={this.handleUserError}
                    />
                  </>
                )
          }
        </Section>
      </View>
    )
  }
}

ScanWidget.propTypes = {
  scannerType: PropTypes.string,
  companyId: PropTypes.string
}

// Export
export default ScanWidget
