import { Component } from 'react'
import { request } from '../../helpers/request'
import PropTypes from 'prop-types'
import window from 'global/window'
import Context from '~/context/global'

class IntegrationEditor extends Component {
  static contextType = Context

  static propTypes = {
    templateID: PropTypes.number,
    workflowID: PropTypes.number,
    contextID: PropTypes.string,
    additionalTemplateID: PropTypes.string,
    identifier: PropTypes.string
  }

  static defaultProps = {
    type: 'flowID'
  }

  constructor (props) {
    super(props)
    this.state = {
      cookie: null,
      host: window.integrateHost || 'https://integrate.base64.ai'
    }
    this.displayError = this.displayError.bind(this)
  }

  displayError (err) {
    this.setState({
      cookie: null
    }, () => {
      this.context.displayModal({
        title: 'Integration error',
        isWide: false,
        isClosable: false,
        content: (
          <div>
            <p>Error details: {err.message}</p>
          </div>
        ),
        options: [
          {
            content: 'Close',
            action: () => {
              window.location.reload()
            },
            intent: 'negative'
          }
        ]
      })
    })
  }

  componentDidMount () {
    request({
      endpoint: `/integration/cookie?${this.props.identifier}=` + this.props.contextID,
      method: 'GET'
    }, (err, result) => {
      if (err) {
        return this.displayError(err)
      }
      this.setState({
        cookie: result.cookie,
        host: window.isLocalhost ? `${window.location.protocol}//${window.location.hostname}:5678` : this.state.host
      })
    }, err => {
      this.displayError(err)
    })
  }

  render () {
    const { templateID, workflowID, contextID, additionalTemplateID } = this.props

    let src = ''
    if (templateID) {
      src = `${this.state.host}/workflows/templates/${templateID}?${this.props.identifier}=${contextID}`
    } else if (workflowID && additionalTemplateID) {
      src = `${this.state.host}/workflow/${workflowID}?${this.props.identifier}=${contextID}&additionalTemplateID=${additionalTemplateID}`
    } else if (workflowID) {
      src = `${this.state.host}/workflow/${workflowID}?${this.props.identifier}=${contextID}`
    }
    return (
      <>{
        this.state.cookie
          ? <iframe src={src} className='integration-editor' />
          : null
      }
      </>
    )
  }
}

export default IntegrationEditor
