import { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import MaterialIcon from '../material-icon/MaterialIcon'
import Popup from '../popup/Popup'
/* SSR-IGNORE */ import ReactJson from 'react-json-view'

const ConfigurationWrapper = ({
  children,
  onChange = () => {},
  value,
  defaultValue = '{}',
  featureName = '',
  className = '',
  show
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [configValue, setConfigValue] = useState(value || defaultValue)
  const [errorMessage, setErrorMessage] = useState('')

  const handleChange = e => {
    setConfigValue(e.updated_src)
  }

  return (
    <>
      {isOpened && (
        <Popup closeHandler={() => {
          if (!errorMessage) {
            onChange?.(configValue)
            return setIsOpened(false)
          }
          alert('Invalid configuration: JSON is not valid.')
        }}
        >
          <ReactJson
            src={configValue}
            name={null}
            iconStyle='square'
            displayDataTypes={false}
            displayObjectSize={false}
            collapsed={5}
            collapseStringsAfterLength={40}
            displayArrayKey={false}
            onAdd={handleChange}
            onDelete={handleChange}
            onEdit={handleChange}
          />
        </Popup>
      )}
      <div className={clsx('configuration-wrapper', className)}>
        {children}
        {show && (
          <MaterialIcon
            style={{ fontSize: '1rem' }}
            title={`Edit ${featureName} configuration`}
            name='edit'
            onClick={() => {
              setIsOpened(true)
            }}
          />
        )}
      </div>
    </>
  )
}

ConfigurationWrapper.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  featureName: PropTypes.string,
  className: PropTypes.string,
  show: PropTypes.bool
}

export default ConfigurationWrapper
