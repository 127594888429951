// Modules
import { Component } from 'react'
import PropTypes from 'prop-types'

// Context
import Context from '~/context/global'

// Layout
import View from '~/layout/view/View'
import Section from '~/layout/section/Section'

// Interface
import Form from '~/interface/form/Form'
import Button from '~/interface/button/Button'
import Link from '~/interface/link/Link'

// Helpers
import { request } from '~/helpers/request'
import { reCaptcha } from '~/helpers/authentication'
import { RegEx } from '../../constants/RegEx'
import { AppButton } from '../../interface/app-button/AppButton'

// View: PasswordReset
class PasswordReset extends Component {
  static contextType = Context

  static propTypes = {
    email: PropTypes.string,
    verification: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      completed: false,
      email: '',
      verification: '',
      loading: false,
      submitHandler: () => {}
    }
    this.requestReset = this.requestReset.bind(this)
    this.verifyReset = this.verifyReset.bind(this)
  }

  componentDidMount () {
    const verification = this.props?.verification
    let email = this.props?.email

    if (email && verification) {
      email = decodeURIComponent(email)
      this.setState({
        email,
        verification
      })
    }
    this.context.redirect('/password-reset')
  }

  requestReset (data, errorCallback) {
    this.setState({ loading: true })
    request({
      endpoint: '/auth/passwordReset',
      body: data
    }, (error, response) => {
      if (error) {
        errorCallback(error?.message || error.toString())
      } else {
        this.setState({
          requested: true
        })
      }
      this.setState({ loading: false })
    })
  }

  verifyReset (data, errorCallback) {
    this.setState({ loading: true })
    request({
      endpoint: '/auth/passwordChange',
      body: {
        ...data,
        email: this.state.email,
        verificationCode: this.state.verification
      }
    }, (error, response) => {
      if (error) {
        errorCallback(error?.message || error.toString())
      } else {
        this.setState({
          completed: true
        })
      }
      this.setState({ loading: false })
    })
  }

  render () {
    const { completed, requested, email, verification } = this.state
    return (
      <View name='password-reset'>
        {
          (requested || completed)
            ? (
                <Section
                  name='finalization'
                  align='center'
                  flex={true}
                >
                  {
                    completed
                      ? (
                          <>
                            <h1>Your password has been changed!</h1>
                            <p>Password reset is successful. You can login with your new password now.</p>
                            <Button to='/login'>Go to Login page</Button>
                          </>
                        )
                      : (
                          <>
                            <h1>Password reset has been requested.</h1>
                            <p>We have sent you an email to confirm password reset.</p>
                            <p>Please click the verification link in the email to continue.</p>
                          </>
                        )
                  }
                </Section>
              )
            : (email && verification)
                ? (
                    <Section
                      name='verification'
                      flex={true}
                    >
                      <Form
                        onDataSubmit={(data, errorCallback) => {
                          reCaptcha('passwordchange', token => {
                            data.token = token
                            this.verifyReset(data, errorCallback)
                          })
                        }}
                        onSubmitHandler={submitHandler => {
                          this.setState({ submitHandler })
                        }}
                        title={<>Reset password for <b>{email}</b></>}
                      >
                        <Form.Input
                          type='password'
                          name='password'
                          reference='Password'
                          placeholder='Enter new password'
                          autoComplete='off'
                          validator={{
                            minimumLength: 8,
                            fieldMatch: 'passwordConfirmation'
                          }}
                        />
                        <Form.Input
                          type='password'
                          name='passwordConfirmation'
                          reference='Password confirmation'
                          placeholder='Confirm new password'
                          autoComplete='off'
                          validator={{
                            minimumLength: 8
                          }}
                        />
                        <AppButton
                          className='primary-cta'
                          loading={this.state.loading}
                          onClick={() => this.state.submitHandler()}
                        >
                          Reset password
                        </AppButton>
                      </Form>
                    </Section>
                  )
                : (
                    <Section
                      name='application'
                      flex={true}
                    >
                      <Form
                        onDataSubmit={(data, errorCallback) => {
                          reCaptcha('passwordreset', token => {
                            data.token = token
                            this.requestReset(data, errorCallback)
                          })
                        }}
                        onSubmitHandler={submitHandler => {
                          this.setState({ submitHandler })
                        }}
                        title='Request password reset'
                      >
                        <Form.Input
                          type='email'
                          name='email'
                          reference='Email address'
                          placeholder='Enter your email address'
                          autoComplete='off'
                          validator={{
                            pattern: RegEx.EMAIL,
                            maximumLength: 255
                          }}
                        />
                        <AppButton
                          className='primary-cta'
                          loading={this.state.loading}
                          onClick={() => this.state.submitHandler()}
                        >
                          Send reset link
                        </AppButton>
                      </Form>
                      <Link
                        to='/login'
                        className='external'
                      >
                        Remembered your password? Login.
                      </Link>
                    </Section>
                  )
        }
      </View>
    )
  }
}

// Export
export default PasswordReset
