import { Component } from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from '../material-icon/MaterialIcon'

class VoiceInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    onResult: PropTypes.func,
    style: PropTypes.object,
    icon: PropTypes.string
  }

  static defaultProps = {
    className: '',
    onClick: () => {},
    onResult: () => {},
    icon: 'mic'
  }

  constructor (props) {
    super(props)
    this.state = {
      isRecording: false,
      recognition: null
    }
  }

  render () {
    return (
    // do not display if not supported
      window.webkitSpeechRecognition
        ? (
            <MaterialIcon
              name={this.props.icon}
              className={'voice-input ' + this.props.className + (this.state.isRecording ? ' recording' : '')}
              style={this.props.style}
              onClick={e => {
                if (this.state.isRecording || this.state.recognition) {
                  this.state.recognition.stop()
                  this.setState({ isRecording: false, recognition: null })
                  return
                }
                // eslint-disable-next-line new-cap
                const recognition = new window.webkitSpeechRecognition()
                recognition.continuous = false
                recognition.interimResults = false

                recognition.onresult = event => {
                  this.setState({ isRecording: false, recognition: null })
                  this.props.onResult(event.results[0][0].transcript)
                }

                recognition.start()
                this.setState({ isRecording: true, recognition })
                this.props.onClick(e)
              }}
            />
          )
        : <></>
    )
  }
}

export default VoiceInput
