import MaterialIcon from '~/interface/material-icon/MaterialIcon'
import PropTypes from 'prop-types'

import clsx from 'clsx'

export const HelpButton = ({ href, className }) => {
  return (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      className={clsx('HelpButton', className)}
    >
      <MaterialIcon name='info' className='-mt-1' />
      Help
    </a>
  )
}

HelpButton.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string
}
