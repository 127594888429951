export const getNestedObject = (nestedObj, path) => {
  if (!path) {
    return nestedObj
  }
  const pathArr = path.split('.')
  return pathArr.reduce((obj, key) =>
    (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj)
}

export const setNestedObject = (nestedObj, path, value) => {
  const pathArr = path.split('.')
  pathArr.reduce((obj, key, index) => {
    if (index === pathArr.length - 1) {
      obj[key] = value
    } else if (!obj[key]) {
      obj[key] = {}
    }
    return obj[key]
  }, nestedObj)
}
