import { Component } from 'react'
// modules
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import MaterialIcon from '../material-icon/MaterialIcon'
import { createSelectFieldsFromString, toCamelCase, toLowercaseFirstLetter } from '../../utilities/format'
import Select from 'react-select'
import DatePicker from '../date-picker/DatePicker'
import Checkbox from '../checkbox/Checkbox'

import { isEqual } from 'radash'

class MultiInputTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: []
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.updateFromOutside = this.updateFromOutside.bind(this)
  }

  componentDidMount () {
    this.setState({
      data: this.props.data
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.data !== this.state.data) {
      this.props.onChange && this.props.onChange(this.state.data)
    }

    if (prevProps.data !== this.props.data) {
      this.updateFromOutside()
    }
  }

  updateFromOutside () {
    this.setState({
      data: this.props.data
    }, () => {
      this.props.onChange(this.state.data)
    })
  }

  handleInputChange (changed, cn, i, options) {
    options = options || {}
    this.setState(prev => {
      const temp = prev.data
      if (cn.isMulti) {
        const spellings = []
        for (const c of changed) {
          spellings.push({ value: c.value, label: c.label })
        }
        temp[i][cn.fieldName] = spellings
      } else {
        const tempVal = changed.value.trim().replace(/^\*\s*/g, '')
        if (
          cn.isCamelCase
          && toCamelCase(tempVal).length !== tempVal.length
          && tempVal.startsWith('features.')
          && tempVal.startsWith('meta.')
        ) {
          changed.value = toCamelCase(changed.value)
        }
        changed.value = toLowercaseFirstLetter(changed.value)
        temp[i][cn.fieldName] = {
          label: changed.label,
          value: changed.value
        }
        if (changed?.inputTypeOverwrite) {
          temp[i].inputTypeOverwrite = changed?.inputTypeOverwrite
          temp[i].limits = changed.limits || []
          options.value = changed?.inputTypeOverwrite.defaultValue
        } else if (changed?.isSubOption) {
          temp[i].inputTypeOverwrite = options?.inputTypeOverwrite
          temp[i].limits = options.limits || []
        } else {
          temp[i].inputTypeOverwrite = {}
          temp[i].limits = []
          if (!options?.value || typeof options?.value === 'object') {
            options.value = ''
          }
        }
      }
      return {
        ...prev,
        data: temp
      }
    }, () => {
      this.props.onChange(this.state.data)
    })
  }

  getInputType (row, column) {
    return this.getInputOption(row, column, 'inputType')
  }

  getInputOption (row, column, key) {
    if (column.fieldName === row?.inputTypeOverwrite?.fieldName) {
      return row?.inputTypeOverwrite[key]
    }
    return column[key]
  }

  getCreatableSelectConfig (row, column) {
    if (column.fieldName === row?.inputTypeOverwrite?.fieldName && row?.inputTypeOverwrite.inputType === 'creatable-select') {
      return row?.inputTypeOverwrite
    }
    return column
  }

  getLimitedOptions (row, column) {
    if (!row.limits?.length || !row.limits.filter(s => s.fieldName === column.fieldName).length) {
      return column.options
    }
    return column.options.filter(s => row.limits.find(r => r.value === s.value))
  }

  getStartDate (dateString) {
    let date = new Date()
    if (!dateString) {
      return date
    }
    date = new Date(dateString)
    if (isNaN(date.getTime())) {
      return new Date()
    }
    return date
  }

  getOptionLabel (d, cn) {
    const selectedOption = cn.options.find(option => {
      if (option.options) {
        return option.options.find(nestedOption => nestedOption.value === d[cn.fieldName])
      }
      return option.value === d[cn.fieldName]
    })
    return selectedOption ? (selectedOption.options ? selectedOption.options.find(nestedOption => nestedOption.value === d[cn.fieldName]) : selectedOption) : cn.defaultValue
  }

  render () {
    const isEmpty = this.state.data?.length === 1 && Object.keys(this.state.data[0]).every(k => {
      const targetColumn = this.props.tableStructure.columns.find(c => c.fieldName === k)
      return isEqual(this.state.data[0][k], targetColumn?.defaultValue) || this.state.data[0][k] === targetColumn?.defaultValue
    })
    return (
      <div id='resp-table'>
        <div id='resp-table-body'>
          <div className={'resp-table-row header ' + (this.props.tableStructure.className || '')}>
            {
              this.props.tableStructure.columns.map((cn, index) => {
                return (
                  cn.columnName
                  && (
                    <div key={index} className={'table-body-cell ' + cn.columnName.toLowerCase().trim().replace(/\s+/, '-')} title={cn.title} style={{ padding: 0 }}>
                      <span>{cn.columnName}</span>
                    </div>
                  )
                )
              })
            }
            {
              this.props.tableStructure.isDeleteButtonEnabled
              && (
                <div className='table-body-cell'>
                  <img
                    className='remove-button'
                    onClick={() => {}}
                    src='/static/images/icons/hitl-close.svg'
                    style={{
                      visibility: 'hidden'
                    }}
                  />
                </div>
              )
            }
          </div>
          { this.state.data.map((d, i) => {
            return (
              <div key={i} className={'resp-table-row ' + (this.props.tableStructure.className || '')}>
                {
                  this.props.tableStructure.columns.map((columnsConfig, index) => {
                    const cn = this.getCreatableSelectConfig(d, columnsConfig)
                    if (!Object.keys(d).includes(cn.fieldName) && cn.alwaysShow !== true) {
                      return
                    }
                    return (
                      <div key={index} className='table-body-cell'>
                        {
                          ['text', 'number'].includes(this.getInputType(d, cn))
                            ? (
                                <input
                                  type={this.getInputType(d, cn)}
                                  name='columnName'
                                  value={d[cn.fieldName] || ''}
                                  placeholder={cn.placeholder}
                                  style={{ width: '100%' }}
                                  onChange={e => {
                                    this.setState(prev => {
                                      const temp = prev.data
                                      temp[i][cn.fieldName] = e.target.value
                                      return {
                                        ...prev,
                                        data: temp
                                      }
                                    }, () => {
                                      this.props.onChange(this.state.data)
                                    })
                                  }}
                                  className='form-control'
                                />
                              )
                            : this.getInputType(d, cn) === 'creatable-select'
                              ? (
                                  <CreatableSelect
                                    isMulti={cn.isMulti}
                                    className='react-select'
                                    classNamePrefix='react-select'
                                    style={{ width: 'auto !important' }}
                                    value={cn.isMulti
                                      ? (d[cn.fieldName] || []).filter(f => f?.label && f?.value)
                                      : d[cn.fieldName]?.label && [d[cn.fieldName]]}
                                    // styles={this.configuration.inputStyle}
                                    components={{ DropdownIndicator: null }}
                                    placeholder={cn.placeholder || 'Select or enter data source...'}
                                    closeMenuOnSelect={true}
                                    onCreateOption={e => {
                                      let changed = createSelectFieldsFromString(e, cn.isMulti
                                        ? (d[cn.fieldName] || []).filter(f => f?.label && f?.value)
                                        : d[cn.fieldName]?.label && [d[cn.fieldName]])
                                      if (!cn.isMulti) {
                                        changed = changed[changed.length - 1]
                                      }
                                      this.handleInputChange(changed, cn, i, d)
                                    }}
                                    onChange={changed => {
                                      this.handleInputChange(changed, cn, i, d)
                                    }}
                                    formatCreateLabel={
                                      value => {
                                        const fields = createSelectFieldsFromString(value)
                                        return `Create ${fields.map(f => `"${f.value}"`).join(', ')}`
                                      }
                                    }
                                    options={cn.options}
                                  />
                                )
                              : this.getInputType(d, cn) === 'select'
                                ? (
                                    <Select
                                      id='databaseTypes'
                                      classNamePrefix='react-select'
                                      className='react-select'
                                      defaultValue={cn.defaultValue}
                                      options={this.getLimitedOptions(d, cn)}
                                      styles={{
                                        container: base => ({
                                          ...base,
                                          fontSize: '0.9rem'
                                        })
                                      }}
                                      onChange={e => {
                                        this.setState(prev => {
                                          const temp = prev.data
                                          temp[i][cn.fieldName] = e.value
                                          return {
                                            ...prev,
                                            data: temp,
                                            isSubmitted: false
                                          }
                                        }, () => {
                                          this.props.onChange && this.props.onChange(this.state.data)
                                        })
                                      }}
                                      value={this.getOptionLabel(d, cn)}
                                    />
                                  )
                                : this.getInputType(d, cn) === 'date'
                                  ? (
                                      <DatePicker
                                        startDate={this.getStartDate(d.value)}
                                        onChange={date => {
                                          this.setState(prev => {
                                            const temp = prev.data
                                            temp[i][cn.fieldName] = date.getTime()
                                            return {
                                              ...prev,
                                              data: temp
                                            }
                                          }, () => {
                                            this.props.onChange(this.state.data)
                                          })
                                        }}
                                        className='multi-input'
                                      />
                                    )
                                  : this.getInputType(d, cn) === 'number'
                                    ? (
                                        <input
                                          type='number'
                                          value={d[cn.fieldName] || ''}
                                          onChange={date => {
                                            this.setState(prev => {
                                              const temp = prev.data
                                              temp[i][cn.fieldName] = date.getTime()
                                              return {
                                                ...prev,
                                                data: temp
                                              }
                                            }, () => {
                                              this.props.onChange(this.state.data)
                                            })
                                          }}
                                          title=''
                                          checked={d[cn.fieldName] || cn.defaultValue}
                                        />
                                      )
                                    : this.getInputType(d, cn) === 'boolean' && (
                                      <Checkbox
                                        showTitle={false}
                                        className='big taxonomy-checkbox'
                                        placeholder=''
                                        value={d[cn.fieldName]}
                                        onChange={e => {
                                          this.setState(prev => {
                                            const temp = prev.data
                                            temp[i][cn.fieldName] = e.target.checked
                                            return {
                                              ...prev,
                                              data: temp
                                            }
                                          }, () => {
                                            this.props.onChange(this.state.data)
                                          })
                                        }}
                                        title=''
                                        checked={d[cn.fieldName] || cn.defaultValue}
                                      />
                                    )
                        }
                      </div>
                    )
                  })
                }
                {
                  this.props.tableStructure.isDeleteButtonEnabled
                  && (
                    <div className='table-body-cell'>
                      <img
                        className='remove-button'
                        onClick={() => {
                          let temp = this.state.data
                          temp.splice(i, 1)
                          if (temp.length === 0) {
                            const tempData = {}
                            this.props.tableStructure.columns.forEach(cn => {
                              tempData[cn.fieldName] = cn.defaultValue
                            })
                            temp = [tempData]
                          }
                          this.setState({
                            data: temp
                          }, () => {
                            this.props.onChange(this.state.data)
                          })
                        }}
                        src='/static/images/icons/hitl-close.svg'
                        title='Delete'
                        alt='delete icon'
                        style={{
                          filter: isEmpty
                            ? 'grayscale(1) opacity(.5)'
                            : 'grayscale(0) opacity(1)',
                          cursor: isEmpty
                            ? 'default'
                            : 'pointer'
                        }}
                      />
                    </div>
                  )
                }
              </div>
            )
          })}
          <a onClick={() => {
            this.setState(prev => {
              const data = prev.data
              const insertedData = {}
              this.props.tableStructure.columns.forEach(cn => {
                insertedData[cn.fieldName] = cn.defaultValue
              })
              data.push(insertedData)
              return {
                ...prev,
                data
              }
            }, () => {
              this.props.onChange(this.state.data)
            })
          }}
          >
            <div className='add-line' title={this.props.tableStructure.addButtonTitle}>
              <MaterialIcon name='add' /><span>{this.props.tableStructure.addButtonText}</span>
            </div>
          </a>
        </div>
      </div>
    )
  }
}

export default MultiInputTable

MultiInputTable.propTypes = {
  onChange: PropTypes.func,
  tableStructure: PropTypes.object,
  data: PropTypes.array
}

MultiInputTable.defaultProps = {
  onChange: () => {}
}
