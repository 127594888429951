export default new Set([
  // Audio
  'application/ogg',
  'audio/ogg',
  'audio/flac',
  'audio/mpeg',
  'audio/mpeg3',
  'audio/mp4',
  'audio/wav',
  // Video
  'video/mp4',
  'video/webm',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/x-m4v',
  // Image
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/heic',
  'image/heif',
  'image/tif',
  'image/tiff',
  'image/svg+xml',
  'image/webp',
  // PDF
  'application/pdf',
  // Zip
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',
  // HTML
  'text/html',
  // CSV
  'text/csv',
  // Text
  'text/plain',
  // Outlook
  'application/vnd.ms-outlook',
  // Microsoft Office & Open Office
  'application/vnd.oasis.opendocument.text',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.spreadsheet-template',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.presentation-template',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.text-template',
  'application/vnd.oasis.opendocument.text-web',
  'application/vnd.oasis.opendocument.text-master'
])
