// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// Layouts
import Header from '~/layout/header/Header'
import View from '~/layout/view/View'

// Contents
import FeaturesSection from '../../content/features-section/FeaturesSection'
import PartnersSection from '../../content/partners-section/PartnersSection'
import { triggerTrackingEvent } from '../../utilities/tracker'

// Sections
import HowAreWeDifferentSection from '../../content/how-are-we-different-section/HowAreWeDifferentSection'
import PartnerPage from './partner-page/PartnerPage'

// Data
import partners from '../../data/partners'
import WhyBecomeAPartnerSection from '../../content/why-become-a-partner-section/WhyBecomeAPartnerSection'
import { AppButton } from '../../interface/app-button/AppButton'

class Partners extends Component {
  static propTypes = {
    item: PropTypes.string
  }

  render () {
    const { item } = this.props
    return (
      <>
        {item && Object.keys(partners).includes(item)
          ? <PartnerPage pid={item} partner={partners[item]} />
          : (
              <View name='partners'>
                <Header name='partners'>
                  <h1 className='slogan'>Become a Base64.ai Partner</h1>
                  <h3 className='introduction'>
                    Base64.ai is an enterprise-grade, ready-to-use, and white-label<br />
                    Intelligent Document Processing solution for all automation platforms and projects.
                  </h3>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                    <AppButton className='secondary-cta large' href='/referral-program'>Referral program</AppButton>
                    <AppButton
                      className='primary-cta large'
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://meetings.hubspot.com/chris-maertz/chris-base64-partexpl'
                    >
                      Join the partner network
                    </AppButton>
                  </div>
                </Header>
                <PartnersSection
                  showHeaders={true}
                  action={() => {
                    triggerTrackingEvent('partners-page-partner-logo')
                  }}
                />
                <WhyBecomeAPartnerSection />
                <HowAreWeDifferentSection onClickAction={this.toContactSection} />
                <FeaturesSection />
              </View>
            )}
      </>
    )
  }
}

Partners.propTypes = {}

// Export within router
export default withRouter(Partners)
