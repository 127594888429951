// Modules
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
// Context
import Context from '~/context/global'
// Helpers
import { request } from '~/helpers/request'
import Header from '~/layout/header/Header'
// Interface
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import { reCaptcha } from '~/helpers/authentication'
import Webcam from 'react-webcam'
import StatusToEmoji from '~/interface/validation-sign/StatusToEmoji'
import document from 'global/document'
// Utilities
import { triggerTrackingEvent } from '../../utilities/tracker'
import { v4 as uuid } from 'uuid'

// View: Demo
class FaceTrackingDemo extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.webcamRef = React.createRef()
    this.queryImageCanvasRef = React.createRef()
    this.queryImageFigureRef = React.createRef()
    this.state = {
      redirect: false,
      responseStatus: 'init',
      responseMessage: 'Initializing webcam...',
      detectedFaces: null,
      currentImage: null,
      knownFaces: null
    }
    this.drawRects = this.drawRects.bind(this)
    this.addFace = this.addFace.bind(this)
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleUserMedia = this.handleUserMedia.bind(this)
    this.handleUserError = this.handleUserError.bind(this)
  }

  addFace (data, errorCallback) {
    request({
      endpoint: '/face',
      body: {
        ...data
      }
    }, error => {
      if (error) {
        errorCallback(error?.message || error.toString())
      } else {
        this.context.displayModal({
          title: 'Success',
          content: `${data.name} is added.`,
          options: [
            { content: 'OK' }
          ]
        })
      }
    })
  }

  componentDidMount () {
    this.id = setTimeout(() => this.setState({ redirect: true }), 1000 * 60 * 60)
    document.addEventListener('mousedown', this.handleMouseDown, false)
  }

  componentWillUnmount () {
    clearTimeout(this.id)
    document.removeEventListener('mousedown', this.handleMouseDown, false)
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      (this.state.responseMessage !== nextState.responseMessage)
      || (JSON.stringify(this.state.detectedFaces) !== JSON.stringify(nextState.detectedFaces))
      || (JSON.stringify(this.state.knownFaces) !== JSON.stringify(nextState.knownFaces))
    ) {
      this.drawRects()
    }
    return (this.state.responseMessage !== nextState.responseMessage)
  }

  requestLoop (timeout) {
    const that = this
    setTimeout(function () {
      that.makeRequest()
    }, timeout || 10)
  }

  makeRequest () {
    triggerTrackingEvent('demo-completed-facial-tracking')
    if (!this.webcamRef || !this.webcamRef.current) {
      this.requestLoop(1000)
      return
    }
    const image = this.webcamRef.current.getScreenshot()
    if (!image || image === 'data:,') {
      this.requestLoop(1000)
      return
    }

    reCaptcha('demo', token => {
      request({
        endpoint: '/face',
        body: {
          token,
          image,
          knownFaces: this.state.knownFaces
        }
      }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: error.message,
            responseStatus: 'error',
            detectedFaces: null
          })
        } else {
          if (response?.data?.length) {
            this.setState({
              responseMessage: `${response.data.length} face(s) are detected, including ${response.data.filter(r => r.isTracked).length} known face(s). Click on the unregistered faces to register them.`,
              responseStatus: 'success',
              detectedFaces: response.data,
              currentImage: image
            })
          } else {
            this.setState({
              responseMessage: 'No face detected.',
              responseStatus: 'warning',
              detectedFaces: null
            })
          }
        }
        this.requestLoop()
      }, err => {
        this.requestLoop(err)
      })
    }, err => {
      this.requestLoop(err)
    })
  }

  handleUserMedia = () => {
    this.requestLoop()
  }

  handleUserError = error => {
    if (error && error.toString().match('Permission denied')) {
      this.setState({
        responseMessage: 'Cannot launch camera. Did you enable our website to access your camera?',
        responseStatus: 'error',
        detectedFaces: null
      })
    } else if (error && error.toString().match('getUserMedia')) {
      this.setState({
        responseMessage: 'Cannot launch camera. You need to connect to the https website.',
        responseStatus: 'error',
        detectedFaces: null
      })
    } else {
      this.setState({
        responseMessage: 'Cannot launch camera.',
        responseStatus: 'error',
        detectedFaces: null
      })
    }
  }

  handleMouseDown (e) {
    const scale = 1.0 * this.queryImageCanvasRef.current.clientWidth / this.webcamRef.current.video.clientWidth
    if (e.target.className !== 'boxCanvas') {
      return
    }
    const offsetX = e.offsetX / scale
    const offsetY = e.offsetY / scale

    const detectedFaces = this.state.detectedFaces || []
    for (const face of detectedFaces) {
      const box = face.box
      if (
        box.left <= offsetX
        && offsetX <= box.left + box.width
        && box.top <= offsetY
        && offsetY <= box.top + box.height
      ) {
        if (face.isSaved) {
          alert('This face is already saved in your face database. Use "Store Face Data" to see it.')
          return
        }
        const newLabel = prompt('Please enter the name of this person:', face.label)
        if (newLabel === null || newLabel === '') {
          return
        }
        const knownFaces = this.state.knownFaces || []
        const currentImage = this.state.currentImage || null
        const wasFaceTracked = face.isTracked
        if (wasFaceTracked) {
          for (const knownFace of knownFaces) {
            if (knownFace.label === face.label) {
              knownFace.label = newLabel
              break
            }
          }
        }
        face.label = newLabel
        face.isTracked = true
        if (!wasFaceTracked) {
          knownFaces.push(face)
          reCaptcha('demo', token => {
            if (currentImage) {
              const data = {
                name: newLabel,
                uniqueId: uuid(),
                token: token,
                image: currentImage,
                isSaved: true
              }
              this.addFace(data)
            }
          })
        }
        this.setState({
          knownFaces,
          detectedFaces
        })
        return
      }
    }
  }

  drawRects () {
    const video = this.webcamRef.current
    const canvas = this.queryImageCanvasRef.current
    if (video && canvas) {
      const ctx = canvas.getContext('2d')
      canvas.width = video.video.videoWidth
      canvas.height = video.video.videoHeight
      // We want also the canvas to display de image mirrored
      ctx.translate(canvas.width, 0)
      ctx.scale(-1, 1)
      ctx.drawImage(video.video, 0, 0, canvas.width, canvas.height)
      ctx.scale(-1, 1)
      ctx.translate(-canvas.width, 0)
      const faces = this.state.detectedFaces || []
      for (const face of faces) {
        const box = face.box
        ctx.beginPath()
        ctx.rect(box.left, box.top, box.width, box.height)
        ctx.lineWidth = 3
        ctx.strokeStyle = ctx.fillStyle = face.isTracked ? '#00dddd' : '#fb327e'
        ctx.font = '20px Arial'
        ctx.textAlign = 'center'
        ctx.fillText(face.label, box.left + box.width / 2, box.top - 5)
        ctx.stroke()
      }
    }
  }

  render () {
    const { redirect, responseMessage, responseStatus } = this.state
    if (redirect) {
      alert('This demo lasts up to 1 hour. You will be redirected to demo home. Try again this demo later or contact sales@base64.ai for a longer demo.')
      return <Redirect to='/demo' />
    }
    return (
      <View name='face-tracking-demo'>
        <Header name='header'>
          <h1 className='slogan'>Camera Tracking AI</h1>
          <h2 className='introduction'>Your new AI security guard with unlimited eyes</h2>
          <h3 className='introduction'>
            Monitor all security cameras with a single AI. Connect multiple cameras, webcams, and smartphones to distinguish known people
            from intruders in real time. Get alerted when an unauthorized person enters the premises.
          </h3>
        </Header>
        <Section name='webcam' tight={true}>
          <div><StatusToEmoji status={responseStatus} message={responseMessage} /></div>
          <Webcam
            style={{
              position: 'absolute'
            }}
            ref={this.webcamRef}
            audio={false}
            mirrored={true}
            screenshotFormat='image/jpeg'
            screenshotQuality={1}
            forceScreenshotSourceSize={true}
            onUserMedia={this.handleUserMedia}
            onUserMediaError={this.handleUserError}
          />
          <canvas
            className='boxCanvas'
            ref={this.queryImageCanvasRef}
          />
        </Section>
      </View>
    )
  }
}

// Export
export default FaceTrackingDemo
