import { Prompt, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

export const NavigationPrompt = ({
  messageFunction
}) => {
  const location = useLocation()

  const unloadHandler = e => {
    const result = messageFunction(location, 'LEAVE')

    if (result !== true) {
      e.preventDefault()
      e.returnValue = result
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', unloadHandler)
    return () => {
      window.removeEventListener('beforeunload', unloadHandler)
    }
  }, [location, messageFunction])

  return (
    <Prompt
      message={messageFunction}
    />
  )
}

NavigationPrompt.propTypes = {
  /**
   * Function that returns a message to be displayed when the user tries to leave the page.
   * @returns {string | boolean} - The message to be displayed or true if the user can leave the page.
   */
  messageFunction: PropTypes.func.isRequired
}
