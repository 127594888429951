import { Component, Fragment } from 'react'
import Context from '~/context/global'
import Section from '../../../layout/section/Section'
import SortableTable from 'react-sortable-table'
import MultiEmail from '../../../interface/multi-email/MultiEmail'
import { request } from '../../../helpers/request'
import window from 'global/window'
import Loading from '../../../interface/loading/Loading'
import PropTypes from 'prop-types'
import { AppButton } from '../../../interface/app-button/AppButton'

class UserManagement extends Component {
  static contextType = Context
  static userStatus = {
    approved: 'Approved',
    pending: 'Pending approval',
    removing: 'Removing'
  }

  static propTypes = {
    user: PropTypes.object
  }

  static defaultProps = {
    user: {}
  }

  constructor (props) {
    super(props)
    this.state = {
      newUsers: [],
      affiliatedUsers: [],
      isLoaded: false,
      isInviting: false,
      removingEmail: null
    }
    this.invite = this.invite.bind(this)
    this.remove = this.remove.bind(this)
  }

  componentDidMount () {
    if (this.props.user.sponsorUserEmail) {
      return
    }
    request({
      endpoint: '/auth/organization',
      method: 'GET'
    }, (err, response) => {
      if (err) {
        this.setState({ isLoaded: true })
        return alert(err)
      }
      this.setState({ affiliatedUsers: response, isLoaded: true })
    })
  }

  invite () {
    if (this.state.isInviting) {
      return
    }
    this.setState({ isInviting: true })
    request({
      endpoint: '/auth/organization/invite',
      method: 'POST',
      body: {
        emails: this.state.newUsers
      }
    }, (err, response) => {
      this.setState({ isInviting: false })
      if (err) {
        return this.context.displayModal({
          wide: true,
          title: 'Error',
          content:
          <>
            <p>{err.message}</p>
            <br />
            {response.identifier && response.identifier
              .split(',')
              .map((s, i) =>
                <p key={i}><b>{s.split(':')[0]}:</b> {s.split(':')[1]}</p>
              )}
          </>,
          options: [
            {
              content: 'Ok',
              action: () => {
                window.location.reload()
              }
            }
          ]
        })
      }
      this.setState({
        affiliatedUsers: [
          ...this.state.affiliatedUsers,
          ...this.state.newUsers
            .filter(s => !this.state.affiliatedUsers.find(t => t.email === s))
            .map(s => ({ email: s, status: 'pending' }))
        ]
      })
    })
  }

  remove (email) {
    this.setState({
      affiliatedUsers: this.state.affiliatedUsers.map(s => {
        if (s.email === email) {
          s.status = 'removing'
        }
        return s
      })
    })
    request({
      endpoint: '/auth/organization/invite',
      method: 'POST',
      body: {
        email: email
      }
    }, err => {
      if (err) {
        return this.context.displayModal({
          title: 'Error',
          content:
          <>
            <p>{err.message}</p>
          </>,
          options: [
            {
              content: 'Ok'
            }
          ]
        })
      }
      const newUsers = this.state.affiliatedUsers.filter(s => s.email !== email)
      this.setState({ affiliatedUsers: newUsers })
    })
  }

  HEADER_STYLE = {
    padding: '0.5rem',
    whiteSpace: 'nowrap',
    margin: '0.1rem',
    backgroundColor: 'white',
    cursor: 'pointer',
    fontWeight: 'bold',
    width: '30%',
    color: '#333366',
    fontSize: '0.9rem'
  }

  DATA_STYLE = {
    verticalAlign: 'middle',
    padding: '0.5rem',
    color: '#000000',
    borderTop: '1px solid #eeeeee'
  }

  LONG_DATA_STYLE = {
    ...this.DATA_STYLE,
    whiteSpace: 'nowrap'
  }

  defaultSort = (a, b, key) => {
    return a[key] >= b[key] ? 1 : -1
  }

  sortAsc = (data, key) => {
    var result = data.slice(1).sort((a, b) => {
      return this.defaultSort(a, b, key)
    })
    return [data[0], ...result]
  }

  sortDesc = (data, key) => {
    var result = data.slice(1).sort((a, b) => {
      return this.defaultSort(b, a, key)
    })
    return [data[0], ...result]
  }

  CONFIGURATION = {
    flows: [
      {
        header: 'Email',
        key: 'email',
        headerStyle: { ...this.HEADER_STYLE, width: '60%' },
        dataStyle: { ...this.DATA_STYLE, fontWeight: '400', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '1px' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      }, {
        header: 'Status',
        key: 'status',
        renderWithData: data => {
          return <span>{UserManagement.userStatus[data.status]}</span>
        },
        headerStyle: { ...this.HEADER_STYLE, width: '30%', textAlign: 'right' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'right' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      },
      {
        header: '',
        key: 'removeButton',
        renderWithData: data => {
          return (
            <img
              className='remove-button'
              src='/static/images/icons/hitl-close.svg'
              title='Remove user from your organization'
              onClick={() => {
                this.context.displayModal({
                  title: 'Confirmation',
                  content: <p>Do you want to remove {data.email} from your organization?</p>,
                  options: [
                    {
                      content: 'Cancel',
                      intent: 'negative'
                    },
                    {
                      content: 'OK',
                      action: () => {
                        this.remove(data.email)
                      }
                    }
                  ]
                })
              }}
            />
          )
        },
        headerStyle: { ...this.HEADER_STYLE, width: '2%', textAlign: 'center', color: 'white' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' }
      }
    ]
  }

  render () {
    const { sponsorUserEmail, sponsorshipStatus } = this.props.user
    return (
      <Fragment>
        <Section name='setting' tight={true} className='settings-card'>
          {sponsorUserEmail
            ? (
                <>
                  <h2 className='slogan'>Account Sponsorship</h2>
                  {
                    sponsorshipStatus === 'pendingVerification'
                      ? (
                          <>
                            <p>
                              <a href={'mailto:' + sponsorUserEmail} title={'Send e-mail to ' + sponsorUserEmail}>{sponsorUserEmail}</a>
                      &nbsp;requested to be sponsor account. Check your email inbox to confirm this request or contact support&nbsp;
                              <a href='mailto:support@base64.ai' title='Send e-mail to support@base64.ai'>support@base64.ai.</a>
                            </p>
                          </>
                        )
                      : (
                          <p>
                            <a href={'mailto:' + sponsorUserEmail} title={'Send e-mail to ' + sponsorUserEmail}>{sponsorUserEmail}</a>
                    &nbsp;is your account sponsor. Contact your administrator or&nbsp;
                            <a href='mailto:support@base64.ai' title='Send e-mail to support@base64.ai'>support@base64.ai.</a>
                          </p>
                        )
                  }
                </>
              )
            : (
                <>
                  <h1 className='slogan'>User Management</h1>
                  <div className='invite-user-container'>
                    <MultiEmail
                      className='react-select'
                      classNamePrefix='react-select'
                      placeholder='Enter user emails to invite your organizations'
                      emails={this.state.newUsers}
                      onChange={emails => {
                        this.setState({
                          newUsers: emails.map(s => s.toLowerCase())
                        })
                      }}
                      blurBehavior='handleChange'
                      validateDomain={false}
                      style={{
                        margin: '0.3rem 0'
                      }}
                    />
                    <AppButton className='secondary-cta large min-w-28' onClick={this.invite}>{this.state.isInviting ? <Loading /> : 'Invite'}</AppButton>
                  </div>
                  {
                    this.state.isLoaded
                      ? !!this.state.affiliatedUsers.length && (
                          <SortableTable
                            data={this.state.affiliatedUsers}
                            columns={this.CONFIGURATION.flows}
                            style={{
                              borderCollapse: 'separate',
                              borderSpacing: '1px',
                              width: '100%'
                            }}
                            iconStyle={{ paddingLeft: '0.2rem' }}
                          />
                        )
                      : <Loading />
                  }
                </>
              )}

        </Section>
      </Fragment>
    )
  }
}

export default UserManagement
