import { Component } from 'react'
import PropTypes from 'prop-types'
import DocumentNavigator from './DocumentNavigator'
import checkInside from './geometry'
import { withRouter } from 'react-router-dom'
import Tippy from '@tippyjs/react'
import RemoveButton from '../remove-button/RemoveButton'

const HighlightColor = {
  TRANSPARENT: '#00000000',
  YELLOW: '#FFFF00A0',
  POSTIT_YELLOW: '#FEFF9C',
  GREEN: '#CCFF00A0',
  PINK: '#FFC0CBA0',
  CYAN: '#00FFFFA0'
}

class ImageViewer extends Component {
  static propTypes = {
    images: PropTypes.array,
    annotations: PropTypes.array,
    selectionChangeHandler: PropTypes.func,
    mode: PropTypes.string,
    context: PropTypes.object,
    contextProvider: PropTypes.object,
    location: PropTypes.object, // URL
    startPage: PropTypes.number
  }

  static defaultProps = {
    annotations: []
  }

  constructor (props) {
    super(props)
    this.state = {
      activeIndex: 0,
      annotations: this.props.annotations,
      staticAnnotations: [],
      containerContext: null,
      images: this.props.images,
      imageContexts: [],
      isFullscreen: false,
      isInit: true,
      isResize: false,
      rotationAngle: 0,
      mode: this.props.mode,
      onFirstDraw: undefined,
      selectedAnnotations: new Set()
    }

    this.getContainerContext = this.getContainerContext.bind(this)
    this.getImageWidth = this.getImageWidth.bind(this)
    this.getImageHeight = this.getImageHeight.bind(this)
    this.setImageToCanvas = this.setImageToCanvas.bind(this)
    this.draw = this.draw.bind(this)
    this.drawImage = this.drawImage.bind(this)
    this.calculateRotatedContext = this.calculateRotatedContext.bind(this)
    this.drawAnnotations = this.drawAnnotations.bind(this)
    this.mouseEventHandler = this.mouseEventHandler.bind(this)
    this.isMousePointerInside = this.isMousePointerInside.bind(this)
    this.getMousePosition = this.getMousePosition.bind(this)
    this.drawSelectionArea = this.drawSelectionArea.bind(this)
    this.clearSelectionsHandler = this.clearSelectionsHandler.bind(this)
    this.clearAnnotations = this.clearAnnotations.bind(this)
    this.clearAnnotation = this.clearAnnotation.bind(this)
    this.isCorrectPage = this.isCorrectPage.bind(this)
    this.renderStaticAnnotationOverlay = this.renderStaticAnnotationOverlay.bind(this)
  }

  componentDidMount () {
    this.draw()
    window.addEventListener('resize', this.draw)

    this.prepareUrlParams()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.draw)
    document.querySelector('#image-viewer-canvas')?.removeEventListener('mousemove', e => this.mouseEventHandler(e))
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.images !== this.props.images) {
      this.setImageToCanvas()
    }

    if (this.state.containerContext?.height && prevState.containerContext?.height !== this.state.containerContext?.height) {
      this.props.contextProvider.setState({ canvasHeight: this.state.containerContext?.height })
    }
    if (this.state.containerContext?.offsetTop && prevState.containerContext?.offsetTop !== this.state.containerContext?.offsetTop) {
      this.props.contextProvider.setState({ canvasOffsetTop: this.state.containerContext?.offsetTop })
    }

    this.props.context.activeIndex = this.state.activeIndex
    if (this.props.context?.annotationsFromUrl !== prevProps.context?.annotationsFromUrl) {
      this.addAnnotations()
    }
    if (this.state.staticAnnotations != prevState.staticAnnotations) {
      let annotations
      if (this.state.onFirstDraw) {
        annotations = this.state.staticAnnotations
      } else {
        annotations = [...new Set([...this.state.staticAnnotations, ...this.state.annotations])]
      }
      this.setState({ annotations }, this.draw)
    }
    if (this.props.context.mode !== prevProps.context.mode) {
      if (this.props.context.annotationType === 'dom') {
        this.clearAnnotations()
      }
      const nextState = {
        previousMode: this.state.mode,
        mode: this.props.context.annotationType || 'dom',
        annotations: this.props.context.annotationType === 'dom'
          ? this.props.annotations
          : []
      }
      if (this.props.context.annotationType !== 'dom' && this.props.context?.selectionLocation) {
        nextState.annotations = [{ event: 'selected', location: this.props.context?.selectionLocation }]
        nextState.activeIndex = this.props.context?.selectionRelativeIndex || 0
        nextState.mode = 'result'
      }
      nextState.annotations = [...this.state.staticAnnotations, ...nextState.annotations]
      this.setState(nextState, this.draw)
    }
    if (this.props.context?.selectionLocation !== prevProps.context?.selectionLocation) {
      if (!this.props.context.selectionLocation) {
        const nextState = { mode: 'view' }
        if (this.state.staticAnnotations?.length) {
          nextState.mode = 'result'
          nextState.annotations = this.state.staticAnnotations
        }
        this.setState(nextState, this.draw)
        return
      }
      if (this.props.context?.isSelected) {
        const rotatedPosition = this.getAnnotationPositionOnWindowSpace(this.props.context?.selectionLocation.topRight)
        const rotatedPositionBottom = this.getAnnotationPositionOnWindowSpace(this.props.context?.selectionLocation.bottomRight)
        this.props.contextProvider.setState({
          selectionCanvasLocation: {
            x: rotatedPosition.x + (this.state.containerContext?.offsetLeft || 0),
            y: rotatedPosition.y + (Math.abs(rotatedPositionBottom.y - rotatedPosition.y) / 2) - (this.state.containerContext?.offsetTop || 0)
          }
        })
        const pageNumber = this.props.context?.selectionLocation?.pageNumber - this.props.startPage
        const nextState = {
          previousMode: this.state.mode,
          annotations: [{ event: 'selected', location: this.props.context?.selectionLocation }],
          activeIndex: this.props.context?.selectionRelativeIndex || pageNumber || 0,
          mode: 'result'
        }
        nextState.annotations = [...this.state.staticAnnotations, ...nextState.annotations]
        this.setState(nextState, this.draw)
      } else {
        const nextState = {
          mode: this.state.previousMode || this.props.mode,
          annotations: this.props.annotations || []
        }
        nextState.annotations = [...this.state.staticAnnotations, ...nextState.annotations]
        this.setState(nextState, this.draw)
      }
    }
  }

  addAnnotations () {
    const nextState = {
      staticAnnotations: [],
      activeIndex: undefined,
      mode: 'result',
      previousMode: this.state.mode
    }

    this.props.context?.annotationsFromUrl.forEach(annotation => {
      annotation.color = this.props.context?.selectionHighlightColor || HighlightColor.YELLOW

      if (!annotation.location) {
        const naturalDimensions = this.getNaturalDimensions(this.props.context.results[0])
        const width = naturalDimensions?.width || 0
        const height = naturalDimensions?.height || 0
        const MARGIN = 30

        annotation.location = {
          pageNumber: 0,
          topLeft: { x: 0, y: MARGIN },
          topRight: { x: width, y: MARGIN },
          bottomRight: { x: width, y: height + 5 - MARGIN },
          bottomLeft: { x: 0, y: height + 5 - MARGIN }
        }

        annotation.color = HighlightColor.TRANSPARENT
        annotation.tippyOffset = 40
        annotation.tippyTheme = 'postit'
        annotation.noScroll = true
      }

      const pageNumber = annotation.location.pageNumber - this.props.startPage
      if (nextState.activeIndex === undefined || nextState.activeIndex > pageNumber) {
        nextState.activeIndex = pageNumber
      }

      nextState.staticAnnotations.push({
        event: 'disabled',
        ...annotation,
        style: this.state.onFirstDraw
          ? {
              fill: {
                color: annotation.color
              }
            }
          : undefined
      })
    })
    this.setState(nextState, this.draw)
  }

  prepareUrlParams () {
    // Access query params from the URL
    const queryParams = new URLSearchParams(this.props.location.search)

    // Get specific parameters
    const annotationsStr = queryParams.get('annotations')
    let annotationsFromUrl

    try {
      annotationsFromUrl = annotationsStr ? JSON.parse(decodeURIComponent(annotationsStr)) : []
    } catch (error) {
      console.error('Error parsing location', error)
    }

    if (annotationsFromUrl?.length) {
      this.setState({
        onFirstDraw: () => {
          this.props.contextProvider.setState({
            annotationsFromUrl
          })

          setTimeout(() => {
            const annotation = annotationsFromUrl[0]
            const location = annotation?.location || annotation

            if (!location) {
              return
            }

            const rotatedPosition = this.getAnnotationPositionOnWindowSpace(location.topRight)
            const rotatedPositionBottom = this.getAnnotationPositionOnWindowSpace(location.bottomRight)

            const top = annotation?.noScroll
              ? 0
              : rotatedPosition.y + (Math.abs(rotatedPositionBottom.y - rotatedPosition.y) / 2) - (this.state.containerContext?.offsetTop || 0)

            if (rotatedPosition && rotatedPositionBottom) {
              window.scrollTo({
                left: 0,
                top,
                behavior: 'smooth'
              })
            }
          }, 100)
        }
      })
    }
  }

  getTooltipPosition (annotation) {
    const topRightPosition = this.getAnnotationPositionOnWindowSpace(annotation.topRight)
    const topLeftPosition = this.getAnnotationPositionOnWindowSpace(annotation.topLeft)
    const bottomLeftPosition = this.getAnnotationPositionOnWindowSpace(annotation.bottomLeft)

    const canvas = document.getElementById('image-viewer-canvas')

    const width = topRightPosition.x - topLeftPosition.x
    const height = bottomLeftPosition.y - topLeftPosition.y

    return {
      top: topLeftPosition.y + (canvas.offsetTop || 0) - 5,
      left: topLeftPosition.x + (canvas.offsetLeft || 0) - 9,
      width,
      height
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextState.isInit || !!nextState.containerContext?.height
  }

  mouseEventHandler (event) {
    if (!['dom', 'image'].includes(this.state.mode)) {
      return
    }
    const selectionArea = this.getSelectionArea(event)
    let isChanged = false
    let isSelectionChanged = false
    const mouse = this.getMousePosition(event)
    if (!mouse) {
      return
    }

    if (event.type === 'dblclick') {
      let isAllowed = true
      this.state.annotations.forEach(annotation => {
        if (!this.isCorrectPage(annotation)) {
          return
        }
        const isInside = this.isMousePointerInside(this.getRotatedLocation(annotation, this.state.containerContext), mouse)
        if (isInside) {
          isAllowed = false
        }
      })
      return isAllowed && this.clearSelectionsHandler()
    }

    if (selectionArea?.isValid) {
      this.state.annotations.forEach(annotation => {
        if (!this.isCorrectPage(annotation)) {
          return
        }
        const isInsideOfSelectionArea = this.isAnnotationBoxInsideSelectedArea(this.getRotatedLocation(annotation, this.state.containerContext), selectionArea, this.state.containerContext, annotation)
        if (isInsideOfSelectionArea && !['selected', 'disabled'].includes(annotation.event)) {
          annotation.event = 'selected'
          annotation.style = annotation.style || {}
          annotation.style.stroke = { lineWidth: 2, dashPattern: [4, 4] }
          annotation.style.fill = { color: '#00dddd66' }
          isChanged = true
          isSelectionChanged = true
        } else if (!isInsideOfSelectionArea && annotation.event === 'selected') {
          isChanged = true
          isSelectionChanged = true
          if (!event.shiftKey) {
            this.clearAnnotation(annotation)
          }
        }
      })
    } else {
      this.state.annotations.forEach(annotation => {
        if (!this.isCorrectPage(annotation)) {
          return
        }
        const isInside = this.isMousePointerInside(this.getRotatedLocation(annotation, this.state.containerContext), mouse)
        if (event.type === 'mouseup') {
          if (isInside && !['selected', 'disabled'].includes(annotation.event)) {
            annotation.event = 'selected'
            annotation.style = annotation.style || {}
            annotation.style.stroke = { lineWidth: 2, dashPattern: [4, 4] }
            annotation.style.fill = { color: '#00dddd66' }
            isChanged = true
            isSelectionChanged = true
          } else if (isInside && annotation.event === 'selected') {
            isChanged = true
            isSelectionChanged = true
            this.clearAnnotation(annotation)
          }
        } else if (event.type === 'mousemove' && !['selected', 'disabled'].includes(annotation.event)) {
          if (isInside && annotation.event !== 'mouseover') {
            annotation.event = 'mouseover'
            annotation.style = annotation.style || {}
            annotation.style.stroke = { lineWidth: 2, dashPattern: [4, 4] }
            isChanged = true
          } else if (!isInside && annotation.event === 'mouseover') {
            isChanged = true
            this.clearAnnotation(annotation)
          }
        }
      })
    }

    if (
      isSelectionChanged
      && this.props.selectionChangeHandler
      && this.state.mode === 'dom'
    ) {
      this.props.selectionChangeHandler(this.state.annotations.filter(annotation => annotation.event === 'selected'), event)
    }

    if (
      this.props.selectionChangeHandler
      && event.type === 'mouseup'
      && !selectionArea?.isValid
      && this.state.selectionArea?.isValid
      && this.state.mode === 'image'
    ) {
      this.props.selectionChangeHandler(this.calculateOriginalBox(this.state.selectionArea), event)
    }

    if (isChanged || selectionArea) {
      this.setState({
        selectionArea,
        annotations: this.state.annotations,
        hasSelectedAnnotation: this.state.annotations.some(annotation => annotation.event === 'selected')
      }, this.draw)
    }
  }

  draw () {
    if (this.state.isResize) {
      return
    }
    this.setState({ containerContext: this.getContainerContext(), isResize: true }, this.setImageToCanvas)
  }

  drawCleanRect (ctx, x, y, w, h) {
    ctx.fillStyle = 'white'
    ctx.rect(x, y, w, h)
    ctx.fill()
  }

  drawImage (ctx, image) {
    const rotatedContext = this.calculateRotatedContext(image)
    ctx.clearRect(0, 0, rotatedContext.width, rotatedContext.height)
    ctx.save()
    const transformMatrix = ctx.getTransform()
    const transformWidth = Math.floor(rotatedContext.width / 2)
    const transformHeight = Math.floor(rotatedContext.height / 2)
    if (Math.floor(transformMatrix.e) !== transformWidth && Math.floor(transformMatrix.f) !== transformHeight) {
      ctx.translate(rotatedContext.width / 2, rotatedContext.height / 2)
      ctx.rotate(rotatedContext.rotationAngle * Math.PI / 180)
    }

    if (this.isPortrait(rotatedContext.rotationAngle)) {
      this.drawCleanRect(ctx, -transformWidth, -transformHeight, rotatedContext.width, rotatedContext.height)
      ctx.drawImage(image, -transformWidth, -transformHeight, rotatedContext.width, rotatedContext.height)
    } else {
      this.drawCleanRect(ctx, -transformHeight, -transformWidth, rotatedContext.height, rotatedContext.width)
      ctx.drawImage(image, -transformHeight, -transformWidth, rotatedContext.height, rotatedContext.width)
    }

    ['dom', 'result'].includes(this.state.mode) && this.drawAnnotations(ctx, rotatedContext)
    this.drawSelectionArea(ctx, this.state.selectionArea)

    this.setState({ isResize: false })
  }

  drawAnnotations (ctx, rotatedContext) {
    this.state.annotations.forEach(annotation => {
      if (!this.isCorrectPage(annotation)) {
        return
      }
      this.drawRectangle(ctx, this.getRotatedLocation(annotation, rotatedContext), annotation.style)
    })
  }

  drawRectangle (ctx, location, style) {
    style = style || {}
    style.padding = style.padding || 1
    style.fill = style.fill || {}
    if (style.fill) {
      ctx.fillStyle = style.fill.color || '#00dddd4d'
    }
    if (style.stroke) {
      ctx.strokeStyle = style.stroke.color || '#00dddd'
      ctx.lineWidth = style.stroke.lineWidth || 5
      ctx.setLineDash(style.stroke.dashPattern || [])
    }
    ctx.beginPath()
    ctx.moveTo(location.topLeft.x - style.padding, location.topLeft.y - style.padding)
    ctx.lineTo(location.topRight.x + style.padding, location.topRight.y - style.padding)
    ctx.lineTo(location.bottomRight.x + style.padding, location.bottomRight.y + style.padding)
    ctx.lineTo(location.bottomLeft.x - style.padding, location.bottomLeft.y + style.padding)
    ctx.lineTo(location.topLeft.x - style.padding, location.topLeft.y - style.padding)
    ctx.closePath()
    if (style.fill) {
      ctx.fill()
    }
    if (style.stroke) {
      ctx.stroke()
    }
  }

  drawSelectionArea (ctx, area) {
    if (!ctx || !area) {
      return
    }
    ctx.fillStyle = '#00dddd1a'
    ctx.fillRect(area.left, area.top, area.width, area.height)
  }

  clearAnnotations () {
    this.props.annotations.forEach(this.clearAnnotation)
  }

  clearAnnotation (annotation, isForced) {
    if (annotation.event === 'disabled' && !isForced) {
      return
    }
    delete annotation.style?.stroke
    delete annotation.style?.fill
    delete annotation.event
  }

  getContainerContext () {
    const container = document.querySelector('.image-viewer-container')
    return {
      width: container?.clientWidth,
      offsetTop: container?.offsetTop,
      offsetLeft: container?.offsetLeft
    }
  }

  getSelectionArea (event) {
    const nullArea = { top: 0, left: 0, with: 0, height: 0, isValid: null }
    if (event.type !== 'mousemove' && event.type !== 'mouseup') {
      return nullArea
    } else if (event.type === 'mouseup' && this.state.selectionAreaStartPosition) {
      return nullArea
    }
    const mouse = this.getMousePosition(event)
    if (!mouse) {
      return nullArea
    }
    if (!this.state.selectionAreaStartPosition && event.buttons === 1) {
      this.setState({ selectionAreaStartPosition: mouse })
      return nullArea
    } else if (this.state.selectionAreaStartPosition && event.buttons === 0) {
      this.setState({ selectionAreaStartPosition: false })
      return nullArea
    } else if (this.state.selectionAreaStartPosition && event.buttons === 1) {
      let width = mouse.x - this.state.selectionAreaStartPosition.x
      let height = mouse.y - this.state.selectionAreaStartPosition.y
      let top = this.state.selectionAreaStartPosition.y
      let left = this.state.selectionAreaStartPosition.x
      if (width < 0) {
        left = left + width
      }
      if (height < 0) {
        top = top + height
      }
      width = Math.abs(width)
      height = Math.abs(height)
      return {
        top,
        left,
        width,
        height,
        isValid: true
      }
    }
  }

  getRotatedLocation (annotation, rotatedContext) {
    const calculatedLocation = {}
    Object.keys(annotation.location).forEach(key => {
      if (!['topLeft', 'topRight', 'bottomRight', 'bottomLeft'].includes(key)) {
        return
      }
      calculatedLocation[key] = calculatedLocation[key] || {}
      calculatedLocation[key] = { ...this.getRotatedPosition(annotation.location[key].x, annotation.location[key].y, rotatedContext) }
    })
    return calculatedLocation
  }

  getRotatedPosition (x, y, rotatedContext) {
    const ratio = rotatedContext.ratio
    return this.isPortrait(rotatedContext.rotationAngle)
      ? {
          x: x * ratio - (rotatedContext.width / 2),
          y: y * ratio - (rotatedContext.height / 2)
        }
      : {
          x: x * ratio - (rotatedContext.height / 2),
          y: y * ratio - (rotatedContext.width / 2)
        }
  }

  getOriginalPosition (x, y, rotatedContext) {
    const ratio = rotatedContext.ratio
    return this.isPortrait(rotatedContext.rotationAngle)
      ? {
          x: (x + (rotatedContext.width / 2)) / ratio,
          y: (y + (rotatedContext.height / 2)) / ratio
        }
      : {
          x: (x + (rotatedContext.height / 2)) / ratio,
          y: (y + (rotatedContext.width / 2)) / ratio
        }
  }

  getImageWidth () {
    return this.state.containerContext?.width
  }

  getImageHeight () {
    return this.state.containerContext?.height
  }

  getAnnotationPositionOnWindowSpace (coordinate) {
    const context = this.state.containerContext
    if (!context?.height || !coordinate) {
      return
    }
    const padding = 10
    const position = {
      x: coordinate.x * context.ratio + padding,
      y: coordinate.y * context.ratio
    }
    let rotatedPosition = {}
    const radian = context.rotationAngle * Math.PI / 180
    if (context.rotationAngle === -90) {
      rotatedPosition = {
        x: position.x * Math.cos(radian) - position.y * Math.sin(radian),
        y: position.x * Math.sin(radian) + position.y * Math.cos(radian) + context.height - padding
      }
    } else if (context.rotationAngle === -180) {
      rotatedPosition = {
        x: position.x * Math.cos(radian) - position.y * Math.sin(radian) + context.width - padding,
        y: position.x * Math.sin(radian) + position.y * Math.cos(radian) + context.height - padding
      }
    } else if (context.rotationAngle === -270) {
      rotatedPosition = {
        x: position.x * Math.cos(radian) - position.y * Math.sin(radian) + context.width,
        y: position.x * Math.sin(radian) + position.y * Math.cos(radian) + padding
      }
    } else {
      return position
    }
    return rotatedPosition
  }

  getMousePosition (event) {
    const context = this.state.containerContext
    if (!context?.height) {
      return
    }
    const position = {
      x: event.offsetX - (context.width / 2),
      y: event.offsetY - (context.height / 2)
    }
    let rotatedPosition = {}
    if (context.rotationAngle === -90) {
      rotatedPosition = {
        x: 0 - position.y,
        y: position.x
      }
    } else if (context.rotationAngle === -180) {
      rotatedPosition = {
        x: 0 - position.x,
        y: 0 - position.y
      }
    } else if (context.rotationAngle === -270) {
      rotatedPosition = {
        x: position.y,
        y: 0 - position.x
      }
    } else {
      return position
    }
    return rotatedPosition
  }

  isPortrait (angle) {
    return angle === 0 || angle === -180
  }

  getCenter (location) {
    const w1 = Math.abs(location.bottomRight.x - location.topLeft.x)
    const h1 = Math.abs(location.bottomRight.y - location.topLeft.y)
    const w2 = Math.abs(location.bottomLeft.x - location.topRight.x)
    const h2 = Math.abs(location.bottomLeft.y - location.topRight.y)
    const w = w1 > w2 ? w1 : w2
    let cx, cy, h
    if (w === w1) {
      h = h1
      cx = (location.bottomRight.x > location.topLeft.x ? location.topLeft.x : location.bottomRight.x) + w1 / 2
      cy = (location.bottomRight.y > location.topLeft.y ? location.topLeft.y : location.bottomRight.y) + h1 / 2
    } else {
      h = h2
      cx = (location.bottomLeft.x > location.topRight.x ? location.topRight.x : location.bottomLeft.x) + w2 / 2
      cy = (location.bottomLeft.y > location.topRight.y ? location.topRight.y : location.bottomLeft.y) + h2 / 2
    }
    return { x: cx, y: cy, w, h }
  }

  isCorrectPage (annotation) {
    const pageNumber = annotation.location.pageNumber - this.props.startPage
    return pageNumber === this.state.activeIndex
  }

  isMousePointerInside (location, mouse) {
    if (location.topLeft.x === location.topRight.x || location.bottomLeft.x === location.bottomRight.x) {
      return false
    }
    return checkInside([location.topLeft, location.topRight, location.bottomLeft, location.bottomRight], mouse)
  }

  isAnnotationBoxInsideSelectedArea (location, selectionArea, context, annotation) {
    if (!selectionArea?.isValid) {
      return false
    }
    const { x, y } = this.getCenter(location)
    const { width, height, top, left } = selectionArea
    return x > left && x < left + width && y > top && y < top + height
  }

  calculateOriginalBox (selectionArea) {
    const { width, height, top, left } = selectionArea
    const topLeftOriginalPosition = this.getOriginalPosition(left, top, this.state.containerContext)
    const bottomRightOriginalPosition = this.getOriginalPosition(left + width, top + height, this.state.containerContext)
    const originalHeight = Math.abs(topLeftOriginalPosition.y - bottomRightOriginalPosition.y)
    const originalWidth = Math.abs(topLeftOriginalPosition.x - bottomRightOriginalPosition.x)
    return { left: topLeftOriginalPosition.x, top: topLeftOriginalPosition.y, height: originalHeight, width: originalWidth, pageNumber: this.state.activeIndex }
  }

  getNaturalDimensions (result) {
    const pageIndex = this.state.activeIndex
    const width = result.features.dom?.pages?.[pageIndex]?.properties?.width || result.features.properties.width
    const height = result.features.dom?.pages?.[pageIndex]?.properties?.height || result.features.properties.height
    return { width, height }
  }

  calculateRotatedContext (image, canvas) {
    const rotationAngle = this.state.rotationAngle % 360
    const width = this.state.containerContext.width
    const result = this.props.context.results[0]
    let height = 0
    let ratio = 1
    const naturalDimensions = this.getNaturalDimensions(result)
    if (this.isPortrait(rotationAngle)) {
      ratio = width / naturalDimensions.width
      height = naturalDimensions.height * ratio
    } else {
      ratio = width / naturalDimensions.height
      height = naturalDimensions.width * ratio
    }
    return {
      width,
      height,
      rotationAngle,
      ratio,
      offsetTop: canvas?.offsetTop,
      offsetLeft: canvas?.offsetLeft
    }
  }

  setImageToCanvas () {
    const canvas = document.querySelector('#image-viewer-canvas')
    if (!canvas) {
      return
    }
    const ctx = canvas.getContext('2d')
    const image = new Image()
    image.onload = () => {
      const rotatedContext = this.calculateRotatedContext(image, canvas)
      if (this.state.containerContext.height !== rotatedContext.height) {
        this.drawImage(ctx, image)
        if (!this.state.isEventAttached) {
          canvas.addEventListener('mousemove', e => this.mouseEventHandler(e))
          canvas.addEventListener('mouseup', e => this.mouseEventHandler(e))
          canvas.addEventListener('dblclick', e => this.mouseEventHandler(e))
        }
        this.setState({
          containerContext: this.calculateRotatedContext(image, canvas),
          isEventAttached: true,
          isInit: false
        }, () => this.setImageToCanvas())
      }

      if (this.state.onFirstDraw) {
        this.state.onFirstDraw()
        this.setState({ onFirstDraw: undefined })
      }
    }
    image.src = this.state.images[this.state.activeIndex]
    const rotatedContext = this.calculateRotatedContext(image, canvas)
    if (this.state.containerContext.height === rotatedContext.height) {
      this.drawImage(ctx, image)
    }
  }

  clearSelectionsHandler () {
    this.state.annotations.forEach(annotation => {
      if (annotation.event === 'selected') {
        this.clearAnnotation(annotation)
      }
    })
    this.props.selectionChangeHandler([])
    this.setState({ annotations: this.state.annotations, hasSelectedAnnotation: false }, this.draw)
  }

  renderStaticAnnotationOverlay () {
    return this.state.staticAnnotations
      .filter(annotation => annotation.location.pageNumber === this.state.activeIndex)
      .map((annotation, index) => (
        <Tippy
          key={'sa-' + index}
          content={(
            <div className='min-w-40'>
              <div className='just-flex justify-between items-center'>
                <div className='font-bold'>{annotation.question || 'Highlighted source'}</div>
                <RemoveButton
                  className='text-sm'
                  onRemove={() => {
                    this.setState({
                      annotations: this.state.annotations.filter(s => s != annotation),
                      staticAnnotations: this.state.staticAnnotations.filter(s => s != annotation)
                    })
                  }}
                />
              </div>
              <div>{annotation.answer}</div>
            </div>
          )}
          animation='shift-away'
          arrow={false}
          interactive={true}
          offset={annotation?.tippyOffset ? [0, annotation.tippyOffset] : undefined}
          theme={annotation?.tippyTheme}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              ...this.getTooltipPosition(annotation.location)
            }}
          />
        </Tippy>
      ))
  }

  render () {
    const isMediaAudio = this.props.images?.[0]?.match(/^data:audio\//)
    const isMediaVideo = this.props.images?.[0]?.match(/^data:video\//)
    return (
      <div
        className={`image-viewer-fullscreen-controller ${
          this.state.isFullscreen ? 'open' : ''
        }`}
      >
        <div
          className='image-viewer-container'
          style={{ height: this.getImageHeight() + 'px' }}
        >
          {this.state.containerContext
          && (isMediaAudio
            ? (
                <audio src={this.props.images[0]} controls preload='auto'>
                  Your browser does not support the audio tag.
                </audio>
              )
            : isMediaVideo
              ? (
                  <video src={this.props.images[0]} controls>
                    Your browser does not support the video tag.
                  </video>
                )
              : (
                  <>
                    <DocumentNavigator
                      activeIndex={this.state.activeIndex}
                      pageCount={this.state.images.length}
                      rotateHandler={() => {
                        this.setState(
                          { rotationAngle: this.state.rotationAngle - 90 },
                          this.draw
                        )
                      }}
                      nextPageHandler={() => {
                        if (this.state.activeIndex < this.state.images.length - 1) {
                          this.setState(
                            { activeIndex: this.state.activeIndex + 1 },
                            this.draw
                          )
                        }
                      }}
                      prevPageHandler={() => {
                        if (this.state.activeIndex > 0) {
                          this.setState(
                            { activeIndex: this.state.activeIndex - 1 },
                            this.draw
                          )
                        }
                      }}
                      setPageHandler={index => {
                        this.setState({ activeIndex: index }, this.draw)
                      }}
                      hasSelectedAnnotation={this.state.hasSelectedAnnotation}
                      clearSelectionsHandler={this.clearSelectionsHandler}
                      isFullscreen={this.state.isFullscreen}
                      fullscreenHandler={() => {
                        this.setState(
                          { isFullscreen: !this.state.isFullscreen },
                          this.draw
                        )
                      }}
                      resultUuid={this.props.context?.resultUuid}
                      linkedResults={this.props.context?.linkedResults}
                    />
                    {this.renderStaticAnnotationOverlay()}
                    <canvas
                      id='image-viewer-canvas'
                      className='image-viewer-image'
                      width={this.getImageWidth()}
                      height={this.getImageHeight()}
                    />
                  </>
                ))}
        </div>
      </div>
    )
  }
}

export default withRouter(ImageViewer)
