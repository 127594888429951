// Layout
import View from '~/layout/view/View'
import Section from '~/layout/section/Section'
import Block from '~/layout/block/Block'
import Header from '~/layout/header/Header'

// View: Terms of Service
const TermsOfService = () => (
  <View name='terms-of-service'>
    {/* Title */}
    <Header tight={true}>
      <h1>Terms of Service</h1>
    </Header>
    {/* Agreement */}
    <Section
      name='agreement'
      align='left'
    >
      <Block
        name='welcome'
        identifier={true}
      >
        <p>Last updated: Sep 6, 2023</p>
        <p>
          This Terms of Service (the &ldquo;Agreement&rdquo;) contains the terms of your access
          and use of the Base64.ai Software as a Service product and all related services (the &ldquo;Service&rdquo;)
          provided by Base64.ai Inc. (&ldquo;Base64.ai&rdquo;). By accepting the terms and conditions or using the
          Service, you agree to be bound by this Agreement. You agree that your access to the Service may be
          terminated by Base64.ai at its sole discretion at any time for any reason except as otherwise agreed in
          writing between you and Base64.ai.
        </p>
        <p>
          IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY,
          YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THE ENTITY TO THIS AGREEMENT, IN WHICH CASE
          &ldquo;YOU&rdquo; WILL MEAN THE ENTITY YOU REPRESENT.
        </p>
      </Block>

      <Block
        name='access-to-service'
        identifier={true}
      >
        <h3>Access to Service</h3>
        <p>
          Base64.ai hereby grants you a non-exclusive, non-transferable right to access and use
          the Service solely for use by you in accordance with the terms and conditions of this Agreement.
        </p>
        The Standard Service features include:
        <ol className='alphabetic indented'>
          <li>Classify document types</li>
          <li>Extract data</li>
          <li>Validate results</li>
          <li>Document data redaction</li>
          <li>API access</li>
        </ol>
        The Ultimate Service features include everything in the Standard Service, plus:
        <ol className='alphabetic indented'>
          <li>Workflow management (Flow)</li>
          <li>Data storage and retention rules</li>
          <li>Facial and signature recognition and verification</li>
          <li>No-code integration framework</li>
          <li>Data extraction with Question & Answers</li>
        </ol>
        <br />
        <p><span>The Services are further described at <a href='https://base64.ai' target='_blank' rel='noopener noreferrer'>https://base64.ai</a>.</span></p>
      </Block>
      <Block
        name='restriction-on-use'
        identifier={true}
      >
        <h3>Restrictions on Use</h3>
        <p>
          You agree that you will not use the Service for any purpose beyond that granted in
          this Agreement and the Services Agreement, where applicable. This means that you will not at any time,
          directly or indirectly, and will not permit any person or entity to:
        </p>
        <ul>
          <li>
            Copy, modify, or create derivative works of the Service, in whole or
            in part.
          </li>
          <li>
            Reverse engineer, disassemble, decompile, decode, adapt, or
            otherwise attempt to derive or gain access to any software component of our intellectual property,
            including any attempts to discover the underlying code, structure, implementation, or algorithms of the
            Service.
          </li>
          <li>
            Use the Service to develop, create, or permit others to develop or
            create a product or service similar or competitive to our intellectual property, particularly our
            Service.
          </li>
          <li>
            Make the Service available to third parties, including by reselling,
            licensing, renting, leasing, transferring, lending, timesharing, assigning, or redistributing our
            intellectual property.
          </li>
          <li>
            Use the Service or our intellectual property in any manner or for
            any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or
            other right of any person or that violates any applicable law.
          </li>
        </ul>
      </Block>
      <Block
        name='responsibility'
        identifier={true}
      >
        <h3>Responsibility</h3>
        <p>As used in this Agreement, &ldquo;You&rdquo; includes all of your employees,
          contractors, agents, third-party customers, and clients that use the Service or on whose behalf you use the
          Service. You hereby agree that you are fully responsible for the actions and omissions of such third parties
          as if you had acted or failed to act yourself.
        </p>
      </Block>
      <Block
        name='reservation-of-rights'
        identifier={true}
      >
        <h3>Reservation of Rights</h3>
        <p>
          We reserve all rights not expressly granted to you in this Agreement and the SaaS
          Agreement, where applicable. Except for the limited rights and licenses expressly granted in this Agreement
          and the Services Agreement, where applicable, nothing in this Agreement grants to you or any third party any
          intellectual property rights or other right, title, or interest in or to the Service.
        </p>
      </Block>
      <Block
        name='pricing'
        identifier={true}
      >
        <h3>Pricing</h3>
        <p>You will pay us the fees set forth in your Account dashboard and on the Service or in
          a signed Order Form between you and Base64.ai (the &ldquo;Fees&rdquo;). All Fees will be in US dollars
          unless otherwise agreed. These Fees are a recurring subscription on a monthly or annual basis (as chosen by
          you or agreed upon in the Order Form). They are being prepaid in advance of our granting access to the
          Service for the agreed-upon subscription period, beginning with the Fee payment date. All Fees are
          non-refundable, except to the extent agreed in the Base64.ai Service Level Agreement agreed by you and
          Base64.ai.
        </p>
        <p>Depending on the service level you choose in your Account dashboard or the Order
          Form, if you reach the limit of allowed services for that level, we will pause the Service until your next
          billing cycle, or you may have the option to upgrade your Account&rsquo;s service level or add additional
          credits to accommodate additional services. If you have any questions, please contact us at <span><a
          href='mailto:support@base64.ai'
                                                                                                            >support@base64.ai
        </a>
        </span>
        </p>
        <p>All Fees and amounts payable by you under this Agreement do not include taxes and
          similar assessments. You are responsible for all sales, use, and excise taxes, and any other similar taxes,
          duties, and charges of any kind imposed by any federal, state, or local governmental or regulatory authority
          on any amounts payable by you under this Agreement.
        </p>
      </Block>
      <Block
        name='feedback'
        identifier={true}
      >
        <h3>Feedback</h3>
        <p>If you or any of your employees or contractors send or transmit any communications or
          materials to us suggesting or recommending changes to the Service, including new features or functionality,
          or any comments, questions, suggestions, or the like (together, the &ldquo;Feedback&rdquo;), we are free to
          use such feedback irrespective of any other obligation or limitation. You hereby assign to us on your behalf
          and on behalf of your employees, contractors, and agents all rights, titles, and interests. We are free to
          use, without any attribution or compensation to any party, any ideas, know-how, concepts, techniques, or
          other intellectual property rights contained in the Feedback for any purpose whatsoever.
        </p>
      </Block>
      <Block
        name='service-modification'
        identifier={true}
      >
        <h3>Service Modifications</h3>
        <p>Except as otherwise agreed in the Order Form or Service Level Agreement, if
          applicable:
        </p>
        <ol>
          <li>We reserve the right to modify or discontinue, temporarily or
            permanently, the Service (or any part of the Service) with or without notice at any time.
          </li>
          <li>You agree that we will not be liable to you or any third party for
            any modification, suspension, or discontinuance of the Service.
          </li>
        </ol>
      </Block>
      <Block
        name='software-performance'
        identifier={true}
      >
        <h3>Software Performance</h3>
        <p>Except as otherwise specifically agreed in the Order Form or Service Level
          Agreement, if applicable:
        </p>
        <ol>
          <li>We will make commercially reasonable efforts to have our Service
            operate properly. However, as a service that relies on third-party networks, software, and services, and
            continuous Internet connectivity, we do not guarantee that our Service will operate in an uninterrupted
            or error-free manner or that it will always be available, free from errors, omissions, or
            malfunctions.
          </li>
          <li>If we receive notice of any failure or malfunction or we become
            aware of them by ourselves, we will use commercially reasonable efforts to regain the Service&rsquo;s
            availability as soon as practicable. But such incidents will not be considered a breach of this
            Agreement.
          </li>
        </ol>
      </Block>
      <Block
        name='service-level-agreement'
        identifier={true}
      >
        <h3>Service Level Agreement</h3>
        <p>You acknowledge and agree that the attached Service Level Agreement is only valid
          between you and Base64.ai with respect to and to the extent that you have signed up for any
          paid&nbsp;Service tier. Base64.ai makes no warranties, representations,
          promises, or other agreements as to the performance, availability, or other aspects of the Service. The
          agreement terms apply to you if the Service tier you have chosen in your Account dashboard is a paid Service
          tier, you are current on all required payments, and you are not otherwise in breach of this Agreement or any
          other Agreement between you and Base64.ai.
        </p>
      </Block>
      <Block
        name='data-usage'
        identifier={true}
      >
        <h3>Data Usage</h3>
        <p>By using the Service, you hereby agree that Base64.ai may use technical and related information,
          including all information and documents provided by you or otherwise in connection with our Privacy
          Policy (the &ldquo;User Data&rdquo;) (i) to facilitate the provision of the Service, (ii) to verify
          compliance with this Agreement, and (iii) that Base64.ai may use such information to improve the Service.
          You hereby grant to Base64.ai a non-exclusive, irrevocable, perpetual, royalty-free, fully paid-up,
          worldwide right and license to use any such User Data only for the foregoing purposes in this paragraph.
        </p>
      </Block>
      <Block
        name='security-compliance'
        identifier={true}
      >
        <h3>Security Compliance</h3>
        <p>Base64.ai is ISO 20243, HIPAA, SOC 2 Type 2, and GDPR certified, and all Service data will be
          hosted on servers compliant with such data security standards. You can read more here: <span><a href='https://base64.ai/compliance' target='_blank' rel='noopener noreferrer'>https://base64.ai/compliance</a></span>.
        </p>
        <p>Additionally, all commercially reasonable standards customarily in place in the
          industry for usage, backup storage, and security for all electronic data and other information will be
          maintained at all times.
        </p>
      </Block>
      <Block
        name='disclaimer-of-warranties'
        identifier={true}
      >
        <h3>Disclaimer of Warranties</h3>
        <p>Except as otherwise agreed in a separate written agreement between you and
          Base64.ai (including, for example, a separate Order Form or Service Level Agreement):
        </p>
        <ol>
          <li>Your use of the Service is entirely at your own risk.</li>

          <li>The Service is provided by Base64.ai on an as-is basis.</li>

          <li>Base64.ai expressly disclaims all warranties of any kind, whether
            express or implied, including the implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement.
          </li>

          <li>Base64.ai makes no warranty that (i) the Service will meet your
            requirements, (ii) that operation of the Service will be uninterrupted, timely, secure, or error-free,
            or (iii) the results that may be obtained from the use of the Service will be accurate or
            reliable.
          </li>

          <li>No advice or information, whether oral or written, obtained by you
            from Base64.ai or through the Service creates any warranty regarding the Service not expressly stated in
            this Agreement or your Services Agreement, where applicable.
          </li>
        </ol>
        <p>Because some states or jurisdictions do not allow the disclaimer of implied
          warranties, the foregoing disclaimer only applies to the extent allowed in your applicable state or
          jurisdiction.
        </p>
      </Block>
      <Block
        name='limitation-of-liability'
        identifier={true}
      >
        <h3>Limitation of Liability</h3>
        <p>Except as otherwise agreed in any applicable Order Form or Service Level Agreement,
          you expressly understand and agree that Base64.ai is not liable for any direct, indirect, incidental,
          special, consequential, or exemplary damages, including damages for loss of profits, goods, goodwill, use,
          data, or other intangible losses (even if Base64.ai has been advised of the possibility of such damages),
          resulting from the use or the inability to use the Service or any other matter relating to the Service, and
          without regard to whether such damages, or claims of damages arise based in contract, tort or
          otherwise.
        </p>
        <p>Because some states or jurisdictions do not allow the exclusion or the limitation of
          liability for consequential or incidental damages, in such states or jurisdictions, the liability of
          Base64.ai and its affiliates will be limited to the fullest extent permitted by law.
        </p>
      </Block>
      <Block
        name='indemnification'
        identifier={true}
      >
        <h3>Indemnification</h3>
        <p>You agree to indemnify and hold Base64.ai and its affiliates, officers, agents,
          and employees harmless from any claim, demand, loss, costs, or expense, including attorneys&rsquo; fees,
          made by any person or entity arising out of your violation of this Agreement, state or federal laws or
          regulations, or any other person&rsquo;s rights, including infringement of any copyright or violation of any
          proprietary or privacy right. Under no circumstances, including any negligent act, will Base64.ai or its
          affiliates or agents be liable for any damages of any kind that result from the use of, or the inability to
          use, the Service.
        </p>
      </Block>
      <Block
        name='intellectual-property-rights'
        identifier={true}
      >
        <h3>Intellectual Property Rights</h3>
        <p>You agree that this Agreement does not transfer from Base64.ai to you any Base64.ai
          or third-party intellectual property, and all rights, titles, and interest in and to such property will
          remain (as between the parties) solely with Base64.ai. &ldquo;Base64.ai&rdquo;, the Base64.ai logo, and all
          other trademarks, service marks, graphics, and logos used in connection with the Service, or
          Base64.ai&rsquo;s products or services, are trademarks or registered trademarks of Base64.ai or
          Base64.ai&rsquo;s licensors. Other trademarks, service marks, graphics, and logos used in connection with
          the Service may be the trademarks of other third parties. Your use of the Service grants you no right or
          license to reproduce or otherwise use any Base64.ai or third-party trademarks.
        </p>
        <p>Further, you hereby agree that Base64.ai may use your name or your company&rsquo;s
          name (as applicable) and logo, as well as any written testimonials you provide, in connection with
          Base64.ai&rsquo;s marketing and promotional efforts, including but not limited to usage on the Base64.ai
          website and social media channels.
        </p>
      </Block>
      <Block
        name='cookies'
        identifier={true}
      >
        <h3>Cookies</h3>
        <p>Base64.ai employs the use of cookies. By using Base64.ai&rsquo;s website, you consent
          to the use of cookies in accordance with the Base64.ai privacy policy.
        </p>
        <p>We use cookies to enable us to retrieve user details for each visit. Cookies are used
          in some areas of our site to enable the functionality of this area and ease of use for those people
          visiting. Some of our affiliate/advertising partners may also use cookies.
        </p>
      </Block>
      <Block
        name='term-and-termination'
        identifier={true}
      >
        <h3>Term and Termination</h3>
        <p>These Terms of Use, and any applicable Order Form or Service Level Agreement, will
          remain in full force and effect while you use the Service. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
          TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS
          TO AND USE OF THE SERVICE (INCLUDING BLOCKING CERTAIN IP ADDRESSES) TO ANY PERSON OR ENTITY FOR ANY REASON
          OR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED
          IN THESE TERMS OF USE OR ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OF THE SERVICE AT ANY
          TIME, AT OUR SOLE DISCRETION. If we terminate or suspend your account for any reason, you are prohibited
          from registering and creating a new account under your name, a pseudonym, or the name of any third party,
          even if you may be acting on behalf of the third party. In addition to terminating and suspending your
          account, we reserve the right to take appropriate legal action, including, without limitation, pursuing
          civil, criminal, and injunctive redress.
        </p>
      </Block>
      <Block
        name='legal-compliance'
        identifier={true}
      >
        <h3>Legal Compliance</h3>
        <p>By using the Service, you represent and warrant that (i) you are not located in a
          country that is subject to a U.S. Government embargo or that has been designated by the U.S. Government as a
          &ldquo;terrorist supporting&rdquo; country; (ii) you are not listed on any U.S. Government list of
          prohibited or restricted parties.
        </p>
        <p>You agree that the Service, including all software, documentation, technical data,
          reports, deliverables, and any other materials provided to you by Base64.ai, may be subject to export
          controls under the laws and regulations of the United States. You agree not to export or re-export any such
          materials to any country to which such export is restricted or prohibited.
        </p>
      </Block>
      <Block
        name='governing-law;'
        identifier={true}
      >
        <h3>Governing Law; Jurisdiction</h3>
        <p>
          The Agreement, and all future agreements you enter into with us, unless otherwise indicated on such other agreement,
          will be governed by the laws of the State of New York. This is the case regardless of whether you reside or transact
          business with us or any of our affiliates or agents in the State of New York or elsewhere. Unless a dispute would be
          governed by the terms of the Section entitled Binding Arbitration below or if governed by your Services Agreement,
          where applicable, you agree to submit to the personal and exclusive jurisdiction of the courts located within the city of New York, NY, United States.
          <br /><br /><b>For EU users only:</b>&nbsp;In the event of a dispute relating
          to the interpretation, performance, or validity of this Agreement, an amicable solution can be sought before
          any legal action. You can file your complaint with Base64.ai by sending a message via email to
          support@base64.ai. In case of failure, you can, within one year of the failed request, have recourse to an
          Alternative Dispute Resolution procedure by filing an online complaint on the European Commission&rsquo;s
          Online Dispute Resolution website at <span><a target='_blank' rel='noopener noreferrer' href='https://webgate.ec.europa.eu/odr'>https://webgate.ec.europa.eu/odr</a></span>.
          If out-of-court dispute resolutions fail, the dispute may be brought before the competent courts.
        </p>
      </Block>
      <Block
        name='binding-arbitration'
        identifier={true}
      >
        <h3>Binding Arbitration</h3>
        <p>Except as otherwise agreed in the Services Agreement, where applicable, any dispute
          or claim relating in any way to your use of the Service (each, a &ldquo;Claim&rdquo;) will be resolved by
          binding arbitration rather than in court (except that you may assert claims in small-claims court if your
          claims qualify). You agree that each Claim must be brought individually.
        </p>
        <p>YOU AND BASE64.AI AGREE THAT (i) THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE
          ARBITRATED ON A CLASS ACTION BASIS OR TO UTILIZE CLASS ACTION PROCEDURES; (ii) THERE IS NO RIGHT OR
          AUTHORITY FOR ANY DISPUTE TO BE BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY OR AS A PRIVATE ATTORNEY
          GENERAL; AND (iii) NO ARBITRATION MAY BE JOINED WITH ANY OTHER ARBITRATION.
        </p>
        <p>The Federal Arbitration Act and federal arbitration law apply to this Agreement and
          this binding arbitration clause.
        </p>
        <p>Arbitration is a process with no judge or jury &ndash; an arbitrator will review the
          arguments in the dispute and award damages and other relief just like a court would. The arbitrator must
          follow this Agreement as a court otherwise would. Court review of the arbitration award is limited under the
          Federal Arbitration Act.
        </p>
        <p>To start an arbitration, you must send an email to&nbsp;<a href='mailto:support@base64.ai'>support@base64.ai</a>&nbsp;describing your Claim and requesting arbitration, or we
          may do the same by sending a
          written notice requesting arbitration to your address. The proceedings will be conducted through JAMS, using
          their Streamlined Arbitration Rules and Procedures. You can view these rules at jamsadr.com or by calling
          800-352-5267. The payment of the initial filing fees will be made by the party filing the Claim, and any
          other filing and other fees will be apportioned as directed by the JAMS rules. The arbitration will take
          place in New York, NY, USA, unless the Parties agree to video, phone, or internet connection
          appearances.
        </p>
        <p>Except as otherwise set forth below, you may seek any remedies available to you under
          federal, state, or local laws in an arbitration action. As part of the arbitration, both you and Base64.ai
          will have the opportunity to discover non-privileged information that is relevant to the Claim. The
          arbitrator will provide a written statement of the arbitrator&rsquo;s decision regarding the Claim, the
          award given (including any attorneys&rsquo; fees and costs awarded), and the arbitrator&rsquo;s findings and
          conclusions on which the arbitrator&rsquo;s decision is based.
        </p>
        <p>Notwithstanding the terms of this Section, either of us may bring a lawsuit in court
          for equitable relief for any misuse or infringement of intellectual property rights or any Claim related to,
          or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use of the
          Service.
        </p>
        <p>BY AGREEING TO THIS ARBITRATION PROVISION, YOU UNDERSTAND THAT YOU AND BASE64.AI
          WAIVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
        </p>
        <p>You and Base64.ai agree that if any portion of this Section is found illegal or
          unenforceable, that portion will be severed, and the remainder of the Section will be given full force and
          effect.
        </p>
      </Block>
      <Block
        name='miscellaneous-terms'
        identifier={true}
      >
        <h3>Miscellaneous Terms</h3>
        <p><b>Agreement Revisions:</b>&nbsp;Base64.ai may revise this
          Agreement at any time by updating this Agreement on the Service. We will notify you of any such updates
          through your Account dashboard or any email address for you that we have on file. By continuing to use the
          Service after such updates, you agree to be bound by any such revisions and should, therefore, periodically
          visit this page to determine the then-current Agreement to which you are bound.
        </p>
        <p><b>Force Majeure:</b>&nbsp;Base64.ai is not liable for any delay or
          failure to perform resulting from causes outside the reasonable control of Base64.ai, including without
          limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond
          Base64.ai&rsquo;s control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military
          authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy,
          labor, or materials.
        </p>
        <p><b>No Partnership:</b>&nbsp;You agree that no joint venture,
          partnership, employment, or agency relationship exists between you and Base64.ai because of this Agreement
          or your use of the Service.
        </p>
        <p><b>Assignment:</b>&nbsp;Base64.ai may assign this Agreement, in
          whole or in part, to any person or entity at any time, with or without your consent. You may not assign the
          Agreement without Base64.ai&rsquo;s prior written consent, and any unauthorized assignment by you will be
          null and void.
        </p>
        <p><b>Severability:</b>&nbsp;If any part of this Agreement is
          determined to be void, invalid, or unenforceable, then that portion will be severed, and the remainder of
          the Agreement will be given full force and effect.
        </p>
        <p><b>Attorneys&rsquo; Fees:</b>&nbsp;In the event of any litigation
          or dispute resolution brought by either party in connection with this Agreement, the prevailing party in
          such litigation will be entitled to recover from the other party all the reasonable costs, attorneys&rsquo;
          fees, and other expenses incurred by such prevailing party in the proceedings.
        </p>
        <p><b>No Waiver:&nbsp;</b>Our failure to enforce any provision of this
          Agreement will in no way be construed to be a present or future waiver of such provision, nor in any way
          affect the right of any party to enforce every such provision thereafter. The express waiver by us of any
          provision, condition, or requirement of this Agreement will not constitute a waiver of any future obligation
          to comply with such provision, condition, or requirement.
        </p>
        <p><b>Equitable Remedies:</b>&nbsp;You hereby agree that Base64.ai
          would be irreparably damaged if the terms of this Agreement were not specifically enforced, and therefore
          you agree that we will be entitled, without bond, other security, or proof of damages, to appropriate
          equitable remedies with respect to breaches of this Agreement, in addition to such other remedies as we may
          otherwise have available to us under applicable laws.
        </p>
        <p><b>Conflicts:</b>&nbsp;If there is any conflict between this
          Agreement and the Service Level Agreement or any Order Form, the terms of the Service Level Agreement or
          Order Form will control.
        </p>
        <p><b>Entire Agreement:</b>&nbsp;This Agreement, including any
          applicable Order Form or Service Level Agreement, and any other documents expressly incorporated by
          reference, constitutes the entire agreement between you and Base64.ai with respect to the Service and
          supersedes all prior or contemporaneous communications, whether electronic, oral or written, between you and
          Base64.ai with respect to the Service.
        </p>
      </Block>

      <Block
        name='exhibit-a'
        identifier={true}
      >
        <h2 className='centered'><b>Exhibit A</b></h2>
        <h2 className='centered'><b>SERVICE LEVEL AGREEMENT FOR PAYING CUSTOMERS</b></h2>
        <p>The Exhibit A terms apply to you if the Service tier you have chosen in your Account
          dashboard is a paid Service tier, you are current on all required payments, and you are not otherwise in
          breach of this Agreement or any other Agreement between you and Base64.ai.
        </p>
        <p><b>Minimum Service Levels</b></p>
        <p>Base64.ai will maintain the following minimum service levels for
          any <b>paid</b>&nbsp;Service tier customers:
        </p>
        <ul>
          <li><b>API Monthly Uptime:</b>&nbsp;Base64.ai
            guarantees its API will be available no less than 99% during a given calendar month, excluding holidays
            and weekends and any scheduled or Customer-requested maintenance (the &ldquo;API Uptime SLA&rdquo;).
            Further, any downtime resulting from outages of third-party connections or utilities or other reasons
            beyond Base64.ai&rsquo;s reasonable control will also be excluded from any such calculation. An
            &ldquo;outage&rdquo; is defined as an instance where a sent document either returns no response or
            returns a response with HTTP codes 501-599 for five consecutive minutes.
          </li>
          <li><b>Response Times:</b>&nbsp;Customer must
            report all support-related issues to Base64.ai via the reporting API, email, or ZenDesk, as appropriate.
            Base64.ai will respond to high-priority, critical API outages within one business day of
            Base64.ai&rsquo;s receipt of the support ticket. Base64.ai will make all commercially reasonable efforts
            to remedy the outage in a timely fashion. Base64.ai will use commercially reasonable efforts to remedy
            any other support-related issues.
          </li>
          <li><b>Delivery Capacity:</b>&nbsp;Base64.ai will
            be able to process an input submitted via API in no more than 5 minutes only if (a) the input is a
            supported file type, (b) the input contains a document of no more than 50 individual pages, and (c) the
            input contains a supported document in a standardized format. The foregoing delivery capacity will be
            measured from the time that Customer sends the file to Base64.ai to the time that Customer receives data
            back from Base64.ai via the API. Base64.ai will be able to receive files 24 hours per day, 7 days per
            week. There will be no limit on the number of files that Base64.ai can receive and process, but there is
            a maximum of 10 concurrent requests.
          </li>
        </ul>
        <p><b>Service Level Agreement Breach Protocol</b></p>
        <p>The following are the sole possible Base64.ai material breaches of this Service Level
          Agreement:
        </p>
        <ul>
          <li>Missing the API Uptime SLA in any given month; and</li>
          <li>Continuous and ongoing failure to acknowledge and give feedback
            on errors within the time frame specified above
          </li>
        </ul>
        <p>Base64.ai will refund a percentage of fees paid by Customer, excluding the one-time
          NRE, paid during the one month before an infraction as follows:
        </p>
        <ul>
          <li>0% for the first SLA breach in 1 given calendar month;
          </li>
          <li>2.5% for the second SLA breach in the same calendar month;
            plus
          </li>
          <li>An additional 5% for a third SLA breach in the same calendar
            month.
          </li>
        </ul>
        <p>Notification of the breach must be agreed in writing (email acceptable) for purposes
          of this refund provision. For the avoidance of doubt, the maximum refund under this Agreement for any number
          of SLA breaches is 20% of fees paid in the one month prior to any given SLA breach.
        </p>
        <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY IN ANY AGREEMENT BETWEEN YOU AND BASE64.AI,
          YOU HEREBY ACKNOWLEDGE AND AGREE THAT BASE64.AI CANNOT AND DOES NOT GUARANTEE THE ACCURACY OF ANY DATA
          EXTRACTION AND THE RESULTS OF THE SERVICES, EXCEPT AND SOLELY TO THE EXTENT AS SPECIFICALLY AGREED IN THIS
          SERVICE LEVEL AGREEMENT. BASE64.AI&rsquo;S SOLE LIABILITY RELATED TO YOUR USE OF THE SERVICE IS AS
          SPECIFICALLY AGREED IN THIS SERVICE LEVEL AGREEMENT.
        </p>
      </Block>

    </Section>

  </View>
)

// Export
export default TermsOfService
