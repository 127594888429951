// Modules
import PropTypes from 'prop-types'

// Interface: Loading
const Loading = props => (
  <svg
    className='dots loading'
    viewBox='0 0 17 5'
  >
    <circle
      fill={props.color}
      cx='2.5'
      cy='2.5'
      r='2.5'
    >
    </circle>
    <circle
      fill={props.color}
      cx='8.5'
      cy='2.5'
      r='2.5'
    >
    </circle>
    <circle
      fill={props.color}
      cx='14.5'
      cy='2.5'
      r='2.5'
    >
    </circle>
  </svg>
)

Loading.propTypes = {
  color: PropTypes.string
}

// Export
export default Loading
