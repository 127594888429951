// Modules
import PropTypes from 'prop-types'
import React, { Component } from 'react'
// Context
import Context from '~/context/global'
// Helpers
import { request } from '../../helpers/request'
// Interface
import Section from '~/layout/section/Section'
import ValidationSign from '~/interface/validation-sign/ValidationSign'
import StatusToEmoji from '~/interface/validation-sign/StatusToEmoji'
import ReportIssueButton from '~/interface/report-issue-button/ReportIssueButton'

// Layout
import View from '~/layout/view/View'
import document from 'global/document'
import { AppButton } from '../../interface/app-button/AppButton'

// View: Demo
class HealthAndSafetyDocumentValidation extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      responseMessage: 'Please type a company name and upload a Health & Safety Document.',
      responseStatus: 'info',
      companyName: '',
      document: null,
      fileName: '',
      validationResponse: [],
      zoomedItem: null
      // validationResponse: [{ description: 'description desc', isValid: false, message: 'document must be valid' }, { description: 'mock desc', isValid: true, message: 'document valid' }]
    }

    this.renderImage = this.renderImage.bind(this)
  }

  componentDidMount () {
    // if (!this.props.user) {
    //   window.location.href = '/login'
    // }
  }

  renderImage (data) {
    const isZoomed = data === this.state.zoomedItem
    const closeZoom = () => this.setState({
      zoomedItem: null
    })
    document[`${isZoomed ? 'add' : 'remove'}EventListener`](
      'keydown',
      event => event.keyCode === 27 && closeZoom()
    )
    return (
      <React.Fragment key={0}>
        {data
          ? (
              <img
                className='cropped'
                src={data}
                onClick={event => (!isZoomed || event.target.classList.contains('zoomed'))
                && this.setState({
                  zoomedItem: isZoomed ? null : data
                })}
                style={{
                  marginRight: '0.3rem',
                  borderBottom: 'unset'
                }}
              />
            )
          : <span className='info'>No image</span>}
        <div className={(isZoomed ? 'zoomed ' : '') + 'preview'} style={{ display: isZoomed ? 'flex' : 'none' }}>
          <figure className='image'>
            <img src={data} />
            <figcaption>
              <a
                className='close noBorder'
                style={{ display: isZoomed ? 'inline-block' : 'none' }}
                onClick={closeZoom}
              >
                Close
              </a>
            </figcaption>
          </figure>
        </div>
      </React.Fragment>
    )
  }

  validateDocument () {
    const { companyName, document, fileName } = this.state
    if (!companyName) {
      return alert('Company name cannot be empty.')
    }
    if (!document) {
      return alert('Document input cannot be empty.')
    }
    this.setState({
      responseMessage: 'Processing...',
      responseStatus: 'info'
    })
    request({
      endpoint: '/scan',
      method: 'POST',
      body: { companyName, document, fileName, modelTypes: ['health/safety/policy'] }
    }, (error, results) => {
      // callback
      if (error) {
        alert('An error occurred :' + error.message)
        this.setState({
          responseMessage: 'An error occurred :' + error.message,
          responseStatus: 'error'
        })
      }
      const validations = results?.[0].features.validations || []
      const notAcceptedChecks = validations.filter(validation => (!validation.isValid || validation.warning) && !validation.isOptional)
      const warnings = validations.filter(validation => validation.warning)
      this.setState({
        validationResponse: validations,
        responseMessage: warnings.length
          ? 'This document could not processed successfully. Please upload again.'
          : notAcceptedChecks.length === 0
            ? 'Document is valid.'
            : `Document is not valid. ${notAcceptedChecks.length} error${notAcceptedChecks.length === 1 ? '' : 's'} detected.`,
        responseStatus: notAcceptedChecks.length === 0 ? 'success' : 'error'
      })
    }, () => {
      // error handler
    })
  }

  fileUploadHandler (e) {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = ev => {
      this.setState({
        document: ev.target.result,
        fileName: file.name
      })
    }
    file && reader.readAsDataURL(file)
  }

  resetInputs () {
    document.getElementById('document-input').value = ''
    this.setState({
      validationResponse: [],
      companyName: '',
      responseMessage: 'Please type a company name and upload a Health & Safety Document.',
      responseStatus: 'info',
      image: null,
      fileName: ''
    })
  }

  render () {
    const { companyName, validationResponse, responseMessage, responseStatus } = this.state
    return (
      <View name='health-and-safety-demo'>
        <Section name='header' tight={true} align='center'>
          <h1 className='slogan'>Health & Safety Validation AI</h1>
          <h2 className='introduction'>Validate H&S documents in seconds</h2>
          <h3 className='introduction'>
            Base64.ai automatically reviews Health & Safety Documents and detects compliance issues such as incorrect file formats, stale revision dates, and watermarks.
          </h3>
        </Section>
        <Section name='content'>
          <div className='status'>
            <StatusToEmoji status={responseStatus} message={responseMessage} />
          </div>
          <div className='input'>
            <label>
              Company name<br />
              <input
                type='text'
                name='company-name'
                value={companyName || ''}
                placeholder='e.g. Base64.ai.'
                disabled={this.state.responseMessage === 'Processing...'}
                onChange={e => {
                  this.setState({
                    companyName: e.target.value
                  })
                }}
              />
            </label>
            <br />
            <br />
            <label>
              Health &amp; Safety Document <br />
              <input
                type='file'
                id='document-input'
                name='document'
                disabled={this.state.responseMessage === 'Processing...'}
                onChange={e => {
                  this.fileUploadHandler(e)
                }}
              />
            </label>
          </div>
          <div className='result'>
            {validationResponse.length === 0
              ? (
                  <>
                    {this.props.user && this.props.user.email
                      ? (
                          <AppButton
                            className='secondary-cta large'
                            disabled={this.state.responseMessage === 'Processing...'}
                            onClick={() => {
                              this.validateDocument()
                            }}
                          >
                            Validate
                          </AppButton>
                        )
                      : (
                          <AppButton
                            className='secondary-cta large'
                            href='/login'
                          >
                            Login
                          </AppButton>
                        )}
                  </>
                )
              : (
                  <>
                    {validationResponse.length !== 0
                      ? (
                          <AppButton
                            className='secondary-cta large'
                            onClick={() => {
                              this.resetInputs()
                            }}
                          >
                            Clear
                          </AppButton>
                        )
                      : <></>}
                  </>
                )}
          </div>
          {
            validationResponse.length !== 0
              ? (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <td className='firstRow'>Check</td>
                          <td className='firstRow'>Page</td>
                          <td className='firstRow'>Message</td>
                          <td className='firstRow'>Image</td>
                          <td className='firstRow'>Result</td>
                        </tr>
                      </thead>
                      <tbody>
                        {validationResponse.map(validation => (
                          <>
                            <tr>
                              <td>
                                {validation.description}
                              </td>
                              <td>
                                {validation.page !== undefined && (validation.page + 1)}
                              </td>
                              <td>
                                { (Array.isArray(validation.message))
                                  ? validation.message.map(v => <>{v}<br /></>)
                                  : validation.message}
                              </td>
                              <td>
                                { validation.crop && this.renderImage(validation.crop)}
                              </td>
                              <td className={`centered ${validation.isOptional && 'grayscale'}`}>
                                <ValidationSign success={!validation.warning && validation.isValid} />
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                    <div className='placeholder' />
                    <ReportIssueButton image={this.state.image} />
                  </>
                )
              : <></>
          }
        </Section>
      </View>
    )
  }
}

HealthAndSafetyDocumentValidation.propTypes = {
  user: PropTypes.object
}

// Export
export default HealthAndSafetyDocumentValidation
