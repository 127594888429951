export default {
  'covid-19-vaccine-card': {
    name: 'Covid-19 & vaccine card',
    description: 'Whether it is for an event, back-to-office preparation, or restaurant checkin, validating the Covid-19 vaccination card upfront avoids lines and keeps everyone safe. Base64.ai can validate all vaccine card worldwide against any IDs, driver licenses, or passports.',
    demo: '/demo/covid-19-vaccination-validation'
  },
  'background-check': {
    name: 'Background check',
    canLowerCase: true,
    description: 'Instantly process background check applications without manual document transcription. Verify IDs, photos, signatures, and voice characteristics of applicants from most countries. Base64.ai is proud member of the Professional Background Screening Association (PBSA).',
    dataExtractionTypes: ['us-driver-license', 'international-id', 'passport', 'visa']
  },
  'gig-economy': {
    name: 'Gig economy',
    canLowerCase: true,
    description: 'Scale your onboarding with Base64.ai. Process documents from deliverers, walkers, and professionals while they are still on your onboarding funnel.',
    dataExtractionTypes: ['us-driver-license', 'invoice', 'vehicle-registration', 'vehicle-insurance']
  },
  'banking-and-kyc': {
    name: 'Banking & KYC',
    description: 'Accept online signup applications and process paper forms instantly. Base64.ai provides speed and accuracy so that you can stay ahead of the competition.',
    dataExtractionTypes: ['us-driver-license', 'passport', 'international-id', 'file']
  },
  'ride-sharing': {
    name: 'Ride sharing',
    canLowerCase: true,
    description: 'Manual document processing is the top reason for the conversion rate drops in driver onboarding. Base64.ai allows you to onboard drivers instantly without delays or typos.',
    dataExtractionTypes: ['us-driver-license', 'international-id', 'vehicle-registration', 'vehicle-insurance']
  },
  'insurance': {
    name: 'Insurance',
    canLowerCase: true,
    description: 'Instantly process insurance forms, validate the forms against the requirements, and avoid costly typos.',
    dataExtractionTypes: ['vehicle-insurance', 'acord', 'sbc']
  },
  'airlines': {
    name: 'Airlines',
    canLowerCase: true,
    description: 'Instantly accept new users for mileage programs at checkin kiosks without any further need to fill up long signup forms.',
    dataExtractionTypes: ['passport', 'visa', 'us-driver-license', 'international-id']
  },
  'logistics': {
    name: 'Insurance & Logistics',
    canLowerCase: true,
    description: 'Instantly scan license plates, driver licenses, containers, and logistics forms to organize your lot, and to reduce wait times.',
    dataExtractionTypes: ['us-driver-license', 'vehicle-registration', 'shipping-container', 'invoice', 'acord']
  },
  'onboarding': {
    name: 'Customer onboarding',
    canLowerCase: true,
    description: 'Base64.ai can process onboarding documents in lightning speed without needing large teams of manual labor. ',
    dataExtractionTypes: ['us-driver-license', 'passport', 'international-id', 'file']
  },
  'mobile-development': {
    name: 'iPhone & Android',
    description: 'Base64.ai API is compatible with all mobile platforms. Our mobile SDK provides on-device barcode scanning, and you can still use our API for advanced document processing in the cloud.',
    demo: 'https://play.google.com/store/apps/details?id=com.base64ai.camera'
  },
  'fraud-prevention': {
    name: 'Fraud prevention',
    canLowerCase: true,
    description: 'Use our facial recognition API to validate that the user is who they claim to be in real-time. Our advanced AI can work with as low as a single reference photo. You can use it for ID validation, log in with selfie, and vendor verification.',
    demo: '/demo/facial-recognition'
  },
  'offline-air-gapped-networks': {
    name: 'Offline & air-gapped networks',
    canLowerCase: true,
    description: 'We know some documents are ultra-sensitive and cannot leave your computers for any reason. Base64.ai can work both in the cloud and on-premises networks, including air-gapped (offline) datacenters. Our AI can OCR & process documents in your Docker hosts, Kubernetes, Podman, or OpenShift environment without sending documents, OCR, nor extracted data to the Internet. Contact us to learn more.'
  }
}
