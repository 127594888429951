export default {
  'vaccination': {
    icon: 'masked',
    text: 'Verify vaccination status instantly'
  },
  'fleets': {
    icon: 'announce',
    text: 'Validate large fleets with ease'
  },
  'customer-satisfaction': {
    icon: 'approve',
    text: 'Higher customer satisfaction'
  },
  'end-to-end': {
    icon: 'grid',
    text: 'End-to-end solution for all document types'
  },
  'instant-recognize': {
    icon: 'archive_doc',
    text: 'Instantly recognize any document type with our AI'
  },
  'pre-built-models': {
    icon: 'search_glass',
    text: 'Available for over 2,800 pre-built models'
  },
  'unknown-models': {
    icon: 'archive_question',
    text: 'Use Semantic AI to classify unknown documents'
  },
  'summarize-datapoints': {
    icon: 'convo',
    text: 'Summarize and extract data points similar to how our AI’s document processing capabilities'
  },
  'detailed-answers': {
    icon: 'multi_laptop',
    text: 'Get detailed responses to important questions'
  },
  'facilitate-impairment': {
    icon: 'ear',
    text: 'Facilitate earlier access to data extraction for users with physical impairments'
  },
  'digitizing': {
    icon: 'template',
    text: 'Spend less time creating and digitizing documents'
  },
  'avoid-omissions': {
    icon: 'pdf',
    text: 'Avoid errors and omissions when manually converting forms to PDF format'
  },
  'digitize-forms': {
    icon: 'cabinet_drawer',
    text: 'Ensure uniformity when digitizing forms in large quantities'
  },
  'accurately': {
    icon: 'square',
    text: 'Process and verify your documents instantly and accurately'
  },
  'classify-shape': {
    icon: 'ratio',
    text: 'Classify documents by shape to boost processing confidence'
  },
  'consistent-layout': {
    icon: 'text_edit',
    text: 'Maintains consistent formatting and layout across documents'
  },
  'reduce-errors-in-classification': {
    icon: 'ruler',
    text: 'Reduces errors and discrepancies in information processing'
  },
  'accesibility-for-hearing-impaired': {
    icon: 'image_file',
    text: 'Accessibility for the hearing impaired'
  },
  'index-efficiently': {
    icon: 'picture',
    text: 'Index images more efficiently'
  },
  'accessibility-for-education': {
    icon: 'technical_support',
    text: 'Accessible material for students and learners'
  },
  'seconds': {
    icon: 'eye',
    text: 'Turn days of validation into seconds'
  },
  'operations-and-processing': {
    icon: 'coin',
    text: 'Save money on operations and processing costs'
  },
  'identity-in-seconds': {
    icon: 'target',
    text: 'Validate customers’ identity in seconds'
  },
  'blur': {
    icon: 'checklist',
    text: 'Eliminate documents with glare and blur'
  },
  'customer-safety': {
    icon: 'waypoint',
    text: 'Boost customer safety'
  },
  'get-users-approved': {
    icon: 'checkmark',
    text: 'Get users approved in seconds not hours'
  },
  'existing-platform': {
    icon: 'approve',
    text: 'Integrate right into your existing platform'
  },
  'establish-proof': {
    icon: 'archive',
    text: 'Establish proof of certification and summarize your client’s coverage in seconds'
  },
  'reduce': {
    icon: 'cog_clock',
    text: 'Reduce thousands of hours spent on document processing'
  },
  'onboarding': {
    icon: 'sim_card',
    text: 'Validate information right from your system'
  },
  'security': {
    icon: 'phone_person',
    text: 'Boost security with facial recognition processing'
  },
  'background': {
    icon: 'book',
    text: 'Background checks in seconds'
  },
  'extract': {
    icon: 'ticket',
    text: 'Extract any document type with high accuracy'
  },
  'identify': {
    icon: 'magnify',
    text: 'Identify a shipping container in seconds'
  },
  'autonomous': {
    icon: 'speed_up',
    text: 'Rely on autonomous document understanding'
  },
  'track': {
    icon: 'phone_warehouse',
    text: 'Track shipments with ease'
  },
  'save-time': {
    icon: 'hourglass',
    text: 'Save time validating pages of information'
  },
  'apply': {
    icon: 'magnify',
    text: 'Apply background check while processing'
  },
  'organize': {
    icon: 'layers',
    text: 'Organize your data with an all-in-one solution'
  },
  'boost': {
    icon: 'crosshair',
    text: 'Boost accuracy and consistency'
  },
  'one': {
    icon: 'datacenter',
    text: 'All-in-one data extraction'
  },
  'system': {
    icon: 'mesh',
    text: 'Integrate right into your system'
  },
  'enhance': {
    icon: 'overlap',
    text: 'Capture an image and process within seconds'
  },
  'on-the-go': {
    icon: 'layers_transparent',
    text: 'Support validation on the go'
  },
  'in-your-pocket': {
    icon: 'phone_discount',
    text: 'Automated document processing in your pocket'
  },
  'sensitive-data': {
    icon: 'monument',
    text: 'High level security for sensitive data'
  },
  'offline': {
    icon: 'server',
    text: 'Offline compatible'
  },
  'your-data': {
    icon: 'building',
    text: 'Handle your data on-premise'
  },
  'sensitive': {
    icon: 'mosoleum',
    text: 'Process sensitive data with bank-level security'
  },
  'errors': {
    icon: 'wallet_money', // Find wallet_coin in 256x256
    text: 'Eliminate expensive errors'
  },
  'consistency': {
    icon: 'cog_warehouse',
    text: 'Maintain high accuracy and consistency'
  },
  'measures': {
    icon: 'monument',
    text: 'Strengthen your security measures'
  },
  'reducing-accuracy': {
    icon: 'facial_recognition',
    text: 'Prevent changing facial features from reducing accuracy'
  },
  'recognize': {
    icon: 'sync_person',
    text: 'Recognize faces as they age'
  },
  'processing-with-less': {
    icon: 'speed_up',
    text: 'Faster processing with less paperwork'
  },
  'identity-in': {
    icon: 'target',
    text: 'Validate identity in seconds'
  },
  'spending-less': {
    icon: 'chart_price',
    text: 'Boost accuracy while spending less'
  },
  'customers-identity': {
    icon: 'book',
    text: 'Validate customers’ identity in seconds'
  },
  'format': {
    icon: 'approve',
    text: 'Instantly extract data in any format'
  },
  'speed-up': {
    icon: 'chart_price',
    text: 'Speed up your processing funnel'
  },
  'faster-processing': {
    icon: 'speed_up',
    text: 'Manage less paperwork'
  },
  'at-once': {
    icon: 'chart_price',
    text: 'Validate all documents at once'
  },
  'satisfaction': {
    icon: 'chart_satisfaction',
    text: 'Boost customer satisfaction'
  },
  'in-seconds': {
    icon: 'target',
    text: 'Validate IDs from 180 countries'
  },
  'consistently': {
    icon: 'speed_up',
    text: 'Consistently process with accuracy'
  },
  'processing-costs': {
    icon: 'chart_price',
    text: 'Save time and processing costs'
  },
  'languages': {
    icon: 'target',
    text: 'Process documents in over 60 languages'
  },
  'and-misinterpretations': {
    icon: 'speed_up',
    text: 'Reduce errors and misinterpretations'
  },
  'operations': {
    icon: 'chart_price',
    text: 'Save money on operations and costs'
  },
  'customers': {
    icon: 'target',
    text: 'Validate travelers without disruption'
  },
  'visa': {
    icon: 'speed_up',
    text: 'Extract information from any visa type'
  },
  'avoid': {
    icon: 'chart_price',
    text: 'Avoid delays and reduce processing time'
  },
  'use-questions': {
    icon: 'qa',
    text: 'Avoid building custom models by using Q&A to enhance results'
  },
  'get-answers-from-any-document': {
    icon: 'multimedia',
    text: 'Find detailed information about document, audio, and video files'
  },
  'checkbox-validation': {
    icon: 'survey',
    text: 'Minimize manual checkbox validation with quick processing time'
  },
  'handle-checkboxes': {
    icon: 'check',
    text: 'Handle large volumes of checkboxes and radio buttons without jeopardizing consistency'
  },
  'associate-checkboxes': {
    icon: 'document',
    text: 'Instantly associate text with corresponding checkboxes'
  },
  'normalize': {
    icon: 'magnifying_glass',
    text: 'Normalize data points across documents with different formats'
  },
  'eliminate-erros': {
    icon: 'vertical_type',
    text: 'Eliminate confusion and human-prone errors'
  },
  'avoid-inaccuracies': {
    icon: 'file',
    text: 'Avoid inaccuracies from values with different naming conventions'
  },
  'automate-with-qna': {
    icon: 'keyboard',
    text: 'Automate entire workflows to find the same questions using Q&A queries'
  },
  'text-to-speech': {
    icon: 'sound',
    text: 'Convert text into audio without the need for third-party software'
  },
  'enable-impaired': {
    icon: 'online_learning',
    text: 'Enable individuals with visual impairments to comprehend text-based content effortlessly'
  },
  'multiple-formats': {
    icon: 'conversation',
    text: 'Engage and record information in more than one file format'
  },
  'inclusivity': {
    icon: 'workgroup',
    text: 'Fosters inclusivity for a wider audience'
  },
  'educational': {
    icon: 'graduation',
    text: 'Useful for educational settings, entertainment media, and business communications'
  },
  'hearing-impairment': {
    icon: 'ear',
    text: 'Accessible to users with hearing impairments'
  },
  'extract-banking-info': {
    icon: 'bank',
    text: 'Extract banking sensitive information with high security'
  },
  'process-payment': {
    icon: 'global',
    text: 'Process international payments and reduces errors in data entry instantly'
  },
  'ensure-accuracy': {
    icon: 'tax',
    text: 'Ensure that all relevant information is accurately extracted without skipping crucial information'
  },
  'minimize-discrepancy': {
    icon: 'pay',
    text: 'Minimizing discrepancies from countless data points'
  },
  'compliant': {
    icon: 'compliant',
    text: 'Process sensitive data with a certified SOC 2 Type 2, HIPAA, and GDPR solution'
  },
  'location-insight': {
    icon: 'map',
    text: 'Enables location-based insights for analysis and better decision-making'
  },
  'improve-data-management': {
    icon: 'gps',
    text: 'Improve customer data management'
  },
  'data-visualization': {
    icon: 'location',
    text: 'Gain new insights into your business through data visualization'
  },
  'no-storage': {
    icon: 'protection',
    text: 'Use an AI solution that does not store your data'
  },
  'data-fields': {
    icon: 'speed_up',
    text: 'Eliminate manual validation for all data fields'
  },
  'on-operations': {
    icon: 'money',
    text: 'Reduce processing expenses'
  },
  'receipt-data': {
    icon: 'analysis',
    text: 'Ensure your business has no receipt data loss'
  },
  'less-manual': {
    icon: 'invoice',
    text: 'Less manual data entry, fewer errors, and lower resource costs'
  },
  'semi-structured': {
    icon: 'order',
    text: 'Process semi-structured documents while maintaining accuracy and requiring no templates for each version'
  },
  'integrate-validation': {
    icon: 'delivery_box',
    text: 'Integrate into your system within minutes to track validations'
  },
  'documents': {
    icon: 'speed_up',
    text: 'Automated multi-page documents'
  },
  'your-business': {
    icon: 'analysis',
    text: 'Ensure your business has no invoice data loss'
  },
  'all-data-points': {
    icon: 'chart_price',
    text: 'Validate all data points'
  },
  'certification': {
    icon: 'archive',
    text: 'Establish proof of certification'
  },
  'thousands-of-hours': {
    icon: 'cog_clock',
    text: 'Eliminate thousands of hours spent on document processing'
  },
  'quick-onboarding': {
    icon: 'sim_card',
    text: 'Process right from your existing software'
  },
  'days-long-delays': {
    icon: 'wristwatch',
    text: 'Reduce days-long delays and process in seconds'
  },
  'your-patients': {
    icon: 'attention',
    text: 'Accurately validate your patient’s document with ease'
  },
  'cycle-time': {
    icon: 'pointer',
    text: 'Improve revenue cycle time and reduce processing costs'
  },
  'your-security': {
    icon: 'mosoleum',
    text: 'Strengthen your security measures'
  },
  'car': {
    icon: 'car',
    text: 'Identify a car in seconds'
  },
  'money-on': {
    icon: 'chart_price',
    text: 'Save time and process instantly'
  },
  'shipments': {
    icon: 'phone_discount',
    text: 'Track shipments with ease'
  },
  'higher': {
    icon: 'chart_price',
    text: 'Higher customer satisfaction'
  },
  'quick': {
    icon: 'speed_up',
    text: 'Quick and easy processing'
  },
  'money': {
    icon: 'layers',
    text: 'Handle muilt-page processing'
  },
  'prone': {
    icon: 'grid_transparent',
    text: 'Reduce expensive human errors'
  },
  'unstructured': {
    icon: 'chart_discount',
    text: 'Process unstructured text'
  },
  'capture': {
    icon: 'square',
    text: 'Capture data from unstructured handwritten documents'
  },
  'misinterpretation': {
    icon: 'bill',
    text: 'Reduce misinterpretation'
  },
  'your-user': {
    icon: 'overlap',
    text: 'Enhance your user experience'
  },
  'all-in-one': {
    icon: 'grid_archive',
    text: 'End-to-end solution for your document processing'
  },
  'cycle': {
    icon: 'discount',
    text: 'Reduce processing cycles'
  },
  'steps': {
    icon: 'checklist_board',
    text: 'Eliminate unnecessary steps'
  },
  'extract-signature': {
    icon: 'extract_signature',
    text: 'Extract all signatures on a document in seconds'
  },
  'match-signature': {
    icon: 'match_signature',
    text: 'Match signatures between documents'
  },
  'compatible': {
    icon: 'compatible',
    text: 'Compatible for any document type'
  },
  'facial-recognition': {
    icon: 'face_detection',
    text: 'Crosscheck images to validate identity'
  },
  'id-scan': {
    icon: 'Id_scan',
    text: 'Compare images with your database'
  },
  'multiple-people': {
    icon: 'multiple_people',
    text: 'Recognize users at different angles or with changing facial structures'
  },
  'document-validation': {
    icon: 'docarchive',
    text: '100% accurate document validation'
  },
  'validate-multilang': {
    icon: 'translate',
    text: 'Validate in multiple languages'
  },
  'hipaa': {
    icon: 'security',
    text: 'Process sensitive data with HIPAA healthcare data security certification'
  },
  'high-confidence': {
    icon: 'ambulance',
    text: 'Extract data from checkboxes and tables with high confidence'
  },
  'minimize-disputes': {
    icon: 'healthcare',
    text: 'Minimizes human errors and reduces the chances of claim disputes'
  },
  'smart-health': {
    icon: 'hospital',
    text: 'Retrieve information from SMART Health Card QR codes instead of processing entire documents'
  },
  'streamline-healthcare': {
    icon: 'healthcare_card',
    text: 'Streamline healthcare document onboarding process'
  },
  'smart-health-fraud': {
    icon: 'healthcare_card',
    text: 'Automatically validate the SMART Health Card data against fraud'
  },
  'safety-glasses': {
    icon: 'safety_glasses',
    text: 'Avoid misinterpreting critical safety information'
  },
  'safety-regulations': {
    icon: 'carpenter',
    text: 'Maintain compliance with safety regulations and prevent potential fines and penalties'
  },
  'safety-management': {
    icon: 'hardhat',
    text: 'Enhance safety management and tracking practices'
  },
  'reduce-waiting-time': {
    icon: 'airplane',
    text: 'Shorten declaration review to reduce waiting time'
  },
  'track-processed-documents': {
    icon: 'traveler',
    text: 'Keep track of processed documents by integrating them into your existing platform'
  },
  'prevent-unqualified-travelers': {
    icon: 'no_travelling',
    text: 'Avoid validating errors and approving unqualified travelers'
  },
  'validate-bill-of-lading': {
    icon: 'cargo',
    text: 'Validate multi page Bill of Lading documents without disruptions'
  },
  'limit-human-involvement': {
    icon: 'container',
    text: 'Reduce extraction errors by limiting human involvement during processing'
  },
  'efficient-data': {
    icon: 'forklift',
    text: 'Efficiently gather data despite varying document formats'
  },
  'workplace-safety': {
    icon: 'report_archive',
    text: 'Boost data extraction to facilitate proactive measures to enhance workplace safety'
  },
  'shorten-review': {
    icon: 'protector_vest',
    text: 'Shorten time-consuming manual reviews and missing important information'
  },
  'validate-various-languages': {
    icon: 'translate',
    text: 'Validate safety information in various languages'
  },
  'always-available': {
    icon: 'calendar',
    text: 'Base64.ai document reviewers are available 24/7'
  },
  'no-training': {
    icon: 'elearning',
    text: 'Start processing today with no training required'
  },
  'object-detection': {
    icon: 'search',
    text: 'Extract document data, signatures, and faces'
  },
  'save-time-building': {
    icon: 'clock_bell',
    text: 'Save time building models for document types'
  },
  'ai-processing': {
    icon: 'AI_processing',
    text: 'Powerful AI-driven Intelligent document processing (IDP)'
  },
  'multi-language': {
    icon: 'AI_processing',
    text: 'Powerful extraction in multiple languages'
  },
  'integration-code': {
    icon: 'integration_code',
    text: 'Integrate in less than 2 hours'
  },
  'coding-on-laptop': {
    icon: 'coding_on_laptop',
    text: 'No coding or AI experience required'
  },
  'mobile-desktop-devices': {
    icon: 'mobile_desktop_devices',
    text: 'Suitable for desktop and mobile processes'
  },
  'document-extraction': {
    icon: 'document_extraction',
    text: 'We recognize digital signature on any document type'
  },
  'signatureAI-extract': {
    icon: 'signatureAI_extract',
    text: 'Validate for an extra layer of security'
  },
  'data-search': {
    icon: 'data_search',
    text: 'Eliminate manual verification'
  },
  'data-search-entity': {
    icon: 'data_search',
    text: 'Recognize more than 10 different types of entities'
  },
  'analyzing': {
    icon: 'analyzing',
    text: 'Use AI to process table structures in seconds'
  },
  'document-format': {
    icon: 'document_format',
    text: 'Extract data points in any format'
  },
  'processing-accuracy': {
    icon: 'processing_accuracy',
    text: 'Enhance your processing accuracy'
  },
  'recognize-checkboxes': {
    icon: 'grid',
    text: 'We recognize checkboxes on any document type'
  },
  'no-template': {
    icon: 'no_template',
    text: 'Extract data without the need for templates'
  },
  'process-checkboxes': {
    icon: 'checkboxes',
    text: 'Validate lists in seconds'
  },
  'track-information': {
    icon: 'square',
    text: 'Track your information and validate the data'
  },
  'processing-more': {
    icon: 'processing_more',
    text: 'Process more with less'
  },
  'every-data-point': {
    icon: 'crosshair',
    text: 'Process with AI the recognizes every data point in your documents'
  },
  'every-size-shape-form': {
    icon: 'chart_discount',
    text: 'Extract watermarks in any size, shape, or form'
  },
  'validate-compact': {
    icon: 'form_document',
    text: 'Validate compact data in seconds'
  },
  'process-more': {
    icon: 'layers_transparent',
    text: 'Process all data points with information'
  },
  'high-accuracy':
  {
    icon: 'AI_accuracy',
    text: 'Accurately extract all necessary data points'
  },
  'certified': {
    icon: 'mosoleum',
    text: 'Certified for SOC 2 and GDPR compliance'
  },
  'id-cards': {
    icon: 'Id_cards',
    text: 'Crosscheck user identity with multiple documents'
  },
  'validated-id': {
    icon: 'validated_id',
    text: 'Fast and accurate validation'
  },
  'card-approval': {
    icon: 'card_approval',
    text: 'Approve your customers in seconds'
  },
  'card-validation': {
    icon: 'card_validation',
    text: 'Reduce image quality errors'
  },
  'S.S-card': {
    icon: 'S.S_card',
    text: 'Process with bank-level security'
  },
  'verify-sensitive': {
    icon: 'mobile_image_capture',
    text: 'Verify sensitive data with AI'
  },
  'id-validation': {
    icon: 'Id_validation',
    text: 'Process with HIPAA and SOC 2 compliance'
  },
  'bank-level-security': {
    icon: 'Bank_level_security',
    text: 'Base64.ai does not store your data'
  },
  'convenience': {
    icon: 'convenience',
    text: 'Create convenience for your processing needs'
  },
  'reliable': {
    icon: 'reliable',
    text: 'Use a reliable service and eliminate downtime'
  },
  'scalable': {
    icon: 'scalable',
    text: 'Scalable and cost-effectiveness'
  },
  'time': {
    icon: 'image_processing',
    text: 'Cut down validation time'
  },
  'improve-workflow': {
    icon: 'handwriting',
    text: 'Improve your workflow with accurate and fast AI'
  },
  'recognize-imperfections': {
    icon: 'eye',
    text: 'Use AI to recognize imperfections in the documents you process'
  },
  'avoid-low-quality': {
    icon: 'crosshair',
    text: 'Avoid low-quality images by notifying users before they submit'
  },
  'eliminate-erronous-documents': {
    icon: 'chart_discount',
    text: 'Eliminate hard-to-read documents and reduce verification time'
  },
  'secure-data': {
    icon: 'locked_Id',
    text: 'Secure data extraction AI'
  },
  'id-validation-approved': {
    icon: 'Id_validation_approved',
    text: 'Instantly validate ID types'
  },
  'classify': {
    icon: 'classification',
    text: 'Automatically classify document types'
  },
  'multi-document': {
    icon: 'multi_document',
    text: 'Accept multiple documents on the same photo or page'
  },
  'avoid-invalid': {
    icon: 'arrow',
    text: 'Avoid invalid entries'
  }

}
