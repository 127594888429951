// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'
import Loading from '../loading/Loading'
import { AppButton } from '../app-button/AppButton'

// Interface: ErrorLoadingModal
class LoadingModal extends Component {
  static propTypes = {
    data: PropTypes.object,
    style: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {}
  }

  static getEmptyMessageObject = () => {
    return {
      title: null,
      description: null,
      status: 'success',
      code: null
    }
  }

  static getMessageDescriptionWithCode = (description, errorCode) => {
    description = description || ''
    errorCode = errorCode || ''
    if (!description.trim().match(/^0xB64[A-Z\d]+/) && errorCode?.length) {
      description = `${description} (Error code: ${errorCode})`
    }
    return LoadingModal.addDotIfNotExist(description)
  }

  static getStandardErrorMessageObject = err => {
    return {
      isLoaded: false,
      messageObject: {
        title: 'Error',
        description: (err.message || err) + '.',
        status: 'error',
        code: err?.code || ''
      }
    }
  }

  static addDotIfNotExist = text => {
    text = text || ''
    return text.trim() + (text.match(/\.$/) ? '' : '.')
  }

  render () {
    const description = LoadingModal.getMessageDescriptionWithCode(this.props.data?.description, this.props.data?.code)
    return (
      <div className='loading-container' style={this.props.style}>
        {this.props.data?.status === 'loading' && <Loading color={this.props?.style?.loaderColor} />}
        <h2 className='title'>{this.props?.data?.title || 'Loading...'}</h2>
        <p className='paragraph'>{description || 'Please wait, this may take a few seconds.'}</p>
        {
          (this.props?.data?.button && this.props.data?.status !== 'loading') && (
            <>
              <br />
              <AppButton className='secondary-cta' href={this.props.data.button.to}>
                {this.props.data.button.text}
              </AppButton>
            </>
          )
        }
      </div>
    )
  }
}

// Export
export default LoadingModal
