import { Component } from 'react'
import PropTypes from 'prop-types'
import DataExtractionBox from './DataExtractionBox'
import * as uuid from 'uuid'
import MaterialIcon from '../material-icon/MaterialIcon'
import Checkbox from '../checkbox/Checkbox'
import Popup from '../popup/Popup'
import window from 'global/window'
import { Parser as Json2csv } from 'json2csv'
import document from 'global/document'
import Link from '../link/Link'
import Dropdown from '../dropdown/Dropdown'
import { mergeBoxDict, mergeLocation } from '../../helpers/box'
import Chart from 'chart.js/auto'
import Select from 'react-select'
import clsx from 'clsx'
import { isEqual } from 'radash'
import { getFirstFloat } from '~/utilities/format'

class TableBox extends Component {
  static propTypes = {
    tables: PropTypes.object,
    context: PropTypes.object,
    contextProvider: PropTypes.object,
    isEditDisabled: PropTypes.bool,
    header: PropTypes.string
  }

  static defaultProps = {
    header: 'Tables'
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedTable: null,
      selectedTableIndex: 0,
      seeMore: false,
      mode: 'view',
      isSelected: false,
      chart: null,
      chartType: 'bar',
      tablesToShow: [],
      dataType: 'table',

      // chart options
      aggregated: true,
      horizontal: false,
      stacked: false
    }
    this.id = uuid.v4()
    this.updateTable = this.updateTable.bind(this)
    this.renderExtendableTableIcon = this.renderExtendableTableIcon.bind(this)
    this.downloadCSV = this.downloadCSV.bind(this)
    this.deleteTable = this.deleteTable.bind(this)
    this.selectionHandler = this.selectionHandler.bind(this)
    this.blurHandler = this.blurHandler.bind(this)
    this.focusHandler = this.focusHandler.bind(this)

    this.displayLimit = 16

    this.chartOptions = [
      { label: 'Bar', value: 'bar' },
      { label: 'Line', value: 'line' },
      { label: 'Pie', value: 'pie' },
      { label: 'Doughnut', value: 'doughnut' },
      { label: 'Polar Area', value: 'polarArea' },
      { label: 'Radar', value: 'radar' }
      // TODO: add dataset exception for bubble and scatter chart types
      // { label: 'Bubble', value: 'bubble' },
      // { label: 'Scatter', value: 'scatter' }
    ]
  }

  updateTable (table) {
    this.props.contextProvider.updateResult('tables', table, {
      parent: 'features',
      tableIndex: table.tableIndex,
      pageIndex: table.pageIndex
    })
  }

  calculateFontSize (content) {
    const contentLength = content.length
    let fontSize = '0.9rem' // default font size

    if (contentLength > 2) {
      fontSize = '0.65rem'
    }

    return fontSize
  }

  calculateChartChanges (prevState) {
    return (prevState.chartType !== this.state.chartType) || !isEqual(prevState.selectedTable, this.state.selectedTable) || !isEqual(prevState.dataType, this.state.dataType)
  }

  updateChart () {
    if (this.state.selectedTable) {
      this.state.chart?.destroy?.()

      const ctx = document.getElementById(`chart-canvas`)
      const table = this.state.selectedTable.table
      if (ctx) {
        try {
          const aggregated = this.state.aggregated
          let labels = table.rows.slice(1).map(row => row.cells[0].text)
          const columnNames = table.rows[0].cells.slice(1).map(cell => cell.text)
          const datasets = []

          for (let i = 0; i < columnNames.length; i++) {
            const columnValues = table.rows.slice(1).map(row => getFirstFloat(row.cells[i + 1].text))
            datasets.push({
              label: columnNames[i],
              data: columnValues,
              borderWidth: 1
            })
          }

          if (aggregated) {
            const duplicateLabels = new Set()
            const labelIndexMap = new Map()

            for (let i = 0; i < labels.length; i++) {
              if (labelIndexMap.has(labels[i])) {
                duplicateLabels.add(labels[i])
                labelIndexMap.get(labels[i]).push(i)
              } else {
                labelIndexMap.set(labels[i], [i])
              }
            }

            const indexesToAggregate = Array.from(duplicateLabels).reduce((acc, label) => {
              const indices = labelIndexMap.get(label).slice(1)
              return [...acc, ...indices]
            }, [])

            datasets.forEach(dataset => {
              const modifiedData = []

              dataset.data.forEach((data, index) => {
                if (indexesToAggregate.includes(index)) {
                  modifiedData[modifiedData.length - 1] += data
                } else {
                  modifiedData.push(data)
                }
              })

              dataset.data = modifiedData
            })

            labels = [...new Set(labels)]
          }

          const chart = new Chart(ctx, {
            type: this.state.chartType,
            data: {
              labels,
              datasets
            },
            options: {
              indexAxis: this.state.horizontal ? 'y' : 'x',
              scales: this.state.stacked
                ? {
                    x: {
                      stacked: true
                    },
                    y: {
                      stacked: true
                    }
                  }
                : {
                    y: {
                      beginAtZero: true
                    }
                  },
              responsive: false
            }
          })

          if (this.isPolarChartType()) {
            const backgroundColors = chart?.data?.datasets?.[0]?.backgroundColor
            chart.data.datasets.forEach(dataset => {
              dataset.backgroundColor = backgroundColors ?? dataset.backgroundColor
            })
          }

          this.setState({ chart }, () => {
            this.state.chart?.resize?.()
          })
        } catch (error) {
          console.error('chartjs error', error)
        }
      }
    } else {
      this.setState({ chartType: 'bar' })
    }
  }

  componentDidUpdate (_prevProps, prevState) {
    if (prevState.selectedTable !== this.state.selectedTable) {
      this.props.contextProvider.setState({
        doesPopupActive: !!this.state.selectedTable
      })
    }

    if (this.calculateChartChanges(prevState)) {
      this.updateChart()
    }
  }

  isBasicChartType () {
    return ['bar', 'line'].includes(this.state.chartType)
  }

  isPolarChartType () {
    return ['pie', 'doughnut', 'polarArea'].includes(this.state.chartType)
  }

  getTablesToShow () {
    return this.state.seeMore
      ? this.props.tables
      : this.props.tables.slice(0, this.displayLimit)
  }

  renderExtendableTableIcon () {
    const tablesToShow = this.getTablesToShow()

    return tablesToShow.map((table, index) => {
      return (
        <div
          key={index}
          className='table-numerator-item'
          style={{ fontSize: this.calculateFontSize(String(index)) }}
          title={table.table?.title}
          onClick={() => {
            this.setState({ selectedTable: table, selectedTableIndex: index })
          }}
        >
          <div className='table-numerator-item-title'>
            {table.table?.title ?? (index + 1)}
          </div>
          {table.table?.isValid && <MaterialIcon name='check_circle' title='Valid table' />}
        </div>
      )
    })
  }

  downloadCSV (selectedTable) {
    const data = []
    if (!selectedTable?.table?.rows?.length) return
    const header = []
    selectedTable.table.rows.forEach((row, rowIndex) => {
      if (!row?.cells?.length) return
      const obj = {}
      row.cells.forEach((cell, index) => {
        if (!rowIndex) {
          header.push(`Column ${index + 1}`)
        }
        obj[header[index]] = cell.text
      })
      data.push(obj)
    })
    if (!data?.length) return
    try {
      const url = window.URL.createObjectURL(
        new Blob([new Json2csv(header).parse(data)])
      )
      const a = document.createElement('a')
      a.href = url
      a.download = `Base64ai table extraction table ${selectedTable.tableIndex + 1}.csv`
      a.click()
      a.remove()
      setTimeout(() => window.URL.revokeObjectURL(url), 100)
    } catch (e) {
      console.error(e)
    }
  }

  downloadChart () {
    const chart = this.state.chart

    if (chart) {
      const canvas = chart.canvas
      const ctx = canvas.getContext('2d')

      // Save the current state
      ctx.save()

      // Set the background to white
      ctx.globalCompositeOperation = 'destination-over'
      ctx.fillStyle = this.props.context?.flow?.hitl?.chartBackground ?? '#ffffff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Create a temporary link element to trigger the download
      const a = document.createElement('a')
      a.href = canvas.toDataURL('image/png')
      a.download = `${this.getSelectedTableTitle()}_chart.png`

      // Trigger the download
      a.click()

      // Restore the original state
      ctx.restore()
      this.state.chart.update()
    }
  }

  selectionHandler (force) {
    if (
      (!this.props.contextProvider || !this.state.selectedLocation)
      && !force
    ) {
      return
    }
    this.props.contextProvider.setState({
      selectionContext: 'table',
      selectionDomId: this.state.isSelected ? this.state.selectionDomId : null,
      selectionLocation: this.state.isSelected
        ? this.state.selectedLocation
        : null,
      selectionRelativeIndex: this.state.isSelected
        ? this.state.selectedLocation.pageNumber - this.props.context.startPage
        : null,
      isSelected: this.state.isSelected
    })
  }

  blurHandler () {
    this.setState({ isSelected: false, selectionDomId: null }, () =>
      this.selectionHandler(true)
    )
  }

  focusHandler (selectedLocation, selectionDomId) {
    this.setState(
      {
        isSelected: true,
        selectedLocation,
        selectionDomId
      },
      () => this.selectionHandler()
    )
  }

  printRow (row, tableObject, indexes, isHeader) {
    const getClassName = index =>
      tableObject.table.rows[indexes.row].cells[index].text
      && tableObject.table.rows[indexes.row].cells[index].confidence
      < (this.props.context?.flow?.hitl?.tableHighlightThreshold ?? 10) / 100
        ? 'cell-warning'
        : ''
    return (
      <tr>
        {row.cells.map((cell, index) => {
          const warningClass = getClassName(index)
          const location = tableObject.table.rows[indexes.row].cells[index].location
          const className = warningClass
            + (`tableRow${indexes.row}Cell${index}` === this.state.selectionDomId
              ? ' focus'
              : '')
          return this.state.mode === 'view' || this.props.isEditDisabled
            ? (isHeader
                ? (
                    <th
                      tabIndex='0'
                      id={`tableRow${indexes.row}Cell${index}`}
                      key={index}
                      className={className}
                      onFocus={e => this.focusHandler(location, e.target.id)}
                    >
                      {cell.text}
                    </th>
                  )
                : (
                    <td
                      tabIndex='0'
                      id={`tableRow${indexes.row}Cell${index}`}
                      key={index}
                      className={className}
                      onFocus={e => this.focusHandler(location, e.target.id)}
                    >
                      {cell.text}
                    </td>
                  )
              )
            : (
                <div
                  className={
                    'editable-row-container'
                    + (`tableRow${indexes.row}Cell${index}` === this.state.selectionDomId ? ' focus' : '')
                  }
                >
                  <input
                    className={'hitl-input ' + warningClass}
                    value={cell.text}
                    id={`tableRow${indexes.row}Cell${index}`}
                    onChange={e => {
                      // set original value
                      const originalValue = tableObject.table.rows[indexes.row].cells[index]
                        .originalValue
                      const currentValue = tableObject.table.rows[indexes.row].cells[index].text
                      tableObject.table.rows[indexes.row].cells[index].originalValue = originalValue ?? currentValue
                      tableObject.table.rows[indexes.row].cells[index].text = e.target.value
                      tableObject.table.rows[indexes.row].cells[index].confidence = 1
                      if (
                        tableObject.table.rows[indexes.row].cells[index].originalValue === tableObject.table.rows[indexes.row].cells[index].text
                      ) {
                        delete tableObject.table.rows[indexes.row].cells[index].originalValue
                      }
                      this.updateTable(tableObject)
                    }}
                    onFocus={e => this.focusHandler(location, e.target.id)}
                  />
                  <div className='right-buttons'>
                    <Dropdown
                      isAlignedRight={false}
                      className='hitl-options'
                      style={{}}
                      title='&middot;&middot;&middot;'
                      items={[
                        {
                          label: 'Add column left',
                          action: () => {
                            tableObject.table.rows.forEach(row => {
                              row.cells.splice(index, 0, {
                                text: '',
                                confidence: 1
                              })
                            })
                            this.updateTable(tableObject)
                          }
                        },
                        {
                          label: 'Add column right',
                          action: () => {
                            tableObject.table.rows.forEach(row => {
                              row.cells.splice(index + 1, 0, {
                                text: '',
                                confidence: 1
                              })
                            })
                            this.updateTable(tableObject)
                          }
                        },
                        {
                          label: 'Add row above',
                          action: () => {
                            const newRow = { cells: [] }
                            tableObject.table.rows[indexes.row].cells.forEach(
                              () => {
                                newRow.cells.push({ text: '', confidence: 1 })
                              }
                            )
                            tableObject.table.rows.splice(indexes.row, 0, newRow)
                            this.updateTable(tableObject)
                          }
                        },
                        {
                          label: 'Add row below',
                          action: () => {
                            const newRow = { cells: [] }
                            tableObject.table.rows[indexes.row].cells.forEach(
                              () => {
                                newRow.cells.push({ text: '', confidence: 1 })
                              }
                            )
                            tableObject.table.rows.splice(
                              indexes.row + 1,
                              0,
                              newRow
                            )
                            this.updateTable(tableObject)
                          }
                        },
                        {
                          label: 'Merge with the right column',
                          action: () => {
                            if (
                              index
                              === tableObject.table.rows[indexes.row].cells.length - 1
                            ) {
                              return
                            }
                            tableObject.table.rows.forEach(row => {
                              if (row.cells[index + 1]?.text) {
                                row.cells[index].originalValue
                              = row.cells[index].originalValue
                              ?? row.cells[index].text
                                row.cells[index].text
                              = row.cells[index].text
                              + ' '
                              + row.cells[index + 1]?.text
                                row.cells[index].text
                              = row.cells[index].text.trim()

                                // merge box and location
                                if (row.cells[index + 1]?.box) {
                                  row.cells[index].box = mergeBoxDict(
                                    row.cells[index].box,
                                    row.cells[index + 1]?.box
                                  )
                                }
                                if (row.cells[index + 1]?.location) {
                                  row.cells[index].location = mergeLocation(
                                    row.cells[index].location,
                                    row.cells[index + 1]?.location
                                  )
                                }
                              }
                              row.cells.splice(index + 1, 1)
                            })
                            this.updateTable(tableObject)
                          }
                        },
                        {
                          label: 'Merge with the row below',
                          action: () => {
                            if (indexes.row === tableObject.table.rows.length - 1) {
                              return
                            }
                            tableObject.table.rows[indexes.row + 1]?.cells?.forEach(
                              (cell, index) => {
                                if (cell?.text) {
                                  tableObject.table.rows[indexes.row].cells[
                                    index
                                  ].originalValue
                                = tableObject.table.rows[indexes.row].cells[index]
                                      .originalValue
                                      ?? tableObject.table.rows[indexes.row].cells[index]
                                        .text
                                  tableObject.table.rows[indexes.row].cells[
                                    index
                                  ].text
                                = tableObject.table.rows[indexes.row].cells[index]
                                      .text
                                      + ' '
                                      + cell?.text
                                  tableObject.table.rows[indexes.row].cells[
                                    index
                                  ].text
                                = tableObject.table.rows[indexes.row].cells[
                                      index
                                    ].text.trim()

                                  if (cell?.box) {
                                    tableObject.table.rows[indexes.row].cells[
                                      index
                                    ].box = mergeBoxDict(
                                      tableObject.table.rows[indexes.row].cells[
                                        index
                                      ].box,
                                      cell?.box
                                    )
                                  }
                                  if (cell?.location) {
                                    tableObject.table.rows[indexes.row].cells[
                                      index
                                    ].location = mergeLocation(
                                      tableObject.table.rows[indexes.row].cells[
                                        index
                                      ].location,
                                      cell?.location
                                    )
                                  }
                                }
                              }
                            )
                            tableObject.table.rows.splice(indexes.row + 1, 1)
                            this.updateTable(tableObject)
                          }
                        },
                        {
                          label: 'Approve cell',
                          action: () => {
                            tableObject.table.rows[indexes.row].cells[
                              index
                            ].confidence = 1
                            this.updateTable(tableObject)
                          }
                        },
                        {
                          label: 'Delete column',
                          action: () => {
                            if (tableObject?.table?.rows?.[0].cells?.length === 1) {
                              this.deleteTable()
                              return
                            }
                            tableObject.table.rows.forEach(row => {
                              row.cells.splice(index, 1)
                            })
                            this.updateTable(tableObject)
                          }
                        },
                        {
                          label: 'Delete row',
                          action: () => {
                            if (tableObject?.table?.rows?.length === 1) {
                              this.deleteTable()
                              return
                            }
                            tableObject.table.rows.splice(indexes.row, 1)
                            this.updateTable(tableObject)
                          }
                        }
                      ]}
                    />
                  </div>
                </div>
              )
        })}
      </tr>
    )
  }

  deleteTable () {
    const tables = this.props.tables.filter(
      table => table.tableIndex !== this.state.selectedTable.tableIndex
    )
    this.props.contextProvider.updateResult('tables', tables, {
      parent: 'features',
      tableIndex: this.state.selectedTable.tableIndex,
      pageIndex: this.state.selectedTable.pageIndex,
      remove: true
    })
    this.blurHandler()
    this.setState({ selectedTable: null, mode: 'view' })
  }

  printHeader (tableObject) {
    return this.printRow(
      tableObject.table.rows[0],
      tableObject,
      { row: 0 },
      true
    )
  }

  printBody (tableObject) {
    return tableObject.table.rows
      .map((row, index) => {
        if (!index) {
          return null
        }
        return this.printRow(row, tableObject, { row: index })
      })
      .filter(s => s)
  }

  getSelectedTableTitle () {
    return this.state.selectedTable?.table?.title || `Table ${this.state.selectedTableIndex + 1}`
  }

  cloneTable () {
    const clonedTable = structuredClone(this.state.selectedTable)
    clonedTable.table.source = clonedTable.table.source ? `${clonedTable.table.source} clone` : 'clone'
    this.props.contextProvider.updateResult('tables', clonedTable, { parent: 'features', tableIndex: clonedTable.tableIndex, pageIndex: clonedTable.pageIndex, add: true })
  }

  transposeTable () {
    const transposedTable = {
      ...this.state.selectedTable,
      table: {
        ...this.state.selectedTable.table,
        rows: this.state.selectedTable.table.rows[0].cells.map((_, index) => ({
          cells: this.state.selectedTable.table.rows.map(row => row.cells[index])
        })),
        isValid: true
      }
    }
    this.setState({ selectedTable: transposedTable })
    this.updateTable(transposedTable)
  }

  render () {
    return (
      <DataExtractionBox
        title={this.props.header}
        selectedDataReference={this.id}
        isEditable={false}
        isRemovable={false}
        desiredEditMode='result'
        context={this.props.context}
        contextProvider={this.props.contextProvider}
      >
        {this.state.selectedTable && (
          <Popup
            editTitle={this.state.mode !== 'view'}
            title={this.getSelectedTableTitle()}
            defaultPosition={this.props.context?.flow?.hitl?.tablePosition}
            onResize={e => {
              try {
                this.state.chart?.resize?.(e.target.clientWidth - 20, e.target.clientHeight)
              } catch (error) {
                console.error('Chart resize error!', error)
              }
            }}
            onTitleChange={title => {
              const data = { ...this.state.selectedTable }
              data.table.title = title.trim()
              this.updateTable(data)
            }}
            closeHandler={() => {
              this.blurHandler()
              this.setState({ selectedTable: null, mode: 'view', dataType: 'table' })
            }}
            customHeader={() => (
              <div className='header-container select-none'>
                <div
                  className={clsx('tab-item hover-underline dashed', this.state.dataType === 'table' && 'active')}
                  onClick={() => {
                    this.setState({ dataType: 'table', mode: 'view' })
                  }}
                >
                  <MaterialIcon name='view_module' />
                  Table
                </div>
                <div
                  className={clsx('tab-item hover-underline dashed', this.state.dataType !== 'table' && 'active')}
                  onClick={() => {
                    this.setState({ dataType: 'chart', mode: 'view' })
                    this.blurHandler()
                  }}
                >
                  <MaterialIcon name='monitoring' />
                  Chart
                </div>
              </div>
            )}
            customIcons={() => (
              <div className='icon-container select-none'>
                {/* Delete table */}
                {!this.props.context?.isEditDisabled
                && !this.props.isEditDisabled && (
                  <MaterialIcon
                    className={clsx('delete-button edit-button', this.state.dataType === 'chart' && 'hidden')}
                    title='Delete this table'
                    name='delete'
                    onClick={() => {
                      this.deleteTable()
                    }}
                  />
                )}
                {/* Transpose table */}
                {!this.props.context?.isEditDisabled && !this.props.isEditDisabled && this.state.dataType !== 'chart' && (
                  <MaterialIcon
                    className='edit-button'
                    title='Transpose this table'
                    name='screen_rotation_alt'
                    onClick={() => {
                      this.transposeTable()
                    }}
                  />
                )}
                {/* Clone the table */}
                {!this.props.context?.isEditDisabled && !this.props.isEditDisabled && this.state.dataType !== 'chart' && (
                  <MaterialIcon
                    className='clone-button edit-button'
                    title='Clone this table'
                    name='content_copy'
                    onClick={() => {
                      this.cloneTable()
                    }}
                  />
                )}
                {this.state.mode === 'view' && !this.props.isEditDisabled && (
                  <MaterialIcon
                    className='edit-button'
                    name='download'
                    title={this.state.dataType === 'chart' ? 'Download chart as PNG file' : 'Download table as a CSV file'}
                    onClick={() => {
                      this.state.dataType === 'chart' ? this.downloadChart() : this.downloadCSV(this.state.selectedTable)
                    }}
                  />
                )}
                {!this.props.context?.isEditDisabled
                && !this.props.isEditDisabled && this.state.dataType !== 'chart' && (

                  <MaterialIcon
                    className={clsx('edit-button', this.state.mode !== 'view' && 'active')}
                    name={this.state.mode === 'view' ? 'edit' : 'save'}
                    title={
                      this.state.mode === 'view'
                        ? 'Edit table'
                        : 'Save table'
                    }
                    onClick={() => {
                      this.blurHandler()
                      this.setState({
                        mode: this.state.mode === 'view' ? 'edit' : 'view'
                      })

                      if (this.state.mode !== 'view') {
                        const selectedTable = structuredClone(this.state.selectedTable)
                        selectedTable.table.isValid = true

                        this.setState({ selectedTable })
                        this.updateTable(selectedTable)
                      }
                    }}
                  />

                )}
              </div>
            )}
            moveHandler={position => {
              this.props.contextProvider.setState({
                movingPopupPosition: position
              })
            }}
          >

            <div className={clsx('chart-container', this.state.dataType !== 'chart' && 'hidden')}>
              <div className='chart-content'>
                <div className='select-label'> Chart type: </div>
                <Select
                  classNamePrefix='react-select'
                  className='react-select'
                  defaultValue={this.chartOptions?.[0]}
                  styles={{
                    control: base => ({
                      ...base,
                      height: '2.6rem'
                    }),
                    container: provided => ({
                      ...provided,
                      width: '100%',
                      maxWidth: '8rem'
                    })
                  }}
                  options={this.chartOptions}
                  closeMenuOnSelect
                  placeholder='Select chart type'
                  noOptionsMessage={() => 'No type found'}
                  onChange={changed => {
                    this.setState({ chartType: changed.value })
                    if (!this.isBasicChartType()) {
                      // reset horizontal and stacked options
                      this.setState({
                        horizontal: false,
                        stacked: false
                      })
                    }
                    Promise.resolve().then(() => {
                      this.state.chart?.resize?.()
                    })
                  }}
                />
                <div className='chart-options'>
                  <Checkbox
                    labelClassName='chart-option-label'
                    checked={this.state.aggregated}
                    placeholder='Aggregate values on X axis'
                    onChange={e => {
                      this.setState({
                        aggregated: e.target.checked
                      }, () => {
                        this.updateChart()
                      })
                    }}
                  />
                  {this.isBasicChartType() && (
                    <>
                      <Checkbox
                        labelClassName='chart-option-label'
                        checked={this.state.horizontal}
                        placeholder='Horizontal chart'
                        onChange={e => {
                          this.setState({
                            horizontal: e.target.checked
                          }, () => {
                            this.updateChart()
                          })
                        }}
                      />
                      <Checkbox
                        labelClassName='chart-option-label'
                        checked={this.state.stacked}
                        placeholder='Stacked chart'
                        onChange={e => {
                          this.setState({
                            stacked: e.target.checked
                          }, () => {
                            this.updateChart()
                          })
                        }}
                      />
                    </>
                  )}

                </div>
              </div>
              <canvas id='chart-canvas' />
            </div>

            {this.state.dataType === 'table' && (
              <div
                className='table-view-body'
                onScroll={e => {
                  this.props.contextProvider.setState({
                    movingPopupInnerScrollTop: e.target.scrollTop,
                    movingPopupInnerScrollLeft: e.target.scrollLeft
                  })
                }}
              >
                <table
                  style={{
                    width: '100%',
                    minWidth:
                      this.state.selectedTable.table.rows[0].cells.length * 12
                      + 'rem'
                  }}
                >
                  <thead>{this.printHeader(this.state.selectedTable)}</thead>
                  <tbody>{this.printBody(this.state.selectedTable)}</tbody>
                </table>
              </div>
            )}
          </Popup>
        )}
        <div className='table-numerator'>
          {this.renderExtendableTableIcon()}
        </div>
        <div className='table-numerator-controls'>
          {this.props.tables?.length > this.displayLimit && (
            <Link
              className='add-table'
              onClick={() => this.setState({ seeMore: !this.state.seeMore })}
            >
              See {!this.state.seeMore ? 'more' : 'less'}
            </Link>
          )}
          <br />
          {!this.props.context?.isEditDisabled
          && !this.props.isEditDisabled && (
            <Link
              className='add-table'
              onClick={() => {
                const pageIndex = this.props.context.activeIndex || 0
                const table = {
                  tableIndex: this.props.tables.length,
                  pageIndex: pageIndex,
                  table: {
                    text: '',
                    rows: [
                      {
                        text: '',
                        cells: [
                          {
                            text: '',
                            confidence: 1
                          }
                        ]
                      }
                    ]
                  }
                }
                this.props.contextProvider.updateResult('tables', table, {
                  parent: 'features',
                  tableIndex: table.tableIndex,
                  pageIndex,
                  add: true
                })
              }}
            >
              Add new table
            </Link>
          )}
        </div>
      </DataExtractionBox>
    )
  }
}

export default TableBox
