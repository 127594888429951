export default {
  basicInformation: {
    title: 'Basic information',
    description: 'You can edit the basic Information required for your Flow.'
  },
  aiFeatures: {
    title: 'AI features',
    description: 'Fine-tune AI features to suit your specific needs.'
  },
  enhancements: {
    title: 'Enhancements',
    description: 'Changes made here will be reflected in the API result.'
  },
  restrictions: {
    title: 'Restrictions',
    description: 'These settings are useful for special scenarios only. Enabling them could break regular data extraction.'
  },
  integration: {
    title: 'Integrations',
    description: 'Integrations allow your Flow to communicate with over 400 popular platforms such as Google Drive, Dropbox, and Salesforce using the built-in no-code components. '
  },
  reviewPage: {
    title: 'Review page',
    description: 'Adjust the Flow User Interface (UI) settings to review the documents more efficiently.'
  },
  dangerZone: {
    title: 'Delete',
    description: 'This is for pausing or deleting processes within that particular stream.',
    class: 'risk'
  }
}
