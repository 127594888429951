import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import SortableTable from 'react-sortable-table'

// Context
import Context from '~/context/global'

// Interface
import Button from '~/interface/button/Button'
import Link from '~/interface/link/Link'
import Typewriter from 'typewriter-effect'

// Layout
import View from '~/layout/view/View'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'

// Helpers
// import { reCaptcha } from '~/helpers/authentication'
import { request } from '../../helpers/request'
import { v4 as uuid } from 'uuid'
import { reCaptcha } from '../../helpers/authentication'
import { getBase64 } from '../../helpers/file'
import { camelCaseToTitleCase, capitalizeFirstLetter, formatNumber, toIsoDate } from '../../utilities/format'
import window from 'global/window'
import document from 'global/document'
import MaterialIcon from '../../interface/material-icon/MaterialIcon'
import FlowSearchInput from '../../interface/flow-search-input/FlowSearchInput'
import { isWorkingOffline } from '../../context/environment'
import LoadingModal from '../../interface/loading-modal/LoadingModal'
import { Helmet } from 'react-helmet'
import constants from '~/data/constants'
import additionalListColumnsFields from '../../data/additionalListColumnsFields'
import StatDisplaySection from '../../content/chart-display-section/stat-display-section/StatDisplaySection'
import FullscreenDropzone from '../../interface/fullscreen-dropzone/FullscreenDropzone'
import FilterModalContent from '../../interface/flow-icon-modal/FilterModalContent'
import UploadDocumentModalContent from '../../interface/flow-icon-modal/UploadDocumentModalContent'
import BackButton from '../../interface/back-button/BackButton'
import { getCustomModels } from '../../helpers/getCustomModels'
import { handleModels } from '../../helpers/handleModels'
import { rerunIntegration } from '../../helpers/integration'
import clsx from 'clsx'
import { isEqual } from 'radash'

class ShowFlowResults extends Component {
  static contextType = Context

  static propTypes = {
    user: PropTypes.object,
    flowID: PropTypes.string,
    models: PropTypes.array,
    history: PropTypes.object,
    filter: PropTypes.string,
    limit: PropTypes.string,
    getModels: PropTypes.func,
    setFlows: PropTypes.func,
    flows: PropTypes.array,
    searchParamsObject: PropTypes.object,
    changeSearchParams: PropTypes.func
  }

  static defaultProps = {
    user: null,
    flowID: null
  }

  constructor (props) {
    super(props)

    this.pickSample = this.pickSample.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this)
    this.processFiles = this.processFiles.bind(this)
    this.updateFailedResponse = this.updateFailedResponse.bind(this)
    this.createProcessingResult = this.createProcessingResult.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
    this.handlePastedFiles = this.handlePastedFiles.bind(this)
    this.flowSearchRef = createRef()
    this.setAllModels = this.setAllModels.bind(this)
    this.rerunIntegration = rerunIntegration.bind(this)
    this.handleTableClick = this.handleTableClick.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.deselectAllrows = this.deselectAllrows.bind(this)
    this.deleteSelectedResults = this.deleteSelectedResults.bind(this)
    this.deleteResults = this.deleteResults.bind(this)
    this.getSelectedFilter = this.getSelectedFilter.bind(this)

    this.statuses = [
      { label: 'Needs review', value: 'needsReview', color: 'orange' },
      { label: 'Rejected', value: 'rejected', color: 'red' },
      { label: 'Approved', value: 'approved', color: 'green' },
      { label: 'Auto-approved', value: 'autoApproved', color: 'green' },
      { label: 'Processing...', value: 'processing', internal: true },
      { label: 'Failed', value: 'failed', internal: true, color: 'red' },
      { label: 'Error', value: 'error', internal: true, color: 'red' }
    ]

    this.filterMethods = this.statuses.filter(s => !s.internal)

    this.state = {
      user: this.props.user,
      isLoaded: false,
      messageObject: {
        title: 'Retrieving Flow results...',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      },
      results: [],
      prevResults: [],
      flow: null,
      limit: 25,
      selectedModels: [],
      allModels: [],
      selectedFilter: this.props.searchParamsObject?.filter?.map(filter => this.filterMethods.find(f => f.value === filter))?.map(x => {
        x.checked = true
        return x
      }) || [],
      currentFilter: [],
      selectedResults: [],
      currentRowIndex: 1,
      shouldLoad: true,
      lastPaginationTSQuery: null,
      showSample: false,
      uploadSectionShow: false,
      currentTab: props.filter ? 'flow-stats' : 'flow-results',
      isMobile: window?.innerWidth < 767,
      remountKey: 0
    }
  }

  HEADER_STYLE = {
    padding: '0.5rem',
    whiteSpace: 'nowrap',
    margin: '0.1rem',
    backgroundColor: 'white',
    cursor: 'pointer',
    fontWeight: 'bold',
    width: '30%',
    color: '#333366',
    fontSize: '0.9rem'
  }

  DATA_STYLE = {
    verticalAlign: 'middle',
    padding: '0.5rem',
    color: '#000000',
    borderTop: '1px solid #eeeeee'
  }

  LONG_DATA_STYLE = {
    ...this.DATA_STYLE,
    verticalAlign: 'middle',
    whiteSpace: 'nowrap'
  }

  CONFIGURATION = {
    results: [],
    base: [
      {
        header: 'File name',
        key: 'fileName',
        renderWithData: data => {
          let prefix = ''
          if (data?.linkedResults?.length > 1) {
            const index = data?.linkedResults.indexOf(data.resultUuid)
            prefix = index !== -1 ? `Document ${index + 1} - ` : ''
          }
          const fileName = prefix + data.fileName
          if (data.done === false || (!this.state.flow?.role?.includes('reviewer') && !this.state.flow?.role?.includes('owner') && !this.state.flow?.role?.includes('administrator'))) {
            return fileName
          }
          if (!data.resultUuid) {
            return <span title='The result and uploaded document are not stored due to the flow settings.'>{fileName}</span>
          }

          return <Link to={'/hitl/' + data.resultUuid} title={fileName} className='id-cell'>{fileName}</Link>
        },
        headerStyle: { ...this.HEADER_STYLE, width: '30%' },
        dataStyle: { ...this.DATA_STYLE, fontWeight: '400', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '1px' }
      },
      {
        header: 'Status',
        key: 'status',
        renderWithData: data => {
          const showLink = data.done !== false && (this.state.flow?.role?.includes('reviewer') || this.state.flow?.role?.includes('owner') || this.state.flow?.role?.includes('administrator'))
          const isFailed = data.status === 'failed' || data.status === 'error'
          if (!data.resultUuid && data.done !== false && data.status !== 'failed') {
            return <span title='The result and uploaded document are not stored due to the flow settings.'>{this.statuses.find(q => q.value === data.status)?.label}</span>
          }
          return (
            <span
              onClick={() => {
                if (isFailed) {
                  this.showMessage(data.rejectionReason,
                    true,
                    {
                      isWide: false,
                      errorCode: data.errorCode,
                      resultUuid: data.resultUuid,
                      result: data
                    })
                } else if (showLink) {
                  this.context.redirect('/hitl/' + data.resultUuid)
                }
              }}
              style={
                {
                  cursor: showLink || isFailed ? 'pointer' : '',
                  fontWeight: '400',
                  color: this.statuses.find(q => q.value === data.status)?.color
                }
              }
            >
              { this.statuses.find(q => q.value === data.status)?.label }
            </span>
          )
        },
        headerStyle: { ...this.HEADER_STYLE, width: '15%' },
        dataStyle: { ...this.DATA_STYLE }
      }
    ]
  }

  filterAction = () => {
    const { selectedFilter } = this.state
    this.context.displayModal({
      title: 'Filter results',
      content: (
        <FilterModalContent
          selectedFilter={selectedFilter}
          filterMethods={this.filterMethods}
          onChangeHandler={value => this.setState({ selectedFilter: value })}
        />
      ),
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: 'Apply',
          action: () => {
            this.setState({ shouldLoad: true })
            this.props.changeSearchParams('filter', this.state.selectedFilter.length > 0 ? this.state.selectedFilter.map(filter => filter.value).join(',') : null)
          }
        }],
      style: {
        minWidth: '30%'
      },
      isClosable: true
    })
  }

  exportResultAction = () => {
    const selectedRows = Array.from(document.querySelectorAll('.selected-row'))
    const selectedResults = selectedRows.map(row => row.querySelector('.id-cell').href.split('/').pop())

    if (selectedResults.length === 0) {
      return this.context.displayModal({
        title: 'Warning',
        content: (
          <div>
            <p>Select the results you want to download first. Use shift-click or ctrl-click to select multiple results at once.</p>
          </div>
        ),
        options: [{
          content: 'OK',
          intent: 'positive'
        }]
      })
    }

    this.context.displayModal({
      title: 'Question',
      content: (
        <div>
          <p>
            Do you want to email {selectedResults.length} {selectedResults.length === 1 ? 'result' : 'results'} to {this.props.user.email}?
          </p>
        </div>
      ),
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: 'Yes',
          action: () => {
            this.downloadRequest(selectedResults)
          }
        }]
    })
  }

  getSelectedFilter = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const newFilter = searchParams.get('filter')?.split(',').map(filter => this.filterMethods.find(f => f.value === filter))?.map(x => {
      x.checked = true
      return x
    }) || []
    return newFilter
  }

  deselectAllrows = (resetIndex = false) => {
    const selectedRows = document.querySelectorAll('tr.selected-row')
    selectedRows.forEach(tr => tr.classList.remove('selected-row'))
    if (resetIndex) {
      this.setState({ currentRowIndex: 1 })
    }
    this.setState({ selectedResults: [] })
  }

  isCompleteResult = resultRow => {
    // if id-cell has href, return true
    const idCell = resultRow.querySelector('.id-cell')
    return !!idCell?.href
  }

  handleTableClick = e => {
    const target = e.target
    let td = target
    if (target.classList.contains('m-icon')) { // top row buttons do not deselect rows
      return
    }
    if (!td) {
      return this.deselectAllrows(false)
    }
    for (let i = 0; i < 10; i++) {
      if (td.tagName === 'TD') {
        break
      }
      td = td.parentElement
      if (!td || td.tagName === 'HTML' || td.tagName === 'BODY' || td.tagName === 'ROOT') {
        return this.deselectAllrows(false)
      }
    }
    const tr = td.parentElement
    if (tr?.tagName !== 'TR' || !this.isCompleteResult(tr)) {
      return this.deselectAllrows(false)
    }
    const currentRowIndex = tr.rowIndex
    const lastRowIndex = this.state.currentRowIndex

    // if shift is held when clicked
    if (e.shiftKey) {
      // prevent default
      e.preventDefault()
      // get all trs between TR AND LAST
      const allTrs = document.querySelectorAll('tr')
      const startIndex = Math.min(currentRowIndex, lastRowIndex)
      const endIndex = Math.max(currentRowIndex, lastRowIndex)
      // add selected class to all trs between current and last and remove all other selected rows
      this.deselectAllrows(false)
      for (let i = startIndex; i <= endIndex; i++) {
        allTrs[i].classList.add('selected-row')
      }
    } else if (e.ctrlKey) {
      // prevent default
      e.preventDefault()
      tr.classList.toggle('selected-row')
    } else {
      // remove all other selected rows
      this.deselectAllrows()
      tr.classList.add('selected-row')
    }

    // if there is only 1 selected row, set currentRowIndex to the rowIndex of the clicked row
    if (document.querySelectorAll('.selected-row').length === 1) {
      this.setState({ currentRowIndex: currentRowIndex })
    }
    // set selectedResults to resultUUID of all .selected-row's
    const selectedRows = Array.from(document.querySelectorAll('.selected-row'))
    // get .id-cell's from each selected row and get the uuid from the href
    const selectedResults = selectedRows.map(row => row.querySelector('.id-cell').href.split('/').pop())
    this.setState({ selectedResults })
  }

  deleteSelectedResults = () => {
    const selectedRows = Array.from(document.querySelectorAll('.selected-row'))
    const selectedResults = selectedRows.map(row => row.querySelector('.id-cell').href.split('/').pop())

    if (selectedResults.length === 0) {
      return this.context.displayModal({
        title: 'Warning',
        content: (
          <div>
            <p>Select the results you want to delete first. Use shift-click or ctrl-click to delete multiple results.</p>
          </div>
        ),
        options: [{
          content: 'OK',
          intent: 'positive'
        }]
      })
    }
    this.context.displayModal({
      title: 'Delete results',
      content: (
        <div>
          <p>Are you sure you want to<br />delete {selectedResults.length} selected {selectedResults.length > 1 ? 'results' : 'result'}?</p>
        </div>
      ),
      options: [
        {
          content: 'Cancel'
        },
        {
          content: 'Delete',
          intent: 'negative',
          action: () => {
            this.deleteResults(selectedResults)
          }
        }
      ]
    })
  }

  deleteResults = (resultsToDelete = []) => {
    request({
      endpoint: '/result/delete',
      method: 'POST',
      body: { results: resultsToDelete }
    }, error => {
      if (error) {
        this.showMessage(error, true)
        return
      }
      this.context.displayModal({
        title: 'Success',
        content: (
          <div>
            <p>{resultsToDelete.length} {resultsToDelete.length === 1 ? 'result is' : 'results are'} deleted</p>
          </div>
        ),
        options: [{
          content: 'OK',
          intent: 'positive',
          action: () => {
            window.location.reload()
          }
        }],
        isClosable: false
      })
    })
    this.context.displayModal({})
    this.setState({
      messageObject: {
        title: 'Deleting results...',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      }
    })
  }

  displayUsageAction = () => {
    this.props.history.push(`/flow/${this.props.flowID}/usage/this-month${this.props.limit ? `/${this.props.limit}` : ''}`)
    this.setState({ currentTab: 'flow-stats', selectedFilter: [] }, this.makeRequest)
  }

  settingsAction = () => {
    const userRole = this.state.flow.role || []
    if (userRole.includes('owner') || userRole.includes('administrator')) {
      this.context.redirect(`/flow/${this.props.flowID}/edit`)
    } else {
      this.context.displayModal({
        title: 'Flow information',
        content: (
          <>
            <p style={{ textAlign: 'left' }}>
              <b>Flow owner:</b>
              {' '}
              <a
                href={`mailto:${this.state.flow.ownerEmail}`}
              >
                {this.state.flow.ownerEmail}
              </a>
              <br />
              <b>Flow ID:</b>
              {' '}
              {this.state.flow.flowID}
            </p>
          </>
        ),
        options: [{
          content: 'OK',
          intent: 'positive'
        }],
        isClosable: true
      })
    }
  }

  isFileUploaded = uuid => {
    return this.state.results.find(result => result.uuid === uuid)?.done
  }

  setAllModels () {
    const promises = []
    promises.push(new Promise(resolve => {
      getCustomModels(this.state.flow, customModelsData => {
        resolve(customModelsData)
      })
    }))
    if (this.props.getModels) {
      promises.push(new Promise(resolve => {
        this.props.getModels((error, response) => {
          if (!error) {
            resolve(response)
          }
        })
      }))
    }
    Promise.all(promises).then(([customModelsData, response]) => {
      const modelsData = handleModels(response, this.state.flow, customModelsData)
      const selectedModels = this.props.models.map(model => ({
        label: response[model] || customModelsData.find(f => f.value === model)?.label,
        value: model
      }))
      this.setState({ allModels: modelsData, selectedModels })
    })
  }

  uploadAction = () => {
    const userRole = this.state.flow.role || []
    const isAdminPrivilege = userRole.includes('owner') || userRole.includes('administrator')
    return (
      <div style={{ height: this.state.uploadSectionShow ? '10%' : '0%' }}>
        {this.state.uploadSectionShow && (
          <UploadDocumentModalContent
            allowRender={true}
            selectedModels={this.state.selectedModels}
            allModels={this.state.allModels}
            handleFiles={this.handleFiles}
            flow={this.state.flow}
            isFileUploaded={this.isFileUploaded}
            pickSample={(url, name) => {
              const pickSampleResponse = this.pickSample(url, name)
              return pickSampleResponse
            }}
            onChangeSelect={(filter, modelTitleNameString) => {
              this.setState({ selectedModels: filter, modelTitleNameString }, () => {
                this.context.redirect(`/flow/${this.props.flowID}/result` + (
                  filter === null
                    ? ''
                    : `/${filter.map(item => item.value).join('+')}`
                ))
              })
            }}
            isRenderSelect={(userRole && (isAdminPrivilege || userRole.includes('uploader')))}
          />
        )}
      </div>
    )
  }

  defaultSort = (a, b, key) => {
    if (!a || !b) {
      return 0
    }
    return a[key] >= b[key] ? 1 : -1
  }

  sortAsc = (data, key) => {
    data.sort((a, b) => {
      return this.defaultSort(a.additionalListColumns, b.additionalListColumns, key)
    })
    return data
  }

  sortDesc = (data, key) => {
    data.sort((a, b) => {
      return this.defaultSort(b.additionalListColumns, a.additionalListColumns, key)
    })
    return data
  }

  handleKeyPress = e => {
    document.onselectstart = function () {
      return !(e.key == 'Shift' && e.shiftKey)
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('click', this.handleTableClick)
    window.addEventListener('keyup', this.handleKeyPress)
    window.addEventListener('keydown', this.handleKeyPress)

    document.addEventListener('paste', this.handlePastedFiles)
    const flows = this.props.flows
    const flow = flows?.find(it => it.flowID === this.props.flowID)
    this.setState({ search: window.location.search })
    if (flow?.results && flow.results?.length > 0) {
      this.setState({
        isLoaded: true,
        messageObject: LoadingModal.getEmptyMessageObject(),
        results: flow.results,
        oldResults: flow.results,
        flow: flow,
        selectedResults: [],
        currentRowIndex: 1
      }, this.setAllModels())
    } else {
      // if there is no search param or there is a selected filter set (this comes in redirection), make api call. Otherwise, it will be made in componentDidUpdate
      if (!window.location.search || this.state.selectedFilter.length) {
        this.makeRequest()
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('click', this.handleTableClick)
    window.removeEventListener('keyup', this.handleKeyPress)
    window.removeEventListener('keydown', this.handleKeyPress)

    document.removeEventListener('paste', this.handlePastedFiles)
    if (this.state.selectedFilter?.length) {
      this.setState({
        results: null,
        oldResults: null,
        flow: null
      })
    }
  }

  resizeHandler () {
    this.setState({
      isMobile: window.innerWidth < 767
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.flowSearch !== this.state.flowSearch) {
      return this.forceUpdate()
    }
    if (prevState.selectedFilter !== this.state.selectedFilter) {
      if (!this.state.currentFilter.length && !this.state.selectedFilter.length) {
        return this.setState({ shouldLoad: false })
      }
      let callback = () => {}
      if (prevState.selectedFilter.length > 0 && !this.state.selectedFilter.length) {
        callback = this.makeRequest
      }
      this.setState({ shouldLoad: true }, callback)
    }
    if (prevState.showSample !== this.state.showSample) {
      this.forceUpdate()
    }
    if (window.location.pathname.includes('result') && this.state.currentTab === 'flow-stats') {
      this.setState({ currentTab: 'flow-results' })
    }
    if (prevState.flowSearch !== this.state.flowSearch
      || prevState.selectedFilter !== this.state.selectedFilter
      || prevState.showSample !== this.state.showSample) {
      this.setState(prevState => ({
        remountKey: prevState.remountKey + 1
      }))
    }

    if (!isEqual(prevProps.searchParamsObject, this.props.searchParamsObject) && this.state.currentTab === 'flow-results') { // if search params changed in flow result page, make a request
      this.setState({ selectedFilter: this.getSelectedFilter() }, this.makeRequest)
    }
  }

  showMessage = (message, isError, options = {}) => {
    this.context.displayModal({
      title: isError ? 'Error' : 'Success',
      content: (
        <div>{
          options.errorCode
            ? <p>Integration failed with error code <Link to='/error-codes'>{options.errorCode}</Link></p>
            : <p>{message}</p>
        }
        </div>
      ),
      options: [{
        content: 'OK',
        intent: 'positive'
      },
      options.errorCode && options.resultUuid
        ? {
            content: 'Rerun',
            intent: 'negative',
            action: () => {
              const result = this.state.results.find(result => result.resultUuid === options.resultUuid)
              if (result) {
                result.status = 'processing'
                this.setState({ results: this.state.results })
              }
              rerunIntegration(options.resultUuid, (err, response) => {
                if (err) {
                  if (result) {
                    result.status = 'error'
                  }
                  this.setState({ results: this.state.results })
                  return this.context.displayModal({
                    title: 'Error',
                    content: (<p>{err?.message}</p>),
                    options: [{
                      content: 'OK',
                      intent: 'positive'
                    }] })
                }
                if (result) {
                  result.status = response.status
                  this.setState({ results: this.state.results })
                }
              })
            }
          }
        : null
      ].filter(s => s),
      isClosable: true,
      isWide: options.isWide !== undefined ? options.isWide : true
    })
  }

  downloadRequest = selectedResults => {
    const endpoint = '/flow/download/' + this.props.flowID

    request({
      endpoint,
      method: 'POST',
      body: { results: selectedResults }
    }, (error, response) => {
      if (error) {
        this.showMessage(error, true)
        return
      }
      if (response.message !== 'OK') {
        this.showMessage('There was an error while sending results as email.', true)
      } else {
        this.showMessage(`${selectedResults.length} ${selectedResults.length === 1 ? 'result' : 'results'} sent to your email.`, false)
      }
    }, () => {
      this.showMessage('There was an error while sending results as email.', true)
    })
  }

  makeRequest = options => {
    if (!this.state.shouldLoad && !options?.force) {
      return
    }
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Retrieving Flow results...',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      },
      flow: null
    })

    let endpoint = '/result/?flowID=' + this.props.flowID + '&limit=' + this.state.limit
    if (options?.prev) {
      endpoint = `${endpoint}&prevTimestamp=${options.prev}`
    } else if (options?.next) {
      endpoint = `${endpoint}&nextTimestamp=${options.next}`
    }
    const filterQuery = this.state.selectedFilter.map(q => q.value.replace(/_/, '-')).join(',')
    if (filterQuery) {
      endpoint = `${endpoint}&filter=${filterQuery}`
    }
    // hide deleted results
    endpoint = `${endpoint}&hideDeleted=true`
    request({
      endpoint,
      method: 'GET'
    }, (error, response) => {
      if (response.message) {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while fetching Flow results',
            description: response.message,
            status: 'error',
            code: response.code
          }
        })
        return
      }
      if (error) {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while fetching Flow results',
            description: error,
            status: 'error',
            code: null
          }
        })
        return
      }
      this.setState({
        isLoaded: true,
        messageObject: LoadingModal.getEmptyMessageObject(),
        flow: response.flow,
        results: response.results?.length ? response.results : this.state.prevResults ? this.state.prevResults : [],
        oldResults: response.results,
        currentFilter: this.state.selectedFilter,
        shouldLoad: false,
        selectedResults: [],
        currentRowIndex: 1,
        remountKey: this.state.remountKey + 1
      }, () => {
        this.updateContext()
        this.setAllModels()
      })
    }, err => {
      this.setState({
        isLoaded: false,
        messageObject: {
          title: 'Error',
          description: 'Error happened while processing: ' + (err.message || err) + '.',
          status: 'error',
          code: err.code
        }
      })
    })
  }

  updateContext () {
    if (!this.props.flows || !this.props.setFlows) {
      return
    }
    let { flows } = this.props
    const { flow, insight, results } = this.state // fetch up-to-date flow & results from API
    if (results?.length) {
      flow.insight = insight
      flow.results = results
    }
    if (!flows) {
      flows = [flow]
    }
    const injected = flows.map(fl => {
      if (fl.flowID === flow.flowID) {
        return flow
      } else return fl
    })
    this.props.setFlows(injected)
  }

  getPaginatedResults = options => {
    const results = this.state.results
    this.setState({
      results: null,
      flow: null,
      prevResults: results
    })

    if (!results?.length) {
      this.makeRequest()
    } else if (options?.prev) {
      this.makeRequest({ prev: results[0].updatedAt || results[0].createdAt, force: true })
    } else if (options?.next) {
      this.makeRequest({ next: results[results.length - 1].updatedAt || results[results.length - 1].createdAt, force: true })
    }
    window.scrollTo(0, 0)
  }

  toShortName (name) {
    return name.substr(0, 200) + (name.length > 200 ? '...' : '')
  }

  createProcessingResult (file) {
    const date = new Date()
    return {
      fileName: this.toShortName(file.name),
      uuid: file.uuid,
      model: { name: 'Loading...' },
      updatedAt: date.getTime(),
      createdAt: date.getTime(),
      status: 'processing',
      isFirst: true,
      isLast: this.state.results.length === 0,
      done: false
    }
  }

  handleFiles = accepted => {
    accepted = accepted.map(file => ({
      name: file.name,
      uuid: uuid(),
      source: file,
      lastModifiedDate: file.lastModifiedDate
    }))
    let results = this.state.results
    accepted.forEach(file => {
      if (!file.name) {
        return
      }
      const fileObject = this.createProcessingResult(file)
      results = [fileObject, ...results]
    })
    this.setState({
      results
    })
    this.processFiles(accepted)
    return accepted.map(file => file.uuid)
  }

  processFiles (files, url) {
    if (url) {
      return this.uploadDocument(url)
    }
    files.forEach(file => {
      if (file.done) {
        return
      }
      getBase64(file, async (error, imageBase64) => {
        if (imageBase64 && imageBase64.startsWith('data:;')) {
          if (file.name.toLowerCase().endsWith('.msg')) {
            imageBase64 = imageBase64.replace('data:;', 'data:application/vnd.ms-outlook;')
          } else {
            error = true
          }
        }
        if (error) {
          const results = this.state.results
          return this.updateFailedResponse(results, error, file)
        }
        this.uploadDocument(imageBase64, file)
      })
    })
  }

  updateFailedResponse (results, error, file) {
    let result = results.find(rr => rr.uuid === file.uuid)
    let isResultExist = true
    if (!result) {
      isResultExist = false
      result = {
        uuid: file.uuid,
        fileName: this.toShortName(file.name)
      }
    }
    result.rejectionReason = error.message
    if (error.code) {
      result.rejectionReason += ` (Error code ${error.code})${error.identifier ? ` (${error.identifier.split(':').join(' ')})` : ''}`
    }
    result.done = false
    result.status = 'failed'
    result.model = { name: 'Failed' }
    return this.setState({
      results: isResultExist ? results : [result, ...results]
    })
  }

  uploadDocument (data = '', file) {
    const body = {
      originalFileName: file.name
    }
    if (data.startsWith('data:')) {
      body.document = data
    } else if (data.startsWith('http')) {
      body.url = data
      if (data.endsWith('/static/content/features/data-extraction/models//1.png')) {
        body.settings = {
          useSegmentation: true
        }
      }
    }
    if ((this.props.models || []).length) {
      body.modelTypes = this.props.models
    }
    reCaptcha('demo', token => {
      body.token = token
      request({
        endpoint: '/scan',
        headers: {
          'base64ai-flow-id': this.props.flowID
        },
        body
      }, (error, scanResults) => {
        let results = this.state.results
        if (error) {
          return this.updateFailedResponse(results, scanResults, file)
        }
        let overwrited = false
        const getAdditionalListColumns = (fieldList, res) => {
          const listFields = {}
          const constFieldColumns = additionalListColumnsFields
          for (const field of fieldList || []) {
            listFields[field.fieldName] = res?.fields?.[field.fieldName]?.value || constFieldColumns.find(f => f.value === field.fieldName)?.getProperty?.(res) || ''
          }
          return listFields
        }
        for (const scanResult of scanResults) {
          const result = results.find(rr => rr.uuid === file.uuid)
          const date = new Date()
          const additionalListColumns = this.state.flow.additionalListColumns
          const resultAdditionalListColumns = results?.length ? results[results.length - 1].additionalListColumns : null
          for (const field of Object.keys(resultAdditionalListColumns || {})) {
            if (!additionalListColumns.find(f => f.fieldName === field)) {
              additionalListColumns.push({ fieldName: field, displayName: capitalizeFirstLetter(camelCaseToTitleCase(field).toLowerCase()).replace(/\bid\b/, 'ID') })
            }
          }
          if (result && !overwrited) {
            overwrited = true
            result.status = scanResult.status || ''
            result.resultUuid = this.state.flow.hitl?.storeFiles !== false ? scanResult.uuid : null
            result.model = scanResult.model
            result.additionalListColumns = getAdditionalListColumns(additionalListColumns, scanResult)
            result.fileName = file.name
            result.updatedAt = date.getTime()
            result.createdAt = date.getTime()
            result.done = true
          } else {
            const resultObject = {
              status: scanResult.status || '',
              resultUuid: this.state.flow.hitl?.storeFiles !== false ? scanResult.uuid : null,
              model: scanResult.model,
              fileName: file.name,
              updatedAt: date.getTime(),
              createdAt: date.getTime(),
              done: true
            }
            resultObject.additionalListColumns = getAdditionalListColumns(additionalListColumns, scanResult)
            results = [resultObject, ...results]
          }
        }
        this.setState({
          results,
          oldResults: results,
          remountKey: this.state.remountKey + 1
        }, () => {
          this.updateContext()
        })
      }, error => {
        this.updateFailedResponse(this.state.results, error, file)
      })
    })
  }

  handlePastedFiles (event) {
    if (!event) {
      return
    }
    const files = []
    const possibleUrl = (event?.clipboardData?.getData('Text') || '').trim()
    if (possibleUrl.startsWith('http')) {
      const splittedURL = possibleUrl.split('/')
      this.pickSample(possibleUrl, 'Clipboard item: ' + splittedURL[splittedURL.length - 1]?.replace(/\?.*/, ''))
      return
    }
    const items = (event?.clipboardData || event?.originalEvent?.clipboardData)?.items
    if (!items) {
      return
    }
    Array.from(items).forEach(item => {
      if (item.kind === 'file') {
        const blob = item.getAsFile()
        const file = new File([blob], 'Clipboard item', { type: 'image/png' })
        files.push(file)
      }
    })
    if (files.length > 0) {
      this.handleFiles(files)
    }
  }

  pickSample (url, name) {
    if (url.match(/\.thumbnail\.png/)) {
      url = url.replace(/\.thumbnail\.png/, '.png')
    }
    if (url.match(/\/(health\/sbc|digital_signature)\/\d.png/)) {
      url = url.replace('.png', '.pdf')
    } else if (url.match(/\/multimedia\/1.png/)) {
      url = url.replace('.png', '.mp4')
    } else if (url.match(/\/multimedia\/2.png/)) {
      url = url.replace('.png', '.ogg')
    }
    const inputUrl = url.includes(location.origin) ? url : `${location.origin}/api/download?url=${encodeURI(url)}`
    const fileId = uuid()
    fetch(
      inputUrl, {
        credentials: 'include'
      })
      .then(result => result.blob())
      .then(blob => {
        const file = {
          name,
          uuid: fileId,
          source: blob,
          fileUrl: url
        }
        this.setState({ results: [this.createProcessingResult(file), ...this.state.results] }, () => {
          this.uploadDocument(url, file)
        })
      })
    return fileId
  }

  render () {
    const {
      user
    } = this.props
    const wasUserLoggedIn = this.state.user?.email || user?.email
    const { isLoaded, messageObject, insight, results, flow, selectedFilter } = this.state
    results?.forEach(result => {
      let name = result.model?.name || ''
      if (name.match(/semantic\/[\w\d]/i) && name.split('/').length > 1) {
        name = capitalizeFirstLetter(name.split('/')[1]) + ' (Semantic)'
      }
      result.type = name
    })
    if (results && !this.state.flowSearch) {
      results.sort((a, b) => b.updatedAt - a.updatedAt)
    }
    let userRole
    let isOnlyUploader = false
    let isOnlyReviewer = false
    let isAdminPrivilege = false
    let isUploader = false

    if (flow?.flowID && flow?.role?.length) {
      userRole = flow.role
      isOnlyUploader = userRole.includes('uploader') && !userRole.includes('owner') && !userRole.includes('reviewer') && !userRole.includes('administrator')
      isOnlyReviewer = userRole.includes('reviewer') && !userRole.includes('owner') && !userRole.includes('uploader') && !userRole.includes('administrator')
      isAdminPrivilege = userRole.includes('owner') || userRole.includes('administrator')
      isUploader = userRole.includes('uploader')
    }

    this.CONFIGURATION.results = [...this.CONFIGURATION.base]
    let additionalListColumns = !flow?.additionalListColumns
      ? [
          {
            fieldName: '*modelName',
            displayName: 'Name'
          },
          {
            fieldName: '*updatedAt',
            displayName: 'Updated at'
          }
        ]
      : flow.additionalListColumns
    const additionalListOfLastElement = this.state.results?.length ? this.state.results[this.state.results.length - 1].additionalListColumns : null
    if (this.state.flowSearch && additionalListOfLastElement) {
      additionalListColumns = Object.keys(additionalListOfLastElement || {}).map(s => {
        if (s === '*modelName') {
          return {
            fieldName: '*modelName',
            displayName: 'Name'
          }
        }
        if (s === '*updatedAt') {
          return {
            fieldName: '*updatedAt',
            displayName: 'Updated at'
          }
        }
        if (s === '*createdAt') {
          return {
            fieldName: '*createdAt',
            displayName: 'Created at'
          }
        }
        if (s === '*integrationStatus') {
          return {
            fieldName: '*integrationStatus',
            displayName: 'Integration status'
          }
        }
        return { fieldName: s, displayName: capitalizeFirstLetter(camelCaseToTitleCase(s).toLowerCase()).replace(/\bid\b/, 'ID') }
      })
    }
    if (flow?.flowID && additionalListColumns?.length) {
      let i = 1
      for (const field of additionalListColumns) {
        if (!this.CONFIGURATION.results.find(rr => rr.key === field.fieldName)) {
          this.CONFIGURATION.results.splice(i, 0, {
            header: field.displayName,
            key: field.fieldName,
            renderWithData: data => {
              if (field.fieldName === '*updatedAt') {
                return (
                  <div className='table-warning-column'>
                    <div className='table-warning-column'>
                      <span>{toIsoDate(data.updatedAt)}</span>
                    </div>
                  </div>
                )
              } else if (field.fieldName === '*createdAt') {
                return <span>{toIsoDate(data.createdAt)}</span>
              } else if (field.fieldName === '*modelName') {
                let name = data.model?.name || ''
                if (name.match(/semantic\/[\w\d]/i) && name.split('/').length > 1) {
                  name = capitalizeFirstLetter(name.split('/')[1]) + ' (Semantic)'
                }
                return <span title={data.rejectionReason || name}>{name}</span>
              }
              return <span>{data.additionalListColumns?.[field.fieldName] || ''}</span>
            },
            headerStyle: { ...this.HEADER_STYLE, width: '15%' },
            dataStyle: { ...this.DATA_STYLE },
            descSortFunction: this.sortDesc,
            ascSortFunction: this.sortAsc,
            defaultSorting: field.fieldName === 'searchScore' ? 'DESC' : undefined
          })
        }
        i++
      }
      if (this.CONFIGURATION.results.length > 2) {
        const numberOfAdditionalColumns = this.CONFIGURATION.results.length - 2
        for (let i = 1; i < (numberOfAdditionalColumns + 1); i++) {
          this.CONFIGURATION.results[i].headerStyle.width = `${55 / numberOfAdditionalColumns}%`
        }
      }
    }
    let loadingErrorButton = {
      to: '/flow',
      text: 'Back to Flows'
    }
    if (!isLoaded && !wasUserLoggedIn?.length) {
      loadingErrorButton = {
        to: '/login?next=/flow',
        text: 'Login to access Flows'
      }
    }
    const isFlowSearchEnabled = flow?.flowSearch?.status === 'enabled' && !isWorkingOffline()

    const isNumeric = value => {
      return /^-?\d+$/.test(value)
    }

    results?.forEach(result => {
      Object.entries(result?.additionalListColumns || {}).forEach((pair, _) => {
        const key = pair[0]
        const value = pair[1]
        if (
          !['*updatedAt', '*createdAt'].includes(key) // neglect if it is number but represents a date
          && (typeof value === 'number' || (typeof value === 'string' && isNumeric(value))) // or if it is a number or a string that represents a number
        ) {
          result.additionalListColumns[key] = formatNumber(typeof value === 'number' ? value : parseInt(value, 10))
        }
      })
    })

    if (flow?.reviewSla?.status === 'enabled') {
      this.CONFIGURATION.results.unshift({
        header: 'SLA',
        key: 'sla',
        renderWithData: data => {
          return data.isDocumentOverdue
            ? (
                <span title='The review of this document is delayed.'>
                  <MaterialIcon name='warning' className='icon-size' />
                </span>
              )
            : ''
        },
        headerStyle: { ...this.HEADER_STYLE, width: '5%' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' }
      })
    }

    return (
      <View name='flow'>
        {isLoaded && <FullscreenDropzone handleFiles={this.handleFiles} />}
        <Header name='header'>
          {
            flow?.flowID
              ? (
                  <div className={'header-container results ' + (isFlowSearchEnabled ? 'flow-search' : '')}>
                    <Helmet>
                      <title>
                        {flow.name}
                        {constants.titleSuffix}
                      </title>
                    </Helmet>
                    <div>
                      <BackButton
                        onClick={() => {
                          this.setState({ currentTab: 'flow-results' })
                          this.context.redirect(this.state.currentTab === 'flow-results' ? '/flow' : `/flow/${flow?.flowID}/result`)
                        }}
                        placeholder={this.state.currentTab === 'flow-results' ? 'Back to Flows' : 'Back to Flow result'}
                      />
                      <div className='title'>
                        <h1 className='slogan'>{flow.name}</h1>
                        <h2
                          className='introduction'
                        >
                          List and review results you processed in this Flow.
                        </h2>
                      </div>
                    </div>
                    <div className={'dropdown-container ' + (isFlowSearchEnabled ? 'flow-search' : '')}>
                      {(!this.state.isMobile && isFlowSearchEnabled) && (
                        <div className='flow-search-input' style={{ width: '100%' }}>
                          <FlowSearchInput
                            ref={this.flowSearchRef}
                            models={this.props.models}
                            getModels={this.props.getModels}
                            flow={flow}
                            onSearchStarted={() => {
                              this.setState({
                                isLoaded: false,
                                messageObject: {
                                  title: 'Loading...',
                                  description: 'Please wait, this may take a few seconds.',
                                  status: 'loading',
                                  code: null
                                }
                              })
                            }}
                            onSearchCompleted={searchResults => {
                              this.setState({
                                isLoaded: true,
                                messageObject: LoadingModal.getEmptyMessageObject(),
                                insight: searchResults.insight,
                                results: searchResults.list,
                                isLast: !!searchResults.isLast,
                                noResultMessage: searchResults.length === 0 ? 'There are no search results with this selection' : '',
                                flowSearch: true,
                                selectedResults: [],
                                currentRowIndex: 1
                              }, () => {
                                this.updateContext()
                              })
                            }}
                            onSearchFailed={response => {
                              if (response.message) {
                                this.setState({
                                  isLoaded: false,
                                  messageObject: {
                                    title: 'Error while fetching Flow search results',
                                    description: response.message,
                                    status: 'error',
                                    code: response.code
                                  }
                                })
                              }
                            }}
                            onClear={() => {
                              this.setState({
                                results: this.state.oldResults,
                                insight: null,
                                flowSearch: false,
                                remountKey: this.state.remountKey + 1
                              })
                            }}
                            flowName={flow.name}
                          />
                        </div>
                      )}
                      { flow?.flowID
                        ? (
                            <div className='flow-features'>
                              {
                                !isOnlyUploader && (
                                  <div className={clsx(selectedFilter?.length && 'selected')} onClick={() => this.filterAction()}>
                                    <MaterialIcon title='Filter the results' name='filter_alt' className='material-icon-outlined icon-size' />
                                  </div>
                                )
                              }

                              {
                                !isOnlyUploader && (
                                  <div onClick={() => this.exportResultAction()}>
                                    <MaterialIcon title='Download the results via Email' name='download' className='material-icon-outlined icon-size' />
                                  </div>
                                )
                              }
                              {
                                isAdminPrivilege && (
                                  <div onClick={() => this.deleteSelectedResults()}>
                                    <MaterialIcon title='Delete selected results' name='delete' className='material-icon-outlined icon-size' />
                                  </div>
                                )
                              }
                              {
                                isAdminPrivilege && (
                                  <div onClick={() => this.displayUsageAction()}>
                                    <MaterialIcon title='See usage statistics of your Flow' name='monitoring' className='material-icon-outlined icon-size' />
                                  </div>
                                )
                              }
                              <div onClick={() => this.settingsAction()}>
                                <MaterialIcon title='Go to Flow Settings' name='settings' className='material-icon-outlined icon-size' />
                              </div>
                              {
                                (isAdminPrivilege || isUploader) && (
                                  <div className={clsx(this.state.uploadSectionShow && 'selected')} onClick={() => this.setState({ uploadSectionShow: !this.state.uploadSectionShow })}>
                                    <MaterialIcon title='Upload a document' name='upload' className='material-icon-outlined icon-size' />
                                  </div>
                                )
                              }
                            </div>
                          )
                        : <></>}
                      {/* minmax(2rem, 1.5fr) minmax(0.2rem, 0.5fr) minmax(2rem, 1.5fr) auto */}
                    </div>
                  </div>
                )
              : null
          }
        </Header>
        {this.state.currentTab === 'flow-results'
        && (
          <Section name='show-results' className={isLoaded ? 'settings-card' : ''}>
            {!isLoaded || messageObject.status === 'loading'
              ? (
                  <LoadingModal
                    data={{
                      ...messageObject,
                      button: loadingErrorButton
                    }}
                  />
                )
              : (
                  <>
                    <div
                      className='header-container'
                      style={{
                        display: 'flex',
                        flexDirection: isOnlyReviewer ? 'row-reverse' : 'row',
                        gap: '.4rem',
                        marginBottom: '1rem',
                        justifyContent: isOnlyUploader ? 'space-around' : 'space-between',
                        alignItems: 'baseline'
                      }}
                    >
                    </div>
                    <>
                      { userRole && (isUploader || isAdminPrivilege) && this.uploadAction() }
                    </>
                    { userRole && !(userRole?.length === 1 && userRole[0] === 'uploader' && results?.length === 0)
                      ? (
                          <>
                            {insight && (
                              <div className='insights'>
                                <h4>AI insights</h4>
                                <Typewriter
                                  options={{
                                    strings: insight.replace(/\s\*\*/g, '<b>').replace(/\*\*/g, '</b>').replace(/\n/g, '<br/>'),
                                    autoStart: true,
                                    delay: 10
                                  }}
                                />
                              </div>
                            )}
                            <SortableTable
                              key={this.state.remountKey}
                              data={results}
                              columns={this.CONFIGURATION.results}
                              style={{
                                borderCollapse: 'separate',
                                borderSpacing: '1px',
                                width: '100%'
                              }}
                              iconStyle={{ paddingLeft: '0.2rem' }}
                            />
                          </>
                        )
                      : null}
                    { results?.length === 0 && userRole && (isAdminPrivilege || userRole.includes('reviewer'))
                      ? (
                          selectedFilter.length || this.state.noResultMessage
                            ? (
                                <div className='placeholder' style={{ margin: '4rem' }}>
                                  <h2 className='title'>{this.state.noResultMessage || 'No results match your filters.'}</h2>
                                  <p className='paragraph'>
                                    <a href='https://help.base64.ai/kb/guide/en/flows-human-in-the-loop-document-processing-WH1QqHEiey/Steps/2068067' target='_blank' rel='noreferrer'>Learn more</a>
                                    {' '}
                                    about how to use the flows.
                                  </p>
                                </div>
                              )
                            : (
                                <div className='placeholder' style={{ margin: '4rem' }}>
                                  <h2 className='title'>This flow has no results yet.</h2>
                                  <p className='paragraph'>
                                    <a href='https://help.base64.ai/kb/guide/en/flows-human-in-the-loop-document-processing-WH1QqHEiey/Steps/2068067' target='_blank' rel='noreferrer'>Learn more</a>
                                    {' '}
                                    about how to use the flows.
                                  </p>
                                </div>
                              )
                        )
                      : null}
                    { (userRole && (isAdminPrivilege || userRole.includes('reviewer')))
                      ? (
                          <div className='flow-footer ' style={{ marginTop: results?.length > 0 ? '0rem' : '16rem' }}>
                            <>
                              { results?.length > 0 && !isOnlyUploader
                              && (
                                <div className='button-set'>
                                  <Button
                                    size='small'
                                    id='nextResults'
                                    disabled={results[results.length - 1].isLast || (this.state.flowSearch && this.state.isLast)}
                                    onClick={() => {
                                      if (this.state.flowSearch) {
                                        return this.flowSearchRef?.current.searchNextPage()
                                      }
                                      this.getPaginatedResults({ next: true })
                                    }}
                                  >
                                    →
                                  </Button>
                                  <Button
                                    size='small'
                                    id='prevResults'
                                    disabled={results[0].isFirst || (this.state.flowSearch && this.flowSearchRef.current?.state.pageNumber === 0)}
                                    onClick={() => {
                                      if (this.state.flowSearch) {
                                        return this.flowSearchRef.current?.searchPreviousPage()
                                      }
                                      this.getPaginatedResults({ prev: true })
                                    }}
                                  >
                                    ←
                                  </Button>
                                </div>
                              )}
                            </>
                          </div>
                        )
                      : null }
                  </>
                )}

          </Section>
        )}
        {this.state.currentTab === 'flow-stats'
        && (
          <StatDisplaySection
            user={this.props.user}
            filter={this.props.filter}
            limit={this.props.limit}
            history={this.props.history}
            models={this.props.models}
            getModels={this.props.getModels}
            flowID={this.props.flowID}
            currentEndpoint={`/flow/${this.props.flowID}/usage`}
            classNameOverride='settings-card'
          />
        )}
      </View>
    )
  }
}

export default ShowFlowResults
