// Modules
import { Component } from 'react'

// Helpers
import Section from '~/layout/section/Section'
import { googleOneTabAuthentication } from '~/helpers/googleOneTabAuthentication'
import window from 'global/window'
import { triggerTrackingEvent } from '../../utilities/tracker'
import { isWorkingOffline } from '../../context/environment'

class ThirdPartAuthentication extends Component {
  constructor (props) {
    super(props)
    this.state = {
      origin: ''
    }
  }

  componentDidMount () {
    googleOneTabAuthentication()
    this.setState({
      origin: window.location.origin
    })
  }

  render () {
    return (
      isWorkingOffline()
        ? <></>
        : (
            <Section name='third-part'>
              <p>or continue with</p>
              <div
                className='grid-row'
                onMouseEnter={() => {
                  window.preventDefaultDisable = true
                }}
                onMouseLeave={() => {
                  window.preventDefaultDisable = false
                }}
              >
                <div id='google-button' />
                <a
                  id='linkedin-button'
                  onClick={() => {
                    triggerTrackingEvent('login-attempt-linkedin')
                  }}
                  href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77tzdlyad6eupx&scope=r_liteprofile%20r_emailaddress&redirect_uri=${this.state.origin}/api/auth/linkedin`}
                >
                  <img alt='linkedIn login button' src='https://content.linkedin.com/content/dam/me/brand/en-us/brand-home/logos/In-Blue-Logo.png.original.png' />
                </a>
                <a
                  id='microsoft-button'
                  onClick={() => {
                    triggerTrackingEvent('login-attempt-microsoft')
                  }}
                  href='/api/auth/microsoft/login'
                >
                  <img alt='Microsoft login button' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAO5JREFUWEftmDEKwkAQRf8KCWJMqSSdtbew1dpLeIscwtreA3kFJa1LECMqu2CMYJOdESL8rTPD25dh9ycGPV/G8dnlLNPgrJKR3W4OQIWxRr9ihVMDeI8HR2nTSzRMPWCNs7QXauQEFFmkQZE+uDnmDMoc0qDMH2eQN0nQBPEuDtLWKqLB3hos13OVBOw26AOr0ioWsD5RY1+qAT4mUyU8wDSAuzJDFIkjP+I69YBXhch/Q25e3yQgYMBbp8EAaR8lNEiDIQZ4DoZYa9fQIA12MMCDuoOsr4/+n0Hpjl19AusDq9JP9Hdg1YD7UY8n7dhrOLWm5MoAAAAASUVORK5CYII=' />
                </a>
              </div>
            </Section>
          )
    )
  }
}

// Export
export default ThirdPartAuthentication
