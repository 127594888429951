// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'

// Context
import Context from '~/context/global'

// Interface
import Link from '~/interface/link/Link'
import Header from '~/layout/header/Header'

// Layout
import View from '~/layout/view/View'
import { isWorkingOffline } from '~/context/environment'
import Section from '../../layout/section/Section'
import demoDocumentAi from '../../data/demo-document-ai'
import demoFaceAi from '../../data/demo-face-ai'
import demoSignatureAi from '../../data/demo-signature-ai'
import demoNoCodeAi from '../../data/demo-no-code-ai'
import clsx from 'clsx'

// View: Demo
class Demo extends Component {
  static contextType = Context

  showHealthAndSafety = (user, s) => (
    (
      s.title === 'demoHealthAndSafety'
      && user?.tags?.includes('healthAndSafety')
    )
    || s.title !== 'demoHealthAndSafety'
  )

  renderInfoCard = (data, user) => {
    return data.map(s => {
      return (!isWorkingOffline() || s.isWorkOffline) && this.showHealthAndSafety(user, s)
        && (
          <div key={s.title} className={clsx('info-card ', s.isLarge && 'large')} onClick={() => this.context.redirect(s.url)}>
            <div className='image'>
              <img className='img green-overlay' src={`/static/images/icons/${s.path}`}></img>
            </div>
            <div className='data'>
              <p className={clsx('description ', s.isLarge && 'large')}>{s.description}</p>
              <Link to={s.url} className='link-to'>{s.buttonTitle} &gt;</Link>
            </div>
          </div>
        )
    })
  }

  render () {
    const { user } = this.props
    return (
      <View name='demo'>
        <Header
          name='demo'
          align='center'
        >
          <h1 className='slogan'>Try Our AI on Your Browser</h1>
          <h2 className='introduction'>Get work done faster</h2>
          <h3 className='introduction'>
            See for yourself how Base64.ai processes all types of documents, recognizes faces, and compares signatures, and integrates with 400+ third party systems including databases and scanners.
          </h3>
        </Header>
        <Section tight={true} align='center'>
          <h3 id='document-ai' className='introduction'><Link className='title-link'><b>Document AI</b></Link> extracts and validates the data in all types of documents.</h3>
          <div className='info-card-container'>
            {this.renderInfoCard(demoDocumentAi, user)}
          </div>
        </Section>
        <Section tight={true} align='center'>
          <h3 id='facial-ai' className='introduction' style={{ marginTop: '2rem' }}>
            <Link className='title-link'><b>Face AI</b></Link> recognizes and verifies faces on documents and images.
          </h3>
          <div className='info-card-container'>
            {this.renderInfoCard(demoFaceAi)}
          </div>
        </Section>
        <Section tight={true} align='center'>
          <h3 id='signature-ai' className='introduction' style={{ marginTop: '2rem' }}>
            <Link className='title-link'><b>Signature AI</b></Link> recognizes, extracts, and verifies signatures on documents and images.
          </h3>
          <div className='info-card-container'>
            {this.renderInfoCard(demoSignatureAi)}
          </div>
        </Section>
        {!isWorkingOffline() && (
          <Section tight={true} align='center'>
            <h3 id='no-code-ai' className='introduction' style={{ marginTop: '2rem' }}><Link className='title-link'><b>No-code AI</b></Link> allows rapid integration without coding.</h3>
            <div className='info-card-container'>
              {this.renderInfoCard(demoNoCodeAi)}
            </div>
          </Section>
        )}
      </View>
    )
  }
}

Demo.propTypes = {
  user: PropTypes.object
}

// Export
export default Demo
