export const createEmptyDom = (width = 1440, height = 1440, dpiX = 72, dpiY = 72, mimeType = 'image/png', pageCount = 1) => {
  return {
    text: '',
    confidence: 0,
    box: {
      pageNumber: 0,
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      right: 0,
      bottom: 0
    },
    pages: Array(pageCount).fill().map((_, pageIndex) => ({
      text: '',
      confidence: 0,
      box: {
        pageNumber: pageIndex,
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        right: 0,
        bottom: 0
      },
      properties: {
        mimeType,
        rotationAngle: 0,
        dpiX,
        dpiY,
        width,
        height,
        normalizedWidth: width,
        normalizedHeight: height
      },
      blocks: [],
      location: {
        topLeft: {
          x: 0,
          y: 0
        },
        topRight: {
          x: 0,
          y: 0
        },
        bottomLeft: {
          x: 0,
          y: 0
        },
        bottomRight: {
          x: 0,
          y: 0
        },
        pageNumber: pageIndex
      },
      tables: []
    }))
  }
}
