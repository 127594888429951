import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reCaptcha } from '~/helpers/authentication'

// import layout components
import View from '~/layout/view/View'

// Interface
import Form from '~/interface/form/Form'
import Button from '../../interface/button/Button'
import FileInput from '~/interface/file-input/FileInput'

/* SSR-IGNORE */ import 'react-step-progress-bar/styles.css'
import { ProgressBar, Step } from 'react-step-progress-bar'

// Context
import Context from '~/context/global'

// import subpages
import HowToReceive from './pages/HowToReceive'
import HowToSend from './pages/HowToSend'
import SelectExtractTypes from './pages/SelectExtractTypes'
import SelectDocumentTypes from './pages/SelectDocumentTypes'
import acceptedFileFormats from '~/data/acceptedFileFormats'

import { request } from '~/helpers/request'
import { getBase64 } from '~/helpers/file'
import { v4 as uuid } from 'uuid'
import { RegEx } from '../../constants/RegEx'
import { AppButton } from '../../interface/app-button/AppButton'

class IntegrationWizard extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.wizardContainerRef = React.createRef()

    this.state = {
      page: 0,
      sampleFiles: []
    }
    this.goToPreviousPage = this.goToPreviousPage.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
    this.showIntroductionPage = this.showIntroductionPage.bind(this)
    this.handleFiles = this.handleFiles.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.showSuccessMessage = this.showSuccessMessage.bind(this)
    this.showContactPage = this.showContactPage.bind(this)
  }

  goToPreviousPage () {
    this.setState(prev => {
      return { ...prev, page: this.state.page - 1 }
    })
    this.scrollToTop()
  }

  goToNextPage () {
    this.setState(prev => {
      return { ...prev, page: this.state.page + 1 }
    })
    this.scrollToTop()
  }

  showIntroductionPage () {
    return (
      <>
        <h2 className='introduction' style={{ marginTop: '50px', textAlign: 'center', width: '78%', marginLeft: 'auto', marginRight: 'auto' }}>
          Base64.ai&apos;s Integration Wizard will help you choose the best
          integration options to automate your document processing based on your desired workflow.
        </h2>
        <div className='button-container-center'>
          <AppButton
            className='secondary-cta large'
            onClick={() => {
              this.goToNextPage()
            }}
          >
            Start now
          </AppButton>
        </div>
      </>
    )
  }

  handleFiles (callback) {
    const sampleFiles = this.state.sampleFiles.map(file => ({
      name: file.name,
      uuid: uuid(),
      type: file.type,
      source: file
    }))

    let filesRemaining = sampleFiles.length

    if (!filesRemaining) {
      callback([])
      return
    }

    sampleFiles.forEach(file => {
      getBase64(file, (error, base64data) => {
        if (base64data && base64data.startsWith('data:;')) {
          if (file.name.toLowerCase().endsWith('.msg')) {
            base64data = base64data.replace('data:;', 'data:application/vnd.ms-outlook;')
          } else {
            error = true
          }
        }
        if (!error) {
          file.base64data = base64data
        }
        filesRemaining--
        if (!filesRemaining) {
          callback(sampleFiles)
        }
      })
    })
  }

  handleSubmit (data, successCallback, errorCallback) {
    data.name = data.name.trim()
    if (data.name.length < 2) {
      errorCallback('Given name is too short. Please supply more than 2 valid characters.')
      return
    }

    data.companyName = data.companyName.trim()
    if (data.companyName.length < 2) {
      errorCallback('Company name is too short. Please supply more than 2 valid characters.')
      return
    }

    this.handleFiles(sampleFiles => {
      request({
        endpoint: '/integration-wizard-result',
        body: {
          ...data,
          ...this.state,
          sampleFiles
        }
      }, (error, response) => {
        if (error) {
          errorCallback(error?.message || error.toString())
        } else {
          successCallback()
        }
      })
    })
  }

  showSuccessMessage () {
    this.context.displayModal({
      title: 'Thank you',
      content: 'We received your request and we will get back to you shortly!',
      isClosable: true,
      options: [
        {
          content: 'OK',
          action: () => this.context.redirect('/')
        }
      ]
    })
  }

  showContactPage () {
    const { user } = this.props
    const { sampleFiles } = this.state

    return (
      <div className='main'>
        <div className='description' style={{ paddingTop: '10px' }}>
          <h2 style={{ marginTop: '40px' }}>Enter your contact information</h2>
          <h3 className='introduction'>
            You have almost completed all of the steps! Please add your contact information and click Send to submit your integration needs.
            You may also add sample files or
            <a onClick={() => {
              this.goToPreviousPage()
            }}
            >
              go back
            </a> and edit your answers.
          </h3>
        </div>
        <div className='entries'>
          <Form
            className='leadForm inline'
            style={{ marginTop: '20px' }}
            onDataSubmit={(data, errorCallback) => {
              reCaptcha('lead', token => {
                data.token = token
                this.handleSubmit(data, this.showSuccessMessage, errorCallback)
              })
            }}
          >
            <Form.Input
              type='text'
              name='name'
              reference='Name'
              placeholder='Name'
              prevalue={user?.givenName ? user.givenName + ' ' + user.familyName : ''} // if not logged in it is an empty object
              autoComplete='off'
              validator={{
                minimumLength: 1
              }}
            />
            <Form.Input
              type='email'
              name='email'
              reference='Work email'
              placeholder='Work email'
              prevalue={user?.workEmail}
              autoComplete='off'
              validator={{
                pattern: RegEx.EMAIL,
                maximumLength: 255
              }}
            />
            <Form.Input
              type='text'
              name='companyName'
              reference='Company name'
              placeholder='Company name'
              prevalue={user?.company}
              autoComplete='off'
            />
            <Form.Input
              type='text'
              name='website'
              reference='Website'
              placeholder='Company website'
              autoComplete='off'
              validator={{
                pattern: /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
                maximumLength: 255
              }}
            />
            <Form.Textarea
              className='custom-message'
              type='text'
              name='message'
              col='200'
              reference='Your message'
              placeholder='Type your message'
              autoComplete='off'
            />
            <FileInput
              accept={Array.from(acceptedFileFormats)}
              dropText={<>Upload sample files here (optional)</>}
              onDrop={(accepted, rejectedFiles, event) => {
                accepted.map(file => Object.assign(file, {
                  preview: URL.createObjectURL(file)
                }))
                this.setState(prev => {
                  return { ...prev, sampleFiles: accepted }
                })
              }}
            />
            <aside className='thumbsContainer'>
              {sampleFiles.map(file => (
                <div className='thumb' key={file.name}>
                  <div className='thumbInner'>
                    {
                      file.type.startsWith('image')
                      && (
                        <img
                          src={file.preview}
                          className='img'
                          // Revoke data uri after image is loaded
                          onLoad={() => {
                            URL.revokeObjectURL(file.preview)
                          }}
                        />
                      )
                    }
                    <span key={file.name} className='text'>{file.name}
                      <span
                        className='close'
                        onClick={() => {
                          this.setState(prev => {
                            return {
                              ...prev,
                              sampleFiles: this.state.sampleFiles.filter(
                                it => it.name !== file.name
                              )
                            }
                          })
                        }}
                      >&nbsp; &times;
                      </span>
                    </span>
                  </div>
                </div>
              ))}
            </aside>
            <Form.Button>
              Send
            </Form.Button>
          </Form>
        </div>
      </div>
    )
  }

  scrollToTop () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  render () {
    const { page } = this.state

    return (
      <View>
        <div className='container'>
          <div className='wizard-container' ref={this.wizardContainerRef}>
            <h1 className='slogan'>Integration Wizard</h1>

            <ProgressBar
              percent={page * 20}
              filledBackground='rgb(15 150 255)'
              width='98%'
            >
              <Step>
                {({ accomplished, index }) => (
                  <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                    {index + 1}
                  </div>
                )}
              </Step>
            </ProgressBar>

            {page === 0 && this.showIntroductionPage()}
            {page === 1 && <SelectDocumentTypes parent={this} getModels={this.props.getModels} models={[]} />}
            {page === 2 && <SelectExtractTypes parent={this} />}
            {page === 3 && <HowToSend parent={this} />}
            {page === 4 && <HowToReceive parent={this} />}
            {page === 5 && this.showContactPage()}

            {page !== 0 && page !== 5
            && (
              <div className='button-container-right fixToBottom'>
                <Button onClick={() => {
                  this.goToNextPage()
                }}
                >Next →
                </Button>
                <Button
                  onClick={() => {
                    this.goToPreviousPage()
                  }}
                  className='secondary-cta'
                > ← Previous
                </Button>
              </div>
            )}
          </div>
        </div>
      </View>
    )
  }
}

IntegrationWizard.propTypes = {
  getModels: PropTypes.func,
  user: PropTypes.object
}

// Export
export default IntegrationWizard
