import { useFlows } from '~/providers/FlowContext'
import EditCustomModel from './EditCustomModel'

const CustomModelSettings = props => {
  const { flows } = useFlows()

  return (
    <EditCustomModel
      {...props}
      flows={flows}
    />
  )
}

export default CustomModelSettings
