import { Component } from 'react'
import PropTypes from 'prop-types'
import ImageViewer from '../../interface/image-viewer/ImageViewer'
import { request } from '../../helpers/request'
import { getCustomModels } from '../../helpers/getCustomModels'
import * as uuid from 'uuid'
import DataExtractionResultViewer from '../../interface/data-extraction-result-viewer/DataExtractionResultViewer'
import SelectionArrow from '../../interface/selection-arrow/SelectionArrow'
import Link from '../../interface/link/Link'
import window from 'global/window'
import Context from '~/context/global'
import { capitalizeFirstLetter } from '../../utilities/format'
import CustomSurvey from '../../interface/custom-survey/CustomSurvey'
import { reCaptcha } from '../../helpers/authentication'
import Banner from '../../interface/banner/Banner'
import MaterialIcon from '../../interface/material-icon/MaterialIcon'
import { handleModels } from '../../helpers/handleModels'
import LoadingModal from '../../interface/loading-modal/LoadingModal'
import { clone } from '../../utilities/object'
import BackButton from '../../interface/back-button/BackButton'
import { rerunIntegration } from '../../helpers/integration'
import { Messages } from '~/constants'
import { NavigationPrompt } from '../../interface/navigation-prompt/NavigationPrompt'
class HITL extends Component {
  static contextType = Context

  static propTypes = {
    resultUuid: PropTypes.string,
    user: PropTypes.object,
    getModels: PropTypes.func,
    setFlows: PropTypes.func,
    flows: PropTypes.array
  }

  constructor (props) {
    super(props)
    this.state = {
      file: [],
      isLoaded: false,
      isSubmitted: true,
      rejectionReason: 'Document is unreadable.', // first option is default
      activeResultIndex: 0,
      activeIndex: 0,
      mode: 'view',
      isMobile: false
    }

    this.getAnnotations = this.getAnnotations.bind(this)
    this.selectionChangeHandler = this.selectionChangeHandler.bind(this)
    this.forceUpdateState = this.forceUpdateState.bind(this)
    this.updateResult = this.updateResult.bind(this)
    this.deleteResult = this.deleteResult.bind(this)
    this.deleteUpload = this.deleteUpload.bind(this)
    this.resetResults = this.resetResults.bind(this)
    this.addNewResult = this.addNewResult.bind(this)
    this.rejectResult = this.rejectResult.bind(this)
    this.approveResult = this.approveResult.bind(this)
    this.rerunExportIntegration = this.rerunExportIntegration.bind(this)
    this.reprocessDocument = this.reprocessDocument.bind(this)
    this.showRejectionReason = this.showRejectionReason.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
    this.addQuestionsToFlow = this.addQuestionsToFlow.bind(this)
    this.getAndAddQuestionsToCustomModel = this.getAndAddQuestionsToCustomModel.bind(this)
    this.addQuestionsToModel = this.addQuestionsToModel.bind(this)
    this.getCustomModel = this.getCustomModel.bind(this)
    this.updateContext = this.updateContext.bind(this)
    this.rerunIntegration = rerunIntegration.bind(this)

    this.configuration = {
      rejectionReasonList: [
        'Document is unreadable.',
        'Document type is not valid for this flow.'
      ],
      hitlStatusToText: {
        autoApproved: 'auto-approved as per the flow settings',
        needsReview: 'needs review'
      }
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()
    request({
      endpoint: '/result/' + this.props.resultUuid + '?hitl=true',
      method: 'GET'
    }, (err, response) => {
      if (err) {
        return alert('Unable to get results ' + err.message)
      }
      this.setState({
        ...response,
        resultUuid: this.props.resultUuid,
        isLoaded: true,
        results: response.result,
        startPage: response?.result?.[0]?.features?.properties?.startPage || 0,
        originalResults: JSON.stringify(response.result),
        isEditDisabled: this.isMobile() || ['approved', 'autoApproved'].includes(response.hitlStatus),
        isDocumentUnderReview: !!response.isDocumentUnderReview
      }, () => {
        getCustomModels(this.state.flow, customModelsData => {
          this.props.getModels && this.props.getModels((error, modelResponse) => {
            if (!error) {
              this.startTime = Date.now()
              // console.log(response)
              // console.log(modelResponse)
              // console.log(handleModels(modelResponse, this.state.flow, customModelsData, { isHITL: true }))
              this.setState({
                modelsData: handleModels(modelResponse, this.state.flow, customModelsData, { isHITL: true })
              })
            }
          })
        })
      })
    })
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeHandler)
  }

  isMobile () {
    return window.innerWidth < 768
  }

  resizeHandler () {
    if (!this.state.isMobile && this.isMobile()) {
      return this.setState({ isMobile: true, isEditDisabled: true })
    }
    if (this.state.isMobile && !this.isMobile()) {
      return this.setState({ isMobile: false, isEditDisabled: ['approved', 'autoApproved'].includes(this.state.hitlStatus) })
    }
  }

  forceUpdateState (states, callback) {
    this.setState({ ...states, isLoaded: false },
      () => {
        this.setState({ isLoaded: true },
          () => {
            setTimeout(callback, 500)
          })
      })
  }

  getAnnotations () {
    const { results } = this.state
    if (!this.state.isLoaded || !results) {
      return []
    }
    const resultsAnnotations = []
    results.forEach(result => {
      const annotations = []
      if (!result.features?.dom) {
        return
      }
      result.features.dom.pages.forEach(page => {
        page?.blocks?.forEach(block => {
          block?.lines?.forEach(line => {
            line?.words?.forEach(word => {
              const newWord = clone(word)
              newWord.id = uuid.v4()
              newWord.location.properties = page.properties
              annotations.push(newWord)
            })
          })
        })
      })
      resultsAnnotations.push(annotations)
    })
    return resultsAnnotations
  }

  removeDeletedFieldsAndFeaturesFromResponse = validResults => {
    validResults.forEach(validResult => {
      Object.keys(validResult.fields).map(fk => {
        if (validResult.fields[fk].isDeleted === true) {
          delete validResult.fields[fk]
        }
      })
      Object.keys(validResult.features).filter(it => ['faces', 'signatures', 'glares'].includes(it)).map(fk => {
        validResult.features[fk].filter(f => f.isDeleted === true).map((f, fInd) => {
          delete validResult.features[fk][fInd]
          if (!validResult.features[fk].filter(f => f)?.length) {
            delete validResult.features[fk]
          }
        })
      })
      Object.keys(validResult.features.properties).filter(it => ['isGlareFree', 'isInFocus', 'isWatermarkFree'].includes(it)).map(fk => {
        const originalKey = `original${capitalizeFirstLetter(fk)}`
        if (validResult.features.properties[originalKey] !== undefined) {
          delete validResult.features.properties[originalKey]
        }
      })
    })
  }

  submitResponse (options) {
    const changedItems = []
    const validResults = clone(this.state.results)

    const { hitlStatusToText } = this.configuration
    const hitlStatus = options?.status
    const hitlStatusText = hitlStatusToText[hitlStatus] || hitlStatus || 'approved'

    validResults.forEach(validResult => {
      // clear processing helper properties
      validResult.features.dom?.pages.forEach(page => {
        if (!page.blocks) {
          return
        }
        page.blocks.forEach(block => {
          block.lines.forEach(line => {
            line.words.forEach(word => {
              delete word.location.properties
            })
          })
        })
      })
      if (validResult.originalOcr) {
        changedItems.push({
          key: 'OCR',
          value: validResult.ocr,
          originalValue: validResult.originalOcr
        })
      }

      if (validResult.features.changedTables) {
        changedItems.push({
          key: 'Tables',
          value: validResult.features.changedTables.join(', '),
          originalValue: '(changed)'
        })
        delete validResult.features.changedTables
      }

      if (validResult.features.addedTables) {
        changedItems.push({
          key: 'Tables',
          value: validResult.features.addedTables.join(', '),
          originalValue: '(added)'
        })
        delete validResult.features.addedTables
      }
      if (validResult.features.removedTables) {
        changedItems.push({
          key: 'Tables',
          value: validResult.features.removedTables.join(', '),
          originalValue: '(removed)'
        })
        delete validResult.features.removedTables
      }

      if (validResult.features.originalEcho) {
        changedItems.push({
          key: 'Echo',
          value: typeof validResult.echo === 'object' ? JSON.stringify(validResult.echo) : validResult.echo,
          originalValue: typeof validResult.features.originalEcho === 'object' ? JSON.stringify(validResult.features.originalEcho) : validResult.features.originalEcho
        })
        delete validResult.features.originalEcho
      }

      Object.keys(validResult.fields).map(fk => {
        if (validResult.fields[fk].originalValue !== undefined) {
          changedItems.push(validResult.fields[fk])
        }
        if (validResult.fields[fk].originalKey !== undefined) {
          changedItems.push(validResult.fields[fk])
        }
        if (validResult.fields[fk].type === 'custom') {
          changedItems.push({
            key: validResult.fields[fk].key,
            value: validResult.fields[fk].value,
            originalValue: '(new field)'
          })
        }
        if (validResult.fields[fk].isDeleted === true) {
          changedItems.push({
            key: validResult.fields[fk].key,
            value: validResult.fields[fk].value,
            originalValue: '(deleted)'
          })
        }
      })

      Object.keys(validResult.features).filter(it => ['faces', 'signatures', 'glares'].includes(it)).map(fk => {
        validResult.features[fk].filter(f => f.isDeleted === true).map((f, fInd) => {
          changedItems.push({
            key: fk + '-' + (fInd + 1),
            originalValue: '(deleted)'
          })
        })
      })

      Object.keys(validResult.features.properties).filter(it => ['isGlareFree', 'isInFocus', 'isWatermarkFree'].includes(it)).map(fk => {
        const originalValue = validResult.features.properties[`original${capitalizeFirstLetter(fk)}`]
        if (originalValue !== undefined && originalValue !== validResult.features.properties[fk]) {
          changedItems.push({
            key: fk,
            value: validResult.features.properties[fk] ? 'checked' : 'unchecked',
            originalValue: originalValue ? 'checked' : 'unchecked'
          })
        }
      })

      if (validResult.model?.originalName !== undefined || validResult.model?.originalType !== undefined) {
        changedItems.push({
          key: 'Model name',
          originalValue: validResult.model.originalName,
          value: validResult.model.name
        })
        changedItems.push({
          key: 'Model type',
          originalValue: validResult.model.originalType,
          value: validResult.model.type
        })
      }
    })
    const action = () => {
      this.setState({
        isLoaded: false,
        loadingMessages: {
          title: 'Uploading your review...'
        }
      })
      window.scrollBy({
        top: -10000,
        behavior: 'smooth'
      })
      this.removeDeletedFieldsAndFeaturesFromResponse(validResults)
      const resultUuid = this.props.resultUuid || ''
      if (!resultUuid) {
        return this.setState({ isSubmitted: false })
      }
      const requestOptions = {
        endpoint: '/result/' + resultUuid,
        body: {
          testIntegrationType: options?.testIntegrationType,
          status: options?.status || 'approved',
          results: validResults,
          elapsedTime: (Date.now() - this.startTime) / 1000
        }
      }
      if (options?.status === 'rejected' && this.state.rejectionReason?.length) {
        requestOptions.body.rejectionReason = this.state.rejectionReason
      }
      request(
        {
          endpoint: requestOptions.endpoint,
          body: requestOptions.body
        }, (error, _) => {
          this.setState({
            isLoaded: true,
            loadingMessages: null
          })
          if (error) {
            return this.context.displayModal({
              title: 'Errors in submission',
              content: (
                <div>
                  <p>Please correct the following errors:</p>
                  <ul
                    style={{
                      textAlign: 'left',
                      marginTop: '1rem',
                      listStyle: 'auto'
                    }}
                  >
                    Unable to submit:
                    {' '}
                    { error.message }
                  </ul>
                </div>
              ),
              options: [
                { content: 'OK' }
              ],
              isClosable: true
            })
          }
          let flowDisplayOptions = [{ content: 'OK' }]
          let contentMessage = 'Submission completed'
          if (this.state.flow?.flowID) {
            const { flows } = this.props
            if (flows) {
              const flow = flows?.find(it => it.flowID === this.state.flow?.flowID)
              if (flow?.results && flow.results?.length > 0) {
                const flowResult = flow.results.find(it => it.resultUuid === this.props.resultUuid)
                if (flowResult) {
                  flowResult.status = options?.status || 'approved'
                }
              }
            }
            flowDisplayOptions = [
              {
                content: 'See all results',
                intent: 'negative',
                action: () => {
                  this.context.redirect(`/flow/results/${this.state.flow.flowID}`)
                }
              },
              {
                content: 'Go to next result',
                action: () => {
                  this.setState({
                    isLoaded: false,
                    loadingMessages: {
                      title: 'Fetching next result...'
                    }
                  })
                  window.scrollBy({
                    top: -10000,
                    behavior: 'smooth'
                  })
                  request({
                    method: 'GET',
                    endpoint: `/result/?nextTimestamp=${this.state.createdAt}&limit=1&flowID=${this.state.flow.flowID}&filter=needsReview`
                  }, (err, nextNeedsReviewResponse) => {
                    if (err) {
                      this.context.redirect(`/flow/results/${this.state.flow.flowID}`)
                    }
                    if (nextNeedsReviewResponse?.results?.length && nextNeedsReviewResponse.results[0].resultUuid) {
                      this.setState({ isSubmitted: true })
                      this.context.redirect(`/hitl/${nextNeedsReviewResponse.results[0].resultUuid}`)
                      window.location.reload()
                    } else {
                      this.context.redirect(`/flow/results/${this.state.flow.flowID}`)
                    }
                  })
                }
              }
            ]
            contentMessage = (
              <>
                The document is {hitlStatusText}. If the flow has output integration,
                <br />
                they will be executed with the updated results automatically.
              </>
            )
          }
          this.context.displayModal({
            title: 'Success',
            content: (
              <div>
                <p>{contentMessage}</p>
              </div>
            ),
            options: flowDisplayOptions,
            isWide: true,
            isClosable: true
          })
          this.setState({ isSubmitted: false })
        }, error => alert('Unable to submit : ' + error)
      )
    }
    if (options.disablePopUp) {
      return action()
    }
    this.context.displayModal({
      isForbiddenToClickOutside: true,
      title: 'Confirmation',
      isWide: true,
      content: (
        <div>
          <p>
            Do you want to
            {' '}
            {options.testIntegrationType ? 'test' : options?.status === 'rejected' ? 'reject' : 'approve'}
            {' '}
            this document
            {(changedItems.length && (!options?.status || options?.status === 'approved')) ? ` with ${changedItems.length} modification${changedItems.length > 1 ? 's' : ''}` : ''}
            ?
          </p>
          { (!options?.status || options?.status === 'approved')
            ? (
                <ul style={{
                  textAlign: 'left',
                  marginTop: '1rem',
                  listStyle: 'auto'
                }}
                >
                  {changedItems.map((ci, i) => (
                    <li key={i} style={{ marginTop: '0.5rem' }}>
                      {ci.originalKey && (
                        <>
                          <b>
                            {ci.originalKey}
                            {' '}
                            (key)
                          </b>
                          {' '}
                          &rarr;
                          {' '}
                          {ci.key}
                          <br />
                        </>
                      )}
                      {ci.originalValue === '(deleted)'
                        ? (
                            <>
                              <b>
                                {ci.key}
                                :
                              </b>
                              {' '}
                              <i>(deleted)</i>
                            </>
                          )
                        : (
                            <>
                              <b>
                                {ci.key}
                                :
                              </b>
                              {' '}
                              {ci.originalValue || <i>(Empty)</i>}
                              {' '}
                              &rarr;
                              {' '}
                              {ci.value || <i>(Empty)</i>}
                            </>
                          )}
                    </li>
                  )
                  )}
                </ul>
              )
            : options?.status === 'rejected'
            && (
              <CustomSurvey
                questionnaire={this.configuration.rejectionReasonList}
                selectedItem={0}
                isOtherAvailable={true}
                otherPlaceHolder='You can specify your rejection reason...'
                onChangeHandle={(index, rejectionReason) => {
                  this.setState({ rejectionReason })
                }}
              />
            )}
        </div>
      ),
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: 'Yes',
          action
        }
      ]
    })
  }

  selectionChangeHandler (data, event) {
    if (event?.shiftKey) {
      data = [...new Set([...this.state.dataSelectionResult?.data || [], ...data])]
    }
    this.setState({
      dataSelectionResult: {
        data,
        targetReference: this.state.selectedDataReference
      }
    })
  }

  updateResult (key, value, options, callback = () => {}) {
    options = options || {}
    options.parent = options.parent || 'fields'
    options.resultIndex = options.resultIndex || 0
    const tempResult = [...this.state.results]
    if (options.tableIndex !== undefined) {
      const updateBasicTable = () => {
        tempResult[options.resultIndex].features.tables = tempResult[options.resultIndex].features.dom.pages.map(page =>
          page.tables.map(table =>
            table.rows.map(row =>
              row.cells.map(cell =>
                cell.text
              )
            )
          )
        ).flat(1)
      }
      if (options.remove) {
        tempResult[options.resultIndex].features.dom.pages[options.pageIndex].tables.splice(options.tableIndex, 1)
        // add changelog about table removal
        const changelog = `Table ${options.tableIndex + 1} on page ${options.pageIndex + 1 + (this.state.results?.[0]?.features?.properties?.startPage || 0)}`
        tempResult[options.resultIndex].features.removedTables = tempResult[options.resultIndex].features.removedTables || []
        tempResult[options.resultIndex].features.removedTables = [...new Set([...tempResult[options.resultIndex].features.removedTables, changelog])]
        updateBasicTable()
        return this.setState({ results: tempResult }, callback)
      } else if (options.add) {
        tempResult[options.resultIndex].features.dom.pages[options.pageIndex].tables = tempResult[options.resultIndex].features.dom.pages[options.pageIndex].tables || []
        tempResult[options.resultIndex].features.dom.pages[options.pageIndex].tables.push(value.table)
        // add changelog about table addition
        const changelog = `Table ${tempResult[options.resultIndex].features.dom.pages[options.pageIndex].tables.length} on page ${options.pageIndex + 1 + (this.state.results?.[0]?.features?.properties?.startPage || 0)}`
        tempResult[options.resultIndex].features.addedTables = tempResult[options.resultIndex].features.addedTables || []
        tempResult[options.resultIndex].features.addedTables = [...new Set([...tempResult[options.resultIndex].features.addedTables, changelog])]
        updateBasicTable()
        return this.setState({ results: tempResult }, callback)
      }
      const changeLog = `Table ${options.tableIndex + 1} on page ${options.pageIndex + 1 + (this.state.results?.[0]?.features?.properties?.startPage || 0)}`
      tempResult[options.resultIndex].features.changedTables = tempResult[options.resultIndex].features.changedTables || []
      tempResult[options.resultIndex].features.changedTables = [...new Set([...tempResult[options.resultIndex].features.changedTables, changeLog])]
      value.table.rows.forEach(row => {
        row.text = row.cells.map(cell => cell.text).join('\t')
      })
      value.table.text = value.table.rows.map(row => row.text).join('\n')
      tempResult[options.resultIndex].features.dom.pages[options.pageIndex].tables[options.tableIndex] = value.table
      updateBasicTable()
    } else if (options.objectIndex !== undefined) {
      tempResult[options.resultIndex][options.parent][key][options.objectIndex] = value
    } else if (options.parent === 'properties') {
      if (!tempResult[options.resultIndex].features.properties[`original${capitalizeFirstLetter(key)}`]) {
        tempResult[options.resultIndex].features.properties[`original${capitalizeFirstLetter(key)}`] = tempResult[options.resultIndex].features.properties[key]
      }
      tempResult[options.resultIndex].features.properties[key] = value
    } else if (key === 'self-object') {
      if (!tempResult[options.resultIndex][`original${capitalizeFirstLetter(options.parent)}`]) {
        tempResult[options.resultIndex][`original${capitalizeFirstLetter(options.parent)}`] = tempResult[options.resultIndex][options.parent]
      }
      tempResult[options.resultIndex][options.parent] = value
    } else if (key === 'echo-parameter') {
      if (!tempResult[options.resultIndex].features.originalEcho) {
        tempResult[options.resultIndex].features.originalEcho = tempResult[options.resultIndex].echo
      }
      tempResult[options.resultIndex].echo = value.value
    } else {
      tempResult[options.resultIndex][options.parent][key] = value
    }

    this.setState({ results: tempResult, isSubmitted: false }, callback)
  }

  deleteResult (index) {
    let { results } = this.state
    const { activeResultIndex } = this.state
    if (results.length === 1) {
      return alert('You need at least one result to continue to review')
    }
    results[index] = null
    results = results.filter(s => s)
    this.forceUpdateState({ results, activeResultIndex })
  }

  deleteUpload (resultUuid, fileName, linkedResults) {
    this.context.displayModal({
      title: 'Confirmation',
      content: (
        <p>
          Do you want to delete
          {' '}<b>{fileName}</b>{' '}
          and
          {' '}{linkedResults.length}{' '}
          related result
          {linkedResults.length > 1 ? 's' : '' }
          ?
        </p>
      ),
      options: [
        {
          content: 'Cancel',
          intent: 'positive'
        },
        {
          content: 'Delete',
          intent: 'negative',
          action: () => {
            this.setState({
              isLoaded: false,
              loadingMessages: {
                title: 'Deleting documents and results...'
              }
            }, () => {
              request({
                endpoint: '/result/delete/',
                method: 'POST',
                body: {
                  results: [resultUuid]
                }
              }, err => {
                if (err) {
                  return this.setState({
                    isLoaded: true,
                    loadingMessages: {
                      title: ''
                    }
                  }, () => {
                    alert(err)
                  })
                }
                this.context.redirect(`/flow/results/${this.state.flow?.flowID}`)
                window.location.reload()
              })
            })
          }
        }
      ],
      isClosable: true
    })
  }

  resetResults () {
    this.forceUpdateState({ results: JSON.parse(this.state.originalResults) })
  }

  addNewResult (callback) {
    const { activeResultIndex, results } = this.state
    let newResultReference = results
    if (!results.length) {
      newResultReference = JSON.parse(this.state.originalResults)
    }
    const index = activeResultIndex || 0
    const emptyResult = {
      model: newResultReference[index].model,
      fields: {},
      features: {
        creditsSpent: newResultReference[index]?.features?.creditsSpent,
        properties: newResultReference[index]?.features?.properties,
        signatures: newResultReference[index]?.features?.signatures,
        faces: newResultReference[index]?.features?.faces,
        glares: newResultReference[index]?.features?.glares,
        dom: newResultReference[index]?.features?.dom
      },
      ocr: '',
      uuid: uuid.v4()
    }
    results.push(clone(emptyResult))
    this.forceUpdateState({ results }, callback)
  }

  rejectResult () {
    this.submitResponse({ status: 'rejected' })
  }

  approveResult (options) {
    this.submitResponse({ status: 'approved', ...options })
  }

  rerunExportIntegration () {
    const resultUuid = this.props.resultUuid || ''
    if (!resultUuid) {
      return this.setState({ isSubmitted: false })
    }
    this.context.displayModal({
      title: 'Confirmation',
      content: 'Are you sure you want to rerun export workflow?',
      options: [
        {
          content: 'Cancel'
        },
        {
          content: 'Rerun',
          intent: 'negative',
          action: () => {
            rerunIntegration(resultUuid, err => {
              if (err) {
                return this.context.displayModal({
                  title: 'Error while rerunning export workflow',
                  content: (
                    <div>
                      <p>Please correct the following errors:</p>
                      <ul
                        style={{
                          textAlign: 'left',
                          marginTop: '1rem',
                          listStyle: 'auto'
                        }}
                      >
                        Unable to rerun:
                        {' '}
                        { err.message }
                      </ul>
                    </div>
                  ),
                  options: [
                    { content: 'OK' }
                  ],
                  isClosable: true
                })
              }
              this.context.displayModal({
                title: 'Successful',
                content: (
                  <div>
                    <p>Export workflow successfully rerun</p>
                  </div>
                )
              })
            })
          }
        }
      ]
    })
  }

  reprocessDocument (filteredModel, resultIndex) {
    const results = this.state.results
    const options = {}
    if ((filteredModel || '').toLowerCase() === 'unrecognized') {
      options.notReprocess = { label: 'Unrecognized', value: 'unrecognized' }
    }
    if (options?.notReprocess) {
      const result = results[resultIndex]
      result.model = {
        name: options.notReprocess.label,
        type: options.notReprocess.value,
        originalType: result.model.type,
        originalName: result.model.name
      }
      this.setState({
        results
      })
    }
    if (!options?.notReprocess) {
      reCaptcha('demo', token => {
        this.setState({
          isLoaded: false,
          loadingMessages: {
            title: 'Reprocessing document...'
          }
        })
        request({
          endpoint: '/scan/hitl/' + this.props.resultUuid,
          headers: {
            'base64ai-flow-id': this.state.flow?.flowID
          },
          body: { token, modelTypes: [filteredModel] }
        }, (err, reprocessResult) => {
          this.setState({
            isLoaded: true,
            loadingMessages: null
          })
          if (err) {
            return alert('Unable to reprocess document ' + err.message)
          }
          this.forceUpdateState({
            results: reprocessResult
          })
        }, err => {
          return alert('Unable to reprocess document ' + err.message)
        })
      })
    }
  }

  showRejectionReason (reviewer, reason) {
    this.context.displayModal({
      title: 'Rejection reason',
      content: (
        <div>
          <p><b>{`This result is rejected by ${reviewer}. Reason: `}</b></p>
          <p style={{ marginLeft: '1rem' }}>{reason}</p>
        </div>
      ),
      options: [{
        content: 'OK',
        intent: 'positive'
      }],
      isClosable: true
    })
  }

  updateContext () {
    let { flows } = this.props
    const { flow } = this.state // fetch up-to-date flow & results from API
    if (!flows) {
      flows = [flow]
    }
    const injected = flows.map(fl => {
      if (fl.flowID === flow.flowID) {
        if (fl.results?.length) {
          flow.results = fl.results
        }
        return flow
      } else return fl
    })
    this.props.setFlows(injected)
  }

  getCustomModel (modelUUID, callback) {
    request({
      endpoint: '/custom-model/' + modelUUID,
      method: 'GET'
    }, (err, response) => {
      if (err) {
        return alert('Unable to get custom model ' + err.message)
      }
      callback(response)
    })
  }

  addQuestionsToModel (model, modelUUID, callback) {
    request({
      endpoint: '/custom-model/' + modelUUID,
      method: 'POST',
      body: model
    }, (err, response) => {
      if (err) {
        return callback(err)
      }
      callback(null, response)
    })
  }

  getAndAddQuestionsToCustomModel (questions, modelUUID, callback) {
    this.getCustomModel(modelUUID, model => {
      const customModel = model
      const existingQuestions = customModel.questions || []
      const existingQuestionKeys = existingQuestions.map(q => q.key)
      const newQuestions = questions.filter(q => !existingQuestionKeys.includes(q.key))
      if (!newQuestions.length) {
        return callback(new Error('Question already exists'))
      }
      customModel.questions = [...existingQuestions, ...newQuestions]
      this.addQuestionsToModel(customModel, modelUUID, callback)
    })
  }

  addQuestionsToFlow (questions, callback) {
    request({
      endpoint: '/flow/' + this.state.flow.flowID + '?question=true',
      method: 'POST',
      body: {
        questions
      }
    }, (err, response) => {
      if (err) {
        return callback(err)
      }
      this.setState({ flow: response }, () => {
        this.updateContext()
        callback(null, response)
      })
    })
  }

  getDocumentType () {
    const { results } = this.state
    return results?.[0]?.model?.type || ''
  }

  isDocumentSupportedForPreview () {
    const documentType = this.getDocumentType()
    return !['object/csv', 'object/plain'].includes(documentType)
  }

  render () {
    const { hitlStatusToText } = this.configuration
    const {
      results,
      hitlStatus,
      reviewedBy,
      rejectionReason,
      isDocumentUnderReview
    } = this.state
    return (
      <>
        <NavigationPrompt messageFunction={(location, action) => {
          const isLeaving = !location.pathname.startsWith(`/hitl/${this.props?.resultUuid}`) || action === 'LEAVE'
          if (isLeaving && !this.state.isSubmitted) {
            return Messages.CHANGES_NOT_SAVED
          }

          return true
        }}
        />
        {
          this.state.isLoaded
            ? (
                <div className={'hitl-container ' + (this.state.isMobile ? 'mobile' : '')}>
                  { !this.state.isMobile && this.state.isSelected && <SelectionArrow context={this.state} />}
                  {
                    results.length > 0
                    && ['approved', 'rejected', 'autoApproved'].includes(hitlStatus)
                    && (
                      <Banner
                        className={hitlStatus}
                      >
                        <p style={{ fontWeight: '600' }}>
                          This result was already
                          {' '}
                          {hitlStatusToText[hitlStatus] || hitlStatus}
                          { reviewedBy?.length
                            ? ' by ' + reviewedBy[0]
                            : ''}
                          .
                          {' '}
                          {(hitlStatus === 'rejected' && rejectionReason?.length)
                            ? <Link onClick={() => this.showRejectionReason(reviewedBy[0], rejectionReason)}>See reason</Link>
                            : ''}
                        </p>
                        {
                          hitlStatus !== 'rejected'
                          && (
                            <p style={{ fontWeight: '400' }}>
                              You cannot make further changes.&nbsp;
                              <a target='_blank' href='https://help.base64.ai/kb/guide/en/flows-human-in-the-loop-document-processing-WH1QqHEiey/Steps/2068067' rel='noreferrer'>Learn more</a>
                            </p>
                          )
                        }
                      </Banner>
                    )
                  }
                  {
                    results.length > 0
                    && isDocumentUnderReview
                    && (
                      <Banner className='under-review'>
                        <p style={{ fontWeight: '600' }}>Other parties are currently reviewing this result.</p>
                      </Banner>
                    )
                  }
                  <div className='hitl-header'>
                    <div className='left'>
                      <BackButton
                        placeholder={this.state.flow?.name}
                        onClick={() => {
                          this.context.redirect(`/flow/${this.state.flow?.flowID}/result`)
                        }}
                      />
                      <MaterialIcon name='chevron_right' className='forward-arrow' />
                      <span className='hitl-header-title'>{this.state.fileName}</span>
                    </div>
                  </div>
                  <div className='hitl-body'>
                    {this.state.activeResultIndex !== null
                      ? this.isDocumentSupportedForPreview()
                        ? (
                            <ImageViewer
                              selectionChangeHandler={this.selectionChangeHandler}
                              mode='view'
                              context={this.state}
                              contextProvider={{ setState: data => {
                                this.setState({ ...data })
                              } }}
                              annotations={this.getAnnotations()[this.state.activeResultIndex]}
                              startPage={this.state.results?.[0]?.features?.properties?.startPage || 0}
                              images={this.state.file}
                            />
                          )
                        : (
                            <div className='no-preview'>
                              <p>Preview is not available for this document type.</p>
                            </div>
                          )
                      : <div>No result</div>}
                    <DataExtractionResultViewer
                      context={this.state}
                      results={this.state.results}
                      contextProvider={{
                        setState: data => {
                          this.setState({ ...data })
                        },
                        updateResult: this.updateResult,
                        deleteResult: this.deleteResult,
                        resetResults: this.resetResults,
                        addNewResult: this.addNewResult,
                        rejectResult: this.rejectResult,
                        approveResult: this.approveResult,
                        rerunExportIntegration: this.rerunExportIntegration,
                        reprocessDocument: this.reprocessDocument,
                        deleteUpload: this.deleteUpload,
                        addQuestionsToFlow: this.addQuestionsToFlow,
                        getAndAddQuestionsToCustomModel: this.getAndAddQuestionsToCustomModel,
                        displayModal: this.context.displayModal
                      }}
                      user={this.props.user}
                    />
                  </div>
                </div>
              )
            : (
                <LoadingModal
                  data={{
                    status: 'loading',
                    title: this.state.loadingMessages?.title || 'Retrieving result...',
                    description: this.state.loadingMessages?.description || 'Please wait, this may take a few seconds.'
                  }}
                />
              )
        }
      </>
    )
  }
}

HITL.propTypes = {
  user: PropTypes.object,
  resultUuid: PropTypes.string
}

export default HITL
