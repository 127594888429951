// Modules
import { Component } from 'react'

import Section from '~/layout/section/Section'
import { isWorkingOffline } from '~/context/environment'

// Content: TeaserSection
class TeaserSection extends Component {
  constructor (params) {
    super(params)
  }

  render () {
    return (
      !isWorkingOffline()
      && (
        <Section
          name='teaser'
          identifier
        >
          <h2 className='title'>Automate data extraction<br />with no-code integrations, one-call API, or our RPA partners</h2>
          <h3 className='introduction'>
            We have a scalable solution for all document types.<br />
            Automate existing business processes in minutes.<br />
            No coding experience or training needed!
          </h3>
        </Section>
      )
    )
  }
}

// Export
export default TeaserSection
