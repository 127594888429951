import { AppButton } from '../../interface/app-button/AppButton'
import PropTypes from 'prop-types'

const FlowGreetings = ({ context, userLoggedIn }) => {
  const redirectTo = userLoggedIn => {
    !userLoggedIn && context.redirect('/login?next=/flow')
  }
  return (
    <div className='flow-greetings'>
      <h2 className='slogan'>
        Welcome to Base64.ai Flows!
      </h2>
      <h3 className='introduction'>
        Flows are the easiest way to process your documents. Our AI can read documents from any source, process all types of documents, validate the results, and integrate your data to any system.{' '}
        <a href='https://help.base64.ai/kb/guide/en/flows-human-in-the-loop-document-processing-WH1QqHEiey/Steps/2068067' target='_blank' rel='noreferrer'>Learn more</a>
      </h3>
      <ul>
        <li onClick={() => {
          redirectTo(userLoggedIn)
        }}
        >
          <img src='/static/images/icons/cabinet.png' className='green-overlay'></img>
          <p className='featureTitle'>
            Read documents from 400+
            <br></br>
            data sources
          </p>
          <p className='featureDescription'>Import data from web, mobile, email, API, scanner, Google Drive, Dropbox, OneDrive, AWS, S3, etc...</p>
        </li>
        <li onClick={() => {
          redirectTo(userLoggedIn)
        }}
        >
          <img src='/static/images/icons/brain.png' className='green-overlay'></img>
          <p className='featureTitle'>
            Extract data from 2,800+
            <br></br>
            document types
          </p>
          <p className='featureDescription'>Process IDs, driver&apos;s licenses, passports, visas, invoices, receipts, checks, forms, vaccine cards, etc...</p>
        </li>
        <li onClick={() => {
          redirectTo(userLoggedIn)
        }}
        >
          <img src='/static/images/icons/good-quality.png' className='green-overlay'></img>
          <p className='featureTitle'>
            Review results to achieve
            <br></br>
            100% accuracy
          </p>
          <p className='featureDescription'>Validate the results with your team or ask Base64.ai to validate for you</p>
        </li>
        <li onClick={() => {
          redirectTo(userLoggedIn)
        }}
        >
          <img src='/static/images/icons/shipped.png' className='green-overlay'></img>
          <p className='featureTitle'>
            Integrate your data
            <br></br>
            to anywhere
          </p>
          <p className='featureDescription'>Export your data automatically to Google Sheets, Quickbooks, Databases, API, SFTP, etc...</p>
        </li>
      </ul>
      { userLoggedIn
        ? (
            <AppButton className='secondary-cta' href='/flow/new'>
              Create your first flow
            </AppButton>
          )
        : (
            <AppButton className='secondary-cta' id='newFlowButton' href='/login?next=/flow'>
              Login to access flows
            </AppButton>
          )}
    </div>
  )
}

FlowGreetings.propTypes = {
  context: PropTypes.object,
  userLoggedIn: PropTypes.bool
}

export default FlowGreetings
