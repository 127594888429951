export const toCamelCase = str => {
  return str
    .toLowerCase()
    .replace(/\(.*\)/g, '') // Remove text in parentheses
    .replace(/[._-]/g, ' ') // Replace separators with space
    .replace(/[^\w ]+/gm, '') // Remove non-alphanumeric, non-space characters
    .trim() // Trim whitespace
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) => idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()).replace(/\s+/g, '')
}

export const camelCaseToTitleCase = str => {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase()
  })
}

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const toLowercaseFirstLetter = string =>
  string.charAt(0).toLowerCase() + string.slice(1)

export const twoDigits = digit =>
  ('0' + digit).slice(-2)

export const toIsoDate = millis => {
  if (!millis || typeof millis !== 'number') {
    return ''
  }
  const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000
  try {
    return new Date(millis - timeZoneOffset).toISOString().slice(0, 19).replace('T', ' ')
  } catch (e) {
    console.error('toIsoDate: Invalid millis', millis)
    return 'Unknown'
  }
}

export const createSelectFieldsFromString = (str, preexistingFields = [], delimiter = ',') => {
  // use delimiter to split but not if the delimiter has a \ before it
  const delimiterRegex = new RegExp(`(?<!\\\\)${delimiter}`)
  const fields = str.split(delimiterRegex).map(ff => {
    return { value: ff?.trim(), label: ff?.trim() }
  })?.filter(ff => ff.value) || []
  // clear the delimiter from the fields
  fields.forEach(f => {
    f.value = f.value.replace(new RegExp(`\\\\${delimiter}`, 'g'), delimiter)
    f.label = f.label.replace(new RegExp(`\\\\${delimiter}`, 'g'), delimiter)
  })
  return [...preexistingFields, ...fields]
}

// eslint-disable-next-line no-undef
export const formatNumber = (value, locales = 'en-US', config = {}) => new Intl.NumberFormat(locales, config).format(value)

/**
 * Function to get the first float number in a string
 */
export const getFirstFloat = str => {
  const match = str.match(/-?\d+(?:,\d+)*(?:\.\d+)?/)
  return match ? parseFloat(match[0].replace(/,/g, '')) : 0
}

export const formatLLMResponse = response => {
  const text = (response?.answer || response?.value || '').toString()
  return text?.replace?.(/\*\*(.*?)\*\*/g, '<b>$1</b>').replace(/\n/g, '<br />')
}
