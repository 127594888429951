import flowSearchKeyFields from './flowSearchKeyFields'

export default {
  columns: [
    {
      columnName: 'Order by',
      inputType: 'creatable-select',
      fieldName: 'orderBy',
      placeholder: 'Enter key name...',
      options: flowSearchKeyFields,
      defaultValue: {
        label: 'Updated at',
        value: 'meta.updatedAt'
      },
      isMulti: false,
      isCamelCase: true
    },
    {
      columnName: 'Sort order',
      inputType: 'select',
      fieldName: 'orderType',
      options: [
        { label: 'Descending', value: 'desc' },
        { label: 'Ascending', value: 'asc' }
      ],
      defaultValue: 'desc',
      isMulti: false
    }
  ],
  addButtonText: 'Add additional order criteria',
  addButtonTitle: 'Add additional order criteria to sort documents precisely with their content.',
  isDeleteButtonEnabled: true,
  className: 'three-column flow-search'
}
