// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Section from '~/layout/section/Section'
import View from '~/layout/view/View'
import PropTypes from 'prop-types'

// Context
import Context from '~/context/global'
import Header from '~/layout/header/Header'
import Link from '~/interface/link/Link'

// View: Blog
class Blog extends Component {
  static contextType = Context
  constructor (props) {
    super(props)
    this.state = {
      type: this.props.type,
      posts: []
    }
    this.fetchData = this.fetchData.bind(this)
    this.getDateTime = this.getDateTime.bind(this)
    this.getPostContent = this.getPostContent.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.renderLine = this.renderLine.bind(this)
  }

  componentDidMount () {
    if (!this.state.posts.length || this.state.type !== this.props.type) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.type !== prevProps.type) {
      this.fetchData()
    }
  }

  fetchData () {
    fetch(`/api/${this.props.type || 'blog'}/posts/`, { method: 'POST' })
      .then(res => res.json())
      .then(data => {
      // create two-dimensional array with 3 elements per inner array
        this.setState({ posts: data.sort((a, b) => b.createdAt - a.createdAt) })
      })
  }

  getPostContent (postKey) {
    return (this.state.posts || []).filter(post => post.postKey === postKey.split('-').splice(-1)[0])[0]
  }

  getDateTime (ms) {
    return new Date(ms).toLocaleDateString()
  }

  renderLine (item) {
    return (
      <>
        <img src={item.image} alt={`Cover image of ${item.title}`} />
        <article className='content'>
          <h2 className='title'>{item.title}</h2>
          <h3 className='info'>{item.author} on {this.getDateTime(item.createdAt)}</h3>
          <main>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </main>
        </article>
      </>
    )
  }

  renderItem (item, i) {
    return (
      <li key={i}>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        {item.url.startsWith('/')
          ? (
              <Link className='row' to={item.url} title={item.title}>
                {this.renderLine(item)}
              </Link>
            )
          : (
              <a className='row' href={item.url} target='_blank' rel='noopener noreferrer' title={item.title}>
                {this.renderLine(item)}
              </a>
            )}
      </li>
    )
  }

  render () {
    const { posts } = this.state
    return (
      <View name='blog'>
        <Header name='blog'>
          <h1 className='slogan'>
            <b>{this.props.type ? 'Case Studies' : 'News About Base64.ai'}</b>
          </h1>
          <h3 className='introduction'>
            {this.props.type ? 'See the innovative companies driving document automation' : 'Read about us and articles related to automated document processing'}
          </h3>
        </Header>
        <Section name='posts'>
          <ul>
            {posts.map((item, index) => this.renderItem(item, index))}
          </ul>
        </Section>
      </View>
    )
  }
}

Blog.propTypes = {
  type: PropTypes.string,
  postKey: PropTypes.string
}

// Export within router
export default withRouter(Blog)
