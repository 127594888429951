import { Component } from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import { handleModels } from '../../../helpers/handleModels'

export default class SelectDocumentTypes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filter: [],
      documentTypes: []
    }

    this.configuration = {
      modelsData: [],
      filterStyle: {
        container: provided => ({
          ...provided,
          marginBottom: '1.25rem'
        })
      }
    }
  }

  componentDidMount () {
    this.props.getModels && this.props.getModels((error, response) => {
      if (!error) {
        this.configuration.modelsData = handleModels(response)
        this.setState({ models: response })
      }
    })
  }

  render () {
    const {
      modelsData,
      filterStyle
    } = this.configuration

    return (
      <>
        <div className='main'>
          <div className='description'>
            <h2>What type of documents would you like to process?</h2>
            <h3 className='introduction small'>
              Base64.ai processes all document types including IDs, passports, invoices, receipts, checks, and forms.
              Select or enter the type of documents you wish to process (multiple selection is allowed).
            </h3>
          </div>
          <div className='entries'>

            <CreatableSelect
              isMulti
              className='react-select'
              classNamePrefix='react-select'
              value={this.props.parent.state.documentTypes}
              styles={filterStyle}
              options={modelsData}
              closeMenuOnSelect={false}
              style={{ marginBottom: '40px' }}
              placeholder='Select or enter document types...'
              noOptionsMessage={() => 'No document type found'}
              filterOption={({
                label,
                value
              }, query) => {
                if (!query) {
                  return true
                }
                const modelName = label.toLowerCase()
                const modelType = value.toLowerCase()
                query = query.toLowerCase()
                for (const part of query.split(' ').filter(x => x.trim())) {
                  if (!modelName.includes(part) && !modelType.includes(part)) {
                    return false
                  }
                }
                return true
              }}
              onChange={filtered => {
                this.setState(prev => {
                  return { ...prev, filter: filtered }
                })
                this.props.parent.setState(prev => {
                  return {
                    ...prev,
                    documentTypes: filtered
                  }
                }) // update the parent state as well
              }}
            />
          </div>
        </div>
      </>
    )
  }
}

SelectDocumentTypes.propTypes = {
  models: PropTypes.array,
  getModels: PropTypes.func,
  parent: PropTypes.object
}
