import React, { Component, createRef } from 'react'

// Interface
import MultiEmail from '../../interface/multi-email/MultiEmail'
import Link from '../../interface/link/Link'
import Checkbox from '../../interface/checkbox/Checkbox'
import RadioButton from '../../interface/radio-button/RadioButton'
import IntegrationManager from '../../interface/integration-manager/IntegrationManager'
import MaterialIcon from '../../interface/material-icon/MaterialIcon'
import { Slider } from '../../interface/slider/Slider'

// Constants
import { defaultTablePositionOptions } from '~/constants'

// Layout
import View from '~/layout/view/View'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'

// Context
import Context from '~/context/global'

// Helpers
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { request } from '~/helpers/request'
import { getNestedObject, setNestedObject } from '~/helpers/object'
import Select from 'react-select'
import window from 'global'
import PropTypes from 'prop-types'
import FileInput from '../../interface/file-input/FileInput'
import acceptedFileFormats from '../../data/acceptedFileFormats'
import { getBase64 } from '~/helpers/file'
import DownloadLink from '../../interface/download-link/DownloadLink'
import tabs from '../../data/flowTabs.js'
import { hasFeature, isWorkingOffline } from '../../context/environment'
import TimeIntervalPicker from '../../interface/time-interval-picker/TimeIntervalPicker'
import additionalListColumnsFields from '../../data/additionalListColumnsFields'
import additionalListColumnsTable from '../../data/additionalListColumnsTable'
import MultiInputTable from '../../interface/multi-input-table/MultiInputTable'
import ResultMustHaveDisabledFields from '../../interface/result-must-have-disabled-fields/ResultMustHaveDisabledFields'
import FlowCreatableSelect from '../../interface/flow-creatable-select/FlowCreatableSelect'
import { createSelectFieldsFromString, toCamelCase } from '../../utilities/format'
import { handleModels } from '../../helpers/handleModels'
import LoadingModal from '../../interface/loading-modal/LoadingModal'
import BackButton from '../../interface/back-button/BackButton.jsx'
import QuestionAnswer from '../../interface/question-answer/QuestionAnswer.jsx'
import Taxonomy from '../../interface/taxonomy/Taxonomy.jsx'
import { defaultQuestions, defaultTaxonomies } from '~/constants'
import { isEqual } from 'radash'
import { convertToJSONObject } from '../../utilities/object.js'
import { HelpButton } from '../../interface/help-button/HelpButton.jsx'
import { Messages } from '~/constants'
import { NavigationPrompt } from '../../interface/navigation-prompt/NavigationPrompt.jsx'
import { AppButton } from '../../interface/app-button/AppButton.jsx'
import { AppDropdown } from '../../interface/app-dropdown/AppDropdown.jsx'
import clsx from 'clsx'

class CreateFlow extends Component {
  static contextType = Context

  static propTypes = {
    user: PropTypes.object,
    fetching: PropTypes.bool,
    isEdit: PropTypes.bool,
    flowID: PropTypes.string,
    getModels: PropTypes.func,
    activeTab: PropTypes.string,
    setFlows: PropTypes.func,
    addFlow: PropTypes.func,
    deleteFlow: PropTypes.func,
    flows: PropTypes.array
  }

  static defaultProps = {
    isEdit: false,
    flowID: null,
    activeTab: ''
  }

  constructor (props) {
    super(props)
    this.state = {
      user: this.props.user,
      isLoaded: false,
      messageObject: {
        title: 'Loading...',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      },
      name: 'Your New Base64.ai Flow',
      flowData: null,
      emailSubject: 'Your Process Results',
      hitlMode: 'hitl-active',
      responseEmails: [],
      hitlEmails: [],
      uploadNotifierStatus: false,
      allowedUploaders: [],
      administrators: [],
      taxonomies: defaultTaxonomies,
      questions: defaultQuestions,
      settings: this.createDefaultSettings(),
      defaultSettings: this.createDefaultSettings(),
      resultManipulation: {
        disabledFields: [],
        mustHaveFields: [],
        disableAllExceptMustHaveFields: false
      },
      replyMail: true,
      additionalListColumns: this.getAdditionalListColumns(),
      isSubmitted: true,
      isMobile: window?.innerWidth < 767,
      isBottomSaveButtonEnabled: window?.scrollY > 150,
      customModelOptions: [],
      customModels: []
    }

    this.tabList = Object.keys(tabs)
    this.taxonomyRef = createRef()

    this.dataTypes = [
      {
        label: 'Any',
        value: 'text'
      },
      {
        label: 'Number',
        value: 'number'
      },
      {
        label: 'Date',
        value: 'date'
      }
    ]

    this.retentionTimeTypes = [
      {
        label: 'Hour',
        value: 'hour'
      },
      {
        label: 'Day',
        value: 'day'
      },
      {
        label: 'Week',
        value: 'week'
      },
      {
        label: 'Month',
        value: 'month'
      }
    ]

    this.configuration = {
      modelsData: []
    }

    this.handleReviewerChange = this.handleReviewerChange.bind(this)
    this.uploadInstructionDocuments = this.uploadInstructionDocuments.bind(this)
    this.deleteInstructionsFile = this.deleteInstructionsFile.bind(this)
    this.renderCancelSaveContainer = this.renderCancelSaveContainer.bind(this)
    this.getActiveTab = this.getActiveTab.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
    this.accordionSectionOnClickHandler = this.accordionSectionOnClickHandler.bind(this)
    this.scrollHandler = this.scrollHandler.bind(this)
    this.copySection = this.copySection.bind(this)
    this.pasteSection = this.pasteSection.bind(this)
    this.resetUpdatedFlowResults = this.resetUpdatedFlowResults.bind(this)
    this.setFlowResult = this.setFlowResult.bind(this)
    this.setModels = this.setModels.bind(this)
    this.setFlowResultWithModels = this.setFlowResultWithModels.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.flowID !== this.props.flowID) {
      this.setModels()
    }
    if (!prevProps.user?.email && !this.props.isEdit && !!prevProps.fetching && !this.props.fetching) {
      if (this.props.user?.email) {
        this.setState({
          isLoaded: true,
          messageObject: LoadingModal.getEmptyMessageObject()
        })
      } else {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Login required',
            description: 'Please login to ' + (this.props.isEdit ? 'update the Flow.' : 'create a Flow'),
            status: 'error',
            code: null
          }
        })
      }
    }
  }

  setModels (callback) {
    this.handleCustomModels(() => {
      this.props.getModels && this.props.getModels((error, response) => {
        if (!error) {
          this.configuration.modelsData = handleModels(response, null, this.state.customModelOptions)
          this.setState({ models: response })
        }
        callback && callback()
      })
    })
  }

  setFlowResult () {
    const flows = this.context.flows
    const flow = flows?.find(it => it.flowID === this.props.flowID)

    if (flow?.flowID) {
      this.setModelSettings(flow)
      return this.setState({
        name: flow.name,
        flowData: flow,
        flowID: flow.flowID,
        hitlMode: flow?.hitl?.status === 'enabled' ? 'hitl-active' : flow?.hitl?.storeFiles === false ? 'do-not-store' : 'hitl-inactive',
        allowedUploaders: flow.allowedUploaders,
        administrators: flow.administrators || [],
        hitlEmails: flow.hitl?.reviewers || [],
        uploadNotifierStatus: !!flow?.destination?.isEmailNotifierEnabled,
        taxonomies: flow.taxonomies?.length
          ? flow.taxonomies
          : [{
              name: '',
              type: 'text',
              alternateSpelling: []
            }],
        questions: flow.questions?.length
          ? flow.questions
          : [{
              key: '',
              question: ''
            }],
        settings: flow?.settings || this.createDefaultSettings(),
        replyMail: flow?.settings?.integrations?.scanFromEmail?.replyMail !== undefined ? !!flow.settings.integrations.scanFromEmail.replyMail : true,
        resultManipulation: {
          disabledFields: flow?.resultManipulation?.disabledFields || [],
          mustHaveFields: flow?.resultManipulation?.mustHaveFields || [],
          disableAllExceptMustHaveFields: !!flow?.resultManipulation?.disableAllExceptMustHaveFields
        },
        additionalListColumns: this.getAdditionalListColumns(flow),
        customModels: this.getCustomModelLabelValue(flow?.customModels || []),
        isLoaded: true,
        messageObject: LoadingModal.getEmptyMessageObject()
      })
    }
    if (this.props.isEdit) {
      this.fetchFlow()
    } else {
      if (this.props.user?.email) {
        this.setState({
          isLoaded: true,
          messageObject: LoadingModal.getEmptyMessageObject()
        })
      }
    }
  }

  setFlowResultWithModels () {
    this.setModels(this.setFlowResult)
  }

  componentDidMount () {
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('scroll', this.scrollHandler)
    if (this.props.isEdit && !this.props.flowID) {
      this.context.redirect('/flow')
    }
    this.setFlowResultWithModels()
  }

  isFlowRequestedByAdmin (flow) {
    return flow?.role?.includes('admin') || flow?.role?.includes('administrator')
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('scroll', this.scrollHandler)
  }

  resizeHandler () {
    this.setState({
      isMobile: window.innerWidth < 767
    })
  }

  scrollHandler () {
    this.setState({
      isBottomSaveButtonEnabled: window?.scrollY > 150
    })
  }

  handleReviewerChange = event => {
    this.setState({
      hitlMode: event.target.value,
      isSubmitted: false
    })
  }

  setInput (name, value) {
    const settings = this.state.settings

    settings[name] = value
    this.setState({
      settings,
      isSubmitted: Object.keys(settings).reduce((acc, key) => acc && settings[key] === this.state.defaultSettings[key], true)
    })
  }

  onCheckBoxChange (data) {
    this.setInput(data.value, data.checked)
  }

  createBasicFlow = callback => {
    const flowData = this.prepareFlowData()
    this.setState({
      flowData
    }, () => {
      window.scrollTo(0, 0)
      this.makeRequest(callback)
    })
  }

  copySection = (sectionMap, callback) => {
    const state = this.state
    const copiedSection = {}
    for (const [key, value] of Object.entries(sectionMap)) {
      const nestedValue = getNestedObject(state, value)
      copiedSection[key] = nestedValue ?? {}
    }
    navigator.clipboard.writeText(JSON.stringify(copiedSection))
    callback && callback()
  }

  pasteSection = (ref, callback) => {
    const state = this.state
    navigator.clipboard.readText().then(clipText => {
      try {
        const clipboardData = JSON.parse(clipText)
        for (const [key, value] of Object.entries(clipboardData)) {
          setNestedObject(state, key, value)
        }
        this.setState({
          ...state,
          isSubmitted: false
        }, () => {
          if (ref?.current) {
            ref.current.updateFromOutside()
          }
          callback && callback()
        })
      } catch (e) {
        console.error(e)
      }
    })
  }

  deleteFlow = () => {
    this.context.displayModal({
      title: 'Success',
      content: (
        <div>
          <p>Are you sure you want to delete this flow?</p>
        </div>
      ),
      options: [{
        content: 'Cancel',
        intent: 'positive'
      }, {
        content: 'Yes',
        intent: 'negative',
        action: () => {
          this.makeRequestForDelete({ status: 'deleted' })
        }
      }],
      isClosable: true,
      isWide: true
    })
  }

  prepareFlowData () {
    let {
      taxonomies, questions
    } = this.state

    const {
      name, allowedUploaders, administrators, hitlMode, hitlEmails, resultManipulation, additionalListColumns, uploadNotifierStatus, settings, customModels
    } = this.state

    const flowData = {
      ...this.state.flowData,
      status: 'enabled',
      settings
    }
    if (name) {
      flowData.name = name
    }
    if (hitlMode === 'hitl-active') {
      flowData.hitl = {
        ...(flowData.hitl || {}),
        status: 'enabled',
        reviewers: hitlEmails,
        storeFiles: true
      }
    } else {
      flowData.hitl = {
        ...(flowData.hitl || {}),
        status: 'disabled',
        storeFiles: hitlMode !== 'do-not-store',
        notifyReviewers: false,
        reviewers: []
      }
    }
    if (allowedUploaders?.length) {
      flowData.allowedUploaders = allowedUploaders || []
    } else {
      flowData.allowedUploaders = []
    }
    if (administrators?.length) {
      flowData.administrators = administrators || []
    } else {
      flowData.administrators = []
    }
    if (taxonomies
      && !(taxonomies.length === 1 && taxonomies[0].name === '' && taxonomies[0].alternateSpelling?.length === 0)) {
      taxonomies = taxonomies.filter(tt => !(tt.name === '' || tt.alternateSpelling?.length === 0))
      flowData.taxonomies = taxonomies
    } else {
      flowData.taxonomies = []
    }
    if (questions
      && !(questions.length === 1 && questions[0].key === '' && questions[0].question === '')) {
      questions = questions.filter(qq => !(qq.key === '' || qq.question === ''))
      flowData.questions = questions
    } else {
      flowData.questions = []
    }
    flowData.resultManipulation = {
      mustHaveFields: resultManipulation.mustHaveFields || [],
      disabledFields: resultManipulation.disabledFields || [],
      disableAllExceptMustHaveFields: !!resultManipulation.disableAllExceptMustHaveFields
    }
    if (additionalListColumns?.length) {
      const alc = this.serializeAdditionalListColumns(additionalListColumns)
        .filter(f => f.displayName !== '' && f.fieldName !== '')
        .filter(f => f.fieldName && !f.fieldName?.match(' '))
      flowData.additionalListColumns = alc
    }
    flowData.postProcessing = flowData.postProcessing || {}
    flowData.destination = flowData.destination || {}
    flowData.destination.isEmailNotifierEnabled = !!uploadNotifierStatus
    flowData.customModels = customModels || []
    if (flowData.settings?.integrations?.scanFromEmail?.id) {
      flowData.settings.integrations.scanFromEmail.replyMail = !!this.state.replyMail
    }
    if (flowData.settings?.allowedModelTypes?.length) {
      for (const allowedModelType of flowData.settings.allowedModelTypes) {
        if (!flowData.customModels.find(cm => cm.value === allowedModelType.value)
          && allowedModelType.value.startsWith('custom/user/')) {
          flowData.customModels.push(allowedModelType)
        }
      }
    }
    if (flowData.settings?.modelTypes?.length) {
      for (const modelType of flowData.settings.modelTypes) {
        if (!flowData.customModels.find(cm => cm.value === modelType.value)
          && modelType.value.startsWith('custom/user/')) {
          flowData.customModels.push(modelType)
        }
      }
    }
    return flowData
  }

  handleCustomModels (callback) {
    if (!this.props.isEdit) {
      return callback && callback()
    }
    request({
      endpoint: '/custom-model',
      method: 'GET'
    }, (error, response) => {
      if (error) {
        console.log(error)
        return callback()
      }
      const customModelOptions = response.map(cm => {
        return {
          label: cm.name,
          value: cm.type
        }
      })
      this.setState({ customModelOptions }, callback)
    })
  }

  deserializeAdditionalListColumns (alc) {
    const additionalColumns = []
    for (const column of (alc || [])) {
      const isInList = additionalListColumnsFields.find(c => c.value === column.fieldName)
      if (isInList) {
        additionalColumns.push({
          fieldName: isInList,
          displayName: column.displayName
        })
      } else {
        additionalColumns.push({
          fieldName: {
            label: column.fieldName,
            value: column.fieldName
          },
          displayName: column.displayName
        })
      }
    }
    if (!additionalColumns?.length) {
      return [{
        fieldName: {
          label: '',
          value: ''
        },
        displayName: ''
      }]
    }
    return additionalColumns
  }

  serializeAdditionalListColumns (alc) {
    const additionalColumns = []
    for (const column of (alc || [])) {
      const isCamelCase = !column.fieldName.value?.trim()?.match(/\s/g)
      additionalColumns.push({
        fieldName: isCamelCase ? column.fieldName.value.trim() : toCamelCase(column.fieldName.value.trim()),
        displayName: column.displayName
      })
    }
    return additionalColumns
  }

  getAdditionalListColumns (flow) {
    return flow?.additionalListColumns === undefined
      ? [
          {
            fieldName: {
              label: 'Model name',
              value: '*modelName'
            },
            displayName: 'Name'
          },
          {
            fieldName: {
              label: 'Updated at',
              value: '*updatedAt'
            },
            displayName: 'Updated at'
          }
        ]
      : this.deserializeAdditionalListColumns(flow.additionalListColumns)
  }

  getCustomModelLabelValue (customModels) {
    const results = []
    if (Array.isArray(customModels)) { // old custom model structure - ['modelUUID_1', 'modelUUID_2']
      if (!this.state.customModelOptions?.length || !customModels?.length) {
        return results
      }
      for (const customModel of customModels) {
        const result = this.state.customModelOptions.find(cm => cm.value === customModel)
        if (result) {
          results.push(result)
        }
      }
    } else { // new custom model structure - { 'modelUUID_1': 'modelName_1', 'modelUUID_2': 'modelName_2' }
      for (const key of Object.keys(customModels || {})) {
        const result = {
          label: customModels[key],
          value: key
        }
        results.push(result)
      }
    }
    return results
  }

  filterCustomModels (modelArray) {
    return modelArray.filter(s => {
      if (!s.value.startsWith('custom/user')) {
        return true
      }
      return !!this.state.customModelOptions.find(cm => cm.value === s.value)
    })
  }

  setModelSettings = flow => {
    if (flow?.settings?.modelTypes?.length) {
      flow.settings.modelTypes = this.filterCustomModels(flow.settings.modelTypes)
    }
    if (flow?.settings?.allowedModelTypes?.length) {
      flow.settings.allowedModelTypes = this.filterCustomModels(flow.settings.allowedModelTypes)
    }
  }

  fetchFlow = () => {
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Loading...',
        description: 'Fetching the Flow.',
        status: 'loading',
        code: null
      }
    })
    const endpoint = `/flow${'/?flowID=' + this.props.flowID}`
    request({
      endpoint,
      method: 'GET'
    }, (error, response) => {
      if (response.message) {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while fetching the Flow',
            description: response.message,
            status: 'error',
            code: response.code
          }
        })
        return
      }
      if (error) {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while fetching the Flow',
            description: error,
            status: 'error',
            code: null
          }
        })
        return
      }
      let isFound = false
      response.forEach(flow => {
        if (flow.flowID === this.props.flowID) {
          isFound = true
          if (!flow?.isOwner && !this.isFlowRequestedByAdmin(flow)) {
            this.context.redirect('/flow')
            return
          }
          this.setModelSettings(flow)
          this.setState({
            name: flow.name,
            flowData: flow,
            flowID: flow.flowID,
            hitlMode: flow?.hitl?.status === 'enabled' ? 'hitl-active' : flow?.hitl?.storeFiles === false ? 'do-not-store' : 'hitl-inactive',
            allowedUploaders: flow.allowedUploaders,
            administrators: flow.administrators || [],
            hitlEmails: flow.hitl?.reviewers || [],
            uploadNotifierStatus: !!flow?.destination?.isEmailNotifierEnabled,
            taxonomies: flow.taxonomies?.length
              ? flow.taxonomies
              : [{
                  name: '',
                  type: 'text',
                  alternateSpelling: []
                }],
            questions: flow.questions?.length
              ? flow.questions
              : [{
                  key: '',
                  question: ''
                }],
            settings: flow?.settings || this.createDefaultSettings(),
            replyMail: flow?.settings?.integrations?.scanFromEmail?.replyMail !== undefined ? !!flow.settings.integrations.scanFromEmail.replyMail : true,
            resultManipulation: {
              disabledFields: flow?.resultManipulation?.disabledFields || [],
              mustHaveFields: flow?.resultManipulation?.mustHaveFields || [],
              disableAllExceptMustHaveFields: !!flow?.resultManipulation?.disableAllExceptMustHaveFields
            },
            additionalListColumns: this.getAdditionalListColumns(flow),
            customModels: this.getCustomModelLabelValue(flow?.customModels || [])
          }, () => {
            this.setState({
              isLoaded: true,
              messageObject: LoadingModal.getEmptyMessageObject()
            })
          })
        }
      })
      if (!isFound) {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while fetching the Flow',
            description: 'Requested Flow does not exist.',
            status: 'error',
            code: null
          }
        })
        this.context.displayModal({
          title: 'Flow not found',
          content: (
            <div>
              <p>Requested flow does not exist</p>
            </div>
          ),
          options: [
            {
              content: 'OK',
              action: () => {
                this.context.redirect('/flow')
              }
            }],
          isClosable: true,
          isWide: true
        })
      }
    })
  }

  integrationManagerCallBack = () => {
    this.fetchFlow()
  }

  emailNotifierCallBack = notifyWithEmail => {
    this.setState({
      isSubmitted: false,
      uploadNotifierStatus: !!notifyWithEmail
    })
  }

  onScanFromEmailNotifierChange = isEnabled => {
    this.setState({
      isSubmitted: false,
      replyMail: !!isEnabled
    })
  }

  uploadInstructionDocuments (accepted) {
    if (!accepted?.length || !this.props.flowID) {
      return
    }
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Loading...',
        description: 'Uploading the instruction file.',
        status: 'loading',
        code: null
      }
    })
    getBase64({ source: accepted[0] }, (err, base64Document) => {
      if (err) {
        return console.error(err)
      }
      request({
        endpoint: '/flow/instructions',
        method: 'POST',
        body: {
          document: base64Document,
          flowID: this.props.flowID,
          fileName: accepted[0].name
        }
      }, (err, response) => {
        if (err) {
          this.setState({
            isLoaded: true,
            messageObject: LoadingModal.getEmptyMessageObject()
          })
          this.context.displayModal({
            title: 'Upload failed',
            content: 'Instructions file upload failed.',
            options: [{ content: 'OK' }],
            isClosable: true
          })
          return console.error(err)
        }
        const flowData = this.state.flowData
        flowData.instructions = response.instructions
        this.setState({
          flowData,
          isLoaded: true,
          messageObject: LoadingModal.getEmptyMessageObject()
        })
        this.context.displayModal({
          title: 'Success',
          content: 'Instructions file uploaded successfully.',
          options: [{ content: 'OK' }],
          isClosable: true
        })
      }, err => {
        console.error(err)
      })
    })
  }

  deleteInstructionsFile = () => {
    const flowData = this.state.flowData
    if (!flowData) {
      return
    }
    this.context.displayModal({
      title: 'Remove instructions file',
      content: (
        <div>
          <p>Are you sure to delete the Flow Instructions document?</p>
        </div>
      ),
      options: [{
        content: 'Cancel',
        intent: 'negative'
      }, {
        content: 'Yes',
        intent: 'positive',
        action: () => {
          this.setState({
            isLoaded: false,
            messageObject: {
              title: 'Loading...',
              description: 'Deleting instruction file.',
              status: 'loading',
              code: null
            }
          })
          request({
            endpoint: '/flow/instructions/delete',
            method: 'POST',
            body: {
              flowID: this.props.flowID,
              fileName: flowData.instructions?.fileName || '',
              uploadID: flowData.instructions?.uploadID || ''
            }
          }, err => {
            if (err) {
              this.setState({
                isLoaded: true,
                messageObject: LoadingModal.getEmptyMessageObject()
              })
              this.context.displayModal({
                title: 'Removal is failed',
                content: 'Instructions file\'s delete operation failed.',
                options: [{ content: 'OK' }],
                isClosable: true
              })
              return console.error(err)
            }
            flowData.instructions = {}
            this.setState({
              flowData,
              isLoaded: true,
              messageObject: LoadingModal.getEmptyMessageObject()
            })
            this.context.displayModal({
              title: 'Success',
              content: 'Instructions file deleted successfully.',
              options: [{ content: 'OK' }],
              isClosable: true
            })
          }, err => {
            console.error(err)
          })
        }
      }],
      isClosable: true,
      isWide: true
    })
  }

  accordionSectionOnClickHandler = key => {
    // FIXME: Scroll to top
    if (!this.props.isEdit) {
      return this.context.redirect('/flow/new')
    }
    if (this.props.activeTab === key) {
      return this.context.redirect(`/flow/${this.props?.flowID}/edit`)
    }
    this.context.redirect(`/flow/${this.props?.flowID}/edit/${key}`)
  }

  makeRequestForDelete = updateInfo => {
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Loading...',
        description: 'Deleting the Flow.',
        status: 'loading',
        code: null
      }
    })

    let endpoint = '/flow'
    if (this.state.flowData?.flowID) {
      endpoint = endpoint + '/' + this.state.flowData.flowID
    }
    request({
      endpoint,
      method: 'POST',
      body: updateInfo
    }, (error, response) => {
      if (response.message) {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while ' + (this.props.isEdit ? 'updating the Flow' : 'creating a Flow'),
            description: response.message,
            status: 'error',
            code: response.code
          }
        })
        return
      }
      if (error) {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while ' + (this.props.isEdit ? 'updating the Flow' : 'creating a Flow'),
            description: error,
            status: 'error',
            code: null
          }
        })
        return
      }
      this.setState({
        isLoaded: true,
        messageObject: LoadingModal.getEmptyMessageObject()
      }, () => {
        const message = 'Your Flow is successfully deleted.'
        this.context.displayModal({
          title: 'Success',
          content: (
            <div>
              <p>{message}</p>
            </div>
          ),
          isClosable: false,
          options: [
            {
              content: 'OK',
              action: () => {
                this.props.deleteFlow(this.state.flowData.flowID)
                this.context.redirect('/flow')
              }
            }]
        })
      })
    }, err => {
      this.setState({
        isLoaded: false,
        messageObject: {
          title: 'Error',
          description: 'Error happened while processing: ' + (err.message || err) + '.',
          status: 'error',
          code: err.code
        }
      })
    })
  }

  createDefaultSettings () {
    return {
      hideDom: false,
      convertBlocksToFields: false,
      convertTextToSpeech: false,
      detectBarcodes: true,
      detectQrCodes: false,
      detectBlur: true,
      detectCheckboxes: true,
      detectFaces: true,
      detectGlares: true,
      detectIBAN: false,
      generatePDF: false,
      detectSignatures: true,
      detectWatermarks: false,
      eagerProcessID: false,
      extractExifData: false,
      flattenTable: false,
      geocodeAddresses: false,
      mergeSemanticResults: true,
      mergeTables: false,
      keepOriginalTables: false,
      modelTypes: [],
      processExcelOnly: false,
      allowedModelTypes: [],
      readDigitalSignatures: true,
      recognizeEntities: false,
      renderLineItemsAsObjects: false,
      runDocumentClassificationOnly: false,
      semanticProcessing: true,
      useSegmentation: false,
      verifyDocumentShape: true
    }
  }

  makeRequest = callback => {
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Loading...',
        description: this.state.flowData?.flowID ? 'Updating the Flow.' : 'Creating a Flow.',
        status: 'loading',
        code: null
      }
    })
    let endpoint = '/flow'
    if (this.state.flowData?.flowID) {
      endpoint = endpoint + '/' + this.state.flowData.flowID
    }
    request({
      endpoint,
      method: 'POST',
      body: this.state.flowData
    }, (error, response) => {
      if (response.message) {
        return this.setState({
          isLoaded: true,
          messageObject: LoadingModal.getEmptyMessageObject()
        }, () => {
          this.context.displayModal({
            title: `Error while ${this.props.flowID ? 'updating the' : 'creating a'} Flow`,
            content: (
              <div>
                <p>{LoadingModal.getMessageDescriptionWithCode(response.message, response.code)}</p>
              </div>
            ),
            options: [
              { content: 'OK' }
            ],
            isWide: true
          })
          callback && callback(error, null)
        })
      }
      if (error) {
        return this.setState({
          isLoaded: true,
          messageObject: LoadingModal.getEmptyMessageObject()
        }, () => {
          this.context.displayModal({
            title: `Error while ${this.props.flowID ? 'updating the' : 'creating a'} Flow`,
            content: (
              <div>
                <p>{LoadingModal.getMessageDescriptionWithCode(error, null)}</p>
              </div>
            ),
            options: [
              { content: 'OK' }
            ]
          })
          callback && callback(error, null)
        })
      }
      this.props.addFlow(response)
      this.setState({
        isLoaded: true,
        messageObject: LoadingModal.getEmptyMessageObject(),
        flowID: response.flowID,
        flowData: {
          ...response
        },
        taxonomies: response?.taxonomies?.length
          ? response.taxonomies
          : [{
              name: '',
              type: 'text',
              alternateSpelling: [],
              deleteMatchedTaxonomy: false
            }],
        questions: response?.questions?.length
          ? response.questions
          : [{
              key: '',
              question: ''
            }],
        hitlMode: this.state.flowData?.hitl?.status === 'enabled' ? 'hitl-active' : this.state.flowData?.hitl?.storeFiles === false ? 'do-not-store' : 'hitl-inactive',
        settings: response?.settings || this.createDefaultSettings(),
        resultManipulation: {
          disabledFields: response?.resultManipulation?.disabledFields || [],
          mustHaveFields: response?.resultManipulation?.mustHaveFields || [],
          disableAllExceptMustHaveFields: !!response?.resultManipulation?.disableAllExceptMustHaveFields
        },
        additionalListColumns: this.getAdditionalListColumns(response),
        uploadNotifierStatus: !!response?.destination?.isEmailNotifierEnabled,
        replyMail: response?.settings?.integrations?.scanFromEmail?.replyMail !== undefined ? !!response.settings.integrations.scanFromEmail.replyMail : true,
        isSubmitted: true
      }, () => {
        const message = (this.props.isEdit || this.state.flowData?.flowID) ? 'Your flow is successfully updated.' : 'Your flow is successfully created.'
        this.context.displayModal({
          title: 'Success',
          content: (
            <div>
              <p>{message}</p>
            </div>
          ),
          options: [
            {
              content: 'Edit flow',
              action: () => {
                this.context.redirect(`/flow/${this.state.flowData.flowID}/edit/${this.getActiveTab()}`)
              },
              intent: 'warning'
            },
            {
              content: 'Go to flow',
              action: () => {
                this.context.redirect(`/flow/${this.state.flowData.flowID}/result`)
              }
            }
          ]
        })
        callback && callback(null, response)
      })
    }, err => {
      this.setState({
        isLoaded: false,
        messageObject: {
          title: 'Error while ' + this.props.isEdit ? 'updating the Flow' : 'creating a Flow',
          description: 'Error happened while processing: ' + (err.message || err) + '.',
          status: 'error',
          code: err.code
        }
      })
      callback && callback(err, null)
    })
  }

  setFlowStatus = status => {
    window.scrollTo(0, 0)
    this.setState({
      flowData: {
        ...this.state.flowData,
        status
      }
    }, () => {
      this.makeRequest()
    })
  }

  downloadFlowAsJson = () => {
    const flowData = this.prepareFlowData()
    const fileName = `${this.state.name || 'Flow'}.json`
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(flowData, null, 2))

    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', fileName)
    downloadAnchorNode.click()
  }

  renderCancelSaveContainer () {
    return (
      <div className='cancel-save-container'>
        <Section name='cancel-save'>
          <div className={'button-set' + (this.state.isBottomSaveButtonEnabled ? ' is-bottom' : ' is-top')}>
            <AppButton
              className='secondary-cta min-w-36'
              href={`/flow${this.props.isEdit ? '/results/' + this.props.flowID : ''}`}
            >
              Cancel
            </AppButton>
            <AppDropdown
              className='primary-cta'
              leftButtonClassName='min-w-32'
              options={[{ name: 'Export settings', onClick: this.downloadFlowAsJson }]}
              disabled={this.state.name?.length < 3 || this.state.isSubmitted}
              isDropdownDisabled={!this.props.isEdit}
              onClick={() => {
                this.createBasicFlow(this.resetUpdatedFlowResults)
              }}
            >
              {this.props.isEdit ? 'Update' : 'Save'}
            </AppDropdown>
          </div>
        </Section>
      </div>
    )
  }

  resetUpdatedFlowResults () {
    const { flows } = this.props
    const updatedFlows = flows.map(flow => {
      if (flow?.flowID === this.state?.flowData?.flowID) {
        return {
          ...flow,
          results: null,
          insight: null
        }
      }
      return flow
    })
    this.props.setFlows(updatedFlows)
  }

  getActiveTab () {
    let activeTab = this.props.activeTab
    if (!this.props.isEdit
      || (
        (!activeTab || !this.tabList.includes(activeTab))
        && !this.state.isMobile
      )
    ) {
      activeTab = 'basicInformation'
    }
    return activeTab
  }

  renderCopyPasteSection (copiedSections) {
    return (
      <div className='copy-paste-icons'>
        <MaterialIcon
          title='Copy settings of the current section to clipboard'
          name='content_copy'
          onClick={() => {
            this.copySection(copiedSections, () => {
              this.context.displayModal({
                title: 'Copied',
                content: 'Copied to clipboard.',
                options: [{ content: 'OK' }],
                isClosable: true
              })
            })
          }}
        />
        <MaterialIcon
          title='Paste settings from clipboard to the current section'
          name='content_paste'
          onClick={() => {
            this.pasteSection(null, () => {
              this.context.displayModal({
                title: 'Pasted',
                content: 'Pasted from clipboard.',
                options: [{ content: 'OK' }],
                isClosable: true
              })
            })
          }}
        />
      </div>
    )
  }

  render () {
    const {
      user
    } = this.props
    const wasUserLoggedIn = this.state.user?.email || user?.email
    const activeTab = this.getActiveTab()
    const {
      flowID,
      isLoaded,
      messageObject,
      flowData,
      hitlMode,
      settings,
      customModels,
      customModelOptions
    } = this.state
    let loadingErrorButton = {
      to: '/flow',
      text: 'Back to Flows'
    }
    if (!isLoaded && !wasUserLoggedIn?.length) {
      loadingErrorButton = {
        to: '/login?next=/flow',
        text: 'Login to access Flows'
      }
    }

    const {
      modelsData
    } = this.configuration

    const titleText = this.props?.flowID ? 'Edit Flow' + (this.state.flowData?.name ? ` "${this.state.flowData.name}"` : '') + (this.state.flowData?.status === 'disabled' ? ' (Disabled)' : '') : 'Create a New Flow'
    const titleDescription = this.props?.flowID ? 'You can edit all the details for your existing Flow' : 'You can edit all the details for your new Flow'
    let keyIndex = 0
    return (
      <View name='flow-settings'>
        <NavigationPrompt messageFunction={(location, action) => {
          const isLeaving = !location.pathname.startsWith(`/flow/${flowID}/edit`) || action === 'LEAVE'
          if (isLeaving && !this.state.isSubmitted) {
            return Messages.CHANGES_NOT_SAVED
          }

          return true
        }}
        />
        {isLoaded === false
          ? (
              <Section name='block'>
                <LoadingModal
                  data={{
                    ...messageObject,
                    button: loadingErrorButton
                  }}
                />
              </Section>
            )
          : (
              <>
                <Header name='header'>
                  <div className='header-container settings'>
                    <div>
                      <BackButton
                        onClick={() => {
                          const nextHref = this.state.flowID ? `/flow/results/${this.state.flowID}/result` : '/flow'
                          this.context.redirect(nextHref)
                        }}
                        placeholder='Back to results'
                      />
                      <div className='title'>
                        <h1 className='slogan' title={titleDescription}>{titleText}</h1>
                        <h2
                          className='introduction'
                        >
                          {this.props?.flowID ? 'Customize your existing flow.' : 'Create your custom flow easily with a few steps.'}
                        </h2>
                      </div>
                    </div>
                    {!this.state.isMobile && this.renderCancelSaveContainer()}
                  </div>
                </Header>
                <Section className='settings-container'>
                  <div className='flow-settings-grid-container'>
                    <div className='left-navigation'>
                      <ul>
                        {Object.keys(tabs)
                          .map((key, index) => (
                            <li
                              title={tabs[key].description}
                              className={(key !== 'basicInformation' && !this.props.isEdit ? 'disable ' : '') + (activeTab === key ? 'active' : '')}
                              key={index}
                              onClick={() => {
                                if (this.props.isEdit) {
                                  this.context.redirect(`/flow/${this.props?.flowID}/edit/${key}`)
                                } else {
                                  this.context.redirect('/flow/new')
                                }
                              }}
                            >
                              <Link
                                to={this.props.isEdit ? `/flow/${this.props?.flowID}/edit/${key}` : '/flow/new'}
                                className={this.props.isEdit ? (tabs[key].class || '') : ((key !== 'basicInformation') ? 'disable' : '')}
                              >
                                {tabs[key].title}
                              </Link>
                              <Link
                                to={this.props.isEdit ? `/flow/${this.props?.flowID}/edit/${key}` : '/flow/new'}
                                className={this.props.isEdit ? ('arrow ' + (tabs[key].class || '')) : ((key !== 'basicInformation') ? 'disable' : '')}
                              >
                                <MaterialIcon name='chevron_right' />
                              </Link>
                            </li>
                          )
                          )}
                      </ul>
                    </div>
                    <Section
                      name='basic'
                      className='settings-card'
                      if={this.state.isMobile || activeTab === 'basicInformation'}
                      accordion={this.state.isMobile}
                      isOpen={activeTab === 'basicInformation'}
                      title='Basic Information'
                      onClick={() => {
                        this.accordionSectionOnClickHandler('basicInformation')
                      }}
                    >
                      {!this.state.isMobile && (
                        <span className='title-container'>
                          <h1 className='title'>Basic Information</h1>
                          <HelpButton href='https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2068067,2644284' />
                        </span>
                      )}
                      <h2 className='title'>Flow name</h2>
                      <input
                        className='basic fullscreen-input'
                        type='text'
                        value={this.state.name || this.state.flowData?.name || ''}
                        name='flow-name'
                        placeholder='Your New Base64.ai Flow'
                        onChange={e => {
                          this.setState({
                            name: e.target.value,
                            isSubmitted: false
                          })
                        }}
                      />
                      {this.props.flowID && (
                        <>
                          <h2 className='title'>Flow ID</h2>
                          <div className='flow-id-container'>
                            <span className='basic fullscreen-input'>{flowID}</span>
                            <CopyToClipboard
                              text={flowID}
                              onCopy={() => {
                                window.alert('Copied to your clipboard!')
                              }}
                            >
                              <MaterialIcon
                                name='content_copy'
                                style={{
                                  fontVariationSettings: '\'FILL\' 0,\'wght\' 200,\'GRAD\' 0,\'opsz\' 48'
                                }}
                              >
                              </MaterialIcon>
                            </CopyToClipboard>
                          </div>
                        </>
                      )}
                      <h2 className='title'>Administrators</h2>
                      <p className='header-paragraph'>
                        Administrators can edit the flow settings including deleting the flow. They can and upload and
                        review the results.
                        <br />
                        Flow owners have administrator permissions thus they can also upload and review the documents.
                      </p>
                      <div>
                        <MultiEmail
                          // width="720px"
                          placeholder='Enter administrators emails...'
                          emails={flowData?.administrators || []}
                          onChange={emails => {
                            this.setState({
                              administrators: emails,
                              isSubmitted: false
                            })
                          }}
                          style={{
                            marginLeft: '0rem',
                            marginBottom: '0rem'
                          }}
                        />
                      </div>

                      <h2 className='title'>Uploaders</h2>
                      <p className='header-paragraph'>
                        Uploaders are allowed to upload documents to this flow.
                        <br />
                        Enter a domain name (such as example.com) to add everyone within a company or specify individual
                        emails.
                      </p>
                      <div>
                        <MultiEmail
                          // width="720px"
                          placeholder='Enter uploader emails or domain names...'
                          emails={flowData?.allowedUploaders || []}
                          validateDomain={true}
                          onChange={emails => {
                            this.setState({
                              allowedUploaders: emails,
                              isSubmitted: false
                            })
                          }}
                          style={{
                            marginLeft: '0rem',
                            marginBottom: '0rem'
                          }}
                        />
                      </div>
                      <h2 className='title'>Reviewers</h2>
                      <p className='header-paragraph'>
                        Reviewers are allowed to see the uploaded documents and make changes to the AI results on the review
                        page.
                        <br />
                        Enter a domain name (such as example.com) to add everyone within a company or specify individual
                        emails.
                      </p>
                      <div className='reviewerContainer'>
                        <RadioButton
                          label='Allow the following users to see, review, and approve the results:'
                          value={hitlMode === 'hitl-active'}
                          className='hitl-radio-button'
                          onChange={() => {
                            this.setState({
                              hitlMode: 'hitl-active',
                              isSubmitted: false
                            })
                          }}
                        />
                        <MultiEmail
                          // width="720px"
                          placeholder='Enter reviewer emails or domain names...'
                          emails={flowData?.hitl?.reviewers || []}
                          onChange={emails => {
                            this.setState({
                              hitlEmails: emails,
                              isSubmitted: false
                            })
                          }}
                          validateDomain={true}
                          style={{
                            paddingLeft: '1.8rem',
                            margin: '0.3rem 0',
                            maxWidth: '690px'
                          }}
                        />
                        <RadioButton
                          label='Auto-approve the results without a human review'
                          value={hitlMode === 'hitl-inactive'}
                          className='hitl-radio-button'
                          onChange={() => {
                            this.setState({
                              hitlMode: 'hitl-inactive',
                              isSubmitted: false
                            })
                          }}
                        />
                        <RadioButton
                          label='Do not store my data, including the uploaded documents and AI results'
                          value={hitlMode === 'do-not-store'}
                          className='hitl-radio-button'
                          onChange={() => {
                            this.setState({
                              hitlMode: 'do-not-store',
                              isSubmitted: false
                            })
                          }}
                        />
                      </div>
                      <p className='header-paragraph reviewer-help'>
                        <a
                          href='https://help.base64.ai/kb/guide/en/flow-human-in-the-loop-document-processing-WH1QqHEiey/Steps/2068067,2119939'
                          target='_blank'
                          rel='noreferrer'
                          className='help-anchor'
                        >
                          Learn more
                        </a>
                        {' '}
                        about roles and their
                        permissions.
                      </p>

                      {hitlMode !== 'do-not-store' && !isWorkingOffline() && (
                        <div>
                          <h2 className='title'>Data retention policy</h2>
                          <p className='header-paragraph'>
                            Specify when files and their extracted data should be deleted, excluding those in needs review
                            status.
                            {' '}
                            <br />
                            Only files that are approved, auto-approved, or rejected will be subject to deletion.
                          </p>
                          <TimeIntervalPicker
                            title='Limit data retention period'
                            value={this.state.flowData?.dataRetention?.value || '1'}
                            type={this.state.flowData?.dataRetention?.type || 'week'}
                            min='1'
                            defaultType='week'
                            placeholder='Limit data retention period:'
                            checked={this.state.flowData?.dataRetention?.status === 'enabled'}
                            onChangeCheckbox={value => {
                              this.setState({
                                isSubmitted: false,
                                flowData: {
                                  ...this.state.flowData,
                                  dataRetention: {
                                    value: this.state.flowData?.dataRetention?.value || 1,
                                    type: this.state.flowData?.dataRetention?.type || 'week',
                                    status: value ? 'enabled' : 'disabled'
                                  }
                                }
                              })
                            }}
                            onChangeValue={value => {
                              this.setState({
                                isSubmitted: false,
                                flowData: {
                                  ...this.state.flowData,
                                  dataRetention: {
                                    type: this.state.flowData?.dataRetention?.type || 'week',
                                    status: this.state.flowData?.dataRetention?.status || 'enabled',
                                    value
                                  }
                                }
                              })
                            }}
                            onChangeType={value => {
                              this.setState({
                                isSubmitted: false,
                                flowData: {
                                  ...this.state.flowData,
                                  dataRetention: {
                                    value: this.state.flowData?.dataRetention?.value || 1,
                                    status: this.state.flowData?.dataRetention?.status || 'enabled',
                                    type: value
                                  }
                                }
                              })
                            }}
                          />
                          {!isWorkingOffline() && (
                            <React.Fragment>
                              <h2 className='title'>Document search engine</h2>
                              <p>
                                Search documents in this flow by their type and contents, e.g. “show me driver licenses that
                                aren’t expired”
                              </p>
                              <Checkbox
                                showTitle={false}
                                className='flow-search big left'
                                placeholder='Enable AI powered search engine.'
                                value='flowSearch'
                                onChange={e => {
                                  this.setState({
                                    isSubmitted: false,
                                    flowData: {
                                      ...this.state.flowData,
                                      flowSearch: {
                                        status: e.target.checked ? 'enabled' : 'disabled',
                                        indexes: this.state.flowData?.flowSearch?.indexes || []
                                      }
                                    }
                                  })
                                }}
                                checked={this.state.flowData?.flowSearch?.status === 'enabled'}
                              />
                            </React.Fragment>
                          )}
                          <FlowCreatableSelect
                            value={(this.state.flowData?.flowSearch?.indexes || []).filter(s => !s.preDefined)}
                            isDisabled={this.state.flowData?.flowSearch?.status !== 'enabled'}
                            placeholder='Add index fields...'
                            formatCreateLabel={value => {
                              const fields = createSelectFieldsFromString(value)
                              return `Create ${fields.map(f => `"${f.value}"`).join(', ')}`
                            }}
                            onChange={indexes => {
                              // index limit?
                              this.setState({
                                isSubmitted: false,
                                flowData: {
                                  ...this.state.flowData,
                                  flowSearch: {
                                    status: 'enabled',
                                    indexes: indexes.map(s => ({ label: s.label.trim(), value: toCamelCase(s.value.trim()) }))
                                  }
                                }
                              })
                            }}
                          />
                        </div>
                      )}
                    </Section>
                    {flowID && (
                      <Section
                        name='ai-features'
                        className='settings-card'
                        if={this.state.isMobile || activeTab === 'aiFeatures'}
                        accordion={this.state.isMobile}
                        isOpen={activeTab === 'aiFeatures'}
                        title='AI Features'
                        onClick={() => {
                          this.accordionSectionOnClickHandler('aiFeatures')
                        }}
                      >
                        {!this.state.isMobile && (
                          <span className='title-container'>
                            <h1 className='title'>AI Features</h1>
                            <div>
                              {this.renderCopyPasteSection({
                                'settings.geocodeAddresses': 'settings.geocodeAddresses', // start of left column
                                'settings.detectBarcodes': 'settings.detectBarcodes',
                                'settings.detectBlur': 'settings.detectBlur',
                                'settings.detectCheckboxes': 'settings.detectCheckboxes',
                                'settings.readDigitalSignatures': 'settings.readDigitalSignatures',
                                'settings.generatePDF': 'settings.generatePDF',
                                'settings.recognizeEntities': 'settings.recognizeEntities',
                                'settings.detectFaces': 'settings.detectFaces',
                                'settings.flattenTable': 'settings.flattenTable',
                                'settings.detectGlares': 'settings.detectGlares',
                                'settings.detectSignatures': 'settings.detectSignatures',
                                'settings.detectIBAN': 'settings.detectIBAN', // start of right column
                                'settings.detectQrCodes': 'settings.detectQrCodes',
                                'settings.renderLineItemsAsObjects': 'settings.renderLineItemsAsObjects',
                                'settings.useSegmentation': 'settings.useSegmentation',
                                'settings.semanticProcessing': 'settings.semanticProcessing',
                                'settings.mergeSemanticResults': 'settings.mergeSemanticResults',
                                'settings.verifyDocumentShape': 'settings.verifyDocumentShape',
                                'settings.convertTextToSpeech': 'settings.convertTextToSpeech',
                                'settings.detectWatermarks': 'settings.detectWatermarks',
                                'settings.extractExifData': 'settings.extractExifData'
                              })}
                              <HelpButton href='https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2068067,2119930' />
                            </div>
                          </span>
                        )}
                        {/* <h2 className='title'>Source</h2> */}
                        <p className='header-paragraph'>
                          Fine-tune the AI features according to your specific needs. Enhance
                          the AI results by selecting additional features and reduce processing time by unselecting
                          others.
                        </p>
                        <div className='check-box-container'>
                          <div className='column-left'>
                            {!isWorkingOffline()
                            && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Address geocoding'
                                value='geocodeAddresses'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Split addresses into street, city, state, zip code, and country names.'
                                checked={settings.geocodeAddresses}
                              />
                            )}
                            { hasFeature('barcodeDetection') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Barcode detection'
                                value='detectBarcodes'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Extract data from PDF417 barcodes.'
                                checked={settings?.detectBarcodes !== undefined ? !!settings.detectBarcodes : true}
                              />
                            )}
                            { hasFeature('imageQuality') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Blur detection'
                                value='detectBlur'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Receive a warning for excess blur (typically caused by a shaking hand).'
                                checked={settings?.detectBlur !== undefined ? !!settings.detectBlur : true}
                              />
                            ) }
                            { hasFeature('formReader') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Checkbox detection'
                                value='detectCheckboxes'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Detect checkboxes, learn if they were checked alongside the text associated with it.'
                                checked={settings?.detectCheckboxes !== undefined ? !!settings.detectCheckboxes : true}
                              />
                            ) }
                            { hasFeature('digitalSignature') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Digital signature recognition'
                                value='readDigitalSignatures'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Extract and validate cryptographic digital certificates and e-signatures.'
                                checked={settings?.readDigitalSignatures !== undefined ? !!settings.readDigitalSignatures : true}
                              />
                            ) }
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='Document generation'
                              value='generatePDF'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Generate digitized PDF using extraction results (ACORD 125 Only).'
                              checked={!!settings.generatePDF}
                            />
                            { hasFeature('entityRecognition') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Entity recognition (NLP)'
                                value='recognizeEntities'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Extract person names, locations, events, and products from your documents.'
                                checked={!!settings.recognizeEntities}
                              />
                            ) }
                            { hasFeature('objectDetection') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Facial recognition'
                                value='detectFaces'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Extract faces, receive the cropped image and its coordinates.'
                                checked={settings?.detectFaces !== undefined ? !!settings.detectFaces : true}
                              />
                            ) }
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='Flatten tables to fields'
                              value='flattenTable'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Flatten tables to fields.'
                              checked={settings.flattenTable}
                            />
                            { hasFeature('imageQuality') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Glare detection'
                                value='detectGlares'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Receive a warning for excess glare (usually seen in flash photography).'
                                checked={settings?.detectGlares !== undefined ? !!settings.detectGlares : true}
                              />
                            ) }
                            { hasFeature('objectDetection') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Handwritten signature recognition'
                                value='detectSignatures'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Extract handwritten signatures, receive the cropped image and its coordinates.'
                                checked={settings?.detectSignatures !== undefined ? !!settings.detectSignatures : true}
                              />
                            ) }
                          </div>
                          <div className='column-right'>
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='IBAN detection'
                              value='detectIBAN'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Extract IBANs from your documents.'
                              checked={!!settings.detectIBAN}
                            />
                            { hasFeature('barcodeDetection') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='QR code detection'
                                value='detectQrCodes'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Extract data from QR codes.'
                                checked={settings?.detectQrCodes !== undefined ? !!settings.detectQrCodes : false}
                              />
                            ) }
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='Render line items as objects'
                              value='renderLineItemsAsObjects'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Convert line items into array of objects (stored in result.features.lineItems).'
                              checked={settings.renderLineItemsAsObjects}
                            />
                            { hasFeature('documentSegmentation') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Segmentation'
                                value='useSegmentation'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Enable to process multiple documents on a single page.'
                                checked={!!settings.useSegmentation}
                              />
                            ) }
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='Semantic processing'
                              value='semanticProcessing'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Use semantic processing to extract data from the document types that our AI has not seen before.'
                              checked={settings?.semanticProcessing !== undefined ? !!settings.semanticProcessing : true}
                            />
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='Semantic result merging'
                              value='mergeSemanticResults'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Merge multi page semantic results into a single result.'
                              checked={settings.mergeSemanticResults}
                            />
                            { hasFeature('shapeClassification') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Shape verification'
                                value='verifyDocumentShape'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Classify documents based on their shape such as card, letter, check, etc.'
                                checked={settings?.verifyDocumentShape !== undefined ? !!settings.verifyDocumentShape : true}
                              />
                            ) }
                            {!isWorkingOffline()
                            && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Text-to-speech'
                                value='convertTextToSpeech'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Convert OCR text to MP3.'
                                checked={settings.convertTextToSpeech}
                              />
                            )}
                            { hasFeature('watermarkDetection') && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Watermark detection'
                                value='detectWatermarks'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Detect if the document has watermarks such as diagonal DRAFT, CONFIDENTIAL, etc. labels.'
                                checked={settings?.detectWatermarks !== undefined ? !!settings.detectWatermarks : false}
                              />
                            )}
                            {!isWorkingOffline()
                            && (
                              <Checkbox
                                showTitle={true}
                                key={++keyIndex}
                                className='big left'
                                placeholder='Extract EXIF metadata'
                                value='extractExifData'
                                onChange={e => {
                                  this.onCheckBoxChange(e.target)
                                }}
                                title='Capture image location, aperture, and other metadata from images.'
                                checked={settings.extractExifData}
                              />
                            )}
                          </div>
                        </div>
                      </Section>
                    )}
                    {flowID && (
                      <Section
                        name='enhancements'
                        className='settings-card'
                        if={this.state.isMobile || activeTab === 'enhancements'}
                        accordion={this.state.isMobile}
                        isOpen={activeTab === 'enhancements'}
                        title='Enhancements'
                        onClick={() => {
                          this.accordionSectionOnClickHandler('enhancements')
                        }}
                      >
                        <div>
                          {!this.state.isMobile && (
                            <span className='title-container'>
                              <h1 className='title'>Enhancements</h1>
                              <div>
                                {this.renderCopyPasteSection({
                                  customModels: 'customModels',
                                  taxonomies: 'taxonomies',
                                  questions: 'questions',
                                  resultManipulation: 'resultManipulation'
                                })}
                                <HelpButton href='https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2068067,2119930,2068067,2076956' />
                              </div>
                            </span>
                          )}
                          <p className='header-paragraph'>
                            Changes made here will be reflected in the API result.
                            {' '}
                            <br />
                            Our AI will make the enhancements in the same order of this page.
                          </p>
                          <h2 className='title'>Custom models</h2>
                          <p className='header-paragraph'>
                            Select the custom models for this flow. You can list your custom models
                            {' '}
                            <Link
                              to='/custom-models'
                            >
                              here
                            </Link>
                            .
                          </p>
                          <div>
                            <Select
                              isMulti
                              classNamePrefix='react-select'
                              className='react-select'
                              value={customModels || []}
                              styles={this.configuration.inputStyle}
                              options={customModelOptions.filter(x => !customModels.find(y => y.value === x.value)).sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1)}
                              closeMenuOnSelect={false}
                              placeholder='Select or enter model type...'
                              noOptionsMessage={() => 'No custom model found'}
                              filterOption={({
                                label,
                                value
                              }, query) => {
                                if (!query) {
                                  return true
                                }
                                const modelName = label.toLowerCase()
                                const modelType = value.toLowerCase()
                                query = query.toLowerCase()
                                for (const part of query.split(' ').filter(x => x.trim())) {
                                  if (!modelName.includes(part) && !modelType.includes(part)) {
                                    return false
                                  }
                                }
                                return true
                              }}
                              onChange={(changed, { action, removedValue, removedValues }) => {
                                if (action === 'clear') {
                                  const usedModels = (this.state.settings.allowedModelTypes || [])
                                    .concat(this.state.settings.modelTypes || [])
                                    .filter((model, index, self) =>
                                      index === self.findIndex(t => t.value === model.value)
                                    )
                                    .filter(model => removedValues.some(m => m.value === model.value))

                                  if (usedModels.length > 0) {
                                    this.context.displayModal({
                                      title: 'Warning: Models in use',
                                      content: (
                                        <div>
                                          <p>
                                            The following custom models are currently in use in flow restrictions:
                                          </p>
                                          <ul>
                                            {usedModels.map(model => (
                                              <li key={model.value}><strong>{model.label}</strong></li>
                                            ))}
                                          </ul>
                                          <p>
                                            Please remove them from <strong>&quot;Restrict document types&quot;</strong> and/or
                                            <strong>&quot;Force document type classification&quot;</strong> before clearing.
                                          </p>
                                        </div>
                                      ),
                                      options: [
                                        {
                                          content: 'Cancel',
                                          intent: 'secondary'
                                        },
                                        {
                                          content: 'Ok',
                                          intent: 'warning',
                                          action: () => {
                                            this.context.redirect(`/flow/${this.props?.flowID}/edit/restrictions#restrict-document-types`)
                                          }
                                        }
                                      ],
                                      isClosable: true,
                                      isWide: true
                                    })
                                    return
                                  }
                                }
                                if (action === 'remove-value' && removedValue) {
                                  const isInModelTypes = this.state.settings.modelTypes.some(
                                    model => model.value === removedValue.value
                                  )
                                  const isInAllowedModelTypes = this.state.settings.allowedModelTypes?.some(
                                    model => model.value === removedValue.value
                                  )
                                  if (isInModelTypes || isInAllowedModelTypes) {
                                    this.context.displayModal({
                                      title: 'Warning: Model in Use',
                                      content: (
                                        <div>
                                          <p>
                                            The custom model <strong>&quot;{removedValue.label}&quot;</strong> is currently in use.
                                          </p>
                                          <p>
                                            Remove it from{' '}
                                            {isInModelTypes && isInAllowedModelTypes && (
                                              <>
                                                <strong>&quot;Restrict document types&quot;</strong> and{' '}
                                                <strong>&quot;Force document type classification&quot;</strong>
                                              </>
                                            )}
                                            {isInModelTypes && !isInAllowedModelTypes && (
                                              <strong>&quot;Restrict document types&quot;</strong>
                                            )}
                                            {!isInModelTypes && isInAllowedModelTypes && (
                                              <strong>&quot;Force document type classification&quot;</strong>
                                            )}
                                            {' '}in flow restrictions.
                                          </p>
                                        </div>
                                      ),
                                      options: [
                                        {
                                          content: 'Cancel',
                                          intent: 'secondary'
                                        },
                                        {
                                          content: 'Ok',
                                          intent: 'warning',
                                          action: () => {
                                            this.context.redirect(`/flow/${this.props?.flowID}/edit/restrictions#restrict-document-types`)
                                          }
                                        }
                                      ],
                                      isClosable: true,
                                      isWide: true
                                    })
                                    return
                                  }
                                }
                                this.setState({
                                  isSubmitted: false,
                                  customModels: changed
                                })
                              }}
                            />
                          </div>
                          <Taxonomy
                            taxonomies={this.state.taxonomies}
                            onChange={data => {
                              const isDefault = isEqual(data, defaultTaxonomies)

                              this.setState({
                                taxonomies: data,
                                isSubmitted: isDefault ? this.state.isSubmitted : false
                              })
                            }}
                            headerTitle='Custom taxonomy'
                            headerParagraph={(
                              <p className='header-paragraph'>
                                Different documents may have alternative wording for the same data fields.
                                <br />
                                Add alternative spelling, wording, or synonyms to your taxonomy to fall back on if the preferred
                                field does not exist in the result.
                                <br />
                                <a
                                  target='_blank'
                                  href='https://help.base64.ai/kb/guide/en/flow-human-in-the-loop-document-processing-WH1QqHEiey/Steps/2068067,2076956'
                                  rel='noreferrer'
                                >
                                  Learn more
                                </a>
                                {' '}
                                about the taxonomy.
                              </p>
                            )}
                          />
                          {/* Questions */}
                          { hasFeature('questionAnswer') && (
                            <QuestionAnswer
                              flows={this.props.flows}
                              questions={this.state.questions}
                              onChange={data => {
                                const isDefault = isEqual(data, defaultQuestions)

                                this.setState({
                                  questions: data,
                                  isSubmitted: isDefault ? this.state.isSubmitted : false
                                })
                              }}
                              headerParagraph={(
                                <p className='header-paragraph'>
                                  Add questions you want our AI to answer, which will generate fields in the result.
                                  <br />
                                  <a
                                    target='_blank'
                                    href='https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2068067,2076956,2618478'
                                    rel='noreferrer'
                                  >
                                    Learn more
                                  </a>
                                  {' '}
                                  about the questions &amp; answers.
                                </p>
                              )}
                            />
                          )}
                        </div>
                        <ResultMustHaveDisabledFields
                          resultManipulation={this.state.resultManipulation}
                          onChange={data => {
                            this.setState({
                              resultManipulation: data,
                              isSubmitted: false
                            })
                          }}
                        />
                        <h2 className='title'>Post-processing</h2>
                        <p className='header-paragraph disabled-fields' style={{ marginTop: '1rem !important' }}>
                          Navigate to the
                          {' '}
                          <a onClick={() => {
                            this.accordionSectionOnClickHandler('integration')
                          }}
                          >
                            Integrations
                            Tab
                          </a>
                          {' '}
                          to augment the AI results with custom business logic and data from external systems.
                        </p>
                      </Section>
                    )}
                    {flowID && (
                      <Section
                        name='restrictions'
                        className='settings-card'
                        if={this.state.isMobile || activeTab === 'restrictions'}
                        accordion={this.state.isMobile}
                        isOpen={activeTab === 'restrictions'}
                        title='Restrictions'
                        onClick={() => {
                          this.accordionSectionOnClickHandler('restrictions')
                        }}
                      >
                        {!this.state.isMobile && (
                          <span className='title-container'>
                            <h1 className='title'>Restrictions</h1>
                            <div>
                              {this.renderCopyPasteSection({
                                'settings.hideDom': 'settings.hideDom',
                                'settings.convertBlocksToFields': 'settings.convertBlocksToFields',
                                'settings.eagerProcessID': 'settings.eagerProcessID',
                                'settings.processExcelOnly': 'settings.processExcelOnly',
                                'settings.mergeTables': 'settings.mergeTables',
                                'settings.keepOriginalTables': 'settings.keepOriginalTables',
                                'settings.allowedModelTypes': 'settings.allowedModelTypes',
                                'settings.modelTypes': 'settings.modelTypes',
                                'flowData.settings.limitPages': 'flowData.settings.limitPages',
                                'flowData.settings.maxPages': 'flowData.settings.maxPages',
                                'settings.runDocumentClassificationOnly': 'settings.runDocumentClassificationOnly'
                              })}
                              <HelpButton href='https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2068067,2460523' />
                            </div>
                          </span>
                        )}
                        <p>
                          These settings are useful for special scenarios only. Enabling them could break regular data
                          extraction.
                        </p>
                        <div className='restrictions'>
                          <div className='column-left single'>
                            <h2 className='title'>Specialized workloads</h2>
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big'
                              placeholder='Hide DOM'
                              value='hideDom'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Hide DOM blocks to reduce the response size.'
                              checked={settings.hideDom}
                            />
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big'
                              placeholder='Convert DOM text blocks to result fields'
                              value='convertBlocksToFields'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Use the blocks of text in documents as result fields. This setting disables data extraction.'
                              checked={settings.convertBlocksToFields}
                            />
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='Partial result extraction for US IDs'
                              value='eagerProcessID'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title={'Continue processing US IDs and driver\'s licenses even if a document is mostly unreadable or cropped. This setting would not allow you to process other document types.'}
                              checked={settings?.eagerProcessID !== undefined ? !!settings.eagerProcessID : false}
                            />
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='Process Excel tables only'
                              value='processExcelOnly'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Only return data from Excel tables. This disables all other data extraction.'
                              checked={settings.processExcelOnly}
                            />
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='Merge multi-page tables'
                              value='mergeTables'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Merge tables that span across multiple pages.'
                              checked={settings.mergeTables}
                            />
                            <Checkbox
                              showTitle={true}
                              key={++keyIndex}
                              className='big left'
                              placeholder='Keep original tables'
                              value='keepOriginalTables'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              title='Keep original tables instead of normalized tables.'
                              checked={settings.keepOriginalTables}
                            />
                          </div>
                        </div>
                        <h2 className='title' id='restrict-document-types'>Restrict document types</h2>
                        <p className='header-paragraph'>
                          When you restrict the flow to specific document types and the uploaded
                          document type is not on the list, you will receive Unrecognized as document type.
                          <br />
                          Leave empty for
                          automatic document type classification (default).
                        </p>
                        <Select
                          isMulti
                          classNamePrefix='react-select'
                          className='react-select'
                          value={settings?.allowedModelTypes || []}
                          styles={this.configuration.inputStyle}
                          options={modelsData}
                          closeMenuOnSelect={false}
                          placeholder='Select or enter document types...'
                          noOptionsMessage={() => 'No document type found'}
                          filterOption={({
                            label,
                            value
                          }, query) => {
                            if (!query) {
                              return true
                            }
                            const modelName = label.toLowerCase()
                            const modelType = value.toLowerCase()
                            query = query.toLowerCase()
                            for (const part of query.split(' ').filter(x => x.trim())) {
                              if (!modelName.includes(part) && !modelType.includes(part)) {
                                return false
                              }
                            }
                            return true
                          }}
                          onChange={changed => {
                            // const allowedModelTypes = changed.filter(type => type.value).map(type => type.value)
                            this.setState({
                              isSubmitted: false,
                              settings: {
                                ...settings,
                                allowedModelTypes: changed
                              }
                            })
                          }}
                        />
                        <h2 className='title'>Force document type classification</h2>
                        <p className='header-paragraph'>
                          If you force the classification, our AI will always pick one of the
                          forced model types even when the uploaded document&apos;s type is not in the list.
                          <br />
                          Leave empty for
                          automatic document type classification (default).
                        </p>
                        <Select
                          isMulti
                          classNamePrefix='react-select'
                          className='react-select'
                          value={settings?.modelTypes || []}
                          styles={this.configuration.inputStyle}
                          options={modelsData}
                          closeMenuOnSelect={false}
                          placeholder='Select or enter document types...'
                          noOptionsMessage={() => 'No document type found'}
                          filterOption={({
                            label,
                            value
                          }, query) => {
                            if (!query) {
                              return true
                            }
                            const modelName = label.toLowerCase()
                            const modelType = value.toLowerCase()
                            query = query.toLowerCase()
                            for (const part of query.split(' ').filter(x => x.trim())) {
                              if (!modelName.includes(part) && !modelType.includes(part)) {
                                return false
                              }
                            }
                            return true
                          }}
                          onChange={changed => {
                            // const modelTypes = changed.filter(type => type.value).map(type => type.value)
                            this.setState({
                              isSubmitted: false,
                              settings: {
                                ...settings,
                                modelTypes: changed
                              }
                            })
                          }}
                        />
                        <h2 className='title'>Set page limit</h2>
                        <p>Read documents up to the specified number of pages. The remaining pages will be discarded.</p>
                        <div className='page-limitation-settings'>
                          <div className='page-limitation-checkbox-container'>
                            <Checkbox
                              className='page-limitation-checkbox big'
                              placeholder='Set limit pages count'
                              onChange={e => {
                                const flowData = this.state.flowData
                                flowData.settings = flowData.settings || {}
                                flowData.settings.limitPages = flowData.settings.limitPages || {}
                                flowData.settings.limitPages.status = e.target.checked ? 'enabled' : 'disabled'
                                flowData.settings.limitPages.value = flowData.settings.limitPages.status === 'enabled'
                                  ? flowData.settings.maxPages?.status === 'enabled'
                                    ? flowData.settings.maxPages?.value
                                    : 50
                                  : ''
                                this.setState({
                                  isSubmitted: false,
                                  flowData
                                })
                              }}
                              checked={this.state.flowData?.settings?.limitPages?.status === 'enabled'}
                            />
                          </div>
                          <input
                            className={'basic page-limitation ' + (this.state.flowData?.settings?.limitPages?.status !== 'enabled' ? 'disabled' : '')}
                            disabled={this.state.flowData?.settings?.limitPages?.status !== 'enabled'}
                            min='1'
                            max='50'
                            type='number'
                            value={this.state.flowData?.settings?.limitPages?.value}
                            name='limit-page-limitation'
                            onChange={e => {
                              if (e.target.value > 50) {
                                return alert('Limit pages parameter must be lower than 50.')
                              }
                              if (
                                this.state.flowData.settings.maxPages?.status === 'enabled'
                                && e.target.value > this.state.flowData.settings.maxPages?.value
                              ) {
                                return alert('The limit pages parameter must be lower than maximum pages parameter.')
                              }
                              const flowData = this.state.flowData
                              flowData.settings = flowData.settings || {}
                              flowData.settings.limitPages = flowData.settings.limitPages || {}
                              flowData.settings.limitPages.value = e.target.value
                              this.setState({
                                isSubmitted: false,
                                flowData
                              })
                            }}
                          />
                        </div>
                        <h2 className='title'>Reject long documents</h2>
                        <p>
                          Return an error if the document has more than the specified number of pages. No processing will
                          occur.
                        </p>
                        <div className='page-limitation-settings'>
                          <div className='page-limitation-checkbox-container'>
                            <Checkbox
                              className='page-limitation-checkbox big'
                              placeholder='Set maximum page count'
                              onChange={e => {
                                const flowData = this.state.flowData
                                flowData.settings = flowData.settings || {}
                                flowData.settings.maxPages = flowData.settings.maxPages || {}
                                flowData.settings.maxPages.status = e.target.checked ? 'enabled' : 'disabled'
                                flowData.settings.maxPages.value = flowData.settings.maxPages.status === 'enabled'
                                  ? 50
                                  : ''
                                this.setState({
                                  isSubmitted: false,
                                  flowData
                                })
                              }}
                              checked={this.state.flowData?.settings?.maxPages?.status === 'enabled'}
                            />
                          </div>
                          <input
                            className={'basic page-limitation ' + (this.state.flowData?.settings?.maxPages?.status !== 'enabled' ? 'disabled' : '')}
                            disabled={this.state.flowData?.settings?.maxPages?.status !== 'enabled'}
                            min='1'
                            max='50'
                            type='number'
                            value={this.state.flowData?.settings?.maxPages?.value}
                            name='max-page-limitation'
                            onChange={e => {
                              if (e.target.value > 50) {
                                return alert('Maximum pages parameter must be lower than 50.')
                              }
                              const flowData = this.state.flowData
                              flowData.settings = flowData.settings || {}
                              flowData.settings.maxPages = flowData.settings.maxPages || {}
                              flowData.settings.maxPages.value = e.target.value
                              if (e.target.value < this.state.flowData.settings.limitPages?.value) {
                                flowData.settings.limitPages.value = e.target.value
                              }
                              this.setState({
                                isSubmitted: false,
                                flowData
                              })
                            }}
                          />
                        </div>
                        <div className='classification-only-container'>
                          <div className='column-left single'>
                            <h2 className='title'>Document classification only</h2>
                            <p className='header-paragraph classification-only'>
                              If selected, our AI will only classify the
                              document type but will not extract data from documents.
                            </p>
                            <Checkbox
                              key={++keyIndex}
                              className='big'
                              placeholder='Run only document classification'
                              value='runDocumentClassificationOnly'
                              onChange={e => {
                                this.onCheckBoxChange(e.target)
                              }}
                              checked={settings.runDocumentClassificationOnly}
                            />
                          </div>
                        </div>
                      </Section>
                    )}
                    {flowID && (
                      <Section
                        name='source-destination'
                        className='settings-card'
                        if={this.state.isMobile || activeTab === 'integration'}
                        accordion={this.state.isMobile}
                        isOpen={activeTab === 'integration'}
                        title='Integrations'
                        onClick={() => {
                          this.accordionSectionOnClickHandler('integration')
                        }}
                      >
                        <div>
                          {!this.state.isMobile && (
                            <span className='title-container'>
                              <h1 className='title'>Integrations</h1>
                              <HelpButton href='https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2068067,2068068' />
                            </span>
                          )}
                          <p>
                            Integrations allow your Flow to communicate with over 400 popular platforms such as Google Drive,
                            Dropbox, and Salesforce using the built-in no-code components.
                            Ingest new documents as they come, augment the results with your custom business logic, and export
                            the results to any external system without requiring coding or servers.
                          </p>
                          {flowData?.role?.includes('owner') || this.isFlowRequestedByAdmin(flowData)
                            ? (
                                <IntegrationManager
                                  context={flowData}
                                  user={user}
                                  onCloseEditor={this.integrationManagerCallBack}
                                  onEmailOptionChange={this.emailNotifierCallBack}
                                  onScanFromEmailNotifierChange={this.onScanFromEmailNotifierChange}
                                  onContentTypeChanged={(integrationType, contentType) => {
                                    flowData[integrationType].integration.content = contentType
                                    this.setState({ flowData, isSubmitted: false })
                                  }}
                                  notifierStatus={this.state.uploadNotifierStatus}
                                  replyMail={!!this.state.replyMail}
                                />
                              )
                            : <span>Contact the flow owner to add custom integrations.</span>}
                        </div>
                      </Section>
                    )}
                    {
                      flowData && (
                        <Section
                          name='reviewPage'
                          className='settings-card'
                          if={this.state.isMobile || activeTab === 'reviewPage'}
                          accordion={this.state.isMobile}
                          isOpen={activeTab === 'reviewPage'}
                          title='Review page'
                          onClick={() => {
                            this.accordionSectionOnClickHandler('reviewPage')
                          }}
                        >
                          {!this.state.isMobile && (
                            <span className='title-container'>
                              <h1 className='title'>Review page</h1>
                              <div>
                                {this.renderCopyPasteSection({
                                  'additionalListColumns': 'additionalListColumns',
                                  'flowData.hitl.tableHighlightThreshold': 'flowData.hitl.tableHighlightThreshold',
                                  'flowData.reviewSla': 'flowData.reviewSla',
                                  'flowData.hitl.hideReviewerWatermarks': 'flowData.hitl.hideReviewerWatermarks'
                                })}
                                <HelpButton href='https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2068067,2068071' />
                              </div>
                            </span>
                          )}
                          <p className='header-paragraph'>
                            Adjust the Flow User Interface (UI) settings to review the documents more efficiently.
                          </p>
                          <h2 className='title'>Upload instructions</h2>
                          <p className='header-paragraph'>
                            Provide a file that explain your review processes. The reviewers can access it from the review page
                            for guidance.
                          </p>
                          {this.state.flowData.instructions?.fileName && (
                            <div className='existingUploadLinks'>
                              <DownloadLink
                                style={{ marginBottom: '1rem' }}
                                url={'/flow/instructions/' + flowID}
                                name='View instructions'
                                fileName={this.state.flowData.instructions?.fileName}
                              />
                              <a
                                className='removeFileAnchor'
                                onClick={() => this.deleteInstructionsFile(this.state.flowData.instructions.fileName)}
                              >
                                Delete this file
                              </a>
                            </div>
                          )}
                          {
                            hitlMode === 'hitl-active'
                              ? (
                                  <div className='uploadFileButton'>
                                    <FileInput
                                      accept={Array.from(acceptedFileFormats)}
                                      onDrop={accepted =>
                                        this.uploadInstructionDocuments(accepted)}
                                      indicator={true}
                                      dropText='Upload a document'
                                      multiple={false}
                                      disabled={!!this.state.flowData?.instructions?.fileName}
                                      style={this.state.flowData?.instructions?.fileName?.length ? { opacity: 0.5 } : {}}
                                    />
                                  </div>
                                )
                              : (
                                  <>
                                    <br />
                                    <i>
                                      You should enable review mode
                                      {' '}
                                      <Link
                                        to={`/flow/edit/${this.props?.flowID}/basicInformation`}
                                      >
                                        here
                                      </Link>
                                      {' '}
                                      to upload instruction file.
                                    </i>
                                  </>
                                )
                          }
                          <h2 className='title'>Display additional columns</h2>
                          <p className='header-paragraph'>
                            Select the additional columns to display on the Flow results list.
                            <br />
                            Select the suggested items or enter custom data sources, which should be as in the API
                            result format, e.g., companyName, dateOfBirth, etc.
                            <br />
                            File name of the document and status of the result are static columns.
                          </p>
                          <MultiInputTable
                            tableStructure={additionalListColumnsTable}
                            data={this.state.additionalListColumns}
                            onChange={data => {
                              const cleanedData = convertToJSONObject(data) // 'data' contains some unused functions which disables 'isEqual' method to compare properly.
                              const list = this.getAdditionalListColumns()
                              const isDefault = isEqual(cleanedData, list)
                              this.setState({
                                additionalListColumns: data,
                                isSubmitted: isDefault ? this.state.isSubmitted : false
                              })
                            }}
                          />
                          <h2 className='title'>Table editor chart background color</h2>
                          <p className='header-paragraph'>
                            Set the background color for the chart in the table editor on the download operation.
                          </p>
                          <input
                            type='color'
                            name='favcolor'
                            className='color-picker'
                            defaultValue={this.state.flowData?.hitl?.chartBackground?.slice(0, 7)}
                            onInput={e => {
                              const value = e.target.value
                              const flowData = this.state.flowData
                              flowData.hitl = flowData.hitl || {}
                              flowData.hitl.chartBackground = value
                              this.setState({
                                isSubmitted: false,
                                flowData: this.state.flowData
                              })
                            }}
                          />
                          <h2 className='title'>Table editor highlight threshold</h2>
                          <p className='header-paragraph'>
                            Set a confidence level between 0 and 100% to highlight the cells with low confidence score.
                          </p>
                          <div className='table-editor-highlighter-slider-container'>
                            <Slider
                              defaultValue={10}
                              value={this.state.flowData?.hitl?.tableHighlightThreshold}
                              step={1}
                              min={0}
                              max={100}
                              onChange={value => {
                                const flowData = this.state.flowData
                                flowData.hitl = flowData.hitl || {}
                                flowData.hitl.tableHighlightThreshold = value
                                this.setState({
                                  isSubmitted: false,
                                  flowData: this.state.flowData
                                })
                              }}
                            />
                            <span>
                              {' '}
                              {this.state.flowData?.hitl?.tableHighlightThreshold ?? 10}
                              {' '}
                              %
                            </span>
                          </div>
                          <h2 className='title'>Table editor default position</h2>
                          <p className='header-paragraph'>
                            Set the default position of the table editor in the flow page.
                          </p>
                          <Select
                            classNamePrefix='react-select'
                            className='react-select'
                            styles={{
                              control: base => ({
                                ...base,
                                height: '2.6rem',
                                width: '12rem'
                              }),
                              container: provided => ({
                                ...provided,
                                maxWidth: '12rem'
                              })
                            }}
                            defaultValue={defaultTablePositionOptions.find(e => e.value === this.state.flowData?.hitl?.tablePosition) ?? {
                              label: 'Center', value: 'center'
                            }}
                            options={
                              defaultTablePositionOptions
                            }
                            closeMenuOnSelect
                            placeholder='Select table position'
                            noOptionsMessage={() => 'Not found'}
                            onChange={changed => {
                              const value = changed.value
                              const flowData = this.state.flowData
                              flowData.hitl = flowData.hitl || {}
                              flowData.hitl.tablePosition = value
                              this.setState({
                                isSubmitted: false,
                                flowData: this.state.flowData
                              })
                            }}
                          />
                          {hitlMode === 'hitl-active' && (
                            <div>
                              <h2 className='title'>Review time SLA</h2>
                              <p className='header-paragraph'>
                                Set document review timeline to highlight overdue items.
                              </p>
                              <TimeIntervalPicker
                                title='Expected review time'
                                min='1'
                                max='1000'
                                value={this.state.flowData?.reviewSla?.value || 1}
                                type={this.state.flowData?.reviewSla?.type || 'day'}
                                defaultType='day'
                                placeholder='Expected review time:'
                                checked={this.state.flowData?.reviewSla?.status === 'enabled'}
                                onChangeCheckbox={value => {
                                  this.setState({
                                    isSubmitted: false,
                                    flowData: {
                                      ...this.state.flowData,
                                      reviewSla: {
                                        type: this.state.flowData?.reviewSla?.type || 'day',
                                        value: this.state.flowData?.reviewSla?.value || 1,
                                        status: value ? 'enabled' : 'disabled'
                                      }
                                    }
                                  })
                                }}
                                onChangeValue={value => {
                                  this.setState({
                                    isSubmitted: false,
                                    flowData: {
                                      ...this.state.flowData,
                                      reviewSla: {
                                        type: this.state.flowData?.reviewSla?.type || 'day',
                                        status: this.state.flowData?.reviewSla?.status || 'enabled',
                                        value
                                      }
                                    }
                                  })
                                }}
                                onChangeType={value => {
                                  this.setState({
                                    isSubmitted: false,
                                    flowData: {
                                      ...this.state.flowData,
                                      reviewSla: {
                                        value: this.state.flowData?.reviewSla?.value || 1,
                                        status: this.state.flowData?.reviewSla?.status || 'enabled',
                                        type: value
                                      }
                                    }
                                  })
                                }}
                              />
                            </div>
                          )}
                          {hitlMode === 'hitl-active' && (
                            <div>
                              <h2 className='title'>Reviewer watermarks</h2>
                              <p className='header-paragraph'>
                                Displays documents to reviewers behind a watermark.
                              </p>
                              <Checkbox
                                checked={!!this.state.flowData?.hitl?.hideReviewerWatermarks}
                                className='big'
                                placeholder='Hide watermarks on reviewed documents'
                                onChange={e => {
                                  this.setState({
                                    isSubmitted: false,
                                    flowData: {
                                      ...this.state.flowData,
                                      hitl: {
                                        ...this.state.flowData.hitl,
                                        hideReviewerWatermarks: e.target.checked
                                      }
                                    }
                                  })
                                }}
                              />
                            </div>
                          )}
                        </Section>
                      )
                    }
                    {this.props?.flowID && (
                      <Section
                        name='danger-zone'
                        if={this.state.isMobile || activeTab === 'dangerZone'}
                        className='settings-card'
                        accordion={this.state.isMobile}
                        isOpen={activeTab === 'dangerZone'}
                        title='Delete'
                        onClick={() => {
                          this.accordionSectionOnClickHandler('dangerZone')
                        }}
                      >
                        {!this.state.isMobile && (
                          <span className='title-container'>
                            <h1 className='title'>Delete</h1>
                            <HelpButton href='https://help.base64.ai/kb/guide/en/flow-end-2-end-automation-hitl-document-processing-WH1QqHEiey/Steps/2068067,2068071,2068067,2644277' />
                          </span>
                        )}
                        <p>
                          <b>Warning: This is the danger zone.</b>
                          <br />
                          Use the following options to pause or delete operations within this particular flow.
                        </p>
                        <h2 className='title'>
                          {this.state.flowData?.status === 'enabled'
                            ? 'Freeze'
                            : 'Unfreeze'}
                          {' '}
                          flow
                        </h2>
                        <p className='header-paragraph'>
                          {this.state.flowData?.status === 'enabled'
                            ? 'Keep your data and settings, but do not accept new files.'
                            : 'Unfreeze the flow to accept new files.'}
                        </p>
                        <AppButton
                          className={clsx('secondary-cta min-w-32', this.state.flowData?.status === 'enabled' && 'danger')}
                          disabled={this.state.flowData?.isDefault}
                          title={this.state.flowData?.isDefault ? 'This is your default flow. You cannot freeze it unless you set another default flow on the User Settings Page.' : ''}
                          onClick={() => {
                            this.setFlowStatus(this.state.flowData?.status === 'enabled' ? 'disabled' : 'enabled')
                          }}
                        >
                          {this.state.flowData?.status === 'enabled' ? 'Freeze' : 'Unfreeze'}
                        </AppButton>

                        <h2 className='title'>Delete flow</h2>
                        <p className='header-paragraph'>Delete your documents and settings. This action is not reversible.</p>
                        <AppButton
                          id='deleteFlow'
                          className='secondary-cta danger min-w-32'
                          disabled={this.state.flowData?.isDefault}
                          title={this.state.flowData?.isDefault ? 'This is your default flow. You cannot delete it unless you set another default flow on the User Settings Page.' : ''}
                          onClick={() => {
                            this.deleteFlow(this.props?.flowID)
                          }}
                        >
                          Delete
                        </AppButton>
                      </Section>
                    )}
                  </div>
                </Section>
                {
                  (this.state.isBottomSaveButtonEnabled || this.state.isMobile)
                  && (
                    <Section className='bottom-save-button-container settings-container'>
                      {this.renderCancelSaveContainer()}
                    </Section>
                  )
                }
              </>
            )}
      </View>
    )
  }
}

export default CreateFlow
