import { Component } from 'react'

// interface
import CreatableSelect from 'react-select/creatable'
import { components } from 'react-select'

// utilities
import { emailValidator as isEmail, domainValidator as isDomain, regexValidator as isRegex } from '../../utilities/validators'

// modules
import PropTypes from 'prop-types'

class MultiEmail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      inputValue: '',
      value: this.props.emails || []
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentDidMount () {
    const values = this.props.emails || []
    this.setState({ value: values.map(it => this.createOption(it)) })
  }

  createOption (label) {
    return {
      label, value: label
    }
  }

  handleChange (value, action) {
    this.setState({ value },
      () => {
        this.props.onChange(this.state.value.map(it => {
          return it.value
        }))
      })
  }

  handleInputChange (value) {
    this.setState({ inputValue: value })
  }

  handleKeyDown (event) {
    const { inputValue, value } = this.state
    const domainValidator = this.props.validateDomain && isDomain
    const emailValidator = this.props.validateEmail !== false && isEmail
    const regexValidator = this.props.validateRegex && isRegex

    if (!inputValue?.trim()) return
    if (domainValidator && emailValidator) {
      if (!domainValidator(inputValue) && !emailValidator(inputValue)) {
        return
      }
    } else if (domainValidator && !emailValidator) {
      if (!domainValidator(inputValue)) {
        return
      }
    } else if (regexValidator) {
      if (inputValue.startsWith('/')) {
        if (!regexValidator(inputValue)) {
          return
        }
      }
    } else {
      if (!emailValidator(inputValue)) {
        return
      }
    }
    const updateState = () => {
      this.setState({
        inputValue: '',
        value: [...value, this.createOption(inputValue)]
      }, () => {
        this.props.onChange(this.state.value.map(it => {
          return it.value
        }))
      })
      event.preventDefault()
    }
    if (event.type === 'blur' && this.props.blurBehavior === 'handleChange') {
      updateState()
      return
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
        if (this.props.ignoreSpace && event.key === ' ') {
          return
        }
        updateState()
    }
  }

  // FIXME: I would much rather have this under EditCustomModel.mjs however that would require a lot of this that switching
  // and wrapping functions that return functions. Best solution for a use case like this is to not define the fields as state here.
  // Open to ideas
  MultiValueLabelForDirectHave = props => {
    return (
      <components.MultiValueLabel
        {...props}
        innerProps={{
          ...(props?.innerProps || {}),
          onClick: e => {
            e.stopPropagation()
            e.preventDefault()

            const directHave = this.state.value

            // check if regex
            // match any regex
            const isRegex = props?.data?.label?.match(/^\/.*\/.*$/)
            const index = directHave.findIndex(dh => dh.label === props?.data?.label)
            if (isRegex) {
              // remove / and /i
              const flagsLength = props?.data?.label?.match(/^\/.*\/(.*)$/)[1].length
              const text = props?.data?.label?.slice(1, -flagsLength - 1)
              // replace existing string
              directHave[index] = { label: text, value: text }
              // setstate
            } else {
              // add / and /i
              const regex = `/${props?.data?.label}/i`
              // replace existing string
              directHave[index] = { label: regex, value: regex }
              // setstate
            }
            this.handleChange(directHave)
          }
        }}
      />
    )
  }

  render () {
    const { inputValue, value } = this.state
    const components = {
      DropdownIndicator: null
    }
    if (this.props.isRegexSelect) {
      components.MultiValueLabel = this.MultiValueLabelForDirectHave
    }
    return (
      <div data-view='multi-email' style={{ ...this.props.style, width: this.props.width }}>
        <CreatableSelect
          isMulti
          isClearable
          className='react-select'
          classNamePrefix='react-select'
          inputProps={{ autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off' }}
          components={components}
          menuIsOpen={false}
          placeholder={this.props.placeholder || 'Please enter valid emails...'}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleKeyDown}
          inputValue={inputValue}
          value={value}
        />
      </div>
    )
  }
}

MultiEmail.propTypes = {
  emails: PropTypes.array,
  onChange: PropTypes.func,
  blurBehavior: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.object,
  validateDomain: PropTypes.bool,
  validateEmail: PropTypes.bool,
  validateRegex: PropTypes.bool,
  ignoreSpace: PropTypes.bool,
  isRegexSelect: PropTypes.bool
}

export default MultiEmail
