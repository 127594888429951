// Layout
import Section from '~/layout/section/Section'
import mediaKit from '~/data/mediaKit'
import { isWorkingOffline } from '~/context/environment'

const SVGComponent = props => (
  <img
    src="/static/media-kit/base64.ai-icon.svg"
    alt="Base64.ai Icon"
    className='svg logo'
    {...props}
  />
)

const MediaKitSection = props => (
  !isWorkingOffline()
  && (
    <Section
      name='media-kit'
      align='center'
      identifier
    >
      <h1 className='slogan'>Logos</h1>
      <ul className='list'>
        <li>
          <a href='/static/media-kit/base64.ai-icon.svg' target='_blank' className='noBorder icon-anchor' rel='noopener noreferrer'>
            <SVGComponent />
          </a>
          <p className='description'>Icon in SVG format</p>
        </li>
        {mediaKit.map(logo => (
          <li key={logo}>
            <a href={`/static/media-kit/${logo.filename}`} target='_blank' className='noBorder icon-anchor' rel='noopener noreferrer'>
              <img className={logo.isIcon ? 'icon' : 'logo'} src={`/static/media-kit/${logo.filename}`} style={logo.style || {}} />
            </a>
            <p className='description'>{logo.description} {(logo.dimensions && `(${logo.dimensions[0]}x${logo.dimensions[1]})`)}</p>
          </li>
        )
        )}
      </ul>
    </Section>
  )
)

// Export
export default MediaKitSection
