// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'
// Context
import Context from '~/context/global'
import { reCaptcha } from '~/helpers/authentication'
import { getBase64 } from '~/helpers/file'
// Helpers
import { request } from '~/helpers/request'
// Interface
import FileInput from '~/interface/file-input/FileInput'
import StatusToEmoji from '~/interface/validation-sign/StatusToEmoji'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import window from 'global/window'
// Utilities
import { triggerTrackingEvent } from '../../utilities/tracker'
// View: Demo
class CovidVaccinationValidation extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      responseMessage: 'Please upload an ID, DL, or passport to the left, and a vaccination card to the right.',
      validationResponse: null,
      idBase64: null,
      idUrl: null,
      vaccineCardUrl: null,
      vaccineCardBase64: null,
      nextPath: null
    }
    this.handleFiles = this.handleFiles.bind(this)
    this.makeRequest = this.makeRequest.bind(this)
    this.validateId = this.validateId.bind(this)
    this.showSampleLink = this.showSampleLink.bind(this)
  }

  componentDidMount () {
    this.setState({
      nextPath: window.location.pathname
    })
  }

  handleFiles (accepted, rejected, isId) {
    this.setState({
      validationResponse: null
    })
    if (accepted && accepted.length) {
      getBase64({ source: accepted[0] }, (error, response) => {
        if (error) {
          this.setState({
            responseStatus: 'error',
            responseMessage: 'Cannot read the file!'
          })
          return
        }
        if (isId) {
          this.setState({
            idBase64: response
          }, this.validateId)
        } else {
          this.setState({
            vaccineCardBase64: response
          }, this.validateId)
        }
      })
    }
    if (rejected && rejected.length) {
      this.setState({
        responseStatus: 'error',
        responseMessage: 'Invalid file format'
      })
    }
  }

  makeRequest () {
    triggerTrackingEvent('demo-completed-covid-vaccination')
    reCaptcha('demo', token => {
      const idOrUrl = this.state.idBase64 ? { idImage: this.state.idBase64 } : { idUrl: this.state.idUrl }
      const vaccineCardOrUrl = this.state.vaccineCardBase64 ? { vaccineCardImage: this.state.vaccineCardBase64 } : { vaccineCardUrl: this.state.vaccineCardUrl }
      request({
        endpoint: '/scan/vaccineCard',
        body: {
          token,
          ...idOrUrl,
          ...vaccineCardOrUrl
        }
      }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: error.message,
            responseStatus: 'error',
            validationResponse: null
          })
        } else {
          if (response.message) {
            this.setState({
              responseMessage: error,
              responseStatus: 'error',
              validationResponse: null
            })
          } else {
            this.setState({
              responseMessage: `${response.givenName} ${response.familyName} is vaccinated`,
              responseStatus: 'success',
              validationResponse: response
            })
          }
        }
      })
    })
  }

  validateId () {
    if ((this.state.idBase64 || this.state.idUrl) && (this.state.vaccineCardBase64 || this.state.vaccineCardUrl)) {
      this.setState({
        responseStatus: 'info',
        responseMessage: 'Analyzing the Covid-19 vaccination card...'
      }, this.makeRequest)
    }
  }

  showSampleLink (status) {
    if (status) {
      return null
    }
  }

  render () {
    const {
      idBase64,
      idUrl,
      responseMessage,
      responseStatus,
      vaccineCardUrl,
      vaccineCardBase64,
      nextPath
    } = this.state
    return (
      <View name='covid-vaccination-demo'>
        <Header name='header'>
          <h1 className='slogan'>Covid-19 Vaccination Card Validation AI</h1>
          <h2 className='introduction'>Validate Covid-19 vaccination cards with IDs, driver licenses and passports</h2>
          <h3 className='introduction'>Help stop the spread of Covid-19 by automatically and accurately matching a vaccination card with an ID, driver license, or passport.</h3>
        </Header>
        <Section name='output'>
          <div><StatusToEmoji status={responseStatus} message={responseMessage} />{this.showSampleLink(responseStatus)}</div>
        </Section>
        {nextPath
          ? (
              <Section name='input'>
                <div className='uploadBox uploadBoxLeft'>
                  <FileInput
                    double={true}
                    context={this.context}
                    requireLogin={!this.props.user}
                    next={window.location.pathname}
                    dropText='Upload an ID'
                    buttonText='Upload file'
                    multiple={false}
                    indicator={true}
                    accept={[
                      'image/png',
                      'image/jpg',
                      'image/jpeg',
                      'image/gif',
                      'application/pdf'
                    ]}
                    onDrop={(accepted, rejected, event) =>
                      this.handleFiles(accepted, rejected, true)}
                  />
                  {idBase64 || idUrl ? <img src={idBase64 || idUrl} /> : null}
                </div>
                <div className='uploadBox'>
                  <FileInput
                    double={true}
                    context={this.context}
                    requireLogin={!this.props.user}
                    next={window.location.pathname}
                    dropText='Upload a vaccine card'
                    multiple={false}
                    indicator={true}
                    accept={[
                      'image/png',
                      'image/jpg',
                      'image/jpeg',
                      'image/gif',
                      'application/pdf'
                    ]}
                    onDrop={(accepted, rejected, event) =>
                      this.handleFiles(accepted, rejected)}
                  />
                  {vaccineCardBase64 || vaccineCardUrl ? <img src={vaccineCardBase64 || vaccineCardUrl} /> : null}
                </div>
              </Section>
            )
          : null}
      </View>
    )
  }
}

CovidVaccinationValidation.propTypes = {
  user: PropTypes.object
}

// Export
export default CovidVaccinationValidation
