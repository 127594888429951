// Modules
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

// Pseudo-components
const PseudoLink = props => props.to
  ? <RouterLink {...props}>{props.children}</RouterLink>
  : <a {...props}>{props.children}</a>

PseudoLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
}

// Interface: Link
const Link = props => (
  <PseudoLink
    {...props}
    className={
      (props.className ? `${props.className} ` : '')
      + (props.role === 'button' ? '' : 'link')
    }
  />
)

Link.propTypes = {
  role: PropTypes.string,
  className: PropTypes.string
}

// Export
export default Link
