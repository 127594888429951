// Modules
import { Component } from 'react'
import { reCaptcha } from '~/helpers/authentication'
import { request } from '~/helpers/request'

// Context
import Context from '~/context/global'

// Interface
import Form from '~/interface/form/Form'
import SocialIcon from '~/interface/social-icon/SocialIcon'

// Layout
import View from '~/layout/view/View'
import Section from '~/layout/section/Section'
import Header from '~/layout/header/Header'
import window from 'global/window'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { triggerTrackingEvent } from '../../utilities/tracker'
import { isWorkingOffline } from '../../context/environment'
import { RegEx } from '../../constants/RegEx'
import { AppButton } from '../../interface/app-button/AppButton'
// View: Contact
class Contact extends Component {
  static contextType = Context

  constructor (params) {
    super(params)
    this.state = {
      search: '',
      loading: false,
      submitHandler: () => {}
    }
  }

  componentDidMount () {
    this.setState({ search: window.location.search })
  }

  addLead (data, errorCallback) {
    triggerTrackingEvent('contact-lead-form-submit')

    this.setState({ loading: true })
    request({
      endpoint: '/lead',
      body: {
        ...data,
        referrer: window.location.href
      }
    }, (error, response) => {
      if (error) {
        errorCallback(error?.message || error.toString())
      } else {
        this.context.displayModal({
          title: 'Thank you',
          content: 'We will get back to you shorty!',
          options: [
            {
              content: 'OK',
              action: () => {
                window.location.href = window.location.origin + '/flow'
              }
            }
          ],
          isClosable: true
        })
      }
      this.setState({ loading: false })
    })
  }

  render () {
    const mapsKey = window.isLocalhost ? 'AIzaSyDktxsXDcZ8urrmYetipJdpWdTl6Djqm8k' : 'AIzaSyAq4vh647WZ09y4dAjWVDPSRT_i8m_kejc'
    return (
      <View name='contact'>
        <Header name='contact'>
          <h1 className='slogan'>
            Contact Base64.ai
          </h1>
          <h3 className='introduction'>
            Pick a time with our team,<br />
            or leave your info and we will get back to you.
          </h3>
        </Header>
        {
          this.state.search?.match(/meeting=/)
            ? (
                <Section name='scheduled' align='center'>
                  <h2 className='title'>Your meeting is scheduled!</h2>
                  <p className='introduction'>We received your request. We are looking forward to meeting you soon.</p>
                  <p><a href='/contact'>Show form again</a></p>
                </Section>
              )
            : (
                <>
                  <Section name='meeting' tight={true}>
                    <iframe
                      src='https://meetings.hubspot.com/keith-haas?embed=true'
                      scrolling='no'
                      style={{ display: 'block', margin: 'auto', width: '100%', height: '680px', marginTop: '48px' }}
                    >
                    </iframe>
                  </Section>
                  <Section name='form'>
                    <Form
                      className='leadForm'
                      title='Leave your information and we will get back to you'
                      onDataSubmit={(data, errorCallback) => {
                        reCaptcha('lead', token => {
                          data.token = token
                          this.addLead(data, errorCallback)
                        })
                      }}
                      onSubmitHandler={submitHandler => {
                        this.setState({ submitHandler })
                      }}
                    >
                      <Form.Input
                        type='text'
                        name='name'
                        reference='Name'
                        placeholder='Name'
                        autoComplete='off'
                        validator={{
                          minimumLength: 1
                        }}
                      />
                      <Form.Input
                        type='email'
                        name='email'
                        reference='Work email'
                        placeholder='Work email'
                        autoComplete='off'
                        validator={{
                          pattern: RegEx.EMAIL,
                          maximumLength: 255
                        }}
                      />
                      <Form.Input
                        type='text'
                        name='companyName'
                        reference='Company name'
                        placeholder='Company name'
                        autoComplete='off'
                      />
                      <Form.Input
                        type='text'
                        name='website'
                        reference='Website'
                        placeholder='Company website'
                        autoComplete='off'
                      />
                      <Form.Textarea
                        className='custom-message'
                        type='text'
                        name='message'
                        col='200'
                        reference='Your message'
                        placeholder='Type your message'
                        autoComplete='off'
                      />
                      <AppButton
                        className='secondary-cta large'
                        loading={this.state.loading}
                        onClick={this.state.submitHandler}
                      >
                        Send
                      </AppButton>
                    </Form>
                  </Section>
                </>
              )
        }
        <Section name='info'>
          {
            !isWorkingOffline()
              ? (
                  <a
                    className='map'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://www.google.com/maps?q=Base64.ai'
                  >
                    <img
                      alt='Base64.ai'
                      src={'https://maps.googleapis.com/maps/api/staticmap?center=244+Madison+Ave+NY&zoom=13&style=feature:poi%7Cvisibility:off&size=600x400&markers=icon:https://base64.ai/static/images/iconx32.png%7C244+Madison+Ave+NY&key=' + mapsKey}
                    />
                  </a>
                )
              : <></>
          }
          <div className='text'>
            <h3>
              <span>Address:</span>
              <a
                className='map'
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.google.com/maps?q=Base64.ai'
              >
                Base64.ai, Inc<br />
                244 Madison Ave, Suite 1124<br />
                New York, NY 10016<br />
                USA
              </a>
              <CopyToClipboard
                text='Base64.ai, Inc 244 Madison Ave, Suite 1124 New York, NY 10016 USA'
                onCopy={() => {
                  window.alert('Copied to your clipboard!')
                }}
              >
                <span className='clipboard-icon' title='Click here to copy'>
                  📋
                </span>
              </CopyToClipboard>
            </h3>

            <h3>
              <span>Sales team:</span>
              <a href='mailto:sales@base64.ai' title='Send e-mail to sales@base64.ai'>sales@base64.ai</a>
            </h3>
            <h3>
              <span>Support team:</span>
              <a href='mailto:support@base64.ai' title='Send e-mail to support@base64.ai'>support@base64.ai</a>
            </h3>
            <div>
              <SocialIcon color='blue' href='https://base64.ai/linkedin' title="Visit Base64.ai's LinkedIn page" media='linkedin' />
              <SocialIcon color='blue' href='https://base64.ai/twitter' title="Visit Base64.ai's X (Twitter) page" media='x' />
              <SocialIcon color='blue' href='https://base64.ai/youtube' title="Visit Base64.ai's YouTube channel" media='youtube' />
              <SocialIcon color='blue' href='https://base64.ai/vimeo' title="Visit Base64.ai's Vimeo channel" media='vimeo' />
              <SocialIcon color='blue' href='https://base64.ai/facebook' title="Visit Base64.ai's Facebook page" media='facebook' />
              <SocialIcon color='blue' href='https://base64.ai/github' title="Visit Base64.ai's Github page" media='github' />
            </div>
          </div>
        </Section>
      </View>
    )
  }
}

// Export
export default Contact
