import Checkbox from '../checkbox/Checkbox'
import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

const timeIntervalTypes = [
  { label: 'Hours', value: 'hour' },
  { label: 'Days', value: 'day' },
  { label: 'Weeks', value: 'week' },
  { label: 'Months', value: 'month' }
]

class TimeIntervalPicker extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChangeValue: PropTypes.func,
    type: PropTypes.string,
    defaultType: PropTypes.string,
    placeholder: PropTypes.node,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    onChangeCheckbox: PropTypes.func,
    onChangeType: PropTypes.func,
    min: PropTypes.string,
    max: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      checked: props.checked || props.defaultChecked,
      value: props.value || props.defaultValue || '1',
      type: props.type || props.defaultType
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked })
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value })
    }
    if (prevProps.type !== this.props.type) {
      this.setState({ type: this.props.type })
    }
  }

  render () {
    return (
      <div className='time-interval-settings'>
        <div className='time-interval-checkbox-container'>
          <Checkbox
            className='time-interval-checkbox big'
            placeholder={this.props.placeholder}
            onChange={e => {
              this.setState({ checked: e.target.checked })
            }}
            onBlur={e => this.props.onChangeCheckbox(this.state.checked)}
            title={this.props.title}
            checked={this.state.checked}
          />
        </div>
        <React.Fragment>
          <input
            className={'basic time-interval-value ' + (!this.state.checked ? 'disabled' : '')}
            disabled={!this.state.checked}
            min={this.props.min}
            max={this.props.max}
            value={this.state.value}
            type='number'
            name='time-interval-period'
            onChange={e => {
              this.setState({ value: e.target.value })
            }}
            onBlur={() => {
              if (!this.state.value) {
                this.props.onChangeValue(this.props.defaultValue || '1')
              } else {
                let returnValue = this.state.value
                if (this.props.min) {
                  returnValue = Math.max(parseInt(returnValue), parseInt(this.props.min)).toString()
                }
                if (this.props.max) {
                  returnValue = Math.min(parseInt(returnValue), parseInt(this.props.max)).toString()
                }
                this.props.onChangeValue(returnValue)
              }
            }}
          />
          <Select
            id='time-interval-type'
            classNamePrefix='react-select'
            className='react-select'
            styles={{
              control: base => ({
                ...base,
                height: '2.6rem'
              })
            }}
            isDisabled={!this.state.checked}
            options={timeIntervalTypes}
            onChange={e => {
              this.setState({ type: e.value })
            }}
            onBlur={() => {
              this.props.onChangeType(this.state.type)
            }}
            value={timeIntervalTypes.find(e => e.value === this.state.type)}
          />
        </React.Fragment>
      </div>
    )
  }
}

export default TimeIntervalPicker
