// Modules
import React, { Component } from 'react'
// Context
import Context from '~/context/global'
import { getBase64 } from '~/helpers/file'
// Helpers
import { request } from '~/helpers/request'
// Interface
import FileInput from '~/interface/file-input/FileInput'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import { reCaptcha } from '~/helpers/authentication'
import Form from '~/interface/form/Form'
import PropTypes from 'prop-types'
// Utilities
import { triggerTrackingEvent } from '../../utilities/tracker'
import { isWorkingOffline } from '../../context/environment'
import { isReCaptchaLoaded } from '../../helpers/authentication'
import { AppButton } from '../../interface/app-button/AppButton'

const repeat = (what, times) => {
  const result = []
  for (let i = 0; i < times; i++) {
    result[i] = what()
  }
  return result
}

// View: Demo
class FaceManagerDemo extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      addImage: null,
      mainImage: null,
      additionalPhotos: null,
      additionalPhotosRefs: repeat(React.createRef, 10),
      additionalPhotosCanvasRefs: repeat(React.createRef, 10),
      additionalPhotosFigureRefs: repeat(React.createRef, 10),
      faces: null,
      recognizedFaceImages: [],
      storedFaceImages: {},
      registrationForm: React.createRef(),
      responseStatus: 'info',
      submitHandler: () => {},
      loading: false,
      responseMessage: 'Select front face to search the person in your face DB (requires login), and add additional photos to test liveness.'
    }
    this.addFace = this.addFace.bind(this)
    this.deleteFace = this.deleteFace.bind(this)
    this.displayFace = this.displayFace.bind(this)
    this.listFaces = this.listFaces.bind(this)
    this.detectFaceLiveness = this.detectFaceLiveness.bind(this)
    this.handleFiles = this.handleFiles.bind(this)
    this.validateLiveness = this.validateLiveness.bind(this)
  }

  componentDidMount () {
    if (this.props.user?.email) {
      if (isWorkingOffline()) {
        this.listFaces()
      } else if (window.grecaptcha) {
        this.listFaces()
      } else {
        if (!isReCaptchaLoaded()) {
          console.error('reCAPTCHA not loaded')
          return
        }
        setTimeout(() => window.grecaptcha.enterprise.ready(() => {
          this.listFaces()
        }), 2000)
      }
    } else if (!this.props.fetching) {
      this.context.displayModal({
        title: 'Unauthorized',
        content: 'Data cannot be fetched. Please login as an admin.',
        options: [
          {
            content: 'OK',
            action: () => this.context.redirect('/login')
          }
        ],
        isClosable: false
      })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.fetching && !this.props.fetching) {
      if (!this.props.user?.email) {
        return this.context.displayModal({
          title: 'Unauthorized',
          content: 'Data cannot be fetched. Please login as an admin.',
          options: [
            {
              content: 'OK',
              action: () => this.context.redirect('/login')
            }
          ],
          isClosable: false
        })
      }
      if (isWorkingOffline()) {
        this.listFaces()
      } else if (window.grecaptcha) {
        this.listFaces()
      } else {
        if (!isReCaptchaLoaded()) {
          console.error('reCAPTCHA not loaded')
          return
        }
        setTimeout(() => window.grecaptcha.enterprise.ready(() => {
          this.listFaces()
        }), 2000)
      }
    }
    this.drawRects()
  }

  handleFiles (accepted, rejected, section, isMainImage) {
    const additionalPhotos = this.state.additionalPhotos || []
    if (accepted && accepted.length) {
      accepted.forEach(acc => {
        getBase64({ source: acc }, (error, response) => {
          if (error) {
            this.setState({
              responseStatus: 'error',
              responseMessage: 'Cannot read the image file!'
            })
            return
          }
          if (isMainImage) {
            this.setState({
              mainImage: response
            }, this.props.user || this.state.additionalPhotos ? this.validateLiveness : null)
          } else if (section === 'test') {
            additionalPhotos.push(response)
            this.setState({
              additionalPhotos
            }, this.state.mainImage ? this.validateLiveness : null)
          } else {
            this.setState({
              addImage: response
            })
          }
        })
      })
    }
    if (rejected && rejected.length) {
      this.setState({
        responseStatus: 'error',
        responseMessage: 'Invalid file format'
      })
    }
  }

  validateLiveness () {
    if (this.state.mainImage) {
      this.setState({
        responseStatus: null,
        responseMessage: 'Processing photos...'
      }, this.detectFaceLiveness)
    }
  }

  detectFaceLiveness () {
    triggerTrackingEvent('demo-completed-facial-manager')
    reCaptcha('demo', token => {
      request({
        endpoint: '/face',
        body: {
          token,
          mainImage: this.state.mainImage,
          additionalPhotos: this.state.additionalPhotos
        }
      }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: error.message,
            responseStatus: 'error'
          })
        } else {
          if (response.message) {
            this.setState({
              responseMessage: error,
              responseStatus: 'error'
            })
          } else {
            if (response.liveness) {
              let responseMessage = ''
              if (this.props.user && this.props.user.email) {
                responseMessage += `Compared with ${(response.numberOfFacesScanned || 0).toLocaleString()} faces, ${response.person ? `matched ${response.person.name} (${response.person.uniqueId})` : 'no match found'}.`
              } else {
                responseMessage += 'Login to compare with your stored faces.'
              }
              if (this.state.additionalPhotos) {
                responseMessage += ` ${response.liveness.confidence * 100}% chance of liveness.`
              } else {
                responseMessage += ' Add additional photos to test liveness.'
              }
              this.setState({
                responseMessage,
                responseStatus: 'success',
                recognizedFaceImages: response.liveness.results || []
              })
            } else {
              this.setState({
                responseMessage: 'The liveness test did not complete.',
                responseStatus: 'warning'
              })
            }
          }
        }
      })
    })
  }

  addFace (data, errorCallback) {
    this.setState({ loading: true })
    request({
      endpoint: '/face',
      body: {
        ...data,
        image: this.state.addImage
      }
    }, (error, response) => {
      if (error) {
        errorCallback(error?.message || error.toString())
      } else {
        const faces = this.state.faces
        faces.push({
          name: data.name,
          faceId: response.faceId,
          uniqueId: data.uniqueId
        })
        faces.sort((a, b) => (a.name > b.name) ? 1 : -1)
        this.setState({
          addImage: null,
          faces
        })
        this.context.displayModal({
          title: 'Success',
          content: `${data.name} is added.`,
          options: [
            { content: 'OK' }
          ]
        })
        this.state.registrationForm.current.resetInput()
      }
      this.setState({ loading: false })
    })
  }

  deleteFace (id) {
    reCaptcha('demo', token => {
      request({
        endpoint: '/face',
        body: {
          token,
          faceId: id
        }
      }, (error, response) => {
        if (error) {
          alert('Unable to load existing faces: ' + error.message)
        } else {
          this.setState({
            faces: (this.state.faces || []).filter(f => f.faceId !== id)
          })
        }
      })
    })
  }

  displayFace (id) {
    reCaptcha('demo', token => {
      request({
        endpoint: '/face/image',
        body: {
          token,
          faceId: id
        }
      }, (error, response) => {
        if (error) {
          alert('Unable to load existing faces: ' + error.message)
        } else {
          this.setState({
            storedFaceImages: { ...this.state.storedFaceImages, ...{ [id]: response.message } }
          })
        }
      })
    })
  }

  listFaces () {
    if (!this.props.user || !this.props.user.email) {
      return
    }
    reCaptcha('demo', token => {
      request({
        endpoint: '/face',
        body: {
          token
        }
      }, (error, response) => {
        if (error) {
          alert('Unable to load existing faces: ' + error.message)
        } else {
          this.setState({
            faces: response.faces || []
          })
        }
      })
    })
  }

  drawRects () {
    this.state.recognizedFaceImages.forEach((rfi, index) => {
      const canvas = this.state.additionalPhotosCanvasRefs[index].current
      if (!canvas) {
        return
      }
      canvas.width = this.state.additionalPhotosFigureRefs[index].current.clientWidth
      canvas.height = this.state.additionalPhotosFigureRefs[index].current.clientHeight
      // const scale = this.state.additionalPhotosRefs[index].current.naturalWidth / this.state.additionalPhotosRefs[index].current.clientWidth

      const image = this.state.recognizedFaceImages[index]
      if (!image) {
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)
        return
      }

      const context = canvas.getContext('2d')
      context.beginPath()
      context.lineWidth = 3
      // context.rect(
      //   image.left / scale,
      //   image.top / scale,
      //   image.width / scale,
      //   image.height / scale
      // )
      context.rect(
        canvas.width * 0.1,
        canvas.height * 0.1,
        canvas.width * 0.8,
        canvas.height * 0.8
      )
      context.setLineDash([5, 5])
      context.strokeStyle = '#00dddd'
      context.stroke()
    })
  }

  render () {
    const {
      addImage,
      faces,
      mainImage,
      additionalPhotos,
      responseStatus,
      responseMessage
    } = this.state
    const {
      user
    } = this.props
    return (
      <View name='face-manager-demo'>
        <Header name='header'>
          <h1 className='slogan'>Store Face Data</h1>
          <h2 className='introduction'>Instantly verify repeat customers or employees with the power of AI</h2>
          <h3 className='introduction'>
            Onboard a new user with a picture, their name, and other information. Once stored, Base64.ai will assess any image against your
            secure library of known faces.
          </h3>
        </Header>
        {/* <Section
        name="test"
        >
        Add liveness detection for even more security by taking pictures from multiple profiles.
        <h2>Test face liveness</h2>
        <div className='statusBar'><StatusToEmoji status={responseStatus} message={responseMessage}/></div>
        <div className="uploadMainImageBox">
          <FileInput
            dropText="Upload a photo"
            buttonText="Upload file"
            multiple={false}
            indicator={true}
            accept={[
              'image/png',
              'image/jpg',
              'image/jpeg',
              'image/gif'
            ]}
            onDrop={(accepted, rejected, event) =>
              this.handleFiles(accepted, rejected, 'test', true)
            }/>
          {mainImage
            ? <img src={mainImage}/> : null
          }
        </div>
        <div className="uploadadditionalPhotosBox">
          <FileInput
            dropText="Upload additional photos from different angles here (optional)"
            buttonText="Upload files"
            multiple={true}
            indicator={true}
            accept={[
              'image/png',
              'image/jpg',
              'image/jpeg',
              'image/gif'
            ]}
            onDrop={(accepted, rejected, event) =>
              this.handleFiles(accepted, rejected, 'test', false)
            }/>
          {additionalPhotos && additionalPhotos.length && (additionalPhotos || []).map((additionalPhoto, index) =>
            <figure ref={this.state.additionalPhotosFigureRefs[index]} key={index}>
              <canvas ref={this.state.additionalPhotosCanvasRefs[index]}/>
              <img
                src={additionalPhoto}
                alt='Additional photo'
                ref={this.state.additionalPhotosRefs[index]}
              />
            </figure>
          )}
        </div>
        </Section> */}
        <Section
          name='add'
        >
          <h2>Register a new face</h2>
          {
            user
              ? (
                  <>
                    <div className='uploadMainImageBox'>
                      <FileInput
                        dropText='Upload a photo'
                        buttonText='Upload file'
                        multiple={false}
                        indicator={true}
                        accept={[
                          'image/png',
                          'image/jpg',
                          'image/jpeg',
                          'image/gif'
                        ]}
                        onDrop={(accepted, rejected, event) =>
                          this.handleFiles(accepted, rejected, 'add')}
                      />
                      {addImage
                        ? <img src={addImage} />
                        : null}
                    </div>
                    <Form
                      ref={this.state.registrationForm}
                      className='registrationForm'
                      title='Registration form'
                      onDataSubmit={(data, errorCallback) => {
                        reCaptcha('demo', token => {
                          data.token = token
                          this.addFace(data, errorCallback)
                        })
                      }}
                      onSubmitHandler={submitHandler => {
                        this.setState({ submitHandler })
                      }}
                    >
                      <Form.Input
                        type='text'
                        name='name'
                        reference='name'
                        placeholder="Enter the person's name"
                        validator={{
                          minimumLength: 1
                        }}
                      />
                      <Form.Input
                        type='text'
                        name='uniqueId'
                        reference='uniqueId'
                        placeholder="Enter the person's unique ID"
                        validator={{
                          minimumLength: 1
                        }}
                      />
                      <AppButton
                        className='secondary-cta large'
                        onClick={this.state.submitHandler}
                        loading={this.state.loading}
                      >
                        Register
                      </AppButton>
                    </Form>
                  </>
                )
              : <p>Login to use this feature.</p>
          }
        </Section>
        <Section
          name='delete'
        >
          <h2>Registered faces</h2>
          {
            user
              ? (
                  <>
                    {faces === null
                      ? <p>Loading...</p>
                      : (
                          faces.length === 0
                            ? <p>You have not registered faces yet.</p>
                            : (
                                <ul className='list'>
                                  {faces.map((f, idx) => {
                                    if (!this.state.storedFaceImages[`${f.faceId}`]) {
                                      this.displayFace(f.faceId)
                                    }
                                    return (
                                      <li key={f.faceId}>
                                        <a onClick={() => confirm('Are you sure to delete?') && this.deleteFace(f.faceId)}><img src={this.state.storedFaceImages[`${f.faceId}`]} /><br />{f.name}</a>
                                      </li>
                                    )
                                  })}
                                </ul>
                              )
                        )}
                  </>
                )
              : <p>Login to use this feature.</p>
          }
        </Section>
      </View>
    )
  }
}

FaceManagerDemo.propTypes = {
  user: PropTypes.object,
  fetching: PropTypes.bool
}

// Export
export default FaceManagerDemo
