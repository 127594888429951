// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'

// Animations
import DataExtraction from '~/animations/data-extraction/DataExtraction'
import FeaturesSection from '~/content/features-section/FeaturesSection'

// Data
import types from '~/data/dataExtractionTypes'

// Interface
import OptionalImage from '~/interface/optional-image/OptionalImage'
import Header from '~/layout/header/Header'
import DynamicTitle from '~/interface/dynamic-title/DynamicTitle'
import DemoButton from '~/interface/demo-button/DemoButton'

// Layout
import Section from '~/layout/section/Section'
import BenefitsSection from '../../../content/benefits-section/BenefitsSection'
import CaseStudiesSection from '~/content/case-studies-section/CaseStudiesSection'
import DemoLinksSection from '../../../content/demo-links-section/DemoLinksSection'
import { AppButton } from '../../../interface/app-button/AppButton'

// View: DataExtractionAPI
class DataExtractionAPI extends Component {
  static propTypes = {
    type: PropTypes.string
  }

  render () {
    let { type } = this.props
    const allTypes = Object.keys(types)
    if (!allTypes.includes(type)) {
      try {
        const m = allTypes.filter(at => at.match(type) || type.match(at))
        if (!m.length) {
          type = ''
        } else {
          type = m[0]
        }
      } catch (e) {
        type = ''
      }
    }
    const name = type ? types[type].name.replace('&', ' & ') : ''
    return (
      <>
        {type
          ? (
              <>
                <DynamicTitle title={`${name} feature`} />
                {/* Header: Presentation */}
                <Header
                  name='presentation'
                  className='with-visual'
                >
                  <h1 className='slogan'>
                    {
                      types[type].alternativeTitle
                        ? (
                            <>
                              <small>{types[type].alternativeTitle}</small>
                              {types[type].brandName || types[type].name}
                            </>
                          )
                        : types[type].subDocument
                          ? (
                              <>
                                <small style={{ display: 'inline' }}>Extract </small>
                                <b>{name}</b>
                                <small>from your Documents</small>
                              </>
                            )
                          : (
                              <>
                                <small>Extract Data From</small>
                        &nbsp;
                                <b>{name}</b>
                              </>
                            )
                    }
                  </h1>
                  <p className='description'>{types[type].description}</p>
                  {
                    types[type].headerImage
                      ? types[type].isAnimated
                        ? (
                            <DataExtraction
                              image={`/static/content/features/data-extraction/samples/${type}.png`}
                              items={types[type].content}
                            />
                          )
                        : <OptionalImage imageUrl={`/static/content/features/data-extraction/covers/${type}.png`} />
                      : <div style={{ height: '3rem' }}></div>
                  }
                  {
                    types[type].youtube
                      ? (
                          <div className='dataExtractionVideoEmbed'>
                            <iframe
                              width='560'
                              height='315'
                              src={`https://www.youtube.com/embed/${types[type].youtube}?cc_load_policy=1`}
                              title='YouTube video player'
                              frameBorder='0'
                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                              allowFullScreen
                            />
                          </div>
                        )
                      : <></>
                  }
                  {types[type].list && (
                    <div className='list'>
                      <h2 className='listHeader'>{types[type].listHeader}</h2>
                      <ul>{ types[type].list.map((item, i) => <li key={i}>{item}</li>) }</ul>
                    </div>
                  )}
                </Header>
                {/* Section: Try Demo */}
                {(types[type].model || types[type].demo)
                && (
                  <Section
                    name='demonstration'
                    align='center'
                  >
                    <h2 className='introduction'>Try now</h2>
                    <h3 className='introduction'>{types[type]?.demoText || `Experience our AI on ${name} and other types of documents now!`}</h3>
                    <DemoButton type={types[type]} />
                  </Section>
                )}
                {/* Section: Demo links */}
                {types[type].demoLinks
                && <DemoLinksSection type={types[type]}></DemoLinksSection>}
                {/* Section: Benefits */}
                <BenefitsSection type={type} />
                {/* Section: Explanatory text */}
                {types[type].text
                && types[type].text.map((paragraph, id) => (
                  <Section key={id} name='text' align='center'>
                    <h2 className='title' style={{ marginBottom: '2.4rem' }}>{paragraph.title}</h2>
                    {paragraph.content.map((part, id) =>
                      <h3 key={id} className='introduction' style={{ marginBottom: '1.8rem' }}>{part}</h3>
                    )}
                  </Section>
                )
                )}
                {/* Section: Case Study */}
                <CaseStudiesSection type={type} />
                {/* Section: Blog posts */}
                {
                  types[type].blogPost
                  && (
                    <Section
                      name='blog-posts'
                      align='center'
                    >
                      <h2 className='title'>Blog posts</h2>
                      <h3 className='introduction'>Read our blog about {types[type].name}!</h3>
                      <AppButton
                        href={`/news/${types[type].blogPost}`}
                        className='secondary-cta large'
                      >
                        Read more
                      </AppButton>
                    </Section>
                  )
                }
                {/* Section: Demonstration */}
              </>
            )
          : (
              <Header name='feature'>
                <h1 className='slogan'>
                  <b>Data Extraction API</b>
                </h1>
                <p className='description'>
                  Our multi-layer artificial intelligence extracts the right data from the right place.
                  <br />
                  We enhance blurry and misaligned images. We cleanup and validate the results.
                  <br />
                  We assign a confidence score for the document type, extracted texts and images.
                  <br />
                  We normalize dates and translate technical codes into human-readable words.
                </p>
              </Header>
            )}
        {/* Section: Features */}
        <FeaturesSection />
      </>
    )
  }
}

// Export
export default DataExtractionAPI
