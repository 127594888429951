export const isWorkingOffline = () => {
  if (typeof window !== 'undefined') {
    return window.workOffline
  }
  if (typeof global !== 'undefined') {
    return global.workOffline
  }
}

export const hasFeature = feature => {
  if (!feature || !isWorkingOffline()) {
    return true
  }
  if (typeof window !== 'undefined') {
    if (!window.features) {
      return true
    }
    if (!Object.keys(window.features).includes(feature)) {
      console.error('Invalid feature key', feature)
      return true
    }
    return !!window.features[feature]
  }
  if (typeof global !== 'undefined') {
    if (!global.features) {
      return true
    }
    if (!Object.keys(global.features).includes(feature)) {
      console.error('Invalid feature key', feature)
      return true
    }
    return !!global.features[feature]
  }
}

export const hasDatabase = () => {
  return hasFeature('databaseServer')
}

export const hasLogin = () => {
  return hasDatabase() || hasFeature('staticUser')
}

export const isProduction = () => {
  if (typeof window !== 'undefined') {
    return window.location.hostname === 'base64.ai'
  }
  if (typeof global !== 'undefined') {
    return !process.env.BASE64AI_LOCAL_DEV_ENV
  }
}

export const isSSR = () => {
  if (typeof global !== 'undefined') {
    return true
  }
}

export const isBlueWashed = () => {
  if (typeof window !== 'undefined') {
    return window.onPremBrand && window.onPremBrand !== 'Base64.ai'
  }
  if (typeof global !== 'undefined') {
    return process.env.ON_PREM_BRAND && process.env.ON_PREM_BRAND !== 'Base64.ai'
  }
}
