import { Component } from 'react'
import PropTypes from 'prop-types'
import window from 'global/window'
import document from 'global/document'

class SelectionArrow extends Component {
  static propTypes = {
    context: PropTypes.object
  }

  static defaultProps = {
    context: {}
  }

  constructor (props) {
    super(props)
    this.state = {
      startPosition: { x: 0, y: 0 },
      endPosition: { x: 0, y: 0 },
      width: 0,
      height: this.props.context?.canvasHeight || 0,
      offsetTop: this.props.context?.canvasOffsetTop || 0
    }
    this.setArrowPositions = this.setArrowPositions.bind(this)
  }

  componentDidMount () {
    this.setArrowPositions()
    this.setState({
      width: window.innerWidth
    })
    window.addEventListener('scroll', this.setArrowPositions)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.setArrowPositions)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.context !== this.props.context) {
      this.setArrowPositions()
      const { canvasHeight, canvasOffsetTop } = this.props.context

      canvasHeight && this.setState({ height: canvasHeight })
      canvasOffsetTop && this.setState({ offsetTop: canvasOffsetTop })
    }
  }

  setArrowPositions () {
    const {
      selectionDomId,
      selectionCanvasLocation,
      resultViewerScrollOffsetTop
    } = this.props.context
    if (!selectionDomId || !selectionCanvasLocation) {
      return
    }
    const startPointDom = document.getElementById(selectionDomId)
    if (!startPointDom) {
      return
    }
    const startPositionPadding = window.getComputedStyle(startPointDom).getPropertyValue('padding')
    const startPositionPaddingValue = startPositionPadding ? parseInt(startPositionPadding.replace('px', '')) : 0
    const startPositionMargin = window.getComputedStyle(startPointDom).getPropertyValue('margin')
    const startPositionMarginValue = startPositionMargin ? parseInt(startPositionMargin.replace('px', '')) : 0

    const state = {
      endPosition: { x: selectionCanvasLocation.x - 5, y: selectionCanvasLocation.y + this.state.offsetTop },
      startPosition: {
        x: startPointDom.offsetLeft + startPositionPaddingValue + 5,
        y: startPointDom.offsetTop - this.state.offsetTop + startPointDom.offsetHeight / 2 - (resultViewerScrollOffsetTop || 0)
      }
    }
    const popupDom = document.querySelector('.popup-component > .popup-container')
    if (this.props.context.selectionContext === 'table' && popupDom) {
      const containerPositionX = popupDom.offsetTop - this.state.offsetTop + window.scrollY
      const innerScrollTop = this.props.context.movingPopupInnerScrollTop || 0
      const innerScrollLeft = this.props.context.movingPopupInnerScrollLeft || 0
      state.startPosition.x = popupDom.offsetLeft + startPointDom.offsetLeft + startPositionMarginValue + startPositionPaddingValue - innerScrollLeft - 4
      state.startPosition.y = containerPositionX + startPointDom.offsetTop - innerScrollTop + startPositionMarginValue + startPointDom.parentElement.offsetHeight / 2 + 2
      if (state.startPosition.y < containerPositionX) {
        state.startPosition.y = containerPositionX
      }
      if (state.startPosition.y > containerPositionX + popupDom.offsetHeight) {
        state.startPosition.y = containerPositionX + popupDom.offsetHeight
      }
      if (state.startPosition.x < popupDom.offsetLeft) {
        state.startPosition.x = popupDom.offsetLeft
      }
      if (state.startPosition.x > popupDom.offsetLeft + popupDom.offsetWidth) {
        state.startPosition.x = popupDom.offsetLeft + popupDom.offsetWidth
      }
    }
    this.setState(state)
    if (this.state.scrollDomID === selectionDomId || this.props.context.selectionContext === 'table') {
      window.scrollUpdated = false
      return true
    }
    if (!window.scrollUpdated && (state.endPosition.y - 50 < window.scrollY || state.endPosition.y > window.scrollY + window.innerHeight - this.state.offsetTop)) {
      window.scrollUpdated = true
      window.scrollTo({ top: state.endPosition.y - 300 })
    }
    const diff = state.startPosition.y - state.endPosition.y
    const sign = diff < 0 ? 1 : -1
    const step = Math.max(Math.abs(diff) * 0.1, 1)
    if ((Math.abs(diff) <= window.innerHeight / 2 && Math.abs(diff) >= 15) || Math.abs(diff) < 0.95) {
      this.setState({ scrollDomID: selectionDomId })
      return true
    }
    setTimeout(() => {
      const dom = document.querySelector('.result-viewer-container')
      if (dom) {
        dom.scrollTo({ top: dom.scrollTop - sign * step })
      }
    })
    return true
  }

  render () {
    const { isSelected, selectionDomId, selectedDataReference } = this.props.context
    let height = null
    if (isSelected) {
      height = this.props.context.selectionContext === 'table'
        ? document.documentElement.scrollHeight - this.state.offsetTop
        : window.innerHeight * 0.75 > this.state.height ? window.innerHeight * 0.75 : this.state.height
    }
    return (
      <div className='selection-arrow-container' style={{ height: '0px', top: this.state.offsetTop + 'px', zIndex: this.props.context.selectionContext === 'table' ? '999999' : '9' }}>
        { isSelected && selectionDomId !== selectedDataReference
          ? (
              <svg viewBox={`0 0 ${this.state.width} ${height}`} style={{ height: height + 'px' }}>
                <line
                  className='marker-line'
                  x1={this.state.startPosition.x}
                  y1={this.state.startPosition.y}
                  x2={this.state.endPosition.x}
                  y2={this.state.endPosition.y}
                  strokeWidth='2'
                  strokeDasharray='4'
                  stroke='#00dddd'
                />
              </svg>
            )
          : <></>}
      </div>
    )
  }
}

export default SelectionArrow
