export const mergeBoxDict = (b1, b2) => {
  if (!b1 || !b2 || b1.pageNumber !== b2.pageNumber) {
    return b1 || b2
  }
  const b = {
    top: Math.min(b1.top, b2.top),
    left: Math.min(b1.left, b2.left),
    right: Math.max(b1.right, b2.right),
    bottom: Math.max(b1.bottom, b2.bottom)
  }
  b.width = b.right - b.left
  b.height = b.bottom - b.top
  b.pageNumber = b1.pageNumber
  return b
}

export const mergeLocation = (l1, l2) => {
  if (!l1 || !l2 || l1.pageNumber !== l2.pageNumber) {
    return l1 || l2
  }
  const l = {
    pageNumber: l1.pageNumber,
    topLeft: {
      x: Math.min(l1.topLeft.x, l2.topLeft.x),
      y: Math.min(l1.topLeft.y, l2.topLeft.y)
    },
    topRight: {
      x: Math.max(l1.topRight.x, l2.topRight.x),
      y: Math.min(l1.topRight.y, l2.topRight.y)
    },
    bottomRight: {
      x: Math.max(l1.bottomRight.x, l2.bottomRight.x),
      y: Math.max(l1.bottomRight.y, l2.bottomRight.y)
    },
    bottomLeft: {
      x: Math.min(l1.bottomLeft.x, l2.bottomLeft.x),
      y: Math.max(l1.bottomLeft.y, l2.bottomLeft.y)
    }
  }
  return l
}
