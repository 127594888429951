// Modules
import { forwardRef } from 'react'
import SocialIcon from '~/interface/social-icon/SocialIcon'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import window from 'global/window'
import MaterialIcon from '~/interface/material-icon/MaterialIcon'
// Layout: Footer
const Footer = forwardRef((props, ref) => (
  <footer
    ref={ref}
    className='footer'
  >
    {/* do not show contact if we are in /contact */}
    {/* {window?.location?.pathname !== '/contact' && <ContactSection />} */}
    <div className='fancy-div'></div>

    <div className='container'>
      <ul
        data-list='copyright'
        className='copyright menu'
      >
        <li>
          <a
            href='/'
            className='brand'
          >
            <img className='symbol' src='/static/images/brand-logo-navbar-light.png' alt='Base64.ai' />
          </a>
        </li>
        <li>
          {/* <SocialIcon href='mailto:support@base64.ai' title='Send e-mail to support@base64.ai' media='email' /> */}
          <SocialIcon href='https://base64.ai/linkedin' title="Visit Base64.ai's LinkedIn page" media='linkedin' />
          <SocialIcon href='https://base64.ai/youtube' title="Visit Base64.ai's YouTube channel" media='youtube' />
          <SocialIcon href='https://base64.ai/twitter' title="Visit Base64.ai's X (Twitter page)" media='x' />
          <SocialIcon href='https://base64.ai/instagram' title="Visit Base64.ai's Instagram page" media='instagram' />
          <SocialIcon href='https://base64.ai/facebook' title="Visit Base64.ai's Facebook page" media='facebook' />
          <SocialIcon href='https://base64.ai/vimeo' title="Visit Base64.ai's Vimeo channel" media='vimeo' />
          <SocialIcon href='https://base64.ai/github' title="Visit Base64.ai's Github page" media='github' />
        </li>
        <li>
          <p className='copyright'>Copyright &copy; {new Date().getFullYear()} Base64.ai, Inc.</p>
        </li>
        <li>
          <a href='https://maps.google.com/maps?q=Base64.ai' target='_blank' rel='noopener noreferrer'>
            244 Madison Ave, Suite 1124<br />
            New York, NY 10016<br />
            USA
          </a>
          <CopyToClipboard
            text='Base64.ai, Inc 244 Madison Ave, Suite 1124 New York, NY 10016 USA'
            onCopy={() => {
              window.alert('Copied to your clipboard!')
            }}
          >
            <MaterialIcon
              name='content_copy'
              style={{
                fontSize: '0.8rem',
                marginLeft: '0.25rem',
                fontVariationSettings: '\'FILL\' 0,\'wght\' 200,\'GRAD\' 0,\'opsz\' 48'
              }}
            >
            </MaterialIcon>
          </CopyToClipboard>
        </li>
      </ul>
      <ul
        data-list='links'
        className='right menu'
      >
        <li className='header'>
          <b>About</b>
        </li>

        <li>
          <a href='/about/'>About Base64</a>
        </li>
        <li>
          <a href='/patents/'>Patents</a>
        </li>
        <li>
          <a
            href='https://www.linkedin.com/company/base64ai/jobs/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Careers
          </a>
        </li>
        <li>
          <a href='/compliance'>Compliance <nobr>(ISO, HIPAA, SOC 2, GDPR)</nobr></a>
        </li>
      </ul>

      <ul
        data-list='policies'
        className='right menu'
      >
        <li className='header'>
          <b>Policies</b>
        </li>
        <li>
          <a href='/privacy-policy'>Privacy policy</a>
        </li>
        {/* */}
        <li>
          <a href='/terms-of-service'>Terms of service</a>
        </li>
        <li>
          <a href='/acceptable-use-policy'>Acceptable use policy</a>
        </li>
      </ul>

      <ul
        data-list='resources'
        className='right menu'
      >
        <li className='header'>
          <b>Support</b>
        </li>
        <li>
          <a
            href='https://help.base64.ai'
            target='_blank'
            rel='noopener noreferrer'
          >
            Help center
          </a>
        </li>
        <li>
          <a
            href='https://base64.ai/api/doc'
            target='_blank'
            rel='noopener noreferrer'
          >
            API documentation
          </a>
        </li>
        <li>
          <a href='/contact/'>Contact us</a>
        </li>
      </ul>
    </div>
  </footer>
))

Footer.displayName = 'Footer'

// Export
export default Footer
