// Interface
import Link from '~/interface/link/Link'

// Data
import solutionTypes from '~/data/solutionTypes'

// Layout
import Section from '~/layout/section/Section'
import { hasDatabase } from '../../context/environment'

// Content: SolutionsSection
const SolutionsSection = props => (
  hasDatabase()
  && (
    <Section
      name='solutions'
      align='center'
      identifier
    >
      <h2 className='title'>We can process all types of documents</h2>
      <p className='introduction'>
        Cutting-edge artificial intelligence is ready to pick up manual data entry work.<br />Just select your industry to learn how we can help your business.
      </p>
      <ul className='list'>
        {Object.keys(solutionTypes).map(type => (
          <li key={type}>
            <Link to={`/solutions/${type}`}>
              {solutionTypes[type].name}
            </Link>
          </li>
        )
        )}
      </ul>
    </Section>
  )
)

// Export
export default SolutionsSection
