// Modules
import { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, withRouter } from 'react-router-dom'

// Context
import Context from '~/context/global'

// Layout
import View from '~/layout/view/View'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'

// Helpers
import { request } from '~/helpers/request'

// Styles
/* SSR-IGNORE */ import 'react-day-picker/lib/style.css'
import UsageSection from '~/content/usage-section/UsageSection'
import document from 'global/document'
import MultiEmail from '~/interface/multi-email/MultiEmail'
import StatDisplaySection from '../../content/chart-display-section/stat-display-section/StatDisplaySection'
import { AppButton } from '../../interface/app-button/AppButton'

// View: Usage
class Usage extends Component {
  static contextType = Context

  static propTypes = {
    user: PropTypes.object,
    filter: PropTypes.string,
    limit: PropTypes.string,
    models: PropTypes.object,
    getModels: PropTypes.func,
    history: PropTypes.object
  }

  static defaultProps = {
    item: 'this-month'
  }

  constructor (props) {
    super(props)
    this.state = {
      user: this.props.user,
      data: null,
      loading: false,
      activeFilter: null,
      displayMode: 'day',
      customLimits: {}
    }

    this.configuration = {
      filters: [
        { value: 'this-year', label: 'This year' },
        { value: 'this-month', label: 'This month' },
        { value: 'this-week', label: 'This week' },
        { value: 'last-month', label: 'Last month' },
        { value: 'last-week', label: 'Last week' },
        { value: 'custom', label: 'Custom date' }
      ],
      defaultFilter: 'this-month'
    }
    this.setDimensions = this.setDimensions.bind(this)
  }

  componentDidMount () {
    this.showAll = window.location.href.includes('showAll')
    this.setDimensions()
    window.addEventListener('resize', this.setDimensions)
    this.props.getModels()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user
      })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setDimensions)
  }

  setDimensions () {
    const windowWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    const maximumWidth = 960
    let horizontalPortion = 0.75
    let verticalPortion = 1 / 3
    if (windowWidth < 480) {
      horizontalPortion = 0.9
      verticalPortion = 2 / 3
    } else if (windowWidth < 1024) {
      horizontalPortion = 0.85
      verticalPortion = 1 / 2
    }
    const width = Math.min(
      Math.floor(
        windowWidth * horizontalPortion
      ),
      maximumWidth
    )
    const height = Math.floor(
      width * verticalPortion
    )
    this.setState({
      dimensions: { width, height }
    })
  }

  render () {
    const { filter } = this.props
    let { user } = this.state
    const { filters, defaultFilter } = this.configuration

    user = user || {}
    user.tags = user.tags || []
    const title = this.showAll && user.tags.includes('admin') ? 'Our API usage' : 'Your API usage'
    return filter && filters.map(filter => filter.value).includes(filter)
      ? (
          <View name='usage'>
            <Header>
              <>
                <h1 className='title'>{title}</h1>
                {((!this.showAll && user.subscriptionType !== 'enterprise') || (user?.originalUserEmail && user.originalUserEmail !== user.email)) && (
                  <div className='usageTable'>
                    <UsageSection user={this.state.user} />
                    <AppButton className='secondary-cta large' href='/pricing'>Buy credits</AppButton>
                  </div>
                )}
                {user.subscriptionType === 'enterprise' && <h2 className='introduction'>You are on an invoice plan.</h2>}
              </>
            </Header>
            <StatDisplaySection
              filter={this.props.filter}
              limit={this.props.limit}
              models={this.props.models}
              getModels={this.props.getModels}
              history={this.props.history}
              currentEndpoint='/usage'
            />
            {
              this.state.user && this.state.user.email
                ? (
                    <Section
                      name='recipients'
                      align='center'
                    >
                      <p className='info'>Your weekly usage emails are delivered to <span className='highlight'>{user.email}</span></p>
                      <div className='additional-recipients'>
                        <p>Additional email recipients:</p>
                        <MultiEmail
                          // width= "720px"
                          emails={user && user.ccEmails}
                          onChange={emails => {
                            user.ccEmails = emails
                          }}
                          placeholder='Please enter valid emails...'
                        />
                        <AppButton
                          className='secondary-cta large'
                          onClick={() => {
                            request({
                              endpoint: '/auth/user',
                              body: {
                                ccEmails: user.ccEmails || []
                              },
                              method: 'POST'
                            }, error => {
                              if (error) {
                                this.context.displayModal({
                                  title: 'Error',
                                  content: 'Could not update the CC email list.',
                                  options: [
                                    { content: 'OK', action: () => this.context.redirect('/login') }
                                  ]
                                })
                              } else {
                                this.context.displayModal({
                                  title: 'Success',
                                  content: 'CC email list has been updated.',
                                  options: [
                                    { content: 'OK' }
                                  ]
                                })
                              }
                            })
                          }}
                        >
                          Update
                        </AppButton>
                      </div>
                    </Section>
                  )
                : <></>
            }
          </View>
        )
      : <Redirect to={`/usage/${defaultFilter}`} />
  }
}

// Export within router
export default withRouter(Usage)
