import MaterialIcon from '../material-icon/MaterialIcon'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'

const RetryButton = ({ onRetry, retryCount = 0, title }) => {
  const handleRetry = async () => {
    onRetry?.()
  }

  return (

    <Tippy
      content={retryCount ? `Retried ${retryCount} times` : 'Retry to get a different answer'}
      placement='top'
      animation='shift-away'
      arrow={false}
    >
      <MaterialIcon
        title={title || ''}
        name='refresh'
        className='hover-blue'
        onClick={handleRetry}
      />
    </Tippy>
  )
}

RetryButton.propTypes = {
  onRetry: PropTypes.func,
  retryCount: PropTypes.number,
  title: PropTypes.string
}

export default RetryButton
