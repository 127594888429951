export const isMarkdownTable = str => {
  if (typeof str !== 'string' || str.trim() === '') {
    return false
  }

  const lines = str.trim().split('\n')

  // A markdown table should have at least 2 lines (header and separator)
  if (lines.length < 2) {
    return false
  }

  // Remove the separator line if it's at index 1
  if (lines[1]?.match(/^[-| ]+$/)) {
    lines.splice(1, 1)
  }

  return lines[0].split('|').length === lines[1].split('|').length
}

export const parseMarkdownTable = markdownTable => {
  if (!isMarkdownTable(markdownTable)) {
    return []
  }

  const lines = markdownTable.trim().split('\n')
  const hasLeadingPipe = lines[0].startsWith('|')
  // Remove the separator line if it's at index 1
  if (lines[1]?.match(/^[-| ]+$/)) {
    lines.splice(1, 1)
  }
  const result = lines
    .map(line =>
      line
        .trim()
        .slice(hasLeadingPipe ? 1 : 0, hasLeadingPipe ? line.length - 1 : line.length) // Remove leading and trailing '|' if hasLeadingPipe is true
        .split(/(?<!\\)\|/) // Split by '|' but not by '\|'
        .map(cell => cell.trim().replace(/\\(?=\|)/g, '')) // Remove '\' before '|'
    )

  return result
}
