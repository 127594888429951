// Modules
import { forwardRef } from 'react'
import PropTypes from 'prop-types'

// Layout: Header
const Header = forwardRef((props, ref) => (
  <header
    data-header={props.name}
    ref={ref}
    id={
      props.identifier
        ? props.name
        : null
    }
    className={
      (props.className ? `${props.className} ` : '')
      + (props.align ? `${props.align} aligned ` : '')
      + (props.tight ? 'tight ' : '')
      + 'header'
    }
  >
    <div className='container'>
      {props.children}
    </div>
  </header>
))

Header.displayName = 'Header'

Header.propTypes = {
  name: PropTypes.string,
  align: PropTypes.string,
  tight: PropTypes.bool,
  identifier: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
}

Header.defaultProps = {
  tight: false,
  identifier: false
}

// Export
export default Header
