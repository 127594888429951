import flowSearchKeyFields from './flowSearchKeyFields'

export default {
  columns: [
    {
      columnName: 'Key',
      inputType: 'creatable-select',
      fieldName: 'key',
      placeholder: 'Enter key name...',
      options: flowSearchKeyFields,
      defaultValue: {
        label: '',
        value: ''
      },
      isMulti: false,
      isCamelCase: true
    },
    {
      columnName: 'Criteria',
      inputType: 'select',
      fieldName: 'criteria',
      options: [
        { label: '=', value: '$eq' },
        { label: '!=', value: '$ne' },
        { label: 'in', value: '$in' },
        { label: 'not in', value: '$nin' },
        { label: '<=', value: '$lte' },
        { label: '<', value: '$lt' },
        { label: '>=', value: '$gte' },
        { label: '>', value: '$gt' }
      ],
      defaultValue: '$eq',
      isMulti: false
    },
    {
      columnName: 'Value',
      fieldName: 'value',
      inputType: 'text',
      placeholder: 'Enter value...',
      defaultValue: ''
    }
  ],
  addButtonText: 'Add new criteria',
  addButtonTitle: 'Add new criteria to filter documents precisely with their content.',
  isDeleteButtonEnabled: true,
  className: 'four-column flow-search'
}
