import { Component } from 'react'

// Interface
import FileInput from '~/interface/file-input/FileInput'
import StatusToEmoji from '~/interface/validation-sign/StatusToEmoji'
import Checkbox from '~/interface/checkbox/Checkbox'
import CreatableSelect from 'react-select/creatable'
import window from 'global'

// Layout
import View from '~/layout/view/View'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'

// Helpers
import { reCaptcha } from '~/helpers/authentication'
import { getBase64 } from '~/helpers/file'
import { request } from '~/helpers/request'

// Utilities
import { triggerTrackingEvent } from '../../utilities/tracker'
import LoadingCircle from '../../interface/loading-circle/LoadingCircle'
import { AppButton } from '../../interface/app-button/AppButton'

class RedactionDemo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      responseMessage: 'Upload a photo and select fields to anonymize.',
      responseStatus: '',
      redactedDocument: null,
      imageBase64: null,
      imageUrl: null,
      fields: [
        { label: 'Name', value: 'name' },
        { label: 'License number', value: 'licenseNumber' }
      ],
      selections: { redactFaces: true, redactSignatures: true, redactMRZ: false }
    }

    this.defaultOptions = [
      { label: 'Name', value: 'name' },
      { label: 'Given name', value: 'givenName' },
      { label: 'Family name', value: 'familyName' },
      { label: 'Organization', value: 'organization' },
      { label: 'License number', value: 'licenseNumber' },
      { label: 'Document number', value: 'documentNumber' },
      { label: 'Address', value: 'address' },
      { label: 'Date', value: 'date' },
      { label: 'Date of birth', value: 'dateOfBirth' },
      { label: 'Issue date', value: 'issueDate' },
      { label: 'Expiration date', value: 'expirationDate' },
      { label: 'VIN', value: 'vin' },
      { label: 'Total', value: 'total' },
      { label: 'Tax', value: 'tax' },
      { label: 'Any number (use regex \\d+)', value: '\\d+' },
      { label: 'Any letter (use regex [a-z]+)', value: '[a-z]+' }
    ]

    this.handleChange = this.handleChange.bind(this)
    this.onCheckBoxChange = this.onCheckBoxChange.bind(this)
    this.handleFiles = this.handleFiles.bind(this)
  }

  showSampleLink (status) {
    if (status === '' || status === 'success') {
      return (
        <a
          className='showSample'
          onClick={() => {
            this.setState({
              responseMessage: 'Select fields to anonymize.',
              responseStatus: 'info',
              redactedDocument: null,
              imageUrl: window.location.origin + '/static/content/features/data-extraction/models/driver_license/usa/ca/1.png',
              imageBase64: null
            }, () => {
              this.makeRequest()
              window.scrollTo({ top: 1050, behavior: 'smooth' })
            })
          }}
        >{status === '' ? 'Show sample' : 'Restart with sample'}
        </a>
      )
    } else {
      return null
    }
  }

  setInput (name, value) {
    const selections = this.state.selections
    selections[name] = value
    this.setState({ selections })
  }

  onCheckBoxChange (data) {
    this.setInput(data.value, data.checked)
  }

  handleChange (value, action) {
    this.setState({ fields: value })
  }

  handleFiles (accepted, rejected, isReference) {
    if (accepted && accepted.length) {
      getBase64({ source: accepted[0] }, (error, response) => {
        if (error) {
          this.setState({
            responseStatus: 'error',
            responseMessage: 'Cannot read the image file!'
          })
          return
        }
        this.setState({
          imageBase64: response,
          responseMessage: 'Select fields to anonymize.'
        })
      })
    }
    if (rejected && rejected.length) {
      this.setState({
        responseStatus: 'error',
        responseMessage: 'Invalid file format'
      })
    }
  }

  makeRequest = () => {
    triggerTrackingEvent('demo-completed-redaction')

    this.setState({
      responseStatus: 'info',
      responseMessage: 'Detecting and anonymizing fields...',
      redactedDocument: null
    })

    reCaptcha('demo', token => {
      const documentOrUrl = this.state.imageBase64 ? { document: this.state.imageBase64 } : { url: this.state.imageUrl }
      request({
        endpoint: '/scan',
        body: {
          token,
          ...documentOrUrl,
          settings: {
            redactions: {
              fields: this.state.fields.map(it => it.value),
              faces: this.state.selections.redactFaces,
              mrz: this.state.selections.redactMRZ,
              signatures: this.state.selections.redactSignatures
            }
          }
        }
      }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: error.message + '.',
            responseStatus: 'error'
          })
          return
        }
        if (response.message) {
          this.setState({
            responseMessage: response.message,
            responseStatus: 'error'
          })
          return
        }
        const result = response[0]
        if (result.uuid) {
          this.setState({
            responseMessage: 'Image succesfully redacted.',
            responseStatus: 'success',
            redactedDocument: result.redactedDocument
          })
        }
      }, err => {
        this.setState({
          responseMessage: 'Error happened while processing: ' + err.message + '.',
          responseStatus: 'error'
        })
      })
    })
  }

  render () {
    const {
      responseMessage,
      responseStatus,
      imageBase64,
      imageUrl,
      redactedDocument,
      fields,
      selections
    } = this.state

    return (
      <View name='redaction-demo'>
        <Header name='header'>
          <h1 className='slogan'>Redaction AI</h1>
          <h2 className='introduction'>Detect and remove personal and sensitive information<br /> from documents and images</h2>
          <div className='redactionDemoVideoEmbed'>
            <iframe
              width='560'
              height='315'
              src='https://www.youtube.com/embed/3beufvTnW3E?cc_load_policy=1'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </div>
          <h3 className='introduction'>
            Built on our ability to extract data from any document, Base64.ai Redaction AI irreversibly removes PII and sensitive information like names,
            dates, faces, signatures, addresses, and more, ensuring that the data is shared on need-to-know basis.
            Try out our free demo and see for yourself how it can help with customer data security.
          </h3>
        </Header>
        <Section name='input' tight={true}>
          <div style={{ height: '2.25rem' }}>
            <StatusToEmoji status={responseStatus} message={responseMessage} />
            &nbsp;
            {this.showSampleLink(responseStatus)}
          </div>
          <div className='uploadBox'>
            <FileInput
              dropText='Upload a photo'
              multiple={false}
              indicator={true}
              accept={[
                'image/png',
                'image/jpg',
                'image/jpeg',
                'image/gif'
              ]}
              style={{ height: '72px', padding: '0.5rem' }}
              onDrop={(accepted, rejected, event) => {
                this.handleFiles(accepted, rejected)
                this.setState({
                  responseMessage: 'Select fields to anonymize and upload a photo.',
                  responseStatus: 'info',
                  redactedDocument: null
                })
              }}
            />
          </div>
          <div className='check-box-container'>
            <Checkbox
              key={0}
              className='big left'
              placeholder='Redact faces'
              onChange={e => {
                this.onCheckBoxChange(e.target)
              }}
              checked={selections.redactFaces}
              value='redactFaces'
            />
            <Checkbox
              key={1}
              className='big'
              placeholder='Redact signatures'
              onChange={e => {
                this.onCheckBoxChange(e.target)
              }}
              checked={selections.redactSignatures}
              value='redactSignatures'
            />
            <Checkbox
              key={2}
              className='big'
              placeholder='Redact MRZ'
              onChange={e => {
                this.onCheckBoxChange(e.target)
              }}
              checked={selections.redactMRZ}
              value='redactMRZ'
            />
          </div>
          <div className='selectionContainer'>
            <CreatableSelect
              isMulti
              className='react-select'
              classNamePrefix='react-select'
              value={fields}
              placeholder='Please enter fields...'
              closeMenuOnSelect={false}
              onChange={this.handleChange}
              options={this.defaultOptions}
            />
            <AppButton
              className='secondary-cta large min-w-56'
              id='redactButton'
              onClick={() => {
                this.makeRequest()
              }}
              disabled={(responseMessage === 'Detecting and anonymizing fields...' || (imageBase64 === null && imageUrl === null))}
            >
              Redact Document
            </AppButton>
          </div>
        </Section>
        <Section name='output'>
          {imageBase64 || imageUrl
            ? (
                <div className='outputContainer'>
                  <div className='imageContainer'>
                    <h2 className='outputTitle'>Original Document</h2>
                    <img src={imageBase64 || imageUrl} />
                  </div>
                  { responseMessage === 'Detecting and anonymizing fields...'
                    ? <LoadingCircle />
                    : redactedDocument
                      ? (
                          <div className='imageContainer'>
                            <h2 className='outputTitle'>Redacted Document</h2>
                            <img src={redactedDocument} />
                          </div>
                        )
                      : null}
                </div>
              )
            : null}
        </Section>
      </View>
    )
  }
}

export default RedactionDemo
