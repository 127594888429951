import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { request } from '~/helpers/request'
import SortableTable from 'react-sortable-table'
import Section from '../../layout/section/Section'
import Header from '../../layout/header/Header'
import View from '../../layout/view/View'
// Context
import Context from '~/context/global'
import window from 'global'
import { toIsoDate } from '../../utilities/format'
import LoadingModal from '../../interface/loading-modal/LoadingModal'
import BackButton from '../../interface/back-button/BackButton'
import CreateFromTemplate from '../create-from-template/CreateFromTemplate'
import { AppDropdown } from '../../interface/app-dropdown/AppDropdown'
import { AppButton } from '../../interface/app-button/AppButton'

class ListCustomModel extends Component {
  static contextType = Context

  static propTypes = {
    user: PropTypes.object
  }

  HEADER_STYLE = {
    padding: '0.5rem',
    whiteSpace: 'nowrap',
    margin: '0.1rem',
    backgroundColor: 'white',
    cursor: 'pointer',
    fontWeight: 'bold',
    width: '30%',
    color: '#333366',
    fontSize: '0.9rem'
  }

  DATA_STYLE = {
    verticalAlign: 'middle',
    padding: '0.5rem',
    color: '#000000',
    borderTop: '1px solid #eeeeee'
  }

  LONG_DATA_STYLE = {
    ...this.DATA_STYLE,
    whiteSpace: 'nowrap'
  }

  constructor (props) {
    super(props)
    this.state = {
      user: this.props.user,
      isLoaded: false,
      messageObject: {
        title: 'Retrieving custom models...',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      },
      isMobile: window?.innerWidth < 767,
      staticModels: null,
      customModels: null
    }

    this.resizeHandler = this.resizeHandler.bind(this)
    this.createModelDataRef = createRef()
    this.createModelDataRef.current = { newName: '', modelTemplate: null }
  }

  componentDidMount () {
    this.getCustomModels()
    this.getStaticModels()
    window.addEventListener('resize', this.resizeHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeHandler)
  }

  resizeHandler () {
    this.setState({
      isMobile: window.innerWidth < 767
    })
  }

  getStaticModels () {
    request({ endpoint: '/models?isBuild=true', method: 'GET' }, (err, result) => {
      if (err) {
        return console.error(err)
      }
      this.setState({
        staticModels: result
      })
    }, err => {
      console.error(err)
    })
  }

  defaultSort = (a, b, key) => {
    return a[key] >= b[key] ? 1 : -1
  }

  sortAsc = (data, key) => {
    var result = data.slice(1).sort((a, b) => {
      return this.defaultSort(a, b, key)
    })
    return [data[0], ...result]
  }

  sortDesc = (data, key) => {
    var result = data.slice(1).sort((a, b) => {
      return this.defaultSort(b, a, key)
    })
    return [data[0], ...result]
  }

  CONFIGURATION = {
    customModels: [
      {
        header: 'Name',
        key: 'name',
        renderWithData: data => {
          data.role = data.role || []
          if (data.role.includes('administrator') || data.role.includes('owner')) {
            return (
              <a onClick={() => {
                this.context.redirect('/custom-models/edit/' + data.modelUUID)
              }}
              >
                {data.name}
              </a>
            )
          }
          return <span>{data.name}</span>
        },
        headerStyle: { ...this.HEADER_STYLE, width: '50%' },
        dataStyle: { ...this.DATA_STYLE, fontWeight: '400', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '1px' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      },
      {
        header: 'Model type',
        key: 'modelType',
        renderWithData: data => {
          return <span title={'custom/user/' + data.modelUUID}>{'custom/user/' + data.modelUUID}</span>
        },
        headerStyle: { ...this.HEADER_STYLE, width: '30%' },
        dataStyle: { ...this.DATA_STYLE, fontWeight: '400', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '1px' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      },
      {
        header: 'Updated at',
        key: 'updatedAt',
        headerStyle: { ...this.HEADER_STYLE, width: '20%', textAlign: 'center' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' },
        renderWithData: data => {
          return toIsoDate(data.updatedAt) || 'Never'
        },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      }
    ]
  }

  getCustomModels = () => {
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Retrieving custom models...',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      }
    })

    request({
      endpoint: '/custom-model',
      method: 'GET'
    }, (error, response) => {
      if (response.message) {
        this.setState({
          isLoaded: true,
          messageObject: {
            title: 'Error while fetching custom models',
            description: response.message,
            status: 'error',
            code: response.code
          }
        })
        return
      }
      if (error) {
        this.setState({
          isLoaded: true,
          messageObject: {
            title: 'Error while fetching custom models',
            description: error,
            status: 'error',
            code: null
          }
        })
        return
      }
      this.setState({
        isLoaded: true,
        messageObject: LoadingModal.getEmptyMessageObject(),
        customModels: response
      })
    }, err => {
      this.setState({
        isLoaded: true,
        messageObject: {
          title: 'Error',
          description: 'Error happened while processing: ' + (err.message || err) + '.',
          status: 'error',
          code: err.code
        }
      })
    })
  }

  greetings = userLoggedIn => {
    const modelCount = this.state.staticModels?.reduce((acc, modelType) => {
      return acc + Object.keys(modelType.data).length
    }, 0) || 'thousands of'

    return (
      <div className='flow-greetings'>
        <h2 className='slogan'>
          Welcome to Base64.ai Custom Models!
        </h2>
        <h3 className='introduction'>
          Base64.ai comes with {modelCount?.toLocaleString?.('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} prebuilt models. If you have a special document type, you can classify them using a custom model.
        </h3>
        <ul>
          <li>
            <img src='/static/images/icons/folder_upload.png' className='green-overlay'></img>
            <p className='featureTitle'>Classify documents</p>
            <p className='featureDescription'>Identify document types based on their text, shapes, barcodes, and other features.</p>
          </li>
          <li>
            <img src='/static/images/icons/contact_form.png' className='green-overlay'></img>
            <p className='featureTitle'>No training required</p>
            <p className='featureDescription'>Our Large Language Models and Generative AI automatically pulls forms, tables, checkboxes, faces, signatures.</p>
          </li>
          <li>
            <img src='/static/images/icons/document_tick.png' className='green-overlay'></img>
            <p className='featureTitle'>Share your custom document with Flows</p>
            <p className='featureDescription'>Models can be directly shared within the custom model builder. By incorporating it into flows, you grant it access to your integrations.</p>
          </li>
          <li>
            <img src='/static/images/icons/network.png' className='green-overlay'></img>
            <p className='featureTitle'>Integrate your document type to anywhere</p>
            <p className='featureDescription'>Augment the AI results with custom code and third party integrations with Google Sheets, Quickbooks, Databases, API, SFTP, etc...</p>
          </li>
        </ul>
        { userLoggedIn
          ? (
              <AppButton className='secondary-cta' href='/custom-models/new'>
                Create your first custom model
              </AppButton>
            )
          : (
              <AppButton className='secondary-cta' id='newFlowButton' href='/login?next=/custom-models'>
                Login to access custom flows
              </AppButton>
            )}
      </div>
    )
  }

  openNameConfirmationModal = () => {
    this.createModelDataRef.current = { newName: '', modelTemplate: null }
    this.context.displayModal({
      title: 'Create Custom Model from template',
      content: (
        <CreateFromTemplate
          isCustomModelClone={true}
          component={{ name: 'New Custom Model' }}
          onChange={d => {
            this.createModelDataRef.current = {
              newName: d.newName,
              modelTemplate: d.template
            }
            console.log(this.createModelDataRef.current)
          }}
        />
      ),
      options: [
        {
          content: 'Cancel',
          intent: 'negative',
          action: () => {
            this.createModelDataRef.current = { newName: '', modelTemplate: null }
          }
        },
        {
          content: 'Create Custom Model',
          intent: 'primary',
          action: () => {
            console.log(this.createModelDataRef.current)
            if (this.createModelDataRef.current.modelTemplate) {
              this.createModelFromJson({
                ...this.createModelDataRef.current.modelTemplate,
                name: this.createModelDataRef.current.newName || this.createModelDataRef.current.modelTemplate.name
              })
            } else {
              this.context.displayModal({
                title: 'Error',
                content: 'Please select a JSON file.',
                options: [{ content: 'OK' }]
              })
            }
          }
        }
      ]
    })
  }

  createModelFromJson = jsonContent => {
    this.setState({ isLoaded: false, messageObject: {
      title: 'Creating custom model from JSON...',
      description: 'Please wait, this may take a few seconds.',
      status: 'loading',
      code: null
    }
    })
    request({
      endpoint: '/custom-model/import',
      method: 'POST',
      body: jsonContent
    }, (error, response) => {
      this.setState({ isLoaded: true, messageObject: LoadingModal.getEmptyMessageObject() })
      if (error) {
        this.createModelDataRef.current = { newName: '', modelTemplate: null }
        this.context.displayModal({
          title: 'Error',
          content: 'Failed to create custom model from JSON: ' + error,
          options: [{ content: 'OK' }],
          isClosable: true
        })
      } else {
        this.createModelDataRef.current = { newName: '', modelTemplate: null }
        this.context.displayModal({
          title: 'Success',
          content: 'Custom model created successfully from JSON',
          isClosable: false,
          options: [{
            content: 'OK',
            action: () => {
              this.context.redirect(`/custom-models/edit/${response.modelUUID}`)
            }
          }]
        })
      }
    })
  }

  render () {
    const {
      user
    } = this.props
    const wasUserLoggedIn = this.state.user?.email || user?.email

    const { messageObject, customModels, isLoaded } = this.state
    let loadingErrorButton = {
      to: '/flow',
      text: 'Back to Flows'
    }
    if (!isLoaded && !wasUserLoggedIn?.length) {
      loadingErrorButton = {
        to: '/login?next=/custom-models',
        text: 'Login to access custom models'
      }
    }

    const modelOptions = [
      {
        name: 'Use a settings file',
        onClick: this.openNameConfirmationModal
      }
    ]

    return (
      <View name='flow'>
        <Header name='header'>
          { wasUserLoggedIn
            ? (
                <>
                  <div className='header-container'>
                    <div>
                      <BackButton
                        to='/flow'
                        placeholder='Flow page'
                      />
                      <div className='title'>
                        <h1 className='slogan'>My Custom Models</h1>
                        <h2
                          className='introduction'
                        >
                          Manage your custom models.
                          <span className='explanation-additional-line'> A custom model can be used in multiple Flows.</span>
                        </h2>
                      </div>
                    </div>
                    <div className='button-set'>
                      <AppDropdown
                        options={modelOptions}
                        className='primary-cta'
                        href='/custom-models/new'
                        title='You can create a new custom model. This allows you to classify specific document types.'
                      >
                        {this.state.isMobile ? 'New model' : 'Create a new model'}
                      </AppDropdown>
                    </div>
                  </div>
                </>
              )
            : null }
        </Header>
        <Section name='model-listing' className={(!customModels || customModels?.length === 0) ? 'welcome blue' : 'settings-card'}>
          { !isLoaded
            ? (
                <LoadingModal
                  data={{
                    ...messageObject,
                    button: loadingErrorButton
                  }}
                />
              )
            : customModels?.length
              ? (
                  <>
                    <SortableTable
                      data={customModels || []}
                      columns={this.CONFIGURATION.customModels}
                      style={{
                        borderCollapse: 'separate',
                        borderSpacing: '1px',
                        width: '100%'
                      }}
                      iconStyle={{ paddingLeft: '0.4rem' }}
                    />
                  </>
                )
              : this.greetings(wasUserLoggedIn)}
        </Section>
      </View>
    )
  }
}

export default ListCustomModel
