import { useContext, useEffect, useMemo, useState, useRef } from 'react'
import SortableTable from 'react-sortable-table'

// Context
import Context from '~/context/global'
// Interface

// Layout
import View from '~/layout/view/View'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'

// Helpers
import PropTypes from 'prop-types'
import { formatNumber, toIsoDate } from '../../utilities/format'
import LoadingModal from '../../interface/loading-modal/LoadingModal'
import BackButton from '../../interface/back-button/BackButton'
import { useFlows } from '../../providers/FlowContext'
import FlowGreetings from './FlowGreetings'
import CreateFromTemplate from '../create-from-template/CreateFromTemplate'
import { AppButton } from '../../interface/app-button/AppButton'
import { AppDropdown } from '../../interface/app-dropdown/AppDropdown'

const HEADER_STYLE = {
  padding: '0.5rem',
  whiteSpace: 'nowrap',
  margin: '0.1rem',
  backgroundColor: 'white',
  cursor: 'pointer',
  fontWeight: 'bold',
  width: '30%',
  color: '#333366',
  fontSize: '0.9rem'
}

const DATA_STYLE = {
  verticalAlign: 'middle',
  padding: '0.5rem',
  color: '#000000',
  borderTop: '1px solid #eeeeee'
}

const defaultSort = (a, b, key) => {
  return a[key] >= b[key] ? 1 : -1
}

const sortAsc = (data, key) => {
  var result = data.slice(1).sort((a, b) => {
    return defaultSort(a, b, key)
  })
  return [data[0], ...result]
}

const sortDesc = (data, key) => {
  var result = data.slice(1).sort((a, b) => {
    return defaultSort(b, a, key)
  })
  return [data[0], ...result]
}

const getTableConfig = context => ([
  {
    header: 'Name',
    key: 'name',
    renderWithData: data => {
      const title = data.isDefault ? 'We prepared this Flow for you. You can start with the sample files inside.' : ''
      return (
        <a
          title={title}
          onClick={() => {
            context.redirect('/flow/results/' + data.flowID)
          }}
        >
          {data.name} {data.status === 'disabled' ? '(Frozen)' : ''}
        </a>
      )
    },
    headerStyle: { ...HEADER_STYLE, width: '30%' },
    dataStyle: { ...DATA_STYLE, fontWeight: '400', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '1px' },
    descSortFunction: sortDesc,
    ascSortFunction: sortAsc
  },
  {
    header: 'Ownership',
    key: 'role',
    renderWithData: data => {
      let roles = (data.role || []).filter(r => r !== 'admin')
      if (roles.find(r => r === 'administrator')) {
        roles = ['administrator']
      } else if (roles.find(r => r === 'owner')) {
        roles = ['owner']
      }
      roles = roles.join(', ')
      return <span title='Roles of the user for this Flow.'>{roles}</span>
    },
    headerStyle: { ...HEADER_STYLE, width: '15%', textAlign: 'center' },
    dataStyle: { ...DATA_STYLE, fontWeight: '400', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '1px', textAlign: 'center' },
    descSortFunction: sortDesc,
    ascSortFunction: sortAsc
  },
  {
    header: 'Needs review',
    key: 'numberOfNeedsReview',
    render: data => {
      return <span title='Number of documents to be reviewed'>{data}</span>
    },
    headerStyle: { ...HEADER_STYLE, width: '11%', textAlign: 'center' },
    dataStyle: { ...DATA_STYLE, textAlign: 'center' },
    descSortFunction: sortDesc,
    ascSortFunction: sortAsc
  },
  {
    header: 'Approved',
    key: 'numberOfApproved',
    render: data => {
      return <span title='Number of approved documents'>{data}</span>
    },
    headerStyle: { ...HEADER_STYLE, width: '10%', textAlign: 'center' },
    dataStyle: { ...DATA_STYLE, textAlign: 'center' },
    dataProps: { className: 'mobile-fobic' },
    descSortFunction: sortDesc,
    ascSortFunction: sortAsc
  },
  {
    header: 'Rejected',
    key: 'numberOfRejected',
    render: data => {
      return <span title='Number of rejected documents'>{data}</span>
    },
    headerStyle: { ...HEADER_STYLE, width: '10%', textAlign: 'center' },
    dataStyle: { ...DATA_STYLE, textAlign: 'center' },
    dataProps: { className: 'mobile-fobic' },
    descSortFunction: sortDesc,
    ascSortFunction: sortAsc
  },
  {
    header: 'Total',
    key: 'numberOfUploads',
    render: data => {
      return <span title='Total number of documents'>{data}</span>
    },
    headerStyle: { ...HEADER_STYLE, width: '8%', textAlign: 'center' },
    dataStyle: { ...DATA_STYLE, textAlign: 'center' },
    descSortFunction: sortDesc,
    ascSortFunction: sortAsc
  },
  {
    header: 'Last uploaded',
    key: 'lastUploadedAt',
    headerStyle: { ...HEADER_STYLE, width: '17%', textAlign: 'center' },
    dataStyle: { ...DATA_STYLE, textAlign: 'center' },
    renderWithData: data => {
      return <span title='Last update date and time'>{data.lastUploadedAt ? toIsoDate(data.lastUploadedAt) : 'Never'}</span>
    },
    descSortFunction: sortDesc,
    ascSortFunction: sortAsc
  }
]
)

const FlowList = ({ user }) => {
  const context = useContext(Context)
  const {
    flows,
    message,
    isLoaded,
    isLoading,
    refreshFlows,
    requestWrapper
  } = useFlows()
  const [wasUserLoggedIn, setWasUserLoggedIn] = useState(false)
  const createFlowDataRef = useRef({ newName: '', flowTemplate: null })

  useEffect(() => {
    if (!wasUserLoggedIn && user?.email && !flows.length) {
      refreshFlows()
    }
    setWasUserLoggedIn(!!user?.email)
  }, [user])

  let loadingErrorButton = {
    to: '/flow',
    text: 'Back to Flows'
  }
  if (!isLoaded && !wasUserLoggedIn?.length) {
    loadingErrorButton = {
      to: '/login?next=/flow',
      text: 'Login to access Flows'
    }
  }

  const manipulatedFlows = useMemo(() => {
    let result = flows
    if (flows) {
      const defaultFlow = flows.filter(s => s.isDefault)[0]
      result = [defaultFlow, ...(flows.filter(s => !s.isDefault) || [])]
    }

    result = result?.filter(s => s)
      .map(flow => {
        return {
          ...flow,
          numberOfApproved: formatNumber(flow.numberOfApproved),
          numberOfRejected: formatNumber(flow.numberOfRejected),
          numberOfUploads: formatNumber(flow.numberOfUploads),
          numberOfNeedsReview: formatNumber(flow.numberOfNeedsReview)
        }
      })
    return result
  }, [flows])

  const openNameConfirmationModal = () => {
    createFlowDataRef.current = { newName: '', flowTemplate: null }
    context.displayModal({
      title: 'Create Flow from template',
      content: (
        <CreateFromTemplate
          component={{ name: 'New Flow' }}
          onChange={d => {
            createFlowDataRef.current = {
              newName: d.newName,
              flowTemplate: d.template
            }
          }}
        />
      ),
      options: [
        {
          content: 'Cancel',
          intent: 'negative',
          action: () => {
            createFlowDataRef.current = { newName: '', flowTemplate: null }
          }
        },
        {
          content: 'Create Flow',
          intent: 'primary',
          action: () => {
            if (createFlowDataRef.current.flowTemplate) {
              createFlowFromJson({
                ...createFlowDataRef.current.flowTemplate,
                name: createFlowDataRef.current.newName || createFlowDataRef.current.flowTemplate.name
              })
            } else {
              context.displayModal({
                title: 'Error',
                content: 'Please select a JSON file.',
                options: [{ content: 'OK' }]
              })
            }
          }
        }
      ]
    })
  }

  const createFlowFromJson = jsonContent => {
    requestWrapper('Creating flow from JSON...', {
      endpoint: '/flow/import',
      method: 'POST',
      body: jsonContent
    }, (error, response) => {
      if (error) {
        createFlowDataRef.current = { newName: '', flowTemplate: null }
        context.displayModal({
          title: 'Error',
          content: 'Failed to create flow from JSON: ' + error,
          options: [{ content: 'OK' }],
          isClosable: true
        })
      } else {
        createFlowDataRef.current = { newName: '', flowTemplate: null }
        context.displayModal({
          title: 'Success',
          content: 'Flow created successfully from JSON',
          isClosable: false,
          options: [{
            content: 'OK',
            action: () => {
              context.redirect(`/flow/${response.flowID}/edit`)
            }
          }]
        })
      }
    })
  }

  const flowOptions = [
    {
      name: 'Use a settings file',
      onClick: openNameConfirmationModal
    }
  ]

  return (
    <View name='flow'>
      <Header name='header'>
        { isLoaded && wasUserLoggedIn
          ? (
              <>
                <div className='header-container'>
                  <div>
                    <BackButton
                      to='/'
                      placeholder='Home page'
                    />
                    <div className='title'>
                      <h1 className='slogan' title='These are your Flows'>My Flows</h1>
                      <h2
                        className='introduction'
                      >
                        Create workflows to automate your document processes.
                      </h2>
                    </div>
                  </div>
                  <div className='button-set list-custom-models'>
                    <AppButton
                      href='/custom-models'
                      className='secondary-cta'
                      title='You can access the list of models you have previously created.'
                    >
                      List custom models
                    </AppButton>
                    <AppDropdown
                      href='/flow/new'
                      className='primary-cta'
                      title='You can create a new flow. In this way, you can distinguish document types more easily.'
                      options={flowOptions}
                    >
                      Create a new flow
                    </AppDropdown>
                  </div>
                </div>
              </>
            )
          : null }
      </Header>
      <Section name='flow-listing' className={(!flows || flows?.length === 0) ? 'welcome blue' : 'settings-card'}>
        { isLoading
          ? (
              <LoadingModal
                data={{
                  ...message,
                  status: 'loading',
                  button: loadingErrorButton
                }}
              />
            )
          : wasUserLoggedIn && flows?.length > 0
            ? (
                <>
                  <SortableTable
                    data={manipulatedFlows}
                    columns={getTableConfig(context)}
                    style={{
                      borderCollapse: 'separate',
                      borderSpacing: '1px',
                      width: '100%'
                    }}
                    iconStyle={{ paddingLeft: '0.4rem' }}
                  />
                </>
              )
            : <FlowGreetings context={context} userLoggedIn={wasUserLoggedIn} />}
      </Section>
      {
        flows?.length === 1
          ? (
              <Section name='flow-listing' className='welcome blue'>
                <FlowGreetings context={context} userLoggedIn={true} />
              </Section>
            )
          : <></>
      }
    </View>
  )
}

FlowList.propTypes = {
  user: PropTypes.object
}

export default FlowList
