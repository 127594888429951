import PropTypes from 'prop-types'
import { formatLLMResponse } from './format'
import SourceInfo from '../interface/data-extraction-result-viewer/SourceInfo'

function FormatLLMResponseWithSources ({ response }) {
  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: formatLLMResponse(response) }} />
      {response.sources?.length > 0 && (
        <SourceInfo
          source={response.sources[0]}
          questionObj={response}
        />
      )}
    </>
  )
}

FormatLLMResponseWithSources.propTypes = {
  response: PropTypes.object
}

export default FormatLLMResponseWithSources
