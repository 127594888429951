const events = {
  'contact-lead-form-submit': { adroll: '27535b78', outbrain: 'Lead Form Submitted - Contact' },
  'contact-section-lead-form-submit': { adroll: 'f9854e01', outbrain: 'Lead Form Submitted - Contact Section' },
  'demo-completed-facial-tracking': { adroll: '81610e1b', outbrain: 'Demo Completed - Face Tracking' },
  'demo-completed-acord-25': { adroll: '457788be', outbrain: 'Demo Completed - ACORD 25' },
  'demo-completed-covid-vaccination': { adroll: '699d1e7c', outbrain: 'Demo Completed - Covid Vaccination' },
  'demo-completed-document-processing': { adroll: 'f79d3881', outbrain: 'Demo Completed - Document Processing' },
  'demo-completed-facial-recognition': { adroll: '2ab3abac', outbrain: 'Demo Completed - Facial Recognition' },
  'demo-completed-face-manager': { adroll: '2c9275e7', outbrain: 'Demo Completed - Face Manager' },
  'demo-completed-facial-verification': { adroll: 'ade1a3d0', outbrain: 'Demo Completed - Facial Verification' },
  'demo-completed-signature-recognition': { adroll: '3d5af48e', outbrain: 'Demo Completed - Signature Recognition' },
  'demo-completed-signature-verification': { adroll: '43bae006', outbrain: 'Demo Completed - Signature Verification' },
  'demo-completed-redaction': { adroll: '6d1e1721' },
  'signup-completed-email': { adroll: '4ca5299b', outbrain: 'Signup Completed - Email' },
  'login-attempt-linkedin': { adroll: '30ee85f3', outbrain: 'Login Attempt - LinkedIn' },
  'login-attempt-microsoft': { adroll: 'd00ca8a0', outbrain: 'Login Attempt - Microsoft' },
  'login-completed-google': { adroll: '91587528', outbrain: 'Login Completed - Google' },
  'login-completed-email': { adroll: '35d3f19a', outbrain: 'Login Completed - Email' },
  'integrations-api-key-button': { adroll: 'c0ca0a86', outbrain: 'Integrations Page - API Key Button Clicked' },
  'integrations-add-ai-button': { adroll: '662c23a3', outbrain: 'Integrations Page - Add AI Button Clicked' },
  'integrations-smart-forms-button': { adroll: 'ad868c79', outbrain: 'Integrations Page - Smart Forms Button Clicked' },
  'integrations-platform-partner-logo': { adroll: '5bb69927', outbrain: 'Integrations Page - Platform Partners Logo Clicked' },
  'integrations-solution-partner-logo': { adroll: 'bfeace4a', outbrain: 'Integrations Page - Solution Partners Logo Clicked' },
  'integrations-on-premises-button': { adroll: '0de988d2', outbrain: 'Integrations Page - On-Premises Button Clicked' },
  'partners-page-partner-logo': { adroll: '0c46f003', outbrain: 'Partners Page - Partner Logo Clicked' },
  'subscription-button-clicked-enterprise': { adroll: '43fe98d8', outbrain: 'Subscription Button Clicked - Enterprise' },
  'subscription-button-clicked-new-scale': { adroll: '31d99d0e', outbrain: 'Subscription Button Clicked - New Subscription Scale' },
  'subscription-button-clicked-new-success': { adroll: '79a010fa', outbrain: 'Subscription Button Clicked - New Subscription Success' },
  'subscription-button-clicked-updated-scale': { adroll: '12fff64a', outbrain: 'Subscription Button Clicked - Updated Subscription Scale' },
  'subscription-button-clicked-updated-success': { adroll: 'cf98e568', outbrain: 'Subscription Button Clicked - Updated Subscription Success' },
  'home-partner-logo': { adroll: '58786ff8', outbrain: 'Home Page - Partner Logo Clicked' }
}

export const triggerTrackingEvent = eventId => {
  const event = events[eventId]
  try {
    // Ignore undefined errors since __adroll is injected in runtime
    // eslint-disable-next-line no-undef
    if (__adroll) {
      // Ignore undefined errors since __adroll is injected in runtime
      // eslint-disable-next-line no-undef
      event.adroll && __adroll.record_user({ adroll_segments: event.adroll })
    }
    // Ignore undefined errors since obApi is injected in runtime
    // eslint-disable-next-line no-undef
    if (obApi) {
      // Ignore undefined errors since obApi is injected in runtime
      // eslint-disable-next-line no-undef
      event.outbrain && obApi('track', event.outbrain)
    }
  } catch (err) {
    // Adroll is not loaded
    // Possible cause: Adblock or Offline, no need to throw an error if it is the case.
    // We might want to log if we are in dev mode
  }
}
