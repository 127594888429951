// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'
// Context
import Context from '~/context/global'
import { reCaptcha } from '~/helpers/authentication'
import { getBase64 } from '~/helpers/file'
// Helpers
import { request } from '~/helpers/request'
// Interface
import FileInput from '~/interface/file-input/FileInput'
import StatusToEmoji from '~/interface/validation-sign/StatusToEmoji'
import ValidationSign from '~/interface/validation-sign/ValidationSign'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import window from 'global/window'
// Utilities
import { triggerTrackingEvent } from '../../utilities/tracker'

// View: Demo
class Acord25Validation extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      responseMessage: 'Please upload an ACORD 25 form and validation CSV.',
      validationResponse: null,
      acordFormBase64: null,
      acordFormUrl: null,
      validationFileUrl: null,
      validationFileBase64: null,
      nextPath: null
    }
    this.downloadLink = this.downloadLink.bind(this)
    this.handleFiles = this.handleFiles.bind(this)
    this.makeRequest = this.makeRequest.bind(this)
    this.validateAcordForm = this.validateAcordForm.bind(this)
    this.showSampleLink = this.showSampleLink.bind(this)
  }

  downloadLink (link) {
    if (!link) {
      return null
    } else if (link.startsWith('data:')) {
      const fileName = 'file.' + link.split(';')[0].split('/')[1]
      return <a className='link' href={link} download={fileName}>Download file</a>
    } else {
      return <a className='link' href={link} target='_blank' rel='noopener noreferrer'>Download file</a>
    }
  }

  componentDidMount () {
    this.setState({
      nextPath: window.location.pathname
    })
  }

  handleFiles (accepted, rejected, isAcordForm) {
    this.setState({
      validationResponse: null
    })
    if (accepted && accepted.length) {
      getBase64({ source: accepted[0] }, (error, response) => {
        if (error) {
          this.setState({
            responseStatus: 'error',
            responseMessage: 'Cannot read the file!'
          })
          return
        }
        if (isAcordForm) {
          this.setState({
            acordFormBase64: response
          }, this.validateAcordForm)
        } else {
          this.setState({
            validationFileBase64: response
          }, this.validateAcordForm)
        }
      })
    }
    if (rejected && rejected.length) {
      this.setState({
        responseStatus: 'error',
        responseMessage: 'Invalid file format'
      })
    }
  }

  makeRequest () {
    triggerTrackingEvent('demo-completed-acord-25')
    reCaptcha('demo', token => {
      const validationFileOrUrl = this.state.validationFileBase64 ? { validationData: this.state.validationFileBase64 } : { validationUrl: this.state.validationFileUrl }
      const acordFormOrUrl = this.state.acordFormBase64 ? { image: this.state.acordFormBase64 } : { url: this.state.acordFormUrl }
      request({
        endpoint: '/scan/validateAcord25',
        body: {
          token,
          ...validationFileOrUrl,
          ...acordFormOrUrl
        }
      }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: error.message,
            responseStatus: 'error',
            validationResponse: null
          })
        } else {
          if (response.message) {
            this.setState({
              responseMessage: error,
              responseStatus: 'error',
              validationResponse: null
            })
          } else {
            this.setState({
              responseMessage: 'Document analyzed',
              responseStatus: 'success',
              validationResponse: response
            })
          }
        }
      })
    })
  }

  validateAcordForm () {
    if ((this.state.acordFormBase64 || this.state.acordFormUrl) && (this.state.validationFileBase64 || this.state.validationFileUrl)) {
      this.setState({
        responseStatus: 'info',
        responseMessage: 'Analyzing ACORD 25 form...'
      }, this.makeRequest)
    }
  }

  showSampleLink (status) {
    if (status) {
      return null
    }
    return (
      <a
        className='showSample'
        onClick={() => {
          this.setState({
            acordFormUrl: window.location.origin + '/static/content/features/data-extraction/models/insurance/acord/25/3.png',
            validationFileUrl: window.location.origin + '/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv'
          }, this.validateAcordForm)
        }}
      >Show sample
      </a>
    )
  }

  render () {
    const {
      acordFormBase64,
      acordFormUrl,
      responseMessage,
      responseStatus,
      validationFileUrl,
      validationFileBase64,
      validationResponse,
      nextPath
    } = this.state
    return (
      <View name='acord25-validation-demo'>
        <Header name='header'>
          <h1 className='slogan'>ACORD 25 Validation AI</h1>
          <h2 className='introduction'>Validate certificate of liability insurances in seconds instead of hours</h2>
          <h3 className='introduction'>
            ACORD 25 Certificate of Liability Insurance forms require time and skill to process and compare against insurance requirements.
            Simply upload a CSV with required coverage details and COI to check.
            Base64.ai instantly extracts all 93 fields from an ACORD 25 form, detects the operator, compares the extracted data against their requirements, and highlights fields that do not match.
            An indispensable tool for any company processing COI forms, saving thousands of FTE hours.
          </h3>
        </Header>
        <Section name='output'>
          <div><StatusToEmoji status={responseStatus} message={responseMessage} />{this.showSampleLink(responseStatus)}</div>
        </Section>
        {nextPath
          ? (
              <Section name='input'>
                <div className='uploadBox uploadBoxLeft'>
                  <FileInput
                    double={true}
                    context={this.context}
                    requireLogin={!this.props.user}
                    next={nextPath}
                    dropText='Upload an ACORD 25 form'
                    buttonText='Upload file'
                    multiple={false}
                    indicator={true}
                    accept={[
                      'image/png',
                      'image/jpg',
                      'image/jpeg',
                      'image/gif',
                      'application/pdf'
                    ]}
                    onDrop={(accepted, rejected, event) =>
                      this.handleFiles(accepted, rejected, true)}
                  />
                  {this.downloadLink(acordFormBase64 || acordFormUrl)}
                </div>
                <div className='uploadBox'>
                  <FileInput
                    double={true}
                    context={this.context}
                    requireLogin={!this.props.user}
                    next={nextPath}
                    dropText='Upload a validation CSV'
                    multiple={false}
                    indicator={true}
                    accept={[
                      'text/csv',
                      'text/plain',
                      '.csv'
                    ]}
                    onDrop={(accepted, rejected, event) =>
                      this.handleFiles(accepted, rejected)}
                  />
                  {this.downloadLink(validationFileBase64 || validationFileUrl)}
                </div>
              </Section>
            )
          : null}
        <Section name='result'>
          {validationResponse === null
            ? null
            : (
                <>
                  <p className='result'>
                    <b>
                      <ValidationSign success={!!validationResponse.operatorName} />
                      {
                        validationResponse.operatorName
                          ? `Match:\u00A0${validationResponse.operatorName}` + (
                          validationResponse.operatorName
                            ? '\u00A0(' + (Object.keys(validationResponse.categories).filter(catKey => validationResponse.categories[catKey]).join(', ') || 'No category match') + ')'
                            : null
                        )
                          : `No operator match with "${validationResponse.validationResults.operatorName.scanValue}"`
                      }
                    </b>
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <td className='firstRow'>Field</td>
                        <td className='firstRow'>ACORD 25</td>
                        {
                          Object.keys(validationResponse.categories).map((catKey, i) => (
                            <td key={i} className='firstRow'>
                              <ValidationSign success={validationResponse.categories[catKey]} />
                              {catKey}
                            </td>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        validationResponse.validationResults && Object.values(validationResponse.validationResults || {}).map((field, index) => (
                          <tr key={index}>
                            <td>{field.name}</td>
                            <td>{field.scanValue}</td>
                            {
                              (field.values || []).map((fv, i2) => (
                                <td key={i2}>
                                  <ValidationSign success={fv.validation} />
                                  {fv.expectedValue}
                                </td>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </>
              )}
        </Section>
      </View>
    )
  }
}

Acord25Validation.propTypes = {
  user: PropTypes.object
}

// Export
export default Acord25Validation
