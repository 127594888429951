import { Component } from 'react'
import Checkbox from '../../../interface/checkbox/Checkbox'
import PropTypes from 'prop-types'

export default class HowToSend extends Component {
  constructor (props) {
    super(props)
    this.state = {
      input: this.props.parent.state.howToSendResult || {}
    }
  }

  setInput (name, value, callback) {
    const input = this.state.input
    input[name] = value
    this.setState({ input })
    // add result in the parent element
    this.props.parent.setState(prev => {
      return {
        ...prev,
        howToSendResult: this.state.input
      }
    })
    callback && callback(input)
  }

  onCheckBoxChange (data) {
    this.setInput(data.placeholder, data.checked)
  }

  onTextBoxChange (data) {
    this.setInput('other', data.value)
  }

  sendMethods = [
    'Email',
    'Base64.ai API',
    'My customers need an upload form',
    'Upload on my website or mobile app',
    'Cloud file storage (Dropbox, Google Drive, S3, etc.)',
    'On-premises datacenter',
    'Database',
    'RPA project (UiPath, Zapier, etc.)'
  ]

  render () {
    return (
      <>
        <div className='main'>
          <div className='description'>
            <h2>How would you like to send us your documents?</h2>
            <h3 className='introduction small'>
              Base64.ai offers many integration options.
              Tell our AI to read files in a folder, file storage, or database.
              If you are familiar with programming, use the SDK that supports 27 programming languages.
              Base64.ai is also integrated with many RPA systems such as UiPath and Zapier.
            </h3>
          </div>
          <div className='entries'>
            {this.sendMethods.map((item, index) => (
              <Checkbox
                key={index}
                className='big'
                placeholder={item}
                onChange={e => {
                  this.onCheckBoxChange(e.target)
                }}
                checked={this.state.input[item]}
              />
            ))}

            <div className='textAreaContainer'>
              <textarea
                placeholder='Describe your other options here...'
                value={this.props.parent.state.howToSendResult?.other || ''}
                onChange={e => {
                  this.onTextBoxChange(e.target)
                }}
              >
              </textarea>
            </div>
          </div>
        </div>
      </>
    )
  }
}

HowToSend.propTypes = {
  input: PropTypes.object,
  parent: PropTypes.object
}
