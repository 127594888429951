import { hasDatabase } from '../context/environment'

export default [
  {
    title: 'flow',
    url: '/flow',
    description: 'Base64.ai Flow understands is the ultimate document processing AI that understands all types of documents. Speed up document processing by automatically extracting text, tables, data, faces, and signatures. Validate the data extraction results, import & export data using 400+ no-code integrations.',
    buttonTitle: 'Understand Documents, Review & Integrate',
    path: 'Document_AI_1.png',
    isLarge: true,
    isWorkOffline: hasDatabase()
  },
  {
    title: 'demoRedaction',
    url: '/demo/redaction',
    description: 'Anonymize personal and sensitive information from all kind of documents such as IDs, forms, and contracts etc.',
    buttonTitle: 'Redact documents',
    path: 'Document_AI_3.png',
    isWorkOffline: hasDatabase()
  },
  {
    title: 'demoCovid19',
    url: '/demo/covid-19-vaccination-validation',
    description: 'Help stop the spread of Covid-19 by validating Covid-19 vaccination cards with IDs, driver licenses, or passports',
    buttonTitle: 'Validate Covid-19 vaccination',
    path: 'Document_AI_4.png',
    isWorkOffline: hasDatabase()
  },
  {
    title: 'demoAcord25',
    url: '/demo/acord25-validation',
    description: 'Validate ACORD 25 Certificate of Liability Insurance form against the minimum requirements',
    buttonTitle: 'Validate ACORD 25',
    path: 'Document_AI_5.png',
    isWorkOffline: hasDatabase()
  },
  {
    title: 'demoHealthAndSafety',
    url: '/demo/health-and-safety-document-validation/',
    description: 'Validate Health & Safety Documents against expired revision dates, watermarks, and draft text in seconds',
    buttonTitle: 'Validate Health & Safety Doc',
    path: 'Document_AI_6.png',
    isWorkOffline: hasDatabase()
  }
]
