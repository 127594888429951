import { Component } from 'react'
import PropTypes from 'prop-types'
import DataExtractionBox from './DataExtractionBox'
import * as uuid from 'uuid'

class ObjectDetectionBox extends Component {
  static propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    object: PropTypes.object,
    objectIndex: PropTypes.number,
    contextProvider: PropTypes.func,
    context: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
    }
    this.id = uuid.v4()
    this.removeObject = this.removeObject.bind(this)
  }

  removeObject () {
    this.props.contextProvider.updateResult(this.props.type, { ...this.props.object, isDeleted: true }, { parent: 'features', objectIndex: this.props.objectIndex })
  }

  render () {
    return (
      <DataExtractionBox
        title={this.props.title}
        removeHandler={this.removeObject}
        location={this.props.object}
        confidence={this.props.object.confidence}
        selectedDataReference={this.id}
        context={this.props.context}
        contextProvider={this.props.contextProvider}
      >
        <img src={this.props.object.image} />
      </DataExtractionBox>
    )
  }
}

export default ObjectDetectionBox
