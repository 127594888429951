// Modules
import PropTypes from 'prop-types'
import React from 'react'
// Styles
/* SSR-IGNORE */ import 'react-day-picker/lib/style.css'
import { withRouter } from 'react-router-dom'
import SortableTable from 'react-sortable-table'
// Context
import Context from '~/context/global'
// Helpers
import { request } from '~/helpers/request'
// Interface
import Loading from '~/interface/loading/Loading'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import window from 'global/window'
import { hasDatabase, isProduction, isWorkingOffline } from '../../context/environment'
import ReadMoreReact from 'read-more-react'
import Textarea from '../../interface/textarea/Textarea'
import { MultiSelect } from 'react-multi-select-component'
import { toIsoDate } from '../../utilities/format'
import Checkbox from '../../interface/checkbox/Checkbox'
import Dropdown from '../../interface/dropdown/Dropdown'
import { AppButton } from '../../interface/app-button/AppButton'

window.React = React

// View: Usage
class Admin extends React.Component {
  static contextType = Context

  HEADER_STYLE = {
    padding: '0.5rem',
    whiteSpace: 'nowrap',
    margin: '0.1rem',
    backgroundColor: 'white',
    cursor: 'pointer',
    fontWeight: 'bold',
    width: '30%',
    color: '#333366',
    fontSize: '0.9rem'
  }

  DATA_STYLE = {
    padding: '0.5rem 0 0.5rem 0'
  }

  LONG_DATA_STYLE = {
    ...this.DATA_STYLE,
    whiteSpace: 'nowrap'
  }

  CONFIGURATION = {
    users: [
      {
        header: 'Name',
        key: 'name',
        renderWithData: data => {
          return <a href={'/admin/uploads/' + data.userId}>{data.name}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Email',
        key: 'email',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Work email',
        key: 'workEmail',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Phone number',
        key: 'phoneNumber',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Signup IP',
        key: 'ip',
        renderWithData: data => {
          return data.ip && <a href={'https://ipaddress.is/' + data.ip} rel='noopener noreferrer' target='_blank' title="Show the IP's approximate location">{data.ip.replace(/:/g, ': ')}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Created at',
        key: 'createdAt',
        renderWithData: data => {
          return toIsoDate(data.createdAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Sponsor',
        key: 'sponsorUserID',
        renderWithData: data => {
          return <a href={'/admin/uploads/' + data.sponsorUserID}>{data.sponsorUserID}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Last login',
        key: 'lastLoginAt',
        renderWithData: data => {
          return toIsoDate(data.lastLoginAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Last upload',
        key: 'lastUploadAt',
        renderWithData: data => {
          return toIsoDate(data.lastUploadAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Credits',
        key: 'credits',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Spent',
        key: 'spent',
        renderWithData: data => {
          return <a href={'/usage/this-month/?email=' + encodeURIComponent(data.email)} title="Show the user's this month's usage">{data.spent}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: '%',
        key: 'spentPercentage',
        renderWithData: data => {
          const style = {}
          if (data.spentPercentage >= 0.95) {
            style.color = 'red'
          } else if (data.spentPercentage >= 0.85) {
            style.color = 'orange'
          }
          return <span style={style}>{(100 * data.spentPercentage).toFixed(1)}%</span>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Docs',
        key: 'numberOfUploads',
        renderWithData: data => {
          return <a href={'/chore/uploads/user/' + data.userId} title="Show the user's document uploads">{data.numberOfUploads}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Pages',
        key: 'numberOfPages',
        renderWithData: data => {
          return <a href={'/chore/uploads/user/' + data.userId} title="Show the user's document uploads">{data.numberOfPages}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Faces',
        key: 'numberOfFaceRecognitions',
        renderWithData: data => {
          return <a href={'/admin/faces/' + data.userId} title="Show the user's face uploads">{data.numberOfFaceRecognitions}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Issues',
        key: 'issues',
        renderWithData: data => {
          return <a href={'/admin/issues/' + data.userId} className='no-underscore' title="Show the user's issues">🔎</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Tags',
        key: 'tags',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Status',
        key: 'status',
        renderWithData: data => {
          const status = data.status === 'pendingVerification' ? 'pending' : data.status
          return <a onClick={() => this.updateUserData({ userId: data.userId, status, email: data.email })} title="Change the user's status">{status}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'More',
        key: 'more',
        renderWithData: data => (
          <Dropdown
            isAlignedRight={true}
            className='user-options'
            style={{ }}
            title='&middot;&middot;&middot;'
            items={[
              {
                label: 'Reset concurrent request counter',
                action: () => {
                  this.resetConcurrentRequestCounter(data)
                }
              }
            ]}
          />
        ),
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      }
    ],
    paid_users: [
      {
        header: 'Name',
        key: 'name',
        renderWithData: data => {
          return <a href={'/admin/uploads/' + data.userId}>{data.name}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Work email',
        key: 'workEmail',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Email',
        key: 'email',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Created at',
        key: 'createdAt',
        renderWithData: data => {
          return toIsoDate(data.createdAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Customer Status',
        key: 'customerStatus',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Sponsor',
        key: 'sponsorUserID',
        renderWithData: data => {
          return <a href={'/admin/uploads/' + data.sponsorUserID}>{data.sponsorUserID}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Subscription Type',
        key: 'subscriptionType',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Subscription Period',
        key: 'subscriptionPeriod',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Account Type',
        key: 'accountType',
        renderWithData: data => {
          return (
            <MultiSelect
              labelledBy='Account Type'
              disableSearch={true}
              hasSelectAll={false}
              onChange={selected => {
                data.accountType = selected
                const userDataList = this.state.data
                userDataList[data.index] = data
                this.setState({ data: userDataList })
                this.updateAccountType(data.userId, selected)
              }}
              value={data.accountType || []}
              options={[{ label: 'Leader', value: 'leader' }, { label: 'Member', value: 'member' }, { label: 'Contracted', value: 'contracted' }, { label: 'Uncontracted', value: 'uncontracted' }]}
            />
          )
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Last paid at',
        key: 'lastPaidAt',
        renderWithData: data => {
          return toIsoDate(data.lastPaidAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Last upload',
        key: 'lastUploadAt',
        renderWithData: data => {
          return toIsoDate(data.lastUploadAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Credits',
        key: 'credits',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Spent since last payment',
        key: 'usageSinceLastPayment',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Spent',
        key: 'spent',
        renderWithData: data => {
          return <a href={'/usage/this-month/?email=' + encodeURIComponent(data.email)} title="Show the user's this month's usage">{data.spent}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: '%',
        key: 'spentPercentage',
        renderWithData: data => {
          const style = {}
          if (data.spentPercentage >= 0.95) {
            style.color = 'red'
          } else if (data.spentPercentage >= 0.85) {
            style.color = 'orange'
          }
          return <span style={style}>{(100 * data.spentPercentage).toFixed(1)}%</span>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Docs',
        key: 'numberOfUploads',
        renderWithData: data => {
          return <a href={'/chore/uploads/user/' + data.userId} title="Show the user's document uploads">{data.numberOfUploads}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Pages',
        key: 'numberOfPages',
        renderWithData: data => {
          return <a href={'/chore/uploads/user/' + data.userId} title="Show the user's document uploads">{data.numberOfPages}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Tags',
        key: 'tags',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Status',
        key: 'status',
        renderWithData: data => {
          const status = data.status === 'pendingVerification' ? 'pending' : data.status
          return <a onClick={() => this.updateUserData({ userId: data.userId, status, email: data.email })} title="Change the user's status">{status}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      }
    ],
    uploads: [
      {
        header: 'User',
        key: 'user',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE,
        renderWithData: data => <a href={'/admin/uploads/' + data.userID}>{data.userID}</a>
      },
      {
        header: 'Authentication',
        key: 'authentication',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'IP',
        key: 'ip',
        renderWithData: data => {
          return data.ip && <a href={'https://ipaddress.is/' + data.ip} rel='noopener noreferrer' target='_blank'>{data.ip.replace(/:/g, ' ')}</a>
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Model',
        key: 'modelType',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Pages',
        key: 'pages',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Credits',
        key: 'numberOfCreditsSpent',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Faces',
        key: 'faces',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Signatures',
        key: 'signatures',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Hash of File',
        key: 'hashOfFile',
        renderWithData: data => {
          return (data.hashOfFile && data.hashOfFile.length > 4) ? data.hashOfFile.slice(-5) : ''
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Empty',
        key: 'emptyFields',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Created at',
        key: 'createdAt',
        renderWithData: data => {
          return toIsoDate(data.createdAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Image',
        key: 'fileName',
        renderWithData: data => {
          return data ? <a href={'https://storage.cloud.google.com/' + data.fileName} rel='noopener noreferrer' target='_blank'>view</a> : 'No image'
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      }
    ],
    faces: [
      {
        header: 'Authentication',
        key: 'authentication',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Faces',
        key: 'faces',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Success',
        key: 'success',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Created at',
        key: 'createdAt',
        renderWithData: data => {
          return toIsoDate(data.createdAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      }
    ],
    issues: [
      {
        header: 'User',
        key: 'user',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE,
        renderWithData: data => <a href={'/admin/uploads/' + data.user.id}>{data.user.givenName} {data.user.familyName}</a>
      },
      {
        header: 'ID',
        key: 'id',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Authentication',
        key: 'authentication',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Note',
        key: 'note',
        renderWithData: data => <ReadMoreReact text={data.note || ''} readMoreText='read more' />,
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Created at',
        key: 'createdAt',
        renderWithData: data => {
          return toIsoDate(data.createdAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      },
      {
        header: 'Image',
        key: 'fileName',
        renderWithData: data => {
          return data ? <a href={'https://storage.cloud.google.com/' + data.fileName} rel='noopener noreferrer' target='_blank'>view</a> : 'No image'
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      }
    ],
    leads: [
      {
        header: 'Name',
        key: 'name',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Email',
        key: 'email',
        renderWithData: data => {
          return data && data.email ? <a href={'mailto:' + data.email}>{data.email}</a> : 'No email'
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Company name',
        key: 'companyName',
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Email',
        key: 'email',
        renderWithData: data => {
          if (data && data.website && !data.website.match(/^https?:\/\//im)) {
            data.website = `http://${data.website}`
          }
          return data && data.website ? <a href={data.website} target='_blank' rel='noopener noreferrer'>{data.website}</a> : 'No website'
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Message',
        key: 'message',
        renderWithData: data => <ReadMoreReact text={data.message || ''} readMoreText='read more' />,
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Referrer',
        key: 'referrer',
        renderWithData: data => {
          return data && data.referrer ? <a href={data.referrer} target='_blank' rel='noopener noreferrer'>{data.referrer}</a> : 'No referrer'
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.DATA_STYLE
      },
      {
        header: 'Created at',
        key: 'createdAt',
        renderWithData: data => {
          return toIsoDate(data.createdAt)
        },
        headerStyle: this.HEADER_STYLE,
        dataStyle: this.LONG_DATA_STYLE
      }
    ]
  }

  static propTypes = {
    page: PropTypes.string,
    userId: PropTypes.string
  }

  static defaultProps = {
    page: 'users'
  }

  constructor (props) {
    super(props)
    this.state = {
      start: 0,
      showLoadMore: true,
      data: null,
      filter: '',
      refresh: true,
      loading: false
    }

    this.filterablePages = ['users', 'paid_users']
    this.refreshablePages = ['uploads']

    this.updateUserData = this.updateUserData.bind(this)
    this.getUsageData = this.getUsageData.bind(this)
    this.getUserData = this.getUserData.bind(this)
    this.updateAccountType = this.updateAccountType.bind(this)
    this.resetConcurrentRequestCounter = this.resetConcurrentRequestCounter.bind(this)
  }

  resetConcurrentRequestCounter (data) {
    request({
      isChore: true,
      method: 'GET',
      endpoint: '/users/rate-limits/concurrent-requests/clear/' + (data.sponsorUserID || data.userId)
    }, err => {
      this.context.displayModal({
        isForbiddenToClickOutside: true,
        title: 'Warning',
        content:
        <>
          <p>Concurrent request counter {err ? 'could not' : 'is'} reset for {data.name}</p>
          { err && <> <br /><p>{err.message}</p></>}
        </>,
        options: [
          {
            content: 'Ok'
          }
        ]
      })
    })
  }

  componentDidMount () {
    this.setState({
      isProduction: isProduction()
    })
    this.getUsageData()
    this.getUserData()
  }

  updateAccountType (userId, accountTypes) {
    request({
      method: 'POST',
      endpoint: '/admin/users/update-account-type',
      body: {
        userId,
        accountTypes
      }
    })
  }

  updateUserData (options) {
    const { userId, status, email } = options
    if (!userId || !status || !hasDatabase()) {
      return
    }
    const newStatus = status === 'enabled' ? 'disabled' : 'enabled'
    this.context.displayModal({
      isForbiddenToClickOutside: true,
      title: 'Warning',
      content:
      <>
        <p>{`Are you sure to set the user status to ${newStatus}?`}</p>
        <Textarea
          type='text'
          name='editableValue'
          className='editable-textarea'
          placeholder='Enter data'
          onChange={e => {
            this.setState({
              userStatusChangeReason: e.target.value
            })
          }}
        />
      </>,
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: 'Proceed',
          action: () => {
            const reason = (this.state.userStatusChangeReason || '').trim()
            if (!reason) {
              return alert('You must state a reason for this change. The reason will be recorded for audit purposes.')
            }
            request({
              endpoint: `/auth/user/${userId}`,
              body: {
                email,
                reason,
                status: newStatus
              }
            }, (error, response) => {
              if (error) {
                this.context.displayModal({
                  title: 'Unauthorized',
                  content: error.message,
                  options: [
                    {
                      content: 'OK',
                      action: () => this.context.redirect('/login')
                    }
                  ]
                })
              } else {
                this.state.data.filter(u => u.userId === userId)[0].status = newStatus
                this.setState({
                  data: this.state.data
                })
              }
            })
          }
        }
      ]
    })
  }

  getUserData () {
    const { userId } = this.props
    if (!userId || !hasDatabase()) {
      return
    }
    request({
      endpoint: `/auth/user/${userId}`,
      method: 'GET'
    }, (error, response) => {
      if (error) {
        this.context.displayModal({
          title: 'Unauthorized',
          content: error.message,
          isForbiddenToClickOutside: true,
          isClosable: false,
          options: [
            {
              content: 'OK',
              action: () => this.context.redirect('/login')
            }
          ]
        })
      } else {
        this.setState({
          user: response
        })
      }
    })
  }

  getUsageData () {
    const {
      page,
      userId
    } = this.props
    let { start, data } = this.state
    const { filter } = this.state

    const endpointBase = `/admin/${page}`
    const endpoint = userId ? `${endpointBase}/${userId}` : endpointBase
    let endpointUrl = `${endpoint}?start=${start}&limit=250`
    if (filter) {
      endpointUrl = `${endpoint}?filter=${filter}`
    }
    if (this.state.refresh) {
      start = 0
    }
    request({
      endpoint: endpointUrl,
      method: 'GET'
    }, (error, response) => {
      if (error) {
        this.context.displayModal({
          title: 'Unauthorized',
          content: error.message,
          isForbiddenToClickOutside: true,
          isClosable: false,
          options: [
            {
              content: 'OK',
              action: () => this.context.redirect('/login')
            }
          ]
        })
      } else {
        const showLoadMore = response.length === 250
        if (this.state.refresh) {
          data = []
        }
        this.setState({
          showLoadMore,
          start: response?.[response?.length - 1]?.createdAt || start,
          data: (data || []).concat(response),
          loading: false
        })
        if (this.state.refresh && this.refreshablePages.includes(page)) {
          setTimeout(this.getUsageData, 5000)
        }
      }
    })
  }

  render () {
    const {
      isProduction,
      data,
      user,
      showLoadMore,
      loading
    } = this.state
    const {
      page,
      userId
    } = this.props

    const userText = userId
      ? user
        ? `for ${user.givenName} ${user.familyName} (${user.email})`
        : `for "${userId}"`
      : ''

    const anonymousUserId = isProduction ? 'RbJkLhKz9QyrrM6SnbAJ' : 'KBlFa0rlh76sCfliyWGn'
    const showFilter = this.filterablePages.includes(page) || page === ''
    const showRefresh = this.refreshablePages.includes(page)

    return (
      <View name='admin'>
        <Header>
          <div className='sticky'>
            <h1 className='title'>Base64.ai stats for {page?.replace(/_/g, ' ')} {userText ? <><br />{userText}</> : ''} </h1>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
              <AppButton hardLink href='/admin/' className='secondary-cta min-w-36'>Users</AppButton>
              {!isWorkingOffline() && <AppButton hardLink href='/admin/paid_users' className='secondary-cta min-w-36'>Paid Users</AppButton>}
              <AppButton hardLink href={`/admin/uploads/${anonymousUserId}`} className='secondary-cta min-w-36'>Anonymous demos</AppButton>
              <AppButton hardLink href='/admin/uploads/' className='secondary-cta min-w-36'>Last 250 uploads</AppButton>
              {!isWorkingOffline() && <AppButton hardLink href='/admin/leads' className='secondary-cta min-w-36'>Leads</AppButton>}
              <AppButton hardLink href='/admin/issues' className='secondary-cta min-w-36'>Issues</AppButton>
            </div>
          </div>
        </Header>
        <Section
          name='results'
          align='center'
        >
          {
            (loading || !data)
              ? (
                  <div className='placeholder'>
                    <Loading />
                    <h2 className='introduction'>Calculating usage...</h2>
                    <h3 className='paragraph'>Please wait, this may take a few seconds.</h3>
                  </div>
                )
              : (
                  <>
                    {
                      showFilter && (
                        <div className='filter-container'>
                          <input
                            type='text'
                            placeholder='Filter'
                            onChange={e => {
                              this.setState({
                                filter: e.target.value
                              })
                            }}
                            onKeyDown={e => {
                              // send on enter
                              if (e.key === 'Enter') {
                                this.setState({
                                  loading: true,
                                  start: 0,
                                  data: []
                                }, this.getUsageData)
                              }
                            }}
                          />
                          <AppButton
                            className='secondary-cta large'
                            onClick={() => {
                              this.setState({
                                loading: true,
                                start: 0,
                                data: []
                              }, this.getUsageData)
                            }}
                          >
                            Filter
                          </AppButton>
                        </div>
                      )
                    }
                    {
                      showRefresh && (
                        <Checkbox
                          placeholder='Automatically refresh new uploads'
                          className='big'
                          value='refresh'
                          onChange={e => {
                            this.setState({ refresh: e.target.data })
                          }}
                          showTitle={false}
                          checked={this.state.refresh}
                        />
                      )
                    }
                    <SortableTable
                      data={this.state.data}
                      columns={this.CONFIGURATION[page]}
                      style={{
                        borderCollapse: 'separate',
                        borderSpacing: '1px'
                      }}
                      iconStyle={{ paddingLeft: '0.2rem' }}
                    />
                    <p style={{ fontSize: '2rem', lineHeight: '10rem' }}>{showLoadMore ? <a onClick={this.getUsageData}>Click here to load more</a> : 'That\'s all folks!' }</p>
                  </>
                )
          }
        </Section>
      </View>
    )
  }
}

// Export within router
export default withRouter(Admin)
