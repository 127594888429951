import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DataExtractionBox from './DataExtractionBox'
import * as uuid from 'uuid'
import Popup from '../popup/Popup'
import MaterialIcon from '../material-icon/MaterialIcon'

class OCRBox extends Component {
  static propTypes = {
    ocr: PropTypes.string,
    context: PropTypes.object,
    contextProvider: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      mode: 'view',
      height: 'unset',
      copyIconState: 'content_copy',
      isOpen: false
    }
    this.id = uuid.v4()
    this.valueDiv = React.createRef()
    this.updateHandler = this.updateHandler.bind(this)
    this.inputHeightHandler = this.inputHeightHandler.bind(this)
    this.copyIconTimer = null
  }

  componentDidMount () {
    this.inputHeightHandler()
    window.addEventListener('resize', this.inputHeightHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.inputHeightHandler)
    this.clearCopyIconTimer()
    this.setState({ copyIconState: 'content_copy' })
  }

  clearCopyIconTimer () {
    if (this.copyIconTimer) {
      clearTimeout(this.copyIconTimer)
      this.copyIconTimer = null
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.mode !== this.state.mode) {
      this.inputHeightHandler()
    }
    if (prevState.isOpen !== this.state.isOpen) {
      this.props.contextProvider.setState({ doesPopupActive: !!this.state.isOpen })
    }
  }

  updateHandler (ocr) {
    this.props.contextProvider.updateResult('self-object', ocr, { parent: 'ocr' })
  }

  inputHeightHandler () {
    if (this.valueDiv.current && this.state.mode !== 'edit') {
      this.setState({ height: this.valueDiv.current.offsetHeight + 'px' })
    }
  }

  copyToClipboard (ocr) {
    navigator.clipboard.writeText(ocr)
    this.setState({ copyIconState: 'check' })
    this.clearCopyIconTimer()
    this.copyIconTimer = setTimeout(() => {
      this.setState({ copyIconState: 'content_copy' })
    }, 1000)
  }

  renderValue (value) {
    return value.split('\f').join('<hr/>').split('\n').join('<br>')
  }

  render () {
    const first100 = this.props.ocr.substring(0, 100)
    const overflow = first100.length >= 100

    return (
      <DataExtractionBox
        title='Full text OCR'
        selectedDataReference={this.id}
        context={this.props.context}
        isEditable={false}
        isRemovable={false}
        desiredMode='result'
        contextProvider={this.props.contextProvider}
      >
        {this.state.isOpen
          ? (
              <Popup
                title='Full text OCR'
                closeHandler={() => {
                  this.setState({ isOpen: false, mode: 'view' })
                  this.clearCopyIconTimer() // Add this line
                }}
                customIcons={() => (
                  <>
                    <MaterialIcon
                      className='clone-button edit-button'
                      title='Copy text to clipboard'
                      name={this.state.copyIconState} // Use the dynamic icon state
                      onClick={() => {
                        this.copyToClipboard(this.props.ocr)
                      }}
                    />
                    <MaterialIcon
                      className={`edit-button ${this.state.mode !== 'view' ? 'active' : ''}`}
                      name='edit'
                      title='Edit text'
                      onClick={() => {
                        this.setState({ mode: this.state.mode === 'view' ? 'edit' : 'view' })
                      }}
                    />
                  </>
                )}
              >
                {
                  this.state.mode === 'edit'
                    ? (
                        <textarea
                          className='hitl-input ocr-input'
                          value={this.props.ocr}
                          style={{ height: this.state.height }}
                          onChange={e => {
                            this.updateHandler(e.target.value)
                          }}
                        />
                      )
                    : <div className='ocr-view' dangerouslySetInnerHTML={{ __html: this.renderValue(this.props.ocr) }}></div>
                }
              </Popup>
            )
          : <></>}
        <div ref={this.valueDiv}>{first100}{overflow ? '... ' : ''} {overflow
        && (
          <div
            className='view-all'
            onClick={() => {
              this.setState({ isOpen: true })
            }}
          >
            View all
          </div>
        )}
        </div>

      </DataExtractionBox>
    )
  }
}

export default OCRBox
