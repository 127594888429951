// Layout
import { Component } from 'react'
import Section from '~/layout/section/Section'
import Context from '~/context/global'

// Data
import cardInfo from '../../data/card-info'

// Content: CardInfoSection
class CardInfoSection extends Component {
  static contextType = Context

  render () {
    return (
      <Section name='card-info' align='center'>
        <h2 className='title'>The best in class Document AI</h2>
        <h3 className='introduction'>Discover why leading companies, RPA systems, and scanners use Base64.ai for document processing.</h3>
        <div className='info-card-container'>
          {cardInfo.map(s => (
            <div key={s.title} className='info-card' onClick={() => this.context.redirect(s.url)}>
              <h1 className='title'>{s.title}</h1>
              <p className='description'>{s.description}</p>
            </div>
          )
          )}
        </div>
      </Section>
    )
  }
}

// Export
export default CardInfoSection
