// Modules
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// Layout
import View from '~/layout/view/View'
import MediaKitSection from '~/content/media-kit-section/MediaKitSection'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'

class MediaKit extends Component {
  static flyers = {
    'Base64.ai-flyer-a4': {
      description: 'Flyer in A4 format'
    },
    'Base64.ai-flyer-a4-turkish': {
      description: 'Turkish Flyer in A4 format'
    },
    'Base64.ai-flyer-a4-spanish': {
      description: 'Spanish Flyer in A4 format'
    }
  }

  render () {
    return (
      <View
        name='media-kit'
      >
        <Header name='media-kit'>
          <h1 className='slogan'>Media Kit</h1>
          <h3 className='introduction'>
            Here you can find our media kit<br />
            consisting of our brand logos, typography and colors.
          </h3>
        </Header>
        <Section name='flyers' align='center'>
          <h1 className='slogan'>Flyers</h1>
          <ul className='list'>
            {Object.keys(MediaKit.flyers).map((key, index) =>
              (
                <li key={index}>
                  <a href={`/static/media-kit/flyers/${key}.pdf`} target='_blank' className='noBorder icon-anchor' rel='noopener noreferrer'>
                    <img className='logo' src={`/static/media-kit/flyers/${MediaKit.flyers[key].thumbnail || key}.png`} />
                  </a>
                  <p className='description'>{MediaKit.flyers[key].description}</p>
                </li>
              )
            )}
          </ul>
        </Section>
        <MediaKitSection />
        {/* <Section name='colors' align='center'>
          <h1 className='slogan'>Colors</h1>
          <img src='/static/media-kit/base64.ai-colors.png' />
          <div className='hex'>
            <span>#006ce8</span>
            <span>#0f97ff</span>
            <span>#8ccbff</span>
            <span>#f3faff</span>
            <span>#ffffff</span>
            <span>#eeeeee</span>
            <span>#eeeeee</span>
            <span>#6e6f75</span>
            <span>#3d3f47</span>
          </div>
        </Section> */}
        <Section name='typography' align='center'>
          <h1 className='slogan'>Typography</h1>
          <div className='bold'>
            <h2>SF UI Display - Bold</h2>
            <p>ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
            <p>abcdefghijklmnopqrstuvwxyz</p>
          </div>
          <div className='light'>
            <h2>SF UI Display - Light</h2>
            <p>ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
            <p>abcdefghijklmnopqrstuvwxyz</p>
          </div>
        </Section>
      </View>
    )
  }
}

MediaKit.propTypes = {
  location: PropTypes.object
}

export default withRouter(MediaKit)
