import { createContext, useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const LocationContext = createContext(undefined)

export const LocationProvider = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const [searchParamsState, setSearchParamsState] = useState(new URLSearchParams(location.search))
  const [searchParamsObject, setSearchParamsObject] = useState({})

  useEffect(() => {
    setSearchParamsState(new URLSearchParams(location.search))
  }, [location.search])

  useEffect(() => {
    const obj = Object.fromEntries(searchParamsState.entries())
    for (const key in obj) {
      obj[key] = obj[key].split(',')
    }
    setSearchParamsObject(obj)
  }, [searchParamsState])

  const setSearchParams = (key, value) => {
    const currentSearchParams = searchParamsState ?? new URLSearchParams()
    if (value) {
      if (Array.isArray(value)) {
        currentSearchParams.delete(key)
        value.forEach(v => currentSearchParams.append(key, v))
      } else {
        currentSearchParams.set(key, value)
      }
    } else {
      currentSearchParams.delete(key)
    }
    setSearchParamsState(currentSearchParams)
    history.push({
      pathname: location.pathname,
      search: currentSearchParams.toString()
    })
  }

  const value = {
    searchParamsObject,
    setSearchParams
  }

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  )
}

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useLocationContext = () => {
  const context = useContext(LocationContext)
  if (!context) {
    throw new Error('useLocationContext must be used within a LocationProvider')
  }
  return context
}
