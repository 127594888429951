import { Component } from 'react'
// import { Document, Page } from 'react-pdf/dist/umd/entry.webpack'
import { Document, Page, pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
class PdfViewer extends Component {
  state = {
    numPages: null,
    pageNumber: 1
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  goToPrevPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber > 1 ? state.pageNumber - 1 : 1 }))

  goToNextPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber < state.numPages ? state.pageNumber + 1 : state.numPages }))

  render () {
    const {
      pageNumber
    } = this.state
    return (
      <div>
        <div>
          <Document
            file={this.props.image}
            onLoadSuccess={this.onDocumentLoadSuccess}
            onPassword={() => false}
          >
            <Page pageNumber={pageNumber} scale={2} />
          </Document>
        </div>
        <nav className='pdfViewer'>
          <button className='pdfViewer' onClick={this.goToPrevPage}>Prev</button>
          <button className='pdfViewer' onClick={this.goToNextPage}>Next</button>
        </nav>
      </div>
    )
  }
}

PdfViewer.propTypes = {
  image: PropTypes.string.isRequired
}

// Export
export default PdfViewer
