// Modules
import PropTypes from 'prop-types'
import {
  Component,
  createRef,
  forwardRef
} from 'react'

// Interface
import Button from '~/interface/button/Button'
import Link from '~/interface/link/Link'

// Interface: Modal
class Modal extends Component {
  static propTypes = {
    isWide: PropTypes.bool,
    isHITL: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.node,
    showNote: PropTypes.bool,
    options: PropTypes.array,
    close: PropTypes.func,
    isClosable: PropTypes.bool,
    modalRef: PropTypes.object,
    style: PropTypes.object
  }

  static defaultProps = {
    title: '',
    content: '',
    options: [{
      tone: 'information',
      content: 'OK'
    }],
    isClosable: true
  }

  constructor (props) {
    super(props)
    this.noteRef = createRef()
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidMount () {
    document.addEventListener('keydown', this.handleKeyPress)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  handleKeyPress ({ keyCode }) {
    switch (keyCode) {
      case 13: {
        const [positive] = this.props.options.filter(option =>
          option.intent === 'positive'
        )
        if (positive && positive.action) {
          positive.action()
          !positive.willNotClose && this.props.close && this.props.close()
        }
        break
      }
      case 27:
        this.props.close && this.props.close()
        break
    }
  }

  render () {
    const {
      title,
      content,
      options,
      close,
      isClosable,
      showNote,
      isWide,
      isHITL,
      modalRef,
      style
    } = this.props

    let boxStyle = isWide ? {} : isHITL ? { width: '30rem' } : { width: '18rem' }
    if (style) {
      boxStyle = {
        ...boxStyle,
        ...style
      }
    }
    const contentStyle = isWide
      ? {
          overflowY: 'scroll',
          maxHeight: '80vh'
        }
      : {}

    return (
      <div className='modal box' style={boxStyle} ref={modalRef}>
        {(title || isClosable)
        && (
          <div className='modalTitle' style={{ backgroundColor: 'white', borderRadius: 'inherit' }}>
            {title
            && <b className='text'>{title}</b>}
            {isClosable
            && (
              <Link
                className='close noBorder'
                onClick={() => close && close()}
              >
                <img src='/static/images/icons/hitl-close.svg' />
              </Link>
            )}
          </div>
        )}
        {content
        && (
          <div className={`content ${options?.length > 0 ? '' : 'no-options'}`} style={contentStyle}>
            {content}
          </div>
        )}
        {showNote
        && (
          <div className='showNote'>
            <textarea rows='4' name='note' placeholder='Describe the problem here...' ref={this.noteRef} />
          </div>
        )}
        {options && options.length > 0
        && (
          <div className='options'>
            {options.map((option, index) => (
              <Button
                key={index}
                className={option.intent}
                onClick={() => {
                  option.action && option.action(this.noteRef.current && this.noteRef.current.value)
                  !option.willNotClose && close && close()
                }}
              >
                {option.content}
              </Button>
            )
            )}
          </div>
        )}
      </div>
    )
  }
}

// export default Modal
const ModalWithRef = forwardRef((props, ref) => (
  <Modal
    modalRef={ref}
    {...props}
  />
))

ModalWithRef.displayName = 'Modal'

export default ModalWithRef
