// Modules
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from '../../interface/material-icon/MaterialIcon'

// Layout: Section
const Section = forwardRef((props, ref) => (
  <React.Fragment>{
    props.if
      ? (
          <section
            data-section={props.name}
            ref={ref}
            id={
              props.identifier
                ? props.name
                : null
            }
            className={
              (props.className ? `${props.className} ` : '')
              + (props.align ? `${props.align} aligned ` : '')
              + (props.outstanding ? 'outstanding ' : '')
              + (props.tight ? 'tight ' : '')
              + (props.flex ? 'flex ' : '')
              + (props.accordion ? 'accordion ' : '')
              + 'section'
            }
            style={props.style || {}}
          >
            { props.accordion
              ? (
                  <div className={'accordion-header ' + (props.isOpen ? 'open ' : '')} onClick={props.onClick}>
                    <div>{props.title}</div>
                    <MaterialIcon name={props.isOpen ? 'expand_more' : 'chevron_right'} />
                  </div>
                )
              : null}
            {
              !props.accordion || props.isOpen
                ? (
                    <div className={
                      (props.isMax ? 'max ' : '')
                      + 'container'
                    }
                    >
                      {props.children}
                    </div>
                  )
                : null
            }

          </section>
        )
      : <></>
  }
  </React.Fragment>
))

Section.displayName = 'Section'

Section.propTypes = {
  style: PropTypes.object,
  name: PropTypes.string,
  align: PropTypes.string,
  outstanding: PropTypes.bool,
  tight: PropTypes.bool,
  flex: PropTypes.bool,
  isMax: PropTypes.bool,
  identifier: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  if: PropTypes.bool,
  accordion: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func
}

Section.defaultProps = {
  outstanding: false,
  tight: false,
  flex: false,
  identifier: false,
  if: true,
  isOpen: true,
  onClick: () => {}
}

// Export
export default Section
