export const defaultTablePositionOptions = [
  {
    label: 'Top Left',
    value: 'top-left'
  },
  {
    label: 'Top Center',
    value: 'top-center'
  },
  {
    label: 'Top Right',
    value: 'top-right'
  },
  {
    label: 'Center Left',
    value: 'center-left'
  },
  {
    label: 'Center',
    value: 'center'
  },
  {
    label: 'Center Right',
    value: 'center-right'
  },
  {
    label: 'Bottom Left',
    value: 'bottom-left'
  },
  {
    label: 'Bottom Center',
    value: 'bottom-center'
  },
  {
    label: 'Bottom Right',
    value: 'bottom-right'
  }
]

export const defaultTaxonomies = [{
  name: '',
  type: 'text',
  alternateSpelling: [],
  deleteMatchedTaxonomy: false
}]

export const defaultQuestions = [{
  key: '',
  question: ''
}]
