// Modules
import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { Link } from 'react-router-dom'

export const AppButton = ({
  expand = 'centered-flex', // centered-flex | block | full
  shape = 'rounded', // pill | rounded | edgy
  fill = 'solid', // solid | outline | clear
  size = 'medium', // small | medium | large
  color = 'secondary', // brand | primary | secondary | dark | warning | success | danger | gray
  disabled,
  loading,
  hardLink,
  noAnimation,
  href,
  rel,
  target,
  options,
  onClick,
  children,
  className,
  ...rest
}) => {
  // Prepare props for button styles
  if (className?.includes('primary-cta')) {
    shape = 'rounded'
    fill = 'solid'
    size = 'medium'
    color = 'secondary'
    className = className.replace('primary-cta', '')
  }

  if (className?.includes('secondary-cta')) {
    shape = 'rounded'
    fill = 'solid'
    size = 'medium'
    color = 'primary'
    className = className.replace('secondary-cta', '')
  }

  if (className?.includes('small')) {
    size = 'small'
    className = className.replace('small', '')
  }

  if (className?.includes('large')) {
    size = 'large'
    className = className.replace('large', '')
  }

  if (className?.includes('disabled')) {
    disabled = true
    className = className.replace('disabled', '')
  }

  const colorOptions = ['brand', 'primary', 'secondary', 'dark', 'warning', 'success', 'danger', 'gray']
  colorOptions.forEach(colorOption => {
    if (className?.includes(colorOption)) {
      color = colorOption
      className = className.replace(colorOption, '')
    }
  })
  // End of prepare props for button styles

  const ref = useRef()

  const isExternalLink = href ? /^(https?:\/\/|\/\/|mailto:|tel:|ftp:)/.test(href) : false

  const onClickHandler = e => {
    if (hardLink && href) {
      window.location.href = href
      return
    }

    if (disabled) {
      e.preventDefault()
      return
    }

    if (!href) {
      e.preventDefault()
    }

    onClick?.(e)
  }

  return (
    <Tippy
      ref={ref}
      disabled={!options?.length}
      placement='bottom-end'
      interactive={true}
      trigger='click'
      theme='light'
      arrow={false}
      animation='shift-away'
      offset={[0, 2]}
      content={(
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
          {
            options?.length > 0 && options.map((option, index) => (
              <AppButton
                key={index}
                fill='clear'
                target='_blank'
                href={option.href}
                onClick={event => {
                  const tippy = ref?.current?._tippy

                  option.onClick?.(event)
                  tippy?.hide?.()
                }}
                disabled={option.disabled}
                className={clsx('dropdown-item')}
                noAnimation
              >
                {option.name}
              </AppButton>
            ))
          }
        </div>
      )}
    >
      <Link
        target={isExternalLink && !target ? '_parent' : target}
        to={isExternalLink ? { pathname: href } : href}
        rel={isExternalLink ? (rel || 'noopener noreferrer') : rel}
        className={clsx(
          'AppButton',
          options?.length && 'dropdown',
          expand,
          shape,
          fill,
          size,
          loading && 'loading',
          color && `color-${color}`,
          noAnimation && 'no-animation',
          className
        )}
        disabled={disabled || loading}
        onClick={onClickHandler}
        {...rest}
      >
        {children}
      </Link>
    </Tippy>
  )
}

AppButton.propTypes = {
  expand: PropTypes.string,
  shape: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  noAnimation: PropTypes.bool,
  hardLink: PropTypes.bool,
  href: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      href: PropTypes.string,
      disabled: PropTypes.bool
    })
  ).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string
}
