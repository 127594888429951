export default [
  {
    title: '1 AI',
    url: '/demo',
    description: 'One solution for all documents, including IDs, passports, invoices, checks, forms, and more.'
  },
  {
    title: 'INTEGRATED',
    url: '/integrations',
    description: '400+ no-code integration to third-party systems for under 1 hour of integration time.'
  },
  {
    title: 'EXTENSIBLE',
    url: '/flow',
    description: 'Add new document types, integrations, and business rules. Command the AI for your needs.'
  },
  {
    title: 'FAST',
    url: '/flow',
    description: 'For most document types, OCR, data extraction, and integration take under 3 seconds.'
  },
  {
    title: 'SECURE',
    url: '/compliance',
    description: 'Certified for ISO, HIPAA, SOC 2 Type 1 & 2, and GDPR, Base64.ai works in the cloud and on-premises datacenters.'
  },
  {
    title: 'ACCURATE',
    url: '/flow',
    description: '99% extraction accuracy for most document types. Base64.ai improves with every document.'
  },
  {
    title: 'PII REDACTION',
    url: '/demo/redaction',
    description: 'Detect and remove sensitive information such as names, dates, and document numbers.'
  },
  {
    title: 'AVAILABLE EVERYWHERE',
    url: '/integrations',
    description: 'Use Base64.ai via API, RPA systems, scanners, web, mobile apps, and others in our partner network.'
  },
  {
    title: 'HUMAN IN THE LOOP',
    url: '/flow',
    description: 'Our Document Reviewer Team instantly verifies your results 24/7 for 100% data extraction accuracy.'
  }
]
