// Modules
import { forwardRef } from 'react'
import PropTypes from 'prop-types'

// Layout: Block
const Block = forwardRef((props, ref) => (
  <article
    data-block={props.name}
    ref={ref}
    id={
      props.identifier
        ? props.name
        : null
    }
    className={
      (props.className ? `${props.className} ` : '')
      + (props.align ? `${props.align} aligned ` : '')
      + 'block'
    }
  >
    {props.children}
  </article>
))

Block.displayName = 'Block'

Block.propTypes = {
  name: PropTypes.string,
  align: PropTypes.string,
  identifier: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
}

Block.defaultProps = {
  identifier: false
}

// Export
export default Block
