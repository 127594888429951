import { useEffect, useState } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { useFlows } from '../../providers/FlowContext'
import { withRouter } from 'react-router-dom'
const defaultOptions = [
  {
    label: 'Ask current document', value: ''
  },
  {
    label: 'Ask a Flow that has AI Search enabled', options: []
  }
]

const defaultOptionIndex = 0

const FlowSelector = ({ onChange }) => {
  const [options, setOptions] = useState(defaultOptions)
  const { flows } = useFlows()

  useEffect(() => {
    const newOptions = structuredClone(defaultOptions)
    const eligibleFlows = flows.filter(flow => (flow.role.includes('uploader') && flow.role.length === 1) || flow?.flowSearch?.status === 'enabled')
    eligibleFlows.sort((a, b) => a.isDefault ? -1 : (b.isDefault ? 1 : (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0))))
    eligibleFlows.forEach(flow => {
      newOptions[1].options.push({
        label: flow.name,
        value: flow.flowID
      })
    })
    if (!newOptions[1].options.length) {
      newOptions[1].options.push({ label: <i>No Flows have AI Search enabled</i>, value: '', isDisabled: true })
    }
    setOptions(newOptions)
  }, [flows])

  return (
    <Select
      styles={{
        menuPortal: base => ({
          ...base,
          fontSize: '0.5rem'
        }),
        control: base => ({
          ...base,
          height: '2.6rem',
          zIndex: 1000
        }),
        container: provided => ({
          ...provided,
          marginBottom: '0.5rem',
          zIndex: 1000
        }),
        menu: provided => ({
          ...provided,
          width: 'calc(100% - 4px)',
          marginLeft: '2px'
        })
      }}
      classNamePrefix='react-select'
      className='react-select'
      defaultValue={defaultOptions[defaultOptionIndex]}
      options={options}
      closeMenuOnSelect
      placeholder='Ask to ...'
      onChange={changed => {
        onChange?.(changed)
      }}
    />
  )
}

FlowSelector.propTypes = {
  onChange: PropTypes.func
}

export default withRouter(FlowSelector)
