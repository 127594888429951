import { useState, useRef, useEffect } from 'react'
import MaterialIcon from '../material-icon/MaterialIcon'
import PropTypes from 'prop-types'
import tippy from 'tippy.js'

const ClipboardButton = ({ contentToCopy = '', onCopy, title }) => {
  const ref = useRef(null)
  const [copied, setCopied] = useState(false)

  const handleCopy = async () => {
    try {
      if (!copied) {
        setCopied(true)
        await navigator.clipboard.writeText(contentToCopy)
        setTimeout(() => setCopied(false), 1500)
        onCopy?.()
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    if (ref.current) {
      tippy(ref.current, {
        content: 'Copied to clipboard ✅',
        placement: 'top',
        animation: 'shift-away',
        arrow: false,
        trigger: 'click',
        onShow (instance) {
          setTimeout(() => {
            instance.hide()
          }, 1500)
        }
      })
    }
  }, [])

  return (
    <MaterialIcon
      htmlRef={ref}
      title={title || 'Copy to clipboard'}
      name={copied ? 'check' : 'content_copy'}
      style={{ color: copied ? '#399E86' : undefined }}
      onClick={handleCopy}
      className='hover-blue'
    />
  )
}

ClipboardButton.propTypes = {
  contentToCopy: PropTypes.string,
  onCopy: PropTypes.func,
  title: PropTypes.string
}

export default ClipboardButton
