// Modules
import React, { Component } from 'react'
// Context
import Context from '~/context/global'
import { getBase64 } from '~/helpers/file'
// Helpers
import { request } from '~/helpers/request'
// Interface
import FileInput from '~/interface/file-input/FileInput'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import { reCaptcha } from '~/helpers/authentication'
import StatusToEmoji from '~/interface/validation-sign/StatusToEmoji'
// Utilities
import { triggerTrackingEvent } from '../../utilities/tracker'

import Paper from 'paper'

// View: Demo
class SignatureVerificationDemo extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.referenceImageRef = React.createRef()
    this.referenceImageCanvasRef = React.createRef()
    this.referenceImageFigureRef = React.createRef()
    this.queryDocumentRef = React.createRef()
    this.queryDocumentCanvasRef = React.createRef()
    this.queryDocumentFigureRef = React.createRef()
    this.similarityThreshold = 0.75
    this.state = {
      responseMessage: 'Please upload two separate documents with signatures, it will show the matches if any.',
      extractedSignatures: null,
      referenceImageBase64: null,
      referenceImageUrl: null,
      queryDocumentUrl: null,
      queryDocumentBase64: null
    }
    this.drawRects = this.drawRects.bind(this)
    this.handleFiles = this.handleFiles.bind(this)
    this.makeRequest = this.makeRequest.bind(this)
    this.recognizeSignatures = this.recognizeSignatures.bind(this)
    this.showSampleLink = this.showSampleLink.bind(this)
  }

  handleFiles (accepted, rejected, isReference) {
    this.setState({
      extractedSignatures: null
    })
    if (accepted && accepted.length) {
      getBase64({ source: accepted[0] }, (error, response) => {
        if (error) {
          this.setState({
            responseStatus: 'error',
            responseMessage: 'Cannot read the image file!'
          })
          return
        }
        if (isReference) {
          this.setState({
            referenceImageBase64: response
          })
          this.setState({ // reset query image when reference image is changed, useful after query response
            queryDocumentBase64: null
          }, this.recognizeSignatures)
        } else {
          this.setState({
            queryDocumentBase64: response
          }, this.recognizeSignatures)
        }
      })
    }
    if (rejected && rejected.length) {
      this.setState({
        responseStatus: 'error',
        responseMessage: 'Invalid file format'
      })
    }
  }

  makeRequest = () => {
    triggerTrackingEvent('demo-completed-signature-verification')
    reCaptcha('demo', token => {
      const queryDocumentOrUrl = this.state.queryDocumentBase64 ? { query: this.state.queryDocumentBase64 } : { queryUrl: this.state.queryDocumentUrl }
      const documentImageOrUrl = this.state.referenceImageBase64 ? { document: this.state.referenceImageBase64 } : { url: this.state.referenceImageUrl }
      request({
        endpoint: '/signature',
        body: {
          token,
          ...queryDocumentOrUrl,
          ...documentImageOrUrl
        }
      }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: error.message + '.',
            responseStatus: 'error'
          })
          return
        }
        if (response.message) {
          this.setState({
            responseMessage: error,
            responseStatus: 'error'
          })
          return
        }
        if (response.reference.length) {
          const numberOfMatches = response.query.filter(it => it.similarities.filter(it2 => it2 > this.similarityThreshold).length > 0).length
          this.setState({
            responseMessage: `
              ${response.reference.length} signature(s) are detected. 
              ${numberOfMatches > 0
                ? numberOfMatches + ' signature(s) are matched. Please select a signature on the left to see the matches'
                : 'No signature(s) are matched'}.`,
            responseStatus: 'success',
            extractedSignatures: response
          }, this.drawOnSource)
          return
        }
        this.setState({
          responseMessage: 'No signatures were found.',
          responseStatus: 'warning'
        })
      }, err => {
        this.setState({
          responseMessage: 'Error happened while processing: ' + err.message,
          responseStatus: 'error'
        })
      })
    })
  }

  clearCanvases = () => {
    this.setState({ extractedSignatures: null })

    Paper.setup(this.referenceImageCanvasRef.current) // clear all canvas elements
    // clear the canvas
    if (Paper.project) {
      Paper.project.activeLayer.removeChildren()
      Paper.view.draw()
    }

    Paper.setup(this.queryDocumentCanvasRef.current)
    // clear the canvas
    if (Paper.project) {
      Paper.project.activeLayer.removeChildren()
      Paper.view.draw()
    }
  }

  // this method waits for both images to be uploaded
  recognizeSignatures () {
    if ((this.state.referenceImageBase64 || this.state.referenceImageUrl) && (this.state.queryDocumentBase64 || this.state.queryDocumentUrl)) {
      this.setState({
        responseStatus: 'info',
        responseMessage: 'Extracting and verifying signatures...'
      }, this.makeRequest)
      this.clearCanvases()
    }
  }

  showSampleLink (status) {
    if (status) {
      return null
    }
    return (
      <a
        className='showSample'
        onClick={() => {
          this.setState({
            referenceImageUrl: window.location.origin + '/static/content/features/signature-verification/signature1.png', // 'https://base64.ai/static/content/features/signature-verification/signature1.png'
            queryDocumentUrl: window.location.origin + '/static/content/features/signature-verification/signature2.png'
          }, this.recognizeSignatures)
        }}
      >Show sample
      </a>
    )
  }

  render () {
    const { responseMessage, responseStatus, referenceImageBase64, referenceImageUrl, queryDocumentUrl, queryDocumentBase64 } = this.state
    return (
      <View name='signature-verification-demo'>
        <Header name='header'>
          <h1 className='slogan'>Signature Verification AI</h1>
          <h2 className='introduction'>Replace manual signature verification with our fast &amp; reliable AI</h2>
          <h3 className='introduction'>
            Extract and compare signatures on documents and photos for signature verification. To try it out, upload a document containing signatures.
            Base64.ai will detect signatures on both images and determine if they match. Works with all forms, contracts, passports, and more!
          </h3>
          <h2 className='introduction'>Signature verification may take up to a minute. Thank you for your patience.</h2>
        </Header>
        <Section name='output'>
          <div>
            <StatusToEmoji status={responseStatus} message={responseMessage} />
            {this.showSampleLink(responseStatus)}
          </div>
        </Section>
        <Section
          name='input'
          tight={true}
        >
          <div className='uploadBox uploadBoxLeft'>
            <FileInput
              double={true}
              dropText='Upload a document'
              multiple={false}
              indicator={true}
              disabled={this.state.responseStatus === 'info'}
              accept={[
                'image/png',
                'image/jpg',
                'image/jpeg',
                'image/gif'
              ]}
              onDrop={(accepted, rejected, event) => {
                this.handleFiles(accepted, rejected, true)
                this.clearCanvases()
              }}
            />
            {referenceImageBase64 || referenceImageUrl
              ? (
                  <figure ref={this.referenceImageFigureRef}>
                    <canvas ref={this.referenceImageCanvasRef} width={600} height={720} />
                    <img src={referenceImageBase64 || referenceImageUrl} ref={this.referenceImageRef} />
                  </figure>
                )
              : null}
          </div>
          <div className='uploadBox'>
            <FileInput
              double={true}
              dropText='Upload the other document'
              multiple={false}
              indicator={true}
              disabled={this.state.responseStatus === 'info'}
              accept={[
                'image/png',
                'image/jpg',
                'image/jpeg',
                'image/gif'
              ]}
              onDrop={(accepted, rejected, event) => {
                this.handleFiles(accepted, rejected)
                this.clearCanvases()
              }}
            />
            {queryDocumentBase64 || queryDocumentUrl
              ? (
                  <figure ref={this.queryDocumentFigureRef}>
                    <canvas ref={this.queryDocumentCanvasRef} width={600} height={720} />
                    <img src={queryDocumentBase64 || queryDocumentUrl} ref={this.queryDocumentRef} />
                  </figure>
                )
              : null}
          </div>
        </Section>
        {queryDocumentBase64 || queryDocumentUrl
          ? (
              <Section style={{ paddingTop: '0rem', margin: '10px 0' }}>
                {/* <p className='introduction' style={{ margin: '10px' }}>Please click the one of the signatures on the left to see the matches.</p> */}
              </Section>
            )
          : null}
      </View>
    )
  }

  drawSourceRectangle = (id, x, y, width, height, showId) => {
    const rect = new Paper.Path.Rectangle(new Paper.Point(x, y), new Paper.Size(width, height))
    rect.strokeWidth = 1
    rect.strokeColor = '#00dddd'
    rect.fillColor = '#00dddd55'
    rect.dashArray = [4, 2]
    rect.name = showId

    var text = new Paper.PointText(new Paper.Point(x, y + height + 16))
    text.fillColor = '#00dddd'
    text.fontSize = 15
    text.content = 'Signature ' + showId

    const highlightRectangle = () => {
      this.drawOnTarget(id)
      rect.strokeWidth = 2
      rect.fillColor = '#00dddd55'
    }

    rect.onMouseDown = () => {
      this.state.rectangles.forEach(r => {
        r.strokeWidth = 1
        r.fillColor = '#00dddd'
      })
      highlightRectangle()
    }

    return rect
  }

  drawTargetRectangle = (percentage, x, y, width, height, explanation, isFilled, id) => {
    const rect = new Paper.Path.Rectangle(new Paper.Point(x, y), new Paper.Size(width, height))
    rect.strokeWidth = 2
    rect.strokeColor = isFilled ? '#00dddd' : '#333366'
    rect.fillColor = isFilled ? '#00dddd55' : '#33336655'
    rect.dashArray = [4, 2]

    var text = new Paper.PointText(new Paper.Point(x, y + height + 16))
    text.fillColor = '#00dddd'
    text.fontSize = 14
    text.content = explanation + percentage + '%'
  }

  sortSignatures = list => {
    const getGroupAverage = (list, gid) => {
      const sublist = list.filter(it => it.group === gid)
      return sublist.reduce((a, b) => a + b.top, 0) / sublist.length
    }
    const ySorted = list.map((it, ind) => { // assign id and group
      it.id = ind
      it.group = null
      return it
    }).sort((a, b) => {
      return a.top - b.top
    }) // sort signatures acc. to y coordinate
    let gid = 0
    let counter = 0
    ySorted[0].group = gid // assign to the first group initially
    ySorted.slice(1).forEach(it => { // start from the second item
      if (Math.abs(it.top - getGroupAverage(ySorted, gid)) < 36) { // group items if they are close enough (36 empirically selected as threshold)
        it.group = gid
      } else {
        // sort old group with respect to x coordinate
        const sublist = ySorted.filter(it => it.group === gid).sort((a, b) => {
          return a.left - b.left
        })
        sublist.forEach(it => {
          it.new_id = ++counter
        })

        // create a new group
        it.group = ++gid
      }
    })

    // sort the last group with respect to x coordinate
    const sublist = ySorted.filter(it => it.group === gid).sort((a, b) => {
      return a.left - b.left
    })
    sublist.forEach(it => {
      it.new_id = ++counter
    })
  }

  drawOnSource = () => {
    const canvas = this.referenceImageCanvasRef.current
    if (!canvas || !this.state.extractedSignatures) {
      return
    }
    const reference = this.state.extractedSignatures.reference
    this.sortSignatures(reference)

    canvas.width = 610 // this.referenceImageFigureRef.current.clientWidth
    canvas.height = 720 // this.referenceImageFigureRef.current.clientHeight
    const scale = this.referenceImageRef.current.naturalWidth / this.referenceImageRef.current.clientWidth

    Paper.setup(canvas)

    const rectangles = reference.map((item, i) => {
      return this.drawSourceRectangle(i, item.left / scale, item.top / scale, item.width / scale, item.height / scale, item.new_id)
    })
    this.setState({ rectangles })

    this.drawOnTarget(reference.find(r => r.new_id === 1).id) // get the id of the signature whose new_id is 1
    const signature1 = rectangles.find(r => r.name === 1) // get the rectangle whose new_id is 1. name stores new_id attribute

    signature1.strokeWidth = 2
    signature1.fillColor = '#00dddd55'

    Paper.view.draw()
  }

  drawOnTarget = id => {
    const canvas = this.queryDocumentCanvasRef.current

    if (!canvas || !this.state.extractedSignatures) {
      return
    }
    const response = this.state.extractedSignatures
    canvas.width = 620 // this.queryDocumentFigureRef.current.clientWidth
    canvas.height = 720 // this.queryDocumentFigureRef.current.clientHeight
    const scale = this.queryDocumentRef.current.naturalWidth / this.queryDocumentRef.current.clientWidth

    Paper.setup(canvas)

    // const matched = response.matchedIndexes.filter(it => it.sourceIndex === id)
    const matched = response.query.filter(it => {
      return it.similarities[id] > this.similarityThreshold
    })
      .sort((a, b) => {
        return b.similarities[id] - a.similarities[id]
      }) // sort similarities in descending order

    if (matched.length > 0) { // display the best match
      const best = matched[0]
      this.drawTargetRectangle(
        (best.similarities[id] * 100),
        best.left / scale,
        best.top / scale,
        best.width / scale,
        best.height / scale,
        'Best match: ', true)
    }

    matched.slice(1).forEach((item, index) => { // display the alternate match
      this.drawTargetRectangle(
        (item.similarities[id] * 100),
        item.left / scale,
        item.top / scale,
        item.width / scale,
        item.height / scale,
        'Alternate ' + (index + 1) + ': ', false)
    })

    Paper.view.draw()
  }

  drawRects () {
    const canvas = this.queryDocumentCanvasRef.current

    if (!canvas) {
      return
    }
    canvas.width = this.queryDocumentFigureRef.current.clientWidth
    canvas.height = this.queryDocumentFigureRef.current.clientHeight
    const scale = this.queryDocumentRef.current.naturalWidth / this.queryDocumentRef.current.clientWidth

    const images = this.state.recognizedFaceImages
    if (!images || images.length === 0) {
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      return
    }

    images.forEach(img => {
      const context = canvas.getContext('2d')
      context.beginPath()
      context.lineWidth = 3
      context.rect(
        img.left / scale,
        img.top / scale,
        img.width / scale,
        img.height / scale
      )
      context.setLineDash([5, 5])
      context.strokeStyle = '#00dddd'
      context.stroke()
    })
  }
}

// Export
export default SignatureVerificationDemo
