// Modules
import PropTypes from 'prop-types'

// Interface: LoadingCircle
const LoadingCircle = props => {
  const classNames = 'spinner-item ' + (props?.size ? props.size : '')

  return (
    <div data-view='animation' style={{ width: props.width ? props.width : '49%' }}>
      <div className='base64-spinner'>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
        <div className={classNames} style={{ background: props.color ? props.color : '#00dddd' }}></div>
      </div>
    </div>
  )
}

LoadingCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string
}

// Export
export default LoadingCircle
