// Modules
import PropTypes from 'prop-types'

// Shape: Hamburger Menu
const HamburgerMenu = props => (
  <svg
    viewBox='0 0 22 18'
    className={`${props.name} icon`}
  >
    <path
      fill={props.color || ''}
      d='M2,0 L20,0 C21.1045695,0 22,0.8954305 22,2 C22,3.1045695 21.1045695,4 20,4 L2,4 C0.8954305,4 0,3.1045695 0,2 C0,0.8954305 0.8954305,0 2,0 Z M2,7 L20,7 C21.1045695,7 22,7.8954305 22,9 C22,10.1045695 21.1045695,11 20,11 L2,11 C0.8954305,11 0,10.1045695 0,9 C0,7.8954305 0.8954305,7 2,7 Z M2,14 L20,14 C21.1045695,14 22,14.8954305 22,16 C22,17.1045695 21.1045695,18 20,18 L2,18 C0.8954305,18 0,17.1045695 0,16 C0,14.8954305 0.8954305,14 2,14 Z'
    />
  </svg>
)

HamburgerMenu.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string
}

// Export
export default HamburgerMenu
