import { Component } from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from '../../interface/material-icon/MaterialIcon'
import Link from '../../interface/link/Link'
import { request } from '~/helpers/request'
import Section from '../../layout/section/Section'
import View from '../../layout/view/View'
import Header from '../../layout/header/Header'
// Context
import Context from '~/context/global'
import Button from '../../interface/button/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import window from 'global/window'
import MultiEmail from '../../interface/multi-email/MultiEmail'
import Checkbox from '../../interface/checkbox/Checkbox'
import Select from 'react-select'
import tabs from '../../data/customModelTabs'
import ResultMustHaveDisabledFields from '../../interface/result-must-have-disabled-fields/ResultMustHaveDisabledFields'
import { hasFeature } from '../../context/environment'
import FileInput from '~/interface/file-input/FileInput'
import acceptedFileFormats from '~/data/acceptedFileFormats'
import { getBase64 } from '../../helpers/file'
import { capitalizeFirstLetter } from '../../utilities/format'
import { Helmet } from 'react-helmet'
import ConfigurationWrapper from '../../interface/configuration-wrapper/ConfigurationWrapper'
import LoadingModal from '../../interface/loading-modal/LoadingModal'
import IntegrationManager from '../../interface/integration-manager/IntegrationManager'
import RadioButton from '../../interface/radio-button/RadioButton'
import CustomModelFlowsSection from './sections/CustomModelFlowsSection'
import CloneComponent from '../clone-component/CloneComponent'
import BackButton from '../../interface/back-button/BackButton'
import QuestionAnswer from '../../interface/question-answer/QuestionAnswer'
import Taxonomy from '../../interface/taxonomy/Taxonomy'
import { defaultQuestions, defaultTaxonomies, Messages } from '~/constants'
import { isEqual } from 'radash'
import { HelpButton } from '../../interface/help-button/HelpButton'
import { NavigationPrompt } from '../../interface/navigation-prompt/NavigationPrompt'
import { AppButton } from '../../interface/app-button/AppButton'
import { AppDropdown } from '../../interface/app-dropdown/AppDropdown'
import { clsx } from 'clsx'

class EditCustomModel extends Component {
  static contextType = Context

  static propTypes = {
    user: PropTypes.object,
    isEdit: PropTypes.bool,
    modelUUID: PropTypes.string,
    activeTab: PropTypes.string,
    flows: PropTypes.array
  }

  constructor (props) {
    super(props)
    this.state = {
      user: this.props.user,
      isLoaded: !this.props.modelUUID,
      isSubmitted: true,
      messageObject: this.props.modelUUID
        ? {
            title: 'Loading...',
            description: 'Please wait, this may take a few seconds.',
            status: 'loading',
            code: null
          }
        : LoadingModal.getEmptyMessageObject(),
      model: {},
      name: '',
      modelUUID: '',
      administrators: [],
      users: [],
      ai: {
        formReader: { status: 'disabled' },
        tableReader: { status: 'disabled' },
        checkBoxDetection: { status: 'disabled' },
        entityRecognition: { status: 'disabled' },
        ibanDetection: { status: 'disabled' },
        signatureDetection: { status: 'disabled' },
        faceDetection: { status: 'disabled' },
        orderTextVertically: { status: 'disabled' },
        qrCodeDetection: { status: 'disabled' }
      },
      classification: {},
      questions: defaultQuestions,
      pageCount: {
        label: 'Unlimited',
        value: 'unlimited'
      },
      documentShape: {
        label: 'Unspecified',
        value: 'unspecified'
      },
      status: 'enabled',
      isMobile: window?.innerWidth < 767,
      isBottomSaveButtonEnabled: window?.scrollY > 150,
      taxonomies: defaultTaxonomies,
      resultManipulation: {
        disabledFields: [],
        mustHaveFields: [],
        disableAllExceptMustHaveFields: false
      },
      extensions: [],
      sampleFiles: [],
      deploymentServer: '',
      isFeatureModel: false,
      analysisLoading: false,
      postProcessing: {}
    }

    this.tabList = Object.keys(tabs)
    this.classificationFields = [
      {
        title: 'Express method',
        paragraph:
         <>
           If your document includes <b>all</b> of the following text, it will be automatically classified as this custom model. Otherwise, the AI will use other classification methods.
         </>,
        placeholder: 'Enter text...',
        data: 'directHave'
      },
      {
        title: 'Probability score',
        paragraph: 'Add the following text from the document to increase the classification probability. The more of the following text is found in the document, the more likely it is to be classified as this custom model.',
        placeholder: 'Enter text...',
        data: 'regex'
      },
      {
        title: 'Overrides',
        paragraph: 'Override a classification match using the following rules. This section is useful for fine-tuning classification across multiple document types.',
        subColumns: [
          {
            title: 'Must-have text',
            paragraph:
            <>
              Your document will only be classified under this custom model if <b>all</b> of the following text is present.
            </>,
            placeholder: 'Enter text...',
            data: 'mustHave'
          },
          {
            title: 'Banned text',
            paragraph:
            <>
              Your document will not be classified under this custom model if <b>any</b> of the following text is present.
            </>,
            placeholder: 'Enter text...',
            data: 'cantHave',
            marginTop: '1rem'
          }
        ]
      }
    ]
    this.renderCancelSaveContainer = this.renderCancelSaveContainer.bind(this)
    this.createCustomModel = this.createCustomModel.bind(this)
    this.makeRequest = this.makeRequest.bind(this)
    this.renderClassificationFields = this.renderClassificationFields.bind(this)
    this.renderAutomaticClassificationBlock = this.renderAutomaticClassificationBlock.bind(this)
    this.analyzeSampleFiles = this.analyzeSampleFiles.bind(this)
    this.fetchCustomModel = this.fetchCustomModel.bind(this)
    this.updateStates = this.updateStates.bind(this)
    this.getPageCountOptions = this.getPageCountOptions.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
    this.scrollHandler = this.scrollHandler.bind(this)
    this.accordionSectionOnClickHandler = this.accordionSectionOnClickHandler.bind(this)
    this.setIsSubmitted = this.setIsSubmitted.bind(this)
  }

  componentDidMount () {
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('scroll', this.scrollHandler)
    if (this.props.isEdit && !this.props.modelUUID) {
      this.context.redirect('/custom-models')
    }
    if (this.props.isEdit) {
      this.fetchCustomModel()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('scroll', this.scrollHandler)
  }

  resizeHandler () {
    this.setState({
      isMobile: window.innerWidth < 767
    })
  }

  scrollHandler () {
    this.setState({
      isBottomSaveButtonEnabled: window?.scrollY > 150
    })
  }

  fetchCustomModel () {
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Loading...',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      }
    })
    request({
      endpoint: ('/custom-model/' + this.props.modelUUID),
      method: 'GET'
    }, (error, response) => {
      if (response.message) {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while fetching the custom model',
            description: response.message,
            status: 'error',
            code: response.code
          }
        })
        return
      }
      if (error) {
        this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while fetching the custom model',
            description: error,
            status: 'error',
            code: null
          }
        })
        return
      }
      if (!response?.modelUUID) {
        return this.setState({
          isLoaded: false,
          messageObject: {
            title: 'Error while fetching the custom model',
            description: 'Requested custom model does not exist',
            status: 'error',
            code: null
          }
        })
      }
      this.updateStates(response)
    })
  }

  isFeatureEnabled (ai, service) {
    return ai?.[service]?.status === 'enabled'
  }

  getAIFeatureOptions (ai, service) {
    if (!this.isFeatureEnabled(ai, service)) {
      return
    }
    if (!Object.keys(ai?.[service]?.options || {}).length) {
      return
    }
    return ai?.[service]?.options
  }

  getDefaultAIFeaturesConfiguration (service) {
    const serviceMap = {
      entityRecognition: {},
      checkBoxDetection: {
        minAspectRatioThreshold: 0.75
      },
      signatureDetection: {
        maxNumberOfSignatures: 1,
        signatureDetectionThreshold: 0.4
      },
      faceDetection: {
        maxNumberOfFaces: 1
      }
    }
    if (!serviceMap[service]) {
      return
    }
    return serviceMap[service]
  }

  updateStates (response, callback) {
    this.setState({
      isLoaded: true,
      messageObject: LoadingModal.getEmptyMessageObject(),
      modelUUID: response.modelUUID,
      name: response.name || '',
      model: {
        ...response
      },
      ai: {
        ...(response?.features?.ai || {})
      },
      postProcessing: response?.postProcessing || {},
      classification: response?.classification || {
        directHave: [],
        mustHave: [],
        cantHave: [],
        regex: []
      },
      role: response?.role || [],
      administrators: response?.administrators || [],
      users: response?.users || [],
      questions: this.serializeQuestions(response?.questions),
      pageCount: this.deserializePageCount(response?.features?.pageCount, response?.features?.multiPage),
      documentShape: this.deserializeDocumentShape(response?.classification?.shapes?.[0] || 'unspecified'),
      taxonomies: response?.taxonomies?.length
        ? response.taxonomies
        : [{
            name: '',
            type: 'text',
            alternateSpelling: []
          }],
      status: response?.status === 'enabled' ? 'enabled' : 'disabled',
      resultManipulation: {
        disabledFields: response?.resultManipulation?.disabledFields || [],
        mustHaveFields: response?.resultManipulation?.mustHaveFields || [],
        disableAllExceptMustHaveFields: !!response?.resultManipulation?.disableAllExceptMustHaveFields
      },
      extensions: response?.extensions || [],
      isFeatureModel: response.isFeatureModel,
      deploymentServer: response.deploymentServer,
      isSubmitted: true,
      sampleFiles: []
    }, () => callback && callback())
  }

  deserializeDocumentShape (documentShape) {
    return {
      label: this.getDocumentShapeOptions().find(f => f.value === documentShape)?.label || documentShape || 'Unspecified',
      value: documentShape || 'unspecified'
    }
  }

  serializeDocumentShape (documentShape) {
    return documentShape.value
  }

  serializePageCount (pageCount) {
    return pageCount.value
  }

  serializeQuestions (questions) {
    if (Array.isArray(questions || [])) {
      return !questions?.length
        ? [{
            key: '',
            question: ''
          }]
        : questions
    }
    if (typeof questions === 'object') {
      const serializedQuestions = []
      Object.keys(questions).forEach(key => {
        const q = {
          key,
          question: questions[key]
        }
        serializedQuestions.push(q)
      })
      return serializedQuestions
    }
    return [{
      key: '',
      question: ''
    }]
  }

  deserializePageCount (pageCount, isMultiPage) {
    if (isMultiPage || !pageCount) {
      return { label: 'Unlimited', value: 'unlimited' }
    }
    return { label: pageCount, value: pageCount }
  }

  getPageCountOptions () {
    const pageCountOptions = [{ label: 'Unlimited', value: 'unlimited' }]
    for (let i = 1; i < 51; i++) {
      pageCountOptions.push({
        label: `${i}`,
        value: `${i}`
      })
    }
    return pageCountOptions
  }

  getDocumentShapeOptions () {
    return [
      { label: 'Unspecified', value: 'unspecified' },
      { label: 'Card', value: 'card' },
      { label: 'Check', value: 'check' },
      { label: 'Letter (portrait or landscape)', value: 'letter' },
      { label: 'Receipt', value: 'receipt' }
    ]
  }

  deleteModel () {
    this.context.displayModal({
      title: 'Success',
      content: (
        <div>
          <p>Are you sure you want to delete this model?</p>
        </div>
      ),
      options: [{
        content: 'Cancel',
        intent: 'positive'
      }, {
        content: 'Yes',
        intent: 'negative',
        action: () => {
          this.setState({
            model: {
              ...this.state.model,
              status: 'deleted'
            }
          }, () => {
            this.makeRequest({ isDeleteOperation: true })
          })
        }
      }],
      isClosable: true,
      isWide: true
    })
  }

  createCustomModel () {
    const modelData = this.prepareModelData()
    this.setState({
      model: modelData
    }, () => {
      window.scrollTo(0, 0)
      this.makeRequest()
    })
  }

  setIsSubmitted (callback) {
    this.setState({
      isSubmitted: true
    }, callback)
  }

  prepareModelData () {
    const {
      name, administrators, users, ai, classification, model, questions, taxonomies, resultManipulation, pageCount, documentShape, status, extensions, postProcessing
    } = this.state
    const modelData = {
      ...model,
      classification,
      status
    }
    if (name) {
      modelData.name = name.trim()
    }
    modelData.postProcessing = postProcessing
    modelData.features = modelData.features || {}
    modelData.features.ai = ai || {}
    modelData.classification = modelData.classification || {}
    modelData.classification.mustHave = classification.mustHave || []
    modelData.classification.directHave = classification.directHave || []
    modelData.classification.cantHave = classification.cantHave || []
    modelData.classification.regex = classification.regex || []
    modelData.administrators = administrators?.length
      ? administrators.map(mail => mail.toLowerCase())
      : []
    modelData.users = users?.length
      ? users.map(mail => mail.toLowerCase())
      : []

    if (!questions?.filter(q => q.key)?.length) {
      modelData.questions = []
    } else {
      modelData.questions = questions.filter(q => q.key)
    }
    if (pageCount) {
      const serializedPageCount = this.serializePageCount(pageCount)
      if (serializedPageCount === 'unlimited') {
        modelData.features.multiPage = true
        modelData.features.pageCount = null
      } else {
        modelData.features.multiPage = false
        modelData.features.pageCount = serializedPageCount
      }
    }
    if (documentShape) {
      const serializedDocumentShape = this.serializeDocumentShape(documentShape)
      if (serializedDocumentShape === 'unspecified') {
        modelData.classification.shapes = []
      } else {
        modelData.classification.shapes = [serializedDocumentShape]
      }
    }
    if (taxonomies
      && !(taxonomies.length === 1 && taxonomies[0].name === '' && taxonomies[0].alternateSpelling?.length === 0)) {
      modelData.taxonomies = taxonomies.filter(tt => !(tt.name === '' || tt.alternateSpelling?.length === 0))
    } else {
      modelData.taxonomies = []
    }
    modelData.resultManipulation = {
      mustHaveFields: resultManipulation.mustHaveFields || [],
      disabledFields: resultManipulation.disabledFields || [],
      disableAllExceptMustHaveFields: !!resultManipulation.disableAllExceptMustHaveFields
    }
    modelData.extensions = extensions
    modelData.isFeatureModel = !!this.state.isFeatureModel
    modelData.deploymentServer = this.state.deploymentServer
    return modelData
  }

  makeRequest (options) {
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Loading...',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      }
    })
    let endpoint = '/custom-model'
    if (this.props?.modelUUID) {
      endpoint = endpoint + '/' + this.props.modelUUID
    }

    request({
      endpoint,
      method: 'POST',
      body: this.state.model
    }, (error, response) => {
      if (response.message) {
        return this.setState({
          isLoaded: true,
          messageObject: LoadingModal.getEmptyMessageObject()
        }, () => {
          this.context.displayModal({
            title: `Error while ${this.props.modelUUID ? 'updating the' : 'creating a'} custom model`,
            content: (
              <div>
                <p>{LoadingModal.getMessageDescriptionWithCode(response.message, response.code)}</p>
              </div>
            ),
            options: [
              { content: 'OK' }
            ],
            isWide: true
          })
        })
      }
      if (error) {
        return this.setState({
          isLoaded: true,
          messageObject: LoadingModal.getEmptyMessageObject()
        }, () => {
          this.context.displayModal({
            title: `Error while ${this.props.modelUUID ? 'updating the' : 'creating a'} custom model`,
            content: (
              <div>
                <p>{LoadingModal.getMessageDescriptionWithCode(error, null)}</p>
              </div>
            ),
            options: [
              { content: 'OK' }
            ]
          })
        })
      }
      this.updateStates(response, () => {
        if (options?.isDeleteOperation) {
          return this.context.displayModal({
            title: 'Success',
            content: (
              <div>
                <p>Your custom model is successfully deleted.</p>
              </div>
            ),
            options: [
              {
                content: 'OK',
                action: () => {
                  this.context.redirect('/custom-models')
                }
              }],
            isClosable: true
          })
        }
        const message = (this.props.modelUUID) ? 'Your custom model is successfully updated.' : 'Your custom model is successfully created.'
        this.context.displayModal({
          title: 'Success',
          content: (
            <div>
              <p>{message}</p>
            </div>
          ),
          options: [
            {
              content: 'Edit model',
              action: () => {
                this.setIsSubmitted(() => {
                  this.context.redirect(`/custom-models/edit/${this.state.model.modelUUID}/${this.getActiveTab()}`)
                  window.location.reload()
                })
              },
              intent: 'warning'
            },
            {
              content: 'Go to models',
              action: () => {
                this.setIsSubmitted(() => {
                  this.context.redirect('/custom-models')
                })
              }
            }
          ]
        })
      })
    }, err => {
      this.setState({
        isLoaded: false,
        messageObject: {
          title: 'Error',
          description: 'Error happened while processing: ' + (err.message || err) + '.',
          status: 'error',
          code: err.code
        }
      })
    })
  }

  analyzeSampleFiles () {
    const { sampleFiles } = this.state
    if (!sampleFiles?.length) {
      return
    }
    // make api scan request for each file
    const documentPromises = []

    sampleFiles.forEach(file => documentPromises.push(new Promise(resolve => {
      if (file.done) {
        return
      }
      getBase64({ source: file }, async (error, imageBase64) => {
        if (imageBase64 && imageBase64.startsWith('data:;')) {
          if (file.name.toLowerCase().endsWith('.msg')) {
            imageBase64 = imageBase64.replace('data:;', 'data:application/vnd.ms-outlook;')
          } else {
            error = true
          }
        }
        if (error) {
          resolve(null)
          return window.alert('Could not read file: ' + file.name + '. Please try again.')
        }
        resolve(imageBase64)
      })
    })))

    Promise.all(documentPromises).then(docs => {
      request({
        endpoint: '/custom-model/' + this.state.model.modelUUID + '/analyze',
        method: 'POST',
        body: {
          documents: docs.filter(d => d)
        }
      }, (error, response) => {
        this.setState({ analysisLoading: false })
        if (error) {
          if (this.props?.user?.email) {
            window.alert(error.message)
            return window.location.reload()
          }
          this.context.redirect('/login')
          return
        }
        if (response.message) {
          if (this.props?.user?.email) {
            return this.context.redirect('/custom-models')
          }
          this.context.redirect('/login')
          return
        }
        this.updateStates(response, this.context.displayModal({
          title: 'Analysis result',
          content: (
            <div>
              <p>Here are the results of the analysis:</p>
              <p className='changes' style={{ marginTop: '1rem' }}>
                <b>Shape: </b>
                <span style={{ fontStyle: response.classification?.shapes?.[0] ? 'normal' : 'italic' }}>{ capitalizeFirstLetter(response.classification?.shapes?.[0] || '') || 'No common shape' }</span>
                <br />
                <b>Page count: </b>
                <span style={{ fontStyle: response.features?.pageCount ? 'normal' : 'italic' }}>{ response.features?.pageCount || 'Unspecified' }</span>
                <br />
                <b>Classifications: </b>
                <span style={{ fontStyle: response.classification?.directHave?.length ? 'normal' : 'italic' }}>{ response.classification?.directHave?.length ? response.classification.directHave.join(', ') : 'No common words' }</span>
              </p>
              <p>Note: This will remove existing classification.</p>
              <p style={{ marginTop: '1rem' }}>Do you want to accept these changes?</p>
            </div>
          ),
          close: () => {
            this.context.redirect(`/custom-models/edit/${this.state.model.modelUUID}/${this.getActiveTab()}`)
            window.location.reload()
          },
          options: [
            {
              content: 'Reject',
              action: () => {
                this.context.redirect(`/custom-models/edit/${this.state.model.modelUUID}/${this.getActiveTab()}`)
                window.location.reload()
              },
              intent: 'warning'
            },
            {
              content: 'Accept',
              action: () => {
                // use the new model data to edit the model
                this.createCustomModel()
              }
            }
          ],
          isWide: true
        }))
      }, err => {
        this.setState({
          responseMessage: 'Error happened while processing: ' + err.message + '.',
          responseStatus: 'error'
        })
      })
    })
  }

  renderAutomaticClassificationBlock () {
    const { sampleFiles } = this.state
    return (
      <>
        <div className='auto-classification-block'>
          <h2 className='title'>Automatic Classification</h2>
          <p className='header-paragraph'>
            You can upload a number of your documents here for analysis and automatic classification generation.
          </p>
          <div className='auto-classification-top-row'>
            <FileInput
              accept={Array.from(acceptedFileFormats)}
              dropText={<>Upload sample documents here (minimum 3)</>}
              onDrop={(accepted, _) => {
                accepted.map(file => Object.assign(file, {
                  preview: URL.createObjectURL(file)
                }))
                this.setState(prev => {
                  return { ...prev, sampleFiles: accepted }
                })
              }}
            />
            {
              sampleFiles.length > 0
              && (
                <Button
                  size='small'
                  disabled={this.state.analysisLoading}
                  onClick={() => {
                    // display modal
                    if (this.state.sampleFiles.length < 3) {
                      return this.context.displayModal({
                        title: 'Analyze documents',
                        content: (<div><p>You need at least 3 documents to analyze</p></div>),
                        options: [
                          {
                            content: 'OK',
                            intent: 'positive'
                          }
                        ]
                      })
                    }
                    this.setState({ analysisLoading: true })
                    this.analyzeSampleFiles()
                  }}
                >
                  {this.state.analysisLoading ? 'Analyzing...' : 'Analyze'}
                </Button>
              )
            }
          </div>
          <aside className='thumbsContainer'>
            {sampleFiles.map(file => (
              <div className='thumb' key={file.name}>
                <div className='thumbInner'>
                  <span key={file.name} className='text'>
                    {file.name}
                    <span
                      className='close'
                      onClick={() => {
                        this.setState(prev => {
                          return {
                            ...prev,
                            sampleFiles: this.state.sampleFiles.filter(
                              it => it.name !== file.name
                            )
                          }
                        })
                      }}
                    >
&nbsp; &times;
                    </span>
                  </span>
                  {
                    file.type.startsWith('image')
                    && (
                      <img
                        src={file.preview}
                        className='img'
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(file.preview)
                        }}
                        // display nothing if image is not loaded
                        onError={e => {
                          e.target.style.display = 'none'
                        }}
                      />
                    )
                  }
                </div>
              </div>
            ))}
          </aside>
        </div>
      </>
    )
  }

  renderClassificationFields () {
    const { classification } = this.state
    return (
      <>
        {
          this.classificationFields.map(classificationField => {
            return (
              <>
                <h2 className='title'>{classificationField.title}</h2>
                <p className='header-paragraph'>
                  {classificationField.paragraph}
                </p>
                { classificationField.subColumns
                  ? classificationField.subColumns.map(sc => {
                    return (
                      <>
                        <p style={{ marginTop: (sc.marginTop || '') }}><b>{sc.title}</b></p>
                        <p className='header-paragraph'>{sc.paragraph}</p>
                        <div>
                          <MultiEmail
                            // width="720px"
                            placeholder={sc.placeholder}
                            emails={classification?.[sc.data] || []}
                            onChange={newClassification => {
                              classification[sc.data] = classification[sc.data] || []
                              classification[sc.data] = newClassification.map(nc => nc.trim())
                              this.setState({ classification, isSubmitted: false })
                            }}
                            isRegexSelect={true}
                            validateEmail={false}
                            validateDomain={false}
                            validateRegex={true}
                            ignoreSpace={true}
                            style={{ marginLeft: '0rem', marginBottom: '0rem' }}
                          />
                        </div>
                      </>
                    )
                  })
                  : (
                      <div>
                        <MultiEmail
                          // width="720px"
                          placeholder={classificationField.placeholder}
                          emails={classification?.[classificationField.data] || []}
                          onChange={newClassification => {
                            classification[classificationField.data] = classification[classificationField.data] || []
                            classification[classificationField.data] = newClassification.map(nc => nc.trim())
                            this.setState({ classification, isSubmitted: false })
                          }}
                          isRegexSelect={true}
                          validateEmail={false}
                          validateDomain={false}
                          validateRegex={true}
                          ignoreSpace={true}
                          style={{ marginLeft: '0rem', marginBottom: '0rem' }}
                        />
                      </div>
                    )}
              </>
            )
          })
        }
      </>
    )
  }

  setInput (name, value) {
    const ai = this.state.ai

    ai[name] = ai[name] || {}
    ai[name].status = value ? 'enabled' : 'disabled'
    this.setState({
      ai,
      isSubmitted: false
    })
  }

  setAIFeatureOptions (name, value) {
    const ai = this.state.ai

    ai[name] = ai[name] || {}
    ai[name].options = value
    this.setState({
      ai,
      isSubmitted: false
    })
  }

  onCheckBoxChange (data) {
    this.setInput(data.value, data.checked)
  }

  downloadCustomModelAsJson = () => {
    const modelData = this.prepareModelData()
    const fileName = `${this.state.name || 'CustomModel'}.json`
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(modelData, null, 2))

    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', fileName)
    downloadAnchorNode.click()
  }

  renderCancelSaveContainer () {
    return (
      <div className='cancel-save-container'>
        <Section name='cancel-save'>
          <div className={'button-set' + (this.state.isBottomSaveButtonEnabled ? ' is-bottom' : ' is-top')}>
            <AppButton
              className='secondary-cta min-w-36'
              href='/custom-models'
            >
              Cancel
            </AppButton>
            <AppDropdown
              className='primary-cta'
              leftButtonClassName='min-w-32'
              options={[{ name: 'Export settings', onClick: this.downloadCustomModelAsJson }]}
              disabled={this.state.name?.length < 3 || this.state.isSubmitted}
              isDropdownDisabled={!this.props.isEdit}
              onClick={() => {
                this.createCustomModel()
              }}
            >
              {this.props.isEdit ? 'Update' : 'Save'}
            </AppDropdown>
          </div>
        </Section>
      </div>
    )
  }

  accordionSectionOnClickHandler (key) {
    // FIXME: Scroll to top
    if (!this.props.isEdit) {
      return this.context.redirect('/custom-models/new')
    }
    if (this.props.activeTab === key) {
      return this.context.redirect(`/custom-models/edit/${this.props?.modelUUID}`)
    }
    this.context.redirect(`/custom-models/edit/${this.props?.modelUUID}/${key}`)
  }

  getActiveTab () {
    let activeTab = this.props.activeTab
    if (!this.props.isEdit
      || (
        (!activeTab || !this.tabList.includes(activeTab))
        && !this.state.isMobile
      )
    ) {
      activeTab = 'basicInformation'
    }
    return activeTab
  }

  render () {
    const { modelUUID, user } = this.props
    const { model, ai, messageObject, isLoaded, pageCount, documentShape, postProcessing } = this.state
    const wasUserLoggedIn = this.state.user?.email || user?.email
    const activeTab = this.getActiveTab()
    const titleText = this.props?.modelUUID ? 'Edit Custom Model' + (this.state.model?.name ? ` "${this.state.model.name}"` : '') + (this.state.model?.status === 'disabled' ? ' (Disabled)' : '') : 'Create a New Custom Model'
    const titleDescription = this.props?.modelUUID ? 'Edit your custom model' : 'Create your own model with few steps.'
    let loadingErrorButton = {
      to: '/custom-models',
      text: 'Back to custom models'
    }
    if (!isLoaded && !wasUserLoggedIn?.length) {
      loadingErrorButton = {
        to: '/login?next=/custom-models',
        text: 'Login to access custom models'
      }
    }
    let keyIndex = 0
    const userRole = this.state.role || []
    const isAdmin = userRole.includes('owner') || userRole.includes('administrator')
    return (
      <View name='flow-settings'>
        <NavigationPrompt messageFunction={(location, action) => {
          const isLeaving = !location.pathname.startsWith('/custom-models/edit') || action === 'LEAVE'
          if (isLeaving && !this.state.isSubmitted) {
            return Messages.CHANGES_NOT_SAVED
          }

          return true
        }}
        />
        { !isLoaded || (!model && this.props.isEdit)
          ? (
              <Section name='block'>
                <LoadingModal
                  data={{
                    ...messageObject,
                    button: loadingErrorButton
                  }}
                />
              </Section>
            )
          : (
              <>
                <Helmet>
                  <title>{this.state.model?.name || (this.props?.modelUUID ? 'Edit Custom Model' : 'Create a New Custom Model')}</title>
                </Helmet>
                <Header name='header'>
                  <div className='header-container settings' style={{ gridTemplateColumns: '2fr 1fr' }}>
                    <div>
                      <BackButton
                        to='/custom-models'
                        placeholder='Back to custom models'
                      />
                      <div className='title'>
                        <h1
                          className='slogan'
                          title={titleDescription}
                        >
                          {titleText}
                        </h1>
                        <h2
                          className='introduction'
                        >
                          {this.props?.modelUUID ? 'Customize your existing custom model.' : 'Create your custom model easily with a few steps.'}
                        </h2>
                      </div>
                    </div>
                    {!this.state.isMobile && this.renderCancelSaveContainer()}
                  </div>

                </Header>
                <Section className='settings-container'>
                  { !isLoaded
                    ? (
                        <LoadingModal
                          data={{
                            ...messageObject,
                            status: 'loading',
                            title: 'Authenticating for Custom Models...',
                            description: 'Please wait, this may take a few seconds.'
                          }}
                        />
                      )
                    : (
                        <div className='flow-settings-grid-container'>
                          <div className='left-navigation'>
                            <ul>
                              {Object.keys(tabs)
                                .filter(key => {
                                  const isFeatureModel = !!this.state.isFeatureModel
                                  const showFor = tabs[key].showFor
                                  if (!showFor) {
                                    return true
                                  }
                                  if (isFeatureModel === true) {
                                    return showFor.includes('feature')
                                  } else if (isFeatureModel === false) {
                                    return showFor.includes('document')
                                  } else { // undefined
                                    return true
                                  }
                                })
                                .map((key, index) => (
                                  <li
                                    title={tabs[key].description}
                                    className={(key !== 'basicInformation' && !this.props.isEdit ? 'disable ' : '') + (activeTab === key ? 'active' : '')}
                                    key={index}
                                    onClick={() => {
                                      if (this.props.isEdit) {
                                        this.context.redirect(`/custom-models/edit/${this.props?.modelUUID}/${key}`)
                                      } else {
                                        this.context.redirect('/custom-models/new')
                                      }
                                    }}
                                  >
                                    <Link
                                      to={this.props.isEdit ? `/custom-models/edit/${this.props?.modelUUID}/${key}` : '/custom-models/new'}
                                      className={this.props.isEdit ? (tabs[key].class || '') : ((key !== 'basicInformation') ? 'disable' : '')}
                                    >
                                      {tabs[key].title}
                                    </Link>
                                    <Link
                                      to={this.props.isEdit ? `/custom-models/edit/${this.props?.modelUUID}/${key}` : '/custom-models/new'}
                                      className={this.props.isEdit ? ('arrow ' + (tabs[key].class || '')) : ((key !== 'basicInformation') ? 'disable' : '')}
                                    >
                                      <MaterialIcon name='chevron_right' />
                                    </Link>
                                  </li>
                                )
                                )}
                            </ul>
                          </div>
                          <Section
                            name='basic'
                            className='settings-card'
                            if={this.state.isMobile || activeTab === 'basicInformation'}
                            accordion={this.state.isMobile}
                            isOpen={activeTab === 'basicInformation'}
                            title='Basic Information'
                            onClick={() => {
                              this.accordionSectionOnClickHandler('basicInformation')
                            }}
                          >
                            {!this.state.isMobile && (
                              <span className='title-container'>
                                <h1 className='title'>Basic Information</h1>
                                <HelpButton href='https://help.base64.ai/kb/guide/en/custom-model-builder-yU4SqS6ldJ/Steps/2693496' />
                              </span>
                            )}
                            <h2 className='title' title='You can create your specific model name here.'>Model name</h2>
                            <input
                              className='basic fullscreen-input'
                              type='text'
                              value={this.state.name || this.state.model?.name || ''}
                              name='flow-name'
                              placeholder='Your new custom model'
                              onChange={e => {
                                this.setState({
                                  name: e.target.value,
                                  isSubmitted: false
                                })
                              }}
                            />
                            <h2 className='title'>Model category</h2>
                            <div className='reviewerContainer'>
                              <RadioButton
                                className='hitl-radio-button'
                                value={!this.state.isFeatureModel}
                                onChange={() => {
                                  this.setState({ isFeatureModel: false, isSubmitted: false })
                                }}
                                label={(
                                  <>
                                    <b>Documents:</b> Classification and data extraction for entire documents (e.g., understanding custom forms, contracts, IDs, etc.)
                                  </>
                                )}
                              />
                              { hasFeature('autoML') && (
                                <RadioButton
                                  className='hitl-radio-button'
                                  value={!!this.state.isFeatureModel}
                                  onChange={() => this.setState({ isFeatureModel: true, isSubmitted: false })}
                                  label={(
                                    <>
                                      <b>Features:</b> Object recognition within documents (e.g., detecting logos, fingerprints, or rubber stamps, etc.)
                                    </>
                                  )}
                                />
                              ) }
                            </div>
                            {this.props.modelUUID && (
                              <>
                                <h2 className='title'>Model type</h2>
                                <div className='flow-id-container'>
                                  <span className='flow-id'>{'custom/user/' + modelUUID}</span>
                                  <CopyToClipboard
                                    text={'custom/user/' + modelUUID}
                                    onCopy={() => {
                                      window.alert('Copied to your clipboard!')
                                    }}
                                  >
                                    <MaterialIcon
                                      name='content_copy'
                                      style={{
                                        fontVariationSettings: '\'FILL\' 0,\'wght\' 200,\'GRAD\' 0,\'opsz\' 48'
                                      }}
                                    >
                                    </MaterialIcon>
                                  </CopyToClipboard>
                                </div>
                              </>
                            )}
                            <h2 className='title'>Model administrators</h2>
                            <p className='header-paragraph'>
                              Specify the emails of Flow owners and administrators who can edit this model and use it in their Flows.
                            </p>
                            <div>
                              <MultiEmail
                                // width="720px"
                                placeholder='Enter the emails or domains of model administrators...'
                                emails={model?.administrators || []}
                                validateDomain={true}
                                onChange={emails => {
                                  this.setState({ administrators: emails, isSubmitted: false })
                                }}
                                style={{ marginLeft: '0rem', marginBottom: '0rem' }}
                              />
                            </div>
                            <h2 className='title'>Model users</h2>
                            <p className='header-paragraph'>
                              Specify the emails of Flow owners and administrators who can use this model in their Flows but cannot make any further changes on it.
                            </p>
                            <div>
                              <MultiEmail
                                // width="720px"
                                placeholder='Enter the emails or domains of model users...'
                                emails={model?.users || []}
                                validateDomain={true}
                                onChange={emails => {
                                  this.setState({ users: emails, isSubmitted: false })
                                }}
                                style={{ marginLeft: '0rem', marginBottom: '0rem' }}
                              />
                            </div>
                            {isAdmin && (
                              <>
                                <h2 className='title'>Clone Custom Model</h2>
                                <p className='header-paragraph'>
                                  Create a new custom model using the parameters of the current custom model. This clone operation does not copy the post-processing integration
                                </p>
                                <div>
                                  <a onClick={() => {
                                    this.context.displayModal({
                                      title: 'Clone Custom Model',
                                      content: (
                                        <CloneComponent
                                          component={{ name: this.state.name, isCustomModel: true }}
                                          onChange={d => {
                                            this.setState({ cloneComponentObject: d })
                                          }}
                                        />
                                      ),
                                      options: [
                                        {
                                          content: 'Cancel',
                                          intent: 'negative'
                                        },
                                        {
                                          content: 'Clone',
                                          action: () => {
                                            this.setState({
                                              isLoaded: false,
                                              messageObject: {
                                                title: 'Cloning the custom model',
                                                description: 'Please wait, this may take a few seconds.',
                                                status: 'loading',
                                                code: null
                                              }
                                            })
                                            const newName = this.state.cloneComponentObject?.newName || ('Copy of ' + this.state.name)
                                            const share = this.state.cloneComponentObject?.shareWithSamePeople || false
                                            request({
                                              endpoint: '/custom-model/clone/' + this.state.modelUUID,
                                              method: 'POST',
                                              body: {
                                                name: newName,
                                                share
                                              }
                                            }, (error, response) => {
                                              if (response.message) {
                                                return this.setState(LoadingModal.getStandardErrorMessageObject(response))
                                              }
                                              if (error) {
                                                return this.setState(LoadingModal.getStandardErrorMessageObject(error))
                                              }
                                              this.setState({
                                                isLoaded: true,
                                                messageObject: LoadingModal.getEmptyMessageObject()
                                              }, this.context.displayModal({
                                                title: 'Clone Custom Model',
                                                content: (
                                                  <div>
                                                    <p>Your custom model has been cloned.</p>
                                                    <p>
                                                      Click <b>Open</b> to access your cloned custom model.
                                                    </p>
                                                  </div>
                                                ),
                                                options: [
                                                  {
                                                    content: 'Cancel',
                                                    intent: 'negative'
                                                  },
                                                  {
                                                    content: 'Open',
                                                    action: () => {
                                                      this.context.redirect('/custom-models/edit/' + response.modelUUID)
                                                      window.location.reload()
                                                    }
                                                  }
                                                ]
                                              }))
                                              // return window.location.reload()
                                            }, err => {
                                              this.setState(LoadingModal.getStandardErrorMessageObject(err))
                                            })
                                          }
                                        }
                                      ]
                                    })
                                  }}
                                  >
                                    Clone custom model
                                  </a>
                                </div>
                              </>
                            )}
                          </Section>
                          {modelUUID && (
                            <Section
                              name='enhancements'
                              className='settings-card'
                              if={this.state.isMobile || activeTab === 'classification'}
                              accordion={this.state.isMobile}
                              isOpen={activeTab === 'classification'}
                              title='Classification'
                              onClick={() => {
                                this.accordionSectionOnClickHandler('classification')
                              }}
                            >
                              {!this.state.isMobile && (
                                <span className='title-container'>
                                  <h1 className='title'>Classification</h1>
                                  <HelpButton href='https://help.base64.ai/kb/guide/en/custom-classification-create-your-own-model-yU4SqS6ldJ/Steps/2693496' />
                                </span>
                              )}
                              {this.state.isFeatureModel
                                ? (
                                    <p className='header-paragraph' style={{ marginTop: '2rem' }}>
                                      Classification is only available for document type models.
                                    </p>
                                  )
                                : (
                                    <>
                                      <p className='header-paragraph' style={{ marginTop: '2rem' }}>
                                        You can automatically classify documents based on their text content, such as words, phrases, and regular expressions.
                                        <br />
                                        This operation is case-sensitive unless you use regular expressions, e.g., <code style={{ background: 'silver', padding: '0.1rem 0.2rem', fontWeight: 'bold' }}>/New York/i</code> matches New York, NEW YORK, and new york.
                                      </p>
                                      {this.renderAutomaticClassificationBlock()}
                                      {this.renderClassificationFields()}
                                      <h2 className='title'>Page count</h2>
                                      <p className='header-paragraph'>
                                        Select the expected page count. Single-page documents such as IDs are 1 page.
                                        <br />
                                        Certain forms may have a fixed page length. Some documents, such as contracts, do not have page limits.
                                      </p>
                                      <Select
                                        isMulti={false}
                                        classNamePrefix='react-select'
                                        className='react-select'
                                        value={pageCount || []}
                                        // styles={filterStyle}
                                        options={this.getPageCountOptions()}
                                        closeMenuOnSelect={true}
                                        placeholder='Select page count...'
                                        noOptionsMessage={() => 'No page count found'}
                                        onChange={changed => {
                                          this.setState({
                                            isSubmitted: false,
                                            pageCount: changed
                                          })
                                        }}
                                      />
                                      <h2 className='title'>Document shape</h2>
                                      <p className='header-paragraph'>
                                        Classify documents based on shape, such as &quot;Letter&quot; for invoices and &quot;Card&quot; for IDs.
                                      </p>
                                      <Select
                                        isMulti={false}
                                        classNamePrefix='react-select'
                                        className='react-select'
                                        value={documentShape || []}
                                        // styles={filterStyle}
                                        options={this.getDocumentShapeOptions()}
                                        closeMenuOnSelect={true}
                                        placeholder='Select the document shape...'
                                        noOptionsMessage={() => 'No document shape found'}
                                        onChange={changed => {
                                          this.setState({
                                            isSubmitted: false,
                                            documentShape: changed
                                          })
                                        }}
                                      />
                                    </>
                                  )}
                            </Section>
                          )}
                          {modelUUID && (
                            <Section
                              name='enhancements'
                              className='settings-card'
                              if={this.state.isMobile || activeTab === 'enhancements'}
                              accordion={this.state.isMobile}
                              isOpen={activeTab === 'enhancements'}
                              title='Enhancements'
                              onClick={() => {
                                this.accordionSectionOnClickHandler('enhancements')
                              }}
                            >
                              {!this.state.isMobile && (
                                <span className='title-container'>
                                  <h1 className='title'>Enhancements</h1>
                                  <HelpButton href='https://help.base64.ai/kb/guide/en/custom-model-builder-yU4SqS6ldJ/Steps/2693496,2693499,2693497,2693498,2694590,2694912' />
                                </span>
                              )}
                              {this.state.isFeatureModel
                                ? (
                                    <p className='header-paragraph' style={{ marginTop: '2rem' }}>
                                      Enhancements are only available for document type models.
                                    </p>
                                  )
                                : (
                                    <>
                                      <p className='header-paragraph'>
                                        Changes made here will be reflected in the model result. <br /> Our AI will make the enhancements in the same order of this page.
                                      </p>
                                      <h2 className='title'>Layout Features</h2>
                                      <p className='header-paragraph'>
                                        Select the layout features you want to add.
                                        <br />
                                        Additional features, such as facial and signature recognition, can be enabled in the Flow settings.
                                      </p>
                                      <div className='check-box-container'>
                                        <div className='column-left'>
                                          { hasFeature('formReader') && (
                                            <Checkbox
                                              showTitle={true}
                                              key={++keyIndex}
                                              className='big left'
                                              placeholder='Enable form reader'
                                              value='formReader'
                                              onChange={e => {
                                                this.onCheckBoxChange(e.target)
                                              }}
                                              title='Detect form fields and key-value pairs.'
                                              checked={this.isFeatureEnabled(ai, 'formReader')}
                                            />
                                          )}
                                          { hasFeature('tableReader') && (
                                            <Checkbox
                                              showTitle={true}
                                              key={++keyIndex}
                                              className='big left'
                                              placeholder='Enable table reader'
                                              value='tableReader'
                                              onChange={e => {
                                                this.onCheckBoxChange(e.target)
                                              }}
                                              title='Detect tabular data layouts.'
                                              checked={this.isFeatureEnabled(ai, 'tableReader')}
                                            />
                                          ) }
                                          { hasFeature('checkBoxDetection') && (
                                            <ConfigurationWrapper
                                              show={this.isFeatureEnabled(ai, 'checkBoxDetection')}
                                              featureName='Checkbox detection'
                                              value={this.getAIFeatureOptions(ai, 'checkBoxDetection')}
                                              onChange={data => this.setAIFeatureOptions('checkBoxDetection', data)}
                                              defaultValue={this.getDefaultAIFeaturesConfiguration('checkBoxDetection')}
                                            >
                                              <Checkbox
                                                showTitle={true}
                                                key={++keyIndex}
                                                className='big left'
                                                placeholder='Enable checkbox detection'
                                                value='checkBoxDetection'
                                                onChange={e => {
                                                  this.onCheckBoxChange(e.target)
                                                }}
                                                title='Detect checkboxes, learn if they were checked alongside the text associated with it.'
                                                checked={this.isFeatureEnabled(ai, 'checkBoxDetection')}
                                              />
                                            </ConfigurationWrapper>
                                          ) }
                                          <Checkbox
                                            showTitle={true}
                                            key={++keyIndex}
                                            className='big left'
                                            placeholder='IBAN detection'
                                            value='ibanDetection'
                                            onChange={e => {
                                              this.onCheckBoxChange(e.target)
                                            }}
                                            title='Extract IBANs from your documents.'
                                            checked={this.isFeatureEnabled(ai, 'ibanDetection')}
                                          />
                                          <Checkbox
                                            showTitle={true}
                                            key={++keyIndex}
                                            className='big left'
                                            placeholder='Re-Order Lines and Words'
                                            value='orderTextVertically'
                                            onChange={e => {
                                              this.onCheckBoxChange(e.target)
                                            }}
                                            title='Re-order lines and words in the OCR of the document.'
                                            checked={this.isFeatureEnabled(ai, 'orderTextVertically')}
                                          />
                                        </div>
                                        <div className='column-right'>
                                          { hasFeature('objectDetection') && (
                                            <ConfigurationWrapper
                                              show={this.isFeatureEnabled(ai, 'signatureDetection')}
                                              featureName='Signature detection'
                                              value={this.getAIFeatureOptions(ai, 'signatureDetection')}
                                              onChange={data => this.setAIFeatureOptions('signatureDetection', data)}
                                              defaultValue={this.getDefaultAIFeaturesConfiguration('signatureDetection')}
                                            >
                                              <Checkbox
                                                showTitle={true}
                                                key={++keyIndex}
                                                className='big left'
                                                placeholder='Enable handwritten signature recognition'
                                                value='signatureDetection'
                                                onChange={e => {
                                                  this.onCheckBoxChange(e.target)
                                                }}
                                                title='Extract handwritten signatures, receive the cropped image and its coordinates.'
                                                checked={this.isFeatureEnabled(ai, 'signatureDetection')}
                                              />
                                            </ConfigurationWrapper>
                                          ) }
                                          { hasFeature('objectDetection') && (
                                            <ConfigurationWrapper
                                              show={this.isFeatureEnabled(ai, 'faceDetection')}
                                              value={this.getAIFeatureOptions(ai, 'faceDetection')}
                                              featureName='Face detection'
                                              onChange={data => this.setAIFeatureOptions('faceDetection', data)}
                                              defaultValue={this.getDefaultAIFeaturesConfiguration('faceDetection')}
                                            >
                                              <Checkbox
                                                showTitle={true}
                                                key={++keyIndex}
                                                className='big left'
                                                placeholder='Enable face detection'
                                                value='faceDetection'
                                                onChange={e => {
                                                  this.onCheckBoxChange(e.target)
                                                }}
                                                title='Extract faces, receive the cropped image and its coordinates.'
                                                checked={this.isFeatureEnabled(ai, 'faceDetection')}
                                              />
                                            </ConfigurationWrapper>
                                          ) }
                                          { hasFeature('barcodeDetection') && (
                                            <Checkbox
                                              showTitle={true}
                                              key={++keyIndex}
                                              className='big left'
                                              placeholder='Enable QR code detection'
                                              value='qrCodeDetection'
                                              onChange={e => {
                                                this.onCheckBoxChange(e.target)
                                              }}
                                              title='Extract data from QR codes.'
                                              checked={this.isFeatureEnabled(ai, 'qrCodeDetection')}
                                            />
                                          ) }
                                          { hasFeature('entityRecognition') && (
                                            <ConfigurationWrapper
                                              show={this.isFeatureEnabled(ai, 'entityRecognition')}
                                              featureName='Entity recognition (NLP)'
                                              value={this.getAIFeatureOptions(ai, 'entityRecognition')}
                                              onChange={data => this.setAIFeatureOptions('entityRecognition', data)}
                                              defaultValue={this.getDefaultAIFeaturesConfiguration('entityRecognition')}
                                            >
                                              <Checkbox
                                                showTitle={true}
                                                key={++keyIndex}
                                                className='big left'
                                                placeholder='Enable entity recognition'
                                                value='entityRecognition'
                                                onChange={e => {
                                                  this.onCheckBoxChange(e.target)
                                                }}
                                                title='Extract person names, locations, events, and products from your documents.'
                                                checked={this.isFeatureEnabled(ai, 'entityRecognition')}
                                              />
                                            </ConfigurationWrapper>
                                          ) }
                                        </div>
                                      </div>
                                      <Taxonomy
                                        taxonomies={this.state.taxonomies}
                                        onChange={data => {
                                          const isDefault = isEqual(data, defaultTaxonomies)

                                          this.setState({
                                            taxonomies: data,
                                            isSubmitted: isDefault ? this.state.isSubmitted : false
                                          })
                                        }}
                                        headerParagraph={(
                                          <p className='header-paragraph'>
                                            Add alternative spelling, wording, or synonyms to your taxonomy to fall back on if the preferred
                                            field does not exist in the result.
                                            <br />
                                            <a target='_blank' href='https://help.base64.ai/kb/guide/en/flow-human-in-the-loop-document-processing-WH1QqHEiey/Steps/2068067,2076956' rel='noreferrer'>Learn more</a> about the taxonomy.
                                          </p>
                                        )}
                                      />
                                      { hasFeature('questionAnswer') && (
                                        <QuestionAnswer
                                          flows={this.props.flows}
                                          questions={this.state.questions}
                                          onChange={data => {
                                            const isDefault = isEqual(data, defaultQuestions)

                                            this.setState({
                                              questions: data,
                                              isSubmitted: isDefault ? this.state.isSubmitted : false
                                            })
                                          }}
                                          headerParagraph={(
                                            <p className='header-paragraph'>
                                              Add questions you want our AI to answer, which will generate fields in the result.
                                            </p>
                                          )}
                                        />

                                      )}

                                      <ResultMustHaveDisabledFields
                                        resultManipulation={this.state.resultManipulation}
                                        onChange={data => {
                                          this.setState({
                                            resultManipulation: data
                                          })
                                        }}
                                      />

                                      <ConfigurationWrapper
                                        show={true}
                                        featureName='Predefined extensions'
                                        value={this.state.extensions}
                                        onChange={data => this.setState({ extensions: data })}
                                        defaultValue={[]}
                                        className='header-inline'
                                      >
                                        <h2 className='title'>Predefined extensions</h2>
                                      </ConfigurationWrapper>
                                      <p className='header-paragraph'>
                                        Extend the AI results with predefined document understanding modules. Changes made here will be reflected in the API result.
                                        <br />
                                        <a target='_blank' href='https://github.com/base64ai/docs/wiki/Predefined-post%E2%80%90processing-extensions' rel='noreferrer'>Learn more</a> about the extensions.
                                      </p>

                                      { hasFeature('integrations') && (
                                        <IntegrationManager
                                          context={{ modelUUID, postProcessing, customModel: true }}
                                          identifier='modelUUID'
                                          user={user}
                                          allowedIntegrations={[IntegrationManager.integrationTypes.POST_PROCESSING]}
                                          onContentTypeChanged={(_integrationType, contentType) => {
                                            postProcessing.integration.content = contentType
                                            this.setState({ postProcessing, isSubmitted: false })
                                          }}
                                          onCloseEditor={() => {}}
                                        />
                                      ) }
                                    </>
                                  )}
                            </Section>
                          )}
                          {modelUUID && (
                            <Section
                              name='enhancements'
                              className='settings-card'
                              if={this.state.isMobile || activeTab === 'deployment'}
                              accordion={this.state.isMobile}
                              isOpen={activeTab === 'deployment'}
                              title='Deployment'
                              onClick={() => {
                                this.accordionSectionOnClickHandler('deployment')
                              }}
                            >
                              {!this.state.isMobile && (
                                <span className='title-container'>
                                  <h1 className='title'>Deployment</h1>
                                  <a
                                    href='https://github.com/base64ai/docs/wiki/Base64.ai-Auto-ML'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    <MaterialIcon name='info' />
                                    Help
                                  </a>
                                </span>
                              )}
                              {!this.state.isFeatureModel
                                ? (
                                    <p className='header-paragraph' style={{ marginTop: '2rem' }}>
                                      Deployment is only available for feature models.
                                    </p>
                                  )
                                : (
                                    <>
                                      <p className='header-paragraph'>
                                        Specify the endpoint for the Base64.ai compatible machine learning service.
                                        <br />
                                        Leave blank for custom document models that will be automatically deployed on existing servers.
                                      </p>
                                      <form>
                                        <input
                                          className='basic fullscreen-input'
                                          type='text'
                                          value={this.state.deploymentServer || ''}
                                          name='deploymentServer'
                                          placeholder='Your custom component model endpoint (e.g., http://10.1.1.1:3000/api/table)'
                                          onChange={e => {
                                            this.setState({
                                              deploymentServer: e.target.value,
                                              isSubmitted: false
                                            })
                                          }}
                                        />
                                      </form>
                                    </>
                                  )}
                            </Section>
                          )}
                          <CustomModelFlowsSection
                            modelUUID={modelUUID}
                            activeTab={activeTab}
                            isEdit={this.props.isEdit}
                            modelName={this.state.name}
                            accordionSectionOnClickHandler={this.accordionSectionOnClickHandler}
                            isMobile={this.state.isMobile}
                          />
                          {this.props?.modelUUID && (
                            <Section
                              name='danger-zone'
                              if={this.state.isMobile || activeTab === 'dangerZone'}
                              className='settings-card'
                              accordion={this.state.isMobile}
                              isOpen={activeTab === 'dangerZone'}
                              title='Delete'
                              onClick={() => {
                                this.accordionSectionOnClickHandler('dangerZone')
                              }}
                            >
                              {!this.state.isMobile && (
                                <span className='title-container'>
                                  <h1 className='title'>Delete</h1>
                                  <HelpButton href='https://help.base64.ai/kb/guide/en/custom-model-builder-yU4SqS6ldJ/Steps/2693496,2693499,2693497,2693498,2694590,2694912,2694913' />
                                </span>
                              )}
                              <p>
                                <b>Warning: This is the danger zone.</b>
                                <br />
                                Use the following options to pause or delete operations within this particular custom model.
                              </p>
                              <h2 className='title'>
                                {this.state.modelUUID?.status === 'enabled'
                                  ? 'Freeze'
                                  : 'Unfreeze'} custom model
                              </h2>
                              <p className='header-paragraph'>
                                {this.state.flowData?.status === 'enabled'
                                  ? 'Keep your model and settings, but do not use this model in future uploads.'
                                  : 'Unfreeze the model to accept new files.'}
                              </p>
                              <AppButton
                                className={clsx('secondary-cta min-w-32', this.state.model?.status === 'enabled' && 'danger')}
                                title=''
                                onClick={() => {
                                  this.setState({
                                    model: {
                                      ...this.state.model,
                                      status: this.state.status === 'enabled' ? 'disabled' : 'enabled'
                                    }
                                  }, () => {
                                    this.makeRequest()
                                  })
                                }}
                              >
                                {this.state.status === 'enabled' ? 'Freeze' : 'Unfreeze'}
                              </AppButton>

                              <h2 className='title'>Delete model</h2>
                              <p className='header-paragraph'>Delete your model and settings. This action is not reversible.</p>
                              <AppButton
                                className='secondary-cta danger min-w-32'
                                title=''
                                onClick={() => {
                                  this.deleteModel(this.props?.modelUUID)
                                }}
                              >
                                Delete
                              </AppButton>
                            </Section>
                          )}
                        </div>
                      )}
                </Section>
                {
                  (this.state.isBottomSaveButtonEnabled || this.state.isMobile) && (
                    <Section className='bottom-save-button-container settings-container'>
                      {this.renderCancelSaveContainer()}
                    </Section>
                  )
                }
              </>
            )}
      </View>
    )
  }
}

export default EditCustomModel
