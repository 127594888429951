const preDefinedInputs = {
  number: {
    inputTypeOverwrite: {
      inputType: 'number',
      fieldName: 'value'
    }
  },
  date: {
    inputTypeOverwrite: {
      inputType: 'date',
      fieldName: 'value'
    }
  },
  boolean: {
    limits: [
      { fieldName: 'criteria', value: '$eq' },
      { fieldName: 'criteria', value: '$neq' }
    ],
    inputTypeOverwrite: {
      fieldName: 'value',
      inputType: 'creatable-select',
      placeholder: 'Select value...',
      options: [
        { value: 'true', label: 'True', isSubOption: true },
        { value: 'false', label: 'False', isSubOption: true }
      ],
      defaultValue: { value: 'true', label: 'True' },
      isMulti: false,
      isCamelCase: false
    }
  }
}

export default [
  {
    label: 'Common fields',
    path: '',
    options: [
      {
        label: 'OCR text',
        value: 'ocrText'
      },
      {
        label: 'Given name',
        value: 'givenName'
      },
      {
        label: 'Family name',
        value: 'familyName'
      },
      {
        label: 'Full name',
        value: 'fullName'
      },
      {
        label: 'Age',
        value: 'age'
      },
      {
        label: 'Date of birth',
        value: 'dateOfBirth'
      },
      {
        label: 'Issue date',
        value: 'issueDate'
      },
      {
        label: 'Expiration date',
        value: 'expirationDate'
      },
      {
        label: 'Company name',
        value: 'companyName'
      },
      {
        label: 'Tax',
        value: 'tax'
      },
      {
        label: 'Total',
        value: 'total'
      }
    ]
  },
  {
    label: 'Predefined fields',
    path: '',
    options: [
      {
        value: 'meta.fileName',
        label: 'File name',
        limits: [
          { fieldName: 'criteria', value: '$eq' },
          { fieldName: 'criteria', value: '$neq' },
          { fieldName: 'criteria', value: '$in' }
        ]
      },
      {
        value: 'meta.updatedAt',
        label: 'Updated at',
        type: 'date'
      },
      {
        value: 'meta.createdAt',
        label: 'Created at',
        type: 'date'
      },
      {
        value: 'meta.status',
        label: 'Status',
        limits: [
          { fieldName: 'criteria', value: '$eq' },
          { fieldName: 'criteria', value: '$neq' },
          { fieldName: 'criteria', value: '$in' }
        ],
        inputTypeOverwrite: {
          fieldName: 'value',
          inputType: 'creatable-select',
          placeholder: 'Enter status...',
          options: [
            { value: 'approved', label: 'Approved', isSubOption: true },
            { value: 'needsReview', label: 'Needs review', isSubOption: true },
            { value: 'autoApproved', label: 'Auto-approved', isSubOption: true },
            { value: 'rejected', label: 'Rejected', isSubOption: true },
            { value: 'error', label: 'Error', isSubOption: true }
          ],
          defaultValue: { value: 'needsReview', label: 'Needs review' },
          isMulti: false,
          isCamelCase: false
        }
      },
      {
        value: 'meta.integrationStatus',
        label: 'Integration status',
        limits: [
          { fieldName: 'criteria', value: '$eq' },
          { fieldName: 'criteria', value: '$neq' }
        ],
        inputTypeOverwrite: {
          fieldName: 'value',
          inputType: 'creatable-select',
          placeholder: 'Enter status...',
          options: [
            { value: 'integrated', label: 'Integrated', isSubOption: true },
            { value: 'failed', label: 'Failed', isSubOption: true },
            { value: 'pending', label: 'Pending', isSubOption: true },
            { value: 'not-applicable', label: 'Not applicable', isSubOption: true }
          ],
          defaultValue: { value: 'integrated', label: 'Integrated' },
          isMulti: false,
          isCamelCase: false
        }
      },
      { value: 'model.name', label: 'Model name' },
      { value: 'model.type', label: 'Model type' },
      { value: 'features.faces.length', label: 'Number of faces', type: 'number' },
      { value: 'features.signatures.length', label: 'Number of signatures', type: 'number' },
      { value: 'features.tables.length', label: 'Number of tables', type: 'number' },
      { value: 'features.properties.pageCount ', label: 'Page count', type: 'number' },
      { value: 'features.properties.mimeType', label: 'Mime type' },
      { value: 'features.properties.isInFocus', label: 'Is in focus', type: 'boolean' },
      { value: 'features.properties.isGlareFree', label: 'Is glare free', type: 'boolean' },
      { value: 'features.properties.isSelectable', label: 'Is selectable', type: 'boolean' },
      { value: 'features.properties.isEditable', label: 'Is editable', type: 'boolean' }
    ]
  }
].map(field => {
  if (!field.options) {
    return field
  }
  field.options = field.options.map(option => {
    if (!option.type) {
      return option
    }
    const obj = {
      ...option,
      ...preDefinedInputs[option.type]
    }
    delete obj.type
    return obj
  })
  return field
})
