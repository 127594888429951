import { Component, Fragment } from 'react'
import { request } from '../../helpers/request'

// Layouts
import Section from '~/layout/section/Section'

// Context
import Context from '~/context/global'

// Helpers
import PropTypes from 'prop-types'
import { hasFeature, isProduction } from '../../context/environment'

// Interfaces
import Link from '~/interface/link/Link'
import window from 'global'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Checkbox from '../checkbox/Checkbox'
import DropdownOption from '../dropdown-option/DropdownOption'
import Select from 'react-select'
import IntegrationEditor from '../integration-editor/IntegrationEditor'
import CustomSurvey from '../custom-survey/CustomSurvey'

const defaultStyle = {
  backgroundColor: 'white',
  color: '#3d3f47',
  fontFamily: 'inherit'
}

const selectStyles = {
  singleValue: (provided, _state) => {
    return {
      ...provided,
      ...defaultStyle
    }
  },
  input: (provided, _state) => {
    return {
      ...provided,
      ...defaultStyle
    }
  },
  menu: (provided, _state) => {
    return {
      ...provided,
      ...defaultStyle
    }
  },
  menuList: (provided, _state) => {
    return {
      ...provided,
      ...defaultStyle
    }
  },
  control: (provided, _state) => {
    return {
      ...provided,
      ...defaultStyle
    }
  }
}

class IntegrationManager extends Component {
  static contextType = Context

  static integrationTypes = {
    SOURCE: 'source',
    EXPORT: 'export',
    POST_PROCESSING: 'postProcessing'
  }

  static propTypes = {
    user: PropTypes.object,
    context: PropTypes.object,
    notifierStatus: PropTypes.bool,
    onCloseEditor: PropTypes.func,
    onEmailOptionChange: PropTypes.func,
    onScanFromEmailNotifierChange: PropTypes.func,
    onContentTypeChanged: PropTypes.func,
    replyMail: PropTypes.bool,
    allowedIntegrations: PropTypes.array,
    identifier: PropTypes.string
  }

  static defaultProps = {
    identifier: 'flowID',
    allowedIntegrations: Object.values(IntegrationManager.integrationTypes)
  }

  constructor (props) {
    super(props)
    this.state = {
      isEditorActive: false,
      integrationTemplates: [],
      selectedTemplate: null,
      additionalTemplate: null,
      integrationType: null
    }
    this.contentTypes = {
      default: 'None',
      EntireDocument: 'Whole document',
      ResultPages: 'Only result pages'
    }
    this.openEditorFrame = this.openEditorFrame.bind(this)
    this.closeEditorFrame = this.closeEditorFrame.bind(this)
    this.postMessageHandler = this.postMessageHandler.bind(this)
    this.renderIntegrationSection = this.renderIntegrationSection.bind(this)
    this.renderContentTypeInput = this.renderContentTypeInput.bind(this)
  }

  componentDidMount () {
    request({ endpoint: '/integration/templates', method: 'GET' }, (err, result) => {
      if (err) {
        return console.error(err)
      }
      this.setState({
        integrationTemplates: result
      })
    }, err => {
      console.error(err)
    })
    window.addEventListener('message', this.postMessageHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('message', this.postMessageHandler)
  }

  postMessageHandler (event) {
    if (!['https://integrate.base64.ai', 'http://localhost:5678', window.location.origin + ':5678', window.integrateHost].includes(event.origin)) {
      return
    }
    try {
      const parsed = JSON.parse(event.data)
      if (parsed?.command === 'close-editor') {
        this.closeEditorFrame()
        window.location.reload()
      } else if (parsed?.command === 'login-required') {
        this.context.displayModal({
          title: 'Session expired',
          content: 'Please login to continue work on integration editor',
          options: [
            {
              content: 'Login',
              action: () => {
                this.closeEditorFrame()
                this.context.logout(() => {
                  this.context.redirect('/login')
                })
              }
            }
          ]
        })
      }
    } catch (e) {
      // do nothing
    }
  }

  openEditorFrame (integrationType, selectedWorkflow, templateId) {
    if (selectedWorkflow?.id && templateId) {
      return this.setState({
        selectedWorkflow,
        integrationType,
        additionalTemplate: this.state.integrationTemplates.find(template => template.id === templateId),
        isEditorActive: true
      })
    } else if (selectedWorkflow?.id) {
      return this.setState({
        selectedWorkflow,
        integrationType,
        isEditorActive: true
      })
    }
    this.setState({
      integrationType,
      isEditorActive: true,
      selectedTemplate: this.state.integrationTemplates.find(template => template.id === templateId)
    })
  }

  closeEditorFrame () {
    this.setState({
      selectedWorkflow: null,
      templateType: null,
      isEditorActive: false,
      selectedTemplate: null,
      additionalTemplate: null
    })
    const { onCloseEditor } = this.props
    onCloseEditor && onCloseEditor()
  }

  getScanEmailAddress (emailKey) {
    return `scan${isProduction() ? '+' : 'test+'}${emailKey}@base64.ai`
  }

  getEmailKey () {
    request({
      endpoint: '/emailKey',
      body: {
        flowID: this.props.context.flowID
      }
    }, error => {
      if (error) {
        this.context.displayModal({
          title: 'Error',
          content: 'Data cannot be fetched. Please try again later.',
          options: [
            { content: 'OK' }
          ]
        })
        return
      }
      window.location.reload()
    })
  }

  renderIntegrationSection (options, existingIntegration, integrationType) {
    const type = {
      source: 'import',
      export: 'export',
      postProcessing: 'post-processing'
    }[integrationType]
    const isValidIntegration = existingIntegration?.id
    return (
      <div className='template-dropdown fullscreen-input'>
        <Select
          isMulti={false}
          classNamePrefix='react-select'
          className='react-select'
          options={options}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: theme.colors.primary50
            }
          })}
          placeholder={isValidIntegration ? `Edit your existing ${type} integration or add another` : `Create your ${type} integrations`}
          styles={selectStyles}
          closeMenuOnSelect={true}
          isSearchable={true}
          onChange={m => {
            this.openEditorFrame(integrationType, existingIntegration, m.value)
          }}
        />
      </div>
    )
  }

  renderContentTypeInput (integrationType, context) {
    if (!context?.[integrationType]?.integration) {
      return null
    }
    let selectedItem = Object.keys(this.contentTypes).indexOf(context[integrationType].integration.content)
    if (selectedItem === -1) {
      selectedItem = 0
    }
    return (
      <div className='content-type-container'>
        <p>Document</p>
        <CustomSurvey
          questionnaire={Object.values(this.contentTypes)}
          selectedItem={selectedItem}
          onChangeHandle={selectedIndex => {
            const { onContentTypeChanged } = this.props
            const key = selectedIndex === 0 ? '' : Object.keys(this.contentTypes)[selectedIndex]
            onContentTypeChanged && onContentTypeChanged(integrationType, key)
          }}
        />
      </div>
    )
  }

  render () {
    const { context } = this.props
    const {
      isEditorActive,
      integrationTemplates,
      selectedTemplate,
      additionalTemplate,
      selectedWorkflow
    } = this.state
    const isEditorEnable = isEditorActive && integrationTemplates.length

    const importOptions = [
      {
        label: 'Your integration',
        options: [
          {
            label: <DropdownOption text={context?.source?.integration?.id ? 'Edit your existing integration' : 'Create new integration'} iconType='svg' iconName={context?.source?.integration?.id ? 'edit' : 'plus'} />,
            value: context?.source?.integration?.id ? null : '2'
          }
        ]
      },
      {
        label: 'Popular templates',
        options: [
          { label: <DropdownOption text='Code' iconType='svg' iconName='code' />, value: '2-code' },
          { label: <DropdownOption text='Google Drive' iconName='drive' />, value: '2-googledrive' },
          { label: <DropdownOption text='MQTT' iconName='mqtt' iconType='svg' />, value: '2-mqtt' },
          { label: <DropdownOption text='Kafka' iconName='kafka' iconType='svg' />, value: '2-kafka' },
          { label: <DropdownOption text='Email (IMAP)' iconName='email-blue-icon' iconType='png' />, value: '2-email' },
          { label: <DropdownOption text='Webhook' iconName='base64' iconType='png' />, value: '2-webhook' },
          { label: <DropdownOption text='Scheduler' iconName='cog_clock_128' iconType='png' />, value: '2-scheduler' }
        ]
      }
    ]

    const postProcessOptions = [
      {
        label: 'Your integration',
        options: [
          {
            label: <DropdownOption text={context?.postProcessing?.integration?.id ? 'Edit your existing integration' : 'Create new integration'} iconType='svg' iconName={context?.postProcessing?.integration?.id ? 'edit' : 'plus'} />,
            value: context?.postProcessing?.integration?.id ? null : '3'
          }
        ]
      },
      {
        label: 'Popular templates',
        options: [
          { label: <DropdownOption text='API Request' iconName='base64' iconType='png' />, value: '3-http' },
          { label: <DropdownOption text='Code' iconName='code' iconType='svg' />, value: '3-code' },
          { label: <DropdownOption text='Modify tables' iconName='grid' iconType='png' />, value: `3-tablemodification${this.props.identifier === 'modelUUID' ? '-model' : ''}` }
        ]
      },
      {
        label: 'Email',
        options: [
          { label: <DropdownOption text='Gmail' iconName='gmail' iconType='png' />, value: '3-gmail' },
          { label: <DropdownOption text='Send email' iconName='email-blue-icon' iconType='png' />, value: '3-sendemail' }
        ]
      },
      {
        label: 'Databases',
        options: [
          { label: <DropdownOption text='MongoDB' iconName='mongodb' iconType='svg' />, value: '3-mongodb' },
          { label: <DropdownOption text='MySQL' iconName='mysql' iconType='svg' />, value: '3-mysql' },
          { label: <DropdownOption text='Microsoft SQL' iconName='mssql' iconType='svg' />, value: '3-mssql' },
          { label: <DropdownOption text='Postgres' iconName='postgres' iconType='svg' />, value: '3-postgres' },
          { label: <DropdownOption text='Snowflake' iconName='snowflake' iconType='svg' />, value: '3-snowflake' }
        ]
      },
      {
        label: 'File storage',
        options: [
          { label: <DropdownOption text='Dropbox' iconName='dropbox' iconType='png' />, value: '3-dropbox' },
          { label: <DropdownOption text='Google Drive' iconName='drive' iconType='png' />, value: '3-googledrive' },
          { label: <DropdownOption text='OneDrive' iconName='onedrive' iconType='png' />, value: '3-onedrive' },
          { label: <DropdownOption text='AWS S3' iconName='s3' iconType='svg' />, value: '3-s3' }
        ]
      }
    ]
    const exportOptions = [
      {
        label: 'Your integration',
        options: [
          {
            label: <DropdownOption text={context?.destination?.integration?.id ? 'Edit your existing integration' : 'Create new integration'} iconType='svg' iconName={context?.destination?.integration?.id ? 'edit' : 'plus'} />,
            value: context?.destination?.integration?.id ? null : '1'
          },
          { label: <DropdownOption text='Failed request' iconName='request' />, value: '1-failed-request' }
        ]
      },
      {
        label: 'Popular templates',
        options: [
          { label: <DropdownOption text='API Request' iconName='base64' />, value: '1-http' },
          { label: <DropdownOption text='Code' iconName='code' iconType='svg' />, value: '1-code' },
          { label: <DropdownOption text='Invoices to Quickbooks Accounting' iconName='quickbooks' iconType='svg' />, value: '1-quickbooks' },
          { label: <DropdownOption text='Result fields to Google Sheets' iconName='sheets' />, value: '1-googlesheets' },
          { label: <DropdownOption text='Result tables to Google Sheets' iconName='sheets' />, value: '1-googlesheetstable' },
          { label: <DropdownOption text='Modify tables' iconName='grid' iconType='png' />, value: '1-tablemodification' },
          { label: <DropdownOption text='Result fields to Excel or CSV' iconName='grid' />, value: '1-csvfield' },
          { label: <DropdownOption text='Result tables to Excel or CSV' iconName='grid' />, value: '1-csvtable' }
        ]
      },
      {
        label: 'Databases',
        options: [
          { label: <DropdownOption text='MongoDB' iconName='mongodb' iconType='svg' />, value: '1-mongodb' },
          { label: <DropdownOption text='MySQL' iconName='mysql' iconType='svg' />, value: '1-mysql' },
          { label: <DropdownOption text='Microsoft SQL' iconName='mssql' iconType='svg' />, value: '1-mssql' },
          { label: <DropdownOption text='Postgres' iconName='postgres' iconType='svg' />, value: '1-postgres' },
          { label: <DropdownOption text='Snowflake' iconName='snowflake' iconType='svg' />, value: '1-snowflake' }
        ]
      },
      {
        label: 'File storage',
        options: [
          { label: <DropdownOption text='Dropbox' iconName='dropbox' iconType='png' />, value: '1-dropbox' },
          { label: <DropdownOption text='Google Drive' iconName='drive' iconType='png' />, value: '1-googledrive' },
          { label: <DropdownOption text='OneDrive' iconName='onedrive' iconType='png' />, value: '1-onedrive' },
          { label: <DropdownOption text='AWS S3' iconName='s3' iconType='svg' />, value: '1-s3' }
        ]
      }
    ]
    let keyIndex = 0
    return (
      <Section name='integration-manager'>
        {
          isEditorEnable
            ? (
                <div className='editor-popup'>
                  <div className='container'>
                    {
                      (selectedTemplate || selectedWorkflow)
                        ? (
                            <div className='editor-container'>
                              <IntegrationEditor
                                identifier={this.props.identifier}
                                templateID={selectedTemplate?.id}
                                contextID={context.flowID || context.modelUUID}
                                workflowID={selectedWorkflow?.id}
                                additionalTemplateID={additionalTemplate?.id}
                              />
                            </div>
                          )
                        : null
                    }
                  </div>
                </div>
              )
            : <></>
        }
        {hasFeature('integrations') && this.props.allowedIntegrations.includes(IntegrationManager.integrationTypes.SOURCE) && (
          <div className='export-integration-container'>
            <h2 className='title'>Import</h2>
            <p className='header-paragraph'>
              <li>API access is always enabled. <a target='_blank' href='https://help.base64.ai/kb/guide/en/flow-human-in-the-loop-document-processing-WH1QqHEiey/Steps/2068067,2068068' rel='noreferrer'>Learn more</a>.</li>
              {hasFeature('smtpServer')
                ? context?.settings?.integrations?.scanFromEmail?.id
                  ? (
                      <>
                        <li>
                          Send documents to&nbsp;
                          <CopyToClipboard
                            text={this.getScanEmailAddress(context.settings.integrations.scanFromEmail.id)}
                            onCopy={() => window.alert('Copied to your clipboard!')}
                          >
                            <span>{this.getScanEmailAddress(context.settings.integrations.scanFromEmail.id)}</span>
                          </CopyToClipboard>
                        </li>
                        <Checkbox
                          key={++keyIndex}
                          className='reply-email big'
                          placeholder='Receive document processing results as an email'
                          value='replyMail'
                          onChange={e => {
                            const { onScanFromEmailNotifierChange } = this.props
                            onScanFromEmailNotifierChange && onScanFromEmailNotifierChange(e.target.checked)
                          }}
                          title='Receive document processing results as an email.'
                          checked={!!this.props.replyMail}
                        />
                      </>
                    )
                  : (
                      <li><Link onClick={() => this.getEmailKey()}>Forward your emails to scan the documents within.</Link></li>
                    )
                : null}
              <li><a href='/contact'>Contact us to use scanners</a> to automatically ingest paper documents.</li>
              <li>Use the dropdown menu below to create no-code integrations and automatically import documents into this Flow.</li>
            </p>

            {this.renderIntegrationSection(importOptions, context?.source?.integration, IntegrationManager.integrationTypes.SOURCE)}
          </div>
        )}
        {
          hasFeature('integrations') && this.props.allowedIntegrations.includes(IntegrationManager.integrationTypes.POST_PROCESSING) && (
            <Fragment>
              <h2 className='title'>Post-processing</h2>
              <p className='header-paragraph'>Augment the AI results with custom business logic and data from external systems. Changes made here will be reflected in the API result.</p>

              {this.renderIntegrationSection(postProcessOptions, context?.postProcessing?.integration, IntegrationManager.integrationTypes.POST_PROCESSING)}
              {this.renderContentTypeInput('postProcessing', context)}
            </Fragment>
          )
        }
        {
          hasFeature('integrations') && this.props.allowedIntegrations.includes(IntegrationManager.integrationTypes.EXPORT) && (
            <Fragment>
              <h2 className='title'>Export</h2>
              <p className='header-paragraph'>Send the document and the results to external systems such as your database or file share. The result will include any manual review changes.</p>

              {hasFeature('smtpServer') && (
                <Checkbox
                  key={++keyIndex}
                  className='big mb-4'
                  placeholder='Receive an email notification when a document is ready to export.'
                  value='isEmailNotifierEnabled'
                  onChange={e => {
                    const { onEmailOptionChange } = this.props
                    onEmailOptionChange && onEmailOptionChange(e.target.checked)
                  }}
                  title='Receive an email notification when a document is ready to export.'
                  checked={!!this.props.notifierStatus}
                />
              )}

              {this.renderIntegrationSection(exportOptions, context?.destination?.integration, IntegrationManager.integrationTypes.EXPORT)}
              {this.renderContentTypeInput('destination', context)}

            </Fragment>
          )
        }
      </Section>
    )
  }
}

export default IntegrationManager
