export default {
  basicInformation: {
    title: 'Basic information',
    description: 'You can edit / create the basic Information for your Custom Model'
  },
  classification: {
    title: 'Classification',
    showFor: ['document'],
    description: 'You can automatically classify documents based on their text content, such as words, phrases, and regular expressions.'
  },
  enhancements: {
    title: 'Enhancements',
    showFor: ['document'],
    description: 'Changes made here will be reflected in the model result. Our AI will make the enhancements in the same order of this page.'
  },
  flows: {
    title: 'Flows',
    showFor: ['document'],
    description: 'Select the Flows in which you want to use your custom model.You must be a Flow owner or administrator to make a change.'
  },
  deployment: {
    title: 'Deployment',
    showFor: ['feature'],
    description: 'Specify the URL that contains Base64.ai compatible ML server URL.'
  },
  dangerZone: {
    title: 'Delete',
    description: 'This is to pause or delete the model you created and the processes within it.',
    class: 'risk'
  }
}
