// Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import document from 'global/document'
import clsx from 'clsx'
// Interface: Dropdown
class Dropdown extends Component {
  static propTypes = {
    title: PropTypes.node,
    items: PropTypes.array,
    className: PropTypes.object,
    style: PropTypes.object,
    menuStyle: PropTypes.object,
    isAlignedRight: PropTypes.bool,
    setClickedDropdownName: PropTypes.func,
    description: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
    this.title = React.createRef()
    this.options = React.createRef()
    this.toggleOptions = this.toggleOptions.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidUpdate (prevState) {
    prevState.open !== this.state.open && document.body[
      `${this.state.open ? 'add' : 'remove'}EventListener`
    ]('click', this.handleClickOutside)
  }

  toggleOptions () {
    this.setState({
      open: !this.state.open
    }, () => {
      const { setClickedDropdownName } = this.props
      if (setClickedDropdownName) {
        if (this.state.open) {
          setClickedDropdownName(this.props.title)
        } else {
          setClickedDropdownName('')
        }
      }
    })
  }

  handleClickOutside (event) {
    this.title.current
    && this.options.current
    && !this.title.current.contains(event.target)
    && !this.options.current.contains(event.target)
    && this.toggleOptions()
  }

  render () {
    const { open } = this.state
    const { title, items, isAlignedRight } = this.props
    return (
      <div
        title={this.props.description}
        className={
          (open ? 'open ' : '')
          + 'dropdown'
        }
      >
        <button
          ref={this.title}
          className={clsx('title', this.props.className)}
          style={{ ...this.props.style }}
          onClick={this.toggleOptions}
        >
          {title || '···'}
        </button>
        <div
          ref={this.options}
          className='options'
          style={{
            ...this.props.menuStyle,
            [isAlignedRight ? 'right' : 'left']: 0
          }}
        >
          {items && items.length > 0
            ? (
                <ul className='items'>
                  {items.map((item, key) => (
                    <li
                      key={key}
                      onClick={() => {
                        item.action && item.action()
                        this.toggleOptions()
                      }}
                    >
                      {item.label}
                    </li>
                  )
                  )}
                </ul>
              )
            : 'No items to display'}
        </div>
      </div>
    )
  }
}

// Export
export default Dropdown
