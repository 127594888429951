import { createContext, useContext, useEffect, useState } from 'react'
import { request } from '~/helpers/request'
import PropTypes from 'prop-types'

const FlowContext = createContext(undefined)

export const FlowProvider = ({ children }) => {
  const [flows, setFlows] = useState([])
  const [message, setMessage] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)

  const fetchFlows = () => {
    setMessage({
      title: 'Retrieving Flows...',
      description: 'Please wait, this may take a few seconds.',
      status: 'loading',
      code: null
    })
    setIsLoaded(false)
    setIsLoading(true)

    request({
      endpoint: '/flow',
      method: 'GET'
    }, (error, response) => {
      setIsLoading(false)
      if (response.message) {
        setIsLoaded(false)
        return setMessage({
          title: 'Error while fetching Flows',
          description: response.message,
          status: 'error',
          code: response.code
        })
      }
      if (error) {
        setIsLoaded(false)
        return setMessage({
          title: 'Error while fetching Flows',
          description: error,
          status: 'error',
          code: null
        })
      }
      response.sort((a, b) => Number(b.isDefault || 0) - Number(a.isDefault || 0))
      setIsLoaded(true)
      setFlows(response)
    }, err => {
      setIsLoaded(false)
      setIsLoading(false)
      setMessage({
        title: 'Error',
        description: 'Error happened while processing: ' + (err.message || err) + '.',
        status: 'error',
        code: err.code
      })
    })
  }

  const refreshFlows = () => {
    fetchFlows()
  }

  useEffect(() => {
    fetchFlows()
  }, [])

  const addFlow = flow => {
    const index = flows.findIndex(f => f.flowID === flow.flowID)
    if (index === -1) {
      flows.push(flow)
    } else {
      flows[index] = flow
    }
    setFlows(flows)
  }

  const deleteFlow = flowID => {
    const updatedFlows = flows.filter(flow => flow.flowID !== flowID)
    setFlows(updatedFlows)
  }

  const requestWrapper = (loadingMessage, options, callback, errorHandler) => {
    setIsLoading(true)
    setMessage({
      title: loadingMessage,
      description: 'Please wait, this may take a few seconds.',
      status: 'loading',
      code: null
    })
    request(options, (error, response) => {
      setIsLoading(false)
      callback(error, response)
    }, error => {
      setIsLoading(false)
      errorHandler(error)
    })
  }

  const value = {
    flows,
    message,
    isLoading,
    isLoaded,
    setFlows,
    addFlow,
    deleteFlow,
    refreshFlows,
    requestWrapper
  }

  return (
    <FlowContext.Provider value={value}>
      {children}
    </FlowContext.Provider>
  )
}

FlowProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useFlows = () => {
  const context = useContext(FlowContext)
  if (!context) {
    throw new Error('useFlow must be used within a FlowProvider')
  }
  return context
}
