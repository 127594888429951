export const clone = object => {
  if (typeof object !== 'object') {
    return object
  }
  return JSON.parse(JSON.stringify(object))
}

/**
 * Converts an object to a JSON object which contains only the primitive data.
 * @param {*} object - The object to convert.
 * @returns The JSON object.
 */
export const convertToJSONObject = object => {
  if (typeof object !== 'object') {
    return object
  }
  return JSON.parse(JSON.stringify(object))
}

/**
 * Converts a base64 encoded audio string to a playable audio source URL.
 * @param {string} base64String - The base64 encoded audio string.
 * @returns {string} The audio source URL.
 */
export const getSourceFromBase64Audio = base64String => {
  if (!base64String) {
    return
  }
  // Decode base64 string
  const decodedData = atob(base64String.split(',')[1])

  // Create Uint8Array from decoded data
  const uint8Array = new Uint8Array(decodedData.length)
  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i)
  }

  // Create Blob from Uint8Array
  const blob = new Blob([uint8Array], { type: 'audio/mp3' })

  // Create download link
  const href = URL.createObjectURL(blob)
  return href
}
