// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'
// Layout
import Context from '~/context/global'

// Content: UsageSection
class UsageSection extends Component {
  static contextType = Context

  render () {
    const user = this.props.user
    if (!user || !user.numberOfCredits) {
      return null
    }
    user.numberOfFaceDetections = user.numberOfFaceDetections || 0
    user.numberOfUploads = user.numberOfUploads || 0
    user.numberOfFaceRecognitions = user.numberOfFaceRecognitions || 0
    user.numberOfPages = user.numberOfPages || 0
    user.numberOfCreditsSpentOnDocuments = user.numberOfCreditsSpentOnDocuments || 0
    user.numberOfCreditsSpentOnFaceDetection = user.numberOfCreditsSpentOnFaceDetection || 0
    user.numberOfCreditsSpentOnFaceRecognition = user.numberOfCreditsSpentOnFaceRecognition || 0
    user.numberOfCreditsSpentOnSignatureDetection = user.numberOfCreditsSpentOnSignatureDetection || 0
    user.numberOfCreditsSpentOnSignatureRecognition = user.numberOfCreditsSpentOnSignatureRecognition || 0
    user.numberOfCreditsPaidForOthers = user.numberOfCreditsPaidForOthers || 0
    const totalCreditsSpent = (
      user.numberOfCreditsSpentOnDocuments
      + user.numberOfCreditsSpentOnFaceDetection
      + user.numberOfCreditsSpentOnFaceRecognition
      + user.numberOfCreditsSpentOnSignatureDetection
      + user.numberOfCreditsSpentOnSignatureRecognition
      + user.numberOfCreditsPaidForOthers
    )
    user.numberOfCredits = user.numberOfCredits || 0
    user.usedAwsCredits = user.usedAwsCredits || 0

    const remainingCredits = user.numberOfCredits + user.usedAwsCredits - totalCreditsSpent
    const remainingValues = `${remainingCredits.toLocaleString()} (${Math.floor(100 * remainingCredits / user.numberOfCredits + user.usedAwsCredits)}%)`

    return (
      <table className='usageTable'>
        <thead>
          <tr>
            <td colSpan='2' className='firstRow balanceHeader'>Your current account balance</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Number of documents processed:</td>
            <td className='rightCell'>{user.numberOfUploads.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Number of pages processed:</td>
            <td className='rightCell'>{user.numberOfPages.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Credits used for document processing:</td>
            <td className='rightCell'>{user.numberOfCreditsSpentOnDocuments.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Credits used for face detection:</td>
            <td className='rightCell'>{user.numberOfFaceDetections.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Credits used for facial recognition:</td>
            <td className='rightCell'>{user.numberOfFaceRecognitions.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Credits used for signature detection:</td>
            <td className='rightCell'>{user.numberOfCreditsSpentOnSignatureDetection.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Credits used for signature matching:</td>
            <td className='rightCell'>{user.numberOfCreditsSpentOnSignatureRecognition.toLocaleString()}</td>
          </tr>
          {
            user.numberOfCreditsPaidForOthers !== 0 && (
              <tr>
                <td>Credits paid for sponsored accounts:</td>
                <td className='rightCell'>{user.numberOfCreditsPaidForOthers.toLocaleString()}</td>
              </tr>
            )
          }
          <tr className='scaledUp'>
            <td>Total used credits:</td>
            <td className='rightCell'>{totalCreditsSpent.toLocaleString()}</td>
          </tr>
          <tr>
            <td>Purchased credits:</td>
            <td className='rightCell'>{user.numberOfCredits.toLocaleString()}</td>
          </tr>
          {
            user && user.usedAwsCredits
              ? (
                  <tr>
                    <td>Used AWS credits:</td>
                    <td className='rightCell'>{user.usedAwsCredits.toLocaleString()}</td>
                  </tr>
                )
              : null
          }
          <tr className='scaledUp'>
            <td>Total acquired credits:</td>
            <td className='rightCell'>{(user.numberOfCredits + user.usedAwsCredits).toLocaleString()}</td>
          </tr>
          <tr className='scaledUp'>
            <td>Remaining credits:</td>
            <td className='rightCell'>
              {remainingValues}
              {
                user && user.hasActiveAwsContract
                  ? (
                      <>
                        <br /><br />
                        Your AWS account is automatically charged for missing credits.
                      </>
                    )
                  : null
              }
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}

UsageSection.propTypes = {
  user: PropTypes.object
}

// Export
export default UsageSection
