// Modules
import { Component } from 'react'
import PropTypes from 'prop-types'

// Data
import caseStudyTypes from '~/data/caseStudyTypes'
import caseStudyTypesMap from '~/data/caseStudyTypesMap'

// Layout
import Section from '~/layout/section/Section'
import { AppButton } from '../../interface/app-button/AppButton'

// Content: Case Studies Section
class CaseStudiesSection extends Component {
  render () {
    const caseStudyType = caseStudyTypesMap[this.props.type] ? caseStudyTypes[caseStudyTypesMap[this.props.type]] : caseStudyTypes.default

    return (
      <Section
        name='case-studies'
        align='center'
      >
        <h2 className='title'>{ caseStudyType.title }</h2>
        <h3 className='introduction'>
          { caseStudyType.paragraph }
        </h3>
        <AppButton className='secondary-cta' href={caseStudyType.reference}>{ caseStudyType.buttonText }</AppButton>
      </Section>
    )
  }
}

CaseStudiesSection.propTypes = {
  type: PropTypes.string
}

// Export
export default CaseStudiesSection
