import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import StyleContext from 'isomorphic-style-loader/StyleContext'
import * as Sentry from '@sentry/react'
import AppWithRouter from './App'
import { isWorkingOffline } from './context/environment'

if (!isWorkingOffline() && !window.isLocalhost) {
  Sentry.init({
    dsn: 'https://31b50587ff4241868948a0688b15f9c1@o1073466.ingest.us.sentry.io/6698556',
    release: 'Base64ai-web-0.0.64-production',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Ignore timeout errors from Recaptcha
    // Recaptcha uses a primitive timeout mechanism so it causes issues with Sentry reporting
    // This code ignores those errors
    // src: (https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338)
    beforeSend: (event, hint) => {
      if (hint.originalException === 'Timeout') return null
      return event
    }
  })
}

const insertCss = (...styles) => {
  const removeCss = styles.map(style => style._insertCss())
  return () => removeCss.forEach(dispose => dispose())
}

ReactDOM.hydrate(
  <BrowserRouter>
    <StyleContext.Provider value={{ insertCss }}>
      <AppWithRouter />
    </StyleContext.Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
