import { Component } from 'react'
import PropType from 'prop-types'
import { isWorkingOffline } from '../../context/environment'

class DemoSampleView extends Component {
  static propTypes = {
    models: PropType.array,
    pickSample: PropType.func
  }

  constructor (props) {
    super(props)
    this.state = {
      origin: null
    }
    this.demoHomePageSamples = [
      'Passport and Driver license\non a single page',
      'Barcodes\nlike PDF417',
      'Vehicle\ninsurance',
      'Check',
      'Receipt',
      'Invoice',
      'Forms\nlike ACORD',
      'Photo'
    ]
    this.imagesPerModel = 8
  }

  componentDidMount () {
    this.setState({ origin: window.location.origin })
  }

  render () {
    const samples = []
    if (!this.state.origin || !this.props.models) {
      return samples
    }
    const { models } = this.props
    const samplePerModel = Math.max(Math.floor(this.imagesPerModel / models.length), 1)
    const path = `${this.state.origin}/static/content/features/data-extraction/models/`
    const createSamples = (model, count) => {
      const getSampleName = (model, index) => {
        if (!model) {
          return this.demoHomePageSamples[index]
        }
        const capitalizeFirstLetter = str => {
          return str.charAt(0).toUpperCase() + str.slice(1)
        }
        return `${capitalizeFirstLetter(model.replace(/\w+\//g, '').replace(/_/g, ' ')) || 'Sample'} ${index + 1}`
      }
      Array(count).fill().forEach((_, index) => {
        const name = getSampleName(model, index)
        if (!model && isWorkingOffline() && name === 'Photo') {
          return
        }
        samples.push({
          source: `${model}/${index + 1}.thumbnail.png`,
          name
        })
      })
    }
    if (models && models.length > 0) {
      models.forEach(model =>
        createSamples(model, samplePerModel)
      )
    } else {
      createSamples('', this.imagesPerModel)
    }
    return samples.length > 0
      ? (
          <div className='options demo-sample-view-container'>
            <ul className='images'>
              {samples.map((sample, index) => (
                <li
                  key={sample.source}
                  style={{ display: 'none' }}
                >
                  <img
                    className={(index === 0 && !models?.length) ? 'first-image' : ''}
                    src={path + sample.source}
                    onLoad={({ target }) => {
                      target.parentNode.style.display = 'inline-block'
                    }}
                    onClick={() => {
                      this.props.pickSample(
                        path + sample.source,
                        sample.name
                      )
                    }}
                  />
                  <small style={{ whiteSpace: 'pre-line' }}>{sample.name}</small>
                </li>
              )
              )}
            </ul>
          </div>
        )
      : null
  }
}

export default DemoSampleView
