export default {
  'Document AI': {
    scanByURL: {
      title: 'Scan a document by URL',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#d1927ec2-8a1c-4377-9c0e-40739ea9e9b9',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\": \\"https://base64.ai/static/content/features/data-extraction/models/2.png\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\": \\"https://base64.ai/static/content/features/data-extraction/models/2.png\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models/2.png"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models/2.png"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url": "https://base64.ai/static/content/features/data-extraction/models/2.png"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"": ""https://base64.ai/static/content/features/data-extraction/models/2.png""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url": "https://base64.ai/static/content/features/data-extraction/models/2.png"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url": "https://base64.ai/static/content/features/data-extraction/models/2.png"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\": \\"https://base64.ai/static/content/features/data-extraction/models/2.png\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    scanByBinary: {
      title: 'Scan a document by file binary',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#b860a304-9aef-4f09-a9a0-4849ee336019',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\": \\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\": \\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"": ""data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\": \\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    scanWithFlow: {
      title: 'Scan a document into a specific flow',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#44420ed7-5ec9-4378-8765-c937aa7f6e33',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .header("base64ai-flow-id", "0000-0000-0000-000000000000")\n  .body("{\\"url\\": \\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\": \\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .addHeader("base64ai-flow-id", "0000-0000-0000-000000000000")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nmyHeaders.append("base64ai-flow-id", "0000-0000-0000-000000000000");\n\nvar raw = JSON.stringify({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nxhr.setRequestHeader("base64ai-flow-id", "0000-0000-0000-000000000000");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}",\n    "base64ai-flow-id": "0000-0000-0000-000000000000"\n  },\n  "data": JSON.stringify({\n    "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\',\n    \'base64ai-flow-id\': \'0000-0000-0000-000000000000\'\n  },\n  body: JSON.stringify({\n    "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\',\n    \'base64ai-flow-id\': \'0000-0000-0000-000000000000\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\',\n  \'base64ai-flow-id\': \'0000-0000-0000-000000000000\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\',\n  \'base64ai-flow-id\': \'0000-0000-0000-000000000000\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  req.Header.Add("base64ai-flow-id", "0000-0000-0000-000000000000")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nrequest.AddHeader("base64ai-flow-id", "0000-0000-0000-000000000000");\nvar body = @"{""url"": ""https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\',\n    \'base64ai-flow-id: 0000-0000-0000-000000000000\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\',\n  \'base64ai-flow-id\' => \'0000-0000-0000-000000000000\'\n));\n$request->setBody(\'{"url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\": \\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\nrequest.addValue("0000-0000-0000-000000000000", forHTTPHeaderField: "base64ai-flow-id")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    scanWithDOM: {
      title: 'Scan a document and also extract its DOM',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#4aca26cd-6fb9-4859-bbcf-78790c202fec',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models//2.png\\",\\"settings\\":{\\"showDom\\":true}}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models//2.png\\",\\"settings\\":{\\"showDom\\":true}}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "showDom": true\n  }\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "showDom": true\n  }\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n    "settings": {\n      "showDom": true\n    }\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n    "settings": {\n      "showDom": true\n    }\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "showDom": true\n  }\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "showDom": True\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "showDom": True\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"https://base64.ai/static/content/features/data-extraction/models//2.png","settings":{"showDom":true}}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""https://base64.ai/static/content/features/data-extraction/models//2.png"",""settings"":{""showDom"":true}}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"https://base64.ai/static/content/features/data-extraction/models//2.png","settings":{"showDom":true}}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"https://base64.ai/static/content/features/data-extraction/models//2.png","settings":{"showDom":true}}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models//2.png\\",\\"settings\\":{\\"showDom\\":true}}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    scanWithTTS: {
      title: 'Scan a document and also convert its text to MP3 (text-to-speech)',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#8f558313-49f0-47fc-bd23-bc94399870c6',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models//2.png\\",\\"settings\\":{\\"convertTextToSpeech\\":true}}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models//2.png\\",\\"settings\\":{\\"convertTextToSpeech\\":true}}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "convertTextToSpeech": true\n  }\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "convertTextToSpeech": true\n  }\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n    "settings": {\n      "convertTextToSpeech": true\n    }\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n    "settings": {\n      "convertTextToSpeech": true\n    }\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "convertTextToSpeech": true\n  }\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "convertTextToSpeech": True\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//2.png",\n  "settings": {\n    "convertTextToSpeech": True\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"https://base64.ai/static/content/features/data-extraction/models//2.png","settings":{"convertTextToSpeech":true}}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""https://base64.ai/static/content/features/data-extraction/models//2.png"",""settings"":{""convertTextToSpeech"":true}}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"https://base64.ai/static/content/features/data-extraction/models//2.png","settings":{"convertTextToSpeech":true}}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"https://base64.ai/static/content/features/data-extraction/models//2.png","settings":{"convertTextToSpeech":true}}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models//2.png\\",\\"settings\\":{\\"convertTextToSpeech\\":true}}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    scanWithPageLimitation: {
      title: 'Scan a document until a given page number',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#8b8c0028-6312-402c-ac16-5547b5023c69',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf\\",\\"settings\\":{\\"limitPages\\":1}}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf\\",\\"settings\\":{\\"limitPages\\":1}}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "limitPages": 1\n  }\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "limitPages": 1\n  }\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n    "settings": {\n      "limitPages": 1\n    }\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n    "settings": {\n      "limitPages": 1\n    }\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "limitPages": 1\n  }\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "limitPages": 1\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "limitPages": 1\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf","settings":{"limitPages":1}}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf"",""settings"":{""limitPages"":1}}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf","settings":{"limitPages":1}}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf","settings":{"limitPages":1}}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf\\",\\"settings\\":{\\"limitPages\\":1}}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    scanWithMaxPages: {
      title: 'Scan a document only if it is under a given page count',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#cbfb111d-5b78-4508-a2df-a984b64c3229',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf\\",\\"settings\\":{\\"maxPages\\":10}}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf\\",\\"settings\\":{\\"maxPages\\":10}}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "maxPages": 10\n  }\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "maxPages": 10\n  }\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n    "settings": {\n      "maxPages": 10\n    }\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n    "settings": {\n      "maxPages": 10\n    }\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "maxPages": 10\n  }\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "maxPages": 10\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf",\n  "settings": {\n    "maxPages": 10\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf","settings":{"maxPages":10}}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf"",""settings"":{""maxPages"":10}}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf","settings":{"maxPages":10}}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf","settings":{"maxPages":10}}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"http://base64.ai/static/content/features/data-extraction/models/health/sbc/1.pdf\\",\\"settings\\":{\\"maxPages\\":10}}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    scanFromFullText: {
      title: 'Scan a document using its OCR only',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#3be61175-ec4e-4267-a5be-58099169412f',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"fullTextPages\\":[\\"USA DRIVER LICENSE\\\\nFEDERAL\\\\nLIMITS\\\\nAPPLY\\\\nDL 11234562\\\\nCLASS C\\\\nEXP 08/31/2014\\\\nEND NONE\\\\nLN SAMPLE\\\\nFN ALEXANDER JOSEPH\\\\n2570 24TH STREET\\\\nANYTOWN, CA 95818\\\\nDOB 08/31/1977\\\\nRSTR NONE\\\\n08311977\\\\n083177\\\\nDONOR\\\\nEYES BRN\\\\nalex f Sample\\\\nSEX M HAIR BLK\\\\nHGT 5-08\\\\\\" WGT 150 lb\\\\nDD 00/00/0000NNNANANFD/YY\\\\nISS\\\\n08/31/2009\\\\n\\"]}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"fullTextPages\\":[\\"USA DRIVER LICENSE\\\\nFEDERAL\\\\nLIMITS\\\\nAPPLY\\\\nDL 11234562\\\\nCLASS C\\\\nEXP 08/31/2014\\\\nEND NONE\\\\nLN SAMPLE\\\\nFN ALEXANDER JOSEPH\\\\n2570 24TH STREET\\\\nANYTOWN, CA 95818\\\\nDOB 08/31/1977\\\\nRSTR NONE\\\\n08311977\\\\n083177\\\\nDONOR\\\\nEYES BRN\\\\nalex f Sample\\\\nSEX M HAIR BLK\\\\nHGT 5-08\\\\\\" WGT 150 lb\\\\nDD 00/00/0000NNNANANFD/YY\\\\nISS\\\\n08/31/2009\\\\n\\"]}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "fullTextPages": [\n    "USA DRIVER LICENSE\\nFEDERAL\\nLIMITS\\nAPPLY\\nDL 11234562\\nCLASS C\\nEXP 08/31/2014\\nEND NONE\\nLN SAMPLE\\nFN ALEXANDER JOSEPH\\n2570 24TH STREET\\nANYTOWN, CA 95818\\nDOB 08/31/1977\\nRSTR NONE\\n08311977\\n083177\\nDONOR\\nEYES BRN\\nalex f Sample\\nSEX M HAIR BLK\\nHGT 5-08\\" WGT 150 lb\\nDD 00/00/0000NNNANANFD/YY\\nISS\\n08/31/2009\\n"\n  ]\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "fullTextPages": [\n    "USA DRIVER LICENSE\\nFEDERAL\\nLIMITS\\nAPPLY\\nDL 11234562\\nCLASS C\\nEXP 08/31/2014\\nEND NONE\\nLN SAMPLE\\nFN ALEXANDER JOSEPH\\n2570 24TH STREET\\nANYTOWN, CA 95818\\nDOB 08/31/1977\\nRSTR NONE\\n08311977\\n083177\\nDONOR\\nEYES BRN\\nalex f Sample\\nSEX M HAIR BLK\\nHGT 5-08\\" WGT 150 lb\\nDD 00/00/0000NNNANANFD/YY\\nISS\\n08/31/2009\\n"\n  ]\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "fullTextPages": [\n      "USA DRIVER LICENSE\\nFEDERAL\\nLIMITS\\nAPPLY\\nDL 11234562\\nCLASS C\\nEXP 08/31/2014\\nEND NONE\\nLN SAMPLE\\nFN ALEXANDER JOSEPH\\n2570 24TH STREET\\nANYTOWN, CA 95818\\nDOB 08/31/1977\\nRSTR NONE\\n08311977\\n083177\\nDONOR\\nEYES BRN\\nalex f Sample\\nSEX M HAIR BLK\\nHGT 5-08\\" WGT 150 lb\\nDD 00/00/0000NNNANANFD/YY\\nISS\\n08/31/2009\\n"\n    ]\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "fullTextPages": [\n      "USA DRIVER LICENSE\\nFEDERAL\\nLIMITS\\nAPPLY\\nDL 11234562\\nCLASS C\\nEXP 08/31/2014\\nEND NONE\\nLN SAMPLE\\nFN ALEXANDER JOSEPH\\n2570 24TH STREET\\nANYTOWN, CA 95818\\nDOB 08/31/1977\\nRSTR NONE\\n08311977\\n083177\\nDONOR\\nEYES BRN\\nalex f Sample\\nSEX M HAIR BLK\\nHGT 5-08\\" WGT 150 lb\\nDD 00/00/0000NNNANANFD/YY\\nISS\\n08/31/2009\\n"\n    ]\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "fullTextPages": [\n    "USA DRIVER LICENSE\\nFEDERAL\\nLIMITS\\nAPPLY\\nDL 11234562\\nCLASS C\\nEXP 08/31/2014\\nEND NONE\\nLN SAMPLE\\nFN ALEXANDER JOSEPH\\n2570 24TH STREET\\nANYTOWN, CA 95818\\nDOB 08/31/1977\\nRSTR NONE\\n08311977\\n083177\\nDONOR\\nEYES BRN\\nalex f Sample\\nSEX M HAIR BLK\\nHGT 5-08\\" WGT 150 lb\\nDD 00/00/0000NNNANANFD/YY\\nISS\\n08/31/2009\\n"\n  ]\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "fullTextPages": [\n    "USA DRIVER LICENSE\\nFEDERAL\\nLIMITS\\nAPPLY\\nDL 11234562\\nCLASS C\\nEXP 08/31/2014\\nEND NONE\\nLN SAMPLE\\nFN ALEXANDER JOSEPH\\n2570 24TH STREET\\nANYTOWN, CA 95818\\nDOB 08/31/1977\\nRSTR NONE\\n08311977\\n083177\\nDONOR\\nEYES BRN\\nalex f Sample\\nSEX M HAIR BLK\\nHGT 5-08\\" WGT 150 lb\\nDD 00/00/0000NNNANANFD/YY\\nISS\\n08/31/2009\\n"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "fullTextPages": [\n    "USA DRIVER LICENSE\\nFEDERAL\\nLIMITS\\nAPPLY\\nDL 11234562\\nCLASS C\\nEXP 08/31/2014\\nEND NONE\\nLN SAMPLE\\nFN ALEXANDER JOSEPH\\n2570 24TH STREET\\nANYTOWN, CA 95818\\nDOB 08/31/1977\\nRSTR NONE\\n08311977\\n083177\\nDONOR\\nEYES BRN\\nalex f Sample\\nSEX M HAIR BLK\\nHGT 5-08\\" WGT 150 lb\\nDD 00/00/0000NNNANANFD/YY\\nISS\\n08/31/2009\\n"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"fullTextPages":["USA DRIVER LICENSE\\nFEDERAL\\nLIMITS\\nAPPLY\\nDL 11234562\\nCLASS C\\nEXP 08/31/2014\\nEND NONE\\nLN SAMPLE\\nFN ALEXANDER JOSEPH\\n2570 24TH STREET\\nANYTOWN, CA 95818\\nDOB 08/31/1977\\nRSTR NONE\\n08311977\\n083177\\nDONOR\\nEYES BRN\\nalex f Sample\\nSEX M HAIR BLK\\nHGT 5-08\\" WGT 150 lb\\nDD 00/00/0000NNNANANFD/YY\\nISS\\n08/31/2009\\n"]}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""fullTextPages"":[""USA DRIVER LICENSE\\nFEDERAL\\nLIMITS\\nAPPLY\\nDL 11234562\\nCLASS C\\nEXP 08/31/2014\\nEND NONE\\nLN SAMPLE\\nFN ALEXANDER JOSEPH\\n2570 24TH STREET\\nANYTOWN, CA 95818\\nDOB 08/31/1977\\nRSTR NONE\\n08311977\\n083177\\nDONOR\\nEYES BRN\\nalex f Sample\\nSEX M HAIR BLK\\nHGT 5-08\\"" WGT 150 lb\\nDD 00/00/0000NNNANANFD/YY\\nISS\\n08/31/2009\\n""]}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"fullTextPages":["USA DRIVER LICENSE\\\\nFEDERAL\\\\nLIMITS\\\\nAPPLY\\\\nDL 11234562\\\\nCLASS C\\\\nEXP 08/31/2014\\\\nEND NONE\\\\nLN SAMPLE\\\\nFN ALEXANDER JOSEPH\\\\n2570 24TH STREET\\\\nANYTOWN, CA 95818\\\\nDOB 08/31/1977\\\\nRSTR NONE\\\\n08311977\\\\n083177\\\\nDONOR\\\\nEYES BRN\\\\nalex f Sample\\\\nSEX M HAIR BLK\\\\nHGT 5-08\\\\" WGT 150 lb\\\\nDD 00/00/0000NNNANANFD/YY\\\\nISS\\\\n08/31/2009\\\\n"]}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"fullTextPages":["USA DRIVER LICENSE\\\\nFEDERAL\\\\nLIMITS\\\\nAPPLY\\\\nDL 11234562\\\\nCLASS C\\\\nEXP 08/31/2014\\\\nEND NONE\\\\nLN SAMPLE\\\\nFN ALEXANDER JOSEPH\\\\n2570 24TH STREET\\\\nANYTOWN, CA 95818\\\\nDOB 08/31/1977\\\\nRSTR NONE\\\\n08311977\\\\n083177\\\\nDONOR\\\\nEYES BRN\\\\nalex f Sample\\\\nSEX M HAIR BLK\\\\nHGT 5-08\\\\" WGT 150 lb\\\\nDD 00/00/0000NNNANANFD/YY\\\\nISS\\\\n08/31/2009\\\\n"]}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"fullTextPages\\":[\\"USA DRIVER LICENSE\\\\nFEDERAL\\\\nLIMITS\\\\nAPPLY\\\\nDL 11234562\\\\nCLASS C\\\\nEXP 08/31/2014\\\\nEND NONE\\\\nLN SAMPLE\\\\nFN ALEXANDER JOSEPH\\\\n2570 24TH STREET\\\\nANYTOWN, CA 95818\\\\nDOB 08/31/1977\\\\nRSTR NONE\\\\n08311977\\\\n083177\\\\nDONOR\\\\nEYES BRN\\\\nalex f Sample\\\\nSEX M HAIR BLK\\\\nHGT 5-08\\\\\\" WGT 150 lb\\\\nDD 00/00/0000NNNANANFD/YY\\\\nISS\\\\n08/31/2009\\\\n\\"]}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    limitModelsForPerformance: {
      title: 'Scan a document by specifying document types (limits the auto-classifier)',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#4353238a-40f2-4f72-b2ca-d444bf95d105',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\",\\"modelTypes\\":[\\"driver_license/usa/ct\\",\\"driver_license/usa/ny\\",\\"driver_license/usa/nj\\"]}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\",\\"modelTypes\\":[\\"driver_license/usa/ct\\",\\"driver_license/usa/ny\\",\\"driver_license/usa/nj\\"]}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "modelTypes": [\n    "driver_license/usa/ct",\n    "driver_license/usa/ny",\n    "driver_license/usa/nj"\n  ]\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "modelTypes": [\n    "driver_license/usa/ct",\n    "driver_license/usa/ny",\n    "driver_license/usa/nj"\n  ]\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n    "modelTypes": [\n      "driver_license/usa/ct",\n      "driver_license/usa/ny",\n      "driver_license/usa/nj"\n    ]\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n    "modelTypes": [\n      "driver_license/usa/ct",\n      "driver_license/usa/ny",\n      "driver_license/usa/nj"\n    ]\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "modelTypes": [\n    "driver_license/usa/ct",\n    "driver_license/usa/ny",\n    "driver_license/usa/nj"\n  ]\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "modelTypes": [\n    "driver_license/usa/ct",\n    "driver_license/usa/ny",\n    "driver_license/usa/nj"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "modelTypes": [\n    "driver_license/usa/ct",\n    "driver_license/usa/ny",\n    "driver_license/usa/nj"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...","modelTypes":["driver_license/usa/ct","driver_license/usa/ny","driver_license/usa/nj"]}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."",""modelTypes"":[""driver_license/usa/ct"",""driver_license/usa/ny"",""driver_license/usa/nj""]}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...","modelTypes":["driver_license/usa/ct","driver_license/usa/ny","driver_license/usa/nj"]}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...","modelTypes":["driver_license/usa/ct","driver_license/usa/ny","driver_license/usa/nj"]}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\",\\"modelTypes\\":[\\"driver_license/usa/ct\\",\\"driver_license/usa/ny\\",\\"driver_license/usa/nj\\"]}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    extractOCRByURL: {
      title: 'Scan a document by URL and only convert to text (OCR)',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#d3e27adc-49fd-4232-a442-a4b07756e61c',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png\\",\\"modelTypes\\":[\\"ocr\\"]}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png\\",\\"modelTypes\\":[\\"ocr\\"]}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png",\n  "modelTypes": [\n    "ocr"\n  ]\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png",\n  "modelTypes": [\n    "ocr"\n  ]\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png",\n    "modelTypes": [\n      "ocr"\n    ]\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png",\n    "modelTypes": [\n      "ocr"\n    ]\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png",\n  "modelTypes": [\n    "ocr"\n  ]\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png",\n  "modelTypes": [\n    "ocr"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png",\n  "modelTypes": [\n    "ocr"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png","modelTypes":["ocr"]}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png"",""modelTypes"":[""ocr""]}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png","modelTypes":["ocr"]}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png","modelTypes":["ocr"]}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/restrict-hilight.png\\",\\"modelTypes\\":[\\"ocr\\"]}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    extractOCRByBinary: {
      title: 'Scan a document by file binary and only convert to text (OCR)',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#081d6fa0-4092-4b7e-a152-60a3d3b1b06a',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...\\",\\"modelTypes\\":[\\"ocr\\"]}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...\\",\\"modelTypes\\":[\\"ocr\\"]}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "modelTypes": [\n    "ocr"\n  ]\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "modelTypes": [\n    "ocr"\n  ]\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n    "modelTypes": [\n      "ocr"\n    ]\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n    "modelTypes": [\n      "ocr"\n    ]\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "modelTypes": [\n    "ocr"\n  ]\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "modelTypes": [\n    "ocr"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "modelTypes": [\n    "ocr"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...","modelTypes":["ocr"]}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."",""modelTypes"":[""ocr""]}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...","modelTypes":["ocr"]}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...","modelTypes":["ocr"]}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...\\",\\"modelTypes\\":[\\"ocr\\"]}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    scanFromTwoSidedCardByURL: {
      title: 'Scan two sided ID cards by URL',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#110d6ea0-f899-418a-984b-b16e2dec4e5a',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"urls\\":[\\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png\\",\\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png\\"]}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"urls\\":[\\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png\\",\\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png\\"]}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "urls": [\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png",\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"\n  ]\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "urls": [\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png",\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"\n  ]\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "urls": [\n      "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png",\n      "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"\n    ]\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "urls": [\n      "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png",\n      "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"\n    ]\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "urls": [\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png",\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"\n  ]\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "urls": [\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png",\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "urls": [\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png",\n    "https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"urls":["https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png","https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"]}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""urls"":[""https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png"",""https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png""]}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"urls":["https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png","https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"]}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"urls":["https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png","https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png"]}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"urls\\":[\\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight.png\\",\\"https://dmv.ny.gov/sites/default/files/styles/panopoly_image_original/public/cdl-endorsehilight-back.png\\"]}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    scanFromTwoSidedCardByBinary: {
      title: 'Scan two sided ID cards by file binary',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#f118f0ed-6def-4a8d-b02c-6c4a0c7a350d',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"images\\":[\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\",\\"data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX...\\"]}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"images\\":[\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\",\\"data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX...\\"]}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "images": [\n    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n    "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n  ]\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "images": [\n    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n    "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n  ]\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "images": [\n      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n      "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n    ]\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "images": [\n      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n      "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n    ]\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "images": [\n    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n    "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n  ]\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "images": [\n    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n    "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "images": [\n    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n    "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"images":["data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...","data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."]}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""images"":[""data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."",""data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX...""]}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"images":["data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...","data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."]}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"images":["data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...","data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."]}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"images\\":[\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\",\\"data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX...\\"]}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    }
  },
  'Redaction AI': {
    redactSensitiveDataByURL: {
      title: 'Redact sensitive information on a document by URL',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#bee2e619-6a5d-47c7-8e02-3b4af95cce33',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models//1.png\\",\\"settings\\":{\\"redactions\\":{\\"fields\\":[\\"name\\",\\"issueDate\\"],\\"faces\\":true,\\"signatures\\":true}}}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models//1.png\\",\\"settings\\":{\\"redactions\\":{\\"fields\\":[\\"name\\",\\"issueDate\\"],\\"faces\\":true,\\"signatures\\":true}}}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//1.png",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": true,\n      "signatures": true\n    }\n  }\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//1.png",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": true,\n      "signatures": true\n    }\n  }\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models//1.png",\n    "settings": {\n      "redactions": {\n        "fields": [\n          "name",\n          "issueDate"\n        ],\n        "faces": true,\n        "signatures": true\n      }\n    }\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models//1.png",\n    "settings": {\n      "redactions": {\n        "fields": [\n          "name",\n          "issueDate"\n        ],\n        "faces": true,\n        "signatures": true\n      }\n    }\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//1.png",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": true,\n      "signatures": true\n    }\n  }\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//1.png",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": True,\n      "signatures": True\n    }\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models//1.png",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": True,\n      "signatures": True\n    }\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"https://base64.ai/static/content/features/data-extraction/models//1.png","settings":{"redactions":{"fields":["name","issueDate"],"faces":true,"signatures":true}}}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""https://base64.ai/static/content/features/data-extraction/models//1.png"",""settings"":{""redactions"":{""fields"":[""name"",""issueDate""],""faces"":true,""signatures"":true}}}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"https://base64.ai/static/content/features/data-extraction/models//1.png","settings":{"redactions":{"fields":["name","issueDate"],"faces":true,"signatures":true}}}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"https://base64.ai/static/content/features/data-extraction/models//1.png","settings":{"redactions":{"fields":["name","issueDate"],"faces":true,"signatures":true}}}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models//1.png\\",\\"settings\\":{\\"redactions\\":{\\"fields\\":[\\"name\\",\\"issueDate\\"],\\"faces\\":true,\\"signatures\\":true}}}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    redactSensitiveDataByBinary: {
      title: 'Redact sensitive information on a document by file binary',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#40c537b1-3325-48d8-82b1-1509126f5f4b',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...\\",\\"settings\\":{\\"redactions\\":{\\"fields\\":[\\"name\\",\\"issueDate\\"],\\"faces\\":true,\\"signatures\\":true}}}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...\\",\\"settings\\":{\\"redactions\\":{\\"fields\\":[\\"name\\",\\"issueDate\\"],\\"faces\\":true,\\"signatures\\":true}}}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": true,\n      "signatures": true\n    }\n  }\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": true,\n      "signatures": true\n    }\n  }\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n    "settings": {\n      "redactions": {\n        "fields": [\n          "name",\n          "issueDate"\n        ],\n        "faces": true,\n        "signatures": true\n      }\n    }\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n    "settings": {\n      "redactions": {\n        "fields": [\n          "name",\n          "issueDate"\n        ],\n        "faces": true,\n        "signatures": true\n      }\n    }\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": true,\n      "signatures": true\n    }\n  }\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": True,\n      "signatures": True\n    }\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...",\n  "settings": {\n    "redactions": {\n      "fields": [\n        "name",\n        "issueDate"\n      ],\n      "faces": True,\n      "signatures": True\n    }\n  }\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...","settings":{"redactions":{"fields":["name","issueDate"],"faces":true,"signatures":true}}}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."",""settings"":{""redactions"":{""fields"":[""name"",""issueDate""],""faces"":true,""signatures"":true}}}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...","settings":{"redactions":{"fields":["name","issueDate"],"faces":true,"signatures":true}}}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...","settings":{"redactions":{"fields":["name","issueDate"],"faces":true,"signatures":true}}}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...\\",\\"settings\\":{\\"redactions\\":{\\"fields\\":[\\"name\\",\\"issueDate\\"],\\"faces\\":true,\\"signatures\\":true}}}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    }
  },
  'Document Validation AI': {
    validateCovidVaccinationCard: {
      title: 'Validate a COVID vaccination card with an ID, driver license, passport, or similar document.',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#6bc36e1e-3b0b-4bd6-91f7-0090d0abf848',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"idImage\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\",\\"vaccineCardImage\\":\\"data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX...\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"idImage\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\",\\"vaccineCardImage\\":\\"data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX...\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "idImage": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "vaccineCardImage": "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "idImage": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "vaccineCardImage": "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "idImage": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n    "vaccineCardImage": "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "idImage": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n    "vaccineCardImage": "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "idImage": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "vaccineCardImage": "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "idImage": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "vaccineCardImage": "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "idImage": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...",\n  "vaccineCardImage": "data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"idImage":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...","vaccineCardImage":"data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""idImage"":""data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD..."",""vaccineCardImage"":""data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX...""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"idImage":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...","vaccineCardImage":"data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"idImage":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...","vaccineCardImage":"data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX..."}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"idImage\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAD...\\",\\"vaccineCardImage\\":\\"data:image/png;base64,dsfVBODFS23442342534lDFdcvs33dX...\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    validateACORD25: {
      title: 'Validate an ACORD 25 file against minimums',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#d1eecf94-4c39-42a3-a81a-5100f399d9dd',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png\\",\\"validationUrl\\":\\"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png\\",\\"validationUrl\\":\\"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png",\n  "validationUrl": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png",\n  "validationUrl": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png",\n    "validationUrl": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png",\n    "validationUrl": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png",\n  "validationUrl": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png",\n  "validationUrl": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png",\n  "validationUrl": "https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png","validationUrl":"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png"",""validationUrl"":""https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png","validationUrl":"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png","validationUrl":"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/3.png\\",\\"validationUrl\\":\\"https://base64.ai/static/content/features/data-extraction/models/insurance/acord/25/acord25-validation.csv\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    }
  },
  'Face AI': {
    recognizeFaceByURL: {
      title: 'Recognize faces by URL',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#e723ea37-e1fe-4d53-b196-c36c73ba42d5',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/face")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"https://base64.ai/static/content/features/facial-recognition/himym.jpg\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"https://base64.ai/static/content/features/facial-recognition/himym.jpg\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/face")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/facial-recognition/himym.jpg"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/face\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/facial-recognition/himym.jpg"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/face");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/face",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/facial-recognition/himym.jpg"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/facial-recognition/himym.jpg"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/facial-recognition/himym.jpg"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/face"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/facial-recognition/himym.jpg"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/facial-recognition/himym.jpg"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/face", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/face"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"https://base64.ai/static/content/features/facial-recognition/himym.jpg"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/face", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""https://base64.ai/static/content/features/facial-recognition/himym.jpg""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/face\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"https://base64.ai/static/content/features/facial-recognition/himym.jpg"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/face\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"https://base64.ai/static/content/features/facial-recognition/himym.jpg"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"https://base64.ai/static/content/features/facial-recognition/himym.jpg\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/face")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    recognizeFaceByBinary: {
      title: 'Recognize faces by file binary',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#80b6fdbe-9326-4044-b3ab-5fc38a3c310a',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/face")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/face")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/face\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/face");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/face",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/face"\n\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/face", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/face"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/face", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/face\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/face\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/face")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    verifyFaceByURL: {
      title: 'Verify faces on two documents by URL',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#c7a03935-96dd-466d-b229-183ecffc28b0',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/face")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png\\",\\"queryUrl\\":\\"https://base64.ai/static/content/features/facial-recognition/justin.jpg\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png\\",\\"queryUrl\\":\\"https://base64.ai/static/content/features/facial-recognition/justin.jpg\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/face")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png",\n  "queryUrl": "https://base64.ai/static/content/features/facial-recognition/justin.jpg"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/face\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png",\n  "queryUrl": "https://base64.ai/static/content/features/facial-recognition/justin.jpg"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/face");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/face",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png",\n    "queryUrl": "https://base64.ai/static/content/features/facial-recognition/justin.jpg"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png",\n    "queryUrl": "https://base64.ai/static/content/features/facial-recognition/justin.jpg"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png",\n  "queryUrl": "https://base64.ai/static/content/features/facial-recognition/justin.jpg"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/face"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png",\n  "queryUrl": "https://base64.ai/static/content/features/facial-recognition/justin.jpg"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png",\n  "queryUrl": "https://base64.ai/static/content/features/facial-recognition/justin.jpg"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/face", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/face"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png","queryUrl":"https://base64.ai/static/content/features/facial-recognition/justin.jpg"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/face", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png"",""queryUrl"":""https://base64.ai/static/content/features/facial-recognition/justin.jpg""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/face\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png","queryUrl":"https://base64.ai/static/content/features/facial-recognition/justin.jpg"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/face\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png","queryUrl":"https://base64.ai/static/content/features/facial-recognition/justin.jpg"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"https://base64.ai/static/content/features/data-extraction/models/driver_license/usa/az/2.png\\",\\"queryUrl\\":\\"https://base64.ai/static/content/features/facial-recognition/justin.jpg\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/face")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    verifyFaceByBinary: {
      title: 'Verify faces on two documents by file binary',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#78b3c83c-4095-4427-a7e9-e09b19ab3fcc',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/face")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"reference\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\",\\"query\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD...\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"reference\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\",\\"query\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD...\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/face")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "reference": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "query": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/face\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "query": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/face");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/face",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n    "query": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "reference": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n    "query": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "reference": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "query": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/face"\n\npayload = json.dumps({\n  "reference": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "query": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "reference": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "query": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/face", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/face"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...","query":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/face", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."",""query"":""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD...""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/face\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"reference":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...","query":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/face\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"reference":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...","query":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"reference\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\",\\"query\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD...\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/face")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    storeNewFaceData: {
      title: 'Store a new face data for facial recognition',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#b6ff8393-2aad-4c44-b5ab-d84b4dd0d7a0',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/face")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\":\\"data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...\\",\\"name\\":\\"John Doe\\",\\"uniqueId\\":\\"FT123\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\":\\"data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...\\",\\"name\\":\\"John Doe\\",\\"uniqueId\\":\\"FT123\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/face")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...",\n  "name": "John Doe",\n  "uniqueId": "FT123"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/face\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...",\n  "name": "John Doe",\n  "uniqueId": "FT123"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/face");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/face",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...",\n    "name": "John Doe",\n    "uniqueId": "FT123"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...",\n    "name": "John Doe",\n    "uniqueId": "FT123"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...",\n  "name": "John Doe",\n  "uniqueId": "FT123"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/face"\n\npayload = json.dumps({\n  "document": "data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...",\n  "name": "John Doe",\n  "uniqueId": "FT123"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...",\n  "name": "John Doe",\n  "uniqueId": "FT123"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/face", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/face"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...","name":"John Doe","uniqueId":"FT123"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/face", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/..."",""name"":""John Doe"",""uniqueId"":""FT123""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/face\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document":"data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...","name":"John Doe","uniqueId":"FT123"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/face\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document":"data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...","name":"John Doe","uniqueId":"FT123"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\":\\"data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/...\\",\\"name\\":\\"John Doe\\",\\"uniqueId\\":\\"FT123\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/face")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    listStoredFaces: {
      title: 'List stored faces',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#c1cec4b2-72df-457b-847e-bfa865bfef7f',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/face")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/face")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/face\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/face");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send();'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/face",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/face"\n\npayload={}\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = \'\'\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/face", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/face"\n  method := "POST"\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, nil)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/face", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/face\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/face\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'var request = URLRequest(url: URL(string: "https://base64.ai/api/face")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    deleteStoredFace: {
      title: 'Delete a stored face',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#aeac7bc2-e381-4f36-b98a-9a5fc0b8131e',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/face")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"faceId\\":\\"XyZ123456\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"faceId\\":\\"XyZ123456\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/face")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "faceId": "XyZ123456"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/face\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "faceId": "XyZ123456"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/face");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/face",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "faceId": "XyZ123456"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "faceId": "XyZ123456"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "faceId": "XyZ123456"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/face"\n\npayload = json.dumps({\n  "faceId": "XyZ123456"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "faceId": "XyZ123456"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/face", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/face"\n  method := "POST"\n\n  payload := strings.NewReader(`{"faceId":"XyZ123456"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/face", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""faceId"":""XyZ123456""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/face\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"faceId":"XyZ123456"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/face\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"faceId":"XyZ123456"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"faceId\\":\\"XyZ123456\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/face")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    findUserByFaceData: {
      title: 'Find user by face data',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#8fe34cd2-33bf-48f5-b47b-2fd74f58cf20',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/face")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"mainImage\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"mainImage\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/face")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/face\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/face");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/face",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/face"\n\npayload = json.dumps({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/face", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/face"\n  method := "POST"\n\n  payload := strings.NewReader(`{"mainImage":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/face", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""mainImage"":""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/face\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"mainImage":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/face\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"mainImage":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"mainImage\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/face")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    findUserByFaceLiveness: {
      title: 'Find user by face liveness',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#215e9d63-6d39-43ab-b8ad-7f2964f34896',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/face")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"mainImage\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...\\",\\"additionalPhotos\\":[\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...\\",\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...\\",\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC...\\"]}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"mainImage\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...\\",\\"additionalPhotos\\":[\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...\\",\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...\\",\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC...\\"]}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/face")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "additionalPhotos": [\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."\n  ]\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/face\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "additionalPhotos": [\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."\n  ]\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/face");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/face",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n    "additionalPhotos": [\n      "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...",\n      "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...",\n      "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."\n    ]\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n    "additionalPhotos": [\n      "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...",\n      "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...",\n      "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."\n    ]\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/face\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "additionalPhotos": [\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."\n  ]\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/face"\n\npayload = json.dumps({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "additionalPhotos": [\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "mainImage": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "additionalPhotos": [\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...",\n    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."\n  ]\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/face", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/face"\n  method := "POST"\n\n  payload := strings.NewReader(`{"mainImage":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...","additionalPhotos":["data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...","data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...","data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."]}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/face", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""mainImage"":""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."",""additionalPhotos"":[""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA..."",""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB..."",""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC...""]}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/face\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"mainImage":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...","additionalPhotos":["data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...","data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...","data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."]}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/face\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"mainImage":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...","additionalPhotos":["data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...","data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...","data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC..."]}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"mainImage\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...\\",\\"additionalPhotos\\":[\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAA...\\",\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAB...\\",\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAC...\\"]}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/face")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    }
  },
  'Signature AI': {
    recognizeSignatureByURL: {
      title: 'Recognize ink signature by URL',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#d2605e41-f96d-44d4-8081-f5ec553d8bb8',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/signature")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"https://base64.ai/static/content/features/signature-verification/signature1.png\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"https://base64.ai/static/content/features/signature-verification/signature1.png\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/signature")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/signature\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/signature");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jquery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/signature",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/signature-verification/signature1.png"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/signature\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/signature-verification/signature1.png"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/signature\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/signature"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/signature", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/signature"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"https://base64.ai/static/content/features/signature-verification/signature1.png"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/signature", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""https://base64.ai/static/content/features/signature-verification/signature1.png""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/signature\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"https://base64.ai/static/content/features/signature-verification/signature1.png"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/signature\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"https://base64.ai/static/content/features/signature-verification/signature1.png"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"https://base64.ai/static/content/features/signature-verification/signature1.png\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/signature")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    recognizeSignatureByBinary: {
      title: 'Recognize ink signature by file binary',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#3c2fdf1b-07df-4d85-bc7f-48c180b2674c',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/signature")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/signature")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/signature\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/signature");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jquery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/signature",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/signature\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/signature\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/signature"\n\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/signature", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/signature"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/signature", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/signature\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/signature\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/signature")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    verifySignatureByURL: {
      title: 'Verify signatures on two documents by URL',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#ac49e0c1-beea-4a71-b624-dd26351ca6b6',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/signature")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\":\\"https://base64.ai/static/content/features/signature-verification/signature1.png\\",\\"queryUrl\\":\\"https://base64.ai/static/content/features/signature-verification/signature2.png\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\":\\"https://base64.ai/static/content/features/signature-verification/signature1.png\\",\\"queryUrl\\":\\"https://base64.ai/static/content/features/signature-verification/signature2.png\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/signature")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png",\n  "queryUrl": "https://base64.ai/static/content/features/signature-verification/signature2.png"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/signature\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png",\n  "queryUrl": "https://base64.ai/static/content/features/signature-verification/signature2.png"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/signature");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jquery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/signature",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/signature-verification/signature1.png",\n    "queryUrl": "https://base64.ai/static/content/features/signature-verification/signature2.png"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/signature\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/signature-verification/signature1.png",\n    "queryUrl": "https://base64.ai/static/content/features/signature-verification/signature2.png"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/signature\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png",\n  "queryUrl": "https://base64.ai/static/content/features/signature-verification/signature2.png"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/signature"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png",\n  "queryUrl": "https://base64.ai/static/content/features/signature-verification/signature2.png"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/signature-verification/signature1.png",\n  "queryUrl": "https://base64.ai/static/content/features/signature-verification/signature2.png"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/signature", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/signature"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url":"https://base64.ai/static/content/features/signature-verification/signature1.png","queryUrl":"https://base64.ai/static/content/features/signature-verification/signature2.png"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/signature", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"":""https://base64.ai/static/content/features/signature-verification/signature1.png"",""queryUrl"":""https://base64.ai/static/content/features/signature-verification/signature2.png""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/signature\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url":"https://base64.ai/static/content/features/signature-verification/signature1.png","queryUrl":"https://base64.ai/static/content/features/signature-verification/signature2.png"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/signature\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url":"https://base64.ai/static/content/features/signature-verification/signature1.png","queryUrl":"https://base64.ai/static/content/features/signature-verification/signature2.png"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\":\\"https://base64.ai/static/content/features/signature-verification/signature1.png\\",\\"queryUrl\\":\\"https://base64.ai/static/content/features/signature-verification/signature2.png\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/signature")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    verifySignatureByBinary: {
      title: 'Verify signatures on two documents by file binary',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#4901ae73-d3e4-4725-bfdd-a72abd9b9fca',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/signature")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\",\\"queryDocument\\":\\"data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD...\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\",\\"queryDocument\\":\\"data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD...\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/signature")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "queryDocument": "data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/signature\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "queryDocument": "data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/signature");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jquery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/signature",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n    "queryDocument": "data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/signature\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n    "queryDocument": "data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/signature\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "queryDocument": "data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/signature"\n\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "queryDocument": "data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...",\n  "queryDocument": "data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/signature", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/signature"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...","queryDocument":"data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/signature", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB..."",""queryDocument"":""data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD...""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/signature\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...","queryDocument":"data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/signature\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...","queryDocument":"data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD..."}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\":\\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB...\\",\\"queryDocument\\":\\"data:document/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD...\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/signature")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    }
  },
  'Flow': {
    parseSearchQuestion: {
      title: 'Parse a question for search in a flow',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#b603162e-f7c5-4f07-8a47-e030547caad5',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/flow/search/parse")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"question\\": \\"show me male driver licences that are expired\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"question\\": \\"show me male driver licences that are expired\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/flow/search/parse")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "question": "show me male driver licences that are expired"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/flow/search/parse\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "question": "show me male driver licences that are expired"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/flow/search/parse");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/flow/search/parse",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "question": "show me male driver licences that are expired"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/flow/search/parse\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "question": "show me male driver licences that are expired"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/flow/search/parse\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "question": "show me male driver licences that are expired"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/flow/search/parse"\n\npayload = json.dumps({\n  "question": "show me male driver licences that are expired"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "question": "show me male driver licences that are expired"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/flow/search/parse", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/flow/search/parse"\n  method := "POST"\n\n  payload := strings.NewReader(`{"question": "show me male driver licences that are expired"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/flow/search/parse", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""question"": ""show me male driver licences that are expired""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/flow/search/parse\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"question": "show me male driver licences that are expired"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/flow/search/parse\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"question": "show me male driver licences that are expired"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"question\\": \\"show me male driver licences that are expired\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/flow/search/parse")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    searchQuestionInFlow: {
      title: 'Search a question in a flow',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#2467b8da-e475-40a1-9559-d832cf6943f0',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/flow/search/{FLOW_ID}")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"modelTypes\\": [\\"driver_license\\"],\\"fields\\": [{\\"key\\": \\"sex\\",\\"operator\\": \\"$eq\\",\\"query\\": \\"Male\\"},{\\"key\\": \\"expirationDate\\",\\"operator\\": \\"$lt\\",\\"query\\": \\"2024-07-04\\"}],\\"orderBy\\": [{\\"key\\": \\"meta.updatedAt\\",\\"order\\": \\"desc\\"}],\\"question\\": \\"show me male driver licences that are expired\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"modelTypes\\": [\\"driver_license\\"],\\"fields\\": [{\\"key\\": \\"sex\\",\\"operator\\": \\"$eq\\",\\"query\\": \\"Male\\"},{\\"key\\": \\"expirationDate\\",\\"operator\\": \\"$lt\\",\\"query\\": \\"2024-07-04\\"}],\\"orderBy\\": [{\\"key\\": \\"meta.updatedAt\\",\\"order\\": \\"desc\\"}],\\"question\\": \\"show me male driver licences that are expired\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/flow/search/{FLOW_ID}")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "modelTypes": [\n    "driver_license"\n  ],\n  "fields": [\n    {\n      "key": "sex",\n      "operator": "$eq",\n      "query": "Male"\n    },\n    {\n      "key": "expirationDate",\n      "operator": "$lt",\n      "query": "2024-07-04"\n    }\n  ],\n  "orderBy": [\n    {\n      "key": "meta.updatedAt",\n      "order": "desc"\n    }\n  ],\n  "question": "show me male driver licences that are expired"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/flow/search/{FLOW_ID}\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "modelTypes": [\n    "driver_license"\n  ],\n  "fields": [\n    {\n      "key": "sex",\n      "operator": "$eq",\n      "query": "Male"\n    },\n    {\n      "key": "expirationDate",\n      "operator": "$lt",\n      "query": "2024-07-04"\n    }\n  ],\n  "orderBy": [\n    {\n      "key": "meta.updatedAt",\n      "order": "desc"\n    }\n  ],\n  "question": "show me male driver licences that are expired"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/flow/search/%7BFLOW_ID%7D");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/flow/search/{FLOW_ID}",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "modelTypes": [\n      "driver_license"\n    ],\n    "fields": [\n      {\n        "key": "sex",\n        "operator": "$eq",\n        "query": "Male"\n      },\n      {\n        "key": "expirationDate",\n        "operator": "$lt",\n        "query": "2024-07-04"\n      }\n    ],\n    "orderBy": [\n      {\n        "key": "meta.updatedAt",\n        "order": "desc"\n      }\n    ],\n    "question": "show me male driver licences that are expired"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/flow/search/{FLOW_ID}\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "modelTypes": [\n      "driver_license"\n    ],\n    "fields": [\n      {\n        "key": "sex",\n        "operator": "$eq",\n        "query": "Male"\n      },\n      {\n        "key": "expirationDate",\n        "operator": "$lt",\n        "query": "2024-07-04"\n      }\n    ],\n    "orderBy": [\n      {\n        "key": "meta.updatedAt",\n        "order": "desc"\n      }\n    ],\n    "question": "show me male driver licences that are expired"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/flow/search/{FLOW_ID}\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "modelTypes": [\n    "driver_license"\n  ],\n  "fields": [\n    {\n      "key": "sex",\n      "operator": "$eq",\n      "query": "Male"\n    },\n    {\n      "key": "expirationDate",\n      "operator": "$lt",\n      "query": "2024-07-04"\n    }\n  ],\n  "orderBy": [\n    {\n      "key": "meta.updatedAt",\n      "order": "desc"\n    }\n  ],\n  "question": "show me male driver licences that are expired"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/flow/search/{FLOW_ID}"\n\npayload = json.dumps({\n  "modelTypes": [\n    "driver_license"\n  ],\n  "fields": [\n    {\n      "key": "sex",\n      "operator": "$eq",\n      "query": "Male"\n    },\n    {\n      "key": "expirationDate",\n      "operator": "$lt",\n      "query": "2024-07-04"\n    }\n  ],\n  "orderBy": [\n    {\n      "key": "meta.updatedAt",\n      "order": "desc"\n    }\n  ],\n  "question": "show me male driver licences that are expired"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "modelTypes": [\n    "driver_license"\n  ],\n  "fields": [\n    {\n      "key": "sex",\n      "operator": "$eq",\n      "query": "Male"\n    },\n    {\n      "key": "expirationDate",\n      "operator": "$lt",\n      "query": "2024-07-04"\n    }\n  ],\n  "orderBy": [\n    {\n      "key": "meta.updatedAt",\n      "order": "desc"\n    }\n  ],\n  "question": "show me male driver licences that are expired"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/flow/search/{FLOW_ID}", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/flow/search/%7BFLOW_ID%7D"\n  method := "POST"\n\n  payload := strings.NewReader(`{"modelTypes": ["driver_license"],"fields": [{"key": "sex","operator": "$eq","query": "Male"},{"key": "expirationDate","operator": "$lt","query": "2024-07-04"}],"orderBy": [{"key": "meta.updatedAt","order": "desc"}],"question": "show me male driver licences that are expired"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/flow/search/{FLOW_ID}", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""modelTypes"": [""driver_license""],""fields"": [{""key"": ""sex"",""operator"": ""$eq"",""query"": ""Male""},{""key"": ""expirationDate"",""operator"": ""$lt"",""query"": ""2024-07-04""}],""orderBy"": [{""key"": ""meta.updatedAt"",""order"": ""desc""}],""question"": ""show me male driver licences that are expired""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/flow/search/%7BFLOW_ID%7D\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"modelTypes": ["driver_license"],"fields": [{"key": "sex","operator": "$eq","query": "Male"},{"key": "expirationDate","operator": "$lt","query": "2024-07-04"}],"orderBy": [{"key": "meta.updatedAt","order": "desc"}],"question": "show me male driver licences that are expired"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/flow/search/{FLOW_ID}\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"modelTypes": ["driver_license"],"fields": [{"key": "sex","operator": "$eq","query": "Male"},{"key": "expirationDate","operator": "$lt","query": "2024-07-04"}],"orderBy": [{"key": "meta.updatedAt","order": "desc"}],"question": "show me male driver licences that are expired"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"modelTypes\\": [\\"driver_license\\"],\\"fields\\": [{\\"key\\": \\"sex\\",\\"operator\\": \\"$eq\\",\\"query\\": \\"Male\\"},{\\"key\\": \\"expirationDate\\",\\"operator\\": \\"$lt\\",\\"query\\": \\"2024-07-04\\"}],\\"orderBy\\": [{\\"key\\": \\"meta.updatedAt\\",\\"order\\": \\"desc\\"}],\\"question\\": \\"show me male driver licences that are expired\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/flow/search/{FLOW_ID}")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    }
  },
  'Testing': {
    mockDataExtraction: {
      title: 'Mock data extraction for testing (free to use)',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#ea06ee71-a337-4286-a2da-12754d401314',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/mock/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/mock/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/mock/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/mock/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/mock/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/mock/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/mock/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/mock/scan"\n\npayload = json.dumps({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/mock/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/mock/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/mock/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/mock/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/mock/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD...\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/mock/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    }
  },
  'Other': {
    reportIssues: {
      title: 'Report issues to Base64.ai Support programmatically',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#2e4cf476-32a0-4e45-85eb-d09fec1143e0',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/report")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...\\",\\"note\\":\\"Custom note\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...\\",\\"note\\":\\"Custom note\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/report")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "note": "Custom note"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/report\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "note": "Custom note"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/report");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/report",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n    "note": "Custom note"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/report\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n    "note": "Custom note"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/report\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "note": "Custom note"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/report"\n\npayload = json.dumps({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "note": "Custom note"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "document": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...",\n  "note": "Custom note"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/report", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/report"\n  method := "POST"\n\n  payload := strings.NewReader(`{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...","note":"Custom note"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/report", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""document"":""data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD..."",""note"":""Custom note""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/report\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...","note":"Custom note"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/report\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"document":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...","note":"Custom note"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"document\\":\\"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD...\\",\\"note\\":\\"Custom note\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/report")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    getUserData: {
      title: 'Get user data such as name, remaining credits, and tags',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#39190748-5d86-4fba-a6ba-50d0df900e2f',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.get("https://base64.ai/api/auth/user")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/auth/user")\n  .method("GET", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar requestOptions = {\n  method: \'GET\',\n  headers: myHeaders,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/auth/user\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For GET requests, body is set to null by browsers.\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("GET", "https://base64.ai/api/auth/user");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send();'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/auth/user",\n  "method": "GET",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'GET\',\n  \'url\': \'https://base64.ai/api/auth/user\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'GET\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/auth/user\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/auth/user"\n\npayload={}\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("GET", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = \'\'\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("GET", "/api/auth/user", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/auth/user"\n  method := "GET"\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, nil)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/auth/user", Method.Get);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/auth/user\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'GET\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/auth/user\');\n$request->setMethod(HTTP_Request2::METHOD_GET);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'var request = URLRequest(url: URL(string: "https://base64.ai/api/auth/user")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "GET"\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    },
    echoParamenter: {
      title: 'Add echo parameter to a scan request',
      more_url: 'https://apidoc.base64.ai/view/10132588/SWT5hfdz#2-document-processing',
      languages: {
        'java_Unirest': {
          key: 'java',
          name: 'Java - Unirest',
          snippet: 'Unirest.setTimeouts(0, 10000);\nHttpResponse<String> response = Unirest.post("https://base64.ai/api/scan")\n  .header("Content-Type", "application/json")\n  .header("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .body("{\\"url\\": \\"https://base64.ai/static/content/features/data-extraction/models/2.png\\", \\"echo\\": \\"Hello World\\"}")\n  .asString();\n'
        },
        'java_OkHttp': {
          key: 'java',
          name: 'Java - OkHttp',
          snippet: 'OkHttpClient client = new OkHttpClient().newBuilder()\n  .setConnectTimeout(10000, TimeUnit.MILLISECONDS)\n  .build();\nMediaType mediaType = MediaType.parse("application/json");\nRequestBody body = RequestBody.create(mediaType, "{\\"url\\": \\"https://base64.ai/static/content/features/data-extraction/models/2.png\\", \\"echo\\": \\"Hello World\\"}");\nRequest request = new Request.Builder()\n  .url("https://base64.ai/api/scan")\n  .method("POST", body)\n  .addHeader("Content-Type", "application/json")\n  .addHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n  .build();\nResponse response = client.newCall(request).execute();'
        },
        'javascript_Fetch': {
          key: 'javascript',
          name: 'Javascript - Fetch',
          snippet: 'var myHeaders = new Headers();\nmyHeaders.append("Content-Type", "application/json");\nmyHeaders.append("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nvar raw = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png",\n  "echo": "Hello World"\n});\n\nvar requestOptions = {\n  method: \'POST\',\n  headers: myHeaders,\n  body: raw,\n  redirect: \'follow\'\n};\n\nvar promise = Promise.race([\n  fetch(\'https://base64.ai/api/scan\', requestOptions)\n    .then(response => response.text()),\n  new Promise((resolve, reject) =>\n    setTimeout(() => reject(new Error(\'Timeout\')), 10000)\n  )\n]);\n\npromise.then(result => console.log(result)),\npromise.catch(error => console.log(error));'
        },
        'javascript_XHR': {
          key: 'javascript',
          name: 'Javascript - XHR',
          snippet: '// WARNING: For POST requests, body is set to null by browsers.\nvar data = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png",\n  "echo": "Hello World"\n});\n\nvar xhr = new XMLHttpRequest();\nxhr.withCredentials = true;\n\nxhr.addEventListener("readystatechange", function() {\n  if(this.readyState === 4) {\n    console.log(this.responseText);\n  }\n});\n\nxhr.open("POST", "https://base64.ai/api/scan");\nxhr.timeout = 10000;\nxhr.addEventListener("ontimeout", function(e) {\n   console.log(e);\n});\nxhr.setRequestHeader("Content-Type", "application/json");\nxhr.setRequestHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\n\nxhr.send(data);'
        },
        'javascript_jQuery': {
          key: 'javascript',
          name: 'Javascript - jQuery',
          snippet: 'var settings = {\n  "url": "https://base64.ai/api/scan",\n  "method": "POST",\n  "timeout": 10000,\n  "headers": {\n    "Content-Type": "application/json",\n    "Authorization": "ApiKey {EMAIL}:{APIKEY}"\n  },\n  "data": JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models/2.png",\n    "echo": "Hello World"\n  }),\n};\n\n$.ajax(settings).done(function (response) {\n  console.log(response);\n});'
        },
        'nodejs_Request': {
          key: 'javascript',
          name: 'Node.js - Request',
          snippet: 'var request = require(\'request\');\nvar options = {\n  \'method\': \'POST\',\n  \'url\': \'https://base64.ai/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  body: JSON.stringify({\n    "url": "https://base64.ai/static/content/features/data-extraction/models/2.png",\n    "echo": "Hello World"\n  })\n,\n  timeout: 10000\n};\nrequest(options, function (error, response) {\n  if (error) throw new Error(error);\n  console.log(response.body);\n});\n'
        },
        'nodejs_Native': {
          key: 'javascript',
          name: 'Node.js - Native',
          snippet: 'var https = require(\'follow-redirects\').https;\nvar fs = require(\'fs\');\n\nvar options = {\n  \'method\': \'POST\',\n  \'hostname\': \'base64.ai\',\n  \'path\': \'/api/scan\',\n  \'headers\': {\n    \'Content-Type\': \'application/json\',\n    \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n  },\n  \'maxRedirects\': 20\n};\n\nvar req = https.request(options, function (res) {\n  var chunks = [];\n\n  res.on("data", function (chunk) {\n    chunks.push(chunk);\n  });\n\n  res.on("end", function (chunk) {\n    var body = Buffer.concat(chunks);\n    console.log(body.toString());\n  });\n\n  res.on("error", function (error) {\n    console.error(error);\n  });\n});\n\nvar postData = JSON.stringify({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png",\n  "echo": "Hello World"\n});\n\nreq.write(postData);\n\nreq.setTimeout(10000, function() {\n  req.abort();\n});\n\nreq.end();'
        },
        'python_Requests': {
          key: 'python',
          name: 'Python - Requests',
          snippet: 'import requests\nimport json\n\nurl = "https://base64.ai/api/scan"\n\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png",\n  "echo": "Hello World"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\n\nresponse = requests.request("POST", url, headers=headers, data=payload, timeout=10000)\n\nprint(response.text)\n'
        },
        'python_http.client': {
          key: 'python',
          name: 'Python - http.client',
          snippet: 'import http.client\nimport json\n\nconn = http.client.HTTPSConnection("base64.ai", timeout = 10000)\npayload = json.dumps({\n  "url": "https://base64.ai/static/content/features/data-extraction/models/2.png",\n  "echo": "Hello World"\n})\nheaders = {\n  \'Content-Type\': \'application/json\',\n  \'Authorization\': \'ApiKey {EMAIL}:{APIKEY}\'\n}\nconn.request("POST", "/api/scan", payload, headers)\nres = conn.getresponse()\ndata = res.read()\nprint(data.decode("utf-8"))'
        },
        'go_Native': {
          key: 'go',
          name: 'Go - Native',
          snippet: 'package main\n\nimport (\n  "fmt"\n  "time"\n  "strings"\n  "net/http"\n  "io/ioutil"\n)\n\nfunc main() {\n\n  url := "https://base64.ai/api/scan"\n  method := "POST"\n\n  payload := strings.NewReader(`{"url": "https://base64.ai/static/content/features/data-extraction/models/2.png", "echo": "Hello World"}`)\n\n  timeout := time.Duration(10 * time.Second)\n  client := &http.Client {\n    Timeout: timeout,\n  }\n  req, err := http.NewRequest(method, url, payload)\n\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  req.Header.Add("Content-Type", "application/json")\n  req.Header.Add("Authorization", "ApiKey {EMAIL}:{APIKEY}")\n\n  res, err := client.Do(req)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  defer res.Body.Close()\n\n  body, err := ioutil.ReadAll(res.Body)\n  if err != nil {\n    fmt.Println(err)\n    return\n  }\n  fmt.Println(string(body))\n}'
        },
        'csharp_RestSharp': {
          key: 'java',
          name: 'C# - RestSharp',
          snippet: 'var options = new RestClientOptions("https://base64.ai")\n{\n  MaxTimeout = 10000,\n};\nvar client = new RestClient(options);\nvar request = new RestRequest("/api/scan", Method.Post);\nrequest.AddHeader("Content-Type", "application/json");\nrequest.AddHeader("Authorization", "ApiKey {EMAIL}:{APIKEY}");\nvar body = @"{""url"": ""https://base64.ai/static/content/features/data-extraction/models/2.png"", ""echo"": ""Hello World""}";\nrequest.AddStringBody(body, DataFormat.Json);\nRestResponse response = await client.ExecuteAsync(request);\nConsole.WriteLine(response.Content);'
        },
        'php_cURL': {
          key: 'php',
          name: 'PHP - cURL',
          snippet: '<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(\n  CURLOPT_URL => \'https://base64.ai/api/scan\',\n  CURLOPT_RETURNTRANSFER => true,\n  CURLOPT_ENCODING => \'\',\n  CURLOPT_MAXREDIRS => 10,\n  CURLOPT_TIMEOUT => 10000,\n  CURLOPT_FOLLOWLOCATION => true,\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n  CURLOPT_CUSTOMREQUEST => \'POST\',\n  CURLOPT_POSTFIELDS =>\'{"url": "https://base64.ai/static/content/features/data-extraction/models/2.png", "echo": "Hello World"}\',\n  CURLOPT_HTTPHEADER => array(\n    \'Content-Type: application/json\',\n    \'Authorization: ApiKey {EMAIL}:{APIKEY}\'\n  ),\n));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;\n'
        },
        'php_HTTP_Request2': {
          key: 'php',
          name: 'PHP - HTTP_Request2',
          snippet: '<?php\nrequire_once \'HTTP/Request2.php\';\n$request = new HTTP_Request2();\n$request->setUrl(\'https://base64.ai/api/scan\');\n$request->setMethod(HTTP_Request2::METHOD_POST);\n$request->setConfig(array(\n  \'timeout\' => 10,\n  \'follow_redirects\' => TRUE\n));\n$request->setHeader(array(\n  \'Content-Type\' => \'application/json\',\n  \'Authorization\' => \'ApiKey {EMAIL}:{APIKEY}\'\n));\n$request->setBody(\'{"url": "https://base64.ai/static/content/features/data-extraction/models/2.png", "echo": "Hello World"}\');\ntry {\n  $response = $request->send();\n  if ($response->getStatus() == 200) {\n    echo $response->getBody();\n  }\n  else {\n    echo \'Unexpected HTTP status: \' . $response->getStatus() . \' \' .\n    $response->getReasonPhrase();\n  }\n}\ncatch(HTTP_Request2_Exception $e) {\n  echo \'Error: \' . $e->getMessage();\n}'
        },
        'swift_URLSession': {
          key: 'swift',
          name: 'Swift - URLSession',
          snippet: 'let parameters = "{\\"url\\": \\"https://base64.ai/static/content/features/data-extraction/models/2.png\\", \\"echo\\": \\"Hello World\\"}"\nlet postData = parameters.data(using: .utf8)\n\nvar request = URLRequest(url: URL(string: "https://base64.ai/api/scan")!,timeoutInterval: 10000)\nrequest.addValue("application/json", forHTTPHeaderField: "Content-Type")\nrequest.addValue("ApiKey {EMAIL}:{APIKEY}", forHTTPHeaderField: "Authorization")\n\nrequest.httpMethod = "POST"\nrequest.httpBody = postData\n\nlet task = URLSession.shared.dataTask(with: request) { data, response, error in \n  guard let data = data else {\n    print(String(describing: error))\n    return\n  }\n  print(String(data: data, encoding: .utf8)!)\n}\n\ntask.resume()\n'
        }
      }
    }
  }
}
