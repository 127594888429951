import React, { Component } from 'react'
import MaterialIcon from '../material-icon/MaterialIcon'
import MultiInputTable from '../multi-input-table/MultiInputTable'
import propTypes from 'prop-types'
import csv from 'csv-parse/lib/sync'
// Context
import Context from '~/context/global'
import taxonomyTable from '../../data/taxonomyTable'

class Taxonomy extends Component {
  static contextType = Context

  static defaultProps = {
    taxonomyTable: taxonomyTable
  }

  static propTypes = {
    taxonomyTable: MultiInputTable.propTypes.table,
    headerParagraph: propTypes.node,
    onChange: propTypes.func,
    taxonomies: propTypes.arrayOf(propTypes.object)
  }

  constructor (props) {
    super(props)
    this.state = {
      taxonomies: props.taxonomies || [],
      isSubmitted: false,
      taxonomyInputText: ''
    }
    this.onImportTaxonomies = this.onImportTaxonomies.bind(this)
    this.updateTaxonomiesViaImport = this.updateTaxonomiesViaImport.bind(this)
    this.onExportTaxonomies = this.onExportTaxonomies.bind(this)
  }

  updateTaxonomiesViaImport () {
    if (this.state.taxonomyInputText?.length) {
      const text = this.state.taxonomyInputText
      let csvObject = null
      try {
        csvObject = csv(text, {
          skip_empty_lines: true,
          columns: true,
          relax_column_count: true
        })
      } catch (e) {
        console.error(e)
        this.context.displayModal({
          title: 'Error',
          content: (
            <div>
              <p>Failed to parse the CSV file.</p>
            </div>
          ),
          options: [{ content: 'OK' }],
          isClosable: true
        })
        return
      }

      if (!csvObject) {
        this.context.displayModal({
          title: 'Error',
          content: (
            <div>
              <p>Failed to parse the CSV file.</p>
            </div>
          ),
          options: [{ content: 'OK' }],
          isClosable: true
        })
        return
      }

      // csv field validation
      for (const [index, row] of csvObject.entries()) {
        if (!row.name || !row.alternateSpelling) {
          this.context.displayModal({
            title: 'Error',
            content: (
              <div>
                <p>Row {index + 1} has missing field name or alternate spelling.</p>
              </div>
            ),
            options: [{ content: 'OK' }],
            isClosable: true
          })
          return
        }
      }

      const newTaxonomies = csvObject.map(row => ({
        name: row.name,
        alternateSpelling: (row.alternateSpelling || '').split(/(?<!\\),/).map(spelling => {
          const unescapedSpelling = spelling.replace(/\\,/g, ',').trim()
          return { value: unescapedSpelling, label: unescapedSpelling }
        }),
        deleteMatchedTaxonomy: row.deleteMatchedTaxonomy === 'true'
      }))

      this.setState({
        taxonomies: newTaxonomies
      })
      this.props.onChange(newTaxonomies)
    }
  }

  onImportTaxonomies (e) {
    const file = e.target.files && e.target.files[0]
    if (!file) {
      return
    }
    if (file.type !== 'text/csv' || !file.name.endsWith('.csv')) {
      this.context.displayModal({
        title: 'Error',
        content: (
          <div>
            <p>Only CSV files are supported.</p>
            <p>File name: {file.name}</p>
            <p>File type: {file.type}</p>
          </div>
        ),
        options: [{ content: 'OK' }],
        isClosable: true
      })
      // Clear the file input value on error
      e.target.value = null
      return
    }
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      this.setState({
        taxonomyInputText: reader.result
      }, () => {
        try {
          this.updateTaxonomiesViaImport()
          // Clear the file input value
          e.target.value = null
        } catch (error) {
          this.context.displayModal({
            title: 'Error',
            content: (
              <div>
                <p>Failed to parse the CSV file. Please check the file format.</p>
                <p>Error details: {error.message}</p>
              </div>
            ),
            options: [{ content: 'OK' }],
            isClosable: true
          })
          // Clear the file input value on error
          e.target.value = null
        }
      })
    }
    reader.onerror = () => {
      this.context.displayModal({
        title: 'Error',
        content: (
          <div>
            <p>Failed to read the file.</p>
          </div>
        ),
        options: [{ content: 'OK' }],
        isClosable: true
      })
      // Clear the file input value on error
      e.target.value = null
    }
  }

  onExportTaxonomies () {
    const csvString = 'name,alternateSpelling,deleteMatchedTaxonomy\n'
      + this.state.taxonomies.map(row => `${row.name.replace(/,/g, '\\,')},"${row.alternateSpelling.map(obj => obj.value.replace(/,/g, '\\,')).join(',').replace(/"/g, '""')}",${row.deleteMatchedTaxonomy ? 'true' : 'false'}`).join('\n')

    const blob = new Blob([csvString], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'taxonomies.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  render () {
    return (
      <React.Fragment>
        <div className='taxonomy-header-row header-row-with-icons'>
          <div className='header-row-title'>
            <h2 className='title'>Custom Taxonomy</h2>
          </div>
          <div className='header-row-icons'>
            <label htmlFor='taxonomy-csv-import'>
              <MaterialIcon title='Import Taxonomies from file' name='upload' className='material-icon-outlined icon-size' role='button' />
            </label>
            <input id='taxonomy-csv-import' type='file' accept='.csv' hidden onChange={this.onImportTaxonomies} />
            <MaterialIcon title='Export Taxonomies to file' name='download' className='material-icon-outlined icon-size' style={{ marginLeft: '0.5rem' }} onClick={this.onExportTaxonomies} />
          </div>
        </div>
        {this.props.headerParagraph}
        <MultiInputTable
          tableStructure={this.props.taxonomyTable}
          data={this.state.taxonomies}
          onChange={data => {
            this.setState({
              taxonomies: data,
              isSubmitted: false
            }),
            this.props.onChange(data)
          }}
        />
      </React.Fragment>
    )
  }
}

export default Taxonomy
