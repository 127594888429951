// Modules
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Interface: Checkbox
const Checkbox = props => (
  <div
    className={clsx('checkbox', props.className)}
    style={{ ...props.style, width: props.width }}
  >
    {props.placeholder !== undefined
    && (
      <label htmlFor={props.name} title={!props.showTitle && props.title} className={clsx(props.labelClassName, props.still && 'still')}>
        <input
          type='checkbox'
          checked={props.checked || false}
          defaultChecked={props.defaultChecked || false}
          {...props}
        />
        <p>{props.showTitle ? <><b>{props.placeholder}</b><br />{props.title}</> : props.placeholder}</p>
      </label>
    )}
  </div>
)

Checkbox.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  still: PropTypes.bool,
  placeholder: PropTypes.node,
  className: PropTypes.string,
  checked: PropTypes.bool,
  showTitle: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  style: PropTypes.object,
  labelClassName: PropTypes.string
}

// Export
export default Checkbox
