import { useState } from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from '../material-icon/MaterialIcon'
import VoiceInput from '../voice/Voice'

const QuestionBox = ({ onResult, placeholder = 'Type or speak your question...', noteOnTyping, inputAutoFocus }) => {
  const [question, setQuestion] = useState('')
  return (
    <>
      <div className='query'>
        <MaterialIcon name='search' />
        <VoiceInput
          className='voice-question'
          onResult={question => {
            setQuestion(question)
            onResult?.(question, 'voice')
          }}
          style={{ padding: '0.2rem', userSelect: 'none' }}
        />
        <input
          autoFocus={inputAutoFocus}
          className='query'
          placeholder={placeholder}
          title={noteOnTyping}
          value={question}
          onChange={e => {
            setQuestion(e.target.value)
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              if (question) {
                onResult?.(question, 'text')
              }
            }
          }}
        />
        {question && (
          <>
            <MaterialIcon
              name='clear'
              title='Clear question'
              onClick={() => {
                setQuestion('')
              }}
            />
            <MaterialIcon
              name='send'
              title='Ask question'
              onClick={() => {
                if (question) {
                  onResult?.(question, 'text')
                }
              }}
            />
          </>
        )}
      </div>
      {noteOnTyping && <div className='query-info'></div>}
    </>
  )
}

QuestionBox.propTypes = {
  onResult: PropTypes.func,
  placeholder: PropTypes.string,
  noteOnTyping: PropTypes.string,
  inputAutoFocus: PropTypes.bool
}

export default QuestionBox
