import { Component } from 'react'
import Section from '~/layout/section/Section'
import Context from '~/context/global'
import PropTypes from 'prop-types'
import benefitsMap from '~/data/benefitsMap'
import benefits from '~/data/benefits'
import solutionTypes from '~/data/solutionTypes'
import dataExtractionTypes from '~/data/dataExtractionTypes'

class BenefitsSection extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      typeInfo: {
        ...solutionTypes,
        ...dataExtractionTypes
      }
    }
  }

  render () {
    const { type } = this.props
    const { typeInfo } = this.state
    const titleName = (this.state.typeInfo[type]?.name) || ''
    const caseCorrectedTitle = (this.state.typeInfo[type]?.canLowerCase) ? titleName.toLowerCase() : titleName
    return (
      typeInfo[type]
        ? (
            <Section
              name='benefits'
              align='center'
              identifier={true}
            >
              {this.state.typeInfo[type].benefit
                ? <h2 className='title'>{this.state.typeInfo[type].benefit.title}</h2>
                : <h2 className='title'>The benefits of automated<br /> {caseCorrectedTitle} {this.state.typeInfo[type].subDocument ? '' : 'document '}processing</h2>}
              <ul className='list'>
                {
                  benefitsMap[type].map(benefitKey => {
                    return (
                      <li key={benefitKey}>
                        <img
                          className='green-overlay'
                          src={`/static/images/icons/${benefits[benefitKey].icon}_128.png`}
                          srcSet={`/static/images/icons/${benefits[benefitKey].icon}_128.png 1x, /static/images/icons/${benefits[benefitKey].icon}.png 2x`}
                        />
                        <p>{benefits[benefitKey].text}</p>
                      </li>
                    )
                  })
                }
              </ul>
            </Section>
          )
        : null
    )
  }
}

BenefitsSection.propTypes = {
  type: PropTypes.string,
  benefits: PropTypes.array
}

// Export
export default BenefitsSection
