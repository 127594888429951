// Modules
import PropTypes from 'prop-types'
import { Component } from 'react'
import { isMobile } from 'react-device-detect'
// Context
import Context from '~/context/global'
import Header from '~/layout/header/Header'
// Interface
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import window from 'global/window'
// View: Demo
class ScanWidgetDemo extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.state = {
      scannerType: this.props.scannerType || 'face',
      origin: ''
    }
  }

  componentDidMount () {
    if (isMobile) {
      window.location.replace(`/widgets/scan/12345/${this.state.scannerType}`)
    }
    this.setState({ origin: window.location.origin })
  }

  render () {
    return (
      <View name='scan-widget-demo'>
        <Header name='header'>
          <h1 className='slogan'>Embedded {this.state.scannerType === 'face' ? 'Facial Recognition' : 'Document Processing'} AI</h1>
          <h2 className='introduction'>Embed Base64.ai into your processes and products without any coding</h2>
          <h3 className='introduction'>
            Bring the power of Base64.ai {this.state.scannerType} processing into your business’s UX with custom branding and functionality.
            Redirect your user to your embedded Base64.ai {this.props.scannerType} capture website or embed in an iFrame into your product.
            Add your logo, choose your brand colors, and make it look like part of your own flow.
            <br />
            Your UX + Our AI = Customers saying WOW!
          </h3>
        </Header>
        <Section
          name='input'
          tight={true}
        >
          <div className='uploadBox'>
            <iframe
              src={`${this.state.origin}/widgets/scan/12345/${this.state.scannerType}`}
              allow={`camera ${this.state.origin} https://base64.ai`}
              width='800'
              height='800'
            />
          </div>
        </Section>
      </View>
    )
  }
}

ScanWidgetDemo.propTypes = {
  scannerType: PropTypes.string
}

// Export
export default ScanWidgetDemo
