import { Component } from 'react'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'

class FlowCreatableSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    formatCreateLabel: PropTypes.func,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    classNamePrefix: PropTypes.string,
    style: PropTypes.object,
    options: PropTypes.array,
    value: PropTypes.array
  }

  static defaultProps = {
    onChange: () => {},
    style: {},
    options: [
      { label: 'Name', value: 'Name' },
      { label: 'Given name', value: 'Given name' },
      { label: 'Family name', value: 'Family name' },
      { label: 'Full name', value: 'Full name' },
      { label: 'Date', value: 'Date' },
      { label: 'Date of birth', value: 'Date of birth' },
      { label: 'Expiration date', value: 'Expiration date' },
      { label: 'Issue date', value: 'Issue date' },
      { label: 'Address', value: 'Address' },
      { label: 'Vin', value: 'Vin' },
      { label: 'Total', value: 'Total' },
      { label: 'Tax', value: 'Tax' },
      { label: 'Company name', value: 'Company name' },
      { label: 'Bill to', value: 'Bill to' },
      { label: 'Ship to', value: 'Ship to' },
      { label: 'License number', value: 'License number' },
      { label: 'Document number', value: 'Document number' }
    ],
    formatCreateLabel: () => {},
    isDisabled: false,
    placeholder: '',
    className: 'filter',
    classNamePrefix: '',
    value: []
  }

  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value
    }

    this.configuration = {
      modelsData: []
    }
  }

  render () {
    return (
      <>
        <CreatableSelect
          isMulti
          className='react-select'
          classNamePrefix='react-select'
          style={this.props.style}
          value={this.state.value}
          placeholder={this.props.placeholder}
          closeMenuOnSelect={false}
          onChange={e => {
            const value = e.map(ff => {
              return { value: ff.value, label: ff.label }
            })
            this.setState({ value }, () => this.props.onChange(value))
          }}
          isDisabled={this.props.isDisabled}
          options={this.props.options}
          formatCreateLabel={this.props.formatCreateLabel}
        />
      </>
    )
  }
}

export default FlowCreatableSelect
