// Modules
import PropTypes from 'prop-types'
import {
  Component,
  createRef
} from 'react'

// View: OptionalImage
class OptionalImage extends Component {
  static propTypes = {
    imageUrl: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.image = createRef()
    this.present = this.present.bind(this)
  }

  present (success) {
    this.image.current.style.display = success ? 'block' : 'none'
  }

  render () {
    const { imageUrl } = this.props
    return (
      <>
        { imageUrl
        && (
          <img
            ref={this.image}
            style={{
              maxWidth: '100%',
              maxHeight: '500px',
              margin: '1rem auto 0rem'
            }}
            onLoad={() => this.present(true)}
            onError={() => this.present(false)}
            src={imageUrl}
          />
        )}
      </>
    )
  }
}

// Export
export default OptionalImage
