// Modules
import PropTypes from 'prop-types'

// Layout: Overlay
const Overlay = props => (
  <aside
    className={props.isHITL ? ' hitlPage overlay' : 'overlay'}
    onClick={event =>
      event.target.classList.contains('overlay')
      && !props.alwaysOn
      && props.onClick
      && props.onClick()}
  >
    {props.children}
  </aside>
)

Overlay.propTypes = {
  alwaysOn: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  isHITL: PropTypes.bool
}

// Export
export default Overlay
