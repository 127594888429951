import { createEmptyDom } from '../../helpers/dom'
import { isMarkdownTable, parseMarkdownTable } from '../../helpers/markdownTable'
import { toCamelCase } from '../../utilities/format'
import Checkbox from '../checkbox/Checkbox'
import TextareaAutosize from 'react-textarea-autosize'
import MaterialIcon from '../material-icon/MaterialIcon'
import Loading from '../loading/Loading'

const questionAddTargetArray = ['Result', 'Flow', 'Model']

export const displayQuestionAddingModal = (props, state, setState) => {
  const isCustomModel = state.result.model.type?.startsWith('custom/user/')
  const targetArray = isCustomModel ? questionAddTargetArray : questionAddTargetArray.slice(0, 2)
  const newFieldKey = state.newFieldKey
  const newFieldValue = state.newFieldValue
  const originalQuestion = state.originalQuestion
  const isMarkdown = isMarkdownTable(newFieldValue)
  const addingQuestionStatus = state.addingQuestionStatus || {}

  const addTableToResult = (fieldKey, fieldValue) => {
    const tables = []
    const features = state.result.features || {}
    if (!features.dom) {
      features.dom = createEmptyDom(
        state.result.features?.properties?.width || 1440,
        state.result.features?.properties?.height || 1440,
        state.result.features?.properties?.dpiX || 72,
        state.result.features?.properties?.dpiY || 72,
        state.result.features?.properties?.mimeType || 'image/png',
        state.result.features?.properties?.pageCount || 1
      )
    }
    features.dom.pages.forEach((page, pageIndex) => {
      if (!page.tables?.length) {
        return
      }
      page.tables.forEach((table, tableIndex) => {
        tables.push({ table, pageIndex, tableIndex })
      })
    })
    props.contextProvider.updateResult('dom', features.dom, { resultIndex: props.resultIndex, parent: 'features' })
    const pageIndex = props.context.activeIndex || 0
    const parsedTable = parseMarkdownTable(fieldValue)
    if (!parsedTable.length) {
      addingQuestionStatus['Table'] = { status: 'error', message: 'Invalid table.' }
      return
    }
    const table = {
      tableIndex: tables.length,
      pageIndex: pageIndex,
      table: {
        title: fieldKey,
        text: parsedTable.map(row => row.filter(cellText => cellText).join(' ')).join('\n'),
        rows: parsedTable.map(row => ({
          text: row.filter(cellText => cellText).join(' '),
          cells: row.map(cellText => ({
            text: cellText,
            confidence: 1
          }))
        }))
      }
    }
    addingQuestionStatus['Table'] = { status: 'success', message: 'Table added to result successfully.' }
    props.contextProvider.updateResult('tables', table, { parent: 'features', tableIndex: table.tableIndex, pageIndex, add: true })
  }

  const addQuestionToFlow = question => {
    const flow = props.context.flow
    const questions = flow.questions || []
    const questionIndex = questions.findIndex(q => q.key === question.key)
    if (questionIndex === -1) {
      questions.push(question)
      addingQuestionStatus.Flow = {}
      props.contextProvider.addQuestionsToFlow(questions, (err, _response) => {
        if (err) {
          addingQuestionStatus.Flow = { status: 'error', message: err.message }
          setState({ addingQuestionStatus })
          return
        }
        addingQuestionStatus.Flow = { status: 'success', message: 'Question added to flow successfully.' }
        setState({ addingQuestionStatus })
      })
    } else {
      addingQuestionStatus.Flow = { status: 'error', message: 'Question already exists' }
      setState({ addingQuestionStatus })
    }
  }

  props.contextProvider.displayModal({
    title: Object.keys(addingQuestionStatus).length ? 'Adding question' : 'Add answer',
    content: (
      Object.keys(addingQuestionStatus).length
        ? (
            <div className='add-new-field-status-container'>
              <div className='mb-2'>
                <p><b>Question:</b>&nbsp;{originalQuestion}</p>
                <p><b>Key:</b>&nbsp;{newFieldKey}</p>
              </div>
              {
                ['Result', 'Table', 'Flow', 'Custom model'].map(key => {
                  if (!Object.keys(addingQuestionStatus).includes(key)) {
                    return null
                  }
                  return (
                    <div key={'status' + key} className='add-new-field-status-item'>
                      <p><b>{key}:</b></p>&nbsp;
                      {
                        !addingQuestionStatus[key].status
                          ? <div><Loading /></div>
                          : (
                              <MaterialIcon
                                name={addingQuestionStatus[key].status === 'error' ? 'error' : 'task_alt'}
                                className={addingQuestionStatus[key].status}
                                title={addingQuestionStatus[key].message}
                              />
                            )
                      }
                    </div>
                  )
                })

              }
            </div>
          )
        : (
            <div className='add-new-field-input-container'>
              <label className='popup-label'>
                Enter question:
                <br />
                <input
                  className='hitl-input'
                  value={originalQuestion}
                  autoFocus={true}
                  onChange={e => {
                    const target = e.target.value
                    setState({ originalQuestion: target })
                  }}
                />
              </label>
              <label className='popup-label'>
                Enter name:
                <br />
                <input
                  className='hitl-input'
                  value={newFieldKey}
                  autoFocus={true}
                  onChange={e => {
                    const target = e.target.value
                    setState({ newFieldKey: target })
                  }}
                />
              </label>
              <label className='popup-label'>
                Enter value:
                <br />
                <TextareaAutosize
                  maxRows={10}
                  className='hitl-input value-box'
                  value={newFieldValue}
                  onChange={e => {
                    const target = e.target.value
                    setState({ newFieldValue: target })
                  }}
                />
              </label>
              <label className='popup-label'>
                Select destinations:
                <br />
                <div className='target-selection-container'>
                  {targetArray.map((target, index) =>
                    (!state.hideOtherTargets || (state.selectedTargets || []).includes(target)) && (
                      <Checkbox
                        key={index}
                        name={target}
                        placeholder={target}
                        checked={(state.selectedTargets || []).includes(target)}
                        onChange={e => {
                          const selectedTargets = state.selectedTargets || []
                          if (e.target.checked && !selectedTargets.includes(target)) {
                            selectedTargets.push(target)
                          }
                          if (!e.target.checked && selectedTargets.includes(target)) {
                            const indexOf = selectedTargets.indexOf(target)
                            selectedTargets[indexOf] = null
                          }
                          setState({ selectedTargets: selectedTargets.filter(s => s) })
                        }}
                      />
                    )
                  )}
                </div>
              </label>

            </div>
          )
    ),
    options: Object.keys(addingQuestionStatus).length
      ? [{
          content: 'OK',
          intent: 'negative'
        }]
      : [
          {
            content: 'Cancel',
            intent: 'negative'
          },
          {
            content: 'Add',
            intent: 'positive',
            action: () => {
              const validTargets = state.selectedTargets.filter(target => targetArray.includes(target))
              if (!validTargets.length) {
                return alert('At least one valid target must be selected')
              }
              if (validTargets.includes('Result')) {
                if (!newFieldKey) {
                  return alert('Key must be valid')
                }
                if (isMarkdown) {
                  addTableToResult(newFieldKey, newFieldValue)
                }
                addingQuestionStatus.Result = { status: 'success', message: 'Field added to result successfully.' }
                props.contextProvider.updateResult(toCamelCase(newFieldKey), {
                  key: newFieldKey,
                  value: newFieldValue,
                  confidence: 1,
                  type: 'custom',
                  isValid: true
                }, { resultIndex: props.resultIndex }, () => {
                  const searchField = `result${props.resultIndex}${toCamelCase(newFieldKey)}`
                  const resultViewerContainerDom = document.querySelector('.result-viewer-container')
                  if (!resultViewerContainerDom) {
                    return
                  }
                  const newFieldsDom = document.getElementById(searchField)
                  if (!newFieldsDom) {
                    return
                  }
                  const newFieldRelativeTopPosition = Math.abs(resultViewerContainerDom.offsetTop - newFieldsDom.offsetTop)
                  resultViewerContainerDom.scrollTo(0, newFieldRelativeTopPosition)
                  const scrollPosition = newFieldsDom.offsetTop - resultViewerContainerDom.scrollTop
                  window.scrollTo({
                    left: 0,
                    top: scrollPosition - 100,
                    behavior: 'smooth'
                  })
                })
              }
              const flowUUID = state.questionAnswerResponseWillAdd?.flowUUID
              if (validTargets.includes('Flow')) {
                const questionWillAdd = {
                  question: state.originalQuestion,
                  key: newFieldKey
                }
                if (flowUUID) {
                  questionWillAdd.flowUUID = flowUUID
                }
                addQuestionToFlow(questionWillAdd)
              }
              if (validTargets.includes('Model')) {
                addingQuestionStatus['Custom model'] = { status: '' }
                const questionWillAdd = {
                  question: state.originalQuestion,
                  key: newFieldKey
                }
                if (flowUUID) {
                  questionWillAdd.flowUUID = flowUUID
                }
                props.contextProvider.getAndAddQuestionsToCustomModel([
                  questionWillAdd
                ],
                state.result.model.type?.split('/')?.pop(),
                (err, _response) => {
                  if (err) {
                    addingQuestionStatus['Custom model'] = { status: 'error', message: err.message }
                    setState({ addingQuestionStatus })
                    return
                  }
                  addingQuestionStatus['Custom model'] = { status: 'success', message: 'Question added to custom model successfully.' }
                })
              }
              const activeTargets = Object.keys(addingQuestionStatus)
              if (activeTargets.length === 1 && activeTargets[0] === 'Result') {
                return
              }
              setState({ addingQuestionStatus })
            }
          }
        ],
    isClosable: true
  })
}
