import { Component } from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from '../material-icon/MaterialIcon'
import RemoveButton from '../remove-button/RemoveButton'
import EditButton from '../edit-button/EditButton'

class DataExtractionBox extends Component {
  static propTypes = {
    inputType: PropTypes.string,
    confidence: PropTypes.number,
    context: PropTypes.object,
    contextProvider: PropTypes.object,
    children: PropTypes.children,
    isValid: PropTypes.bool,
    isRemovable: PropTypes.bool,
    isEditable: PropTypes.bool,
    location: PropTypes.object,
    modeHandler: PropTypes.func,
    removeHandler: PropTypes.func,
    selectedDataReference: PropTypes.string,
    title: PropTypes.string,
    domReferenceId: PropTypes.string,
    desiredEditMode: PropTypes.string,
    isOverflowDisabled: PropTypes.bool,
    zIndex: PropTypes.number
  }

  static defaultProps = {
    context: {},
    isRemovable: true,
    modeHandler: () => {},
    removeHandler: () => {},
    desiredEditMode: 'dom'
  }

  constructor (props) {
    super(props)
    this.state = {
      isSelected: false,
      selectionDomId: this.props.selectedDataReference,
      mode: 'view',
      doesPopupActive: false
    }
    this.selectionHandler = this.selectionHandler.bind(this)
    this.editButtonClickHandler = this.editButtonClickHandler.bind(this)
    this.isEditableBox = this.isEditableBox.bind(this)
    this.blurHandler = this.blurHandler.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.context.selectedDataReference !== this.props.context.selectedDataReference) {
      this.setState({ mode: this.props.context.selectedDataReference === this.props.selectedDataReference ? 'edit' : 'view' })
    }
    if (prevProps.context.doesPopupActive !== this.props.context.doesPopupActive) {
      this.setState({ doesPopupActive: this.props.context.doesPopupActive })
    }
  }

  componentWillUnmount () {
    if (!this.props.contextProvider) {
      return
    }
    this.props.contextProvider.setState({
      selectionDomId: null,
      selectionLocation: null,
      isSelected: false
    })
  }

  selectionHandler (force) {
    if ((!this.props.contextProvider || !this.props.location || this.state.mode === 'edit') && !force) {
      return
    }
    this.props.contextProvider.setState({
      selectionContext: 'field',
      selectionDomId: this.state.isSelected ? this.state.selectionDomId : null,
      selectionLocation: this.state.isSelected ? this.props.location : null,
      selectionRelativeIndex: this.state.isSelected ? this.props.location.pageNumber - this.props.context.startPage : null,
      isSelected: this.state.isSelected
    })
  }

  editButtonClickHandler () {
    if (this.state.mode !== 'edit') {
      this.setState({ mode: 'edit' }, () => {
        this.props.modeHandler('edit')
        this.props.contextProvider.setState({ mode: 'edit', annotationType: this.props.desiredEditMode, selectedDataReference: this.props.selectedDataReference })
      })
    }
    if (this.state.mode === 'edit') {
      this.setState({ mode: 'view', isSelected: !!this.props.location }, () => {
        this.props.modeHandler('view')
        this.props.contextProvider.setState({
          mode: 'view',
          annotationType: 'result',
          selectedDataReference: null
        })
        this.selectionHandler(!this.props.location)
      })
    }
  }

  getConfidenceLevel (confidence) {
    if (confidence > 0.8) {
      return 'green'
    } else if (confidence > 0.5) {
      return 'orange'
    } else {
      return 'red'
    }
  }

  blurHandler () {
    this.setState({ isSelected: false }, () => {
      if (!this.isEditableBox()) {
        this.selectionHandler()
      }
    })
  }

  isEditableBox () {
    return this.state.mode === 'edit' && this.props.context.selectedDataReference === this.props.selectedDataReference
  }

  convertConfidenceToPercentage (confidence) {
    return (100 * confidence).toFixed(0).toString() + '%'
  }

  render () {
    const resultBoxBackgroundStyle = !this.state.doesPopupActive
      ? {
          zIndex: this.props.title === 'Model' || this.props.inputType === 'date'
            ? this.props.zIndex || 10
            : this.props.zIndex - 1 || 9
        }
      : {}

    return (
      <div
        className='result-box-background'
        id={this.props.domReferenceId}
        style={resultBoxBackgroundStyle}
      >
        <div
          id={this.state.selectionDomId}
          className={`result-box-container ${this.isEditableBox() ? 'editable-container' : ''}`}
          tabIndex='0'
          onBlur={() => {
            this.setState({ isSelected: false }, () => {
              if (!this.isEditableBox()) {
                this.selectionHandler()
              }
            })
          }}
          onFocus={() => {
            this.setState({ isSelected: true }, () => setTimeout(() => {
              this.selectionHandler()
            }, 0))
          }}
        >
          <div className='left item'>
            <div className='grid-padding'></div>
            <div className='is-valid'>
              {
                this.props.isValid === true
                  ? <MaterialIcon name='check_circle' className='valid' />
                  : this.props.isValid === false
                    ? <MaterialIcon name='warning' className='invalid' />
                    : <></>
              }
            </div>
          </div>
          <div className='mid item'>
            <div className='result-box-title'>
              {this.props.title}
            </div>
            <div className={'value ' + (this.props.isOverflowDisabled ? 'overflow-disabled' : '')}>
              {this.props.children}
            </div>
          </div>
          {!this.props.context.isEditDisabled && (
            <div className='right item hover-icons'>
              { this.props.isRemovable && <RemoveButton onRemove={this.props.removeHandler} />}
              { this.props.isEditable && <EditButton onEdit={this.editButtonClickHandler} title={this.isEditableBox() ? 'Finish editing' : 'Edit field'} />}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default DataExtractionBox
