import { request } from '~/helpers/request'
import document from 'global/document'
import { triggerTrackingEvent } from '../utilities/tracker'
import { isWorkingOffline } from '../context/environment'
const googleLoginHandler = () => {
  if (!window?.google) {
    console.log('Login with Google plugin was not loaded')
    return
  }
  window.google.accounts.id.initialize({
    client_id: '802300288339-cnd50ps64pk0kg4vceiirg5pmsd9l4di.apps.googleusercontent.com',
    hd: '*',
    ux_mode: 'popup',
    nonce: '',
    context: 'use',
    auto_prompt: false,
    callback: res => {
      request({ method: 'POST', endpoint: '/auth/google', body: res }, (error, _result) => {
        if (error) {
          alert('Google authentication failed: ' + error.message)
        }
        triggerTrackingEvent('login-completed-google')
        window.location.href = '/flow'
      }, error => alert('Google authentication failed: ' + error.message))
    }
  })
  window.google.accounts.id.prompt()
  const dom = document.querySelector('#google-button')
  if (dom) {
    window.google.accounts.id.renderButton(dom, {
      type: 'icon',
      shape: 'square',
      theme: 'filled_blue',
      text: 'continue_with',
      size: 'large'
    })
  }
}
export const googleOneTabAuthentication = () => {
  if (isWorkingOffline()) {
    return
  }
  let isInsideIframe = false
  try {
    isInsideIframe = window.self !== window.top
  } catch (e) {
    isInsideIframe = true
  }
  if (isInsideIframe || window.location.href.match(/\/widgets\/document-processing/)) {
    return
  }
  if (window.google) {
    googleLoginHandler()
  } else {
    window.onGoogleLibraryLoad = () => {
      googleLoginHandler()
    }
  }
}

export default {
  googleOneTabAuthentication
}
