import { Component } from 'react'
import PropTypes from 'prop-types'
import Context from '~/context/global'
import Section from '../../../layout/section/Section'
import Link from '../../../interface/link/Link'
import SortableTable from 'react-sortable-table'
import { toIsoDate } from '../../../utilities/format'
import Select from 'react-select'
import Checkbox from '../../../interface/checkbox/Checkbox'
import { request } from '../../../helpers/request'
import window from 'global/window'
import LoadingModal from '../../../interface/loading-modal/LoadingModal'

class FlowInput extends Component {
  static propTypes = {
    modelUUID: PropTypes.string,
    flows: PropTypes.array,
    onChange: PropTypes.func
  }

  static defaultProps = {
    flows: []
  }

  constructor (props) {
    super(props)
    this.state = {}
  }

  onCheckBoxChange (data) {
    this.setState({
      [data.value]: data.checked
    }, () => this.props.onChange(this.state))
  }

  render () {
    return (
      <div className='flow-input' data-view='flow' style={{ background: 'none' }}>
        <Section style={{ width: '35vw', padding: 'initial' }}>
          <h2 className='title'>Select a Flow</h2>
          <p className='header-paragraph'>
            From here you can connect the custom model you created to a flow you choose.
          </p>
          <Select
            isMulti={false}
            classNamePrefix='react-select'
            className='react-select'
            value={this.state.selectedFlow}
            options={this.props.flows}
            closeMenuOnSelect={true}
            placeholder='Select a flow'
            noOptionsMessage={() => 'No flow found'}
            menuPlacement='auto'
            onChange={flow => {
              this.setState({
                selectedFlowID: flow.value
              }, () => {
                this.props.onChange(this.state)
              })
            }}
          />
          <br />
          <Checkbox
            showTitle={true}
            key={1}
            className='big left'
            placeholder='Add restrict document types'
            value='addRestrictDocumentType'
            onChange={e => {
              this.onCheckBoxChange(e.target)
            }}
            title='If you check here, you will also add the restrict section in Flow.'
            checked={this.state.addRestrictDocumentType}
          />
          <br />
          <Checkbox
            showTitle={true}
            key={1}
            className='big left'
            placeholder='Add force document type classification'
            value='addForceDocumentTypeClassification'
            onChange={e => {
              this.onCheckBoxChange(e.target)
            }}
            title='If you check here, you will also add the force document section in Flow.'
            checked={this.state.addForceDocumentTypeClassification}
          />
          <br />
        </Section>
      </div>
    )
  }
}

class CustomModelFlowsSection extends Component {
  static contextType = Context

  static propTypes = {
    modelUUID: PropTypes.string,
    modelName: PropTypes.string,
    activeTab: PropTypes.string,
    accordionSectionOnClickHandler: PropTypes.func,
    isMobile: PropTypes.bool,
    isEdit: PropTypes.bool
  }

  static defaultProps = {
    flows: []
  }

  constructor (props) {
    super(props)
    this.state = {
      isLoaded: false,
      isPopupOpen: false,
      assignedFlows: [],
      authorizedFlows: [],
      messageObject: this.props.modelUUID
        ? {
            title: 'Fetching assigned flows',
            description: 'Please wait, this may take a few seconds.',
            status: 'loading',
            code: null
          }
        : LoadingModal.getEmptyMessageObject()
    }
    this.assignCustomModelToFlow = this.assignCustomModelToFlow.bind(this)
    this.removeCustomModelFromFlow = this.removeCustomModelFromFlow.bind(this)
  }

  componentDidMount () {
    if (!this.props.isEdit) {
      return
    }
    request({
      endpoint: '/flow/assigned/' + this.props.modelUUID,
      method: 'GET'
    }, (error, assignedFlowResponse) => {
      if (assignedFlowResponse.message) {
        return this.setState(LoadingModal.getStandardErrorMessageObject(assignedFlowResponse))
      }
      if (error) {
        return this.setState(LoadingModal.getStandardErrorMessageObject(error))
      }
      request({
        endpoint: '/flow/?accessLevel=owner+administrator&source=name,flowID' + '&modelUUID=' + this.props.modelUUID,
        method: 'GET'
      }, (error, authorizedFlowResponse) => {
        if (authorizedFlowResponse.message) {
          return this.setState(LoadingModal.getStandardErrorMessageObject(authorizedFlowResponse))
        }
        if (error) {
          return this.setState(LoadingModal.getStandardErrorMessageObject(error))
        }
        this.setState({
          isLoaded: true,
          assignedFlows: assignedFlowResponse,
          authorizedFlows: authorizedFlowResponse.map(s => {
            if (assignedFlowResponse.find(flow => flow.flowID === s.flowID)) {
              return null
            }
            return { label: s.name, value: s.flowID }
          }).filter(s => s).sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1)
        })
      }, err => {
        this.setState(LoadingModal.getStandardErrorMessageObject(err))
      })
    }, err => {
      this.setState(LoadingModal.getStandardErrorMessageObject(err))
    })
  }

  assignCustomModelToFlow () {
    if (!this.state.selectedFlowID) {
      return alert('Flow must be selected')
    }
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Updating flow',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      }
    })
    request({
      endpoint: '/flow/' + this.state.selectedFlowID,
      method: 'POST',
      body: {
        customModelOperation: 'assign',
        name: this.props.modelName,
        modelUUID: this.props.modelUUID,
        addRestrictDocumentType: !!this.state.addRestrictDocumentType,
        addForceDocumentTypeClassification: !!this.state.addForceDocumentTypeClassification
      }
    }, (error, response) => {
      if (response.message) {
        return this.setState(LoadingModal.getStandardErrorMessageObject(response))
      }
      if (error) {
        return this.setState(LoadingModal.getStandardErrorMessageObject(error))
      }
      this.setState({ isLoaded: true })
      return window.location.reload()
    }, err => {
      this.setState(LoadingModal.getStandardErrorMessageObject(err))
    })
  }

  removeCustomModelFromFlow (flowID) {
    this.setState({
      isLoaded: false,
      messageObject: {
        title: 'Updating flow',
        description: 'Please wait, this may take a few seconds.',
        status: 'loading',
        code: null
      }
    })
    request({
      endpoint: '/flow/' + flowID,
      method: 'POST',
      body: {
        customModelOperation: 'remove',
        name: this.props.modelName,
        modelUUID: this.props.modelUUID
      }
    }, (error, response) => {
      if (response.message) {
        return this.setState(LoadingModal.getStandardErrorMessageObject(response))
      }
      if (error) {
        return this.setState(LoadingModal.getStandardErrorMessageObject(error))
      }

      this.setState({ isLoaded: true })
      return window.location.reload()
    }, err => {
      this.setState(LoadingModal.getStandardErrorMessageObject(err))
    })
  }

  HEADER_STYLE = {
    padding: '0.5rem',
    whiteSpace: 'nowrap',
    margin: '0.1rem',
    backgroundColor: 'white',
    cursor: 'pointer',
    fontWeight: 'bold',
    width: '30%',
    color: '#333366',
    fontSize: '0.9rem'
  }

  DATA_STYLE = {
    verticalAlign: 'middle',
    padding: '0.5rem',
    color: '#000000',
    borderTop: '1px solid #eeeeee'
  }

  LONG_DATA_STYLE = {
    ...this.DATA_STYLE,
    whiteSpace: 'nowrap'
  }

  defaultSort = (a, b, key) => {
    return a[key] >= b[key] ? 1 : -1
  }

  sortAsc = (data, key) => {
    var result = data.slice(1).sort((a, b) => {
      return this.defaultSort(a, b, key)
    })
    return [data[0], ...result]
  }

  sortDesc = (data, key) => {
    var result = data.slice(1).sort((a, b) => {
      return this.defaultSort(b, a, key)
    })
    return [data[0], ...result]
  }

  CONFIGURATION = {
    flows: [
      {
        header: 'Name',
        key: 'name',
        renderWithData: data => {
          if (!data.role.length) {
            return <span>{data.name}</span>
          }
          const title = data.isDefault ? 'We prepared this Flow for you. You can start with the sample files inside.' : ''
          return (
            <a
              title={title}
              onClick={() => {
                this.context.redirect('/flow/results/' + data.flowID)
              }}
            >
              {data.name} {data.status === 'disabled' ? '(Frozen)' : ''}
            </a>
          )
        },
        headerStyle: { ...this.HEADER_STYLE, width: '35%' },
        dataStyle: { ...this.DATA_STYLE, fontWeight: '400', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '1px' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      }, {
        header: 'Ownership',
        key: 'role',
        render: data => {
          return <span>{data[0] || '-'}</span>
        },
        headerStyle: { ...this.HEADER_STYLE, width: '13%', textAlign: 'center' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      },
      {
        header: 'Needs review',
        key: 'numberOfNeedsReview',
        render: data => {
          return <span title='Number of documents to be reviewed'>{data}</span>
        },
        headerStyle: { ...this.HEADER_STYLE, width: '13%', textAlign: 'center' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      },
      {
        header: 'Approved',
        key: 'numberOfApproved',
        render: data => {
          return <span title='Number of approved documents'>{data}</span>
        },
        headerStyle: { ...this.HEADER_STYLE, width: '12%', textAlign: 'center' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' },
        dataProps: { className: 'mobile-fobic' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      },
      {
        header: 'Rejected',
        key: 'numberOfRejected',
        render: data => {
          return <span title='Number of rejected documents'>{data}</span>
        },
        headerStyle: { ...this.HEADER_STYLE, width: '12%', textAlign: 'center' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' },
        dataProps: { className: 'mobile-fobic' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      },
      {
        header: 'Total',
        key: 'numberOfUploads',
        render: data => {
          return <span title='Total number of documents'>{data}</span>
        },
        headerStyle: { ...this.HEADER_STYLE, width: '9%', textAlign: 'center' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      },
      {
        header: 'Last uploaded',
        key: 'lastUploadedAt',
        headerStyle: { ...this.HEADER_STYLE, width: '19%', textAlign: 'center' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' },
        renderWithData: data => {
          return <span title='Last update date and time'>{toIsoDate(data.lastUploadedAt) || 'Never'}</span>
        },
        descSortFunction: this.sortDesc,
        ascSortFunction: this.sortAsc
      },
      {
        header: '',
        key: 'removeButton',
        headerStyle: { ...this.HEADER_STYLE, width: '2%', textAlign: 'center', color: 'white' },
        dataStyle: { ...this.DATA_STYLE, textAlign: 'center' },
        renderWithData: data => {
          if (!data.role.length) {
            return <span></span>
          }
          return (
            <img
              className='remove-button'
              src='/static/images/icons/hitl-close.svg'
              title='Remove your custom model from this Flow'
              onClick={() => {
                this.context.displayModal({
                  title: 'Confirmation',
                  content: <p>Do you want to remove custom model <b>{this.props.modelName}</b> from <b>{data.name}</b></p>,
                  options: [
                    {
                      content: 'Cancel',
                      intent: 'negative'
                    },
                    {
                      content: 'OK',
                      action: () => {
                        this.removeCustomModelFromFlow(data.flowID)
                      }
                    }
                  ]
                })
              }}
            />
          )
        }
      }
    ]
  }

  render () {
    const { activeTab, modelUUID, accordionSectionOnClickHandler, isMobile } = this.props
    return (
      <>
        {modelUUID && (
          <Section
            name='flows'
            className='settings-card'
            if={isMobile || activeTab === 'flows'}
            accordion={isMobile}
            isOpen={activeTab === 'flows'}
            title='Flows'
            onClick={() => {
              accordionSectionOnClickHandler('flows')
            }}
          >
            {!this.state.isLoaded
              ? (
                  <LoadingModal
                    data={this.state.messageObject}
                  />
                )
              : (
                  <>
                    {!isMobile && (
                      <span className='title-container'>
                        <h1 className='title'>Flows</h1>
                      </span>
                    )}
                    <p className='header-paragraph'>
                      Select the Flows in which you want to use your custom model.<br />
                      You must be a Flow owner or administrator to make a change.
                    </p>
                    <h2 className='title'>Assigned Flows</h2>
                    <p className='header-paragraph'>
                      {this.state.assignedFlows?.length ? 'Your custom model was added to the following Flows:' : 'This custom model was not added to any Flows.'}
                    </p>
                    {this.state.authorizedFlows?.length
                      ? (
                          <Link onClick={() => {
                            this.context.displayModal({
                              wide: true,
                              title: '',
                              content: (
                                <FlowInput
                                  flows={this.state.authorizedFlows}
                                  onChange={data => {
                                    this.setState(data)
                                  }}
                                  modelUUID={modelUUID}
                                />
                              ),
                              options: [
                                {
                                  content: 'Cancel',
                                  intent: 'negative'
                                },
                                {
                                  content: 'OK',
                                  action: () => {
                                    this.assignCustomModelToFlow()
                                  }
                                }
                              ]
                            })
                          }}
                          >Click here for assign to flow
                          </Link>
                        )
                      : null}
                    <br />
                    <br />
                    {
                      this.state.assignedFlows?.length
                        ? (
                            <SortableTable
                              data={this.state.assignedFlows}
                              columns={this.CONFIGURATION.flows}
                              style={{
                                borderCollapse: 'separate',
                                borderSpacing: '1px',
                                width: '100%'
                              }}
                              iconStyle={{ paddingLeft: '0.2rem' }}
                            />
                          )
                        : null
                    }
                  </>
                )}

          </Section>
        )}
      </>
    )
  }
}

export default CustomModelFlowsSection
