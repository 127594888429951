import { Component } from 'react'

import {
  Page, Text, View, PDFDownloadLink, Document, StyleSheet, Link, Image, Font
} from '@react-pdf/renderer'

import PropTypes from 'prop-types'
import window from 'global/window'

const numberWithCommas = x => {
  return x.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

const getDate = () => {
  return new Date().toLocaleDateString('en-US', { day: '2-digit', year: '2-digit', month: 'long' })
}
// Create PDF Document Component
const PDFCreator = ({ data }) => {
  Font.register({
    family: 'Roboto-Regular',
    src:
      `${window.location.origin}/static/fonts/Roboto-Regular.ttf`
  })
  Font.register({
    family: 'Roboto-Bold',
    src:
      `${window.location.origin}/static/fonts/Roboto-Bold.ttf`
  })

  // Create pdf styles
  const styles = StyleSheet.create({
    page: {
      font: 'Roboto-Regular',
      flexDirection: 'column',
      backgroundColor: '#f6fbff',
      padding: '40px',
      position: 'relative'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      color: 'black'
    },
    date: {
      position: 'absolute',
      fontSize: 15,
      fontWeight: 600,
      top: 30,
      right: 40,
      alignSelf: 'stretch'
    },
    image: {
      height: 'auto',
      margin: '0 auto',
      width: '32%',
      marginBottom: 10
    },
    logo: {
      height: 'auto',
      margin: '0 auto',
      width: '7.5%'
    },
    title: {
      fontSize: 26,
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 14,
      color: '#0088f1'
    },
    resultItem: {
      width: '100%',
      fontSize: 14,
      color: '#3c3c3c',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 12,
      borderBottom: '1px solid #c7c7c7'
    },
    value: {
      color: '#0088f1'
    },
    text: {
      fontSize: 13,
      color: '#3c3c3c',
      margin: '8px 0',
      lineHeight: 1.4
    }
  })

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.title}>
          <Image style={styles.image} src='/static/media-kit/base64.ai-logo-transparent.png'></Image>
          <Text>ROI Calculation Results</Text>
        </View>
        <View>
          <Text style={{ ...styles.text, margin: '0px' }}>
            <Link src='https://base64.ai' style={{ color: '#00dddd' }}>Base64.ai</Link> is an enterprise-grade, ready-to-use, and white-label Intelligent Document
          </Text>
          <Text style={{ ...styles.text, margin: '0px' }}>
            Processing solution for all automation platforms and projects.
            This document is produced by the ROI (Return on Investment) calculator of Base64.ai
            at <Link src='https://base64.ai/roi-calculator' style={{ color: '#00dddd' }}>
              https://base64.ai/roi-calculator
            </Link>. Use our calculator to estimate how Base64.ai could improve your automated document processing. Here, you can find your selections and results.
          </Text>
        </View>
        <Text style={{ fontSize: '18px', marginTop: '12px' }}>Your Selections</Text>
        <View style={styles.resultItem}>
          <Text>
            Monthly average number of processed document
          </Text>
          <Text style={styles.value}>
            {numberWithCommas(data.documentSize)}
          </Text>
        </View>

        <View style={styles.resultItem}>
          <Text>
            Average number of pages per document
          </Text>
          <Text style={styles.value}>
            {numberWithCommas(data.pagesPerDocument)}
          </Text>
        </View>

        <View style={styles.resultItem}>
          <Text>
            Number of employees processing documents
          </Text>
          <Text style={styles.value}>
            {numberWithCommas(data.employeeSize)}
          </Text>
        </View>

        <View style={styles.resultItem}>
          <Text>
            Average annual cost per employee
          </Text>
          <Text style={styles.value}>
            ${numberWithCommas(data.costPerFTE)}
          </Text>
        </View>

        <View style={{ ...styles.resultItem, color: 'black', fontSize: '20px', marginTop: '36px', marginBottom: '16px' }}>
          <Text>
            Annual Savings
          </Text>
          <Text style={styles.value}>
            ${data.annualSaving} ({data.annualSavingPercentage}%)
          </Text>
        </View>
        <View style={styles.resultItem}>
          <Text>
            Estimated annual cost of current manual process
          </Text>
          <Text style={styles.value}>
            ${data.currentSpending}
          </Text>
        </View>
        <View style={styles.resultItem}>
          <Text>
            Estimated annual cost with <Link src='https://base64.ai' style={{ color: '#00dddd' }}>
              Base64.ai
            </Link>
          </Text>
          <Text style={styles.value}>
            ${data.costWithBase64}
          </Text>
        </View>
        <View style={styles.resultItem}>
          <Text>
            Number of pages processed monthly
          </Text>
          <Text style={styles.value}>
            {data.pagesProcessedMonthly}
          </Text>
        </View>
        <View style={styles.resultItem}>
          <Text>
            Number of documents per employee daily
          </Text>
          <Text style={styles.value}>
            {data.documentsPerEmployee}
          </Text>
        </View>
        <View style={styles.resultItem}>
          <Text>
            % employee time spent on document processing
          </Text>
          <Text style={{ color: data.giveTimeWarning ? 'red' : styles.value.color }}>
            {data.employeeTimeSpent}%
          </Text>
        </View>
        <View style={styles.resultItem}>
          <Text>
            Annual hours saved
          </Text>
          <Text style={styles.value}>
            {data.annualHoursSaved}
          </Text>
        </View>
        <View style={styles.resultItem}>
          <Text>
            Daily hours saved
          </Text>
          <Text style={styles.value}>
            {data.hoursSaved}
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, fontSize: 10, color: '#888888' }}>
            Disclaimer: The purpose of this calculator is to give you an estimated
            cost using 25 cents per page. Your actual cost may vary and may depend
            on the type of document(s). This document is created on {getDate()}.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, marginTop: 16 }}>
            To learn how Base64.ai is helping businesses scale their document processing with a highly accurate and secure solution, feel free to visit <Link src='https://base64.ai/contact' style={{ color: '#00dddd' }}>https://base64.ai/contact
            </Link>
          </Text>
        </View>
        <View style={
          {
            position: 'absolute',
            bottom: '-1.5%',
            left: '50%',
            transform: 'translateX(-45%)'
          }
        }
        >
          <Image style={styles.logo} src='/static/images/iconx1024.png'></Image>
          <Link style={{ fontSize: 10, margin: '4 auto', color: '#0088f1' }} src='https://base64.ai'>
            &copy; {new Date().getFullYear()} Base64.ai
          </Link>
          <View style={{ color: '#888888', fontSize: 10 }}>
            <Text>
              Real-time Document Understanding AI
            </Text>
            <Text>
              for cloud and on-premises datacenters
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

class ROIPDFExport extends Component {
  render () {
    return (
      <div>
        <PDFDownloadLink
          document={<PDFCreator data={this.props.data} />}
          fileName='base64roiCalculatorResult.pdf'
          className='button'
          style={{ marginTop: '3rem', visibility: this.props.visibility ? 'visible' : 'hidden' }}
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Download Pdf'}
        </PDFDownloadLink>
      </div>
    )
  }
}

ROIPDFExport.propTypes = {
  data: PropTypes.object,
  visibility: PropTypes.bool
}

PDFCreator.propTypes = {
  data: PropTypes.object
}

export default ROIPDFExport
