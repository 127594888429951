// Modules
import { Component } from 'react'
import PropTypes from 'prop-types'

class DropdownOption extends Component {
  static propTypes = {
    text: PropTypes.string,
    iconName: PropTypes.string,
    iconType: PropTypes.string
  }

  static defaultProps = {
    text: '',
    iconName: '',
    iconType: 'png'
  }

  render () {
    const { text, iconName, iconType } = this.props
    return (
      <div className='dropdown-option'>
        <div className='icon-container'>
          <img src={`/static/images/icons/${iconName}.${iconType}`} alt={text} />
        </div>
        <span className='text'>{text}</span>
      </div>
    )
  }
}

// Export
export default DropdownOption
