// Layout
import View from '~/layout/view/View'
import Section from '~/layout/section/Section'
import Block from '~/layout/block/Block'
import Header from '~/layout/header/Header'

// Interface
import Link from '~/interface/link/Link'

// View: Privacy Policy
const PrivacyPolicy = () => (
  <View name='terms-of-service'>
    {/* Title */}
    <Header tight={true}>
      <h1>Privacy Policy</h1>
    </Header>
    {/* Agreement */}
    <Section
      name='agreement'
      align='left'
    >
      <Block
        name='welcome'
        identifier={true}
      >
        <p>Last updated: May 2, 2023</p>
        <h3>Your privacy is important to us.</h3>
        <p>It is Base64.ai, Inc‘s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <Link href='https://base64.ai'>Base64.ai</Link> (hereinafter, “us”, “we”, or “https://base64.ai”). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy (“Privacy Policy”) to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
        <p>This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>
      </Block>
      <Block
        name='visitors'
        identifier={true}
      >
        <h3>Website Visitors</h3>
        <p>Like most website operators, Base64.ai, Inc collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Base64.ai, Inc‘s purpose in collecting non-personally identifying information is to better understand how Base64.ai, Inc‘s visitors use its website. From time to time, Base64.ai, Inc may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
        <p>Base64.ai, Inc also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://base64.ai blog posts. Base64.ai, Inc only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>
      </Block>
      <Block
        name='gathering-information'
        identifier={true}
      >
        <h3>Gathering of Personally-Identifying Information</h3>
        <p>Certain visitors to Base64.ai, Inc‘s websites choose to interact with Base64.ai, Inc in ways that require Base64.ai, Inc to gather personally-identifying information. The amount and type of information that Base64.ai, Inc gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at https://base64.ai to provide a username and email address.</p>
      </Block>
      <Block
        name='security'
        identifier={true}
      >
        <h3>Security</h3>
        <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
      </Block>
      <Block
        name='external-links'
        identifier={true}
      >
        <h3>Links To External Sites</h3>
        <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party‘s site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.</p>
        <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>
        <p>If you use Google OAuth, Base64.ai&apos;s use and transfer of information received from Google APIs to any other app will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank' rel='noopener noreferrer'>Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
      </Block>
      <Block
        name='protection-of-information'
        identifier={true}
      >
        <h3>Protection of Certain Personally-Identifying Information</h3>
        <p>Base64.ai, Inc discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Base64.ai, Inc‘s behalf or to provide services available at Base64.ai, Inc‘s website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Base64.ai, Inc‘s website, you consent to the transfer of such information to them. Base64.ai, Inc will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Base64.ai, Inc discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Base64.ai, Inc believes in good faith that disclosure is reasonably necessary to protect the property or rights of Base64.ai, Inc, third parties or the public at large.</p>
        <p>If you are a registered user of https://base64.ai and have supplied your email address, Base64.ai, Inc may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what‘s going on with Base64.ai, Inc and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Base64.ai, Inc takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.</p>
      </Block>
      <Block
        name='aggregated-statistics'
        identifier={true}
      >
        <h3>Aggregated Statistics</h3>
        <p>Base64.ai, Inc may collect statistics about the behavior of visitors to its website. Base64.ai, Inc may display this information publicly or provide it to others. However, Base64.ai, Inc does not disclose your personally-identifying information.</p>
      </Block>
      <Block
        name='cookies'
        identifier={true}
      >
        <h3>Cookies</h3>
        <p>To enrich and perfect your online experience, Base64.ai, Inc uses “Cookies”, similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.</p>
        <p>A cookie is a string of information that a website stores on a visitor‘s computer, and that the visitor‘s browser provides to the website each time the visitor returns. Base64.ai, Inc uses cookies to help Base64.ai, Inc identify and track visitors, their usage of https://base64.ai, and their website access preferences. Base64.ai, Inc visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Base64.ai, Inc‘s websites, with the drawback that certain features of Base64.ai, Inc‘s websites may not function properly without the aid of cookies.</p>
        <p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Base64.ai, Inc‘s use of cookies.</p>
      </Block>
      <Block
        name='policy-changes'
        identifier={true}
      >
        <h3>Privacy Policy Changes</h3>
        <p>Although most changes are likely to be minor, Base64.ai, Inc may change its Privacy Policy from time to time, and in Base64.ai, Inc‘s sole discretion. Base64.ai, Inc encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
      </Block>
    </Section>
  </View>
)

// Export
export default PrivacyPolicy
