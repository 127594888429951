// Layout
import Context from '~/context/global'
import Section from '~/layout/section/Section'
import { Component } from 'react'
import PropTypes from 'prop-types'
import window from 'global/window'
import { AppButton } from '../../interface/app-button/AppButton'

// Content: HowAreWeDifferentSection
class HowAreWeDifferentSection extends Component {
  static contextType = Context
  static propTypes = {
    onClickAction: PropTypes.func
  }

  render () {
    return (
      <Section name='how-are-we-different-section' align='center'>
        <h2 className='slogan'>
          How is Base64.ai different?
        </h2>
        <ul onClick={this.props.onClickAction}>
          <li>
            <p className='featureTitle'>Pre-built</p>
            <p className='featureDescription'>AI models, components, and integrations<br />for automation workflows</p>
          </li>
          <li>
            <p className='featureTitle'>2,700+ types</p>
            <p className='featureDescription'>worldwide IDs, driver licenses, passports, forms, invoices & more</p>
          </li>
          <li>
            <p className='featureTitle'>99%+</p>
            <p className='featureDescription'>data extraction accuracy<br />for most document types</p>
          </li>
          <li>
            <p className='featureTitle'>1 AI</p>
            <p className='featureDescription'>with {window.integrationNumber?.text} easy integration options</p>
          </li>
          <li>
            <p className='featureTitle'>&lt; 1 hour</p>
            <p className='featureDescription'>integration time</p>
          </li>
          <li>
            <p className='featureTitle'>&lt; 3 seconds</p>
            <p className='featureDescription'>to process most document types</p>
          </li>
        </ul>

        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          <AppButton href='/demo' className='secondary-cta large'>
            Try free demos
          </AppButton>
          <AppButton href='/integrations' className='primary-cta large'>
            Integrate now
          </AppButton>
        </div>
      </Section>
    )
  }
}

// Export
export default HowAreWeDifferentSection
