export default [
  {
    title: 'signatureRecognition',
    url: '/demo/signature-recognition',
    description: 'Extract signatures from any document including forms, contracts, and IDs',
    buttonTitle: 'Signature recognition',
    path: 'SIGNATURE_AI_1.png',
    isWorkOffline: true
  },
  {
    title: 'signatureVerification',
    url: '/demo/signature-verification',
    description: 'Compare signatures on multiple documents to verify signature authenticity',
    buttonTitle: 'Signature verification',
    path: 'SIGNATURE_AI_2.png',
    isWorkOffline: true
  }
]
