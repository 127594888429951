export default [
  {
    title: 'Free trial',
    creditsMonthly: 'Try our AI with 100 pages',
    descriptionMonthly: 'Try for free',
    descriptionAnnually: 'Try for free',
    feats: [0, 1, 2, 3, 4],
    target: '/flow',
    buttonText: 'Start Now',
    hasPayment: false
  },
  {
    title: 'Scale',
    creditsMonthly: '1,000 pages/month subscription',
    creditsAnnual: '12,000 pages/year subscription',
    descriptionMonthly: '$300/month\n$0.30 per additional page',
    descriptionAnnually: '$3,000/year\n$0.24 per additional page',
    feats: [0, 1, 2, 3, 4, 5],
    target: 'scale',
    buttonText: 'Subscribe',
    hasPayment: true
  },
  {
    title: 'Success',
    creditsMonthly: '10,000 pages/month subscription',
    creditsAnnual: '120,000 pages/year subscription',
    descriptionMonthly: '$2,500/month\n$0.25 per additional page',
    descriptionAnnually: '$27,600/year\n$0.23 per additional page',
    feats: [0, 1, 2, 3, 4, 5, 6],
    target: 'success',
    buttonText: 'Subscribe',
    hasPayment: true
  },
  {
    title: 'Enterprise',
    creditsMonthly: 'Cloud and on‑premises deployment.',
    descriptionMonthly: 'Please contact sales',
    descriptionAnnually: 'Please contact sales',
    feats: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    target: '/contact',
    buttonText: 'Contact us',
    hasPayment: false
  }
]
