import Link from '../link/Link'
import MaterialIcon from '../material-icon/MaterialIcon'
import PropTypes from 'prop-types'

const BackButton = ({ to, onClick, placeholder }) => {
  return (
    <Link
      style={{ display: 'inline-block', fontSize: '1rem' }}
      onClick={onClick}
      to={to}
      className='back-button-link'
    >
      <div className='back-button-container'>
        <MaterialIcon
          name='arrow_back'
          className='back-button'
        />
        {placeholder ?? 'Back'}
      </div>
    </Link>
  )
}

BackButton.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  placeholder: PropTypes.string
}

export default BackButton
