import { Component } from 'react'
import CreatableSelect from 'react-select/creatable'
import Checkbox from '../checkbox/Checkbox'
import PropTypes from 'prop-types'
import { createSelectFieldsFromString } from '../../utilities/format'

class ResultMustHaveDisabledFields extends Component {
  static propTypes = {
    resultManipulation: PropTypes.object,
    onChange: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {}
  }

  constructor (props) {
    super(props)
    this.state = {
      resultManipulation: {
        disabledFields: [],
        mustHaveFields: [],
        disableAllExceptMustHaveFields: false
      }
    }

    this.configuration = {
      modelsData: []
    }
    this.defaultOptions = [
      { label: 'Name', value: 'Name' },
      { label: 'Given name', value: 'Given name' },
      { label: 'Family name', value: 'Family name' },
      { label: 'Full name', value: 'Full name' },
      { label: 'Date', value: 'Date' },
      { label: 'Date of birth', value: 'Date of birth' },
      { label: 'Expiration date', value: 'Expiration date' },
      { label: 'Issue date', value: 'Issue date' },
      { label: 'Address', value: 'Address' },
      { label: 'Vin', value: 'Vin' },
      { label: 'Total', value: 'Total' },
      { label: 'Tax', value: 'Tax' },
      { label: 'Company name', value: 'Company name' },
      { label: 'Bill to', value: 'Bill to' },
      { label: 'Ship to', value: 'Ship to' },
      { label: 'License number', value: 'License number' },
      { label: 'Document number', value: 'Document number' }
    ]
    this.handleManipulationChange = this.handleManipulationChange.bind(this)
  }

  componentDidMount () {
    this.setState({
      resultManipulation: this.props.resultManipulation
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.resultManipulation !== this.props.resultManipulation) {
      this.setState({
        resultManipulation: this.props.resultManipulation
      })
    }
  }

  handleManipulationChange = (event, options) => {
    const resultManipulation = this.state.resultManipulation || {}
    resultManipulation.mustHaveFields = resultManipulation.mustHaveFields || []
    resultManipulation.disabledFields = resultManipulation.disabledFields || []
    resultManipulation.disableAllExceptMustHaveFields = !!resultManipulation.disableAllExceptMustHaveFields
    if (options?.mustHaveFields) {
      const newMustHave = event.slice(-1)[0] || {}
      // if candidateMustHaveField does not appear in the disabled fields list
      if (
        !newMustHave.value
        || (newMustHave.value && !resultManipulation.disabledFields.map(ff => ff.value).includes(newMustHave.value))
      ) {
        resultManipulation.mustHaveFields = event
      }
    }
    if (options?.disabledFields) {
      const newDisabled = event.slice(-1)[0] || {}
      // if candidateDisableField does not appear in the must-have fields list
      if (
        !newDisabled.value
        || (newDisabled.value && !resultManipulation.mustHaveFields.map(ff => ff.value).includes(newDisabled.value))
      ) {
        resultManipulation.disabledFields = event
      }
    }
    if (options?.removeAllFields) {
      resultManipulation.disableAllExceptMustHaveFields = event.checked
      resultManipulation.disabledFields = []
    }
    this.setState({ resultManipulation, isSubmitted: false }, () => {
      this.props.onChange(this.state.resultManipulation)
    })
  }

  render () {
    const { resultManipulation } = this.state

    return (
      <>
        <h2 className='title'>Required fields</h2>
        <p className='header-paragraph'>
          Add essential field names that must be present in the result even if they were not found in the document.<br />
          Custom Taxonomy and Question &amp; Answer keys are already required fields, so there is no need to add them here.<br />
          The order of fields does not change the output.
        </p>
        {/* if user inputs a comma start a new input */}
        <div>
          <CreatableSelect
            isMulti
            className='react-select'
            classNamePrefix='react-select'
            value={resultManipulation?.mustHaveFields || []}
            placeholder='Add required fields...'
            closeMenuOnSelect={false}
            onCreateOption={
              e => {
                this.handleManipulationChange(createSelectFieldsFromString(e, this.state.resultManipulation?.mustHaveFields), { mustHaveFields: true })
              }
            }
            onChange={
              e => {
                const fields = e.map(ff => {
                  return { value: ff.value, label: ff.label }
                })
                this.handleManipulationChange(fields, { mustHaveFields: true })
              }
            }
            options={this.defaultOptions}
            formatCreateLabel={
              value => {
                let label = ''
                const fields = createSelectFieldsFromString(value)
                const intersection = resultManipulation?.disabledFields?.filter(ff => fields.map(f => f.value).includes(ff.value))
                const unique = fields.filter(f => !intersection.map(ff => ff.value).includes(f.value))

                label = `Create ${unique.map(f => `"${f.value}"`).join(', ')}`
                if (intersection.length) {
                  label += ` - ${intersection.map(f => `"${f.value}"`).join(', ')} already exists in disabled fields`
                }
                return label
              }
            }
          />
        </div>

        <h2 className='title'>Disabled fields</h2>
        <p className='header-paragraph disabled-fields' style={{ marginTop: '1rem !important' }}>
          Add field names you want to remove from the AI extraction results.
        </p>
        <div>
          <CreatableSelect
            isMulti
            className='react-select'
            classNamePrefix='react-select'
            value={resultManipulation?.disabledFields || []}
            placeholder='Enter disabled fields...'
            closeMenuOnSelect={false}
            onCreateOption={
              e => {
                this.handleManipulationChange(createSelectFieldsFromString(e, this.state.resultManipulation?.disabledFields), { disabledFields: true })
              }
            }
            onChange={
              e => {
                const fields = e.map(ff => {
                  return { value: ff.value, label: ff.label }
                })
                this.handleManipulationChange(fields, { disabledFields: true })
              }
            }
            isDisabled={!!resultManipulation?.disableAllExceptMustHaveFields}
            options={this.defaultOptions}
            formatCreateLabel={
              value => {
                let label = ''
                const fields = createSelectFieldsFromString(value)
                const intersection = resultManipulation?.mustHaveFields?.filter(ff => fields.map(f => f.value).includes(ff.value))
                const unique = fields.filter(f => !intersection.map(ff => ff.value).includes(f.value))

                label = `Create ${unique.map(f => `"${f.value}"`).join(', ')}`
                if (intersection.length) {
                  label += ` - ${intersection.map(f => `"${f.value}"`).join(', ')} already exists in must-have fields`
                }

                return label
              }
            }
          />
        </div>
        <div className='check-box-container' style={{ marginTop: '0.5rem' }}>
          <Checkbox
            key={1}
            className='big'
            placeholder='Disable all non-required fields'

            onChange={e => {
              this.handleManipulationChange(e.target, { removeAllFields: true })
            }}
            title={
              resultManipulation?.mustHaveFields?.length
                ? ''
                : 'At least one must-have field should be entered for this configuration.'
            }
            checked={!!resultManipulation?.disableAllExceptMustHaveFields}
          />
        </div>
      </>
    )
  }
}

export default ResultMustHaveDisabledFields
