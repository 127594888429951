import React, { Fragment } from 'react'

import PropTypes from 'prop-types'
import Select from 'react-select'
import DayPicker, { DateUtils } from 'react-day-picker'
import window from 'global/window'
import Context from '~/context/global'

// Utilities
import { capitalizeFirstLetter, twoDigits } from '~/utilities/format'

// Interface
import Loading from '~/interface/loading/Loading'

// Layout
import Section from '~/layout/section/Section'

// Helpers
import { request } from '~/helpers/request'
import BarChart from '../bar-chart/BarChart'
import PieChart from '../pie-chart/PieChart'

// Content: StatDisplaySection
class StatDisplaySection extends React.Component {
  static contextType = Context

  static propTypes = {
    filter: PropTypes.string,
    limit: PropTypes.string,
    models: PropTypes.object,
    getModels: PropTypes.func,
    history: PropTypes.object,
    flowID: PropTypes.string,
    classNameOverride: PropTypes.string,
    currentEndpoint: PropTypes.string
  }

  static defaultProps = {
    filter: 'this-month'
  }

  constructor (props) {
    super(props)
    this.state = {
      data: null,
      loading: false,
      activeFilter: props.filter ?? 'this-month',
      displayMode: 'day',
      customLimits: {},
      flowID: this.props.flowID,
      currentEndpoint: this.props.currentEndpoint
    }

    this.configuration = {
      filters: [
        { value: 'this-year', label: 'This year' },
        { value: 'this-month', label: 'This month' },
        { value: 'this-week', label: 'This week' },
        { value: 'last-month', label: 'Last month' },
        { value: 'last-week', label: 'Last week' },
        { value: 'custom', label: 'Custom date' }
      ],
      defaultFilter: 'this-month',
      filterStyle: {
        container: provided => ({ ...provided, margin: '0 auto 1.25rem', width: '10rem' })
      },

      types: {
        documents: { title: 'Documents', chart: 'bar' },
        pages: { title: 'Pages', chart: 'bar' },
        modelTypes: { title: 'Model types', chart: 'pie' }
      },
      graph: {
        colors: [
          '#333366',
          '#2a467e',
          '#225696',
          '#1a66af',
          '#1387c7',
          '#0b97df',
          '#03a7e7',
          '#00b6e7',
          '#00c5e7',
          '#00dddd'
        ],
        barOptions: {
          tooltip: item => this.renderTooltip(item.data, 'bar')
        },
        pieOptions: {
          tooltip: item => <div className='tooltipCover'>{this.renderTooltip(item.datum, 'pie')}</div>
        }
      }
    }
    this.setDimensions = this.setDimensions.bind(this)
    this.setParameters = this.setParameters.bind(this)
    this.setUsageData = this.setUsageData.bind(this)
    this.getUsageData = this.getUsageData.bind(this)
    this.processUsageData = this.processUsageData.bind(this)
    this.getUTCTimestamp = this.getUTCTimestamp.bind(this)
    this.getUTCString = this.getUTCString.bind(this)
    this.getNextTime = this.getNextTime.bind(this)
    this.getDatesList = this.getDatesList.bind(this)
    this.getLabelFormat = this.getLabelFormat.bind(this)
    this.setCustomLimits = this.setCustomLimits.bind(this)
    this.getCustomLimits = this.getCustomLimits.bind(this)
    this.getDistributedData = this.getDistributedData.bind(this)
    this.getBarChartData = this.getBarChartData.bind(this)
    this.getPieChartData = this.getPieChartData.bind(this)
    this.renderTooltip = this.renderTooltip.bind(this)
    this.processPickerValues = this.processPickerValues.bind(this)
  }

  componentDidMount () {
    this.showAll = window.location.href.includes('showAll')
    this.setDimensions()
    window.addEventListener('resize', this.setDimensions)
    this.props.getModels()
    this.setParameters()
  }

  componentDidUpdate (prevProps) {
    const { filter, limit } = this.props
    if (filter !== prevProps.filter || limit !== prevProps.limit) {
      this.setParameters()
    }
  }

  setDimensions () {
    const windowWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    const maximumWidth = 960
    let horizontalPortion = 0.75
    let verticalPortion = 1 / 3
    if (windowWidth < 480) {
      horizontalPortion = 0.9
      verticalPortion = 2 / 3
    } else if (windowWidth < 1024) {
      horizontalPortion = 0.85
      verticalPortion = 1 / 2
    }
    const width = Math.min(
      Math.floor(
        windowWidth * horizontalPortion
      ),
      maximumWidth
    )
    const height = Math.floor(
      width * verticalPortion
    )
    this.setState({
      dimensions: { width, height }
    })
  }

  setParameters () {
    const { limit } = this.props
    let { filter } = this.props
    const { filters, defaultFilter } = this.configuration
    if (!filters.map(filter => filter.value).includes(filter)) {
      filter = defaultFilter
    }
    const customLimits = this.getCustomLimits(limit)
    this.setState({
      activeFilter: filter,
      customLimits
    })
    if (!(filter === 'custom' && (!customLimits.start || !customLimits.end))) {
      this.setUsageData(filter, customLimits)
    }
  }

  setUsageData (filter, customLimits) {
    const [filterData] = this.configuration.filters.filter(item => item.value === filter)
    if (filter && filterData) {
      this.setState({
        loading: true
      })
      const now = new Date()
      const date = {
        year: now.getUTCFullYear(),
        month: now.getUTCMonth(),
        day: now.getUTCDate(),
        weekDay: now.getUTCDay()
      }
      // Default dates
      let limits = {}
      switch (filter) {
        case 'last-week':
          limits.end = this.getUTCTimestamp([date.year, date.month, date.day - date.weekDay])
          break
        case 'last-month':
          limits.end = this.getUTCTimestamp([date.year, date.month, 1]) - 24 * 60 * 60
          break
        default:
          limits.end = this.getUTCTimestamp([date.year, date.month, date.day])
          break
      }
      switch (filter) {
        case 'this-year':
          limits.start = this.getUTCTimestamp([date.year, 0, 1])
          break
        case 'this-month':
          limits.start = this.getUTCTimestamp([date.year, date.month, 1])
          break
        case 'this-week':
          limits.start = this.getUTCTimestamp([date.year, date.month, date.day - date.weekDay + 1])
          break
        case 'last-week':
          limits.start = this.getUTCTimestamp([date.year, date.month, date.day - date.weekDay - 6])
          break
        case 'last-month':
          now.setMonth(now.getMonth() - 1)
          limits.start = this.getUTCTimestamp([now.getUTCFullYear(), now.getUTCMonth(), 1])
          break
        case 'custom':
          limits = customLimits
          break
      }
      this.getUsageData(
        limits.start,
        limits.end,
        data => {
          this.processUsageData(data, limits, () => {
            this.setState({
              loading: false
            })
          })
        },
        () => this.context.displayModal({
          title: 'Unauthorized',
          content: 'Data cannot be fetched. Please login.',
          options: [
            { content: 'OK', action: () => this.context.redirect('/login') }
          ]
        })
      )
    }
  }

  getUsageData (start, end, onSuccess, onError) {
    const params = window.location.href.split('?')[1]
    const suffix = params ? `&${params}` : ''
    const endpoint = `/usageData?startDate=${this.getUTCString(start)}&endDate=${this.getUTCString(end)}${this.state.flowID ? '&flowID=' + this.state.flowID : ''}${suffix}`
    request({
      endpoint,
      method: 'GET'
    }, (error, response) => {
      if (error) {
        onError && onError(error.toString())
      } else {
        onSuccess && onSuccess(response)
      }
    })
  }

  processUsageData (data, limits, callback) {
    const { filter } = this.props
    const displayMode = this.getDisplayMode(limits)
    const labelFormat = this.getLabelFormat(filter, displayMode)
    labelFormat.timeZone = 'UTC'
    labelFormat.useUTC = true
    const dates = this.getDatesList(limits, displayMode)
    const results = {}
    for (const type in data) {
      const options = {
        chart: 'bar',
        labelFormat,
        ...(this.configuration.types[type] || {})
      }
      results[type] = this[`get${capitalizeFirstLetter(options.chart)}ChartData`](
        data[type], { dates, displayMode, labelFormat }
      )
    }
    this.setState({
      data: results
    })
    callback && callback(results)
  }

  getDisplayMode ({ start, end }) {
    let displayMode = 'day'
    const startDateString = this.getUTCString(start)
    const endDateString = this.getUTCString(end)
    if (this.props.filter === 'this-year') {
      displayMode = 'month'
    } else if (this.props.filter === 'custom' && startDateString && endDateString) {
      const days = (new Date(endDateString) - new Date(startDateString)) / (1000 * 60 * 60 * 24)
      if (days > 366) {
        displayMode = 'year'
      } else if (days > 31) {
        displayMode = 'month'
      }
    }
    displayMode !== this.state.displayMode && this.setState({ displayMode })
    return displayMode
  }

  getUTCTimestamp (data) {
    const isString = typeof data === 'string'
    let timestamp = null
    if (data && isString) {
      data = data.split('-')
    }
    if (data.length === 3) {
      data = data.map(piece => parseInt(piece))
      isString && data[1]--
      const requestedDate = new Date(Date.UTC(...data))
      if (requestedDate instanceof Date && !isNaN(requestedDate)) {
        timestamp = requestedDate.getTime()
      }
    }
    return timestamp
  }

  getUTCString (timestamp) {
    let string = null
    if (typeof timestamp === 'number') {
      const date = new Date(timestamp)
      if (new Date(date) instanceof Date && !isNaN(date)) {
        string = `${
          date.getUTCFullYear()
        }-${
          twoDigits(date.getUTCMonth() + 1)
        }-${
          twoDigits(date.getUTCDate())
        }`
      }
    }
    return string
  }

  getNextTime (timestamp, frequency) {
    const date = new Date(timestamp)
    const result = new Date(
      Date.UTC(
        date.getUTCFullYear() + (frequency === 'year'),
        date.getUTCMonth() + (frequency === 'month'),
        date.getUTCDate() + (frequency === 'day')
      )
    ).getTime()
    return result
  }

  getDatesList (limits, displayMode) {
    const dates = []
    switch (displayMode) {
      case 'year':
        limits.start = this.getUTCTimestamp(
          this.getUTCString(limits.start).slice(0, 4) + '-01-01'
        )
        break
      case 'month':
        limits.start = this.getUTCTimestamp(
          this.getUTCString(limits.start).slice(0, 7) + '-01'
        )
        break
    }
    for (let time = limits.start; time <= limits.end; time = this.getNextTime(time, displayMode)) {
      dates.push(this.getUTCString(time))
    }
    return dates
  }

  getLabelFormat (filter, displayMode) {
    switch (filter) {
      case 'this-year':
        return { month: 'long' }
      case 'this-month':
        return { month: 'short', day: '2-digit' }
      case 'this-week':
        return { weekday: 'long' }
      default:
        switch (displayMode) {
          case 'year':
            return { year: 'numeric' }
          case 'month':
            return { month: 'long' }
          default:
            return { month: 'short', day: '2-digit' }
        }
    }
  }

  setCustomLimits (limits) {
    limits = this.processPickerValues(limits, true)
    if (limits.from && limits.to) {
      const limitString = `${
        this.getUTCString(limits.from)
      }:${
        this.getUTCString(limits.to)
      }`
      this.props.history.push(`${this.state.currentEndpoint}/custom/${limitString}${window.location.search}`)
    } else if (!limits.from && !limits.to) {
      this.props.history.push(`${this.state.currentEndpoint}/custom${window.location.search}`)
    } else {
      this.setState({
        customLimits: {
          start: limits.from || null,
          end: limits.to || null
        }
      })
    }
  }

  getCustomLimits (string) {
    let start = null
    let end = null
    if (string && typeof string === 'string') {
      const limits = string.split(':')
      if (limits.length === 2) {
        limits.forEach((limit, index) => {
          if (index === 0) {
            start = this.getUTCTimestamp(limit)
          } else {
            end = this.getUTCTimestamp(limit)
          }
        })
      }
    }
    return start && end
      ? { start, end }
      : { start: null, end: null }
  }

  getDistributedData (data, dates, displayMode) {
    const distributedData = []
    if (dates.length > 0) {
      const first = this.getUTCTimestamp(dates[0])
      const latest = this.getNextTime(this.getUTCTimestamp(dates[dates.length - 1]), displayMode)
      for (let time = first; time < latest; time = this.getNextTime(time, displayMode)) {
        const limit = this.getNextTime(time, displayMode)
        const key = this.getUTCString(time)
        distributedData[key] = 0
        Object.keys(data).forEach(date => {
          if (this.getUTCTimestamp(date) < limit) {
            distributedData[key] += data[date]
            delete data[date]
          }
        })
      }
    }
    return distributedData
  }

  getBarChartData (data, { dates, displayMode, labelFormat }) {
    data = this.getDistributedData(data, dates, displayMode)
    const result = {
      values: [],
      keys: [],
      total: 0
    }
    dates.forEach(date => {
      const label = new Date(date).toLocaleString('en-US', labelFormat)
      if (typeof data[date] === 'object') {
        result.values.push({ date, label, ...(data[date] || {}) })
        Object.keys(data[date]).forEach(key => {
          result.total += data[date][key]
          !result.keys.includes(key) && result.keys.push(key)
        })
      } else {
        const value = data[date] || 0
        result.values.push({
          date,
          label,
          value,
          color: '#333366'
        })
        result.total += value
      }
    })
    if (displayMode !== 'day') {
      result.action = ({ data }) => {
        let start, end
        if (displayMode === 'year') {
          const year = data.date.slice(0, 4)
          start = year + '-01-01'
          end = year + '-12-31'
        } else if (displayMode === 'month') {
          const yearAndMonth = data.date.slice(0, 7)
          const pieces = data.date.split('-')
          const numberOfDays = new Date(pieces[0], pieces[1], 0).getDate()
          start = yearAndMonth + '-01'
          end = yearAndMonth + '-' + numberOfDays
        }
        this.props.history.push(`${this.state.currentEndpoint}/custom/${start}:${end}${window.location.search}`)
      }
    }
    return result
  }

  getPieChartData (data) {
    const { colors } = this.configuration.graph
    const items = {}
    for (const date in data) {
      if (typeof data[date] === 'object') {
        Object.keys(data[date]).forEach(key => {
          const value = data[date][key] || 0
          if (items[key] === undefined) items[key] = 0
          items[key] += value
        })
      }
    }
    let isIncludingOther = false
    const total = Object.keys(items).length
    const maximumKeyCount = 10
    if (total > maximumKeyCount) isIncludingOther = true
    const keyCount = Math.min(total, maximumKeyCount - isIncludingOther)
    const keys = [
      ...Object.keys(items).filter(i => i !== 'unrecognized')
        .sort((a, b) => Math.sign(items[b] - items[a]))
        .slice(0, keyCount)
    ]
    isIncludingOther && keys.push('other')
    let grandTotal = 0
    let other = []
    Object.keys(items).forEach(key => {
      if (key !== 'other') grandTotal += items[key]
      if (isIncludingOther && !keys.includes(key)) {
        items.other += items[key]
        other.push({
          id: key,
          label: this.props.models[key] || '',
          value: items[key]
        })
        delete items[key]
      }
    })
    other = other.sort((a, b) => Math.sign(b.value - a.value))
    // remove other from values
    const values = keys.map((key, index) => {
      return {
        id: key,
        label: this.props.models[key] || '',
        value: items[key],
        color: colors[index % colors.length],
        ...(
          key === 'other'
            ? {
                label: 'Other',
                other
              }
            : {}
        )
      }
    })
    return { values, keys, total, grandTotal, isIncludingOther }
  }

  processPickerValues (values, isSetting) {
    const difference = new Date().getTimezoneOffset() * 60 * 1000 * isSetting ? -1 : 1
    if (isSetting) {
      values = {
        from: values.from ? new Date(values.from).getTime() : null,
        to: values.to ? new Date(values.to).getTime() : null
      }
    }
    values = {
      from: values.from ? values.from + difference : null,
      to: values.to + difference ? values.to : null
    }
    if (!isSetting) {
      values = {
        from: values.from ? new Date(values.from) : null,
        to: values.to ? new Date(values.to) : null
      }
    }
    return values
  }

  renderTooltip (data) {
    return (
      <div className='tooltip'>
        <div className='content'>
          {data.color
          && (
            <span
              className='color'
              style={{
                backgroundColor: data.color
              }}
            >
            </span>
          )}
          <span className='label'>{data.label || data.key || data.id || ''}</span>
          <b className='value'>{data.value || '0'}</b>
        </div>
        {data.other
        && <small className='hint'>See details below</small>}
      </div>
    )
  }

  render () {
    const { data, loading, dimensions, activeFilter, customLimits } = this.state
    const { filter } = this.props
    const { filters, filterStyle, types, graph } = this.configuration
    const [activeFilterData] = filters.filter(filter => filter.value === activeFilter)
    const picker = this.processPickerValues({
      from: customLimits.start,
      to: customLimits.end
    })

    return (
      <Section
        name='stats'
        align='center'
        className={`${this.props.classNameOverride}`}
      >
        <div style={{ width: '30vw', margin: 'auto' }}>
          <p className='paragraph'>Select an interval to see your usage in a specific period.<br />All dates are in UTC timezone.<br />The results could be delayed up to 1 day.</p>
          {activeFilter
          && (
            <>
              <Select
                classNamePrefix='react-select'
                className='react-select'
                defaultValue={activeFilterData}
                options={filters}
                styles={filterStyle}
                placeholder='Select a display frequency'
                isSearchable={false}
                onChange={option => option.value && option.value !== filter
                && this.props.history.push(`${this.state.currentEndpoint}/${option.value}${window.location.search}`)}
              />
              {activeFilter === 'custom'
              && (
                <DayPicker
                  className='limits'
                  numberOfMonths={1}
                  selectedDays={[picker.from, picker]}
                  disabledDays={[{
                    after: new Date()
                  }]}
                  modifiers={{
                    start: picker.from,
                    end: picker.to
                  }}
                  onDayClick={day =>
                    this.setCustomLimits(
                      DateUtils.addDayToRange(day, picker)
                    )}
                />
              )}
            </>
          )}
        </div>
        {
          (loading || !data)
            ? (
                <div className='placeholder'>
                  <Loading />
                  <h2 className='title'>Calculating usage...</h2>
                  <p className='paragraph'>Please wait, this may take a few seconds.</p>
                </div>
              )
            : Object.keys(data).map(type => (
              <Fragment key={type}>
                {types[type]
                && (
                  <div
                    className='graph'
                    data-type={types[type].chart}
                  >
                    <h2 className='title'>
                      <span>{types[type].title || type}</span>
                      <small>{(data[type].total || 0).toLocaleString()}</small>
                    </h2>
                    {data[type].total > 0
                      ? (
                          <div
                            className='visual'
                            style={{
                              width: dimensions.width + 'px',
                              height: (
                                types[type].chart === 'pie'
                                  ? Math.floor(dimensions.height * 1.25)
                                  : dimensions.height
                              ) + 'px'
                            }}
                          >
                            {types[type].chart === 'bar'
                            && (
                              <BarChart
                                barOptions={graph.barOptions}
                                keys={
                                  data[type].keys.length > 0
                                    ? data[type].keys
                                    : ['value']
                                }
                                data={data[type].values}
                                onClick={data[type].action}
                              />
                            )}
                            {types[type].chart === 'pie'
                            && (
                              <PieChart
                                pieOptions={graph.pieOptions}
                                data={data[type].values}
                              />
                            )}
                          </div>
                        )
                      : <p className='paragraph'>There is no usage to display.</p>}
                    {data[type].isIncludingOther
                    && (
                      <ul className='details'>
                        {
                          data[type].values.map((item, index) => item.other
                            ? item.other.map((otherItem, otherIndex) => (
                              <li key={`other-${otherIndex}`}>
                                {otherItem.label || otherItem.id || 'Unknown'} <b>{otherItem.value}</b>
                              </li>
                            )
                            )
                            : (
                                <li key={index}>
                                  {item.label || item.id || 'Unknown'} <b>{item.value}</b>
                                </li>
                              )
                          )
                        }
                      </ul>
                    )}
                  </div>
                )}
              </Fragment>
            )
            )
        }
      </Section>
    )
  }
}

export default StatDisplaySection
