// Modules
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Interface
import Link from '~/interface/link/Link'

// Pseudo-components
const PseudoButton = props => (props.href || props.to)
  ? <Link {...props}>{props.children}</Link>
  : <button {...props}>{props.children}</button>

PseudoButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node
}

// Interface: Button
const Button = props => (
  <PseudoButton
    {...props}
    role='button'
    className={clsx(
      'button',
      props?.size,
      props?.loading && 'loading',
      props?.className
    )}
  />
)

Button.propTypes = {
  size: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string
}

// Export
export default Button
