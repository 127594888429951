import { Component } from 'react'

// Interface
import Link from '~/interface/link/Link'

// Data
import faqs from '~/data/frequentlyAskedQuestions'

// Layout
import Section from '~/layout/section/Section'

// Helpers
import { hasDatabase } from '../../context/environment'

// Content: FAQSection
class FAQSection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: ''
    }

    this.renderColumn = this.renderColumn.bind(this)
  }

  renderColumn (faqs) {
    return (
      <div className='menu-column'>
        {
          faqs.map((faq, index) => (
            <Section
              key={index}
              title={faq.title}
              isOpen={this.state.activeTab === faq.title}
              onClick={() => this.state.activeTab !== faq.title ? this.setState({ activeTab: faq.title }) : this.setState({ activeTab: '' })}
              accordion={true}
            >
              {
                faq.answer && (
                  <p>
                    {faq.answer}
                  </p>
                )
              }
              {
                faq.list && (
                  <ol>
                    {
                      faq.list.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))
                    }
                  </ol>
                )
              }
              {faq.link && <Link href={faq.link} target='_blank' rel='noopener noreferrer'>see more</Link>}
            </Section>
          ))
        }
      </div>
    )
  }

  render () {
    const left = faqs.slice(0, Math.ceil(faqs.length / 2))
    const right = faqs.slice(Math.ceil(faqs.length / 2), faqs.length)
    return (hasDatabase()
      && (
        <Section
          name='faq'
          align='center'
          identifier
        >
          <h2 className='title'>Frequently Asked Questions</h2>
          <p className='introduction'>
            Find Answers to common questions on Base64.ai’s IDP solution and gain in-depth
            insights into features, integrations, onboarding, demo usage, pricing structure,
            and how to maximize document workflow efficiency with Base64.ai Flow.
            Unleash the power of AI-powered automation for your document processing scenarios.
          </p>
          {/* accordion menu */}
          <div className='accordion-menu'>
            {/* left column */}
            {this.renderColumn(left)}
            {/* right column */}
            {this.renderColumn(right)}
          </div>
        </Section>
      ))
  }
}

// Export
export default FAQSection
