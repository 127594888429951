import { Component } from 'react'
import Section from '~/layout/section/Section'

class WhyBecomeAPartnerSection extends Component {
  render () {
    return (
      <Section name='why' align='center'>
        <h2 className='slogan'>Why become a partner?</h2>
        <h3 className='introduction'>
          Improve your product by&nbsp;
          <a
            href='https://www.forbes.com/sites/theyec/2014/06/03/why-a-white-label-solution-is-easier-than-building-your-own/?sh=3b9670aadd9e'
            target='_blank'
            rel='noopener noreferrer'
          >
            embedding our white-labeled AI solutions.
          </a><br />
          Gain exclusive access to the Base64.ai resources at preferred partner rates.
        </h3>
        <ul onClick={this.toContactSection}>
          <li>
            <img src='/static/content/partners/portal/discount.png' />
            <p className='featureDescription'>Get preferred rates for your customers</p>
          </li>
          <li>
            <img src='/static/content/partners/portal/intelligence.png' />
            <p className='featureDescription'>Full platform access and custom model development</p>
          </li>
          <li>
            <img src='/static/content/partners/portal/good-quality.png' />
            <p className='featureDescription'>API & RPA solutions for workflow integrations</p>
          </li>
          <li>
            <img src='/static/content/partners/portal/low-price.png' />
            <p className='featureDescription'>Decrease project costs with shortened timelines</p>
          </li>
        </ul>
      </Section>
    )
  }
}

export default WhyBecomeAPartnerSection
