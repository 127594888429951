import PropTypes from 'prop-types'
import { AppButton } from '../app-button/AppButton'
import MaterialIcon from '../material-icon/MaterialIcon'
import clsx from 'clsx'

export const AppDropdown = ({
  isDropdownDisabled,
  disabled,
  expand,
  children,
  options,
  onClick,
  href,
  rel,
  target,
  title,
  type,
  leftButtonClassName,
  dropdownButtonClassName,
  ...props
}) => {
  return (
    <div className='AppDropdown'>
      <AppButton
        {...props}
        disabled={disabled}
        expand={expand}
        href={href}
        rel={rel}
        target={target}
        onClick={onClick}
        className={clsx('left-button-of-dropdown', leftButtonClassName, props?.className)}
        title={title}
        type={type}
        noAnimation
      >
        {children}
      </AppButton>
      <AppButton
        {...props}
        options={options}
        disabled={isDropdownDisabled}
        noAnimation
        className={clsx(dropdownButtonClassName, props?.className)}
      >
        <MaterialIcon className='dropdown-icon' name='arrow_drop_down' />
      </AppButton>
    </div>
  )
}

AppDropdown.propTypes = {
  children: PropTypes.node,
  options: PropTypes.array,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  href: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  expand: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  isDropdownDisabled: PropTypes.bool,
  leftButtonClassName: PropTypes.string,
  dropdownButtonClassName: PropTypes.string
}
