import { Component } from 'react'
import PropTypes from 'prop-types'
import Section from '../../layout/section/Section'
import window from 'global'
import FileInput from '../../interface/file-input/FileInput'

class CreateFromTemplate extends Component {
  static propTypes = {
    component: PropTypes.object,
    onChange: PropTypes.func,
    isCustomModelClone: PropTypes.bool
  }

  static defaultProps = {
    component: {},
    isCustomModelClone: false
  }

  constructor (props) {
    super(props)
    this.state = {
      newName: 'Copy of ' + this.props.component.name,
      error: null,
      warning: null,
      selectedFilename: null,
      template: null
    }
  }

  onCheckBoxChange (data) {
    this.setState({
      [data.value]: data.checked
    }, () => this.props.onChange(this.state))
  }

  handleFileChange = accepted => {
    this.setState({ error: null, warning: null })

    if (accepted && accepted.length > 0) {
      const file = accepted[0]
      const reader = new FileReader()
      reader.onload = event => {
        try {
          const template = JSON.parse(event.target.result)
          
          // Check for expected keys based on the current mode
          const expectedFlowKeys = ['settings', 'customModels', 'name']
          const expectedCustomModelKeys = ['classification', 'features', 'name']
          
          let warning = null
          if (this.props.isCustomModelClone) {
            const hasAllCustomModelKeys = expectedCustomModelKeys.every(key => key in template)
            if (!hasAllCustomModelKeys) {
              warning = 'The uploaded JSON may not be a valid custom model configuration. Some expected properties are missing.'
            }
          } else {
            const hasAllFlowKeys = expectedFlowKeys.every(key => key in template)
            if (!hasAllFlowKeys) {
              warning = 'The uploaded JSON may not be a valid flow configuration. Some expected properties are missing.'
            }
          }

          if (template.name) {
            const newName = 'Copy of ' + template.name
            this.setState({
              newName,
              template: template,
              selectedFilename: file.name,
              warning,
              error: null
            }, () => {
              this.props.onChange({
                newName: this.state.newName,
                template: this.state.template
              })
            })
          } else {
            throw new Error('Invalid JSON structure: missing "name" property')
          }
        } catch (error) {
          this.setState({
            error: 'Invalid JSON file: ' + error.message,
            selectedFilename: null,
            template: null,
            warning: null
          }, () => {
            this.props.onChange({
              newName: this.state.newName,
              template: null
            })
          })
        }
      }
      reader.readAsText(file)
    }
  }

  handleNameChange = e => {
    const newName = e.target.value
    this.setState({ newName, error: null }, () => {
      this.props.onChange({
        newName: this.state.newName,
        template: this.state.template
      })
    })
  }

  render () {
    const isMobile = window?.innerWidth < 767
    const width = isMobile ? '55vw' : '25vw'
    return (
      <div className='flow-input' data-view='flow' style={{ background: 'none' }}>
        <Section style={{ width, padding: 'initial', marginTop: '-2rem', marginBottom: '-1rem' }}>
          <div style={{ marginBottom: '1rem' }}>
            <FileInput
              double={false}
              dropText='Upload JSON file'
              multiple={false}
              indicator={true}
              accept={['application/json']}
              onDrop={this.handleFileChange}
            />
          </div>
          {this.state.error && <p className='error'>{this.state.error}</p>}
          {this.state.warning && <p className='warning'>{this.state.warning}</p>}
          {this.state.selectedFilename && (
            <p className='selected-file'>Selected file: {this.state.selectedFilename}</p>
          )}
          <h2 className='title'>Name</h2>
          <input
            type='text'
            className='basic fullscreen-input'
            value={this.state.newName}
            onChange={this.handleNameChange}
          />
          <br />
          <br />
          <br />
          {
            !this.props.isCustomModelClone
              ? <p>This action duplicates the Flow settings only. To include integrations, download and attach them to the new Flow.</p>
              : <p>This action duplicates the custom models settings only. To include post-processing integrations, download and attach them to the new custom model.</p>
          }
          <br />
        </Section>
      </div>
    )
  }
}

export default CreateFromTemplate
