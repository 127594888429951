// Modules
import PropTypes from 'prop-types'

// Shape: Exit
const Exit = props => (
  <svg
    viewBox='0 0 20 18'
    className={`${props.name} icon`}
  >
    <path
      fill={props.color}
      d='M2,18 C0.8954305,18 0,17.1045695 0,16 L0,2 C0,0.8954305 0.8954305,0 2,0 L10,0 C11.1045695,0 12,0.8954305 12,2 C12,3.1045695 11.1045695,4 10,4 L4,4 L4,14 L10,14 C11.1045695,14 12,14.8954305 12,16 C12,17.1045695 11.1045695,18 10,18 L2,18 Z M13.4373763,7 C12.9952859,6.2351387 13.1014226,5.24015024 13.7557864,4.58578644 C14.536835,3.80473785 15.803165,3.80473785 16.5842136,4.58578644 L19.4126407,7.41421356 C20.1936893,8.19526215 20.1936893,9.4615921 19.4126407,10.2426407 L16.5842136,13.0710678 C15.803165,13.8521164 14.536835,13.8521164 13.7557864,13.0710678 C13.1956814,12.5109628 13.0372385,11.7012969 13.2804578,11 L9,11 C7.8954305,11 7,10.1045695 7,9 C7,7.8954305 7.8954305,7 9,7 L13.4373763,7 Z'
    >
    </path>
  </svg>
)

Exit.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string
}

// Export
export default Exit
