import { Component } from 'react'
import PropTypes from 'prop-types'
import DataExtractionResult from './DataExtractionResult'
import Dropdown from '../dropdown/Dropdown'
import MaterialIcon from '../material-icon/MaterialIcon'
import DownloadLink from '../download-link/DownloadLink'
import Context from '~/context/global'

/* SSR-IGNORE */ import ReactJson from 'react-json-view'
import Popup from '../popup/Popup'
import CustomSurvey from '../custom-survey/CustomSurvey'
import { AppButton } from '../app-button/AppButton'

class DataExtractionResultViewer extends Component {
  static contextType = Context

  static propTypes = {
    results: PropTypes.array,
    canvasHeight: PropTypes.number,
    context: PropTypes.object,
    contextProvider: PropTypes.object,
    user: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      results: this.props.results,
      mode: 'visual',
      selectedTestIntegration: 'postProcessing'
    }

    this.renderDataExtractionResults = this.renderDataExtractionResults.bind(this)
    this.renderJSONResponse = this.renderJSONResponse.bind(this)
    this.downloadJSONResponse = this.downloadJSONResponse.bind(this)
    this.getActionItems = this.getActionItems.bind(this)
    this.testIntegrations = [{ title: 'Post-processing integration', value: 'postProcessing' }, { title: 'Export integration', value: 'export' }]
  }

  componentDidMount () {
    const resultViewerContainerDom = document.querySelector('.result-viewer-container')
    if (!resultViewerContainerDom) {
      return
    }
    resultViewerContainerDom.addEventListener('scroll', e => {
      this.props.contextProvider.setState({ resultViewerScrollOffsetTop: e.target.scrollTop })
    })
    this.props.contextProvider.setState({ resultViewerScrollOffsetTop: resultViewerContainerDom.scrollTop })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.context !== this.props.context) {
      const { canvasHeight } = this.props.context
      if (!canvasHeight) {
        return
      }
      const resultViewer = document.querySelector('.result-viewer-container')
      if (!resultViewer) {
        return
      }
      resultViewer.style.height = canvasHeight + 'px'
    }
  }

  renderDataExtractionResults () {
    return this.state.results
      .map((result, index) => (
        <DataExtractionResult
          key={index}
          result={result}
          resultIndex={index}
          context={this.props.context}
          contextProvider={this.props.contextProvider}
        />
      ))
  }

  getActionItems () {
    let items = []
    const { flow, fileName, resultUuid, linkedResults } = this.props?.context || {}

    if (flow?.role?.includes('owner') || flow?.role?.includes('administrator')) {
      items.push({
        label: (
          <div className='dropdown-item-container result-viewer-reject'>
            <MaterialIcon name='delete' />
            <div>Delete document and results</div>
          </div>
        ),
        action: () => {
          this.props.contextProvider.deleteUpload(resultUuid, fileName, linkedResults)
        }
      })
    }

    if (!this.props.context.isEditDisabled) {
      items = [
        {
          label: (
            <div className='dropdown-item-container result-viewer-reject'>
              <MaterialIcon name='block' />
              <div>Reject</div>
            </div>
          ),
          action: () => {
            this.props.contextProvider.rejectResult()
          }
        },
        ...items,
        {
          label: (
            <div className='dropdown-item-container'>
              <MaterialIcon name='add_circle' />
              <div>Add new result</div>
            </div>
          ),
          action: () => {
            this.props.contextProvider.addNewResult(() => {
              const resultViewerContainerDom = document.querySelector('.result-viewer-container')
              if (!resultViewerContainerDom) {
                return
              }
              resultViewerContainerDom.scrollTo({
                top: 999999,
                left: 0,
                behavior: 'smooth'
              })
            })
          }
        },
        {
          label: (
            <div className='dropdown-item-container'>
              <MaterialIcon name='device_reset' />
              <div>Reset</div>
            </div>
          ),
          action: () => {
            this.props.contextProvider.resetResults()
          }
        }
      ]
    }
    if (this.state.mode === 'visual') {
      items.push({
        label: (
          <div className='dropdown-item-container'>
            <MaterialIcon name='data_object' />
            <div>Show API response</div>
          </div>
        ),
        action: () => {
          this.setState({ mode: 'api' })
        }
      })
    }
    if (this.state.mode === 'api') {
      items.push({
        label: (
          <div className='dropdown-item-container'>
            <MaterialIcon name='preview' />
            <div>Show visual response</div>
          </div>
        ),
        action: () => {
          this.setState({ mode: 'visual' })
        }
      })
    }
    if (flow?.instructions?.fileName) {
      items.push({
        label: (
          <div className='dropdown-item-container'>
            <MaterialIcon name='assignment_turned_in' />
            <DownloadLink
              url={'/flow/instructions/' + flow.flowID}
              name='View instructions'
              fileName={flow.instructions.fileName}
            />
          </div>
        ),
        action: () => {}
      })
    }
    if (flow?.role?.includes('owner') || flow?.role?.includes('administrator')) {
      items.push({
        label: (
          <div className='dropdown-item-container'>
            <MaterialIcon name='download' />
            <DownloadLink
              url={'/flow/originalFile/' + resultUuid}
              name='Download original file'
              fileName={fileName || 'Original file'}
            />
          </div>
        ),
        action: () => {}
      })

      items.push({
        label: (
          <div className='dropdown-item-container'>
            <MaterialIcon name='labs' />
            <div>Test integrations</div>
          </div>
        ),
        action: () => {
          this.context.displayModal({
            title: 'Test integration',
            style: { width: '20rem' },
            content: (
              <>
                <p>Please select the Flow Integration for which you would like use this result.</p>
                <CustomSurvey
                  questionnaire={this.testIntegrations.map(s => s.title)}
                  onChangeHandle={e => {
                    this.setState({ selectedTestIntegration: this.testIntegrations[e].value })
                  }}
                />
              </>),
            options: [
              {
                content: 'Cancel',
                intent: 'negative'
              },
              {
                content: 'Test',
                action: () => {
                  if (!this.state.selectedTestIntegration) {
                    return
                  }
                  setTimeout(() => {
                    this.props.contextProvider.approveResult({ testIntegrationType: this.state.selectedTestIntegration, status: this.state.results[0]?.status, disablePopUp: true })
                  }, 500)
                }
              }
            ]
          })
        }
      })
    }
    if (
      this.props.context.isEditDisabled
      && this.props.context.flow?.destination?.integration?.status === 'enabled'
      && (
        this.props.context.flow?.role?.includes('administrator')
        || this.props.context.flow?.role?.includes('owner')
      )
    ) {
      items.push({
        label: (
          <div className='dropdown-item-container'>
            <MaterialIcon name='replay' />
            <div>Rerun export integration</div>
          </div>
        ),
        action: () => {
          this.props.contextProvider.rerunExportIntegration()
        }
      })
    }
    return items
  }

  renderJSONResponse () {
    return (
      <>
        <ReactJson
          src={this.state.results}
          name={null}
          iconStyle='square'
          displayDataTypes={false}
          displayObjectSize={false}
          collapsed={5}
          collapseStringsAfterLength={40}
          displayArrayKey={false}
        />
      </>
    )
  }

  downloadJSONResponse () {
    const results = this.state.results
    if (!results?.length) return
    const blob = new Blob([JSON.stringify(results, null, 2)], { type: 'application/json;charset=utf-8' })
    const href = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.download = 'Base64ai document extraction.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  render () {
    return (
      <div>
        <div className='result-viewer-container'>
          {this.state.mode === 'visual' && this.renderDataExtractionResults()}
        </div>
        {
          this.state.mode === 'api'
          && (
            <Popup
              title='API response'
              closeHandler={() => {
                this.setState({ mode: 'visual' })
              }}
              customIcons={() => (
                <>
                  <MaterialIcon
                    className='download-json-button'
                    name='download'
                    title='Download json response as a file'
                    onClick={() => {
                      this.downloadJSONResponse()
                    }}
                  />
                </>
              )}
            >
              <div className='api-response-container'>
                {this.renderJSONResponse()}
              </div>
            </Popup>
          )
        }
        <div className='result-viewer-bottom-line'>
          <AppButton
            className='primary-cta'
            disabled={this.props.context.isEditDisabled}
            onClick={() => {
              !this.props.context.isEditDisabled && this.props.contextProvider.approveResult()
            }}
          >
            Approve
          </AppButton>
          <Dropdown
            isAlignedRight={true}
            className='hitl-options'
            menuStyle={{ marginTop: '0.25rem' }}
            title='&middot;&middot;&middot;'
            items={this.getActionItems()}
          />
        </div>
      </div>
    )
  }
}

export default DataExtractionResultViewer
