// Modules
import PropTypes from 'prop-types'

// Layout
import View from '~/layout/view/View'
import Header from '~/layout/header/Header'
import window from 'global/window'
import Link from '../../interface/link/Link'
import Section from '../../layout/section/Section'
import Button from '../../interface/button/Button'

// View: Error

const Errors = props => {
  let urlParams = null
  if (window.location) {
    urlParams = new URLSearchParams(window.location.search)
  }

  const message = props.message || urlParams?.get('message')
  const title = props.title || urlParams?.get('title')
  const code = props.code || urlParams?.get('code')

  let errorCode = null
  if (code) {
    errorCode = <Link to='/error-codes'>{code} - {message}</Link>
  }

  return (
    <View name='errors'>
      {/* Header */}
      <Header>
        <h1>{title}</h1>
        {errorCode || (message && <code>{message}</code>)}
      </Header>

      {
        props.children
        || (
          <Section align='center'>
            <p>We apologize for the inconvenience.
              If you need immediate assistance or have any questions,
              please contact our support team at&nbsp;
            <a href='mailto:support@base64.ai' title='Send e-mail to support@base64.ai'>support@base64.ai</a>.
              Thank you for your patience and understanding.
            </p>
            <Button to='/'>Go to homepage</Button>
          </Section>
        )
      }
    </View>
  )
}

Errors.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  code: PropTypes.code,
  children: PropTypes.node
}

Errors.defaultProps = {
  message: '',
  title: ''
}

// Export
export default Errors
