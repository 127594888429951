import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from '../material-icon/MaterialIcon'

class FullscreenDropzone extends Component {
  constructor (props) {
    super(props)

    this.showDropZone = this.showDropZone.bind(this)
    this.hideDropZone = this.hideDropZone.bind(this)
    this.allowDrag = this.allowDrag.bind(this)
    this.handleDrop = this.handleDrop.bind(this)
    this.dragEnter = this.dragEnter.bind(this)

    this.ref = createRef()
  }

  showDropZone () {
    this.ref.current.style.visibility = 'visible'
  }

  dragEnter (e) {
    const hasFileKind = Array.from(e.dataTransfer.items).some(item => item.kind === 'file')
    if (!hasFileKind) {
      return
    }
    this.showDropZone()
  }

  hideDropZone (e) {
    // Only hide the drop zone if the relatedTarget is defined and not contained within the dropzone
    if (!this.ref.current.contains(e.relatedTarget)) {
      this.ref.current.style.visibility = 'hidden'
    }
  }

  allowDrag (e) {
    // currently frontend allows upload of all document types
    e.dataTransfer.dropEffect = 'copy'
    e.preventDefault()
    e.stopPropagation()
  }

  handleDrop (e) {
    e.preventDefault()
    e.stopPropagation()
    this.hideDropZone(e)

    const files = Array.from(e.dataTransfer.files) // Use dataTransfer.files directly
    this.props.handleFiles && this.props.handleFiles(files)
  }

  componentDidMount () {
    window.addEventListener('dragenter', this.dragEnter)

    this.ref.current.addEventListener('dragenter', this.allowDrag)
    this.ref.current.addEventListener('dragover', this.allowDrag)
    this.ref.current.addEventListener('dragleave', this.hideDropZone)
    this.ref.current.addEventListener('drop', this.handleDrop)
  }

  componentWillUnmount () {
    window.removeEventListener('dragenter', this.dragEnter)

    this.ref.current.removeEventListener('dragenter', this.allowDrag)
    this.ref.current.removeEventListener('dragover', this.allowDrag)
    this.ref.current.removeEventListener('dragleave', this.hideDropZone)
    this.ref.current.removeEventListener('drop', this.handleDrop)
  }

  render () {
    return (
      <div ref={this.ref} className='dropzone-fullscreen'>
        <div className='box-background'>
          <div className='box'>
            <small>
              <p><MaterialIcon name='upload' /></p>
              <b>Drop your files here</b>
            </small>
          </div>
        </div>
      </div>
    )
  }
}

FullscreenDropzone.propTypes = {
  handleFiles: PropTypes.func
}

export default FullscreenDropzone
