// Modules
import PropTypes from 'prop-types'

// Interface: StatusToEmoji
const StatusToEmoji = props => {
  if (props.status === 'success') {
    return <span><span className='validation-sign'>✅</span><b>Success:</b> <span className='message'>{props.message}</span></span>
  } else if (props.status === 'warning') {
    return <span><span className='validation-sign'>⚠️</span><b>Warning:</b> <span className='message'>{props.message}</span></span>
  } else if (props.status === 'error') {
    return <span><span className='validation-sign'>❌</span><b>Error:</b> <span className='message'>{props.message}</span></span>
  } else {
    return <span><span className='validation-sign' style={{ fontSize: '1.2rem' }}>🤖</span><b>Status:</b> <span className='message'>{props.message}</span></span>
  }
}

StatusToEmoji.propTypes = {
  message: PropTypes.string,
  status: PropTypes.string
}

// Export
export default StatusToEmoji
