// Modules
import React from 'react'
import PropTypes from 'prop-types'
import Context from '~/context/global'

import { request } from '~/helpers/request'
import { reCaptcha } from '../../helpers/authentication'

// Interface: ReportIssueButton
class ReportIssueButton extends React.Component {
  static contextType = Context
  reportIssue (image) {
    this.context.displayModal({
      isForbiddenToClickOutside: true,
      title: 'Report issue',
      content: 'Thank you for reporting an issue. We will investigate it quickly. Is there anything else that you want to add?',
      showNote: true,
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: 'Report',
          action: note => {
            reCaptcha('demo', token => {
              request({
                endpoint: '/report',
                body: {
                  token,
                  image,
                  note
                }
              })
            })
          }
        }
      ]
    })
  }

  render () {
    return (
      <a onClick={() => this.reportIssue(this.props.image)} className='report-button button small-button negative'>Report issue</a>
    )
  }
}

ReportIssueButton.propTypes = {
  image: PropTypes.string
}

// Export
export default ReportIssueButton
