// Modules
import PropTypes from 'prop-types'
import {
  Component
} from 'react'
import Checkbox from '../checkbox/Checkbox'

// Interface

// Interface: Modal
class FilterModalContent extends Component {
  static propTypes = {
    selectedFilter: PropTypes.array,
    filterMethods: PropTypes.array,
    onChangeHandler: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedFilter: props.selectedFilter
    }
  }

  renderCheckbox = filter => {
    return (
      <Checkbox
        checked={this.state.selectedFilter.includes(filter)}
        key={filter.value}
        label={filter.label}
        placeholder={filter.label}
        className='filter-checkbox'
        still={true}
        onChange={() => {
          const newFilter = this.state.selectedFilter.includes(filter)
            ? this.state.selectedFilter.filter(f => f !== filter)
            : [...this.state.selectedFilter, filter]
          this.setState({
            selectedFilter: newFilter
          })
          this.props.onChangeHandler(newFilter)
        }}
      />
    )
  }

  render () {
    const {
      filterMethods
    } = this.props

    return (
      <div className='filter-content'>
        <div className='filter-container' title='Filter documents by their status'>
          {filterMethods.map((filter, index) => (
            this.renderCheckbox(filter, index)
          ))}
        </div>
      </div>
    )
  }
}

// Export
export default FilterModalContent
