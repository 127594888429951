// Layout
import View from '~/layout/view/View'
// Content
import DataExtractionHeader from '../../content/data-extraction-header/DataExtractionHeader'
import PartnersSection from '../../content/partners-section/PartnersSection'

import { triggerTrackingEvent } from '../../utilities/tracker'
import CardInfoSection from '../../content/card-info-section/CardInfoSection'
import FAQSection from '../../content/faq-section/FAQSection'

// View: Home
const Home = () => (
  <View name='home'>
    <DataExtractionHeader />
    <a className='homepage-banner-anchor noBorder' href='https://www.youtube.com/watch?v=cD9pqqAHF9Y' rel='noreferrer noopener'>
      <div className='homepage-banner'>
        <p className='first-row'>
          <nobr>See how the Base64.ai </nobr> <nobr>Document Intelligence Platform </nobr> <nobr>is Revolutionizing IDP</nobr>
        </p>
        <div>
          <a className='learn-more'>Watch video here</a>
        </div>
      </div>
    </a>
    <CardInfoSection />
    <PartnersSection
      showCompliance={true}
      showMemberships={true}
      showFeatured={true}
      showButton={true}
      action={() => {
        triggerTrackingEvent('home-partner-logo')
      }}
    />
    <FAQSection />
  </View>
)

// Export
export default Home
