import { forwardRef } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const MaterialIcon = ({ name, className = '', onClick, title, style, htmlRef }) => {
  const combinedStyle = {
    ...style || {},
    cursor: onClick ? 'pointer' : undefined
  }

  let content = name
  if (name === 'warning') {
    content = (
      <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px'>
        <path stroke='#FF822D' fill='#FF822D' d='M4.47 19h15.06L12 5.99 4.47 19zM13 18h-2v-2h2v2zm0-4h-2v-4h2v4z' />
        <path stroke='#000000' strokeWidth={0} d='M1 19H4.47zM11 16h2v2h-2zm0-6h2v4h-2z' />
      </svg>
    )
  }

  return (
    <span
      className={clsx('material-symbols-outlined m-icon', className)}
      tabIndex={onClick ? '0' : '-1'}
      title={title || ''}
      style={combinedStyle}
      onClick={onClick}
      ref={htmlRef}
    >
      {content}
    </span>
  )
}

MaterialIcon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object,
  htmlRef: PropTypes.any
}

// export default Material Icon
const MaterialIconWithRef = forwardRef((props, ref) => (
  <MaterialIcon
    htmlRef={ref}
    {...props}
  />
))

MaterialIconWithRef.displayName = 'MaterialIcon'

export default MaterialIconWithRef
