import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MaterialIcon from '../material-icon/MaterialIcon'
import { request } from '~/helpers/request'
import MultiInputTable from '../multi-input-table/MultiInputTable'
import flowSearchTable from '../../data/flowSearchTable'
import Button from '../button/Button'
import Select from 'react-select'
import { getCustomModels } from '../../helpers/getCustomModels'
import flowSearchKeyFields from '../../data/flowSearchKeyFields'
import document from 'global/document'
import Context from '~/context/global'
import { reCaptcha } from '../../helpers/authentication'
import VoiceInput from '../voice/Voice'
import { handleModels } from '../../helpers/handleModels'
import flowSearchOrderByTable from '../../data/flowSearchOrderByTable'
import LoadingCircle from '../loading-circle/LoadingCircle'

const defaultSearchParameters = {
  question: '',
  keyCriteriaValue: [{
    key: {
      label: '',
      value: ''
    },
    criteria: '$eq',
    value: ''
  }],
  orderByValue: [{
    orderBy: {
      label: 'Updated at',
      value: 'meta.updatedAt'
    },
    orderType: 'desc'
  }],
  selectedModelTypes: '',
  pageNumber: 0
}

class FlowSearchInput extends Component {
  static contextType = Context

  static propTypes = {
    models: PropTypes.object,
    getModels: PropTypes.func,
    flow: PropTypes.object,
    flowName: PropTypes.string,
    onSearchStarted: PropTypes.func,
    onSearchCompleted: PropTypes.func,
    onSearchFailed: PropTypes.func,
    onClear: PropTypes.func
  }

  constructor (params) {
    super(params)
    this.state = {
      isSearchTextInputInFocus: false,
      models: [],
      isActive: false,
      isQuestionLoading: false,
      ...defaultSearchParameters
    }
    this.searchInputRef = React.createRef()
    this.dropdownRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.searchNextPage = this.searchNextPage.bind(this)
    this.searchPreviousPage = this.searchPreviousPage.bind(this)
    this.criterias = [
      { label: '=', value: '$eq' },
      { label: 'in', value: '$in' },
      { label: '<=', value: '$lte' },
      { label: '<', value: '$lt' },
      { label: '>=', value: '$gte' },
      { label: '>', value: '$gt' }
    ]

    if (this.props.flow.flowSearch?.indexes?.length) {
      this.props.flow.flowSearch?.indexes.forEach(index => {
        if (flowSearchTable.columns[0].options[0].options.find(s => s.value === index.value)) {
          return
        }
        flowSearchTable.columns[0].options[0].options.push(index)
      })
    }
  }

  handleClickOutside (event) {
    if (
      (this.searchInputRef?.current && !this.searchInputRef.current.contains(event.target))
      && (this.dropdownRef?.current && !this.dropdownRef.current.contains(event.target))
    ) {
      this.setState({
        isDropdownOpen: false
      })
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside, false)
    getCustomModels(this.props.flow, customModelsData => {
      this.props.getModels && this.props.getModels((error, response) => {
        if (!error) {
          this.modelsData = handleModels(response, this.props.flow, customModelsData, { disableFlowFiltering: true })
        }
      })
    })
    if (!this.props.flow?.flowID) {
      return
    }
    const searchParams = this.context.searchParams?.[this.props.flow.flowID]
    if (!searchParams) {
      return
    }
    if (searchParams.question) {
      this.setState({ question: searchParams.question })
    }
    if (searchParams.keyCriteriaValue?.length) {
      this.setState({ keyCriteriaValue: searchParams.keyCriteriaValue })
    } else {
      // fill with flow settings
    }
    if (searchParams.orderByValue?.length) {
      this.setState({ orderByValue: searchParams.orderByValue })
    }
    if (searchParams.selectedModelTypes?.length) {
      this.setState({ selectedModelTypes: searchParams.selectedModelTypes })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside, false)
    if (this.state.question || this.state.keyCriteriaValue?.filter(kcv => kcv.key && kcv.value) || this.state.selectedModelTypes?.length) {
      const { searchParams } = this.context
      searchParams[`${this.props.flow.flowID}`] = {
        question: this.state.question,
        keyCriteriaValue: this.state.keyCriteriaValue?.filter(kcv => kcv.key && kcv.value),
        orderByValue: this.state.orderByValue?.filter(kcv => kcv.orderBy && kcv.orderType),
        selectedModelTypes: this.state.selectedModelTypes
      }
      this.context.setSearchParams(searchParams)
    }
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.setState({ isDropdownOpen: false })
      this.sendQuestion()
    }
  }

  sendQuestion () {
    const { question } = this.state
    if (!question?.trim()?.length) {
      return
    }
    this.setState({ isQuestionLoading: true })
    reCaptcha('demo', token => {
      request({
        method: 'POST',
        endpoint: '/flow/search/parse',
        body: {
          token,
          question
        }
      }, (error, response) => {
        if (!error && response.result) {
          this.parseQuestionResponse(response.result)
        }
        this.setState({ isQuestionLoading: false })
      })
    })
  }

  forceUpdateState () {
    this.setState({
      isDropdownOpen: false
    })
  }

  getModelName (type, modelArray, parent) {
    if (!modelArray) {
      return
    }
    const model = modelArray.find(model => model.path === type || model.value === type)
    if (Array.isArray(model?.options)) {
      return this.getModelName(type, model?.options, model)
    }
    return model?.label || parent?.label
  }

  parseQuestionResponse (response) {
    const modelTypes = []
    const keyCriteriaValue = []
    if (response.documentType) {
      response['document type'] = response.documentType
      delete response.documentType
    }
    const pushModelType = mt => {
      modelTypes.push({
        label: this.getModelName(mt, this.modelsData) || mt,
        value: mt
      })
    }
    if (response['document type']) {
      if (Array.isArray(response['document type'])) {
        response['document type'].forEach(pushModelType)
      } else if (typeof response['document type'] === 'object') {
        Object.keys(response['document type']).forEach(op => {
          if (Array.isArray(response['document type'][op])) {
            response['document type'][op].forEach(pushModelType)
          } else if (typeof response['document type'][op] === 'string') {
            pushModelType(response['document type'][op])
          }
        })
      } else if (typeof response['document type'] === 'string') {
        pushModelType(response['document type'])
      }
      modelTypes.forEach(mt => {
        // todo
        if (mt.value === mt.label) {
          mt.value = '*' + mt.value
        }
      })
    }
    Object.keys(response).filter(ff => ff !== 'document type').forEach(fieldKey => {
      const options = flowSearchKeyFields.map(fskf => fskf.options).flat(1)
      const key = options.find(o => o.value === fieldKey) || { label: fieldKey, value: fieldKey }
      if (typeof response[fieldKey] !== 'object') {
        keyCriteriaValue.push({
          key,
          criteria: '$eq',
          value: response[fieldKey]
        })
        return
      }
      Object.keys(response[fieldKey]).forEach(fk => {
        const criteria = fk
        keyCriteriaValue.push({
          key,
          criteria,
          value: response[fieldKey][criteria] || ''
        })
      })
    })
    this.setState({
      keyCriteriaValue,
      selectedModelTypes: modelTypes
    }, () => {
      this.forceUpdateState()
      this.searchRequest()
    })
  }

  clearSearchParameters () {
    this.setState(defaultSearchParameters
      , () => {
        this.forceUpdateState()
        this.props.onClear && this.props.onClear()
        this.setState({
          isActive: false
        })
      })
  }

  searchNextPage () {
    // 9975 / 25 is soft limit of elasticsearch
    this.setState({ pageNumber: Math.min(this.state.pageNumber + 1, 9975 / 25) }, () => {
      this.searchRequest()
    })
  }

  searchPreviousPage () {
    this.setState({ pageNumber: Math.max(this.state.pageNumber - 1, 0) }, () => {
      this.searchRequest()
    })
  }

  searchRequest () {
    const { keyCriteriaValue, selectedModelTypes, orderByValue } = this.state
    const body = {}
    if (selectedModelTypes?.length) {
      selectedModelTypes.forEach(mt => {
        body.modelTypes = body.modelTypes || []
        body.modelTypes.push(mt.value)
      })
    }
    if (keyCriteriaValue?.filter(kcv => kcv.key && kcv.value).length) {
      keyCriteriaValue?.filter(kcv => kcv.key?.value && kcv.value).forEach(kcv => {
        if (kcv.key.value === 'ocrText') {
          body.ocrText = kcv.value
          return
        }
        const obj = {
          key: kcv.key.value,
          operator: kcv.criteria,
          query: kcv.value
        }
        if (typeof kcv.value === 'object' && kcv.value.value !== undefined) {
          obj.query = kcv.value.value
        }
        body.fields = body.fields || []
        body.fields.push(obj)
      })
    }
    if (orderByValue?.filter(kvc => kvc.orderBy).length) {
      body.orderBy = orderByValue?.map(kvc => ({ key: kvc.orderBy.value, order: kvc.orderType }))
    }
    if (this.state.question) {
      body.question = this.state.question
    }
    if (!Object.keys(body).length) {
      return
    }
    this.props.onSearchStarted && this.props.onSearchStarted()
    this.setState({
      isActive: true
    })

    reCaptcha('demo', token => {
      body.token = token
      request({
        method: 'POST',
        endpoint: `/flow/search/${this.props.flow?.flowID}?pageNumber=${this.state.pageNumber}`,
        body
      }, (error, response) => {
        this.setState({
          isDropdownOpen: false
        })
        if (error) {
          this.props.onSearchFailed && this.props.onSearchFailed(response)
        } else {
          this.props.onSearchCompleted && this.props.onSearchCompleted(response)
        }
      })
    })
  }

  render () {
    const { keyCriteriaValue, orderByValue } = this.state
    return (
      <div className='flow-search-input-container'>
        <div
          title='Search your documents by selected criteria'
          ref={this.searchInputRef}
          className={'search-input-container ' + (this.state.isDropdownOpen || this.state.isSearchTextInputInFocus ? ' focus' : '') + (this.state.isActive ? ' active' : '')}
        >
          <MaterialIcon
            name='search'
            className='search-input-element'
            onClick={() => {
              this.sendQuestion()
            }}
          />
          <VoiceInput
            className='flow-search-voice-input'
            onResult={voiceText => {
              this.setState({
                question: voiceText
              }, this.sendQuestion)
            }}
          />
          <input
            id='search-input-element'
            className='search-input-element'
            type='text'
            placeholder={'Search like "Show me driver licenses that are expired"'}
            onBlur={() => this.setState({ isSearchTextInputInFocus: false })}
            value={this.state.question || ''}
            onChange={event => {
              this.setState({
                question: event.target.value
              })
            }}
            onKeyDown={this.handleKeyDown}
          />
          <MaterialIcon
            className='search-input-element'
            name={this.state.isDropdownOpen ? 'expand_less' : 'expand_more'}
            onClick={() => {
              this.setState({ isDropdownOpen: !this.state.isDropdownOpen })
            }}
          />
        </div>
        {
          this.state.isDropdownOpen
          && (
            <div
              ref={this.dropdownRef}
              className='dropdown-container'
              style={{
                width: this.searchInputRef.current.offsetWidth,
                display: 'unset'
              }}
            >
              {this.state.isQuestionLoading
                ? <div className='question-loading'> <LoadingCircle /> </div>
                : (
                    <div style={{
                      padding: '1rem'
                    }}
                    >
                      <div style={{ marginBottom: '0.5rem' }}>
                        <div className='model-types-container'>
                          <span className='model-types-header'>Model types</span>
                        </div>
                        <Select
                          isMulti
                          classNamePrefix='react-select'
                          className='react-select'
                          value={this.state.selectedModelTypes || []}
                          options={this.modelsData}
                          closeMenuOnSelect={false}
                          placeholder='Select or enter document types...'
                          noOptionsMessage={() => 'No document type found'}
                          filterOption={({
                            label,
                            value
                          }, query) => {
                            if (!query) {
                              return true
                            }
                            const modelName = label.toLowerCase()
                            const modelType = value.toLowerCase()
                            query = query.toLowerCase()
                            for (const part of query.split(' ').filter(x => x?.trim())) {
                              if (!modelName.includes(part) && !modelType.includes(part)) {
                                return false
                              }
                            }
                            return true
                          }}
                          onChange={changed => {
                            // const modelTypes = changed.filter(type => type.value).map(type => type.value)
                            this.setState({
                              selectedModelTypes: changed
                            })
                          }}
                        />
                      </div>
                      <MultiInputTable
                        tableStructure={flowSearchTable}
                        data={keyCriteriaValue}
                        onChange={data => {
                          this.setState({
                            keyCriteriaValue: data
                          })
                        }}
                      />
                      <br />
                      <MultiInputTable
                        tableStructure={flowSearchOrderByTable}
                        data={orderByValue}
                        onChange={data => {
                          this.setState({
                            orderByValue: data
                          })
                        }}
                      />
                      <div className='search-button'>
                        <Button
                          size='small'
                          className='secondary-cta'
                          onClick={() => {
                            this.clearSearchParameters()
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          size='small'
                          id='searchButton'
                          className='right'
                          onClick={() => {
                            this.setState({ pageNumber: 0, isDropdownOpen: false }, () => {
                              this.searchRequest()
                            })
                          }}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                  )}

            </div>
          )
        }
      </div>
    )
  }
}

export default FlowSearchInput
