// Modules
import PropTypes from 'prop-types'
import {
  Component,
  forwardRef
} from 'react'
import DocumentUploader from '../document-uploader/DocumentUploader'
import DemoSampleView from '../demo-sample-view/DemoSampleView'

// Interface: UploadDocumentModalContent
class UploadDocumentModalContent extends Component {
  static propTypes = {
    allowRender: PropTypes.bool,
    selectedModels: PropTypes.array,
    pickSample: PropTypes.func,
    allModels: PropTypes.array,
    handleFiles: PropTypes.func,
    flow: PropTypes.object,
    isFileUploaded: PropTypes.bool,
    modalRef: PropTypes.object,
    onChangeSelect: PropTypes.func,
    isRenderSelect: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedModels: props.selectedModels,
      flow: props.flow
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.selectedModels !== this.props.selectedModels) {
      this.setState({ selectedModels: this.props.selectedModels })
    }
  }

  render () {
    return (
      <div>
        { this.props.isRenderSelect
          ? (
              <DocumentUploader
                selectedModels={this.state.selectedModels}
                allModels={this.props.allModels}
                handleFiles={this.props.handleFiles}
                flow={this.state.flow}
                ref={this.props.modalRef}
                dragAndDropButton={false}
                onChange={(filter, modelTitleNameString) => {
                  this.props.onChangeSelect(filter, modelTitleNameString)
                  this.setState({ selectedModels: filter })
                }}
              />
            )
          : null}
        {this.props.allowRender
        && (
          <div>
            <DemoSampleView models={this.state.selectedModels.map(x => x.value)} pickSample={this.props.pickSample} />
          </div>
        )}
      </div>
    )
  }
}

// Export
const UploadDocumentModalContentWithRef = forwardRef((props, ref) => (
  <UploadDocumentModalContent
    modalRef={ref}
    {...props}
  />
))

UploadDocumentModalContentWithRef.displayName = 'UploadDocumentModalContent'

export default UploadDocumentModalContentWithRef
