// Modules
import {
  Document,
  Font,
  Image as PDFImage,
  Link as PDFLink,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View as PDFView
} from '@react-pdf/renderer'
import { Parser as Json2csv } from 'json2csv'
import PropTypes from 'prop-types'
import React, {
  Component,
  createRef
} from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
/* SSR-IGNORE */ import 'react-day-picker/lib/style.css'

import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { v4 as uuid } from 'uuid'
import document from 'global/document'
import window from 'global/window'

// Context
import Context from '~/context/global'
import { isProduction, isWorkingOffline } from '~/context/environment'

// Helpers
import { request } from '~/helpers/request'
import { reCaptcha } from '~/helpers/authentication'
import { getBase64 } from '~/helpers/file'

// Interface
import Button from '~/interface/button/Button'
import DynamicTitle from '~/interface/dynamic-title/DynamicTitle'
import FileInput from '~/interface/file-input/FileInput'
import Link from '~/interface/link/Link'
import Loading from '~/interface/loading/Loading'
/* SSR-IGNORE */ import ReactJson from 'react-json-view'
import PdfViewer from '~/interface/pdf-viewer/PdfViewer'
import ValidationSign from '~/interface/validation-sign/ValidationSign'
import Section from '~/layout/section/Section'
import ReportIssueButton from '~/interface/report-issue-button/ReportIssueButton'
// Layout
import View from '~/layout/view/View'

// Utilities
import Textarea from '../../interface/textarea/Textarea'
import { triggerTrackingEvent } from '../../utilities/tracker'
import acceptedFileFormats from '../../data/acceptedFileFormats'
import Header from '../../layout/header/Header'
import { capitalizeFirstLetter, toCamelCase } from '../../utilities/format'
import Checkbox from '../../interface/checkbox/Checkbox'
import CustomSurvey from '../../interface/custom-survey/CustomSurvey'
import DownloadLink from '../../interface/download-link/DownloadLink'
import RemoveButton from '../../interface/remove-button/RemoveButton'
import { renderSignature } from './RenderSignature.jsx'
import DocumentUploader from '../../interface/document-uploader/DocumentUploader'
import DemoSampleView from '../../interface/demo-sample-view/DemoSampleView'
import MaterialIcon from '../../interface/material-icon/MaterialIcon'
import QuestionBox from '../../interface/question-box/QuestionBox'
import { handleModels } from '../../helpers/handleModels'
import { clone } from '../../utilities/object.js'
import FormatLLMResponseWithSources from '../../utilities/modifiers'
import { Messages } from '~/constants'
import { NavigationPrompt } from '../../interface/navigation-prompt/NavigationPrompt.jsx'

// View: DocumentProcessingDemo
class DocumentProcessingDemo extends Component {
  static contextType = Context

  static propTypes = {
    sloganText: PropTypes.node,
    introText: PropTypes.node,
    descriptionText: PropTypes.node,
    dropText: PropTypes.node,
    hideHeader: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    isHITL: PropTypes.bool,
    formUuid: PropTypes.string,
    resultUuid: PropTypes.object,
    user: PropTypes.object,
    models: PropTypes.array,
    getModels: PropTypes.func
  }

  static defaultProps = {
    sloganText: 'Document Understanding AI',
    introText: 'Get work done faster',
    descriptionText:
    <>
      Base64.ai automatically detects document types and extracts text, tables, photos, and signatures from all types of documents.
      Start typing below to explore available document types and see sample documents.
      When ready, use the <Link to='/flow'>Flow Manager</Link> to automate your document processing.
    </>,
    models: []
  }

  constructor (props) {
    super(props)

    this.state = {
      origin: null,
      filter: [],
      responses: {},
      zoomedItem: null,
      allModels: [],
      selectedModels: [],
      noCodeConfiguration: null,
      emailAddresses: (this.props.user && this.props.user.email) || '',
      isSubmitted: true,
      renderedSamples: null,
      editableKey: '',
      editableValue: '',
      hitlKeyValuePair: { key: '', value: '' },
      flow: {},
      fileName: '',
      hitlImageIndex: 0,
      secondsElapsed: 0,
      showLoadingScreen: false,
      rejectionReason: 'Document is unreadable.',
      queryDocument: {},
      questionAnswerResponses: {}
    }

    this.configuration = {
      modelsData: [],
      whiteLabelModelsData: [],
      displayTypes: [
        {
          key: 'visual',
          title: 'Visual result'
        },
        {
          key: 'api',
          title: 'API response'
        }
      ],
      rejectionReasonList: [
        'Document is unreadable.',
        'Document type is not valid for this flow.'
      ],
      pdfOptions: {
        thumbnail: '#toolbar=0&navpanes=0&scrollbar=0&view=fitH',
        full: ''
      }
    }
    this.output = createRef()
    this.setAllModels = this.setAllModels.bind(this)
    this.handleFiles = this.handleFiles.bind(this)
    this.indexFiles = this.indexFiles.bind(this)
    this.processFiles = this.processFiles.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    this.updateResponse = this.updateResponse.bind(this)
    this.clearResponses = this.clearResponses.bind(this)
    this.renderCtas = this.renderCtas.bind(this)
    this.renderOcr = this.renderOcr.bind(this)
    this.renderOfac = this.renderOfac.bind(this)
    this.renderPreview = this.renderPreview.bind(this)
    this.renderValue = this.renderValue.bind(this)
    this.renderTable = this.renderTable.bind(this)
    this.renderAudio = this.renderAudio.bind(this)
    this.pickSample = this.pickSample.bind(this)
    this.toShortName = this.toShortName.bind(this)
    this.handlePastedFiles = this.handlePastedFiles.bind(this)
    this.updateCopyFlag = this.updateCopyFlag.bind(this)
    this.arrayToTable = this.arrayToTable.bind(this)
    this.deleteResponse = this.deleteResponse.bind(this)
    this.deleteResponsesResult = this.deleteResponsesResult.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.submitResponse = this.submitResponse.bind(this)
    this.updateResultKey = this.updateResultKey.bind(this)
    this.updateResultValue = this.updateResultValue.bind(this)
    this.renderEditableField = this.renderEditableField.bind(this)
    this.formatDate = this.formatDate.bind(this)
    this.reprocessDocument = this.reprocessDocument.bind(this)
    this.loadConfiguration = this.loadConfiguration.bind(this)
    this.loadResponse = this.loadResponse.bind(this)
    this.csvExport = this.csvExport.bind(this)
    this.csvExportAll = this.csvExportAll.bind(this)
    this.pdfExport = this.pdfExport.bind(this)
    this.unauthorizedExport = this.unauthorizedExport.bind(this)
    this.renderFileInput = this.renderFileInput.bind(this)
    this.displayEditableKeyModal = this.displayEditableKeyModal.bind(this)
    this.displayEditableValueModal = this.displayEditableValueModal.bind(this)
    this.toCamelCase = this.toCamelCase.bind(this)
    this.addNewField = this.addNewField.bind(this)
    this.filterModelByFlowSettings = this.filterModelByFlowSettings.bind(this)
    this.renderSignature = renderSignature.bind(this)
    this.renderQuestionAnswerBox = this.renderQuestionAnswerBox.bind(this)
    this.queryDocumentWithQuestion = this.queryDocumentWithQuestion.bind(this)
  }

  componentDidMount () {
    this.setState({ origin: window.location.origin })
    this.setAllModels()
    document.addEventListener('paste', this.handlePastedFiles)
    if (this.props.isEmbedded) {
      if (this.props.formUuid) {
        this.loadConfiguration()
      } else {
        this.context.displayModal({
          title: 'Say goodbye to manual data entry',
          isWide: true,
          content:
          <>
            <p style={{ marginBottom: '1rem' }}>
              Welcome to our embedded document processing tool<br />that requires no coding for integration.
            </p>
            <p style={{ marginBottom: '1rem' }}>
              1. Share your personalized Base64.ai URL with your clients<br />
              2. Ask your clients to upload documents or take a picture<br />
              3. Your clients will review the results and may make corrections<br />
              4. You will receive the original file & data extraction results<br />automatically in your system or email
            </p>
            <p>
              Speed up processing time and cut costs with Base64.ai!
            </p>
          </>,
          options: [
            {
              content: 'Try now'
            }
          ]
        })
      }
    }
    if (this.props.isHITL) {
      if (this.props.resultUuid) {
        this.loadResponse()
      }
      this.interval = setInterval(() => {
        this.tickTimer()
      }, 1000)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        emailAddresses: (this.props.user && this.props.user.email) || ''
      })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('paste', this.handlePastedFiles)
    if (this.props.isHITL) {
      clearInterval(this.interval)
    }
  }

  tickTimer () {
    this.setState({ secondsElapsed: this.state.secondsElapsed + 1 })
  }

  setAllModels () {
    const promises = []
    if (this.props.getModels) {
      promises.push(new Promise(resolve => {
        this.props.getModels((error, response) => {
          if (!error) {
            resolve(response)
          }
        })
      }))
    }
    Promise.all(promises).then(([response]) => {
      const modelsData = handleModels(response)
      const selectedModels = this.props.models.map(model => ({
        label: response[model],
        value: model
      }))
      this.setState({ allModels: modelsData, selectedModels })
    })
  }

  addNewField (result, customKey, customValue) {
    this.setState({
      customKey: customKey || '',
      customValue: customValue || ''
    }, () => {
      this.context.displayModal({
        title: 'Add a new field',
        isHITL: true,
        content: (
          <>
            <div className='new-field-container' style={{ display: 'flex', flexDirection: 'column' }}>
              <input
                type='text'
                placeholder='Field name'
                value={this.state.customKey}
                onChange={e => {
                  this.setState({
                    customKey: e.target.value
                  }, () => {
                    this.addNewField(result, this.state.customKey, this.state.customValue)
                  })
                }}
              />
              <textarea
                type='text'
                placeholder='Value (optional)'
                style={{ marginTop: '1rem', minHeight: '5rem' }}
                value={this.state.customValue}
                onChange={e => {
                  this.setState({
                    customValue: e.target.value
                  }, () => {
                    this.addNewField(result, this.state.customKey, this.state.customValue)
                  })
                }}
              />
            </div>
          </>
        ),
        options: [
          {
            content: 'Cancel',
            intent: 'negative'
          },
          {
            content: 'Add',
            action: () => {
              const fieldName = toCamelCase(this.state.customKey || '')
              if (!fieldName) {
                return alert('You need to enter a field name')
              }
              const lowerCaseFieldName = fieldName.toLowerCase()
              if (Object.keys(result.fields).filter(key => key.toLowerCase() === lowerCaseFieldName && !result.fields[key].isDeleted).length) {
                return alert('The entered field name is in use. Please pick another field name or change the original field.')
              }
              result.fields[fieldName] = {
                key: capitalizeFirstLetter(this.state.customKey),
                value: this.state.customValue,
                originalValue: this.state.customValue,
                confidence: 1,
                isValid: true
              }
            }
          }
        ],
        isClosable: true
      })
    })
  }

  showRejectionReason (reviewer, reason) {
    this.context.displayModal({
      title: 'Rejection reason',
      content: (
        <div>
          <p><b>{`This result is rejected by ${reviewer}. Reason: `}</b></p>
          <p style={{ marginLeft: '1rem' }}>{reason}</p>
        </div>
      ),
      options: [{
        content: 'OK',
        intent: 'positive'
      }],
      isClosable: true
    })
  }

  removeFeatureByKey (responseUuid, resultUuid, key, index) {
    const responses = this.state.responses
    const result = responses[responseUuid].results.find(r => r.uuid === resultUuid)
    result.features = result.features || {}
    if (!['faces', 'signatures', 'glares'].includes(key)) {
      if (key === 'textToSpeech') {
        result.features[`${key}`].isDeleted = true
      }
      return
    }
    if (result.features[`${key}`] && result.features[`${key}`].length > index) {
      result.features[`${key}`][index].isDeleted = true
    }
    this.updateResponse(responseUuid, responses[responseUuid])
  }

  editPropertiesByKey (responseUuid, resultUuid, key, state) {
    const responses = this.state.responses
    const result = responses[responseUuid].results.find(r => r.uuid === resultUuid)
    result.features = result.features || {}
    result.features.properties = result.features.properties || {}
    if (!['isGlareFree', 'isWatermarkFree', 'isInFocus'].includes(key)) {
      return
    }
    if (result.features.properties[`original${capitalizeFirstLetter(key)}`] === undefined) {
      result.features.properties[`original${capitalizeFirstLetter(key)}`] = result.features.properties[`${key}`]
    }
    result.features.properties[`${key}`] = state.checked
    this.updateResponse(responseUuid, responses[responseUuid])
  }

  loadResponse () {
    request({
      endpoint: '/result/' + this.props.resultUuid,
      method: 'GET'
    }, (error, response) => {
      if (error) {
        alert('Unable to load response: ' + error)
        if (this.props.user?.email) {
          return this.context.redirect('/flow')
        }
        this.context.redirect('/login')
      } else {
        this.setState({
          flow: response.flow,
          fileName: response.fileName
        })
        const webResponse = {
          done: true,
          success: true,
          image: response.file,
          results: Array.isArray(response.result) ? response.result : [response.result],
          flow: response.flow,
          name: response.fileName,
          hitlStatus: response.hitlStatus,
          rejectionReason: response.rejectionReason,
          createdAt: response.createdAt,
          reviewedBy: response.reviewedBy,
          display: 'visual',
          uuid: this.props.resultUuid
        }
        webResponse.originalResults = clone(webResponse.results)
        this.updateResponse(this.props.resultUuid, webResponse)
      }
    }, error => {
      alert('Unable to load response: ' + error)
      this.context.redirect('/')
    })
  }

  loadConfiguration () {
    request({
      endpoint: '/no-code-form/config/' + this.props.formUuid
    }, (error, results) => {
      if (error) {
        alert('Unable to load configuration: ' + error)
        this.context.redirect('/')
      } else {
        this.setState({
          noCodeConfiguration: results
        })
      }
    }, error => {
      alert('Unable to load configuration: ' + error)
      this.context.redirect('/')
    })
  }

  handleFiles (accepted, rejected, event) {
    accepted = accepted.map(file => ({
      name: file.name,
      uuid: uuid(),
      source: file
    }))
    rejected = rejected.map(file => ({
      name: file.name,
      uuid: uuid(),
      source: file
    }))
    // windows fix: move rejeceted heic to accepted
    const rejectedHeic = rejected.filter(file => file?.source?.file?.name?.match(/\.hei[cf]$/i))
    if (rejectedHeic.length) {
      // handle a multiple file drop event convert FileList to Array
      const eventFiles = event?.dataTransfer?.files || event?.target?.files || []
      for (const file of eventFiles) {
        if (file?.name?.match(/\.hei[cf]$/i)) {
          accepted.push({
            name: file.name,
            uuid: uuid(),
            source: file
          })
          rejected = rejected.filter(f => f?.source?.file?.name !== file.name)
        }
      }
    }
    // Index
    this.indexFiles(accepted)
    this.indexFiles(rejected, 'File type is not supported')
    // Process
    this.processFiles(accepted)
  }

  indexFiles (files, error = false) {
    const responses = this.state.responses
    files.forEach(file => {
      if (!file.name) {
        return
      }
      const fileObject = {
        name: this.toShortName(file.name),
        uuid: file.uuid,
        type: file.source.type,
        display: 'visual',
        done: !!error,
        error
      }
      if (file.fileUrl) {
        fileObject.fileUrl = file.fileUrl

        if (file.fileUrl.endsWith('/static/content/features/data-extraction/models//1.png')) {
          fileObject.name += '\n(Segmentation is automatically enabled for this demo)'
        }
      }
      responses[file.uuid] = fileObject
    })
    this.setState({ responses })
  }

  processFiles (files, url) {
    files.forEach(file => {
      if (file.done) {
        return
      }
      getBase64(file, async (error, imageBase64) => {
        const isHeic = file.name.toLowerCase().match(/\.(hei[cf])$/i)
        if (!this.props.isHITL && imageBase64 && imageBase64.startsWith('data:;')) {
          if (file.name.toLowerCase().endsWith('.msg')) {
            imageBase64 = imageBase64.replace('data:;', 'data:application/vnd.ms-outlook;')
          } else if (isHeic) {
            imageBase64 = imageBase64.replace('data:;', `data:image/${isHeic[1]};`)
          } else {
            error = true
          }
        }
        if (error && !this.props.isHITL) {
          this.updateResponse(file.uuid, {
            done: true,
            error: 'Failed to convert file to base64!'
          })
        } else {
          if (!this.props.isHITL) {
            await this.updateResponse(file.uuid, { image: imageBase64 })
          }
          const modelTypes = file.modelTypes || this.props.models
          this.uploadFile(imageBase64, url, modelTypes, file.name, (error, results) => {
            if (error) {
              this.updateResponse(file.uuid, {
                done: true,
                success: false,
                error: error.toString()
              })
            } else {
              if (this.props.isEmbedded) {
                this.setState({ isSubmitted: false })
                const noCodeAcceptedTypes = (this.state.noCodeConfiguration && this.state.noCodeConfiguration.acceptedTypes) || []
                results.forEach(result => {
                  let isAccepted = noCodeAcceptedTypes.length === 0
                  noCodeAcceptedTypes.forEach(type => {
                    if (result.model.type.includes(type)) {
                      isAccepted = true
                    }
                  })
                  if (!isAccepted) {
                    throw new Error((this.state.noCodeConfiguration && this.state.noCodeConfiguration.errorMessage)
                      || `Unexpected document type ${result.model.name}, expected: ${noCodeAcceptedTypes.join(', ')}`)
                  }
                })
              }
              this.updateResponse(file.uuid, {
                done: true,
                success: true,
                originalResults: clone(results),
                results
              })
              if (!this.props.isEmbedded && window.scrollY < 300) {
                window.scrollBy({
                  top: this.output.current.getBoundingClientRect().top - 80,
                  behavior: 'smooth'
                })
              }
            }
          }, error => {
            this.updateResponse(file.uuid, {
              done: true,
              success: false,
              error: error.toString()
            })
          })
        }
      })
    })
  }

  uploadFile (document, url, modelTypes, fileName, callback, errorHandler) {
    /*
    modelTypes = modelTypes.filter(model =>
      model !== 'mrz' && model !== 'pdf417'
    )
    */
    triggerTrackingEvent('demo-completed-document-processing')
    const body = {
      ...(modelTypes.length > 0 ? { modelTypes } : {})
    }
    let endpoint = '/scan'
    body.originalFileName = fileName
    if (url) {
      body.url = url
      if (url.endsWith('/static/content/features/data-extraction/models//1.png')) {
        body.settings = {
          useSegmentation: true
        }
      }
    } else if (document) {
      body.document = document
    }
    if (this.props.isHITL) {
      endpoint = endpoint + '/hitl/' + this.props.resultUuid
    } else {
      body.noCodeFormUuid = this.props.formUuid
    }
    reCaptcha('demo', token => {
      body.token = token
      request({
        endpoint,
        body
      }, callback, errorHandler)
    })
  }

  reprocessDocument (responseUuid, filterValue, options) {
    const response = this.state.responses[responseUuid]
    if ((filterValue || '').toLowerCase() === 'unrecognized') {
      options = options || {}
      options.notReprocess = { label: 'Unrecognized', value: 'unrecognized' }
    }
    if (!options?.notReprocess) {
      delete response.success
      delete response.error
      response.done = false
    } else {
      const result = options.result
      result.model = {
        name: options.notReprocess.label,
        type: options.notReprocess.value,
        originalType: result.model.type,
        originalName: result.model.name
      }
    }
    response.modelTypes = [filterValue]
    this.setState({
      responses: this.state.responses
    })
    if (!options?.notReprocess) {
      this.processFiles(Object.values(this.state.responses), response.fileUrl)
    }
  }

  async updateResponse (uuid, update) {
    return new Promise(resolve => {
      const responses = this.state.responses
      responses[uuid] = {
        ...responses[uuid],
        ...update
      }
      this.setState({
        responses
      }, () => {
        resolve()
      })
    })
  }

  clearResponses () {
    this.setState({
      responses: {},
      queryDocument: {},
      questionAnswerResponses: {}
    })
  }

  deleteResponse (responseUuid) {
    const responses = this.state.responses
    delete responses[responseUuid]
    const queryDocument = this.state.queryDocument
    delete queryDocument[responseUuid]
    const questionAnswerResponses = this.state.questionAnswerResponses
    delete questionAnswerResponses[responseUuid]
    this.setState({
      responses,
      queryDocument,
      questionAnswerResponses
    })
  }

  deleteResponsesResult (responseUuid, resultUuid) {
    // console.log('responseUuid', responseUuid, 'resultUuid', resultUuid)
    const responses = this.state.responses
    responses[responseUuid].results.forEach(r => {
      if (r.uuid === resultUuid) {
        r.isDeleted = true
      }
    })
    this.updateResponse(responseUuid, responses[responseUuid])
  }

  addEmptyResultToResponse (responseUuid) {
    const responses = this.state.responses
    const emptyResult = {
      model: responses[responseUuid].results[0].model,
      fields: {},
      features: {
        creditsSpent: responses[responseUuid].results[0]?.features?.creditsSpent,
        properties: responses[responseUuid].results[0]?.features?.properties,
        signatures: responses[responseUuid].results[0]?.features?.signatures,
        faces: responses[responseUuid].results[0]?.features?.faces,
        glares: responses[responseUuid].results[0]?.features?.glares
      },
      uuid: uuid()
    }
    responses[responseUuid].results.push(clone(emptyResult))
    this.updateResponse(responseUuid, responses[responseUuid])
  }

  removeDeletedFieldsAndFeaturesFromResponse = validResults => {
    validResults.forEach(rr => {
      Object.keys(rr.fields).map(fk => {
        if (rr.fields[fk].isDeleted === true) {
          delete rr.fields[fk]
        }
      })
      Object.keys(rr.features).filter(it => ['faces', 'signatures', 'glares'].includes(it)).map(fk => {
        rr.features[fk].filter(f => f.isDeleted === true).map((f, fInd) => {
          delete rr.features[fk][fInd]
          if (!rr.features[fk].filter(f => f)?.length) {
            delete rr.features[fk]
          }
        })
      })
      Object.keys(rr.features.properties).filter(it => ['isGlareFree', 'isInFocus', 'isWatermarkFree'].includes(it)).map(fk => {
        const originalKey = `original${capitalizeFirstLetter(fk)}`
        if (rr.features.properties[originalKey] !== undefined) {
          delete rr.features.properties[originalKey]
        }
      })
    })
  }

  submitResponse (responseUuid, options) {
    const changedItems = []
    const validResults = this.state.responses[responseUuid].results.filter(r => !r.isDeleted)

    validResults.forEach(rr => {
      Object.keys(rr.fields).map(fk => {
        if (rr.fields[fk].originalValue !== undefined) {
          changedItems.push(rr.fields[fk])
        }
        if (rr.fields[fk].originalKey !== undefined) {
          changedItems.push(rr.fields[fk])
        }
        if (rr.fields[fk].type === 'custom') {
          changedItems.push({
            key: rr.fields[fk].key,
            value: rr.fields[fk].value,
            originalValue: '(new field)'
          })
        }
        if (rr.fields[fk].isDeleted === true) {
          changedItems.push({
            key: rr.fields[fk].key,
            value: rr.fields[fk].value,
            originalValue: '(deleted)'
          })
        }
      })

      Object.keys(rr.features).filter(it => ['faces', 'signatures', 'glares'].includes(it)).map(fk => {
        rr.features[fk].filter(f => f.isDeleted === true).map((f, fInd) => {
          changedItems.push({
            key: fk + '-' + (fInd + 1),
            originalValue: '(deleted)'
          })
        })
      })

      Object.keys(rr.features.properties).filter(it => ['isGlareFree', 'isInFocus', 'isWatermarkFree'].includes(it)).map(fk => {
        const originalValue = rr.features.properties[`original${capitalizeFirstLetter(fk)}`]
        if (originalValue !== undefined && originalValue !== rr.features.properties[fk]) {
          changedItems.push({
            key: fk,
            value: rr.features.properties[fk] ? 'checked' : 'unchecked',
            originalValue: originalValue ? 'checked' : 'unchecked'
          })
        }
      })

      if (rr.model?.originalName !== undefined || rr.model?.originalType !== undefined) {
        changedItems.push({
          key: 'Model name',
          originalValue: rr.model.originalName,
          value: rr.model.name
        })
        changedItems.push({
          key: 'Model type',
          originalValue: rr.model.originalType,
          value: rr.model.type
        })
      }
    })

    this.context.displayModal({
      isForbiddenToClickOutside: true,
      title: 'Confirmation',
      isWide: true,
      content: (
        <div>
          {options?.email
            ? <p>Do you want to send the results via email?</p>
            : <p>Do you want to {options?.status === 'rejected' ? 'reject' : 'approve'} this document{(changedItems.length && (!options?.status || options?.status === 'approved')) ? ` with ${changedItems.length} modification${changedItems.length > 1 ? 's' : ''}` : ''}?</p>}
          { (!options?.status || options?.status === 'approved')
            ? (
                <ul style={{
                  textAlign: 'left',
                  marginTop: '1rem',
                  listStyle: 'auto'
                }}
                >
                  {changedItems.map((ci, i) => (
                    <li key={i} style={{ marginTop: '0.5rem' }}>
                      {ci.originalKey && <><b>{ci.originalKey} (key)</b> &rarr; {ci.key}<br /></>}
                      {ci.originalValue === '(deleted)'
                        ? <><b>{ci.key}:</b> <i>(deleted)</i></>
                        : <><b>{ci.key}:</b> {ci.originalValue || <i>(Empty)</i>} &rarr; {ci.value || <i>(Empty)</i>}</>}
                    </li>
                  )
                  )}
                </ul>
              )
            : options?.status === 'rejected'
            && (
              <CustomSurvey
                questionnaire={this.configuration.rejectionReasonList}
                selectedItem={0}
                isOtherAvailable={true}
                otherPlaceHolder='You can specify your rejection reason...'
                onChangeHandle={(index, rejectionReason) => {
                  this.setState({ rejectionReason })
                }}
              />
            )}
        </div>
      ),
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: 'Yes',
          action: () => {
            if (this.props.isHITL) {
              this.setState({
                showLoadingScreen: true
              })
              window.scrollBy({
                top: -10000,
                behavior: 'smooth'
              })
            }
            this.removeDeletedFieldsAndFeaturesFromResponse(validResults)
            const emailFormUuid = window.location.hostname === 'base64.ai' ? 'f3f08fe3' : 'eac09d0b'
            const formUuid = this.props.formUuid || (this.state.emailAddresses && emailFormUuid)
            const resultUuid = this.props.resultUuid || ''
            if (formUuid || resultUuid) {
              let requestOptions
              if (resultUuid) {
                requestOptions = {
                  endpoint: '/result/' + resultUuid,
                  body: {
                    status: options?.status || 'approved',
                    results: validResults,
                    elapsedTime: this.state.secondsElapsed
                  }
                }
                if (options?.status === 'rejected' && this.state.rejectionReason?.length) {
                  requestOptions.body.rejectionReason = this.state.rejectionReason
                }
              } else {
                requestOptions = {
                  endpoint: '/no-code-form/execute/' + formUuid,
                  body: this.mappingScriptEvaluate(this.state.noCodeConfiguration && this.state.noCodeConfiguration.script, {
                    results: validResults,
                    userEmailAddresses: this.state.emailAddresses,
                    document: this.state.responses[responseUuid].image,
                    name: this.state.responses[responseUuid].name,
                    type: this.state.responses[responseUuid].type
                  })
                }
              }
              request(
                {
                  endpoint: requestOptions.endpoint,
                  body: requestOptions.body
                }, (error, _) => {
                  if (this.props.isHITL) {
                    this.setState({
                      showLoadingScreen: false
                    })
                  }
                  if (error) {
                    this.context.displayModal({
                      title: 'Errors in submission',
                      content: (
                        <div>
                          <p>Please correct the following errors:</p>
                          <ul
                            style={{
                              textAlign: 'left',
                              marginTop: '1rem',
                              listStyle: 'auto'
                            }}
                          >
                            Unable to submit: { error.message }
                          </ul>
                        </div>
                      ),
                      options: [
                        { content: 'OK' }
                      ],
                      isClosable: true
                    })
                  } else {
                    let flowDisplayOptions = [{ content: 'OK' }]
                    let contentMessage = 'Submission completed'
                    if (this.props.isHITL && this.state.flow?.flowID) {
                      const { flows } = this.context
                      if (flows) {
                        const flow = flows?.find(it => it.flowID === this.state.flow?.flowID)
                        if (flow?.results && flow.results?.length > 0) {
                          const flowResult = flow.results.find(it => it.resultUuid === this.props.resultUuid)
                          if (flowResult) {
                            flowResult.status = options?.status || 'approved'
                          }
                        }
                      }
                      flowDisplayOptions = [
                        {
                          content: 'See all results',
                          intent: 'negative',
                          action: () => {
                            this.context.redirect(`/flow/results/${this.state.flow.flowID}`)
                          }
                        },
                        {
                          content: 'Go to next result',
                          action: () => {
                            this.setState({
                              showLoadingScreen: true
                            })
                            window.scrollBy({
                              top: -10000,
                              behavior: 'smooth'
                            })
                            request({
                              method: 'GET',
                              endpoint: `/result/?nextTimestamp=${this.state.responses[responseUuid].createdAt}&limit=1&flowID=${this.state.flow.flowID}&filter=needsReview`
                            }, (err, nextNeedsReviewResponse) => {
                              if (err) {
                                this.context.redirect(`/flow/results/${this.state.flow.flowID}`)
                              }
                              if (nextNeedsReviewResponse?.results?.length && nextNeedsReviewResponse.results[0].resultUuid) {
                                this.setState({ isSubmitted: true })
                                this.context.redirect(`/hitl/${nextNeedsReviewResponse.results[0].resultUuid}`)
                                window.location.reload()
                              } else {
                                this.context.redirect(`/flow/results/${this.state.flow.flowID}`)
                              }
                            })
                          }
                        }
                      ]
                      contentMessage = <>The document is {options?.status || 'approved'}. If the flow has output integration,<br />they will be executed with the updated results automatically.</>
                    }
                    this.context.displayModal({
                      title: 'Success',
                      content: (
                        <div>
                          <p>{contentMessage}</p>
                        </div>
                      ),
                      options: flowDisplayOptions,
                      isWide: true,
                      isClosable: true
                    })
                    this.setState({ isSubmitted: false })
                  }
                }, error => alert('Unable to submit : ' + error)
              )
            } else {
              this.setState({ isSubmitted: false })
            }
          }
        }
      ]
    })
  }

  mappingScriptEvaluate (script, data) {
    if (script) {
      const mappingEval = `let body = arguments[0];
    ${script}
    return body;
    `
      // eslint-disable-next-line no-new-func
      const mapper = new Function(mappingEval)
      return mapper(data)
    } else {
      return data
    }
  }

  changePreviewIndex (images, options) {
    const currentIndex = this.state.hitlImageIndex
    if (options?.left) {
      if (currentIndex <= 0) {
        return
      }
      this.setState({
        hitlImageIndex: currentIndex - 1
      })
    } else if (options?.right) {
      if (currentIndex >= images.length - 1) {
        return
      }
      this.setState({
        hitlImageIndex: currentIndex + 1
      })
    }
  }

  toShortName (name) {
    return name.substr(0, 200) + (name.length > 200 ? '...' : '')
  }

  renderPreview ({
    uuid,
    name,
    results,
    type,
    image
  }) {
    const isZoomed = uuid === this.state.zoomedItem
    const isHITL = this.props.isHITL
    const closeZoom = () => this.setState({
      zoomedItem: null
    })
    document[`${isZoomed ? 'add' : 'remove'}EventListener`](
      'keydown',
      event => event.keyCode === 27 && closeZoom()
    )
    if (!type) {
      const responseMimeType = results?.[0]?.features?.properties?.mimeType
      if (responseMimeType) {
        type = responseMimeType
      } else {
        const lcaseName = name.toLowerCase()
        if (lcaseName.endsWith('pdf')) {
          type = 'application/pdf'
        } else if (lcaseName.match(/\.hei[cf]$/)) {
          type = 'image/hei' + lcaseName.substr(-1)
        } else {
          type = 'image/jpg'
        }
      }
    }

    const model = type === 'application/pdf'
      ? 'pdf'
      : type.startsWith('image/')
        ? 'image'
        : type.startsWith('audio/')
          ? 'audio'
          : type.startsWith('video/')
            ? 'video'
            : 'other'

    const unsupportedMimes = {
      'image/heic': 'HEIC',
      'image/heif': 'HEIF',
      'image/tiff': 'TIF',
      'image/tif': 'TIF'
    }
    return (
      <div className='leftPart'>

        <div
          className={
            (isZoomed ? 'zoomed ' : '')
            + (isHITL ? 'hitlView ' : '')
            + 'preview'
          }
          onClick={event => {
            if (event.target.className.includes('navigate-button')) {
              return
            }
            (!isZoomed || event.target.classList.contains('zoomed'))
            && model !== 'other'
            && !Object.keys(unsupportedMimes).includes(type) && this.setState({
              zoomedItem: isZoomed ? null : uuid
            })
          }}
        >
          <figure className={
            (model ? `${model} ` : '')
            + (isHITL ? 'hitlFigure ' : '')
          }
          >
            {
              Object.keys(unsupportedMimes).includes(type)
                ? `Your browser does not support ${unsupportedMimes[type] || 'such'} files.`
                : (model === 'pdf')
                    ? <PdfViewer image={image} />
                    : (model === 'image')
                        ? Array.isArray(image)
                          ? <img src={image[this.state.hitlImageIndex]} style={{ transform: `rotate(${this.state.rotationAngle || 0}deg)` }} />
                          : <img src={image} />
                        : (model === 'audio')
                            ? <audio src={image} controls preload='auto'>Your browser does not support the video tag.</audio>
                            : (model === 'video')
                                ? <video src={image} controls>Your browser does not support the audio tag.</video>
                                : '(No preview)'
            }
            <figcaption>
              <span className='label'>{name}</span>
              <a
                className='close noBorder'
                onClick={closeZoom}
              >
                Close
              </a>
            </figcaption>
          </figure>
          {isHITL && (
            <div className='document-navigator-box'>
              <div className='document-navigator-container'>
                {Array.isArray(image) && image.length > 1 && (
                  <div className='document-navigator-rotate document-navigator-item'>
                    <img
                      className='navigate-button'
                      onClick={() => {
                        this.changePreviewIndex(image, { left: true })
                      }}
                      src='/static/images/icons/navigateBeforeIcon.png'
                      title='Previous page'
                      alt='navigate before icon'
                    />
                  </div>
                )}
                <div className='document-navigator-page document-navigator-item'>
                  Page: {this.state.hitlImageIndex + 1} / {(Array.isArray(image) && image.length) || 1}
                </div>
                <div className='document-navigator-rotate document-navigator-item'>
                  <img
                    className='rotate-button navigate-button'
                    onClick={() => {
                      this.setState({
                        rotationAngle: (this.state.rotationAngle || 0) - 90
                      })
                    }}
                    src='/static/images/icons/rotateIcon.png'
                    title='Rotate image'
                    alt='rotate icon'
                  />
                </div>
                {Array.isArray(image) && image.length > 1 && (
                  <div className='document-navigator-rotate document-navigator-item'>
                    <img
                      className='navigate-button'
                      onClick={() => {
                        this.changePreviewIndex(image, { right: true })
                      }}
                      src='/static/images/icons/navigateNextIcon.png'
                      title='Next page'
                      alt='navigate next icon'
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

      </div>
    )
  }

  arrayToTable (data) {
    return (
      <table className='visual' style={{ color: 'white' }}>
        <tbody>
          {data.map((row, i1) => (
            <tr key={i1}>
              {row.map((cell, i2) =>
                <td key={i2}>{cell}</td>
              )}
            </tr>
          )
          )}
        </tbody>
      </table>
    )
  }

  renderTable (data, index) {
    const isZoomed = data[index] === this.state.zoomedItem
    const closeZoom = () => this.setState({
      zoomedItem: null
    })
    document[`${isZoomed ? 'add' : 'remove'}EventListener`](
      'keydown',
      event => event.keyCode === 27 && closeZoom()
    )
    return (
      <React.Fragment key={index}>
        {data && data.length && data[index]
          ? (
              <a
                onClick={event => (!isZoomed || event.target.classList.contains('zoomed'))
                && this.setState({
                  zoomedItem: isZoomed ? null : data[index]
                })}
                style={{
                  marginRight: '0.3rem',
                  borderBottom: 'unset'
                }}
              >
                {data.length === 1 ? 'View' : index + 1}
              </a>
            )
          : <span className='info'>No tables</span>}
        <div className={(isZoomed ? 'zoomed ' : '') + 'preview'} style={{ display: isZoomed ? 'flex' : 'none' }}>
          <figure className='table'>
            {isZoomed ? this.arrayToTable(data[index]) : null}
            <figcaption>
              <a
                className='close noBorder'
                style={{ display: isZoomed ? 'inline-block' : 'none' }}
                onClick={closeZoom}
              >
                Close
              </a>
            </figcaption>
          </figure>
        </div>
      </React.Fragment>
    )
  }

  renderAudio (data) {
    return (
      <audio controls='controls' preload='auto'>
        <source src={data} />
      </audio>
    )
  }

  renderOcr (data) {
    const isZoomed = data === this.state.zoomedItem
    const closeZoom = () => this.setState({
      zoomedItem: null
    })
    document[`${isZoomed ? 'add' : 'remove'}EventListener`](
      'keydown',
      event => event.keyCode === 27 && closeZoom()
    )
    return (
      <>
        {data
          ? (
              <span className='info'>
                {data.substr(0, 100).replace(/\n/g, ' ')}...
                <a
                  onClick={event => (!isZoomed || event.target.classList.contains('zoomed')) && this.setState({ zoomedItem: isZoomed ? null : data })}
                >
                  View all
                </a>
              </span>
            )
          : <span className='info'>No text</span>}
        <div className={(isZoomed ? 'zoomed ' : '') + 'preview'} style={{ display: isZoomed ? 'flex' : 'none' }}>
          <figure className='table'>
            {isZoomed
              ? <p>{this.renderValue(data)}</p>
              : null}
            <figcaption>
              <a
                className='close noBorder'
                style={{ display: isZoomed ? 'inline-block' : 'none' }}
                onClick={closeZoom}
              >
                Close
              </a>
            </figcaption>
          </figure>
        </div>
      </>
    )
  }

  renderOfac (data) {
    const isZoomed = data === this.state.zoomedItem
    const closeZoom = () => this.setState({
      zoomedItem: null
    })
    document[`${isZoomed ? 'add' : 'remove'}EventListener`](
      'keydown',
      event => event.keyCode === 27 && closeZoom()
    )
    return (
      <>
        {data && data.matches && data.matches.length
          ? (
              <a onClick={event => (!isZoomed || event.target.classList.contains('zoomed'))
              && this.setState({
                zoomedItem: isZoomed ? null : data
              })}
              >
                {`Found ${data.matches.length} records for ${data.keys.join(', ').replace(/, ([^,]*)$/, ', or $1')} field${data.keys.length === 1 ? '' : 's'}.`}
              </a>
            )
          : <span className='info'>No hits</span>}
        <div className={(isZoomed ? 'zoomed ' : '') + 'preview'} style={{ display: isZoomed ? 'flex' : 'none' }}>
          <figure className='table'>
            {isZoomed
              ? (
                  <ol>
                    {data.matches.map((ofacResponse, index) =>
                      <li key={index} style={{ color: 'white' }}>{ofacResponse}</li>
                    )}
                  </ol>
                )
              : null}
            <figcaption>
              <a
                className='close noBorder'
                style={{ display: isZoomed ? 'inline-block' : 'none' }}
                onClick={closeZoom}
              >
                Close
              </a>
            </figcaption>
          </figure>
        </div>
      </>
    )
  }

  renderValue (value) {
    return value
      ? `${value}`.split('\f').map((page, pageIndex) => (pageIndex)
        ? (
            <React.Fragment key={pageIndex}>
              <hr />
              {page}
            </React.Fragment>
          )
        : page)
      : null
  }

  formatDate (date) {
    try {
      return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split('T')[0]
    } catch (e) {
      console.log('Invalid date', date)
      return this.formatDate(new Date())
    }
  }

  renderEditableField (responseUuid, resultUuid, key) {
    // console.log('renderEditableField responseUuid', responseUuid, 'resultUuid', resultUuid, 'key', key, this.state.responses)
    const result = this.state.responses[responseUuid].results.filter(r => r.uuid === resultUuid)[0]
    // console.log('renderEditableField result', result)
    const isValid = result.fields[key].isValid
    const value = result.fields[key].value
    const type = result.fields[key].type
    const noCharge = 'No charge'
    const notCovered = 'Not covered'
    const backgroundColor = isValid === undefined
      ? 'white'
      : isValid ? 'lightgreen' : 'lightpink'

    if (type === 'static') {
      return value
    } else if (type === 'number') {
      return (
        <input
          type='number'
          step='any'
          value={value}
          style={{ backgroundColor }}
          onChange={e => this.updateResultValue(responseUuid, resultUuid, key, e.target.value)}
        />
      )
    } else if (type === 'date') {
      const dateValue = (new Date(value)).toString() !== 'Invalid Date' ? value : this.formatDate(new Date())
      const safeDate = dateValue
      return (
        <DayPickerInput
          formatDate={(date, _) => this.formatDate(date)}
          format='yyyy-MM-dd'
          parseDate={(str, _) => {
            try {
              return new Date(str)
            } catch (e) {
              console.log('Unable to parse date', str)
              return new Date()
            }
          }}
          value={dateValue}
          dayPickerProps={{
            selectedDays: new Date(safeDate + 'T00:00:00'),
            month: new Date(safeDate + 'T00:00:00')
          }}
          style={{
            backgroundColor,
            width: '100%'
          }}
          inputProps={{ readOnly: true }}
          onDayChange={(value, _x, _y) => this.updateResultValue(responseUuid, resultUuid, key, value)}
        />
      )
    } else if (type === 'boolean') {
      return (
        <Select
          isMulti={false}
          classNamePrefix='react-select'
          className='react-select'
          value={{
            value,
            label: value
          }}
          options={[
            {
              value: 'Yes',
              label: 'Yes'
            },
            {
              value: 'No',
              label: 'No'
            }
          ]}
          placeholder='Select Yes or No...'
          noOptionsMessage={() => 'No matching options found'}
          onChange={filter => this.updateResultValue(responseUuid, resultUuid, key, filter.value)}
        />
      )
    } else if (type === 'sbc') {
      const options = []
      const originalValue = result.fields[key].originalValue === undefined ? value : result.fields[key].originalValue
      if (value !== originalValue) {
        options.push({
          value,
          label: value
        })
      }
      options.push({
        value: originalValue,
        label: `${originalValue} (Base64.ai answer)`
      })
      if (value !== notCovered && originalValue !== notCovered) {
        options.push({
          value: notCovered,
          label: notCovered
        })
      }
      if (value !== noCharge && originalValue !== noCharge) {
        options.push({
          value: noCharge,
          label: noCharge
        })
      }
      options.push({
        value: '',
        label: '...add custom answer'
      })
      return (
        <CreatableSelect
          isMulti={false}
          className='react-select'
          classNamePrefix='react-select'
          defaultValue={{
            value,
            label: value
          }}
          options={options}
          placeholder='Select Yes or No...'
          noOptionsMessage={() => 'No matching options found'}
          onChange={filter => this.updateResultValue(responseUuid, resultUuid, key, filter.value)}
        />
      )
    } else {
      return (
        <textarea
          style={{ backgroundColor }}
          onChange={e => this.updateResultValue(responseUuid, resultUuid, key, e.target.value)}
          value={value}
        />
      )
    }
  }

  displayEditableKeyModal (result, responseUUID, fieldName, humanReadableKey) {
    const modal = {
      title: 'Change key',
      isWide: true,
      content:
      <>
        <p>Please enter a unique key:</p>
        <input
          type='text'
          name='editableKey'
          value={humanReadableKey || ''}
          style={{ marginTop: '1rem' }}
          placeholder='Enter a key'
          onChange={e => {
            humanReadableKey = e.target.value
            this.setState({
              editableKey: humanReadableKey
            })
            this.displayEditableKeyModal(result, responseUUID, fieldName, humanReadableKey)
          }}
        />
      </>,
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: 'Change',
          action: () => {
            this.updateResultKey(responseUUID, result.uuid, fieldName, this.state.editableKey)
            this.setState({
              editableKey: ''
            })
          }
        }
      ]
    }
    this.context.displayModal(modal)
  }

  displayEditableValueModal (result, responseUUID, key, value) {
    this.setState({
      editableValue: value
    })
    const modal = {
      title: 'Change result',
      isWide: true,
      content:
      <>
        <p>Please enter the new value:</p>
        <Textarea
          type='text'
          name='editableValue'
          className='editable-textarea'
          value={value || ''}
          placeholder='Enter data'
          onChange={e => {
            value = e.target.value
            this.setState({
              editableValue: value
            })
            this.displayEditableValueModal(result, responseUUID, key, value)
          }}
        />
      </>,
      options: [
        {
          content: 'Cancel',
          intent: 'negative',
          action: () => {
            this.setState({
              editableValue: ''
            })
          }
        },
        {
          content: 'Change',
          action: () => {
            this.updateResultValue(responseUUID, result.uuid, key, this.state.editableValue)
            this.setState({
              editableValue: ''
            })
          }
        }
      ]
    }
    this.context.displayModal(modal)
  }

  pickSample (url, name) {
    if (url.match(/\.thumbnail\.png/)) {
      url = url.replace(/\.thumbnail\.png/, '.png')
    }
    if (url.match(/\/(health\/sbc|digital_signature)\/\d.png/)) {
      url = url.replace('.png', '.pdf')
    } else if (url.match(/\/multimedia\/1.png/)) {
      url = url.replace('.png', '.mp4')
    } else if (url.match(/\/multimedia\/2.png/)) {
      url = url.replace('.png', '.ogg')
    }
    const inputUrl = url.includes(location.origin) ? url : `${location.origin}/api/download?url=${encodeURI(url)}`
    fetch(
      inputUrl, {
        credentials: 'include'
      })
      .then(result => result.blob())
      .then(blob => {
        const file = {
          name,
          uuid: uuid(),
          source: blob,
          fileUrl: url
        }
        this.indexFiles([file])
        this.processFiles([file], url)
      })
  }

  handlePastedFiles (event) {
    if (!event) {
      return
    }
    const files = []
    const possibleUrl = (event?.clipboardData?.getData('Text') || '').trim()
    if (possibleUrl.startsWith('http')) {
      this.pickSample(possibleUrl, possibleUrl)
      return
    }
    const items = (event?.clipboardData || event?.originalEvent?.clipboardData)?.items
    if (!items) {
      return
    }
    Array.from(items).forEach(item => {
      if (item.kind === 'file') {
        const blob = item.getAsFile()
        files.push({
          name: 'clipboard-item',
          uuid: uuid(),
          source: blob
        })
      }
    })
    if (files.length > 0) {
      this.indexFiles(files)
      this.processFiles(files)
    }
  }

  toCamelCase (str) {
    return str
      .toLowerCase()
      .replace(/\(.*\)/g, '') // Remove text in parentheses
      .replace(/[._-]/g, ' ') // Replace separators with space
      .replace(/[^\w ]+/gm, '') // Remove non-alphanumeric, non-space characters
      .trim() // Trim whitespace
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) => idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()).replace(/\s+/g, '')
  }

  updateCopyFlag (uuid) {
    this.updateResponse(uuid, {
      copied: true
    })
    setTimeout(() => this.updateResponse(uuid, {
      copied: false
    }), 1000)
  }

  updateResultKey (responseUuid, resultUuid, oldKey, newHumanReadableKey) {
    const result = this.state.responses[responseUuid].results.filter(r => r.uuid === resultUuid)[0]
    const requestedKey = toCamelCase(newHumanReadableKey)
    if (oldKey === requestedKey) {
      return true
    }
    if (!requestedKey) {
      alert('This enter a valid field name. Please pick a different one.')
      return false
    }
    if (result.fields[requestedKey]) {
      alert('This field name is in use. Please pick a different one.')
      return false
    }
    result.fields = Object.fromEntries(
      Object.entries(result.fields).map(([existingKey, existingValue]) => {
        if (existingKey === oldKey) {
          existingValue.originalKey = existingValue.key
          existingValue.key = newHumanReadableKey
          return [requestedKey, existingValue]
        }
        return [existingKey, existingValue]
      })
    )
    return true
  }

  updateResultValue (responseUuid, resultUuid, key, value, isDeleted) {
    // console.log('updateResultValue responseUuid', responseUuid, 'resultUuid', resultUuid, 'key', key, this.state.responses)
    const result = this.state.responses[responseUuid].results.filter(r => r.uuid === resultUuid)[0]
    if (isDeleted) {
      result.fields[key].isDeleted = true
    } else {
      const type = result.fields[key].type

      if (type === 'number') {
        value = parseFloat(value)
      } else if (type === 'date') {
        value = this.formatDate(new Date(value))
      }
      if (result.fields[key].originalValue === undefined && (result.fields[key].value + '').trim() !== (value + '').trim()) {
        // console.log('Overriding originalValue', result.fields[key].originalValue, 'with', result.fields[key].value)
        result.fields[key].originalValue = result.fields[key].value
      }
      if (value === result.fields[key].originalValue) {
        delete result.fields[key].originalValue
      }
      result.fields[key].value = value

      if (result.fields[key].originalValue === undefined && (result.fields[key].value + '').trim() !== (value + '').trim()) {
      // console.log('Overriding originalValue', result.fields[key].originalValue, 'with', result.fields[key].value)
        result.fields[key].originalValue = result.fields[key].value
      }
      if (value === result.fields[key].originalValue) {
        delete result.fields[key].originalValue
      }
      result.fields[key].value = value
      result.fields[key].confidence = 1
      result.fields[key].isValid = true
    }
    this.updateResponse(responseUuid, this.state.responses[responseUuid])
  }

  resetForm (responseUuid) {
    const response = this.state.responses[responseUuid]
    response.results = clone(response.originalResults)
    this.updateResponse(responseUuid, response)
  }

  csvExportAll (responses) {
    if (this.props.user || isWorkingOffline()) {
      const data = []
      responses.forEach(response => {
        if (!response || !response.results) {
          return
        }
        response.results.forEach(result => {
          const obj = {}
          obj.Model = result.model.name
          obj.Uuid = result.uuid
          Object.keys(result.fields).forEach(field => {
            obj[result.fields[field].key] = result.fields[field].value
          })
          obj['Full text OCR'] = result.ocr
          data.push(obj)
        })
      })
      try {
        const url = window.URL.createObjectURL(
          new Blob([new Json2csv(['resultIndex', 'key', 'value']).parse(data)])
        )
        const a = document.createElement('a')
        a.href = url
        a.download = 'Base64ai data extraction.csv'
        a.click()
        a.remove()
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      } catch (e) {
        console.log(e)
      }
    } else {
      this.unauthorizedExport('CSV')
    }
  }

  csvExport (response) {
    if (this.props.user || isWorkingOffline()) {
      const data = []
      response.results.forEach(result => {
        const obj = {}
        obj.Model = result.model.name
        obj.Uuid = result.uuid
        Object.keys(result.fields).forEach(field => {
          obj[result.fields[field].key] = result.fields[field].value
        })
        obj['Full text OCR'] = result.ocr
        data.push(obj)
      })
      try {
        const url = window.URL.createObjectURL(
          new Blob([new Json2csv(['resultIndex', 'key', 'value']).parse(data)])
        )
        const a = document.createElement('a')
        a.href = url
        a.download = `${response.name} base64ai data extraction.csv`
        a.click()
        a.remove()
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      } catch (e) {
        console.log(e)
      }
    } else {
      this.unauthorizedExport('CSV')
    }
  }

  unauthorizedExport (exportType) {
    this.context.displayModal({
      isForbiddenToClickOutside: true,
      title: 'Login required',
      content: `Please login to export results as ${exportType}`,
      options: [
        {
          content: 'Cancel',
          intent: 'negative'
        },
        {
          content: 'Login',
          action: () => {
            this.context.redirect('/login')
          }
        }
      ]
    })
  }

  filterModelByFlowSettings (models) {
    const { modelTypes } = this.state?.flow?.settings || {}
    if (!modelTypes?.length) {
      return models
    }
    return [...models.filter(model => {
      const tmpOptions = (model.options || []).filter(option => {
        for (const modelType of modelTypes) {
          if (option.label === 'Semantic') {
            return false
          }
          if (option.value.startsWith(modelType.value)) {
            return true
          }
        }
      })
      if (tmpOptions.length) {
        model.options = tmpOptions
        return true
      }
      return false
    }), {
      label: 'Unrecognized',
      path: 'unrecognized',
      options: [
        { label: 'Unrecognized', value: 'unrecognized' }
      ]
    }]
  }

  queryDocumentWithQuestion (question, resultUUID) {
    const requestUrl = '/result/ask/' + resultUUID
    if (this.state.questionAnswerResponses?.[resultUUID]?.find(q => q.question === question)) {
      return
    }
    const queryObject = {
      question
    }
    const questionAnswerResponses = { ...this.state.questionAnswerResponses }
    questionAnswerResponses[resultUUID] = [...(questionAnswerResponses[resultUUID] || []), queryObject]
    this.setState({ questionAnswerResponses })
    const body = {
      modality: 'multimodal',
      questions: [question]
    }
    if (this.props.isEmbedded) {
      const response = Object.values(this.state.responses).find(response => response?.results?.find(result => result.uuid === resultUUID))
      const result = response.results.find(result => result.uuid === resultUUID)
      body.result = {
        uuid: resultUUID,
        ocr: result?.ocr,
        fields: result.fields
      }
    }
    reCaptcha('demo', token => {
      body.token = token
      request({
        endpoint: requestUrl,
        body
      }, (error, result) => {
        if (error) {
          this.context.displayModal({
            isForbiddenToClickOutside: true,
            title: 'Question error',
            content: `${error}`,
            options: [
              {
                content: 'Close',
                intent: 'negative'
              }
            ]
          })
          // if error then remove the query object
          const responses = { ...this.state.questionAnswerResponses }
          const queryObjectIndex = responses?.[resultUUID]?.findIndex(q => q.question === queryObject.question)
          responses?.[resultUUID]?.splice(queryObjectIndex, 1)
          this.setState({ questionAnswerResponses: responses })
          return
        }
        // find the query object and replace it with the result
        const responses = { ...this.state.questionAnswerResponses }

        const queryObjectIndex = responses?.[resultUUID]?.findIndex(q => q.question === queryObject.question)
        responses[resultUUID][queryObjectIndex] = {
          ...queryObject,
          answer: result?.[0]?.answer || 'N/A'
        }
        this.setState({ questionAnswerResponses: responses })
      })
    })
  }

  renderQuestionAnswerBox (result) {
    if (isWorkingOffline()) {
      return <></>
    }
    // if not first result then don't show question answer box
    return (
      <div className='result-query-document'>
        <QuestionBox onResult={question => {
          const queryDocument = { ...this.state.queryDocument }
          queryDocument[result.uuid] = question
          this.setState({ queryDocument })
          this.queryDocumentWithQuestion(question, result.uuid)
        }}
        />
        {this.state.questionAnswerResponses?.[result.uuid]?.length
          ? (
              <>
                <div className='query-result-title'>Search results</div>
                {this.state.questionAnswerResponses?.[result.uuid]?.map((response, index) => {
                  return (
                    <div className='query-response' key={index}>
                      {response.answer
                        ? (
                            <>
                              <div className='query-response-text'>
                                <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                                  <div className='question'>{response.question}</div>
                                  <RemoveButton
                                    onRemove={() => {
                                      const questionAnswerResponses = { ...this.state.questionAnswerResponses }
                                      questionAnswerResponses?.[result.uuid]?.splice(index, 1)
                                      this.setState({ questionAnswerResponses })
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='input-line'>
                                <div><FormatLLMResponseWithSources response={response} /></div>
                              </div>
                              <div className='add-field-button-container'>
                                <button onClick={() => {
                                  this.addNewField(result, response.question, response.answer)
                                }}
                                ><MaterialIcon name='add_circle' style={{ verticalAlign: '-.15rem', fontSize: 'inherit' }} /> Add as a field
                                </button>
                              </div>
                            </>
                          )
                        : <Loading />}
                    </div>
                  )
                })}
              </>
            )
          : <></>}
      </div>
    )
  }

  pdfExport (response) {
    Font.register({
      family: 'Roboto-Regular',
      src:
        `${window.location.origin}/static/fonts/Roboto-Regular.ttf`
    })
    Font.register({
      family: 'Roboto-Bold',
      src:
        `${window.location.origin}/static/fonts/Roboto-Bold.ttf`
    })
    const debug = false
    const styles = StyleSheet.create({
      page: {
        fontFamily: 'Roboto-Regular',
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        color: '#53565f'
      },
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
      },
      row: {
        flexDirection: 'row',
        margin: 1
      },
      key: {
        fontFamily: 'Roboto-Bold',
        padding: '5pt',
        fontSize: '10pt',
        flexGrow: 1,
        width: '20%'
      },
      value: {
        padding: '5pt',
        fontSize: '10pt',
        flexGrow: 1,
        width: '80%'
      },
      featureFace: {
        padding: '5pt',
        flexGrow: 1,
        width: '15%'
      },
      featureFaceInfo: {
        padding: '5pt',
        flexGrow: 1,
        width: '65%'
      },
      featureSignature: {
        padding: '5pt',
        flexGrow: 1,
        width: '30%'
      },
      featureSignatureInfo: {
        padding: '5pt',
        flexGrow: 1,
        width: '50%'
      },
      keyHeader: {
        padding: '5pt',
        fontSize: '15pt',
        flexGrow: 1,
        width: '20%'
      },
      valueHeader: {
        padding: '5pt',
        fontSize: '15pt',
        flexGrow: 1,
        width: '80%'
      },
      defaultText: {
        padding: '5pt',
        fontSize: '10pt'
      }
    })
    const tableContent = []
    let index = 0
    const len = response.results.filter(result => !result.isDeleted).length
    response.results.filter(result => !result.isDeleted).forEach((result, i1) => {
      if (len > 1) {
        tableContent.push(
          <PDFView style={{ backgroundColor: '#00dddd', ...styles.row }} debug={debug}>
            <Text style={{ color: 'rgb(255,255,255)', ...styles.key }} debug={debug}>Result {i1 + 1} of {len} </Text>
          </PDFView>
        )
      }
      tableContent.push(
        <PDFView style={{ backgroundColor: index++ % 2 === 0 ? '#00dddd55' : '#fff', ...styles.row }} debug={debug}>
          <Text style={styles.key} debug={debug}><b>Model:</b></Text>
          <Text style={styles.value} debug={debug}>{result.model.name}</Text>
        </PDFView>
      )
      Object.keys(result.fields).forEach(key => {
        tableContent.push(
          <PDFView
            style={{ backgroundColor: typeof result.fields[key].isValid === 'boolean' && !result.fields[key].isValid ? '#fca1c4ee' : index++ % 2 === 0 ? '#00dddd55' : '#fff', ...styles.row }}
            debug={debug}
          >
            <Text style={styles.key} debug={debug}>{result.fields[key].key}:</Text>
            <Text style={styles.value} debug={debug}>{result.fields[key].value}</Text>
          </PDFView>
        )
      })
      if (result.features) {
        result.features.faces && result.features.faces.forEach(face => {
          tableContent.push(
            <PDFView style={{ backgroundColor: index++ % 2 === 0 ? '#00dddd55' : '#fff', ...styles.row }} debug={debug}>
              <Text style={styles.key} debug={debug}>Face:</Text>
              <PDFImage src={face.image} style={styles.featureFace} />
              <PDFView style={styles.featureFaceInfo}>
                <Text style={styles.defaultText}>Width: {face.width}px</Text>
                <Text style={styles.defaultText}>Height: {face.height}px</Text>
              </PDFView>
            </PDFView>
          )
        })
        result.features.signatures && result.features.signatures.forEach(signature => {
          tableContent.push(
            <PDFView style={{ backgroundColor: index++ % 2 === 0 ? '#00dddd55' : '#fff', ...styles.row }} debug={debug}>
              <Text style={styles.key} debug={debug}>Signature:</Text>
              <PDFImage src={signature.image} style={styles.featureSignature} />
              <PDFView style={styles.featureSignatureInfo}>
                <Text style={styles.defaultText}>Width: {signature.width}px</Text>
                <Text style={styles.defaultText}>Height: {signature.height}px</Text>
              </PDFView>
            </PDFView>
          )
        })
      }
    })

    const unsupportedMimes = {
      'image/heic': 'Heic images are not displayed',
      'image/heif': 'Heif images are not displayed',
      'image/tiff': 'TIF images are not displayed',
      'image/tif': 'TIF images are not displayed'
    }

    return (
      <Document>
        <Page size='A4' style={styles.page}>
          <PDFView style={styles.section}>
            <Text style={{
              marginRight: 30,
              fontSize: '10pt',
              color: '#4f4f4f',
              fontWeight: 600,
              textAlign: 'right',
              alignSelf: 'stretch'
            }}
            >
              Powered by
              <PDFLink
                src='https://base64.ai'
                style={{
                  fontSize: '10pt',
                  color: '#00dddd',
                  padding: 10
                }}
              >
                Base64.ai
              </PDFLink>
            </Text>
            {response.type === 'application/pdf'
              ? null
              : Object.keys(unsupportedMimes).includes(response.type)
                ? (
                    <Text style={{
                      textAlign: 'center',
                      alignSelf: 'stretch',
                      ...styles.defaultText
                    }}
                    > {unsupportedMimes[response.type] }
                    </Text>
                  )
                : (
                    <PDFView
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                      }}
                      debug={debug}
                    >
                      <PDFImage
                        style={{
                          width: '40%',
                          alignSelf: 'center'
                        }}
                        src={response.image}
                        debug={debug}
                      />
                    </PDFView>
                  )}
            <PDFView style={{ margin: 30 }}>
              {tableContent}
            </PDFView>
          </PDFView>
        </Page>
      </Document>
    )
  }

  renderFileInput (isEmbedded, dropText) {
    return (
      <FileInput
        accept={Array.from(acceptedFileFormats)}
        dropText={isEmbedded ? (this.state.noCodeConfiguration && this.state.noCodeConfiguration.dropText) || undefined : dropText}
        indicator={true}
        onDrop={(accepted, rejected, event) =>
          this.handleFiles(accepted, rejected, event)}
      />
    )
  }

  renderCtas (responses) {
    const { emailAddresses } = this.state
    return (
      responses.filter(r => r.done).length
        ? (
            <p className='csvExportAll'>
              <Button
                to // leave as is
                className='actionButton csvButton'
                onClick={() => emailAddresses || isWorkingOffline() ? this.csvExportAll(responses) : this.unauthorizedExport('CSV / Excel file')}
              >
                Export all results as CSV
              </Button>
              {
                this.props.isEmbedded
                  ? <Button to='/integrations' className='integrateCta' target='_blank' rel='noopener noreferrer'>See other integrations</Button>
                  : <Button to='/integrations' className='integrateCta'>See other integrations</Button>
              }
            </p>
          )
        : null
    )
  }

  render () {
    const {
      filter,
      modelTitleNameString
    } = this.state
    const {
      sloganText,
      introText,
      dropText,
      descriptionText,
      isEmbedded,
      isHITL
    } = this.props

    if (
      this.props.isEmbedded
      && this.state.noCodeConfiguration
      && this.state.noCodeConfiguration.acceptedTypes
      && this.state.noCodeConfiguration.acceptedTypes.length !== 0
    ) {
      this.configuration.whiteLabelModelsData = this.configuration.modelsData.filter(model => {
        let state = false
        this.state.noCodeConfiguration.acceptedTypes.forEach(type => {
          if (model.path.includes(type)) {
            state = true
          }
        })
        return state
      })
    }
    const {
      modelsData,
      displayTypes,
      whiteLabelModelsData
    } = this.configuration
    //
    const titleModels = filter.length && modelTitleNameString ? `${modelTitleNameString} processing demo` : 'Document Processing Demo'
    const responses = Object.values(this.state.responses).reverse()
    const progress = responses.length > 0
      ? responses.filter(response => response.done).length / responses.length
      : 0
    // const success = responses.filter(response => response.success).length
    const topPadding = (isEmbedded || isHITL) ? { padding: 0 } : {}

    const hitlStatusToText = {
      autoApproved: 'auto-approved as per the flow settings',
      needsReview: 'needs review'
    }

    return (
      <View name='document-processing-demo'>
        <NavigationPrompt messageFunction={() => !this.state.isSubmitted ? Messages.CHANGES_NOT_SAVED : true} />
        {(isEmbedded)
          ? null
          : (isHITL && responses.length > 0)
              ? (
                  <>
                    <Header name='hitlHeader' align='center' tight={true}>
                      <div style={{ display: 'flex', gap: '1.5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2rem' }}>
                          <Link
                            onClick={() => {
                              this.context.redirect(`/flow/results/${this.state.flow?.flowID}`)
                            }}
                          >
                            &larr; {`Back to ${this.state.flow?.name}`}
                          </Link>
                          {this.state.flow && (
                            <p className='hitl-file-name'>
                              <span style={{ fontWeight: '500' }}>{responses[0].name}</span>
                            </p>
                          )}
                        </div>
                        <div>
                          {this.state.flow && (this.state.flow?.role?.includes('owner') || this.state.flow?.role?.includes('administrator')) && <DownloadLink style={{ marginBottom: '1rem', marginRight: this.state.flow.instructions?.fileName ? '1rem' : '' }} url={'/flow/originalFile/' + this.props.resultUuid} name='Download original file' fileName={responses?.[0]?.name || 'Original file'} />}
                          {this.state.flow && <DownloadLink url={'/flow/instructions/' + this.state.flow?.flowID} name='View instructions' fileName={this.state.flow.instructions?.fileName} />}
                        </div>
                      </div>
                    </Header>
                    {
                      isHITL && responses.length > 0
                      && ['approved', 'rejected', 'autoApproved'].includes(responses[0].hitlStatus)
                      && (
                        <div id='banner' className='banner' style={responses[0].hitlStatus === 'rejected' ? { backgroundColor: 'red' } : {}}>
                          <p style={{ fontWeight: '600' }}>This result was already {hitlStatusToText[responses[0].hitlStatus] || responses[0].hitlStatus}{ responses[0].reviewedBy?.length ? ' by ' + responses[0].reviewedBy[0] : ''}. {(responses[0].hitlStatus === 'rejected' && responses[0].rejectionReason?.length) ? <Link onClick={() => this.showRejectionReason(responses[0].reviewedBy[0], responses[0].rejectionReason)}>See reason</Link> : ''}</p>
                          {responses[0].hitlStatus !== 'rejected' && <p style={{ fontWeight: '400' }}>You cannot make further changes. <a target='_blank' href='https://help.base64.ai/kb/guide/en/flows-human-in-the-loop-document-processing-WH1QqHEiey/Steps/2068067' rel='noreferrer'>Learn more</a></p>}
                        </div>
                      )
                    }
                  </>
                )
              : (isHITL && responses.length === 0)
                  ? null
                  : (
                      <>
                        <Header name='header' align='center' tight={true}>
                          <h1 className='slogan'>{ sloganText }</h1>
                          { introText && <h2 className='introduction'>{ introText }</h2>}
                          <h3 className='introduction'>{ descriptionText }</h3>
                        </Header>
                        <DynamicTitle title={titleModels} />
                      </>
                    )}
        <Section
          name='input'
          tight={true}
          style={topPadding}
        >
          {isEmbedded
            ? <p className='poweredBy'>{ this.state.noCodeConfiguration?.whiteLabel === false && <>Powered by <a href='https://base64.ai' target='_blank' rel='noopener noreferrer'>Base64.ai</a></>}</p>
            : isHITL
              ? null
              : (
                  <DocumentUploader
                    selectedModels={this.state.selectedModels}
                    allModels={this.state.allModels}
                    handleFiles={this.handleFiles}
                    onChange={(filter, modelTitleNameString) => {
                      this.setState({ selectedModels: filter, modelTitleNameString }, () => {
                        this.context.redirect('/flow' + (
                          filter === null
                            ? ''
                            : `/${filter.map(item => item.value).join('+')}`
                        ))
                      })
                    }}
                  />
                )}
          {isEmbedded
            ? this.renderFileInput(isEmbedded, dropText)
            : isHITL
              ? null
              : <DemoSampleView models={this.state.selectedModels.map(x => x.value)} pickSample={this.pickSample} />}
        </Section>
        <Section
          name='output'
          ref={this.output}
          tight={true}
          isMax={isHITL}
        >
          { isHITL
            ? null
            : (
                <div className={
                  (responses.length > 0 ? '' : 'hidden ')
                  + 'summary'
                }
                >
                  <div
                    className='progress'
                  >
                    <div
                      className='indicator'
                      style={{
                        width: (progress * 100) + '%'
                      }}
                    />
                  </div>
                  <Link
                    className='secondary clear'
                    onClick={this.clearResponses}
                  >
                    Clear responses
                  </Link>
                </div>
              )}
          { (isHITL && (responses.length === 0 || this.state.showLoadingScreen === true))
            ? (
                <div className='hitlPlaceholder'>
                  <Loading />
                  <h2 className='title'>Retrieving result...</h2>
                  <p className='paragraph'>Please wait, this may take a few seconds.</p>
                </div>
              )
            : null}
          {(responses.length > 0 && this.state.showLoadingScreen === false)
          && (
            <ul className={`${isHITL ? 'hitlValidation' : ''} responses`}>
              {responses.map((response, responseIndex) => (
                <li
                  key={responseIndex}
                  className={`${isHITL ? 'hitlView ' : ''}` + `${response.done ? '' : 'loading'}`}
                >
                  {(response.error || !response.done)
                    ? (
                        <p className='status'>
                          <i>
                            <Loading />
                            {
                              response.error
                                ? (
                                    <>
                                      <b>{response.name}</b>
                                      <span className='error'>{response.error} </span>
                                    </>
                                  )
                                : (
                                    <>
                                      <span>Our AI is now processing</span> <b style={{ whiteSpace: 'pre-line' }}>{response.name.replace('\n', ' ')}</b>
                                    </>
                                  )
                            }
                          </i>
                        </p>
                      )
                    : (
                        <>
                          {this.renderPreview(response)}
                          {response.results && response.results.length
                          && (
                            <div className={`${isEmbedded ? 'dataEmbedded' : isHITL ? 'hitlView' : 'dataWeb'} data`}>
                              {(isEmbedded || isHITL)
                                ? null
                                : (
                                    <ul className='options'>
                                      {displayTypes.map(type => (
                                        <li
                                          key={type.key}
                                          className={type.key === response.display ? 'active' : ''}
                                          onClick={() =>
                                            this.updateResponse(response.uuid, {
                                              display: type.key
                                            })}
                                        >
                                          {type.title}
                                        </li>
                                      )
                                      )}
                                    </ul>
                                  )}
                              {response.display === 'visual'
                              && response.results.filter(r => !r.isDeleted).map((result, resultIndex) => (
                                <React.Fragment key={resultIndex}>
                                  {!isEmbedded && !isHITL && this.renderQuestionAnswerBox(result)}
                                  <table className='visual' key={resultIndex}>
                                    <thead>
                                      {
                                        (response.results.filter(r => !r.isDeleted).length > 1 && (!isHITL || (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))))
                                          ? (
                                              <tr>
                                                <th colSpan='2' className='multiResult'>
                                                  Result {resultIndex + 1} of {response.results.filter(r => !r.isDeleted).length}
                                                  <div
                                                    style={{ float: 'right', display: 'flex', height: '1rem', gap: '0.2rem', alignItems: 'center' }}
                                                    title={`Delete result ${resultIndex + 1} with all fields`}
                                                    onClick={() => this.deleteResponsesResult(response.uuid, result.uuid)}
                                                  >
                                                    <img
                                                      style={{
                                                        width: '.9rem',
                                                        height: 'auto'
                                                      }}
                                                      src='/static/images/icons/hitl-close.svg'
                                                      title='Delete the result with all fields'
                                                      alt='delete icon'
                                                    />
                                                    <span style={{
                                                      color: 'red',
                                                      fontWeight: '400',
                                                      fontSize: '.8rem'
                                                    }}
                                                    >
                                                      Delete this result
                                                    </span>
                                                  </div>
                                                </th>
                                              </tr>
                                            )
                                          : null
                                      }
                                      {result.model?.isValid === false
                                        ? (
                                            <tr>
                                              <td colSpan='2'>
                                                <ValidationSign success={false} />️
                                                The document type classification does not match the shape
                                              </td>
                                            </tr>
                                          )
                                        : null}
                                      {result.features?.properties?.isGlareFree === false
                                        ? (
                                            <tr>
                                              <td colSpan='2'>
                                                <ValidationSign success={false} />️
                                                The image has glare, which may negatively impact the results.
                                              </td>
                                            </tr>
                                          )
                                        : null}
                                      {result.features?.properties?.isInFocus === false
                                        ? (
                                            <tr>
                                              <td colSpan='2'>
                                                <ValidationSign success={false} />️
                                                The image is blurry, which may negatively impact the results.
                                              </td>
                                            </tr>
                                          )
                                        : null}
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style={{ verticalAlign: 'middle' }}>Model:</td>
                                        <td style={{ width: (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus)) ? '50%' : '' }}>
                                          {(isEmbedded || (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))) && resultIndex === 0
                                            ? (
                                                <Select
                                                  isMulti={false}
                                                  classNamePrefix='react-select'
                                                  className='react-select'
                                                  value={{
                                                    label: result.model.name,
                                                    value: result.model.type
                                                  }}
                                                  options={() => {
                                                    return this.filterModelByFlowSettings((whiteLabelModelsData && whiteLabelModelsData.length)
                                                      ? whiteLabelModelsData
                                                      : [{ ...modelsData[0], options: [{ value: 'semantic', label: 'Reclassify (semantic)' }, ...modelsData[0].options] }, ...modelsData.slice(1)])
                                                  }}
                                                  closeMenuOnSelect={isHITL === true}
                                                  placeholder='Filter document types...'
                                                  noOptionsMessage={() => 'Loading document types...'}
                                                  filterOption={({
                                                    label,
                                                    value
                                                  }, query) => {
                                                    if (!query) {
                                                      return true
                                                    }
                                                    const modelName = label.toLowerCase().replace(/[-)(]/g, '')
                                                    const modelType = value.toLowerCase()
                                                    query = query.toLowerCase().replace(/[-)(]/g, '')
                                                    for (const part of query.split(' ').filter(x => x.trim())) {
                                                      if (!modelName.includes(part) && !modelType.includes(part)) {
                                                        return false
                                                      }
                                                    }
                                                    return true
                                                  }}
                                                  onChange={filter => {
                                                    this.reprocessDocument(response.uuid, filter.value, { result })
                                                  }}
                                                />
                                              )
                                            : (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                                ? (
                                                    <Select
                                                      isMulti={false}
                                                      classNamePrefix='react-select'
                                                      className='react-select'
                                                      value={{
                                                        label: result.model.name,
                                                        value: result.model.type
                                                      }}
                                                      options={(whiteLabelModelsData && whiteLabelModelsData.length) ? whiteLabelModelsData : modelsData}
                                                      closeMenuOnSelect={isHITL === true}
                                                      placeholder='Filter document types...'
                                                      noOptionsMessage={() => 'Loading document types...'}
                                                      filterOption={({
                                                        label,
                                                        value
                                                      }, query) => {
                                                        if (!query) {
                                                          return true
                                                        }
                                                        const modelName = label.toLowerCase().replace(/[-)(]/g, '')
                                                        const modelType = value.toLowerCase()
                                                        query = query.toLowerCase().replace(/[-)(]/g, '')
                                                        for (const part of query.split(' ').filter(x => x.trim())) {
                                                          if (!modelName.includes(part) && !modelType.includes(part)) {
                                                            return false
                                                          }
                                                        }
                                                        return true
                                                      }}
                                                      onChange={filter => {
                                                        this.reprocessDocument(response.uuid, filter.value, { notReprocess: filter, result })
                                                      }}
                                                    />
                                                  )
                                                : (
                                                    <>
                                                      {result.model.isValid === undefined ? '' : <ValidationSign success={!!result.model.isValid} />}
                                                      <span className='value'>{this.renderValue(result.model.name)}</span>
                                                      { isHITL
                                                        ? null
                                                        : (
                                                            <>
                                                              <br />
                                                              { response.fileUrl && response.fileUrl.match(isProduction() ? /^https:\/\/base64.ai\// : /^http:\/\/localhost:3000\//)
                                                                ? null
                                                                : result.model.type.match(/^((auto)|(semantic))/m)
                                                                  ? <a href='/contact' target='_blank'>Contact us to build your custom model</a>
                                                                  : <Link onClick={() => this.reprocessDocument(response.uuid, 'semantic')}>Incorrect? Try semantic processing</Link>}
                                                            </>
                                                          )}
                                                    </>
                                                  )}
                                        </td>
                                        { isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus)
                                          ? <td></td>
                                          : null}
                                      </tr>
                                      {!isEmbedded && !!(result.features && result.features.textToSpeech)
                                      && (
                                        <tr
                                          className='table'
                                        >
                                          <td>
                                            Listen:
                                          </td>
                                          <td>
                                            {this.renderAudio(result.features.textToSpeech)}
                                          </td>
                                          {
                                            (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                              ? (
                                                  <td style={{ padding: '0.5rem 0' }}>
                                                    <img
                                                      className='remove-button'
                                                      onClick={() => this.removeFeatureByKey(response.uuid, result.uuid, 'textToSpeech')}
                                                      src='/static/images/icons/hitl-close.svg'
                                                      title='Delete this field: text to speech'
                                                      alt='delete icon'
                                                    />
                                                  </td>
                                                )
                                              : null
                                          }
                                        </tr>
                                      )}
                                      {result.fields && Object.keys(result.fields || {}).filter(
                                        key => key !== 'fileName' && result.fields[key].isDeleted !== true).map((key, index) => (
                                        <tr key={index} className='editable-field'>
                                          <td onClick={e => {
                                            if (result.fields && result.fields[key]) {
                                              this.displayEditableKeyModal(result, response.uuid, key, result.fields[key].key)
                                              e.preventDefault()
                                            }
                                          }}
                                          >{result.fields[key].key}:
                                          </td>
                                          {(isEmbedded || (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus)))
                                            ? (
                                                <td>{this.renderEditableField(response.uuid, result.uuid, key)}</td>
                                              )
                                            : (
                                                <td onClick={e => {
                                                  if (result.fields && result.fields[key]) {
                                                    !(isEmbedded || isHITL) && this.displayEditableValueModal(result, response.uuid, key, result.fields[key].value)
                                                    e.preventDefault()
                                                  }
                                                }}
                                                >
                                                  {
                                                    result.fields[key].isValid === undefined
                                                      ? ''
                                                      : <ValidationSign success={!!result.fields[key].isValid} />
                                                  }
                                                  <span className='value'>{this.renderValue(result.fields[key].value)}</span>
                                                </td>
                                              )}
                                          { ((isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus)))
                                            ? (
                                                <td style={{ padding: '0.5rem 0' }}>
                                                  <img
                                                    className='remove-button'
                                                    onClick={() => this.updateResultValue(response.uuid, result.uuid, key, '', true)}
                                                    src='/static/images/icons/hitl-close.svg'
                                                    title={`Delete this field: ${result.fields[key].key}`}
                                                    alt='delete icon'
                                                  />
                                                </td>
                                              )
                                            : null}
                                        </tr>
                                      )
                                      )}
                                      { (!isHITL || (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus)))
                                        ? (
                                            <tr key='addNewField'>
                                              <td colSpan='2'>
                                                <span className='value'><a onClick={() => this.addNewField(result)}>Add a new field (optional)</a></span>
                                              </td>
                                              { isHITL
                                                ? <td></td>
                                                : null}
                                            </tr>
                                          )
                                        : null}
                                      {result.features?.properties?.isWatermarkFree !== undefined
                                        ? (
                                            <tr key='isWatermarkFree'>
                                              <td>Is watermark free?</td>
                                              <td>{ (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                                ? (
                                                    <Checkbox
                                                      className='big'
                                                      title='Check whether is watermark free or not'
                                                      placeholder=' '
                                                      onChange={e => {
                                                        this.editPropertiesByKey(response.uuid, result.uuid, 'isWatermarkFree', e.target)
                                                      }}
                                                      defaultChecked={result.features.properties.isWatermarkFree}
                                                      checked={result.features.properties.isWatermarkFree}
                                                    />
                                                  )
                                                : (
                                                    <>
                                                      <ValidationSign success={result.features.properties.isWatermarkFree === 'Yes' || result.features.properties.isWatermarkFree === true} />
                                                      <span className='value'>{result.features.properties.isWatermarkFree === 'Yes' || result.features.properties.isWatermarkFree === true ? 'Yes' : 'No'}</span>
                                                    </>
                                                  )}
                                              </td>
                                              {
                                                isHITL
                                                  ? <td></td>
                                                  : null
                                              }
                                            </tr>
                                          )
                                        : null}
                                      {result.features?.properties?.isInFocus !== undefined
                                        ? (
                                            <tr key='isInFocus'>
                                              <td>Is in focus?</td>
                                              <td>{ (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                                ? (
                                                    <Checkbox
                                                      className='big'
                                                      title='Check whether is in focus or not'
                                                      placeholder=' '
                                                      onChange={e => {
                                                        this.editPropertiesByKey(response.uuid, result.uuid, 'isInFocus', e.target)
                                                      }}
                                                      defaultChecked={result.features.properties.isInFocus}
                                                      checked={result.features.properties.isInFocus}
                                                    />
                                                  )
                                                : (
                                                    <>
                                                      <ValidationSign success={result.features.properties.isInFocus === 'Yes' || result.features.properties.isInFocus === true} />
                                                      <span className='value'>{result.features.properties.isInFocus === 'Yes' || result.features.properties.isInFocus === true ? 'Yes' : 'No'}</span>
                                                    </>
                                                  )}
                                              </td>
                                              {
                                                (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                                  ? <td></td>
                                                  : null
                                              }
                                            </tr>
                                          )
                                        : null}
                                      {result.features?.properties?.isGlareFree !== undefined
                                        ? (
                                            <tr key='isGlareFree'>
                                              <td>Is glare free?</td>
                                              <td>
                                                { (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                                  ? (
                                                      <Checkbox
                                                        className='big'
                                                        title='Check whether glare free or not'
                                                        placeholder=' '
                                                        onChange={e => {
                                                          this.editPropertiesByKey(response.uuid, result.uuid, 'isGlareFree', e.target)
                                                        }}
                                                        defaultChecked={result.features.properties.isGlareFree}
                                                        checked={result.features.properties.isGlareFree}
                                                      />
                                                    )
                                                  : (
                                                      <><ValidationSign success={result.features.properties.isGlareFree === 'Yes' || result.features.properties.isGlareFree === true} />
                                                        <span className='value'>{result.features.properties.isGlareFree === 'Yes' || result.features.properties.isGlareFree === true ? 'Yes' : 'No'}</span>
                                                      </>
                                                    )}
                                              </td>
                                              {
                                                (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                                  ? <td></td>
                                                  : null
                                              }
                                            </tr>
                                          )
                                        : null}
                                      {result.features && result.features.faces && (result.features.faces || []).filter(f => f).filter(f => f && f.isDeleted !== true).map((face, index) => (
                                        <tr
                                          key={index}
                                          className='face'
                                        >
                                          <td>Face:</td>
                                          <td>
                                            <img src={face.image} />
                                            <dl className='information'>
                                              <dt>Width</dt>
                                              <dd>{`${face.width}px`}</dd>
                                              <dt>Height</dt>
                                              <dd>{`${face.height}px`}</dd>
                                            </dl>
                                          </td>
                                          {
                                            (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                              ? (
                                                  <td style={{ padding: '0.5rem 0' }}>
                                                    <img
                                                      className='remove-button'
                                                      onClick={() => this.removeFeatureByKey(response.uuid, result.uuid, 'faces', index)}
                                                      src='/static/images/icons/hitl-close.svg'
                                                      title='Delete this field: face'
                                                      alt='delete icon'
                                                    />
                                                  </td>
                                                )
                                              : null
                                          }
                                        </tr>
                                      )
                                      )}
                                      {result.features && result.features.signatures && (result.features.signatures || []).filter(s => s && s.isDeleted !== true).map((signature, index) => (
                                        <tr
                                          key={index}
                                          className='signature'
                                        >
                                          <td>Signature:</td>
                                          <td>
                                            <img src={signature.image} />
                                            <dl className='information'>
                                              <dt>Width</dt>
                                              <dd>{`${signature.width}px`}</dd>
                                              <dt>Height</dt>
                                              <dd>{`${signature.height}px`}</dd>
                                            </dl>
                                          </td>
                                          {
                                            (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                              ? (
                                                  <td style={{ padding: '0.5rem 0' }}>
                                                    <img
                                                      className='remove-button'
                                                      onClick={() => this.removeFeatureByKey(response.uuid, result.uuid, 'signatures', index)}
                                                      src='/static/images/icons/hitl-close.svg'
                                                      title='Delete this field: signature'
                                                      alt='delete icon'
                                                    />
                                                  </td>
                                                )
                                              : null
                                          }
                                        </tr>
                                      )
                                      )}
                                      {result.features && result.features.glares && (result.features.glares || []).filter(g => g && g.isDeleted !== true).map((glare, index) => (
                                        <tr
                                          key={index}
                                          className='signature'
                                        >
                                          <td>Glare:</td>
                                          <td>
                                            <img src={glare.image} />
                                            <dl className='information'>
                                              <dt>Width</dt>
                                              <dd>{`${glare.width}px`}</dd>
                                              <dt>Height</dt>
                                              <dd>{`${glare.height}px`}</dd>
                                            </dl>
                                          </td>
                                          {
                                            (isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus))
                                              ? (
                                                  <td style={{ padding: '0.5rem 0' }}>
                                                    <img
                                                      className='remove-button'
                                                      onClick={() => this.removeFeatureByKey(response.uuid, result.uuid, 'glares', index)}
                                                      src='/static/images/icons/hitl-close.svg'
                                                      title='Delete this field: glares'
                                                      alt='delete icon'
                                                    />
                                                  </td>
                                                )
                                              : null
                                          }
                                        </tr>
                                      )
                                      )}
                                      {!(isEmbedded) && !!(result.features && result.features.tables && result.features.tables.length)
                                      && (
                                        <tr
                                          className='table'
                                        >
                                          <td>
                                            {result.features.tables.length > 1 ? 'Tables' : 'Table'}:
                                          </td>
                                          <td>
                                            {result.features.tables.map((_t, tableIndex) => this.renderTable(result.features.tables, tableIndex))}
                                          </td>
                                        </tr>
                                      )}
                                      {!(isEmbedded)
                                      && (
                                        <tr
                                          className='table'
                                        >
                                          <td>
                                            Digital {result.features && result.features.digitalSignatures && result.features.digitalSignatures.length === 1 ? 'signature' : 'signatures'}:
                                          </td>
                                          <td>
                                            {result.features && result.features.digitalSignatures ? result.features.digitalSignatures.map((_t, signatureIndex) => this.renderSignature(result.features.digitalSignatures, signatureIndex)) : 'None'}
                                          </td>
                                        </tr>
                                      )}
                                      {!(isEmbedded || isHITL) && !!(result.features && result.features.ofac)
                                      && (
                                        <tr
                                          className='ofac'
                                        >
                                          <td>OFAC records:</td>
                                          <td>
                                            {this.renderOfac(result.features.ofac)}
                                          </td>
                                        </tr>
                                      )}
                                      {!(isEmbedded) && !!(result.ocr)
                                      && (
                                        <tr
                                          className='ocr'
                                        >
                                          <td>Full text OCR:</td>
                                          <td>
                                            {this.renderOcr(result.ocr)}
                                          </td>
                                        </tr>
                                      )}
                                      { (result.features && result.features.creditsSpent && !isHITL && !isEmbedded) && (
                                        <tr
                                          className='time-saving'
                                        >
                                          <td>Time Savings:</td>
                                          <td>
                                            <span>{result.features.creditsSpent * 2.5} minutes&nbsp;
                                              <Link to='/roi-calculator' style={{ marginLeft: '1rem' }}>Calculate your ROI
                                              </Link>
                                            </span>
                                          </td>
                                          {
                                            isHITL || isEmbedded
                                              ? <td></td>
                                              : null
                                          }
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                  {isEmbedded
                                    ? (
                                        <div style={{ textAlign: 'center' }}>
                                          <button
                                            type='button'
                                            className='actionButton deleteButton'
                                            onClick={() => confirm('Are you sure to delete this file?') && this.deleteResponse(response.uuid)}
                                          >
                                            Delete
                                          </button>
                                          <button
                                            type='reset'
                                            className='actionButton resetButton'
                                            onClick={() => confirm('Are you sure to undo your changes?') && this.resetForm(response.uuid)}
                                          >
                                            Reset
                                          </button>
                                          {this.state.noCodeConfiguration?.csvExport
                                          && (
                                            <button
                                              type='button'
                                              className='actionButton csvButton'
                                              onClick={() => this.csvExport(response)}
                                            >
                                              CSV
                                            </button>
                                          )}
                                          {this.state.noCodeConfiguration?.pdfExport
                                          && (
                                            <PDFDownloadLink document={this.pdfExport(response)} fileName={`${response.name} base64ai data extraction.pdf`} className='pdfExport'>
                                              {({
                                                _blob,
                                                _url,
                                                _loading,
                                                _error
                                              }) => (
                                                <button
                                                  type='button'
                                                  className='actionButton csvButton'
                                                  // onClick={() => this.pdfExport(response)}
                                                >
                                                  PDF
                                                </button>
                                              )}
                                            </PDFDownloadLink>
                                          )}
                                          <button
                                            type='button'
                                            className='actionButton'
                                            onClick={() => this.submitResponse(response.uuid)}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      )
                                    : ((isHITL && ['needsReview', 'rejected'].includes(response.hitlStatus)))
                                        ? resultIndex === response.results.filter(r => !r.isDeleted).length - 1
                                          ? (
                                              <div style={{
                                                textAlign: 'right',
                                                marginTop: '2rem'
                                              }}
                                              >
                                                <div style={{ textAlign: 'left' }}>
                                                  <span
                                                    className='value'
                                                    style={{ fontSize: '.85rem', marginLeft: '.65rem' }}
                                                    title={'This feature allows you to add results that currently do not exist'
                                                    + 'for this document. This may include documents that appear in the same file.'}
                                                  >
                                                    <a onClick={() => this.addEmptyResultToResponse(response.uuid)}>Add New Result</a>
                                                  </span>
                                                </div>
                                                <div style={{ marginTop: '1rem' }}>
                                                  <button
                                                    type='reset'
                                                    className='actionButton resetButton'
                                                    onClick={() => confirm('Are you sure to undo your changes?') && this.resetForm(response.uuid)}
                                                  >
                                                    Reset
                                                  </button>
                                                  <button
                                                    type='button'
                                                    className='actionButton deleteButton'
                                                    onClick={() => this.submitResponse(response.uuid, { status: 'rejected' })}
                                                  >
                                                    Reject
                                                  </button>
                                                  <button
                                                    type='button'
                                                    className='actionButton'
                                                    onClick={() => this.submitResponse(response.uuid, { status: 'approved' })}
                                                  >
                                                    Approve
                                                  </button>
                                                </div>
                                              </div>
                                            )
                                          : null
                                        : isHITL
                                          ? null
                                          : (
                                              !isWorkingOffline() && (
                                                <>
                                                  <form
                                                    className='actionBox'
                                                    onSubmit={e => {
                                                      this.state.emailAddresses && this.submitResponse(response.uuid, { email: true })
                                                      e.preventDefault()
                                                    }}
                                                  >
                                                    <input
                                                      type='email'
                                                      name='emailAddresses'
                                                      style={{ fontSize: '0.74rem' }}
                                                      multiple
                                                      value={this.state.emailAddresses}
                                                      placeholder='Enter your email'
                                                      title='Please enter an email to receive the results'
                                                      onChange={e => {
                                                        this.setState({
                                                          emailAddresses: e.target.value
                                                        })
                                                      }}
                                                    />
                                                    <button type='submit' className='button smallButton'>Email results</button>
                                                    <ReportIssueButton image={response.image} />
                                                  </form>
                                                </>
                                              )
                                            )}
                                </React.Fragment>
                              )
                              )}
                              {response.display === 'api'
                              && (
                                <ReactJson
                                  src={response.results}
                                  name={null}
                                  iconStyle='square'
                                  displayDataTypes={false}
                                  displayObjectSize={false}
                                  collapsed={5}
                                  collapseStringsAfterLength={40}
                                  displayArrayKey={false}
                                />
                              )}
                            </div>
                          )}
                        </>
                      )}
                </li>
              )
              )}
            </ul>
          )}
          {
            isHITL || isEmbedded
              ? null
              : this.renderCtas(responses)
          }
        </Section>
      </View>
    )
  }
}

// Export
export default DocumentProcessingDemo
