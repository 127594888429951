import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import DataExtractionBox from './DataExtractionBox'
import * as uuid from 'uuid'
import DatePicker from '../date-picker/DatePicker'
import BooleanPicker from '../boolean-picker/BooleanPicker'
import { rotatePointAroundOfCenter } from '../image-viewer/geometry'
import TextareaAutosize from 'react-textarea-autosize'
import FormatLLMResponseWithSources from '../../utilities/modifiers'
class FieldBox extends Component {
  static propTypes = {
    field: PropTypes.object,
    fieldKey: PropTypes.string,
    context: PropTypes.object,
    contextProvider: PropTypes.object,
    resultIndex: PropTypes.number
  }

  static defaultProps = {
    context: {}
  }

  constructor (props) {
    super(props)
    this.state = {
      mode: 'view',
      height: 'unset'
    }
    this.id = uuid.v4()
    this.valueDiv = createRef()
    this.modeHandler = this.modeHandler.bind(this)
    this.updateHandler = this.updateHandler.bind(this)
    this.inputHeightHandler = this.inputHeightHandler.bind(this)
    this.removeHandler = this.removeHandler.bind(this)
    this.renderInput = this.renderInput.bind(this)
  }

  componentDidMount () {
    this.inputHeightHandler()
    window.addEventListener('resize', this.inputHeightHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.inputHeightHandler)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.mode !== this.state.mode) {
      this.inputHeightHandler()
      if (this.props.fieldKey === 'echo-parameter') {
        // only parse json when edit is finished
        if (this.state.mode === 'view') {
          try {
            const json = JSON.parse(this.props.field.value)
            this.props.contextProvider.updateResult(this.props.fieldKey, { value: json }, { resultIndex: this.props.resultIndex })
          } catch (e) {
            // invalid json, not an issue
          }
        }
      }
    }
    if (prevProps.context.dataSelectionResult !== this.props.context.dataSelectionResult
      && this.props.context.dataSelectionResult?.targetReference === this.id
    ) {
      this.updateHandler(this.props.context.dataSelectionResult.data)
    }
  }

  modeHandler (mode) {
    this.setState({ mode })
  }

  mergeLocations (locations) {
    const rotate = (dom, revert) => {
      const positions = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight']
      positions.forEach(position => {
        const angle = revert ? 0 - dom.properties.rotationAngle : dom.properties.rotationAngle
        dom[position] = rotatePointAroundOfCenter(dom.properties.width / 2, dom.properties.height / 2, dom[position].x, dom[position].y, angle)
      })
    }
    return locations.reduce((a, b) => {
      if (!a.topLeft) {
        return b
      }
      rotate(a)
      rotate(b)
      const newLocation = {
        topLeft: { x: Math.min(a.topLeft.x, b.topLeft.x), y: Math.min(a.topLeft.y, b.topLeft.y) },
        topRight: { x: Math.max(a.topRight.x, b.topRight.x), y: Math.min(a.topRight.y, b.topRight.y) },
        bottomLeft: { x: Math.min(a.bottomLeft.x, b.bottomLeft.x), y: Math.max(a.bottomLeft.y, b.bottomLeft.y) },
        bottomRight: { x: Math.max(a.bottomRight.x, b.bottomRight.x), y: Math.max(a.bottomRight.y, b.bottomRight.y) },
        properties: b.properties,
        pageNumber: b.pageNumber
      }
      rotate(a, true)
      rotate(b, true)
      rotate(newLocation, true)
      return newLocation
    }, {})
  }

  updateHandler (data) {
    let value
    let location = this.props.field.location
    if (typeof data === 'object') {
      value = data.map(s => s.text).join(' ')
      location = this.mergeLocations(data.map(s => s.location))
      if (!location.topLeft) {
        location = null
      }
    } else if (typeof data === 'string') {
      value = data
    } else if (typeof data === 'boolean') {
      value = data ? 'Yes' : 'No'
    }
    const nextField = {
      ...this.props.field,
      value,
      location,
      originalValue: this.props.field.originalValue || this.props.field.value,
      confidence: 1,
      isValid: true
    }
    this.props.contextProvider.updateResult(this.props.fieldKey, nextField, { resultIndex: this.props.resultIndex })
  }

  removeHandler () {
    this.props.contextProvider.updateResult(this.props.fieldKey, { ...this.props.field, isDeleted: true })
  }

  inputHeightHandler () {
    if (this.valueDiv.current && this.state.mode !== 'edit') {
      this.setState({ height: this.valueDiv.current.offsetHeight + 'px' })
    }
  }

  formatDate (date) {
    try {
      return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split('T')[0]
    } catch (e) {
      console.error('Invalid date', date)
      return this.formatDate(new Date())
    }
  }

  renderInput (field) {
    if (field.type === 'date') {
      const dateValue = value => new Date((new Date(value)).toString() !== 'Invalid Date' ? value : this.formatDate(new Date()))
      return (
        <DatePicker
          className='hitl'
          startDate={dateValue(field.value)}
          onChange={date => {
            this.updateHandler(this.formatDate(date))
          }}
        />
      )
    } else if (field.type === 'boolean') {
      return (
        <BooleanPicker
          onChange={bool => {
            this.updateHandler(bool)
          }}
          value={field.value === 'Yes'}
        />
      )
    } else {
      return (
        <TextareaAutosize
          className='field-box-input hitl-input'
          value={field.value}
          defaultValue={field.value}
          onChange={e => {
            this.updateHandler(e.target.value)
          }}
        />
      )
    }
  }

  render () {
    const { field } = this.props
    return (
      field
        ? (
            <DataExtractionBox
              title={field.key}
              inputType={field.type}
              selectedDataReference={this.id}
              domReferenceId={`result${this.props.resultIndex}${this.props.fieldKey}`}
              confidence={field.confidence}
              context={this.props.context}
              isValid={field.isValid}
              isEditable={true}
              modeHandler={this.modeHandler}
              location={field.location}
              contextProvider={this.props.contextProvider}
              removeHandler={this.removeHandler}
              desiredEditMode={['date', 'boolean'].includes(field.type) ? 'view' : 'dom'}
            >
              {
                this.state.mode === 'edit' && this.props.context.selectedDataReference === this.id
                  ? this.renderInput(field)
                  : <div ref={this.valueDiv}><FormatLLMResponseWithSources response={field} /></div>
              }
            </DataExtractionBox>
          )
        : <></>
    )
  }
}

export default FieldBox
