// Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Interface
import Link from '~/interface/link/Link'
import Input from '~/interface/input/Input'
import Button from '~/interface/button/Button'
import Checkbox from '~/interface/checkbox/Checkbox'
import Textarea from '~/interface/textarea/Textarea'
import PhoneInput from 'react-phone-number-input'
import { capitalizeFirstLetter } from '../../utilities/format'
/* SSR-IGNORE */ import 'react-phone-number-input/style.css'

// Interface: Form
class Form extends Component {
  static Link = Link
  static Input = Input
  static Button = Button
  static Checkbox = Checkbox
  static Textarea = Textarea
  static PhoneInput = PhoneInput

  static propTypes = {
    identifier: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    inline: PropTypes.bool,
    title: PropTypes.node,
    displayLabel: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onDataUpdate: PropTypes.func,
    onDataSubmit: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func
  }

  static defaultProps = {
    inline: false
  }

  constructor (props) {
    super(props)
    this.state = {
      input: {},
      errors: [],
      disabled: false
    }
    this.inputTypes = ['text', 'email', 'password', 'checkbox', 'textarea']
    this.setFormData = this.setFormData.bind(this)
    this.setInput = this.setInput.bind(this)
    this.resetInput = this.resetInput.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.evaluateForm = this.evaluateForm.bind(this)
    this.validateItem = this.validateItem.bind(this)
    this.checkMinimumLength = this.checkMinimumLength.bind(this)
    this.checkMaximumLength = this.checkMaximumLength.bind(this)
    this.checkPattern = this.checkPattern.bind(this)
    this.checkTick = this.checkTick.bind(this)
    this.checkCondition = this.checkCondition.bind(this)
    this.pushError = this.pushError.bind(this)
    this.pullError = this.pullError.bind(this)
    this.resetErrors = this.resetErrors.bind(this)
    this.errorHandler = this.errorHandler.bind(this)
  }

  componentDidMount () {
    this.setFormData()
    this.props.onSubmitHandler && this.props.onSubmitHandler(this.handleSubmit)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.identifier !== this.props.identifier) {
      this.setFormData()
    }
  }

  setFormData () {
    React.Children.map(
      this.props.children,
      child => {
        if (this.inputTypes.includes(child.props.type)) {
          this.setInput(child.props.name, child.props.prevalue || '')
        }
      }
    )
  }

  setInput (name, value, callback) {
    const input = this.state.input
    input[name] = value
    this.setState({ input })
    callback && callback(input, this.errorHandler)
  }

  resetInput () {
    this.setState({
      input: {},
      disabled: false
    })
  }

  resetErrors () {
    this.setState({
      errors: [],
      disabled: false
    })
  }

  handleInput (event) {
    if (!event) {
      return
    }
    const { target } = event
    if (!target) {
      return
    }
    const name = target.name
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value
    this.setInput(name, value, this.props.onDataUpdate)
  }

  errorHandler (error) {
    error && this.pushError(error)
    this.setState({ disabled: false })
  }

  handleSubmit (event) {
    event?.preventDefault?.()
    const { input } = this.state
    const promise = new Promise((resolve, reject) => {
      this.resetErrors()
      const trimmedInput = {}
      Object.keys(input).forEach(item => {
        trimmedInput[item] = typeof input[item] === 'string'
          ? input[item].trim()
          : input[item]
      })
      this.setState({
        input: trimmedInput,
        disabled: true
      })
      resolve()
    })
    promise.then(() => {
      if (this.evaluateForm()) {
        if (this.props.onDataSubmit) {
          this.props.onDataSubmit(this.state.input, this.errorHandler)
        }
      } else {
        this.setState({ disabled: false })
      }
    })
  }

  evaluateForm () {
    let isValid = true
    this.props.children.forEach(child => {
      const { name, type, reference, validator } = child.props
      if (type && type !== 'submit') {
        if (!this.validateItem(reference, this.state.input[name], validator)) {
          isValid = false
        }
      }
    })
    return isValid
  }

  validateItem (reference, value, validator) {
    let isValid = true
    if (validator) {
      Object.keys(validator).forEach(item => {
        const checker = `check${capitalizeFirstLetter(item)}`
        if (!this[checker](reference, value, validator[item])) {
          isValid = false
        }
      }
      )
    }
    return isValid
  }

  checkMinimumLength (reference, value, minimumLength) {
    value = value ?? ''
    const condition = value.length >= minimumLength
    const error = `${reference} is too short.`
    return this.checkCondition(condition, error)
  }

  checkMaximumLength (reference, value, maximumLength) {
    value = value ?? ''
    const condition = value.length <= maximumLength
    const error = `${reference} is too long.`
    return this.checkCondition(condition, error)
  }

  checkPattern (reference, value, pattern) {
    const condition = pattern.test(value)
    const error = `${reference} is not valid.`
    return this.checkCondition(condition, error)
  }

  checkTick (reference, value, expected) {
    const condition = !!value === expected
    const error = `${reference} is not approved.`
    return this.checkCondition(condition, error)
  }

  checkFieldMatch (reference, value, field) {
    field = this.state.input[field]
    const condition = field && value === field
    const error = `${reference} fields don't match.`
    return this.checkCondition(condition, error)
  }

  checkCondition (condition, error) {
    if (condition) {
      this.pullError(error)
      return true
    } else {
      this.pushError(error)
      return false
    }
  }

  pushError (error) {
    if (!this.state.errors.includes(error)) {
      this.setState({
        errors: this.state.errors.concat(error)
      })
    }
  }

  pullError (error) {
    if (this.state.errors.includes(error)) {
      this.setState({
        errors: this.state.errors.filter(item => item !== error)
      })
    }
  }

  renderFormElement (child, displayLabel, input) {
    const { type, name, className, label, prevalue = '', autoFocus, autoComplete, tone, options, hidden } = child.props
    const childObject = {
      className: tone || '',
      onChange: this.handleInput,
      autoComplete,
      autoFocus,
      options
    }
    if (type === 'checkbox') {
      childObject.checked = input[name] || prevalue || ''
    } else {
      childObject.value = input[name] || prevalue || ''
    }
    return hidden
      ? null
      : (
          <fieldset className={className}>
            {displayLabel && label
            && <b className='label'>{label}</b>}
            {
              React.cloneElement(
                child,
                this.inputTypes.includes(type) ? childObject : {}
              )
            }
          </fieldset>
        )
  }

  render () {
    const { input, errors, disabled } = this.state
    const { inline, title, displayLabel, children, className } = this.props

    const renderChildren = children => {
      return React.Children.map(children, child => {
        // Check if child is a valid React element
        if (React.isValidElement(child)) {
          // Check if child's type is in inputTypes
          if (this.inputTypes.includes(child.props.type)) {
            // Render form element for inputs
            return this.renderFormElement(child, displayLabel, input)
          } else if (child.props.children) {
            // Recursively render children that are not input types
            return React.cloneElement(child, {
              children: renderChildren(child.props.children)
            })
          }
          // Return non-input type elements as is
          return child
        }
        return child
      })
    }

    return (
      <form
        autoComplete='nope'
        noValidate
        disabled={disabled}
        onSubmit={this.handleSubmit}
        className={
          (className ? `${className} ` : '')
          + (inline ? ' inline ' : '')
          + 'box'
        }
      >
        {/* Title */}
        {title
        && <b className='title'>{title}</b>}
        {/* Errors */}
        {errors.length > 0
        && (
          <ul
            className='notice'
          >
            {errors.map((error, key) => (
              <li
                key={key}
                className='risk'
                onClick={() => {
                  this.pullError(error)
                }}
              >
                {error}
              </li>
            )
            )}
          </ul>
        )}
        <div className='content'>
          {renderChildren(children)}
        </div>
      </form>
    )
  }
}

// Export
export default Form
