import { Component } from 'react'

// Modules
import PropTypes from 'prop-types'

export default class RadioButton extends Component {
  static propTypes = {
    style: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { style, label, value, onChange } = this.props

    return (
      <label style={style} className={'custom-radio-button-label ' + this.props.className}>
        <input type='radio' checked={value} onChange={onChange} />
        <p>{label}</p>
      </label>
    )
  }
}
