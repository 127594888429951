import { Component } from 'react'
import PropTypes from 'prop-types'
import DataExtractionBox from './DataExtractionBox'
import Select from 'react-select'

class ModelBox extends Component {
  static propTypes = {
    model: PropTypes.object,
    mode: PropTypes.string,
    resultIndex: PropTypes.string,
    contextProvider: PropTypes.object,
    context: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      mode: this.props.mode
    }
    this.modeHandler = this.modeHandler.bind(this)
  }

  modeHandler (mode) {
    this.setState({ mode })
  }

  render () {
    const { model } = this.props
    return (
      <DataExtractionBox
        title='Model'
        confidence={model.confidence}
        isOverflowDisabled={true}
        isValid={model.isValid}
        modeHandler={this.modeHandler}
        isEditable={true}
        isRemovable={false}
        contextProvider={this.props.contextProvider}
        context={this.props.context}
        desiredEditMode='view'
        zIndex={20}
      >
        {
          this.state.mode === 'edit'
            ? (
                <Select
                  isMulti={false}
                  classNamePrefix='react-select'
                  className='react-select'
                  value={{
                    label: model.name,
                    value: model.type
                  }}
                  options={this.props.context.modelsData}
                  closeMenuOnSelect={true}
                  placeholder='Filter document types...'
                  noOptionsMessage={() => 'Loading document types...'}
                  filterOption={({
                    label,
                    value
                  }, query) => {
                    if (!query) {
                      return true
                    }
                    const modelName = label.toLowerCase().replace(/[-)(]/g, '')
                    const modelType = value.toLowerCase()
                    query = query.toLowerCase().replace(/[-)(]/g, '')
                    for (const part of query.split(' ').filter(x => x.trim())) {
                      if (!modelName.includes(part) && !modelType.includes(part)) {
                        return false
                      }
                    }
                    return true
                  }}
                  onChange={m => {
                    this.props.contextProvider.reprocessDocument(m.value, this.props.resultIndex)
                  }}
                />
              )
            : <div>{model.name}</div>
        }

      </DataExtractionBox>
    )
  }
}

export default ModelBox
