// Modules
import React, { Component } from 'react'
// Context
import Context from '~/context/global'
import { getBase64 } from '~/helpers/file'
// Helpers
import { request } from '~/helpers/request'
// Interface
import FileInput from '~/interface/file-input/FileInput'
import Header from '~/layout/header/Header'
import Section from '~/layout/section/Section'
// Layout
import View from '~/layout/view/View'
import { reCaptcha } from '~/helpers/authentication'
import StatusToEmoji from '~/interface/validation-sign/StatusToEmoji'
import document from 'global/document'
// Utilities
import { triggerTrackingEvent } from '../../utilities/tracker'

// View: Demo
class FacialRecognitionDemo extends Component {
  static contextType = Context

  constructor (props) {
    super(props)
    this.imageRef = React.createRef()
    this.imageCanvasRef = React.createRef()
    this.imageFigureRef = React.createRef()
    this.state = {
      responseMessage: 'Please upload a photo with faces.',
      recognizedFaceImages: null,
      imageBase64: null,
      imageUrl: null
    }
    this.drawRects = this.drawRects.bind(this)
    this.handleFiles = this.handleFiles.bind(this)
    this.handlePastedFiles = this.handlePastedFiles.bind(this)
    this.makeRequest = this.makeRequest.bind(this)
    this.recognizeFaces = this.recognizeFaces.bind(this)
    this.showSampleLink = this.showSampleLink.bind(this)
  }

  componentDidMount () {
    document.addEventListener('paste', this.handlePastedFiles)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.drawRects()
  }

  componentWillUnmount () {
    document.removeEventListener('paste', this.handlePastedFiles)
  }

  handlePastedFiles (event) {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items
    Array.from(items).forEach(item => {
      if (item.kind === 'file') {
        const blob = item.getAsFile()
        this.handleFiles([blob])
      }
    })
  }

  handleFiles (accepted, rejected) {
    this.setState({
      recognizedFaceImages: null
    })
    if (accepted && accepted.length) {
      getBase64({ source: accepted[0] }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: 'Cannot read the image file!'
          })
          return
        }
        this.setState({
          imageBase64: response
        }, this.recognizeFaces)
      })
    }
    if (rejected && rejected.length) {
      this.setState({
        responseStatus: 'error',
        responseMessage: 'Invalid file format'
      })
    }
  }

  makeRequest () {
    triggerTrackingEvent('demo-completed-facial-recognition')
    const imageOrUrl = this.state.imageBase64 ? { document: this.state.imageBase64 } : { url: this.state.imageUrl }
    reCaptcha('demo', token => {
      request({
        endpoint: '/face',
        body: {
          token,
          ...imageOrUrl
        }
      }, (error, response) => {
        if (error) {
          this.setState({
            responseMessage: error.message,
            responseStatus: 'error'
          })
        } else {
          if (response.message) {
            this.setState({
              responseMessage: error,
              responseStatus: 'error'
            })
          } else {
            if (response.length) {
              this.setState({
                responseMessage: `${response.length} face(s) are recognized in the image.`,
                responseStatus: 'success',
                recognizedFaceImages: response
              })
            } else {
              this.setState({
                responseMessage: 'The face is not found in the image.',
                responseStatus: 'warning'
              })
            }
          }
        }
      })
    })
  }

  recognizeFaces () {
    if (!this.state.imageBase64 && !this.state.imageUrl) {
      return
    }
    this.setState({
      responseStatus: 'info',
      responseMessage: 'Recognizing faces...'
    }, this.makeRequest)
  }

  showSampleLink (status) {
    if (status) {
      return null
    }
    return (
      <a
        className='showSample'
        onClick={() => {
          this.setState({
            imageUrl: 'https://base64.ai/static/content/features/facial-recognition/himym.jpg'
          }, this.recognizeFaces)
        }}
      >Show sample
      </a>
    )
  }

  drawRects () {
    const canvas = this.imageCanvasRef.current

    if (!canvas) {
      return
    }
    canvas.width = this.imageFigureRef.current.clientWidth
    canvas.height = this.imageFigureRef.current.clientHeight
    const scale = this.imageRef.current.naturalWidth / this.imageRef.current.clientWidth

    const images = this.state.recognizedFaceImages
    if (!images || images.length === 0) {
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      return
    }

    images.forEach(img => {
      const context = canvas.getContext('2d')
      context.beginPath()
      context.lineWidth = 3
      context.rect(
        img.left / scale,
        img.top / scale,
        img.width / scale,
        img.height / scale
      )

      context.setLineDash([5, 5])
      context.strokeStyle = '#00dddd'
      context.stroke()
    })
  }

  render () {
    const {
      responseMessage,
      responseStatus,
      imageBase64,
      imageUrl
    } = this.state
    return (
      <View name='facial-recognition-demo'>
        <Header name='header'>
          <h1 className='slogan'>Face Recognition AI</h1>
          <h2 className='introduction'>Recognize and extract faces from documents and images</h2>
          <h3 className='introduction'>
            Base64.ai analyzes a photo and determines the location of all faces, even if there are multiple people in the picture.
            In seconds, our AI isolates facial data for comparison against known samples. Upload a photo below to recognize faces or try the sample.
          </h3>
        </Header>
        <Section name='output'>
          <div><StatusToEmoji status={responseStatus} message={responseMessage} />{this.showSampleLink(responseStatus)}</div>
        </Section>
        <Section
          name='input'
          tight={true}
        >
          <div className='uploadBox'>
            <FileInput
              dropText='Upload a photo'
              multiple={false}
              indicator={true}
              accept={[
                'image/png',
                'image/jpg',
                'image/jpeg',
                'image/gif'
              ]}
              onDrop={(accepted, rejected, event) =>
                this.handleFiles(accepted, rejected)}
            />
            {imageBase64 || imageUrl
              ? (
                  <figure ref={this.imageFigureRef}>
                    <canvas ref={this.imageCanvasRef} />
                    <img src={imageBase64 || imageUrl} ref={this.imageRef} />
                  </figure>
                )
              : null}
          </div>
        </Section>
      </View>
    )
  }
}

// Export
export default FacialRecognitionDemo
